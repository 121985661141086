define('zaris-frontend/components/obr-obrazac1-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac1Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "70%",
    "data-width": "70%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentElPregleda: Em.A(),
    modelObjectElPregleda: {},
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.obrObrazac1UnosModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      initUpdate: function initUpdate() {
        console.log('init update');
      },

      initDelete: function initDelete() {
        console.log('init delete');
      },

      updateFgRizicno: function updateFgRizicno() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifRadnoMestoModel = Models['default'][this.get('selectedReferencedDomain')].create({ errorHandlerController: this });
        sifRadnoMestoModel.find(this.get('idSelectedRadnoMesto'), this.get('login').getAccessToken(), function (o) {

          o.fgRizicno = outter.get('selektovanoRadnoMesto.fgRizicnoRadnoMesto');

          sifRadnoMestoModel.update(o, outter.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('obrazac1.azuriranjeRadnogMestaHeader').string,
              content: i18n.t('obrazac1.azuriranjeRadnogMestaUspesno').string,
              keepOpen: false,
              type: 'success'
            });

            outter.closePanel();
            outter.toggleProperty('refresh');
          }, function (e) {
            $.Notify({
              caption: i18n.t('obrazac1.azuriranjeRadnogMestaHeader').string,
              content: i18n.t(e.responseJSON.messages[0]).string,
              keepOpen: true,
              type: 'alert'
            });
          });
        }, function (e) {
          $.Notify({
            caption: i18n.t('obrazac1.azuriranjeRadnogMestaHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: false,
            type: 'alert'
          });
          outter.closePanel();
          //  outter.toggleProperty('refresh');
        });
      }

    },

    openPanel: Ember['default'].observer('modalPanelShowEdit', function () {
      this.toggleProperty('refreshObrazac1Modal');

      this.set('idSelectedRadnoMesto', this.get('selektovanoRadnoMesto.' + this.get('selectedReferencedDomainId')));

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac1Modal');
    }),

    /*
    refreshBaseTableListener: Ember.observer('refreshObrazac1Modal', function() {
      this.toggleProperty('refresh');
    }),
    */

    closePanel: function closePanel() {
      this.toggleProperty('refresh');
      $('.obrObrazac1Modal').data('dialog').close();
    },

    tableColumnsElPregleda: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnostiStetnosti',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazac1.opasnostiStetnosti'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).sifra + (row.get(this.key).podsifra != null ? "." + row.get(this.key).podsifra : "") + " - " + row.get(this.key).naziv;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'sifNivoRizika',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazac1.nivoRizika'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).sifra + " - " + row.get(this.key).naziv;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 400,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazac1.napomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});