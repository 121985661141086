define('zaris-frontend/components/file-upload-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevelYesNo', 'fileUploadModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "20%",
    "data-width": "50%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark yes-no-dialog",
    "data-type": "modal",

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    objectId: null,
    servicePath: null,
    refresh: false,
    modalPanelShow: false,

    domainName: "SifLzoTree",

    fileUploaded: "OK",

    didInsertElement: function didInsertElement() {
      this.$('.fileUploadModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      uploadFile: function uploadFile() {
        if (document.getElementById("file_upload_id").files.length == 0) {
          this.set('fileUploaded', "");
        } else {
          this.set('fileUploaded', "OK");
        }
        // zbog Ember run loop-a mora afterRender da bi se DOM updejtovao
        Ember['default'].run.schedule("afterRender", this, function () {
          if (this.get('validate').validate(this)) {
            var outter = this;
            var i18n = this.get('i18n');

            var formData = new FormData();
            formData.append('file', $('input[type=file]')[0].files[0]);
            formData.append('id', this.get('objectId'));

            var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });
            model.uploadFile(this.get('servicePath'), formData, this.get('login').getAccessToken(), 'POST', function (o) {
              $.Notify({
                caption: i18n.t("fileUpload.dodavanjeHeader").string,
                content: i18n.t("fileUpload.dodavanjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.toggleProperty('refresh');
              outter.closePanel();
            });
          }
        });
      },

      submitForm: function submitForm() {
        var validateService = this.get('validate');
        if (validateService._validate(this)) {
          this.closePanel();
        };
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.set('fileUploaded', "");
      document.getElementById('file_upload_form_id').reset();
      Ember['default'].showModalPanel('.fileUploadModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.fileUploadModal').data('dialog').close();
    }

  });

});