define('zaris-frontend/controllers/obr-obrazac07-unos', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    obrObrazac07Content: Em.A(),

    i18n: Ember['default'].inject.service('i18n'),

    //selected domain name
    selectedDomainName: "radnoMesto",

    selectedDomain: Ember['default'].computed('selectedDomainName', function () {
      this.toggleProperty('refresh');
      return this.get('selectedDomainName') === 'radnoMesto' ? 'ObrObrazac07Rm' : 'ObrObrazac07Rms';
    }),

    selectedDomainCapsDown: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'obrObrazac07Rm' : 'obrObrazac07Rms';
    }),

    selectedDomainId: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'idObrazac07Rm' : 'idObrazac07Rms';
    }),

    selectedReferencedDomain: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'SifRadnoMesto' : 'SifRadnoMestoStruktura';
    }),

    selectedReferencedDomainId: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'idRadnoMesto' : 'idRadnoMestoStruktura';
    }),

    tableColumns: Ember['default'].computed('selectedDomainName', function () {
      var columns = [];

      if (this.get('selectedDomainName') === 'radnoMesto') {
        columns.push(ColumnDefinition['default'].create({
          key: 'sifRadnoMesto.naziv',
          savedWidth: 500,
          supportSort: true,
          headerCellName: this.get('i18n').t('obrObrazac07.radnoMesto'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));

        columns.push(ColumnDefinition['default'].create({
          key: 'obrObrazac07OpasnaMaterijaRms.length',
          savedWidth: 250,
          supportSort: true,
          headerCellName: this.get('i18n').t('obrObrazac07.brojEvidentiranihOpasnihMaterija'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));
      } else {
        columns.push(ColumnDefinition['default'].create({
          key: 'sifRadnoMestoStruktura.sifRadnoMesto.naziv',
          savedWidth: 500,
          supportSort: true,
          headerCellName: this.get('i18n').t('obrObrazac07.radnoMesto'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));

        columns.push(ColumnDefinition['default'].create({
          key: 'sifRadnoMestoStruktura.sysOrganizacionaJedinica.naziv',
          savedWidth: 500,
          supportSort: true,
          headerCellName: this.get('i18n').t('obrObrazac07.organizacionaJedinica'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));

        columns.push(ColumnDefinition['default'].create({
          key: 'obrObrazac07OpasnaMaterijaRmses.length',
          savedWidth: 250,
          supportSort: true,
          headerCellName: this.get('i18n').t('obrObrazac07.brojEvidentiranihOpasnihMaterija'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));
      }

      return columns;
    })

  });

});