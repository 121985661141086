define('zaris-frontend/components/sif-lica-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0;
    }),

    activeAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('idRowSelected') !== 0;
      var selectedRow = this.get('selectedRow');
      return isRowSelected && selectedRow.fgArhivirano;
    }),

    archiveAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('idRowSelected') !== 0;
      var selectedRow = this.get('selectedRow');
      return isRowSelected && !selectedRow.fgArhivirano;
    }),

    didInsertElement: function didInsertElement() {
      this.$('.sifElementiPregledaLovModal').appendTo(this.$(document.body));
      this.$('.sifObukeLovModal').appendTo(this.$(document.body));
      this.$('.sifPreglediLovModal').appendTo(this.$(document.body));
      this.$('.sifLzoKategorijeLovModal').appendTo(this.$(document.body));
      this.$('.sifLzoVelicineLovModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      aktivirajLice: function aktivirajLice() {
        var outter = this;
        var i18n = this.get('i18n');
        var idRowSelected = this.get('idRowSelected');

        var model = Models['default'].SifLica.create({ errorHandlerController: this });
        model.getById('aktiviranje', idRowSelected, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            content: i18n.t('sifLica.aktiviranjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },
      deaktivirajLice: function deaktivirajLice() {
        var outter = this;
        var i18n = this.get('i18n');
        var idRowSelected = this.get('idRowSelected');

        var model = Models['default'].SifLica.create({ errorHandlerController: this });
        model.getById('arhiviranje', idRowSelected, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            content: i18n.t('sifLica.deaktiviranjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },
      initElementiPregleda: function initElementiPregleda() {
        this.toggleProperty('refreshSifElementiPregledaLov');
        this.toggleProperty('myModalPanel1Show');
      },

      initObuke: function initObuke() {
        this.toggleProperty('refreshSifObukeLov');
        this.toggleProperty('myModalPanel2Show');
      },

      initPregledi: function initPregledi() {
        this.toggleProperty('refreshSifPreglediLov');
        this.toggleProperty('myModalPanel3Show');
      },

      initLzoVelicine: function initLzoVelicine() {
        this.toggleProperty('refreshSifLzoVelicineLov');
        this.toggleProperty('myModalPanel4Show');
      },

      initLzoKategorije: function initLzoKategorije() {
        this.toggleProperty('refreshSifLzoKategorijeLov');
        this.toggleProperty('myModalPanel5Show');
      }

    }
  });

});