define('zaris-frontend/templates/obr-obrazac02-unos', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac02-unos.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_zaposlenima_rasporedjenim"],[],["loc",[null,[4,28],[4,83]]]],
        ["inline","component",["zaris-datatable"],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"domainName","VobrObrazac02","domainId","idZaposleni","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[14,16],[14,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[15,22],[15,33]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[16,22],[16,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[17,18],[17,25]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[18,34],[18,57]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"disabledCreate",true,"disabledDelete",true,"idModalPanelEdit","sifZaposleniEditModal","renderSacuvajObrazac",true,"renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac02Sacuvani","renderIzvestaji",true,"routeIzvestaji","obrObrazac02Izvestaji","modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[30,31],[30,45]]]]],[],[]],"renderCreate",false,"renderDelete",false],["loc",[null,[9,8],[33,10]]]],
        ["inline","obr-obrazac02-modal",[],["id","obrObrazac02Modal","target",["subexpr","@mut",[["get","this",["loc",[null,[41,11],[41,15]]]]],[],[]],"isNew",false,"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[43,16],[43,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[44,12],[44,19]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[45,28],[45,51]]]]],[],[]],"modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[46,25],[46,39]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[47,15],[47,38]]]]],[],[]],"selektovaniZaposleni",["subexpr","@mut",[["get","selectedRow",["loc",[null,[48,27],[48,38]]]]],[],[]]],["loc",[null,[39,0],[49,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});