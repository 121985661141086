define('zaris-frontend/templates/application', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/application.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","page-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","flex-grid no-responsive-future containerCentered");
        dom.setAttribute(el2,"style","height: 100%; width: 90%;");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","render",["navigation"],[],["loc",[null,[2,0],[2,23]]]],
        ["content","outlet",["loc",[null,[7,6],[7,16]]]],
        ["inline","modal-yes-no",[],["id","idYesNoDialog","titleMsgKey",["subexpr","@mut",[["get","titleYesNoMsgKey",["loc",[null,[13,14],[13,30]]]]],[],[]],"descMsgKey",["subexpr","@mut",[["get","descYesNoMsgKey",["loc",[null,[14,13],[14,28]]]]],[],[]]],["loc",[null,[11,0],[15,2]]]],
        ["inline","modal-ok",[],["id","idDialogOk","titleMsgKey",["subexpr","@mut",[["get","titleOkMsgKey",["loc",[null,[19,14],[19,27]]]]],[],[]],"descMsgKey",["subexpr","@mut",[["get","descOkMsgKey",["loc",[null,[20,13],[20,25]]]]],[],[]],"messageType",["subexpr","@mut",[["get","modalOkType",["loc",[null,[21,14],[21,25]]]]],[],[]]],["loc",[null,[17,0],[22,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});