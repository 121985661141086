define('zaris-frontend/controllers/obr-obrazac06a-unos', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    i18n: Ember['default'].inject.service('i18n'),

    idObukaFilter: '',

    contentZakazan: Em.A(),

    componentTab1: 'zaris-datatable',

    actions: {
      filterChanged: function filterChanged(selected) {
        this.toggleProperty('refresh');
      },

      openTab1: function openTab1() {
        this.set('componentTab1', 'zaris-datatable');
        this.set('componentTab2', null);
      },

      openTab2: function openTab2() {
        this.set('componentTab1', null);
        this.set('componentTab2', 'zaris-datatable');
      }
    },

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'interniMaticniBroj',
        savedWidth: 130,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.interniMaticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'imePrezime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 80,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.maticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'nazivObuke',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.nazivObuke'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.radnoMestoNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'organizacionaJedinicaNaziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.organizacionaStruktura'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    }),

    tableColumnsZakazan: Ember['default'].computed(function () {
      var columns = [];
      var outter = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'sifObuka.naziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.nazivObuke'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumObuke',
        savedWidth: 120,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.datumObuke'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledeceObuke',
        savedWidth: 120,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.datumSledeceObuke'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'intervalObuke',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.interval'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.content.sifObuka.fgPeriodicno) {
            return row.content.sifObuka.intervalMeseci;
          } else {
            return outter.get('i18n').t('sifKorisnickiDefinisaneObuke.obukaSeObavljaJednom');
          }
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.napomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});