define('zaris-frontend/components/sif-zaposleni-edit-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifZaposleniEditModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      update: function update() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifZaposleniModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            sifZaposleniModel = Models['default'].SifZaposleni.create({ errorHandlerController: _this });

            sifZaposleniModel.update(_this.get('modelObject'), _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("sifZaposleni.azuriranjeHeader").string,
                content: i18n.t("sifZaposleni.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      Ember['default'].showModalPanel('.sifZaposleniEditModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifZaposleniEditModal').data('dialog').close();
    }
  });

});