define('zaris-frontend/components/zaris-header-block', ['exports', 'ember-table/components/header-block'], function (exports, HeaderBlock) {

  'use strict';

  exports['default'] = HeaderBlock['default'].extend({
    actions: {
      sortByColumn: function sortByColumn(content) {
        this.set('action', 'sortByColumn');
        this.sendAction('action', content);
      }
    }
  });

});