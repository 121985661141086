define('zaris-frontend/components/obr-obrazac08-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac08Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentObuke: Em.A(),
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.obrObrazac08PregledModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      novaObuka: function novaObuka() {
        //    this.set('fgUnos', true);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelPregled');
        }, 100);
      },

      azurirajObuku: function azurirajObuku() {
        //    this.set('fgUnos', false);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelPregled');
        }, 100);
      }

    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshObrazac08Modal');
      this.set('idRowSelectedObr8', 0);

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac08Modal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshObrazac08Modal', function () {
      this.toggleProperty('refreshWoResetSelection');
    }),

    closePanel: function closePanel() {
      $('.obrObrazac08Modal').data('dialog').close();
    },

    tableColumnsPregled: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'brojIspitivanja',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac08.brojIspitivanja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumIspitivanja',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac08.datumIspitivanja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledecegIspitivanja',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac08.datumSledecegIspitivanja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac08.napomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});