define('zaris-frontend/controllers/sys-pravna-lica', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/models/models', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, Models, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service('i18n'),
    login: Ember['default'].inject.service('login'),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'idPravnoLice',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.id'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.naziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.maticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'pib',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.pib'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'sifraDelatnosti',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.sifraDelatnosti'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'adresa',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.adresa'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'mesto',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.mesto'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'drzava',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('xsysPravnoLice.drzava'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});