define('zaris-frontend/components/modal-yes-no', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    id: 'dialogDelete',
    classNames: ['zarisModalPanel', 'dialog', 'zarisYesNoModalPanel', 'modalPanelLevelYesNo', 'panelHidden'],
    classNameBindings: ['messageType'],
    attributeBindings: ['data-role', 'data-close-button', 'data-windows-style', 'data-overlay', 'data-overlay-color'],
    "data-role": 'dialog',
    "data-close-button": true,
    "data-windows-style": true,
    "data-overlay": true,
    "data-overlay-color": "op-dark yes-no-dialog",

    titleMsgKey: '',
    descMsgKey: '',
    //panel type/color (ERROR => 'alert', SUCCESS => 'success', WARN: 'warning', 'INFO' => 'info')
    messageType: 'alert',

    //callback function called on CONFIRM
    controller: null,
    action: null,

    expose: (function () {
      var app_controller = this.get('targetObject');
      var exposedName = "comp-" + this.get('id');
      app_controller.set(exposedName, this);
    }).on('init'),

    actions: {
      openModal: function openModal(titleMsgKey, descMsgKey, controller, action, messageType) {
        this.set('titleMsgKey', titleMsgKey);
        this.set('descMsgKey', descMsgKey);
        this.set('controller', controller);
        this.set('action', action);

        if (messageType !== null && messageType !== undefined) {
          this.set('messageType', messageType);
        }

        Ember['default'].showModalPanel('#' + this.get('id'));
      },

      closeModal: function closeModal() {
        $('#' + this.get('id')).data('dialog').close();
      },

      modalConfirm: function modalConfirm() {
        //sends action up the hierarchy
        //this.set('action', 'deleteConfirm');
        //this.sendAction();

        this.get('controller.' + this.get('action'))(this.get('controller'));
      }
    }
  });

});