define('zaris-frontend/components/zaris-header-cell', ['exports', 'ember-table/components/header-cell'], function (exports, HeaderCell) {

  'use strict';

  exports['default'] = HeaderCell['default'].extend({
    actions: {
      sortByColumn: function sortByColumn(content) {
        this.set('action', 'sortByColumn');
        this.sendAction('action', content);
      }
    }
  });

});