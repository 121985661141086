define('zaris-frontend/controllers/sif-opasnosti-stetnosti', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    i18n: Ember['default'].inject.service('i18n'),

    valueFactors: Ember['default'].computed('valueFactors', function () {
      var i18n = this.get('i18n');

      return Em.A([{
        'display_name': i18n.t('sifOpasnostiStetnosti.evidentirana').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }]);
    }),
    zarisColumnsDefinition: [{ savedWidth: 100, tableCellView: 'zaris-tree-table-cell-boolean-icon', translateBoolean: false }]

  });

});