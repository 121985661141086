define('zaris-frontend/controllers/obr-obrazac10-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    lzo: "lzoObavljenaIspitivanja",

    idObrazac10Lzo: null,

    rokTrajanja: 14,
    rokIspitivanja: 14,

    sort: '1',

    actions: {

      generate: function generate() {

        // prepakovanje objekta
        var pObj = {};

        var idObrazac10Lzo = this.get('idObrazac10Lzo');
        pObj.p_id_obrazac10_lzo = idObrazac10Lzo;

        var lzo = this.get('lzo');
        var rokTrajanja = this.get('rokTrajanja');
        var rokIspitivanja = this.get('rokIspitivanja');

        pObj.p_obavljena_ispitivanja = "0";
        pObj.p_bez_atesta_uputstva = "0";
        pObj.p_isteklo_trajanje = "0";
        pObj.p_isteklo_ispitivanje = "0";
        pObj.p_datum_trajanje_od = null;
        pObj.p_datum_trajanje_do = null;
        pObj.p_datum_ispitivanje_od = null;
        pObj.p_datum_ispitivanje_do = null;

        switch (lzo) {
          case 'lzoObavljenaIspitivanja':
            pObj.p_obavljena_ispitivanja = "1";
            break;
          case 'lzoBezAtesta':
            pObj.p_bez_atesta_uputstva = "1";
            break;
          case 'lzoIstekaoRokTrajanja':
            pObj.p_isteklo_trajanje = "1";
            break;
          case 'lzoIstekaoRokIspitivanja':
            pObj.p_isteklo_ispitivanje = "1";
            break;
          case 'lzoIsticeRokTrajanja':
            pObj.p_datum_trajanje_od = moment().format("DD.MM.YYYY");
            pObj.p_datum_trajanje_do = moment().add(rokTrajanja, 'days').format("DD.MM.YYYY");
            break;
          case 'lzoIsticeRokIspitivanja':
            pObj.p_datum_ispitivanje_od = moment().format("DD.MM.YYYY");
            pObj.p_datum_ispitivanje_do = moment().add(rokIspitivanja, 'days').format("DD.MM.YYYY");
          default:
            break;
        }

        var sort = this.get('sort');
        pObj.p_sort = sort;

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_10';
        izvestajiDTO.reportParameters = pObj;

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }

    }

  });

});