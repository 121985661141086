define('zaris-frontend/templates/obr-obrazac04-izvestaji', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 12
            },
            "end": {
              "line": 14,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.common.unosPodataka"],[],["loc",[null,[12,14],[12,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 12
            },
            "end": {
              "line": 20,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.menu.sacuvani_izvestaji"],[],["loc",[null,[18,14],[18,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 26
              },
              "end": {
                "line": 83,
                "column": 103
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac4.izvestaji.sviNivoiRadneSposobnosti"],[],["loc",[null,[83,51],[83,102]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 84,
                "column": 26
              },
              "end": {
                "line": 84,
                "column": 99
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac4.izvestaji.radnaSposobnostVecaOd"],[],["loc",[null,[84,50],[84,98]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 26
              },
              "end": {
                "line": 85,
                "column": 100
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac4.izvestaji.radnaSposobnostManjaOd"],[],["loc",[null,[85,50],[85,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 26
              },
              "end": {
                "line": 86,
                "column": 100
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac4.izvestaji.radnaSposobnostJednaka"],[],["loc",[null,[86,50],[86,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 24
            },
            "end": {
              "line": 87,
              "column": 24
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","<>"],0,null,["loc",[null,[83,26],[83,116]]]],
          ["block","x-option",[],["value",">"],1,null,["loc",[null,[84,26],[84,112]]]],
          ["block","x-option",[],["value","<"],2,null,["loc",[null,[85,26],[85,113]]]],
          ["block","x-option",[],["value","="],3,null,["loc",[null,[86,26],[86,113]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 22
              },
              "end": {
                "line": 107,
                "column": 88
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac4.izvestaji.radnimMestima"],[],["loc",[null,[107,47],[107,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 108,
                "column": 22
              },
              "end": {
                "line": 108,
                "column": 99
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac4.izvestaji.organizacionojStrukturi"],[],["loc",[null,[108,48],[108,98]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 22
              },
              "end": {
                "line": 109,
                "column": 87
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac4.izvestaji.zaposlenima"],[],["loc",[null,[109,48],[109,86]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 22
            },
            "end": {
              "line": 110,
              "column": 22
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","rm"],0,null,["loc",[null,[107,22],[107,101]]]],
          ["block","x-option",[],["value","org"],1,null,["loc",[null,[108,22],[108,112]]]],
          ["block","x-option",[],["value","zap"],2,null,["loc",[null,[109,22],[109,100]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 139,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac04-izvestaji.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 90%; ");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row 2");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","flex-grid ");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2 flex-just-sa");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2 flex-just-sa");
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2 flex-just-sa last-row");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h4");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell obr-izv-cell-generate colspan6");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button success izv-generate-button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-checkmark");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element6, [3]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [1, 1]);
        var element12 = dom.childAt(element10, [3, 1]);
        var element13 = dom.childAt(element9, [3]);
        var element14 = dom.childAt(element3, [3]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element14, [3]);
        var element17 = dom.childAt(element16, [3]);
        var element18 = dom.childAt(element3, [5]);
        var element19 = dom.childAt(element18, [1, 1]);
        var element20 = dom.childAt(element18, [3, 1]);
        var morphs = new Array(24);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[4] = dom.createMorphAt(element5,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
        morphs[6] = dom.createMorphAt(element8,1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [5]),0,0);
        morphs[8] = dom.createMorphAt(element11,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element11, [5]),0,0);
        morphs[10] = dom.createMorphAt(element12,1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element12, [5]),0,0);
        morphs[12] = dom.createMorphAt(element13,1,1);
        morphs[13] = dom.createMorphAt(element13,3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        morphs[15] = dom.createMorphAt(element15,3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element17, [1, 1]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element17, [3, 1]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element19, [1, 1]),0,0);
        morphs[20] = dom.createMorphAt(dom.childAt(element19, [3, 1]),1,1);
        morphs[21] = dom.createElementMorph(element20);
        morphs[22] = dom.createMorphAt(element20,2,2);
        morphs[23] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_profesionalnim_oboljenjima"],[],["loc",[null,[4,28],[4,84]]]],
        ["block","link-to",["obrObrazac04Unos"],["tagName","button","class","image-button info"],0,null,["loc",[null,[11,12],[14,24]]]],
        ["block","link-to",["obrObrazac04Sacuvani"],["tagName","button","class","image-button info"],1,null,["loc",[null,[17,12],[20,24]]]],
        ["inline","t",["obrazac4.izvestaji.headerStruktura"],[],["loc",[null,[26,22],[26,64]]]],
        ["inline","select-tree",[],["strukturaModalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[28,44],[28,67]]]]],[],[]],"label","obrazac4.izvestaji.sviNivoiStrukture","obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[30,24],[30,36]]]]],[],[]]],["loc",[null,[27,18],[31,22]]]],
        ["inline","t",["obrazac4.izvestaji.headerRadnaMesta"],[],["loc",[null,[36,24],[36,67]]]],
        ["inline","radio-button",[],["value","radnaMesta","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[38,64],[38,74]]]]],[],[]]],["loc",[null,[38,22],[38,76]]]],
        ["inline","t",["obrazac4.izvestaji.radnaMesta"],[],["loc",[null,[40,44],[40,81]]]],
        ["inline","radio-button",[],["value","radnoMesto","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[49,64],[49,74]]]]],[],[]]],["loc",[null,[49,22],[49,76]]]],
        ["inline","t",["obrazac4.izvestaji.radnoMesto"],[],["loc",[null,[51,44],[51,81]]]],
        ["inline","radio-button",[],["value","radnoMestoStruktura","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[56,73],[56,83]]]]],[],[]]],["loc",[null,[56,22],[56,85]]]],
        ["inline","t",["obrazac4.izvestaji.radnoMestoStruktura"],[],["loc",[null,[58,44],[58,90]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMesto","value",["subexpr","@mut",[["get","idRadnoMesto",["loc",[null,[63,85],[63,97]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[63,120],[63,130]]]],"radnoMesto"],[],["loc",[null,[63,112],[63,144]]]]],[],["loc",[null,[63,107],[63,145]]]]],["loc",[null,[63,20],[63,147]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMestoStruktura","value",["subexpr","@mut",[["get","idRadnoMestoStruktura",["loc",[null,[64,94],[64,115]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[64,138],[64,148]]]],"radnoMestoStruktura"],[],["loc",[null,[64,130],[64,171]]]]],[],["loc",[null,[64,125],[64,172]]]]],["loc",[null,[64,20],[64,174]]]],
        ["inline","t",["obrazac4.izvestaji.headerDijagnoza"],[],["loc",[null,[73,22],[73,64]]]],
        ["inline","select-items",[],["nullLabel","obrazac4.izvestaji.svaOboljenja","nullChoice",true,"domainName","SifOboljenje","value",["subexpr","@mut",[["get","idOboljenje",["loc",[null,[74,125],[74,136]]]]],[],[]]],["loc",[null,[74,18],[74,139]]]],
        ["inline","t",["obrazac4.izvestaji.headerPreostalaRadnaSposobnost"],[],["loc",[null,[78,22],[78,79]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","sposobnostOperacija",["loc",[null,[82,42],[82,61]]]]],[],[]]],2,null,["loc",[null,[82,24],[87,37]]]],
        ["inline","input",[],["type","number","disabled",["subexpr","equals",[["get","sposobnostOperacija",["loc",[null,[92,63],[92,82]]]],"<>"],[],["loc",[null,[92,55],[92,88]]]],"value",["subexpr","@mut",[["get","sposobnost",["loc",[null,[92,95],[92,105]]]]],[],[]]],["loc",[null,[92,24],[92,107]]]],
        ["inline","t",["obrazac4.izvestaji.grupisiIzvestajPo"],[],["loc",[null,[102,24],[102,68]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","groupBy",["loc",[null,[106,40],[106,47]]]]],[],[]]],3,null,["loc",[null,[106,22],[110,35]]]],
        ["element","action",["generate"],[],["loc",[null,[116,24],[116,45]]]],
        ["inline","t",["app.common.generisi"],[],["loc",[null,[116,125],[116,152]]]],
        ["inline","select-tree-lov-modal",[],["id","strukturaTreeLovModal","rootView",true,"domainName","SysOrganizacionaJedinicaTree","idModalPanel","sifOrganizacionaJedinicaModal","target",["subexpr","@mut",[["get","this",["loc",[null,[130,11],[130,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[131,10],[131,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[132,16],[132,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[133,12],[133,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[134,19],[134,42]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[135,16],[135,27]]]]],[],[]],"obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[136,8],[136,20]]]]],[],[]],"displayName","app.menu.organizaciona_struktura"],["loc",[null,[125,0],[138,2]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});