define('zaris-frontend/controllers/obr-obrazac03-unos', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    i18n: Ember['default'].inject.service('i18n'),

    objPovredjeni: {},
    objRukovodioc: {},
    objOcevidac: {},

    init: function init() {
      this._super();

      var objPovredjeni = this.get('objPovredjeni');
      objPovredjeni.id = null;
      objPovredjeni.naziv = "";

      var objRukovodioc = this.get('objRukovodioc');
      objRukovodioc.id = null;
      objRukovodioc.naziv = "";

      var objOcevidac = this.get('objOcevidac');
      objOcevidac.id = null;
      objOcevidac.naziv = "";
    },

    myModalPanel2Show: false,
    showZaposleni: false,

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'idObrazac03PovredniList',
        savedWidth: 50,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.id'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifLicaByIdLicePovredjeni.ime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var imePrezime = row.get("sifLicaByIdLicePovredjeni.ime") + " " + row.get('sifLicaByIdLicePovredjeni.prezime');
          return imePrezime;
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifRadnoMestoStruktura.sifRadnoMesto.naziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.radnoMesto'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'vremePovrede',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.datumPovrede'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'sifIzvorPovrede.sifra',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.izvorPovrede'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var sifra = row.get('sifIzvorPovrede.sifra');
          var naziv = row.get('sifIzvorPovrede.naziv');
          if (sifra != null && naziv != null) return sifra + ' - ' + naziv;else return "";
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'sifUzrokPovrede.sifra',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.uzrokPovrede'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var sifra = row.get('sifUzrokPovrede.sifra');
          var naziv = row.get('sifUzrokPovrede.naziv');
          if (sifra != null && naziv != null) return sifra + ' - ' + naziv;else return "";
        }
      }));

      return columns;
    })

  });

});