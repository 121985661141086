define('zaris-frontend/components/sif-lzo-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    objectId: null,
    servicePath: null,
    refresh: false,
    modalPanelShow: false,

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0 && this.get('selectedRow.id_lzo') !== null;
    }),

    isUputstvoExist: Ember['default'].computed('selectedRow', function () {
      return this.get('selectedRow.values.5.value');
    }),

    isUputstvoRequired: Ember['default'].computed('selectedRow', function () {
      return this.get('selectedRow.values.3.value');
    }),

    isAtestExist: Ember['default'].computed('selectedRow', function () {
      return this.get('selectedRow.values.6.value');
    }),

    isAtestRequired: Ember['default'].computed('selectedRow', function () {
      return this.get('selectedRow.values.4.value');
    }),

    didInsertElement: function didInsertElement() {
      this.$('.fileUploadModal').appendTo(this.$(document.body));
      this.$('.sifLzoKategorijaModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      setUputstvoPostoji: function setUputstvoPostoji() {
        var outter = this;
        var i18n = this.get('i18n');

        var obj = this.get('selectedRow');
        obj.fg_uputstvo = true;

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });
        sifLzoTreeModel.getById('setUputstvoPostoji', obj.id_lzo, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            caption: i18n.t("sifLicnaZastitnaOprema.azuriranjeHeader").string,
            content: i18n.t("sifLicnaZastitnaOprema.azuriranjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },
      setUputstvoNePostoji: function setUputstvoNePostoji() {
        var outter = this;
        var i18n = this.get('i18n');

        var obj = this.get('selectedRow');
        obj.fg_uputstvo = false;

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });
        sifLzoTreeModel.getById('setUputstvoNePostoji', obj.id_lzo, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            caption: i18n.t("sifLicnaZastitnaOprema.azuriranjeHeader").string,
            content: i18n.t("sifLicnaZastitnaOprema.azuriranjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },
      setAtestPostoji: function setAtestPostoji() {
        var outter = this;
        var i18n = this.get('i18n');

        var obj = this.get('selectedRow');
        obj.fg_atest = true;

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });
        sifLzoTreeModel.getById('setAtestPostoji', obj.id_lzo, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            caption: i18n.t("sifLicnaZastitnaOprema.azuriranjeHeader").string,
            content: i18n.t("sifLicnaZastitnaOprema.azuriranjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },
      setAtestNePostoji: function setAtestNePostoji() {
        var outter = this;
        var i18n = this.get('i18n');

        var obj = this.get('selectedRow');
        obj.fg_atest = false;

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });
        sifLzoTreeModel.getById('setAtestNePostoji', obj.id_lzo, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            caption: i18n.t("sifLicnaZastitnaOprema.azuriranjeHeader").string,
            content: i18n.t("sifLicnaZastitnaOprema.azuriranjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },

      initUploadUputstvo: function initUploadUputstvo() {
        this.set('objectId', this.get('selectedRow.id_lzo'));
        this.set('servicePath', 'uploadUputstvo');
        this.toggleProperty('fileUploadModalPanelShow');
      },

      downloadUputstvo: function downloadUputstvo() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });

        sifLzoTreeModel.getById('downloadUputstvo', this.get('selectedRow.id_lzo'), this.get('login').getAccessToken(), 'GET', function (hash) {
          console.log(hash);

          var link = document.createElement('a');console.log(sifLzoTreeModel);
          link.href = sifLzoTreeModel.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      deleteUputstvo: function deleteUputstvo() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });
        sifLzoTreeModel.getById('deleteUputstvo', this.get('selectedRow.id_lzo'), this.get('login').getAccessToken(), 'POST', function (o) {
          $.Notify({
            caption: i18n.t("fileUpload.brisanjeHeader").string,
            content: i18n.t("fileUpload.brisanjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },

      initUploadAtest: function initUploadAtest() {
        this.set('objectId', this.get('selectedRow.id_lzo'));
        this.set('servicePath', 'uploadAtest');
        this.toggleProperty('fileUploadModalPanelShow');
      },

      downloadAtest: function downloadAtest() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });

        sifLzoTreeModel.getById('downloadAtest', this.get('selectedRow.id_lzo'), this.get('login').getAccessToken(), 'GET', function (hash) {
          console.log(hash);

          var link = document.createElement('a');console.log(sifLzoTreeModel);
          link.href = sifLzoTreeModel.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      deleteAtest: function deleteAtest() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });
        sifLzoTreeModel.getById('deleteAtest', this.get('selectedRow.id_lzo'), this.get('login').getAccessToken(), 'POST', function (o) {
          $.Notify({
            caption: i18n.t("fileUpload.brisanjeHeader").string,
            content: i18n.t("fileUpload.brisanjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },

      dodajKategoriju: function dodajKategoriju() {
        this.toggleProperty('modalPanelShow');
        Ember['default'].showModalPanel('.sifLzoKategorijaModal');
      }
    }
  });

});