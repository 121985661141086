define('zaris-frontend/controllers/sif-lica', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    tableColumns: Ember['default'].computed(function () {

      var self = this;

      var idColumn = ColumnDefinition['default'].create({
        key: 'interniMaticniBroj',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLica.inputInterniMaticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var imePrezimeColumn = ColumnDefinition['default'].create({
        key: 'ime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLica.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var srednjeIme = row.get("srednjeIme") !== undefined && row.get("srednjeIme") !== null ? "(" + row.get("srednjeIme") + ") " : "";
          var imePrezime = row.get("ime") + " " + srednjeIme + row.get('prezime');
          var sufiks = self.ohsNameSuffix(row.get("liceZaBzr"));
          if (row.get("liceZaBzr")) {
            return Ember['default'].String.htmlSafe(imePrezime + ' <span style="color:#e59400;">' + sufiks + '</span>');
          }
          return imePrezime;
        }
      });
      var maticniBrojColumn = ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLica.inputMaticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var datumRodjenjaColumn = ColumnDefinition['default'].create({
        key: 'datumRodjenja',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLica.inputDatumRodjenja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      });
      var polColumn = ColumnDefinition['default'].create({
        key: 'pol',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLica.inputPol'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.genderValueNames(row.get(this.key).naziv);
        }
      });
      var adresaColumn = ColumnDefinition['default'].create({
        key: 'adresa',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLica.inputAdresa'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });

      var zanimanjeColumn = ColumnDefinition['default'].create({
        key: 'zanimanje',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLica.inputZanimanje'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      return [idColumn, imePrezimeColumn, maticniBrojColumn, datumRodjenjaColumn, polColumn, adresaColumn, zanimanjeColumn];
    }),

    actions: {

      initModelObject: function initModelObject(mObject) {
        // postavljanje specificnog modelObjecta
        mObject.pol = {};
        mObject.pol.idPol = 1;
      }

    }

  });

});