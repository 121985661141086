define('zaris-frontend/controllers/sif-lzo-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    ispitivanjeLzo: "lzoKojiJePotrebnoNabaviti",

    zaposleniObj: {},

    periodNabaviti: 30,
    periodIstice: 30,

    prikaziZaposlene: false,
    prikaziZaposleneIzbor: '1',

    init: function init() {
      this._super();

      var zaposleniObj = this.get('zaposleniObj');
      zaposleniObj.id = null;
      zaposleniObj.naziv = "";
    },

    actions: {

      generate: function generate() {

        var pObj = {};

        pObj.p_id_lice = null;

        var zaposleniObj = this.get('zaposleniObj');
        var zaposleniModel = Models['default'].SifZaposleni.create({ errorHandlerController: this });
        if (zaposleniObj.id) {
          var lice = zaposleniModel.getResult('liceByZaposleni/' + zaposleniObj.id, this.get('loginService').getAccessToken(), 'GET');
          pObj.p_id_lice = lice.idLice;
        }

        pObj.p_datum_nabaviti_od = null;
        pObj.p_datum_nabaviti_do = null;
        pObj.p_datum_istice_od = null;
        pObj.p_datum_istice_do = null;

        pObj.p_potrebno_nabaviti = "0";
        pObj.p_istice_rok = "0";
        pObj.p_istekao_rok = "0";
        pObj.p_nedodeljena = "0";

        var periodNabaviti = this.get('periodNabaviti');
        var periodIstice = this.get('periodIstice');

        var ispitivanja = {
          'lzoKojiJePotrebnoNabaviti': function lzoKojiJePotrebnoNabaviti() {

            pObj.p_datum_nabaviti_od = moment().format("DD.MM.YYYY");
            pObj.p_datum_nabaviti_do = moment().add(periodNabaviti, 'days').format("DD.MM.YYYY");

            pObj.p_potrebno_nabaviti = "1";
          },
          'rokZaLzoIstice': function rokZaLzoIstice() {

            pObj.p_datum_istice_od = moment().format("DD.MM.YYYY");
            pObj.p_datum_istice_do = moment().add(periodIstice, 'days').format("DD.MM.YYYY");

            pObj.p_istice_rok = "1";
          },
          'rokTrajanjaJeIstekao': function rokTrajanjaJeIstekao() {
            pObj.p_istekao_rok = "1";
          },
          'nedodeljeniLzo': function nedodeljeniLzo() {
            pObj.p_nedodeljena = "1";
          }
        };

        ispitivanja[this.get('ispitivanjeLzo')]();

        var prikaziZaposlene = this.get('prikaziZaposlene');
        pObj.p_prikazi_sortirane = prikaziZaposlene ? "1" : "0";
        pObj.p_sort = this.get('prikaziZaposleneIzbor');

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'licna_zastitna_oprema';
        izvestajiDTO.reportParameters = pObj;

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});