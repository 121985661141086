define('zaris-frontend/controllers/obr-obrazac05-unos', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    i18n: Ember['default'].inject.service('i18n'),

    myModalPanel2Show: false,
    showZaposleni: false,

    tableColumns: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac05.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.nullToStr(row.get('sifZaposleni').sifLica.ime) + ' ' + self.nullToStr(row.get('sifZaposleni').sifLica.prezime);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac05.radnoMesto'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.nullToStr(row.get('sifZaposleni').sifRadnoMestoStruktura.sifRadnoMesto.naziv) + (row.get('sifZaposleni').sifRadnoMestoStruktura.sysOrganizacionaJedinica.naziv != null ? "(" + row.get('sifZaposleni').sifRadnoMestoStruktura.sysOrganizacionaJedinica.naziv + ")" : "");
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac05.dijagnoza'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.nullToStr(row.get('sifBolest').sifra) + (row.get('sifBolest').sifra ? " - " : "") + self.nullToStr(row.get('sifBolest').naziv);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac05.zdravstvenaUstanova'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.nullToStr(row.get('ustanova'));
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac05.stepenOstecenja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.nullToStr(row.get('stepenOstecenja'));
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac05.preostalaSposobnost'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.nullToStr(row.get('preostalaSposobnost')) + (row.get('preostalaSposobnost') != null ? "%" : "");
        }
      }));

      return columns;
    })

  });

});