define('zaris-frontend/app', ['exports', 'ember', 'ember-resolver', 'ember/load-initializers', 'zaris-frontend/config/environment'], function (exports, Ember, Resolver, loadInitializers, config) {

  'use strict';

  var App;

  Ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = Ember['default'].Application.extend({
    modulePrefix: config['default'].modulePrefix,
    podModulePrefix: config['default'].podModulePrefix,
    Resolver: Resolver['default']
  });

  Ember['default'].TextField.reopen({
    attributeBindings: ['data-validate-hint', 'data-validate-func', 'data-validate-arg', 'data-validate-hint-position', 'title']
  });

  Ember['default'].TextArea.reopen({
    attributeBindings: ['data-validate-hint', 'data-validate-func', 'data-validate-arg', 'data-validate-hint-position', 'title']
  });

  Em.Route.reopen({
    i18n: Ember['default'].inject.service(),
    title: Ember['default'].computed('i18n.locale', function () {
      return this.get('i18n').t('app.title');
    })
  });

  /***************PRODUCTION USE*******************/

  //sprecava izbacivanje Ember.deprecated poruka
  Ember['default'].deprecate = function () {};

  //sprecava izlaz iz ember debug dela
  //Ember.debug = function(){};

  //sprecava izlaz iz console log dela
  //console.log = function(){};

  //sprecava izlaz iz console.debug, ovaj izlaz koristi Ember inspector
  //console.debug = function(){};

  //sprecava da se aplikacija vidi kao ember, inspector nije moguce upaliti
  //window.NO_EMBER_DEBUG = true;

  //window.console=null;

  /************************************************/

  Ember['default'].showModalPanel = function (selector) {
    $(selector).removeClass('panelHidden');
    $(selector).data('dialog').open();
  };

  loadInitializers['default'](App, config['default'].modulePrefix);

  exports['default'] = App;

});