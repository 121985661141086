define('zaris-frontend/components/sif-vrste-velicina-za-lzo-modal-new-vrsta-velicina', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifVrsteVelicinaZaLzoNewVrstaVelicinaModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject2: {},
    selectedRow2: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    initCreate: function initCreate() {
      this.set('modelObject2', {});
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var obj;
          var sifLzoVrstaVelicineModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            obj = {};

            obj.naziv = _this.get('modelObject2.group_value');
            obj.nivo = 2;

            sifLzoVrstaVelicineModel = Models['default'].SifLzoVrstaVelicineTree.create({ errorHandlerController: _this });

            sifLzoVrstaVelicineModel.create(obj, _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('sifLzoVrsteVelicina.dodavanjeHeader').string,
                content: i18n.t('sifLzoVrsteVelicina.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifLzoVrsteVelicina.dodavanjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: false,
                type: 'alert'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.initCreate();
      Ember['default'].showModalPanel('.sifVrsteVelicinaZaLzoNewVrstaVelicinaModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifVrsteVelicinaZaLzoNewVrstaVelicinaModal').data('dialog').close();
    }
  });

});