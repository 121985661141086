define('zaris-frontend/templates/obr-obrazac13-unos-old', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 8
            },
            "end": {
              "line": 70,
              "column": 6
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac13-unos-old.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1,"class","zarisDetailTable table striped hovered cell-hovered border bordered");
          dom.setAttribute(el1,"width","75%");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          dom.setAttribute(el3,"class","trNeki1");
          dom.setAttribute(el3,"bgcolor","white");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"rowspan","3");
          dom.setAttribute(el4,"align","center");
          dom.setAttribute(el4,"width","11%");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"rowspan","3");
          dom.setAttribute(el4,"align","center");
          dom.setAttribute(el4,"width","11%");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"colspan","2");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"rowspan","3");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          dom.setAttribute(el3,"class","trNeki2");
          dom.setAttribute(el3,"bgcolor","white");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"colspan","2");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("<td class=\"boldFont\" colspan=\"2\" align=\"center\">{{t 'obrObrazac13.detailOUP'}}</td>");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          dom.setAttribute(el3,"class","trNeki3");
          dom.setAttribute(el3,"bgcolor","white");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"align","center");
          dom.setAttribute(el4,"width","16%");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"align","center");
          dom.setAttribute(el4,"width","16%");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          dom.setAttribute(el3,"class","trUsmeno");
          dom.setAttribute(el3,"bgcolor","white");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          dom.setAttribute(el3,"class","trPismeno");
          dom.setAttribute(el3,"bgcolor","white");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","boldFont");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"align","center");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [5]);
          var element3 = dom.childAt(element0, [7]);
          var element4 = dom.childAt(element0, [9]);
          var morphs = new Array(17);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [7]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element3, [5]),0,0);
          morphs[10] = dom.createMorphAt(dom.childAt(element3, [7]),0,0);
          morphs[11] = dom.createMorphAt(dom.childAt(element3, [9]),0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          morphs[13] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
          morphs[14] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
          morphs[15] = dom.createMorphAt(dom.childAt(element4, [7]),0,0);
          morphs[16] = dom.createMorphAt(dom.childAt(element4, [9]),0,0);
          return morphs;
        },
        statements: [
          ["inline","t",["obrObrazac13.detailPrijava"],[],["loc",[null,[39,78],[39,112]]]],
          ["inline","t",["obrObrazac13.detailDatum"],[],["loc",[null,[40,78],[40,110]]]],
          ["inline","t",["obrObrazac13.detailNadlezniOrgan"],[],["loc",[null,[41,66],[41,106]]]],
          ["inline","t",["obrObrazac13.detailNapomena"],[],["loc",[null,[42,66],[42,101]]]],
          ["inline","t",["obrObrazac13.detailInspekcijaRada"],[],["loc",[null,[45,66],[45,107]]]],
          ["inline","t",["obrObrazac13.detailSedisteNadleznogORgana"],[],["loc",[null,[49,66],[49,115]]]],
          ["inline","t",["obrObrazac13.detailLicePrimiloPrijavu"],[],["loc",[null,[50,66],[50,111]]]],
          ["inline","t",["obrObrazac13.detailUsmeno"],[],["loc",[null,[53,54],[53,87]]]],
          ["content","usmenoDatumFormatted",["loc",[null,[54,37],[54,61]]]],
          ["content","selectedRow.usmenoSedisteInspekcija",["loc",[null,[55,37],[55,76]]]],
          ["content","selectedRow.usmenoLiceInspekcija",["loc",[null,[56,37],[56,73]]]],
          ["content","selectedRow.usmenoNapomena",["loc",[null,[58,37],[58,67]]]],
          ["inline","t",["obrObrazac13.detailPismeno"],[],["loc",[null,[61,54],[61,88]]]],
          ["content","pismenoDatumFormatted",["loc",[null,[62,37],[62,62]]]],
          ["content","selectedRow.pismenoSedisteInspekcija",["loc",[null,[63,37],[63,77]]]],
          ["content","selectedRow.pismenoLiceInspekcija",["loc",[null,[64,37],[64,74]]]],
          ["content","selectedRow.pismenoNapomena",["loc",[null,[66,37],[66,68]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 85,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac13-unos-old.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [3, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(element6,1,1);
        morphs[2] = dom.createMorphAt(element6,6,6);
        morphs[3] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_prijavama_bolesti_u_vezi_sa_radom"],[],["loc",[null,[4,28],[4,91]]]],
        ["inline","zaris-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[11,17],[11,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[12,18],[12,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","VobrObrazac13","domainId","idObrazac05ZaposleniBolest","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[18,16],[18,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[19,22],[19,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[20,18],[20,25]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[21,25],[21,41]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[22,22],[22,33]]]]],[],[]],"renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac13Sacuvani","renderSearch",true,"renderCreate",false,"renderDelete",false,"renderFilterArhivirano",false,"renderSacuvajObrazac",true,"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[30,34],[30,57]]]]],[],[]]],["loc",[null,[10,8],[31,10]]]],
        ["block","if",[["subexpr","not-null",[["get","selectedRow",["loc",[null,[35,24],[35,35]]]]],[],["loc",[null,[35,14],[35,36]]]]],[],0,null,["loc",[null,[35,8],[70,13]]]],
        ["inline","obr-obrazac13-modal",[],["id","obrObrazac13Modal","target",["subexpr","@mut",[["get","this",["loc",[null,[78,11],[78,15]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[79,12],[79,19]]]]],[],[]],"isNewUnos",["subexpr","@mut",[["get","isNewUnos",["loc",[null,[80,14],[80,23]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[81,21],[81,37]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[82,18],[82,29]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[83,28],[83,51]]]]],[],[]]],["loc",[null,[76,0],[84,2]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});