define('zaris-frontend/components/language-select', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'select',
    classNames: ['language-select', 'select-style'],
    i18n: inject.service(),
    current: computed.readOnly('localeStorage.locale'),
    menuService: Ember['default'].inject.service('menu'),

    locales: computed('i18n.locales', function () {
      var i18n = this.get('i18n');
      var current = this.get('current');
      return this.get('i18n.locales').map(function (loc) {
        return { id: loc, text: i18n.t('language-select.language.' + loc), current: current };
      });
    }),

    // It would be nice to do this with `{{action "setLocale" on="change"}}`
    // in the template, but the template doesn't include the component's own
    // tag yet. See https://github.com/emberjs/rfcs/pull/60
    change: function change() {
      localStorage.locale = this.$().val();
      moment.locale(this.$().val());

      console.log(this.get('current'));
      console.log(this.get('locale'));

      var menuService = this.get('menuService');
      menuService.generateHTMLMenu();
    }
  });

});