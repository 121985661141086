define('zaris-frontend/components/sif-radno-mesto-lzo-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'sifRadnoMestoLzoModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",

    isNew: true,
    refreshLicaSelected: false,
    refreshLzo: false,

    content2: Em.A(),
    content3: Em.A(),

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    licaContent: Em.A(),

    componentName1: 'select-datatable-lov-modal',
    componentName2: null,

    componentNameObserver: Ember['default'].observer('selectedDomainName', function () {
      var _this = this;

      if (this.get('selectedDomainName') === 'radnoMesto') {
        this.set('componentName1', 'select-datatable-lov-modal');
        this.set('componentName2', null);
      } else {
        this.set('componentName1', null);
        this.set('componentName2', 'select-datatable-lov-modal');
      }

      setTimeout(function () {
        _this.$('#sifRadnoMestoLzoLovModalPanel').appendTo(_this.$(document.body));
        _this.$('#sifRadnoMestoStrukturaLzoLovModalPanel').appendTo(_this.$(document.body));
      }, 120);
    }),

    customIdObserver: Ember['default'].observer('objRadnoMesto.id', 'objRadnoMestoStruktura.id', function () {
      // refresh tabele na promenu customId-a
      this.toggleProperty('refreshLzo');
    }),

    selectedLice: null,
    selectedLiceIzabrano: null,

    datatableFilters: {},

    didInsertElement: function didInsertElement() {
      this.$('#strukturaTreeLovModal').appendTo(this.$(document.body));
      this.$('#sifRadnoMestoLovModal').appendTo(this.$(document.body));
      this.$('#sifRadnoMestoStrukturaLovModal').appendTo(this.$(document.body));
      this.$('#sifLiceLovModal').appendTo(this.$(document.body));
      this.$('#sifOpasnostiStetnostiModal').appendTo(this.$(document.body));
      this.$('.sifDatatableLovModal').appendTo(this.$(document.body));
      this.$('.radnoMestoKategorijaLzoModal').appendTo(this.$(document.body));
      this.$('#sifRadnoMestoLzoLovModalPanel').appendTo(this.$(document.body));
      this.$('#sifRadnoMestoStrukturaLzoLovModalPanel').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {

      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var outter = this;
        var i18n = this.get('i18n');

        var obj = {};
        obj.vsifZaposleni = [];

        obj.obrObrazac06ObukaZakazana = this.get('selectedZakazanaObuka');

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.get('selectedLicaContent')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var item = _step.value;

            obj.vsifZaposleni.push(item);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var obrObrazac06ObukaModel = Models['default'].ObrObrazac06Obuka.create({ errorHandlerController: this });
        obrObrazac06ObukaModel.postResult('dodelaObukeZaposlenima', obj, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('obrObrazac06.grupnoDodavanjeObukeHeader').string,
            content: i18n.t('obrObrazac06.grupnoDodavanjeObukeUspesno').string,
            keepOpen: false,
            type: 'success'
          }, function (e) {
            $.Notify({
              caption: i18n.t('obrObrazac06.grupnoDodavanjeObukeHeader').string,
              content: i18n.t(e.responseJSON.messages[0]).string,
              keepOpen: true,
              type: 'alert'
            });
          });
          outter.closePanel();
        });

        console.log(outter.get('selectedLicaContent'));
      }
    },

    componentTable1: null,
    componentTable2: null,

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      if (!this.get('isNew')) {
        // update
        if (this.get('selectedDomainName') == "radnoMesto") {
          // radno mesto
          this.set('objRadnoMesto.id', this.get('selectedRow').idRadnoMesto);
          this.set('objRadnoMesto.naziv', this.get('selectedRow').radnoMestoNaziv);
        } else {
          // radno mesto struktura
          this.set('objRadnoMestoStruktura.id', this.get('selectedRow').idRadnoMesto);
          this.set('objRadnoMestoStruktura.naziv', this.get('selectedRow').radnoMestoNaziv);
        }
      } else {
        // create
        this.set('objRadnoMesto.id', null);
        this.set('objRadnoMesto.naziv', "");

        this.set('objRadnoMestoStruktura.id', null);
        this.set('objRadnoMestoStruktura.naziv', "");
      }

      if (this.get('selectedDomainName') == "radnoMesto") {
        this.set('componentTable1', 'zaris-datatable');
      } else {
        this.set('componentTable2', 'zaris-datatable');
      }

      setTimeout(function () {
        Ember['default'].showModalPanel('.sifRadnoMestoLzoModal');
      }, 120);
    }),

    closePanel: function closePanel() {
      this.set('componentTable1', null);
      this.set('componentTable2', null);
      this.toggleProperty('refresh2');
      $('.sifRadnoMestoLzoModal').data('dialog').close();
    },

    tableRadnoMestoColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLzo.tab2.radnoMesto'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    }),

    tableRadnoMestoStrukturaColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLzo.tab2.radnoMesto'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'organizacionaJedinicaNaziv',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLzo.tab2.organizacionaJedinica'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    }),
    radnaMestaLzoColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'sifLzoKategorija',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLzo.tab2.nazivKategorijeLzo'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).naziv;
        }
      }));

      return columns;
    })
  });

});