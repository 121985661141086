define('zaris-frontend/templates/sem-podesavanje-obavestenja', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 18
                  },
                  "end": {
                    "line": 24,
                    "column": 95
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.nePrikazujObavestenje"],[],["loc",[null,[24,62],[24,95]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 18
                  },
                  "end": {
                    "line": 25,
                    "column": 79
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.dana7"],[],["loc",[null,[25,62],[25,79]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 18
                  },
                  "end": {
                    "line": 26,
                    "column": 81
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.dana14"],[],["loc",[null,[26,63],[26,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 18
                  },
                  "end": {
                    "line": 27,
                    "column": 81
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.dana30"],[],["loc",[null,[27,63],[27,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 18
                  },
                  "end": {
                    "line": 28,
                    "column": 81
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.dana60"],[],["loc",[null,[28,63],[28,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 18
                  },
                  "end": {
                    "line": 29,
                    "column": 81
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.dana90"],[],["loc",[null,[29,63],[29,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 18
                  },
                  "end": {
                    "line": 30,
                    "column": 83
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.dana180"],[],["loc",[null,[30,64],[30,83]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@2.0.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 16
                },
                "end": {
                  "line": 31,
                  "column": 16
                }
              },
              "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
              morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
              morphs[6] = dom.createMorphAt(fragment,13,13,contextualElement);
              return morphs;
            },
            statements: [
              ["block","x-option",[],["value",0,"class","xSelectBoxItem"],0,null,["loc",[null,[24,18],[24,108]]]],
              ["block","x-option",[],["value",7,"class","xSelectBoxItem"],1,null,["loc",[null,[25,18],[25,92]]]],
              ["block","x-option",[],["value",14,"class","xSelectBoxItem"],2,null,["loc",[null,[26,18],[26,94]]]],
              ["block","x-option",[],["value",30,"class","xSelectBoxItem"],3,null,["loc",[null,[27,18],[27,94]]]],
              ["block","x-option",[],["value",60,"class","xSelectBoxItem"],4,null,["loc",[null,[28,18],[28,94]]]],
              ["block","x-option",[],["value",90,"class","xSelectBoxItem"],5,null,["loc",[null,[29,18],[29,94]]]],
              ["block","x-option",[],["value",180,"class","xSelectBoxItem"],6,null,["loc",[null,[30,18],[30,96]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 14
              },
              "end": {
                "line": 32,
                "column": 14
              }
            },
            "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","x-select",[],["value",["subexpr","@mut",[["get","podesavanje.brojDana",["loc",[null,[23,34],[23,54]]]]],[],[]]],0,null,["loc",[null,[23,16],[31,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 18
                  },
                  "end": {
                    "line": 34,
                    "column": 99
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.nePrikazujObavestenje"],[],["loc",[null,[34,66],[34,99]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 18
                  },
                  "end": {
                    "line": 35,
                    "column": 96
                  }
                },
                "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","t",["sem.prikazujObavestenje"],[],["loc",[null,[35,65],[35,96]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@2.0.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 16
                },
                "end": {
                  "line": 36,
                  "column": 16
                }
              },
              "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["block","x-option",[],["value",false,"class","xSelectBoxItem"],0,null,["loc",[null,[34,18],[34,112]]]],
              ["block","x-option",[],["value",true,"class","xSelectBoxItem"],1,null,["loc",[null,[35,18],[35,109]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 14
              },
              "end": {
                "line": 37,
                "column": 14
              }
            },
            "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","x-select",[],["value",["subexpr","@mut",[["get","podesavanje.fgPrikazi",["loc",[null,[33,34],[33,55]]]]],[],[]]],0,null,["loc",[null,[33,16],[36,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 8
            },
            "end": {
              "line": 40,
              "column": 8
            }
          },
          "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"style","width: 30%;");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","boldFont");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              -\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"style","width: 50%;");
          dom.setAttribute(el2,"class","boldFont");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"style","width: 5%;");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
          morphs[1] = dom.createMorphAt(element1,3,3);
          morphs[2] = dom.createAttrMorph(element3, 'class');
          morphs[3] = dom.createMorphAt(element2,3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [5]),1,1);
          return morphs;
        },
        statements: [
          ["inline","t",[["get","podesavanje.keyGrupa",["loc",[null,[13,42],[13,62]]]]],[],["loc",[null,[13,38],[13,64]]]],
          ["inline","t",[["get","podesavanje.keyGrupaOpis",["loc",[null,[15,18],[15,42]]]]],[],["loc",[null,[15,14],[15,44]]]],
          ["attribute","class",["concat",["icon mif-traff ",["get","podesavanje.styleClass",["loc",[null,[18,44],[18,66]]]]]]],
          ["inline","t",[["get","podesavanje.keyNaziv",["loc",[null,[19,18],[19,38]]]]],[],["loc",[null,[19,14],[19,40]]]],
          ["block","if",[["get","podesavanje.fgPeriodicno",["loc",[null,[22,20],[22,44]]]]],[],0,1,["loc",[null,[22,14],[37,21]]]]
        ],
        locals: ["podesavanje","index"],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sem-podesavanje-obavestenja.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("table");
        dom.setAttribute(el4,"class","table striped hovered cell-hovered border bordered");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"class","button");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","mif-checkmark");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"class","button");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","mif-cross");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [1]);
        var element5 = dom.childAt(element4, [3, 1]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element5, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
        morphs[2] = dom.createElementMorph(element6);
        morphs[3] = dom.createMorphAt(element6,2,2);
        morphs[4] = dom.createElementMorph(element7);
        morphs[5] = dom.createMorphAt(element7,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.podesavanja_obavestenja"],[],["loc",[null,[5,28],[5,68]]]],
        ["block","each",[["get","model.podesavanja",["loc",[null,[10,16],[10,33]]]]],[],0,null,["loc",[null,[10,8],[40,17]]]],
        ["element","action",["save"],[],["loc",[null,[43,16],[43,33]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[43,85],[43,110]]]],
        ["element","action",["reset"],[],["loc",[null,[44,16],[44,34]]]],
        ["inline","t",["sem.resetujPodesavanja"],[],["loc",[null,[44,82],[44,112]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});