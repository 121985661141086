define('zaris-frontend/controllers/bz-zakoni', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service('i18n'),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('bazaZnanja.zakoni.naziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'vrsta',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('bazaZnanja.zakoni.vrsta'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});