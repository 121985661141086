define('zaris-frontend/templates/components/obr-obrazac10-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 10
                },
                "end": {
                  "line": 17,
                  "column": 60
                }
              },
              "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","t",["app.common.choose"],[],["loc",[null,[17,35],[17,60]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 19,
                    "column": 12
                  },
                  "end": {
                    "line": 19,
                    "column": 58
                  }
                },
                "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","item.naziv",["loc",[null,[19,43],[19,57]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@2.0.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 10
                },
                "end": {
                  "line": 20,
                  "column": 10
                }
              },
              "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["block","x-option",[],["value",["subexpr","@mut",[["get","item.idLzo",["loc",[null,[19,30],[19,40]]]]],[],[]]],0,null,["loc",[null,[19,12],[19,71]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 8
              },
              "end": {
                "line": 21,
                "column": 8
              }
            },
            "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","x-option",[],["value",null],0,null,["loc",[null,[17,10],[17,73]]]],
            ["block","each",[["get","selectItemsLzo",["loc",[null,[18,18],[18,32]]]]],[],1,null,["loc",[null,[18,10],[20,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 6
            },
            "end": {
              "line": 24,
              "column": 6
            }
          },
          "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","x-select",[],["value",["subexpr","@mut",[["get","modelObject.sifLzo.idLzo",["loc",[null,[16,26],[16,50]]]]],[],[]],"action","lzoSelected"],0,null,["loc",[null,[16,8],[21,21]]]],
          ["inline","input-validation",[],["showState",false,"type","hidden","validType","required","arg","8","value",["subexpr","@mut",[["get","modelObject.sifLzo.idLzo",["loc",[null,[23,92],[23,116]]]]],[],[]]],["loc",[null,[23,8],[23,118]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 6
            },
            "end": {
              "line": 26,
              "column": 6
            }
          },
          "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","input",[],["disabled","true","value",["subexpr","@mut",[["get","modelObject.sifLzo.naziv",["loc",[null,[25,38],[25,62]]]]],[],[]]],["loc",[null,[25,8],[25,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 98,
              "column": 4
            },
            "end": {
              "line": 125,
              "column": 4
            }
          },
          "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"style","width: 95%; min-height: 300px;");
          dom.setAttribute(el1,"class","content panelCentered");
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","component",["zaris-datatable"],["target",["subexpr","@mut",[["get","this",["loc",[null,[102,17],[102,21]]]]],[],[]],"content",["subexpr","@mut",[["get","preglediContent",["loc",[null,[103,18],[103,33]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumnsPregledi",["loc",[null,[104,18],[104,38]]]]],[],[]],"domainName","ObrObrazac10LzoPregledi","domainId","idObrazac10LzoPregledi","isNew",["subexpr","@mut",[["get","isNewPregledi",["loc",[null,[107,16],[107,29]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObjectPregledi",["loc",[null,[108,22],[108,41]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRowPregledi",["loc",[null,[109,22],[109,41]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshPregledi",["loc",[null,[110,18],[110,33]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"modalPanelShow",["subexpr","@mut",[["get","showModalPanelPregledi",["loc",[null,[114,25],[114,47]]]]],[],[]],"titleMsgKey","obrObrazac10.brisanjePreglediHeader","descYesNoMsgKey","obrObrazac10.brisanjePreglediPitanje","titleDeleteMsgKey","obrObrazac10.brisanjePreglediHeader","descDeleteQuestionMsgKey","obrObrazac10.brisanjePreglediPitanje","descDeleteSuccessMsgKey","obrObrazac10.brisanjePreglediUspesno","renderFilterArhivirano",false,"renderSearch",false],["loc",[null,[101,8],[122,10]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 141,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/obr-obrazac10-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.setAttribute(el2,"class","thin");
        dom.setAttribute(el2,"style","margin-top:20px");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-panel-content ui four column grid");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row zarisGridSmallPadding");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row zarisGridSmallPadding");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row zarisGridSmallPadding");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row zarisGridSmallPadding");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control textarea full-size");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-checkmark");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-cross");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [8]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element3, [5]);
        var element7 = dom.childAt(element3, [7]);
        var element8 = dom.childAt(element2, [16]);
        var element9 = dom.childAt(element2, [18]);
        var morphs = new Array(24);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [1, 1]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [3, 1]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [5, 1]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element6, [1, 1]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element6, [3, 1]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element6, [5, 1]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(element7, [5]),1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element2, [10]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element2, [12]),1,1);
        morphs[18] = dom.createMorphAt(element2,14,14);
        morphs[19] = dom.createElementMorph(element8);
        morphs[20] = dom.createMorphAt(element8,2,2);
        morphs[21] = dom.createElementMorph(element9);
        morphs[22] = dom.createMorphAt(element9,2,2);
        morphs[23] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",[["subexpr","if",[["get","isNew",["loc",[null,[6,18],[6,23]]]],"app.menu.licna_zastitna_oprema","obrObrazac10.evidencijaPregledaLicneZastitneOpreme"],[],["loc",[null,[6,14],[6,110]]]]],[],["loc",[null,[6,10],[6,112]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["obrObrazac10.licnaZastitnaOprema"],[],["loc",[null,[13,11],[13,51]]]],
        ["block","if",[["get","isNew",["loc",[null,[15,12],[15,17]]]]],[],0,1,["loc",[null,[15,6],[26,13]]]],
        ["inline","t",["obrObrazac10.inputVrsta"],[],["loc",[null,[34,17],[34,48]]]],
        ["inline","t",["obrObrazac10.inputFabrickiBroj"],[],["loc",[null,[38,17],[38,55]]]],
        ["inline","t",["obrObrazac10.inputGodinaProizvodnje"],[],["loc",[null,[42,17],[42,60]]]],
        ["inline","input-validation",[],["showState",false,"validType","required","value",["subexpr","@mut",[["get","modelObject.vrsta",["loc",[null,[49,72],[49,89]]]]],[],[]]],["loc",[null,[49,10],[49,91]]]],
        ["inline","input-validation",[],["showState",false,"validType","required","value",["subexpr","@mut",[["get","modelObject.fabrickiBroj",["loc",[null,[53,72],[53,96]]]]],[],[]]],["loc",[null,[53,10],[53,98]]]],
        ["inline","input-validation",[],["showState",false,"type","number","validType","required","value",["subexpr","@mut",[["get","modelObject.godinaProizvodnje",["loc",[null,[57,86],[57,115]]]]],[],[]]],["loc",[null,[57,10],[57,117]]]],
        ["inline","t",["obrObrazac10.inputDatumNabavke"],[],["loc",[null,[64,17],[64,55]]]],
        ["inline","t",["obrObrazac10.inputRok"],[],["loc",[null,[68,17],[68,46]]]],
        ["inline","t",["obrObrazac10.inputVaziDo"],[],["loc",[null,[72,17],[72,49]]]],
        ["inline","pikaday-input",[],["value",["subexpr","@mut",[["get","modelObject.datumNabavke",["loc",[null,[79,32],[79,56]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[79,10],[79,78]]]],
        ["inline","input",[],["disabled","true","value",["subexpr","@mut",[["get","modelObject.rok",["loc",[null,[83,40],[83,55]]]]],[],[]]],["loc",[null,[83,10],[83,57]]]],
        ["inline","pikaday-input",[],["disabled","true","value",["subexpr","@mut",[["get","modelObject.vaziDo",["loc",[null,[87,48],[87,66]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[87,10],[87,88]]]],
        ["inline","t",["obrObrazac10.napomena"],[],["loc",[null,[93,11],[93,40]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","modelObject.napomena",["loc",[null,[95,34],[95,54]]]]],[],[]]],["loc",[null,[95,8],[95,57]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["get","isNew",["loc",[null,[98,20],[98,25]]]]],[],["loc",[null,[98,15],[98,26]]]],["subexpr","equals",[["get","selectedDomainName",["loc",[null,[98,35],[98,53]]]],"ObrObrazac10Lzo"],[],["loc",[null,[98,27],[98,72]]]]],[],["loc",[null,[98,10],[98,73]]]]],[],2,null,["loc",[null,[98,4],[125,11]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[127,25],[127,30]]]],"create","update"],[],["loc",[null,[127,21],[127,49]]]]],[],["loc",[null,[127,12],[127,51]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[127,103],[127,128]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[128,12],[128,38]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[128,86],[128,111]]]],
        ["inline","obr-obrazac10-pregledi-modal",[],["id","obrObrazac10PreglediModal","target",["subexpr","@mut",[["get","this",["loc",[null,[134,11],[134,15]]]]],[],[]],"selectedLzoRow",["subexpr","@mut",[["get","modelObject",["loc",[null,[135,19],[135,30]]]]],[],[]],"isNewPregledi",["subexpr","@mut",[["get","isNewPregledi",["loc",[null,[136,18],[136,31]]]]],[],[]],"modelObjectPregledi",["subexpr","@mut",[["get","modelObjectPregledi",["loc",[null,[137,24],[137,43]]]]],[],[]],"refreshPregledi",["subexpr","@mut",[["get","refreshPregledi",["loc",[null,[138,20],[138,35]]]]],[],[]],"modalPanelShowPregledi",["subexpr","@mut",[["get","showModalPanelPregledi",["loc",[null,[139,27],[139,49]]]]],[],[]]],["loc",[null,[132,0],[140,2]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});