define('zaris-frontend/components/sif-obuka-dokument-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'sifObukaDokumentModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",
    "data-type": "modal",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    fileUploaded: "",

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        // validacija fajla ako je prazan postavi se "" - nece proci validaciju
        if (document.getElementById("obuka_dokument").files.length == 0) {
          this.set('fileUploaded', "");
        } else {
          this.set('fileUploaded', "OK"); // unet je dokument - fileUploaded treba da se postavi na bilo sta osim ""
        }
        Ember['default'].run.schedule("afterRender", this, function () {
          var _this = this;

          if (this.get('validate').validate(this)) {
            var outter;
            var modelObject;
            var formData;
            var model;

            (function () {
              outter = _this;

              var i18n = _this.get('i18n');

              _this.set('modelObject.sifObuka', {});
              _this.set('modelObject.sifObuka.idObuka', _this.get('customId'));

              if (!_this.get('modelObject.sifVrstaDokumenta.idVrstaDokumenta')) {
                modelObject = _this.get('modelObject');

                delete modelObject.sifVrstaDokumenta;
              }

              formData = new FormData();

              formData.append('file', $('input[type=file]')[0].files[0]);
              formData.append('sifObukaDokument', JSON.stringify(_this.get('modelObject')));

              model = Models['default'].SifObukaDokument.create({ errorHandlerController: _this });

              model.uploadFile("uploadDokument", formData, _this.get('login').getAccessToken(), 'POST', function (o) {
                $.Notify({
                  caption: i18n.t("commonPanel.dodavanjeHeader").string,
                  content: i18n.t("commonPanel.dodavanjeUspesno").string,
                  keepOpen: false,
                  type: 'success'
                });
                outter.toggleProperty('refresh');
                outter.closePanel();
              }, function (e) {
                $.Notify({
                  caption: i18n.t('sifNivoiRizika.dodavanjeHeader').string,
                  content: i18n.t(e.responseJSON.messages[0]).string,
                  keepOpen: true,
                  type: 'alert'
                });
              });
            })();
          }
        });
      },

      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var modelObject;
          var sifObukaDokumentModel;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');

            _this2.set('modelObject.sifObuka', {});
            _this2.set('modelObject.sifObuka.idObuka', _this2.get('customId'));

            if (!_this2.get('modelObject.sifVrstaDokumenta.idVrstaDokumenta')) {
              modelObject = _this2.get('modelObject');

              delete modelObject.sifVrstaDokumenta;
            }

            sifObukaDokumentModel = Models['default'].SifObukaDokument.create({ errorHandlerController: _this2 });

            sifObukaDokumentModel.update(_this2.get('modelObject'), _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("commonPanel.azuriranjeHeader").string,
                content: i18n.t("commonPanel.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifNivoiRizika.azuriranjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      if (this.get('modelObject').sifVrstaDokumenta == undefined || this.get('modelObject').sifVrstaDokumenta == null) {
        this.set('modelObject.sifVrstaDokumenta', {});
        this.set('modelObject.sifVrstaDokumenta.idVrstaDokumenta', null);
      }
      this.set('fileUploaded', ""); // sluzi za validaciju fajla
      document.getElementById('form_id1').reset();
      Ember['default'].showModalPanel('#sifObukaDokumentModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('#sifObukaDokumentModal').data('dialog').close();
    }
  });

});