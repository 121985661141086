define('zaris-frontend/components/sif-revers-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    objectId: null,
    servicePath: null,
    refresh: false,
    modalPanelShow: false,

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {

      return this.get('idRowSelected') !== 0 && this.get('selectedRow')[this.get('domainId')] !== null;
    }),

    isSkeniranExist: Ember['default'].computed('selectedRow', function () {

      return this.get('selectedRow.skeniranFileName');
    }),

    didInsertElement: function didInsertElement() {
      this.$('.fileUploadModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      preuzmi: function preuzmi() {

        var pObj = {};
        var outter = this;
        var loginService = this.get('loginService');
        var domainId = this.get('domainId');
        pObj.p_id_revers = this.get('selectedRow')[domainId];

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'revers_za_lzo';
        izvestajiDTO.reportParameters = pObj;

        this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('login').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      initUploadSkeniran: function initUploadSkeniran() {
        var domainId = this.get('domainId');
        this.set('objectId', this.get('selectedRow')[domainId]);
        this.set('servicePath', 'uploadRevers');
        this.toggleProperty('fileUploadModalPanelShow');
      },

      downloadSkeniran: function downloadSkeniran() {
        var outter = this;
        var i18n = this.get('i18n');
        var domainId = this.get('domainId');

        var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });

        model.getById('downloadRevers', this.get('selectedRow')[domainId], this.get('login').getAccessToken(), 'GET', function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      deleteSkeniran: function deleteSkeniran() {
        var outter = this;
        var i18n = this.get('i18n');
        var domainId = this.get('domainId');

        var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });
        model.getById('deleteRevers', this.get('selectedRow')[domainId], this.get('login').getAccessToken(), 'POST', function (o) {
          $.Notify({
            caption: i18n.t("fileUpload.brisanjeHeader").string,
            content: i18n.t("fileUpload.brisanjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      }

    }
  });

});