define('zaris-frontend/components/sacuvani-datatable', ['exports', 'ember', 'ember-table/components/ember-table', 'zaris-frontend/models/models', 'zaris-frontend/conf'], function (exports, Ember, EmberTable, Models, conf) {

    'use strict';

    exports['default'] = EmberTable['default'].extend({
        classNames: ['zaris-table-tables-container'],
        classNameBindings: ['enableContentSelection:zaris-table-content-selectable'],

        //name of domain for CRUD
        domainName: 'domainName',
        //name of domain id
        domainId: 'domainId',
        rowHeight: 25,

        //id for yes/no panel
        idYesNoDialog: Ember['default'].computed('domainName', function () {
            return 'idYesNoDialog' + this.get('domainName');
        }),
        //id for ok modal panel
        idDialogOk: Ember['default'].computed('domainName', function () {
            return 'idDialogOk' + this.get('domainName');
        }),
        //panel type for ok panel(ERROR => 'alert', SUCCESS => 'success', WARN: 'warning', 'INFO' => 'info')
        modalOkType: 'alert',
        //defines if modal panel is in mode for create or update
        isNew: true,

        //messages for deleting entity
        titleDeleteMsgKey: 'obrazacSacuvani.brisanjeHeader',
        descDeleteQuestionMsgKey: 'obrazacSacuvani.brisanjePitanje',
        descDeleteSuccessMsgKey: 'obrazacSacuvani.brisanjeUspesno',

        //id of modal panel for add/edit
        modalPanelShow: 'modalPanelShow',
        //id of modal panel for add
        modalPanelShowNew: 'modalPanelShowNew',
        //id of modal panel for edit
        modalPanelShowEdit: 'modalPanelShowEdit',

        //array for generating pages component
        totalPages: Em.A(),
        //content
        content: Ember['default'].A(),
        //current page
        page: 0,
        //results per page
        perPage: 10,
        //initial sort order - DO NOT CHANGE
        sortAscending: true,
        //selected sort column
        sortColumn: null,
        //filter value - bound to input component
        filterValue: "",
        //archived - filter by fg_arhivirano column
        isArchived: "",
        //filter by custom id
        customId: null,
        //id of currently selected row - ZERO if nothing selected
        idRowSelected: 0,
        //selected datatable element
        selectedRow: {},

        //toggle this variable to refresh data - value is not important
        refresh: false,

        //disabled create option in datatable toolbar
        disabledCreate: false,

        //disabled delete option in datatable toolbar
        disabledDelete: false,

        //readOnly mode - create, update and delete disabled
        readOnly: false,

        //flag that defines if control buttons for add, create and delete are rendered
        renderControlButtons: true,

        //flag that defines if select for filtering by fg_arhivirano is rendered
        renderFilterArhivirano: true,

        //flag that defines if search box should be rendered
        renderSearch: true,

        // render unos
        renderUnos: true,

        // flag that defines if search box should be only thing that is rendered
        renderOnlySearch: false,

        //flag that defines if button link to the code table should be rendered
        renderSifarnikLink: false,
        //title to the code table link button
        sifarnikLinkTitle: null,
        //route to the code table
        sifarnikLinkRoute: null,

        // render create button
        renderCreate: true,

        // render update button
        renderUpdate: true,

        // render delete button
        renderDelete: true,

        //flag that defines if button for saving form should be rendered
        renderSacuvajObrazac: false,
        //flag that defines if button for routing to sacuvaniObrasci should be rendered
        renderSacuvaniObrasci: false,
        //flag that defines if button for routing to izvestaji should be rendered
        renderIzvestaji: false,
        //flag that defines if button for routing to sifarnici should be rendered
        renderSifarnici: false,

        //injected login service for getting oauth token
        loginService: Ember['default'].inject.service('login'),

        //injected routing service
        routing: Ember['default'].inject.service('-routing'),

        // returns whether creation of new sub-elements is allowed
        createAllowed: Ember['default'].computed(function () {
            var readOnly = this.get('readOnly');
            var disabledCreate = this.get('disabledCreate');

            return !readOnly && !disabledCreate;
        }),

        // returns whether update of element is allowed
        updateAllowed: Ember['default'].computed('selectedRow', function () {
            var isRowSelected = this.get('isRowSelected');
            var readOnly = this.get('readOnly');

            return isRowSelected && !readOnly;
        }),

        // returns whether delete of element is allowed
        deleteAllowed: Ember['default'].computed('selectedRow', function () {
            var isRowSelected = this.get('isRowSelected');
            var readOnly = this.get('readOnly');
            var disabledDelete = this.get('disabledDelete');

            return isRowSelected && !readOnly && !disabledDelete;
        }),

        //init function called before opening modal panel
        initModal: function initModal() {},

        //transforms json object to Ember object
        createTableItem: function createTableItem(event) {
            var row = Ember['default'].Object.create({ errorHandlerController: this });
            row.setProperties(event);

            return row;
        },

        searchInputId: Ember['default'].computed('domainName', function () {
            return 'filterDatatable' + this.get('domainName');
        }),

        //tells if any row is selected
        isRowSelected: Ember['default'].computed('idRowSelected', function () {
            return this.get('idRowSelected') !== 0;
        }),

        cellNumCssClass: Ember['default'].computed('', function () {
            var n = (this.get('renderControlButtons') ? 3 : 0) + (this.get('renderSifarnikLink') ? 1 : 0) + (this.get('renderFilterArhivirano') ? 1 : 0) + (this.get('renderSearch') ? 1 : 0) + (this.get('renderSacuvajObrazac') ? 1 : 0) + (this.get('renderSacuvaniObrasci') ? 1 : 0) + (this.get('renderIzvestaji') ? 1 : 0) + (this.get('renderSifarnici') ? 1 : 0);

            return 'cells' + n;
        }),

        //listens for param changes and triggers table data update upon filter, sort or paging change
        tableStateChanged: Ember['default'].observer('page', 'sortColumn', 'sortAscending', 'filterValue', 'isArchived', 'refresh', 'refreshWoResetSelection', 'domainName', function () {
            Ember['default'].run.once(this, 'fetchTableData');
        }),

        radnoMesto: undefined,

        //gets data for one datatable page
        fetchTableData: (function () {
            var loginService = this.get('loginService');
            var page,
                url,
                i,
                self = this;
            var model = new Models['default'][this.domainName]();
            page = this.get('page');
            var content = this.get('content');
            content.clear();

            url = conf['default'].serverBaseUrl + '/' + model.getPathFindPaged() + "?page=" + page + "&size=" + this.get('perPage') + "&idPravnoLice=" + localStorage.idPravnoLice + "&value=" + this.get('filterValue') + "&arhivirano=" + this.get('isArchived') + (this.get('radnoMesto') != undefined ? "&radnoMesto=" + this.get('radnoMesto') : "") + "&access_token=" + loginService.getAccessToken() + (this.get('customId') !== null ? "&id=" + this.get('customId') : "");
            if (this.get('sortColumn')) {
                var asc = this.get('sortAscending');
                url = url + '&sort=' + this.get('sortColumn.key') + (asc ? ',asc' : ',desc');
            }
            self.startSpinner();
            $.ajax({
                url: url,
                type: model.getVerbFindPaged(),
                accepts: 'application/json',
                crossDomain: true,
                async: true,
                headers: conf['default'].corsHeaders,
                success: function success(data) {
                    self.stopSpinner();
                    var paging = Em.A();
                    for (var i = 0; i < data.page.totalPages; i++) paging.push({});
                    self.set('totalPages', paging);

                    data.content.forEach(function (entity) {
                        var a = self.createTableItem(entity);
                        content.pushObject(a);
                    });
                },
                error: function error(e) {
                    self.stopSpinner();
                    console.log('DEBUG: GET Failed', e);
                }
            });
        }).on('init'),

        startSpinner: function startSpinner() {
            if (isNaN(localStorage.activeSpinnerRequests)) localStorage.activeSpinnerRequests = 0;

            var n = parseInt(localStorage.activeSpinnerRequests);
            n = n + 1;
            localStorage.activeSpinnerRequests = n;

            if (n === 1) {
                $('#appSpinner').data('dialog').open();
            }
        },
        stopSpinner: function stopSpinner() {
            var n = parseInt(localStorage.activeSpinnerRequests);
            n = n - 1;
            localStorage.activeSpinnerRequests = n;

            if (n === 0) {
                $('#appSpinner').data('dialog').close();
            }
        },

        actions: {
            initDownload: function initDownload() {
                var outter = this;
                var i18n = this.get('i18n');
                var domainId = this.get('domainId');
                console.log(domainId, this.get('domainName'), this.get('selectedRow'));
                var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });

                model.getById('downloadSacuvanObrazac', this.get('selectedRow')[domainId], this.get('loginService').getAccessToken(), 'GET', function (hash) {
                    console.log(hash);

                    var link = document.createElement('a');
                    link.href = model.generateDownloadLink(hash);

                    document.body.appendChild(link);
                    link.click();
                }, function (e) {});
            },
            rowDidClick: function rowDidClick(row, event) {
                var domainId = this.get('domainId');
                this._super(row, event);
                this.set('idRowSelected', row.get('content')[domainId]);
                this.set('selectedRow', row.get('content'));
            },
            nextPage: function nextPage() {
                this.resetSelection();
                this.incrementProperty('page');
            },
            previousPage: function previousPage() {
                this.resetSelection();
                this.decrementProperty('page');
            },
            changePage: function changePage(pageNum) {
                this.resetSelection();
                this.set('page', pageNum);
            },
            filterArhivirano: function filterArhivirano(value, component) {
                this.resetSelection();
                this.set('isArchived', value);
            },
            filter: function filter() {
                this.resetSelection();
                this.set('filterValue', $('#' + this.get('searchInputId')).val());
            },
            sortByColumn: function sortByColumn(column) {
                if (!column.get('supportSort')) {
                    return;
                }

                if (this.get('sortColumn') !== column) {
                    //console.log('New column');
                    this.get('columns').setEach('sorted', false);
                    column.set('sorted', true);
                    column.set('sortAscending', true);
                    this.set('sortAscending', true);
                    this.set('sortColumn', column);
                } else if (this.get('sortColumn') === column) {
                    // Handle disabling sorts
                    if (this.get('sortAscending') === false) {
                        //console.log('disabling sort');
                        this.set('sortColumn', undefined);
                        this.set('sortAscending', true);
                        this.get('columns').setEach('sorted', false);
                        column.set('sorted', false);
                    } else {
                        //console.log('change sort');
                        this.toggleProperty('sortAscending');
                        column.set('sortAscending', this.get('sortAscending'));
                    }
                }

                this.resetSelection();
            },

            initDelete: function initDelete() {
                //prepare and show yes/no modal panel
                this.get("target").send('openYesNoModal', 'idYesNoDialog', this.get('titleDeleteMsgKey'), this.get('descDeleteQuestionMsgKey'), this, 'deleteConfirm');
            },
            initCreate: function initCreate() {
                this.set('isNew', true);

                // ukoliko postoji akcija u kontroleru za kreiranje specificnog modelObjecta
                // prosledi joj se ovaj objekat pa ona tamo kreira strukturu - initModelObject akcija u kontroleru
                var specModelObject = {};
                this.sendAction("onModalCreate", specModelObject);
                this.set('modelObject', specModelObject);

                // dovlacenje liste za select - akcija u kontroleru
                this.sendAction("onModalOpen", this.get('modelObject'));

                this.handleWindowResize({ target: { id: 'undefined' } });

                this.get('modalPanelShow') === 'modalPanelShow' ? this.toggleProperty('modalPanelShowNew') : this.toggleProperty('modalPanelShow');
            },
            createConfirm: function createConfirm() {
                console.log('CONFIRM CREATE');
            },
            initUpdate: function initUpdate() {
                this.set('isNew', false);
                var selected = jQuery.extend(true, {}, this.get('selectedRow'));
                this.set('modelObject', selected);

                // dovlacenje liste za select - akcija u kontroleru
                this.sendAction("onModalOpen");

                this.get('modalPanelShow') === 'modalPanelShow' ? this.toggleProperty('modalPanelShowEdit') : this.toggleProperty('modalPanelShow');
            },
            updateConfirm: function updateConfirm() {
                console.log('UPDATE CONFIRM');
            }
        },

        deleteConfirm: function deleteConfirm(self) {
            var loginService = self.get('loginService');
            var outter = self;

            var model = new Models['default'][self.domainName]();
            model['delete'](self.get('idRowSelected'), loginService.getAccessToken(), function () {
                //call ws to get new results, without deleted one
                outter.toggleProperty('refresh');

                //prepare and show success ok panel
                outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), outter.get('descDeleteSuccessMsgKey'), 'success');

                //hiding yes/no panel
                outter._controller.send('closeOkModal', 'idYesNoDialog');

                //reseting selection, as selected item does not exist anymore
                //outter.resetSelection();
            }, function (e) {
                //prepare and show error ok panel
                outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), e.responseJSON.messages[0], 'alert');

                //hide yes/no modal panel
                outter._controller.send('closeOkModal', 'idYesNoDialog');
            });
        },

        refreshDidChange: Ember['default'].observer('refresh', function () {
            Ember['default'].run.once(this, 'resetSelection');
        }),

        resetSelection: function resetSelection() {
            this.set('idRowSelected', 0);
            this.set('selectedRow', {});
        }
    });

});