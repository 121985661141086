define('zaris-frontend/components/obr-obrazac1-unos-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'obrObrazac1UnosModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",

    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    didInsertElement: function didInsertElement() {
      this._super();
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {
      /* dovlacenje itema za select listu*/
      var loginService = this.get('login');

      var sifNivoRizikaModel = Models['default'].SifNivoRizika.create({ errorHandlerController: this });
      this.set('selectItemsNivoRizika', sifNivoRizikaModel.findAllGetResult(loginService.getAccessToken()));
    },

    initCustomSelectItems: function initCustomSelectItems() {
      var loginService = this.get('login');

      var sifOpasnostiStetnostiModel = Models['default'].SifOpasnostiStetnostiTree.create({ errorHandlerController: this });
      this.set('selectItemsOpasnostiStetnosti', sifOpasnostiStetnostiModel.getResultById(this.get('domainName'), this.get('selektovanoRadnoMesto.' + this.get('referenceDomainId')), loginService.getAccessToken()));
    },

    initCreate: function initCreate() {
      var obj = {};
      var referenceDomainNameLowercase = this.get('referenceDomainName').charAt(0).toLowerCase() + this.get('referenceDomainName').slice(1);
      obj[referenceDomainNameLowercase] = {};
      obj[referenceDomainNameLowercase][this.get('referenceDomainId')] = this.get('customId');

      obj.sifOpasnostiStetnosti = {};
      obj.sifOpasnostiStetnosti.idOpasnostiStetnosti = this.get('selectItemsOpasnostiStetnosti') !== null && this.get('selectItemsOpasnostiStetnosti')[0] !== null ? this.get('selectItemsOpasnostiStetnosti')[0].idOpasnostiStetnosti : 0;

      obj.sifNivoRizika = {};
      obj.sifNivoRizika.idNivoRizika = this.get('selectItemsNivoRizika') !== null && this.get('selectItemsNivoRizika')[0] !== null ? this.get('selectItemsNivoRizika')[0].idNivoRizika : 0;

      this.set('modelObjectUnosObrazac1', obj);
    },

    initUpdate: function initUpdate() {
      var selected = jQuery.extend(true, {}, this.get('selectedRow'));
      this.set('modelObjectUnosObrazac1', selected);
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      merge: function merge() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var isNew;
          var obj;
          var obrazacModel;

          (function () {
            outter = _this;
            isNew = _this.get('isNew');

            var i18n = _this.get('i18n');

            obj = _this.get('modelObjectUnosObrazac1');
            obrazacModel = Models['default'][_this.get('domainName')].create({ errorHandlerController: _this });

            obrazacModel.create(obj, _this.get('login').getAccessToken(), function () {
              if (isNew) {
                $.Notify({
                  caption: i18n.t('obrazac1.dodavanjeHeader').string,
                  content: i18n.t('obrazac1.dodavanjeUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
              } else {
                $.Notify({
                  caption: i18n.t('obrazac1.azuriranjeHeader').string,
                  content: i18n.t('obrazac1.azuriranjeUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
              }

              outter.closePanel();
              outter.toggleProperty('refreshObrazac1Modal');
              outter.toggleProperty('refreshBaseTable');
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.initCustomSelectItems();

      if (this.get('isNew')) {
        this.initCreate();
      } else {
        this.initUpdate();
      }

      Ember['default'].showModalPanel('.obrObrazac1UnosModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.obrObrazac1UnosModal').data('dialog').close();
    }
  });

});