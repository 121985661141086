define('zaris-frontend/controllers/obr-obrazac03-sacuvani', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    refreshObr03: false,

    contentPovredniList: Em.A(),

    componentTab1: 'sacuvani-datatable',

    actions: {
      refreshTable: function refreshTable() {
        this.toggleProperty('refreshObr03');
      },
      openTab1: function openTab1() {
        this.set('componentTab1', 'sacuvani-datatable');
        this.set('componentTab2', null);
      },

      openTab2: function openTab2() {
        this.set('componentTab1', null);
        this.set('componentTab2', 'sacuvani-datatable');
      }
    },

    tableColumnsPovredniList: Ember['default'].computed(function () {
      var self = this;

      var imePrezimeColumn = ColumnDefinition['default'].create({
        key: 'sifLicaByIdLicePovredjeni',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.sacuvani.povredniList.imePrezimePovredjenog'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).ime + " " + row.get(this.key).prezime;
        }
      });

      var dateColumn = ColumnDefinition['default'].create({
        key: 'vremePovrede',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.sacuvani.povredniList.datumPovrede'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      });

      var timeColumn = ColumnDefinition['default'].create({
        key: 'dobaDanaSat',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.sacuvani.povredniList.vremePovrede'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.intToHour(row.get(this.key));
        }
      });

      var vrstaPovredeColumn = ColumnDefinition['default'].create({
        key: 'ukupanBrojPovredjenih',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.sacuvani.povredniList.vrstaPovrede'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) > 1 ? "Kolektivna" : "Pojedinacna";
        }
      });

      var tezinaPovredeColumn = ColumnDefinition['default'].create({
        key: 'fgTeskaPovreda',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.sacuvani.povredniList.ocenaTezinePovrede'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return 'Nije izabrano';
          return row.get(this.key) ? "Teska" : "Laka";
        }
      });

      var scannedColumn = ColumnDefinition['default'].create({
        key: 'skeniranFileName',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac03.sacuvani.povredniList.skeniran'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) != null) {
            return Ember['default'].String.htmlSafe('<div class="circle bg-emerald" />');
          } else {
            return Ember['default'].String.htmlSafe('<div class="circle bg-red" />');
          }
        }
      });

      return [imePrezimeColumn, dateColumn, timeColumn, vrstaPovredeColumn, tezinaPovredeColumn, scannedColumn];
    }),

    tableColumns: Ember['default'].computed(function () {
      var self = this;

      var idColumn = ColumnDefinition['default'].create({
        key: 'idObrazac03Sacuvan',
        savedWidth: 50,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.id'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });

      var nameColumn = ColumnDefinition['default'].create({
        key: 'sysKorisnik',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.korisnik'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get('sysKorisnik.ime') + ' ' + row.get('sysKorisnik.prezime');
        }
      });

      var dateTimeColumn = ColumnDefinition['default'].create({
        key: 'datumVreme',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.datumVreme'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateTimeOutputFormat(row.get(this.key));
        }
      });

      var scannedColumn = ColumnDefinition['default'].create({
        key: 'skeniranFileName',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.skeniran'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) != null) {
            return Ember['default'].String.htmlSafe('<div class="circle bg-emerald" />');
          } else {
            return Ember['default'].String.htmlSafe('<div class="circle bg-red" />');
          }
        }
      });

      return [idColumn, nameColumn, dateTimeColumn, scannedColumn];
    })

  });

});