define('zaris-frontend/controllers/sif-opasne-materije', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'idOpasnaMaterija',
        savedWidth: 50,
        supportSort: true,
        headerCellName: 'ID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Naziv',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'hemijskoIme',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Hemijsko ime',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'un',
        savedWidth: 75,
        supportSort: true,
        headerCellName: 'UN',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'adr',
        savedWidth: 75,
        supportSort: true,
        headerCellName: 'ADR',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'rid',
        savedWidth: 75,
        supportSort: true,
        headerCellName: 'RID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'cas',
        savedWidth: 75,
        supportSort: true,
        headerCellName: 'CAS',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnaMaterijaKlasas',
        savedWidth: 250,
        supportSort: false,
        headerCellName: 'Klasa opasne materije',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var res = '';
          var klase = row.get(this.key);
          klase.forEach(function (val, index) {
            if (index !== 0) {
              res += ', ';
            }
            res += val.xsifKlaseOpasnihMaterija.naziv;
          });

          return res;
        }
      }));

      return columns;
    })
  });

});