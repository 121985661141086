define('zaris-frontend/components/sif-revers-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'sifReversModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentObuke: Em.A(),
    myModalPanelShow: false,
    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.sifReversLzoModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      }

    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshRevers');

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.sifReversModal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshRevers', function () {
      this.toggleProperty('refreshWoResetSelection');
    }),

    closePanel: function closePanel() {
      $('.sifReversModal').data('dialog').close();
    },

    tableReversPregled: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'datum',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRevers.datum'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'skeniranFileName',
        savedWidth: 250,
        supportSort: true,
        headerCellName: 'Skeniran',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) != null) {
            return Ember['default'].String.htmlSafe('<div class="circle bg-emerald" />');
          } else {
            return Ember['default'].String.htmlSafe('<div class="circle bg-red" />');
          }
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifNivoiRizika.inputNapomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});