define('zaris-frontend/components/sys-pravno-lice-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'sysPravnoLiceModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this._super();
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {
      /* dovlacenje itema za select listu*/
      var loginService = this.get('login');
      var xsysPravnoLiceModel = Models['default'].XsysPravnoLice.create({ errorHandlerController: this });
      this.set('selectItems', xsysPravnoLiceModel.findAllGetResult(loginService.getAccessToken()));
    },

    initCreate: function initCreate() {
      var obj = {};
      obj.idPravnoLice = 0 + parseInt(localStorage.idPravnoLice);
      this.set('modelObject', obj);
    },

    actions: {
      odaberiPravnoLice: function odaberiPravnoLice() {
        var idPravnoLice = this.get('modelObject.idPravnoLice');
        localStorage.idPravnoLice = idPravnoLice;

        var result = $.grep(this.get('selectItems'), function (e) {
          return e.idPravnoLice == idPravnoLice;
        });
        if (result !== null) {
          console.log(result[0].naziv);
          localStorage.pravnoLiceNaziv = result[0].naziv;
        }

        this.closePanel();
        window.location.reload();
      },

      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.initCreate();

      Ember['default'].showModalPanel('.sysPravnoLiceModal');
    }),

    closePanel: function closePanel() {
      $('.sysPravnoLiceModal').data('dialog').close();
    }
  });

});