define('zaris-frontend/components/modal-dokumentacija-yes-no', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    id: 'modalDokumentacijaYesNo',
    classNames: ['zarisModalPanel', 'dialog', 'zarisYesNoModalPanel', 'modalPanelLevelYesNo', 'panelHidden'],
    classNameBindings: ['messageType'],
    attributeBindings: ['data-role', 'data-close-button', 'data-windows-style', 'data-overlay', 'data-overlay-color'],
    "data-role": 'dialog',
    "data-close-button": true,
    "data-windows-style": true,
    "data-overlay": true,
    "data-overlay-color": "op-dark yes-no-dialog",

    messageType: 'success',

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      Ember['default'].showModalPanel('#' + this.get('id'));
    }),

    gotoTab2: function gotoTab2() {
      // nadji link na tab2 i klikni na njega
      var link = document.getElementById("a_frame2");
      link.click();
    },

    closeModal: function closeModal() {
      $('#' + this.get('id')).data('dialog').close();
    },

    closeOutterModal: function closeOutterModal() {
      // zatvara okruzujuci modal ukoliko je izabrano ne
      this.get('outterComponent').closePanel();
    },

    actions: {

      closeModalAction: function closeModalAction() {
        this.closeModal();
        this.closeOutterModal();
      },

      modalConfirm: function modalConfirm() {
        this.gotoTab2();
        this.closeModal();
      }
    }
  });

});