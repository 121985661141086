define('zaris-frontend/controllers/sif-elementi-pregleda', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/models/models'], function (exports, Ember, ColumnDefinition, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service('i18n'),

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifElementiPregleda.sifra'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        canAutoResize: true,
        minWidth: 350,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifElementiPregleda.elementPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'intervalMeseci',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifElementiPregleda.interval'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'intervalMeseciPropisan',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifElementiPregleda.propisanInterval'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});