define('zaris-frontend/components/obr-obrazac07-new-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac07NewModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    idRowSelectedRmRms: 0,
    radnoMestoIzborContent: Em.A(),
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        var outter = this;
        var i18n = this.get('i18n');

        var obj = {};
        obj[this.get('selectedReferencedDomain').charAt(0).toLowerCase() + this.get('selectedReferencedDomain').slice(1)] = this.get('selectedRmRms');

        var obrazacModel = Models['default'][this.get('selectedDomain')].create({ errorHandlerController: this });
        obrazacModel.create(obj, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('obrazac1.dodavanjeHeader').string,
            content: i18n.t('obrazac1.dodavanjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refreshBaseTable');
          outter.closePanel();
        }, function (e) {
          $.Notify({
            caption: i18n.t('obrazac1.dodavanjeHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: true,
            type: 'alert'
          });
        });
      }
    },

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelectedRmRms', function () {
      return this.get('idRowSelectedRmRms') !== 0;
    }),

    openPanel: Ember['default'].observer('modalPanelShowNew', function () {

      this.set('modelObject', {});

      this.set('componentName', 'zaris-datatable');

      Ember['default'].showModalPanel('.obrObrazac07NewModal');
    }),

    closePanel: function closePanel() {
      $('.obrObrazac07NewModal').data('dialog').close();
    },

    tableIzborRmRmsColumns: Ember['default'].computed('selectedDomainName', function () {
      var columns = [];

      if (this.get('selectedDomainName') === 'radnoMesto') {

        columns.push(ColumnDefinition['default'].create({
          key: 'sifra',
          savedWidth: 200,
          supportSort: true,
          headerCellName: 'Šifra',
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));

        columns.push(ColumnDefinition['default'].create({
          key: 'naziv',
          savedWidth: 200,
          supportSort: true,
          headerCellName: 'Naziv',
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));
      } else {
        columns.push(ColumnDefinition['default'].create({
          key: 'radnoMestoNaziv',
          savedWidth: 200,
          supportSort: true,
          headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.radnoMestoNaziv'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));
        columns.push(ColumnDefinition['default'].create({
          key: 'organizacionaJedinicaNaziv',
          savedWidth: 200,
          supportSort: true,
          headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.organizacionaJedinicaNaziv'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            return row.get(this.key);
          }
        }));
      }

      return columns;
    })

  });

});