define('zaris-frontend/controllers/obr-obrazac1-sacuvani', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    refreshObr01: false,

    radnoMesto: true,

    actions: {
      refreshTable: function refreshTable() {
        this.toggleProperty('refreshObr01');
      }
    },

    tableColumns: Ember['default'].computed(function () {
      var self = this;

      var idColumn = ColumnDefinition['default'].create({
        key: 'idObrazac01Sacuvan',
        savedWidth: 50,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.id'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });

      var nameColumn = ColumnDefinition['default'].create({
        key: 'sysKorisnik',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.korisnik'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get('sysKorisnik.ime') + ' ' + row.get('sysKorisnik.prezime');
        }
      });

      var dateTimeColumn = ColumnDefinition['default'].create({
        key: 'datumVreme',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.datumVreme'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateTimeOutputFormat(row.get(this.key));
        }
      });

      var scannedColumn = ColumnDefinition['default'].create({
        key: 'skeniranFileName',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrazacSacuvani.skeniran'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {

          if (row.get(this.key) != null) {
            return Ember['default'].String.htmlSafe('<div class="circle bg-emerald" />');
          } else {
            return Ember['default'].String.htmlSafe('<div class="circle bg-red" />');
          }
        }
      });

      return [idColumn, nameColumn, dateTimeColumn, scannedColumn];
    })

  });

});