define('zaris-frontend/components/sif-radno-mesto-struktura-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0;
    }),

    didInsertElement: function didInsertElement() {
      this.$('.sifElementiPregledaLovModal').appendTo(this.$(document.body));
      this.$('.sifObukeLovModal').appendTo(this.$(document.body));
      this.$('.sifPreglediLovModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      initElementiPregleda: function initElementiPregleda() {
        this.toggleProperty('refreshSifElementiPregledaLov');
        this.toggleProperty('myModalPanel1Show');
      },

      initObuke: function initObuke() {
        this.toggleProperty('refreshSifObukeLov');
        this.toggleProperty('myModalPanel2Show');
      },

      initPregledi: function initPregledi() {
        this.toggleProperty('refreshSifPreglediLov');
        this.toggleProperty('myModalPanel3Show');
      }

    }
  });

});