define('zaris-frontend/components/sif-obuka-dokument-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    objectId: null,
    servicePath: null,
    refresh: false,
    modalPanelShow: false,

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {

      return this.get('idRowSelected') !== 0 && this.get('selectedRow')[this.get('domainId')] !== null;
    }),

    actions: {

      initDownload: function initDownload() {

        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default']['SifObukaDokument'].create({ errorHandlerController: this });

        model.getById('downloadDokument', this.get('selectedRow')['idObukaDokument'], this.get('login').getAccessToken(), 'GET', function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }

    }
  });

});