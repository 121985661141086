define('zaris-frontend/templates/obr-obrazac10-unos', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 10
              },
              "end": {
                "line": 10,
                "column": 115
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac10-unos.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","t",["obrObrazac10.lzoKojaPodlezePregledima"],[],["loc",[null,[10,70],[10,115]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 10
              },
              "end": {
                "line": 11,
                "column": 126
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac10-unos.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","t",["obrObrazac10.lzoKojaNePodlezePregledima"],[],["loc",[null,[11,79],[11,126]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 12,
              "column": 8
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac10-unos.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","ObrObrazac10Lzo","class","xSelectBoxItem"],0,null,["loc",[null,[10,10],[10,128]]]],
          ["block","x-option",[],["value","ObrObrazac10LzoNePodleze","class","xSelectBoxItem"],1,null,["loc",[null,[11,10],[11,139]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac10-unos.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(element1,1,1);
        morphs[2] = dom.createMorphAt(element1,3,3);
        morphs[3] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_izvrsenim_pregledima_i_ispitivanjima_sredstava_i_opreme_za_licnu_zastitu_na_radu"],[],["loc",[null,[4,28],[4,138]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","selectedDomainName",["loc",[null,[9,26],[9,44]]]]],[],[]]],0,null,["loc",[null,[9,8],[12,21]]]],
        ["inline","component",["zaris-datatable"],["target",["subexpr","@mut",[["get","this",["loc",[null,[15,17],[15,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[16,18],[16,30]]]]],[],[]],"domainName",["subexpr","@mut",[["get","selectedDomainName",["loc",[null,[17,21],[17,39]]]]],[],[]],"domainId","idObrazac10Lzo","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[19,16],[19,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[20,22],[20,33]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[21,22],[21,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[22,18],[22,25]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"renderSacuvajObrazac",true,"renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac10Sacuvani","renderIzvestaji",true,"routeIzvestaji","obrObrazac10Izvestaji","renderSifarnici",true,"routeSifarnici","sifLicnaZastitnaOprema","modalPanelShow",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[33,25],[33,39]]]]],[],[]],"titleMsgKey","obrObrazac10.brisanjeHeader","descYesNoMsgKey","obrObrazac10.brisanjePitanje","titleDeleteMsgKey","obrObrazac10.brisanjeHeader","descDeleteQuestionMsgKey","obrObrazac10.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac10.brisanjeUspesno"],["loc",[null,[14,8],[39,10]]]],
        ["inline","obr-obrazac10-modal",[],["id","obrObrazac10Modal","domainId","idObrazac10Modal","target",["subexpr","@mut",[["get","this",["loc",[null,[48,11],[48,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[49,10],[49,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[50,16],[50,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[51,12],[51,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[52,19],[52,33]]]]],[],[]],"selectedDomainName",["subexpr","@mut",[["get","selectedDomainName",["loc",[null,[53,23],[53,41]]]]],[],[]]],["loc",[null,[45,0],[54,2]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});