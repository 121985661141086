define('zaris-frontend/templates/sif-radna-mesta', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sif-radna-mesta.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.radna_mesta"],[],["loc",[null,[5,28],[5,56]]]],
        ["inline","zaris-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","SifRadnoMesto","domainId","idRadnoMesto","titleMsgKey","sifRadnaMesta.brisanjeHeader","descYesNoMsgKey","sifRadnaMesta.brisanjePitanje","descDeleteSuccessMsgKey","sifRadnaMesta.brisanjeUspesno","titleDeleteMsgKey","sifRadnaMesta.brisanjeHeader","descDeleteQuestionMsgKey","sifRadnaMesta.brisanjePitanje","descDeleteSuccessMsgKey","sifRadnaMesta.brisanjeUspesno","idModalPanel","sifRadnaMestaModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[24,16],[24,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[25,22],[25,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[26,18],[26,25]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[27,25],[27,41]]]]],[],[]],"toolbarComponent","sif-radno-mesto-toolbar","datatableWriteRole","ROLE_SIF_RADNA_MESTA_WRITE"],["loc",[null,[9,8],[30,10]]]],
        ["inline","sif-radna-mesta-modal",[],["id","sifRadnaMestaModal","target",["subexpr","@mut",[["get","this",["loc",[null,[37,11],[37,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[38,10],[38,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[39,16],[39,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[40,12],[40,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[41,19],[41,35]]]]],[],[]]],["loc",[null,[35,0],[42,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});