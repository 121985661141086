define('zaris-frontend/components/sif-parametri-radne-okoline-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifParametriRadneOkolineModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    didInsertElement: function didInsertElement() {
      this._super();
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {

      var loginService = this.get('login');
      var xsifKategorijaParametraOkolineModel = Models['default'].XsifKategorijaParametraOkoline.create({ errorHandlerController: this });
      this.set('selectItems', xsifKategorijaParametraOkolineModel.findAllGetResult(loginService.getAccessToken()));
    },

    initCreate: function initCreate() {

      var mObject = this.get('modelObject');
      mObject.xsifKategorijaParametraOkoline = {};
      mObject.xsifKategorijaParametraOkoline.idKategorijaParametraOkoline = this.get('selectItems') !== null && this.get('selectItems')[0] !== null ? this.get('selectItems')[0].idKategorijaParametraOkoline : 0;

      this.set('modelObject', mObject);
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifParametarOkolineModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            sifParametarOkolineModel = Models['default'].SifParametarOkoline.create({ errorHandlerController: _this });

            sifParametarOkolineModel.create(_this.get('modelObject'), _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('sifParametriRadneOkoline.dodavanjeHeader').string,
                content: i18n.t('sifParametriRadneOkoline.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.toggleProperty('refresh');
              outter.closePanel();
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifParametriRadneOkoline.dodavanjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      },
      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifParametarOkolineModel;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');

            sifParametarOkolineModel = Models['default'].SifParametarOkoline.create({ errorHandlerController: _this2 });

            sifParametarOkolineModel.update(_this2.get('modelObject'), _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("sifParametriRadneOkoline.azuriranjeHeader").string,
                content: i18n.t("sifParametriRadneOkoline.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.toggleProperty('refresh');
              outter.closePanel();
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifParametriRadneOkoline.azuriranjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {

      if (this.get('isNew')) {
        this.initCreate();
      }

      Ember['default'].showModalPanel('.sifParametriRadneOkolineModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifParametriRadneOkolineModal').data('dialog').close();
    }
  });

});