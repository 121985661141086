define('zaris-frontend/controllers/sif-radno-mesto-struktura', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    i18n: Ember['default'].inject.service('i18n'),

    radnoMestoStrukturaContent: Ember['default'].A(),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.radnoMestoNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'organizacionaJedinicaNaziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.organizacionaJedinicaNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 120,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.sifra'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'adresa',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.adresa'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'elementiPregledaUkupno',
        savedWidth: 75,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.elementiPregledaUkupno'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'obukeUkupno',
        savedWidth: 75,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.obukeUkupno'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'preglediUkupno',
        savedWidth: 90,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.preglediUkupno'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'fgRizicno',
        savedWidth: 75,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnoMestoStruktura.FgRizicno'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.booleanValueNames(row.get(this.key));
        }
      }));
      return columns;
    })

  });

});