define('zaris-frontend/templates/components/obr-obrazac03-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 229,
                  "column": 22
                },
                "end": {
                  "line": 229,
                  "column": 123
                }
              },
              "moduleName": "zaris-frontend/templates/components/obr-obrazac03-modal.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" [");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("]  ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["content","rm.radnoMestoNaziv",["loc",[null,[229,67],[229,89]]]],
              ["content","rm.organizacionaStruktura",["loc",[null,[229,91],[229,120]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 228,
                "column": 20
              },
              "end": {
                "line": 230,
                "column": 20
              }
            },
            "moduleName": "zaris-frontend/templates/components/obr-obrazac03-modal.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","x-option",[],["value",["subexpr","@mut",[["get","rm.idRadnoMestoStruktura",["loc",[null,[229,40],[229,64]]]]],[],[]]],0,null,["loc",[null,[229,22],[229,136]]]]
          ],
          locals: ["rm"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 227,
              "column": 18
            },
            "end": {
              "line": 231,
              "column": 18
            }
          },
          "moduleName": "zaris-frontend/templates/components/obr-obrazac03-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","povredjeniRadnaMesta",["loc",[null,[228,28],[228,48]]]]],[],0,null,["loc",[null,[228,20],[230,29]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 658,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/obr-obrazac03-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","obrObrazac03PovredniList");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"align","left");
        dom.setAttribute(el3,"style","margin-top:1px");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","obrazac");
        dom.setAttribute(el4,"style","display: none;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"id","mojGrid");
        dom.setAttribute(el5,"style","{display:block}");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"id","button-grid");
        dom.setAttribute(el6,"class"," x-panel x-grid-panel");
        dom.setAttribute(el6,"style","");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","x-panel-tl");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","x-panel-tr");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-panel-tc");
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","x-panel-header x-unselectable x-panel-icon gridUnosIcon");
        dom.setAttribute(el10,"id","ext-gen72");
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-tool x-tool-refresh");
        dom.setAttribute(el11,"id","ext-gen79");
        var el12 = dom.createTextNode(" ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","x-panel-header-text");
        dom.setAttribute(el11,"id","ext-gen96");
        var el12 = dom.createTextNode("OB 03 Evidencija o povredama na radu");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","x-panel-bwrap");
        dom.setAttribute(el7,"id","ext-gen73");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","x-panel-ml");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-panel-mr");
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","x-panel-mc");
        dom.setAttribute(el10,"id","ext-gen78");
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-panel-tbar");
        dom.setAttribute(el11,"id","ext-gen74");
        dom.setAttribute(el11,"style","width: 978px;");
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"id","ext-comp-1120");
        dom.setAttribute(el12,"class","x-toolbar x-small-editor x-toolbar-layout-ct");
        dom.setAttribute(el12,"style","width: 972px;");
        var el13 = dom.createElement("table");
        dom.setAttribute(el13,"cellspacing","0");
        dom.setAttribute(el13,"class","x-toolbar-ct");
        var el14 = dom.createElement("tbody");
        var el15 = dom.createElement("tr");
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-left");
        dom.setAttribute(el16,"align","left");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        dom.setAttribute(el19,"class","x-toolbar-left-row");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen98");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1121");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon ");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen99");
        dom.setAttribute(el26,"class"," x-btn-text extButtonDodaj");
        var el27 = dom.createTextNode("Dodaj");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen100");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1122");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon x-item-disabled");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen101");
        dom.setAttribute(el26,"class"," x-btn-text extButtonIzmeni");
        var el27 = dom.createTextNode("Pregled i izmena");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen102");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1123");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon x-item-disabled");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen103");
        dom.setAttribute(el26,"class"," x-btn-text extButtonObrisi");
        var el27 = dom.createTextNode("Obriši");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen104");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen105");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen106");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1125");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen107");
        dom.setAttribute(el26,"class"," x-btn-text extButtonPdf");
        var el27 = dom.createTextNode("Sačuvaj OB 03");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen108");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1126");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon x-item-disabled");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","x-btn-arrow");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen109");
        dom.setAttribute(el26,"class"," x-btn-text extButtonPreuzmi");
        var el27 = dom.createTextNode("Preuzmi povredni list");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen110");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1130");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen111");
        dom.setAttribute(el26,"class"," x-btn-text extButtonSacuvaniObrasci");
        var el27 = dom.createTextNode("Sačuvani obrasci");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen112");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1131");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen113");
        dom.setAttribute(el26,"class"," x-btn-text extButtonIzvestaji");
        var el27 = dom.createTextNode("Izveštaji");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-right");
        dom.setAttribute(el16,"align","right");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"class","x-toolbar-right-ct");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-right-row");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-extras-row");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-panel-body");
        dom.setAttribute(el11,"id","ext-gen75");
        dom.setAttribute(el11,"style","width: 976px; height: 408px;");
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","x-grid3");
        dom.setAttribute(el12,"hidefocus","true");
        dom.setAttribute(el12,"id","ext-gen83");
        dom.setAttribute(el12,"style","width: 976px; height: 408px;");
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","x-grid3-viewport");
        dom.setAttribute(el13,"id","ext-gen84");
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-grid3-header");
        dom.setAttribute(el14,"id","ext-gen85");
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","x-grid3-header-inner");
        dom.setAttribute(el15,"id","ext-gen91");
        dom.setAttribute(el15,"style","width: 976px;");
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-header-offset");
        dom.setAttribute(el16,"style","width:976px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("thead");
        var el19 = dom.createElement("tr");
        dom.setAttribute(el19,"class","x-grid3-hd-row");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-checker");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-hd-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-id");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-obid");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-lice");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Ime i prezime");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Radno mesto");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-datum");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Datum povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-ocena");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Ocena težine povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Vrsta povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Izvor povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Uzrok povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","x-clear");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-grid3-scroller");
        dom.setAttribute(el14,"id","ext-gen86");
        dom.setAttribute(el14,"style","width: 976px; height: 384px;");
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","x-grid3-body");
        dom.setAttribute(el15,"style","width:957px;");
        dom.setAttribute(el15,"id","ext-gen87");
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row  x-grid3-row-first ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("5");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode(" ");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Milan Laketić");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("23.12.2015");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Nije izabrano");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Kolektivna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("10 - Mašine i oprema - stabilni, neodređeni");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("31 - Razbijanje materjala - na zglobu , šavu");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("3");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("3");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Aleksandar Ilić");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("27.01.2015");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Nije izabrano");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Pojedinačna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("01 - Zgrade, građevine, površine na ravnoj podlozi (spolja ili unutra) - neodređeno");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("11 - Problemi sa električnom strujom zbog kvara na opremi - posredni kontakt");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("4");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("4");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Aleksandra Jovanović");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("01.01.2015");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Nije izabrano");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Pojedinačna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("02 - Zgrade, građevine,površine iznad ravni tla (spolja ili unutra) - neodređene");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("11 - Problemi sa električnom strujom zbog kvara na opremi - posredni kontakt");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row  x-grid3-row-last ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("2");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("2");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Aleksandar Ilić");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("01.01.2011");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Laka");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Kolektivna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("06 - Ručno oruđe, bez spoljašnje energije - neodređeno");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("19 - Drugi oblici nezgoda, obuhvaćenih šifrom 10");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("a");
        dom.setAttribute(el15,"href","#");
        dom.setAttribute(el15,"class","x-grid3-focus");
        dom.setAttribute(el15,"tabindex","-1");
        dom.setAttribute(el15,"id","ext-gen88");
        dom.setAttribute(el15,"style","left: 1px; top: 1px;");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","x-grid3-resize-marker");
        dom.setAttribute(el13,"id","ext-gen89");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","x-grid3-resize-proxy");
        dom.setAttribute(el13,"id","ext-gen90");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-panel-bbar");
        dom.setAttribute(el11,"id","ext-gen76");
        dom.setAttribute(el11,"style","width: 978px;");
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"id","ext-comp-1106");
        dom.setAttribute(el12,"class","x-toolbar x-small-editor x-toolbar-layout-ct");
        dom.setAttribute(el12,"style","width: 972px;");
        var el13 = dom.createElement("table");
        dom.setAttribute(el13,"cellspacing","0");
        dom.setAttribute(el13,"class","x-toolbar-ct");
        var el14 = dom.createElement("tbody");
        var el15 = dom.createElement("tr");
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-left");
        dom.setAttribute(el16,"align","left");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        dom.setAttribute(el19,"class","x-toolbar-left-row");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen114");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1107");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen115");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-first");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen116");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1108");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen117");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-prev");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen118");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen119");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen120");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","xtb-text");
        dom.setAttribute(el21,"id","ext-comp-1116");
        var el22 = dom.createTextNode("Strana");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen121");
        var el21 = dom.createElement("input");
        dom.setAttribute(el21,"type","text");
        dom.setAttribute(el21,"size","20");
        dom.setAttribute(el21,"autocomplete","off");
        dom.setAttribute(el21,"id","ext-comp-1109");
        dom.setAttribute(el21,"class"," x-form-text x-form-field x-form-num-field x-tbar-page-number");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen122");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","xtb-text");
        dom.setAttribute(el21,"id","ext-comp-1110");
        var el22 = dom.createTextNode("od 1");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen123");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen124");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen125");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1111");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen126");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-next");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen127");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1112");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen128");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-last");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen129");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen130");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen131");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1113");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon ");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen132");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-loading");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-right");
        dom.setAttribute(el16,"align","right");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"class","x-toolbar-right-ct");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-right-row");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-toolbar-cell");
        dom.setAttribute(el24,"id","ext-gen133");
        var el25 = dom.createElement("div");
        dom.setAttribute(el25,"class","xtb-text");
        dom.setAttribute(el25,"id","ext-comp-1114");
        var el26 = dom.createTextNode("Prikaz 1 - 4 od 4");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-extras-row");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","x-panel-bl x-panel-nofooter");
        dom.setAttribute(el8,"id","ext-gen77");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-panel-br");
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","x-panel-bc");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","div-forma");
        dom.setAttribute(el4,"style","display: block;");
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("table");
        dom.setAttribute(el5,"border","0");
        dom.setAttribute(el5,"cellpadding","0");
        dom.setAttribute(el5,"cellspacing","0");
        dom.setAttribute(el5,"width","");
        dom.setAttribute(el5,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tbody");
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"align","center");
        dom.setAttribute(el8,"class","fnaslov");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"width","25%");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"width","25%");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"width","50%");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("2.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(", ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(", ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("3.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("4.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("5.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("6.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("7.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("8.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("9.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"id","ext-gen137");
        var el9 = dom.createTextNode("\n                3. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","ftextbn");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("10.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("11.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("12.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("13.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"id","ext-gen149");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el9,"id","ext-gen150");
        dom.setAttribute(el9,"style","width: 195px;");
        var el10 = dom.createTextNode("\n          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"rowspan","2");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("14.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"rowspan","3");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align:top");
        var el9 = dom.createTextNode("15.Mesto rada");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("16.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("17.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align:top");
        var el9 = dom.createTextNode("18.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"id","ext-gen140");
        var el9 = dom.createTextNode("4. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el9,"id","ext-gen141");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("19.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("20.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("21.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("22.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"name","rmesto_kombo_povredjeni");
        dom.setAttribute(el8,"id","rmesto_kombo_povredjeni");
        var el9 = dom.createTextNode("5.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextRed");
        dom.setAttribute(el8,"colspan","2");
        var el9 = dom.createTextNode("23.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control-compact");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("24.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("25.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("26.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("<td class=\"ftext\"><input type=\"text\" name=\"ukupan_staz\" id=\"ukupan_staz\" value=\"\" class=\"polja\" maxlength=\"3\" onkeyup=\"this.value=isNumeric(this.value); if(this.value > 100 || this.value < 0) { this.value = ''; alert('Morate uneti broj izmedju 0 i 100')}\"  style=\"width:200px\"/></td>");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("27.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("28.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"rowspan","3");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("29.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("Dan, mesec, godina");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("30.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n             ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("31.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("32.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("33.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("34.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("35.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("36-41. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("41.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"id","ext-gen143");
        var el9 = dom.createTextNode("6. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("input");
        dom.setAttribute(el9,"type","hidden");
        dom.setAttribute(el9,"name","ocevidac_id");
        dom.setAttribute(el9,"id","ocevidac_id");
        dom.setAttribute(el9,"value","");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el9,"id","ext-gen144");
        dom.setAttribute(el9,"style","width: 971px;");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("42.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("43.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("44.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        var el9 = dom.createTextNode("7.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("45.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("46.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("47.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("48.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                 ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                   ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("49.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("50.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("51.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("52.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("53.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("54.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("55.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("56.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftextRed");
        var el9 = dom.createTextNode("57.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("58.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("table");
        dom.setAttribute(el9,"border","0");
        dom.setAttribute(el9,"cellpadding","0");
        dom.setAttribute(el9,"cellspacing","0");
        dom.setAttribute(el9,"width","100%");
        dom.setAttribute(el9,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el10 = dom.createTextNode("\n            ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tbody");
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode("\n                            ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":                            ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        dom.setAttribute(el13,"id","ext-gen155");
        var el14 = dom.createTextNode("\n                              ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el14,"id","ext-gen156");
        dom.setAttribute(el14,"style","width: 209px;");
        var el15 = dom.createTextNode("\n                              ");
        dom.appendChild(el14, el15);
        var el15 = dom.createComment("");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                              ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                            ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                          ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","10%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(": ______________________");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"style","text-align: center;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        var el9 = dom.createTextNode("8. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("59. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("60. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("61.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("62.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("63.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("64.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("65.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("66.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("67.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("table");
        dom.setAttribute(el9,"border","0");
        dom.setAttribute(el9,"cellpadding","0");
        dom.setAttribute(el9,"cellspacing","0");
        dom.setAttribute(el9,"width","100%");
        dom.setAttribute(el9,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el10 = dom.createTextNode("\n            ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tbody");
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode("\n                            ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":                  ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        dom.setAttribute(el13,"id","ext-gen158");
        var el14 = dom.createTextNode("\n                              ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el14,"id","ext-gen159");
        dom.setAttribute(el14,"style","width: 209px;");
        var el15 = dom.createTextNode("\n                                ");
        dom.appendChild(el14, el15);
        var el15 = dom.createComment("");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                              ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                            ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                          ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","10%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode("\n                            ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":                              ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        var el14 = dom.createTextNode("\n                              ");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                            ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                          ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"style","text-align: center;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","okvir");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("table");
        dom.setAttribute(el9,"border","0");
        dom.setAttribute(el9,"cellpadding","0");
        dom.setAttribute(el9,"cellspacing","0");
        dom.setAttribute(el9,"width","100%");
        dom.setAttribute(el9,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el10 = dom.createTextNode("\n            ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tbody");
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"colspan","5");
        dom.setAttribute(el12,"class","fmalinaslov");
        dom.setAttribute(el12,"style","text-align: center; border: none;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(" |_|_|");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","10%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(": ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"style","text-align: center;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n              ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n            ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n                    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-checkmark");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-cross");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [1, 1, 3, 1, 1]);
        var element4 = dom.childAt(element3, [6]);
        var element5 = dom.childAt(element3, [8]);
        var element6 = dom.childAt(element5, [5]);
        var element7 = dom.childAt(element3, [10]);
        var element8 = dom.childAt(element3, [12]);
        var element9 = dom.childAt(element3, [14]);
        var element10 = dom.childAt(element3, [16]);
        var element11 = dom.childAt(element3, [22]);
        var element12 = dom.childAt(element3, [24]);
        var element13 = dom.childAt(element3, [26]);
        var element14 = dom.childAt(element13, [5]);
        var element15 = dom.childAt(element3, [28, 1]);
        var element16 = dom.childAt(element3, [32]);
        var element17 = dom.childAt(element3, [34]);
        var element18 = dom.childAt(element3, [36]);
        var element19 = dom.childAt(element3, [38]);
        var element20 = dom.childAt(element3, [40]);
        var element21 = dom.childAt(element3, [42]);
        var element22 = dom.childAt(element3, [44]);
        var element23 = dom.childAt(element3, [46]);
        var element24 = dom.childAt(element3, [48]);
        var element25 = dom.childAt(element3, [50]);
        var element26 = dom.childAt(element3, [52]);
        var element27 = dom.childAt(element3, [54]);
        var element28 = dom.childAt(element3, [56]);
        var element29 = dom.childAt(element3, [58, 1]);
        var element30 = dom.childAt(element3, [60]);
        var element31 = dom.childAt(element3, [62]);
        var element32 = dom.childAt(element3, [64]);
        var element33 = dom.childAt(element32, [5]);
        var element34 = dom.childAt(element3, [66]);
        var element35 = dom.childAt(element3, [70]);
        var element36 = dom.childAt(element3, [72]);
        var element37 = dom.childAt(element3, [74]);
        var element38 = dom.childAt(element3, [76]);
        var element39 = dom.childAt(element3, [78]);
        var element40 = dom.childAt(element3, [80]);
        var element41 = dom.childAt(element3, [82]);
        var element42 = dom.childAt(element3, [84]);
        var element43 = dom.childAt(element3, [86]);
        var element44 = dom.childAt(element3, [88]);
        var element45 = dom.childAt(element3, [90]);
        var element46 = dom.childAt(element3, [92]);
        var element47 = dom.childAt(element3, [94]);
        var element48 = dom.childAt(element3, [96]);
        var element49 = dom.childAt(element3, [98]);
        var element50 = dom.childAt(element3, [100, 1]);
        var element51 = dom.childAt(element3, [102]);
        var element52 = dom.childAt(element3, [104]);
        var element53 = dom.childAt(element3, [106]);
        var element54 = dom.childAt(element53, [3]);
        var element55 = dom.childAt(element3, [110]);
        var element56 = dom.childAt(element3, [112]);
        var element57 = dom.childAt(element3, [114]);
        var element58 = dom.childAt(element57, [3]);
        var element59 = dom.childAt(element3, [116]);
        var element60 = dom.childAt(element3, [118]);
        var element61 = dom.childAt(element3, [120]);
        var element62 = dom.childAt(element3, [122]);
        var element63 = dom.childAt(element62, [3]);
        var element64 = dom.childAt(element3, [124]);
        var element65 = dom.childAt(element3, [126]);
        var element66 = dom.childAt(element65, [3]);
        var element67 = dom.childAt(element3, [128]);
        var element68 = dom.childAt(element67, [3]);
        var element69 = dom.childAt(element3, [130]);
        var element70 = dom.childAt(element69, [3]);
        var element71 = dom.childAt(element3, [132]);
        var element72 = dom.childAt(element3, [134]);
        var element73 = dom.childAt(element3, [136]);
        var element74 = dom.childAt(element3, [140, 1, 1, 1]);
        var element75 = dom.childAt(element74, [0, 1]);
        var element76 = dom.childAt(element74, [4]);
        var element77 = dom.childAt(element3, [146]);
        var element78 = dom.childAt(element3, [148]);
        var element79 = dom.childAt(element3, [150]);
        var element80 = dom.childAt(element3, [152]);
        var element81 = dom.childAt(element3, [154]);
        var element82 = dom.childAt(element3, [156]);
        var element83 = dom.childAt(element3, [158]);
        var element84 = dom.childAt(element3, [160]);
        var element85 = dom.childAt(element3, [162]);
        var element86 = dom.childAt(element3, [166, 1, 1, 1]);
        var element87 = dom.childAt(element86, [0, 1]);
        var element88 = dom.childAt(element86, [2, 1]);
        var element89 = dom.childAt(element86, [8]);
        var element90 = dom.childAt(element3, [170, 1, 1, 1]);
        var element91 = dom.childAt(element90, [6]);
        var element92 = dom.childAt(element2, [3]);
        var element93 = dom.childAt(element2, [5]);
        var morphs = new Array(187);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [0, 1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
        morphs[6] = dom.createMorphAt(element6,1,1);
        morphs[7] = dom.createMorphAt(element6,3,3);
        morphs[8] = dom.createMorphAt(element6,5,5);
        morphs[9] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element7, [5]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element8, [5]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element9, [5]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element10, [5]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element3, [18, 1]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element3, [20, 1]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element11, [5]),0,0);
        morphs[21] = dom.createMorphAt(dom.childAt(element12, [1]),1,1);
        morphs[22] = dom.createMorphAt(dom.childAt(element12, [5]),0,0);
        morphs[23] = dom.createMorphAt(dom.childAt(element13, [1]),1,1);
        morphs[24] = dom.createMorphAt(element14,1,1);
        morphs[25] = dom.createMorphAt(element14,3,3);
        morphs[26] = dom.createMorphAt(element14,5,5);
        morphs[27] = dom.createMorphAt(element15,1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
        morphs[29] = dom.createMorphAt(dom.childAt(element16, [1]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element16, [5]),1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element17, [1]),1,1);
        morphs[32] = dom.createMorphAt(dom.childAt(element17, [5]),0,0);
        morphs[33] = dom.createMorphAt(dom.childAt(element18, [1]),1,1);
        morphs[34] = dom.createMorphAt(dom.childAt(element18, [5]),1,1);
        morphs[35] = dom.createMorphAt(dom.childAt(element19, [1]),1,1);
        morphs[36] = dom.createMorphAt(dom.childAt(element19, [5, 0]),1,1);
        morphs[37] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
        morphs[38] = dom.createMorphAt(dom.childAt(element20, [3]),0,0);
        morphs[39] = dom.createMorphAt(dom.childAt(element20, [5]),0,0);
        morphs[40] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
        morphs[41] = dom.createMorphAt(dom.childAt(element21, [3]),0,0);
        morphs[42] = dom.createMorphAt(dom.childAt(element22, [3]),0,0);
        morphs[43] = dom.createMorphAt(dom.childAt(element22, [5]),0,0);
        morphs[44] = dom.createMorphAt(dom.childAt(element23, [3]),0,0);
        morphs[45] = dom.createMorphAt(dom.childAt(element23, [5]),0,0);
        morphs[46] = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
        morphs[47] = dom.createMorphAt(dom.childAt(element24, [3]),0,0);
        morphs[48] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
        morphs[49] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
        morphs[50] = dom.createMorphAt(dom.childAt(element26, [1]),1,1);
        morphs[51] = dom.createMorphAt(dom.childAt(element26, [3]),0,0);
        morphs[52] = dom.createMorphAt(dom.childAt(element27, [1]),1,1);
        morphs[53] = dom.createMorphAt(dom.childAt(element27, [3]),0,0);
        morphs[54] = dom.createMorphAt(dom.childAt(element28, [1]),1,1);
        morphs[55] = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
        morphs[56] = dom.createMorphAt(element29,1,1);
        morphs[57] = dom.createMorphAt(dom.childAt(element29, [5]),1,1);
        morphs[58] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
        morphs[59] = dom.createMorphAt(dom.childAt(element30, [5]),0,0);
        morphs[60] = dom.createMorphAt(dom.childAt(element31, [1]),1,1);
        morphs[61] = dom.createMorphAt(dom.childAt(element31, [5]),0,0);
        morphs[62] = dom.createMorphAt(dom.childAt(element32, [1]),1,1);
        morphs[63] = dom.createMorphAt(element33,1,1);
        morphs[64] = dom.createMorphAt(element33,3,3);
        morphs[65] = dom.createMorphAt(element33,5,5);
        morphs[66] = dom.createMorphAt(dom.childAt(element34, [1]),1,1);
        morphs[67] = dom.createMorphAt(dom.childAt(element34, [5]),0,0);
        morphs[68] = dom.createMorphAt(dom.childAt(element3, [68, 1]),1,1);
        morphs[69] = dom.createMorphAt(dom.childAt(element35, [1]),1,1);
        morphs[70] = dom.createMorphAt(dom.childAt(element35, [3, 1]),1,1);
        morphs[71] = dom.createMorphAt(dom.childAt(element36, [1]),1,1);
        morphs[72] = dom.createMorphAt(dom.childAt(element36, [3]),0,0);
        morphs[73] = dom.createMorphAt(dom.childAt(element37, [1]),1,1);
        morphs[74] = dom.createMorphAt(dom.childAt(element37, [3]),0,0);
        morphs[75] = dom.createMorphAt(dom.childAt(element38, [1]),1,1);
        morphs[76] = dom.createMorphAt(dom.childAt(element38, [5]),0,0);
        morphs[77] = dom.createMorphAt(dom.childAt(element39, [1]),1,1);
        morphs[78] = dom.createMorphAt(dom.childAt(element39, [3]),1,1);
        morphs[79] = dom.createMorphAt(dom.childAt(element40, [1]),1,1);
        morphs[80] = dom.createMorphAt(dom.childAt(element40, [3]),0,0);
        morphs[81] = dom.createMorphAt(dom.childAt(element41, [1]),1,1);
        morphs[82] = dom.createMorphAt(dom.childAt(element41, [5]),1,1);
        morphs[83] = dom.createMorphAt(dom.childAt(element42, [1]),1,1);
        morphs[84] = dom.createMorphAt(dom.childAt(element42, [3]),1,1);
        morphs[85] = dom.createMorphAt(dom.childAt(element43, [1]),1,1);
        morphs[86] = dom.createMorphAt(dom.childAt(element43, [3]),0,0);
        morphs[87] = dom.createMorphAt(dom.childAt(element44, [3]),1,1);
        morphs[88] = dom.createMorphAt(dom.childAt(element44, [5]),0,0);
        morphs[89] = dom.createMorphAt(dom.childAt(element45, [1]),1,1);
        morphs[90] = dom.createMorphAt(dom.childAt(element45, [3]),1,1);
        morphs[91] = dom.createMorphAt(dom.childAt(element46, [1]),1,1);
        morphs[92] = dom.createMorphAt(dom.childAt(element46, [3]),1,1);
        morphs[93] = dom.createMorphAt(dom.childAt(element47, [1]),1,1);
        morphs[94] = dom.createMorphAt(dom.childAt(element47, [3]),1,1);
        morphs[95] = dom.createMorphAt(dom.childAt(element48, [1]),1,1);
        morphs[96] = dom.createMorphAt(dom.childAt(element48, [3]),1,1);
        morphs[97] = dom.createMorphAt(dom.childAt(element49, [1]),1,1);
        morphs[98] = dom.createMorphAt(dom.childAt(element49, [3]),1,1);
        morphs[99] = dom.createMorphAt(element50,1,1);
        morphs[100] = dom.createMorphAt(dom.childAt(element50, [7]),1,1);
        morphs[101] = dom.createMorphAt(dom.childAt(element51, [1]),1,1);
        morphs[102] = dom.createMorphAt(dom.childAt(element51, [3]),0,0);
        morphs[103] = dom.createMorphAt(dom.childAt(element52, [1]),1,1);
        morphs[104] = dom.createMorphAt(dom.childAt(element52, [3]),0,0);
        morphs[105] = dom.createMorphAt(dom.childAt(element53, [1]),1,1);
        morphs[106] = dom.createMorphAt(element54,1,1);
        morphs[107] = dom.createMorphAt(element54,3,3);
        morphs[108] = dom.createMorphAt(element54,5,5);
        morphs[109] = dom.createMorphAt(dom.childAt(element3, [108, 1]),1,1);
        morphs[110] = dom.createMorphAt(dom.childAt(element55, [1]),1,1);
        morphs[111] = dom.createMorphAt(dom.childAt(element55, [3]),0,0);
        morphs[112] = dom.createMorphAt(dom.childAt(element56, [1]),1,1);
        morphs[113] = dom.createMorphAt(dom.childAt(element56, [3]),0,0);
        morphs[114] = dom.createMorphAt(dom.childAt(element57, [1]),1,1);
        morphs[115] = dom.createMorphAt(element58,1,1);
        morphs[116] = dom.createMorphAt(element58,3,3);
        morphs[117] = dom.createMorphAt(dom.childAt(element59, [1]),1,1);
        morphs[118] = dom.createMorphAt(dom.childAt(element59, [3]),1,1);
        morphs[119] = dom.createMorphAt(dom.childAt(element60, [1]),1,1);
        morphs[120] = dom.createMorphAt(dom.childAt(element60, [3]),1,1);
        morphs[121] = dom.createMorphAt(dom.childAt(element61, [1]),1,1);
        morphs[122] = dom.createMorphAt(dom.childAt(element61, [3]),0,0);
        morphs[123] = dom.createMorphAt(dom.childAt(element62, [1]),1,1);
        morphs[124] = dom.createMorphAt(element63,1,1);
        morphs[125] = dom.createMorphAt(element63,3,3);
        morphs[126] = dom.createMorphAt(dom.childAt(element64, [1]),1,1);
        morphs[127] = dom.createMorphAt(dom.childAt(element64, [3]),1,1);
        morphs[128] = dom.createMorphAt(dom.childAt(element65, [1]),1,1);
        morphs[129] = dom.createMorphAt(element66,1,1);
        morphs[130] = dom.createMorphAt(element66,3,3);
        morphs[131] = dom.createMorphAt(dom.childAt(element67, [1]),1,1);
        morphs[132] = dom.createMorphAt(element68,1,1);
        morphs[133] = dom.createMorphAt(element68,3,3);
        morphs[134] = dom.createMorphAt(dom.childAt(element69, [1]),1,1);
        morphs[135] = dom.createMorphAt(element70,1,1);
        morphs[136] = dom.createMorphAt(element70,3,3);
        morphs[137] = dom.createMorphAt(dom.childAt(element71, [1]),1,1);
        morphs[138] = dom.createMorphAt(dom.childAt(element71, [3]),1,1);
        morphs[139] = dom.createMorphAt(dom.childAt(element72, [1]),1,1);
        morphs[140] = dom.createMorphAt(dom.childAt(element72, [3]),1,1);
        morphs[141] = dom.createMorphAt(dom.childAt(element73, [1]),1,1);
        morphs[142] = dom.createMorphAt(dom.childAt(element73, [3]),0,0);
        morphs[143] = dom.createMorphAt(element75,1,1);
        morphs[144] = dom.createMorphAt(dom.childAt(element75, [3, 1]),1,1);
        morphs[145] = dom.createMorphAt(dom.childAt(element74, [2, 1]),0,0);
        morphs[146] = dom.createMorphAt(dom.childAt(element76, [1]),0,0);
        morphs[147] = dom.createMorphAt(dom.childAt(element76, [5]),0,0);
        morphs[148] = dom.createMorphAt(dom.childAt(element76, [9]),0,0);
        morphs[149] = dom.createMorphAt(dom.childAt(element3, [144, 1]),1,1);
        morphs[150] = dom.createMorphAt(dom.childAt(element77, [1]),1,1);
        morphs[151] = dom.createMorphAt(dom.childAt(element77, [3]),0,0);
        morphs[152] = dom.createMorphAt(dom.childAt(element78, [1]),1,1);
        morphs[153] = dom.createMorphAt(dom.childAt(element78, [3]),0,0);
        morphs[154] = dom.createMorphAt(dom.childAt(element79, [1]),1,1);
        morphs[155] = dom.createMorphAt(dom.childAt(element79, [3]),0,0);
        morphs[156] = dom.createMorphAt(dom.childAt(element80, [1]),1,1);
        morphs[157] = dom.createMorphAt(dom.childAt(element80, [3]),0,0);
        morphs[158] = dom.createMorphAt(dom.childAt(element81, [1]),1,1);
        morphs[159] = dom.createMorphAt(dom.childAt(element81, [3]),0,0);
        morphs[160] = dom.createMorphAt(dom.childAt(element82, [1]),1,1);
        morphs[161] = dom.createMorphAt(dom.childAt(element82, [3]),0,0);
        morphs[162] = dom.createMorphAt(dom.childAt(element83, [1]),1,1);
        morphs[163] = dom.createMorphAt(dom.childAt(element83, [3]),0,0);
        morphs[164] = dom.createMorphAt(dom.childAt(element84, [1]),1,1);
        morphs[165] = dom.createMorphAt(dom.childAt(element84, [3]),0,0);
        morphs[166] = dom.createMorphAt(dom.childAt(element85, [1]),1,1);
        morphs[167] = dom.createMorphAt(dom.childAt(element85, [3]),0,0);
        morphs[168] = dom.createMorphAt(element87,1,1);
        morphs[169] = dom.createMorphAt(dom.childAt(element87, [3, 1]),1,1);
        morphs[170] = dom.createMorphAt(element88,1,1);
        morphs[171] = dom.createMorphAt(dom.childAt(element88, [3]),1,1);
        morphs[172] = dom.createMorphAt(dom.childAt(element86, [4, 1]),0,0);
        morphs[173] = dom.createMorphAt(dom.childAt(element86, [6, 5]),0,0);
        morphs[174] = dom.createMorphAt(dom.childAt(element89, [1]),0,0);
        morphs[175] = dom.createMorphAt(dom.childAt(element89, [9]),0,0);
        morphs[176] = dom.createMorphAt(dom.childAt(element90, [0, 1]),0,0);
        morphs[177] = dom.createMorphAt(dom.childAt(element90, [2, 1]),0,0);
        morphs[178] = dom.createMorphAt(dom.childAt(element90, [4, 1]),0,0);
        morphs[179] = dom.createMorphAt(dom.childAt(element91, [1]),0,0);
        morphs[180] = dom.createMorphAt(dom.childAt(element91, [5]),0,0);
        morphs[181] = dom.createMorphAt(dom.childAt(element91, [9]),0,0);
        morphs[182] = dom.createElementMorph(element92);
        morphs[183] = dom.createMorphAt(element92,2,2);
        morphs[184] = dom.createElementMorph(element93);
        morphs[185] = dom.createMorphAt(element93,2,2);
        morphs[186] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["obrObrazac03.povredaNaRadu"],[],["loc",[null,[6,10],[6,44]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciOPoslodavcu"],[],["loc",[null,[23,55],[23,106]]]],
        ["inline","t",["obrObrazac03.povredniList.obveznikPodnosenjaPoslodavac"],[],["loc",[null,[34,40],[34,102]]]],
        ["inline","t",["obrObrazac03.povredniList.punNazivPoslodavca"],[],["loc",[null,[35,38],[35,90]]]],
        ["inline","t",["obrObrazac03.povredniList.sedisteAdresaPoslodavca"],[],["loc",[null,[40,26],[40,83]]]],
        ["content","pravnoLice.adresa",["loc",[null,[41,83],[41,104]]]],
        ["content","pravnoLice.mesto",["loc",[null,[41,106],[41,126]]]],
        ["content","pravnoLice.drzava",["loc",[null,[41,128],[41,149]]]],
        ["inline","t",["obrObrazac03.povredniList.regBrojObveznika"],[],["loc",[null,[45,26],[45,76]]]],
        ["content","pravnoLice.maticniBroj",["loc",[null,[46,83],[46,109]]]],
        ["inline","t",["obrObrazac03.povredniList.pib"],[],["loc",[null,[50,26],[50,63]]]],
        ["content","pravnoLice.pib",["loc",[null,[51,83],[51,101]]]],
        ["inline","t",["obrObrazac03.povredniList.sifraDelatnosti"],[],["loc",[null,[55,26],[55,75]]]],
        ["content","pravnoLice.sifraDelatnosti",["loc",[null,[56,83],[56,113]]]],
        ["inline","t",["obrObrazac03.povredniList.ukupnoZaposlenih"],[],["loc",[null,[60,26],[60,76]]]],
        ["content","brojZaposlenih",["loc",[null,[61,83],[61,101]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciOBZRLicuPoslodavac"],[],["loc",[null,[66,10],[66,68]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","saveFirstItemValue",true,"firstItemValue",["subexpr","@mut",[["get","firstIdLiceZaBzr",["loc",[null,[71,98],[71,114]]]]],[],[]],"nullChoice",false,"value",["subexpr","@mut",[["get","idLiceZaBzr",["loc",[null,[71,138],[71,149]]]]],[],[]],"domainName","SifLica","servicePath","selectItemsBzr","styleClass","input-control-compact"],["loc",[null,[71,14],[71,236]]]],
        ["inline","t",["obrObrazac03.povredniList.imePrezime"],[],["loc",[null,[75,34],[75,78]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","liceZaBzr.ime",["loc",[null,[77,74],[77,87]]]]],[],[]],"size","100"],["loc",[null,[77,32],[77,100]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[81,34],[81,72]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","liceZaBzr.maticniBroj",["loc",[null,[83,74],[83,95]]]]],[],[]],"size","100"],["loc",[null,[83,32],[83,108]]]],
        ["inline","t",["obrObrazac03.povredniList.adresaPrebivalista"],[],["loc",[null,[87,34],[87,86]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","liceZaBzr.adresa",["loc",[null,[90,58],[90,74]]]]],[],[]]],["loc",[null,[90,16],[90,77]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","liceZaBzr.mesto",["loc",[null,[91,58],[91,73]]]]],[],[]]],["loc",[null,[91,16],[91,76]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","liceZaBzr.drzava",["loc",[null,[92,58],[92,74]]]]],[],[]]],["loc",[null,[92,16],[92,77]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciOPovredjenom"],[],["loc",[null,[99,19],[99,71]]]],
        ["inline","select-datatable",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[101,47],[101,69]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[102,35],[102,52]]]]],[],[]],"obj",["subexpr","@mut",[["get","objPovredjeni",["loc",[null,[103,24],[103,37]]]]],[],[]],"objToSet",["subexpr","@mut",[["get","controllerObj",["loc",[null,[104,29],[104,42]]]]],[],[]],"objNameToSet","objPovredjeni","placeholderLabel","obrazac2.izvestaji.odaberiteZaposlenog"],["loc",[null,[101,20],[107,20]]]],
        ["inline","t",["obrObrazac03.povredniList.imePrezime"],[],["loc",[null,[115,32],[115,76]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objPovredjeni.naziv",["loc",[null,[118,52],[118,71]]]]],[],[]],"size","100"],["loc",[null,[118,10],[118,84]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[123,29],[123,67]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objPovredjeni.selectedRow.maticniBroj",["loc",[null,[125,80],[125,117]]]]],[],[]],"size","100"],["loc",[null,[125,38],[125,130]]]],
        ["inline","t",["obrObrazac03.povredniList.polMZ"],[],["loc",[null,[129,29],[129,68]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objPovredjeni.selectedRow.pol.naziv",["loc",[null,[132,58],[132,93]]]]],[],[]],"size","100"],["loc",[null,[132,16],[132,106]]]],
        ["inline","t",["obrObrazac03.povredniList.danMesecGodRodjenja"],[],["loc",[null,[136,29],[136,82]]]],
        ["inline","pikaday-input",[],["useUTC",true,"disabled",true,"value",["subexpr","@mut",[["get","objPovredjeni.selectedRow.datumRodjenja",["loc",[null,[139,58],[139,97]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input"],["loc",[null,[139,10],[139,142]]]],
        ["inline","t",["obrObrazac03.povredniList.prebivaliste"],[],["loc",[null,[143,43],[143,89]]]],
        ["inline","t",["obrObrazac03.povredniList.ulicaIBroj"],[],["loc",[null,[144,26],[144,70]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objPovredjeni.selectedRow.adresa",["loc",[null,[145,68],[145,100]]]]],[],[]],"size","100"],["loc",[null,[145,26],[145,113]]]],
        ["inline","t",["obrObrazac03.povredniList.mestoOpstina"],[],["loc",[null,[148,26],[148,72]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objPovredjeni.selectedRow.mesto",["loc",[null,[149,68],[149,99]]]]],[],[]],"size","100"],["loc",[null,[149,26],[149,112]]]],
        ["inline","t",["obrObrazac03.povredniList.drzava"],[],["loc",[null,[153,26],[153,66]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objPovredjeni.selectedRow.drzava",["loc",[null,[154,68],[154,100]]]]],[],[]],"size","100"],["loc",[null,[154,26],[154,113]]]],
        ["inline","t",["obrObrazac03.povredniList.ulicaIBroj"],[],["loc",[null,[158,26],[158,70]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.rmAdresa",["loc",[null,[159,52],[159,72]]]]],[],[]],"size","100"],["loc",[null,[159,26],[159,85]]]],
        ["inline","t",["obrObrazac03.povredniList.mestoOpstina"],[],["loc",[null,[162,26],[162,72]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.rmMesto",["loc",[null,[163,52],[163,71]]]]],[],[]],"size","100"],["loc",[null,[163,26],[163,84]]]],
        ["inline","t",["obrObrazac03.povredniList.drzava"],[],["loc",[null,[166,26],[166,66]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.rmDrzava",["loc",[null,[167,52],[167,72]]]]],[],[]],"size","100"],["loc",[null,[167,26],[167,85]]]],
        ["inline","t",["obrObrazac03.povredniList.vrstaStepenSS"],[],["loc",[null,[170,41],[170,88]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","nullChoice",true,"domainName","SifVrstaStepenSs","value",["subexpr","@mut",[["get","modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs",["loc",[null,[171,123],[171,184]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[171,26],[171,221]]]],
        ["inline","t",["obrObrazac03.povredniList.vrstaStepenPropisaneSS"],[],["loc",[null,[174,41],[174,97]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","nullChoice",true,"domainName","SifVrstaStepenSs","value",["subexpr","@mut",[["get","modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs",["loc",[null,[175,123],[175,193]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[175,26],[175,230]]]],
        ["inline","t",["obrObrazac03.povredniList.statusPovredjenog"],[],["loc",[null,[178,68],[178,119]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","nullChoice",true,"domainName","SifStatusZaposlenog","value",["subexpr","@mut",[["get","modelObject.sifStatusZaposlenog.idStatusZaposlenog",["loc",[null,[179,126],[179,176]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[179,26],[179,213]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciONeposrednomRukovodiocu"],[],["loc",[null,[182,63],[182,126]]]],
        ["inline","select-datatable",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[184,51],[184,73]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[185,39],[185,56]]]]],[],[]],"obj",["subexpr","@mut",[["get","objRukovodioc",["loc",[null,[186,28],[186,41]]]]],[],[]],"objToSet",["subexpr","@mut",[["get","controllerObj",["loc",[null,[187,33],[187,46]]]]],[],[]],"objNameToSet","objRukovodioc","placeholderLabel","obrazac2.izvestaji.odaberiteZaposlenog"],["loc",[null,[184,24],[190,26]]]],
        ["inline","t",["obrObrazac03.povredniList.imePrezime"],[],["loc",[null,[195,32],[195,76]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objRukovodioc.naziv",["loc",[null,[197,74],[197,93]]]]],[],[]],"size","100"],["loc",[null,[197,32],[197,106]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[200,29],[200,67]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objRukovodioc.selectedRow.maticniBroj",["loc",[null,[202,74],[202,111]]]]],[],[]],"size","100"],["loc",[null,[202,32],[202,124]]]],
        ["inline","t",["obrObrazac03.povredniList.adresaPrebivalista"],[],["loc",[null,[205,29],[205,81]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objRukovodioc.selectedRow.adresa",["loc",[null,[208,58],[208,90]]]]],[],[]]],["loc",[null,[208,16],[208,93]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objRukovodioc.selectedRow.mesto",["loc",[null,[209,58],[209,89]]]]],[],[]]],["loc",[null,[209,16],[209,92]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","objRukovodioc.selectedRow.drzava",["loc",[null,[210,58],[210,90]]]]],[],[]]],["loc",[null,[210,16],[210,93]]]],
        ["inline","t",["obrObrazac03.povredniList.posloviKojeObavlja"],[],["loc",[null,[214,29],[214,81]]]],
        ["inline","input",[],["disabled","true","type","text","value",["subexpr","@mut",[["get","rukovodiocRadnaMesta",["loc",[null,[216,74],[216,94]]]]],[],[]],"size","100"],["loc",[null,[216,32],[216,107]]]],
        ["inline","t",["obrObrazac03.povredniList.radnoMestoNastupanjePovrede"],[],["loc",[null,[219,106],[219,167]]]],
        ["inline","t",["obrObrazac03.povredniList.radnoMestoPovredjeniRasporedjen"],[],["loc",[null,[223,44],[223,109]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","idRadnoMestoStruktura",["loc",[null,[227,36],[227,57]]]]],[],[]],"action","izborRadnogMestaUStrukturi"],0,null,["loc",[null,[227,18],[231,31]]]],
        ["inline","t",["obrObrazac03.povredniList.labela24"],[],["loc",[null,[236,41],[236,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.posaoKojiJeObavljao",["loc",[null,[237,58],[237,89]]]]],[],[]],"size","100"],["loc",[null,[237,32],[237,102]]]],
        ["inline","t",["obrObrazac03.povredniList.labela25"],[],["loc",[null,[241,41],[241,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.radnoIskustvo",["loc",[null,[242,58],[242,83]]]]],[],[]]],["loc",[null,[242,32],[242,86]]]],
        ["inline","t",["obrObrazac03.povredniList.labela26"],[],["loc",[null,[245,41],[245,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.ukupanStaz",["loc",[null,[247,58],[247,80]]]]],[],[]]],["loc",[null,[247,32],[247,83]]]],
        ["inline","t",["obrObrazac03.povredniList.labela27"],[],["loc",[null,[250,41],[250,83]]]],
        ["inline","input",[],["type","number","value",["subexpr","@mut",[["get","modelObject.radnoVremeProsek",["loc",[null,[251,61],[251,89]]]]],[],[]]],["loc",[null,[251,33],[251,92]]]],
        ["inline","t",["obrObrazac03.povredniList.labela28"],[],["loc",[null,[255,41],[255,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.ranijePovrede",["loc",[null,[256,58],[256,83]]]]],[],[]]],["loc",[null,[256,32],[256,86]]]],
        ["inline","t",["obrObrazac03.povredniList.labela29"],[],["loc",[null,[261,43],[261,85]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.vremePovrede",["loc",[null,[264,50],[264,74]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input"],["loc",[null,[264,16],[264,118]]]],
        ["inline","t",["obrObrazac03.povredniList.labela30"],[],["loc",[null,[268,29],[268,71]]]],
        ["inline","input",[],["readonly","true","type","text","value",["subexpr","@mut",[["get","formatedDatumPovrede",["loc",[null,[270,58],[270,78]]]]],[],[]]],["loc",[null,[270,16],[270,80]]]],
        ["inline","t",["obrObrazac03.povredniList.labela31"],[],["loc",[null,[274,32],[274,74]]]],
        ["inline","input",[],["class","input-hour","type","text","value",["subexpr","@mut",[["get","modelObject.dobaDanaSat",["loc",[null,[275,71],[275,94]]]]],[],[]]],["loc",[null,[275,26],[275,96]]]],
        ["inline","t",["obrObrazac03.povredniList.labela32"],[],["loc",[null,[280,32],[280,74]]]],
        ["inline","input",[],["class","input-hour","type","text","value",["subexpr","@mut",[["get","modelObject.radniSatOdPocetkaRada",["loc",[null,[281,71],[281,104]]]]],[],[]]],["loc",[null,[281,26],[281,106]]]],
        ["inline","t",["obrObrazac03.povredniList.labela33"],[],["loc",[null,[285,41],[285,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.mestoPovredeSaAdresom",["loc",[null,[287,42],[287,75]]]]],[],[]],"size","100"],["loc",[null,[287,16],[287,88]]]],
        ["inline","t",["obrObrazac03.povredniList.opstina"],[],["loc",[null,[291,41],[291,82]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.mestoPovredeOpstina",["loc",[null,[293,42],[293,73]]]]],[],[]],"size","100"],["loc",[null,[293,16],[293,86]]]],
        ["inline","t",["obrObrazac03.povredniList.drzava"],[],["loc",[null,[297,41],[297,81]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.mestoPovredeDrzava",["loc",[null,[299,42],[299,72]]]]],[],[]],"size","100"],["loc",[null,[299,16],[299,85]]]],
        ["inline","t",["obrObrazac03.povredniList.labela33"],[],["loc",[null,[305,45],[305,87]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","nullChoice",true,"domainName","SifMestoPovrede","value",["subexpr","@mut",[["get","modelObject.sifMestoPovrede.idMestoPovrede",["loc",[null,[307,112],[307,154]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[307,16],[307,191]]]],
        ["inline","t",["obrObrazac03.povredniList.labela41"],[],["loc",[null,[311,41],[311,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.gdeSePovredioOstalo",["loc",[null,[313,42],[313,73]]]]],[],[]],"size","100"],["loc",[null,[313,16],[313,86]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciOOcevicu"],[],["loc",[null,[318,63],[318,111]]]],
        ["inline","select-datatable",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[321,47],[321,69]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[322,35],[322,52]]]]],[],[]],"obj",["subexpr","@mut",[["get","objOcevidac",["loc",[null,[323,24],[323,35]]]]],[],[]],"objToSet",["subexpr","@mut",[["get","controllerObj",["loc",[null,[324,29],[324,42]]]]],[],[]],"objNameToSet","objOcevidac","placeholderLabel","obrazac2.izvestaji.odaberiteZaposlenog"],["loc",[null,[321,20],[327,20]]]],
        ["inline","t",["obrObrazac03.povredniList.imePrezime"],[],["loc",[null,[333,41],[333,85]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","objOcevidac.naziv",["loc",[null,[334,58],[334,75]]]]],[],[]],"size","100"],["loc",[null,[334,32],[334,88]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[337,41],[337,79]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","objOcevidac.selectedRow.maticniBroj",["loc",[null,[338,58],[338,93]]]]],[],[]]],["loc",[null,[338,32],[338,95]]]],
        ["inline","t",["obrObrazac03.povredniList.adresaPrebivalista"],[],["loc",[null,[341,41],[341,93]]]],
        ["inline","input",[],["readonly","true","type","text","value",["subexpr","@mut",[["get","objOcevidac.selectedRow.adresa",["loc",[null,[343,52],[343,82]]]]],[],[]]],["loc",[null,[343,10],[343,85]]]],
        ["inline","input",[],["readonly","true","type","text","value",["subexpr","@mut",[["get","objOcevidac.selectedRow.mesto",["loc",[null,[344,52],[344,81]]]]],[],[]]],["loc",[null,[344,10],[344,84]]]],
        ["inline","input",[],["readonly","true","type","text","value",["subexpr","@mut",[["get","objOcevidac.selectedRow.drzava",["loc",[null,[345,52],[345,82]]]]],[],[]]],["loc",[null,[345,10],[345,85]]]],
        ["inline","t",["obrObrazac03.povredniList.labelaNaslov7"],[],["loc",[null,[350,46],[350,93]]]],
        ["inline","t",["obrObrazac03.povredniList.labela45"],[],["loc",[null,[353,44],[353,86]]]],
        ["inline","input",[],["type","text","class","input-number","value",["subexpr","@mut",[["get","modelObject.ukupanBrojPovredjenih",["loc",[null,[354,79],[354,112]]]]],[],[]],"size","100"],["loc",[null,[354,32],[354,125]]]],
        ["inline","t",["obrObrazac03.povredniList.labela46"],[],["loc",[null,[357,44],[357,86]]]],
        ["inline","input",[],["type","text","class","input-number","value",["subexpr","@mut",[["get","modelObject.ukupanBrojPoginulih",["loc",[null,[358,79],[358,110]]]]],[],[]],"size","100"],["loc",[null,[358,32],[358,123]]]],
        ["inline","t",["obrObrazac03.povredniList.labela47"],[],["loc",[null,[361,44],[361,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgRanijaSlicnaPovreda",["loc",[null,[364,54],[364,87]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[364,16],[364,124]]]],
        ["inline","pikaday-input",[],["useUTC",true,"disabled",["subexpr","not",[["get","modelObject.fgRanijaSlicnaPovreda",["loc",[null,[365,58],[365,91]]]]],[],["loc",[null,[365,53],[365,92]]]],"value",["subexpr","@mut",[["get","modelObject.ranijaSlicnaPovredaKada",["loc",[null,[365,99],[365,134]]]]],[],[]],"format","DD.MM.YYYY","class","input-control select input-control-compact"],["loc",[null,[365,16],[365,207]]]],
        ["inline","t",["obrObrazac03.povredniList.labela48"],[],["loc",[null,[369,44],[369,86]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","nullChoice",true,"domainName","SifIzvorPovrede","value",["subexpr","@mut",[["get","modelObject.sifIzvorPovrede.idIzvorPovrede",["loc",[null,[371,115],[371,157]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[371,19],[371,194]]]],
        ["inline","t",["obrObrazac03.povredniList.labela49"],[],["loc",[null,[375,44],[375,86]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","nullChoice",true,"domainName","SifUzrokPovrede","value",["subexpr","@mut",[["get","modelObject.sifUzrokPovrede.idUzrokPovrede",["loc",[null,[377,112],[377,154]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[377,16],[377,191]]]],
        ["inline","t",["obrObrazac03.povredniList.labela50"],[],["loc",[null,[381,41],[381,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.kratakOpisNastankaPovrede",["loc",[null,[382,58],[382,95]]]]],[],[]],"size","100"],["loc",[null,[382,32],[382,108]]]],
        ["inline","t",["obrObrazac03.povredniList.labela51"],[],["loc",[null,[386,44],[386,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgPovredaNaRmSaRizikom",["loc",[null,[388,54],[388,88]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[388,16],[388,125]]]],
        ["content","fgRizicnoPoruka",["loc",[null,[389,16],[389,35]]]],
        ["inline","t",["obrObrazac03.povredniList.labela52"],[],["loc",[null,[393,44],[393,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgPropisaniUslovi",["loc",[null,[395,54],[395,83]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[395,16],[395,120]]]],
        ["inline","t",["obrObrazac03.povredniList.labela53"],[],["loc",[null,[399,44],[399,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgRanijiPregledi",["loc",[null,[401,54],[401,82]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[401,16],[401,119]]]],
        ["content","fgPovredjeniPreglediPoruka",["loc",[null,[402,16],[402,46]]]],
        ["inline","t",["obrObrazac03.povredniList.labela54"],[],["loc",[null,[406,44],[406,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgOsposobljenZaBezbRad",["loc",[null,[408,54],[408,88]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[408,16],[408,125]]]],
        ["content","fgPovredjeniObukePoruka",["loc",[null,[409,16],[409,43]]]],
        ["inline","t",["obrObrazac03.povredniList.labela55"],[],["loc",[null,[413,44],[413,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgPropisanaSredstvaZaRad",["loc",[null,[415,54],[415,90]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[415,16],[415,127]]]],
        ["content","fgPropisanaSredstvaZaRadPoruka",["loc",[null,[416,16],[416,50]]]],
        ["inline","t",["obrObrazac03.povredniList.labela56"],[],["loc",[null,[420,44],[420,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgObezbedjanaSredstvaZaRad",["loc",[null,[422,54],[422,92]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[422,16],[422,129]]]],
        ["inline","t",["obrObrazac03.povredniList.labela57"],[],["loc",[null,[426,44],[426,86]]]],
        ["inline","select-boolean",[],["fullSize",false,"value",["subexpr","@mut",[["get","modelObject.fgKoriscenaSredstvaZaRad",["loc",[null,[428,54],[428,90]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[428,16],[428,127]]]],
        ["inline","t",["obrObrazac03.povredniList.labela58"],[],["loc",[null,[432,41],[432,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.mereBezbednostiPreduzete",["loc",[null,[433,58],[433,94]]]]],[],[]],"size","100"],["loc",[null,[433,32],[433,107]]]],
        ["inline","t",["obrObrazac03.povredniList.datum"],[],["loc",[null,[444,28],[444,67]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datum1",["loc",[null,[446,64],[446,82]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input"],["loc",[null,[446,30],[446,126]]]],
        ["inline","t",["obrObrazac03.povredniList.mesto"],[],["loc",[null,[456,34],[456,73]]]],
        ["inline","t",["obrObrazac03.povredniList.redBrojIzEvidencije"],[],["loc",[null,[463,34],[463,87]]]],
        ["inline","t",["obrObrazac03.povredniList.MP"],[],["loc",[null,[465,34],[465,70]]]],
        ["inline","t",["obrObrazac03.povredniList.potpisOdgovornogLica"],[],["loc",[null,[467,62],[467,116]]]],
        ["inline","t",["obrObrazac03.povredniList.labelaNaslov8"],[],["loc",[null,[482,47],[482,94]]]],
        ["inline","t",["obrObrazac03.povredniList.labela59"],[],["loc",[null,[485,42],[485,84]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.ustanova",["loc",[null,[486,58],[486,78]]]]],[],[]],"size","100"],["loc",[null,[486,32],[486,91]]]],
        ["inline","t",["obrObrazac03.povredniList.labela60"],[],["loc",[null,[489,42],[489,84]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.imeLekara",["loc",[null,[490,58],[490,79]]]]],[],[]],"size","100"],["loc",[null,[490,32],[490,92]]]],
        ["inline","t",["obrObrazac03.povredniList.labela61"],[],["loc",[null,[493,41],[493,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.dijagnoza",["loc",[null,[494,58],[494,79]]]]],[],[]],"size","100"],["loc",[null,[494,32],[494,92]]]],
        ["inline","t",["obrObrazac03.povredniList.labela62"],[],["loc",[null,[497,41],[497,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.spoljniUzrokPovrede",["loc",[null,[498,58],[498,89]]]]],[],[]],"size","100"],["loc",[null,[498,32],[498,102]]]],
        ["inline","t",["obrObrazac03.povredniList.labela63"],[],["loc",[null,[502,41],[502,83]]]],
        ["inline","select-boolean",[],["value",["subexpr","@mut",[["get","modelObject.fgSmrtniIshod",["loc",[null,[503,55],[503,80]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[503,32],[503,117]]]],
        ["inline","t",["obrObrazac03.povredniList.labela64"],[],["loc",[null,[507,41],[507,83]]]],
        ["inline","select-boolean",[],["value",["subexpr","@mut",[["get","modelObject.fgTeskaPovreda",["loc",[null,[508,55],[508,81]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[508,32],[508,118]]]],
        ["inline","t",["obrObrazac03.povredniList.labela65"],[],["loc",[null,[512,41],[512,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.prethodnePovredeOboljenja",["loc",[null,[513,58],[513,95]]]]],[],[]],"size","100"],["loc",[null,[513,32],[513,108]]]],
        ["inline","t",["obrObrazac03.povredniList.labela66"],[],["loc",[null,[516,41],[516,83]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.drugePrimedbe",["loc",[null,[517,58],[517,83]]]]],[],[]],"size","100"],["loc",[null,[517,32],[517,96]]]],
        ["inline","t",["obrObrazac03.povredniList.labela67"],[],["loc",[null,[520,41],[520,83]]]],
        ["inline","select-boolean",[],["value",["subexpr","@mut",[["get","modelObject.fgSprecenDaRadiTriDana",["loc",[null,[521,55],[521,89]]]]],[],[]],"styleClass","input-control-compact"],["loc",[null,[521,32],[521,126]]]],
        ["inline","t",["obrObrazac03.povredniList.datum"],[],["loc",[null,[531,28],[531,67]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datum1",["loc",[null,[533,66],[533,84]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input"],["loc",[null,[533,32],[533,128]]]],
        ["inline","t",["obrObrazac03.povredniList.mesto"],[],["loc",[null,[544,28],[544,67]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.mesto1",["loc",[null,[545,56],[545,74]]]]],[],[]]],["loc",[null,[545,30],[545,76]]]],
        ["inline","t",["obrObrazac03.povredniList.redniBrojLekarskogDnevnika"],[],["loc",[null,[554,34],[554,94]]]],
        ["inline","t",["obrObrazac03.povredniList.MP"],[],["loc",[null,[563,49],[563,85]]]],
        ["inline","t",["obrObrazac03.povredniList.redniBrojUKnjiziEvidencije"],[],["loc",[null,[568,34],[568,94]]]],
        ["inline","t",["obrObrazac03.povredniList.potpisLekara"],[],["loc",[null,[572,62],[572,108]]]],
        ["inline","t",["obrObrazac03.povredniList.orgZdravstvenogOsiguranja"],[],["loc",[null,[592,92],[592,151]]]],
        ["inline","t",["obrObrazac03.povredniList.datum"],[],["loc",[null,[595,46],[595,85]]]],
        ["inline","t",["obrObrazac03.povredniList.mesto"],[],["loc",[null,[602,34],[602,73]]]],
        ["inline","t",["obrObrazac03.povredniList.redBrIzDnevnika"],[],["loc",[null,[609,34],[609,83]]]],
        ["inline","t",["obrObrazac03.povredniList.MP"],[],["loc",[null,[611,49],[611,85]]]],
        ["inline","t",["obrObrazac03.povredniList.potpisLekara"],[],["loc",[null,[613,62],[613,108]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[632,25],[632,30]]]],"create","update"],[],["loc",[null,[632,21],[632,49]]]]],[],["loc",[null,[632,12],[632,51]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[632,103],[632,128]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[633,12],[633,38]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[633,86],[633,111]]]],
        ["inline","select-datatable-lov-modal",[],["id","sifZaposleniLovModal","idModalPanel","sifZaposleniLovModal","target",["subexpr","@mut",[["get","target",["loc",[null,[641,11],[641,17]]]]],[],[]],"isNew",false,"domainName","SifLica","domainId","idLice","obj",["subexpr","@mut",[["get","controllerObj",["loc",[null,[645,8],[645,21]]]]],[],[]],"objId","idLice","objNameArray","ime prezime","tableColumns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[648,17],[648,29]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[649,12],[649,34]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[650,19],[650,36]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[651,13],[651,36]]]]],[],[]],"referenceDomainName","sifZaposleni","referenceDomainId","idZaposleni","refreshBaseTable",["subexpr","@mut",[["get","refreshBaseTable",["loc",[null,[654,21],[654,37]]]]],[],[]],"displayName","obrazac2.izvestaji.izborZaposlenog","moveVertical",true],["loc",[null,[638,0],[657,2]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});