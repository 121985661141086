define('zaris-frontend/controllers/sif-revers', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    tableColumns: Ember['default'].computed(function () {

      var self = this;

      var idColumn = ColumnDefinition['default'].create({
        key: 'idLice',
        savedWidth: 50,
        supportSort: true,
        headerCellName: 'ID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var imePrezimeColumn = ColumnDefinition['default'].create({
        key: 'imePrezime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var imePrezime = row.get("imePrezime");
          return imePrezime;
        }
      });
      var fgDodeljenaLzo = ColumnDefinition['default'].create({
        key: 'fgDodeljenaLzo',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRevers.dodeljenaLZO'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key)) {
            return Ember['default'].String.htmlSafe('<span style="color:green;">' + self.get('i18n').t('sifRevers.dodeljenaLZO') + "</span>");
          } else {
            return Ember['default'].String.htmlSafe('<span style="color:red;">' + self.get('i18n').t('sifRevers.nijeDodeljenaLZO') + "</span>");
          }
        }
      });

      return [idColumn, imePrezimeColumn, fgDodeljenaLzo];
    }),

    actions: {

      initModelObject: function initModelObject(mObject) {
        // postavljanje specificnog modelObjecta

      }

    }

  });

});