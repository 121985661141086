define('zaris-frontend/routes/authenticated', ['exports', 'ember', 'zaris-frontend/conf'], function (exports, Ember, conf) {

  'use strict';

  var Authenticated = Ember['default'].Route.extend({
    loginService: Ember['default'].inject.service('login'),

    accessToken: function accessToken() {
      var loginService = this.get('loginService');

      var accessToken = null;
      var refreshToken = loginService.get('token');

      if (refreshToken === undefined || refreshToken === null || refreshToken === 'null') {
        this.transitionTo('login');
        return;
      }

      $.ajax({
        url: conf['default'].serverBaseUrl + '/oauth/token?grant_type=refresh_token',
        type: 'POST',
        data: { 'refresh_token': refreshToken },
        accepts: 'application/json',
        async: false,
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success(data) {
          accessToken = data.access_token;
        },
        error: function error() {
          console.log('error getting token');
        }
      });

      return accessToken;
    }
  });

  exports['default'] = Authenticated;

});