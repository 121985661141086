define('zaris-frontend/components/obr-obrazac04-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'obrObrazac04Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "dialog",
    "data-width": "60%",
    "data-drag-element": ".window-caption",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    zaposleniObj: {},

    init: function init() {
      this._super();
      var zaposleniObj = this.get('zaposleniObj');
      zaposleniObj.id = null;
      zaposleniObj.naziv = "";
    },

    didInsertElement: function didInsertElement() {
      this.$('#sifLiceLovModal').appendTo(this.$(document.body));
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var modelObject;
          var ObrObrazac04Model;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            modelObject = _this.get('modelObject');

            modelObject.sifZaposleni.idZaposleni = _this.get('zaposleniObj').id;

            ObrObrazac04Model = Models['default'].ObrObrazac04.create({ errorHandlerController: _this });

            ObrObrazac04Model.create(_this.get('modelObject'), _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('obrObrazac04.dodavanjeHeader').string,
                content: i18n.t('obrObrazac04.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      },
      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var modelObject;
          var ObrObrazac04Model;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');

            modelObject = _this2.get('modelObject');

            modelObject.sifZaposleni.idZaposleni = _this2.get('zaposleniObj').id;

            ObrObrazac04Model = Models['default'].ObrObrazac04.create({ errorHandlerController: _this2 });

            ObrObrazac04Model.update(_this2.get('modelObject'), _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("obrObrazac04.azuriranjeHeader").string,
                content: i18n.t("obrObrazac04.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      var modelObject = this.get('modelObject');
      var zaposleniObj = this.get('zaposleniObj');
      if (this.get('isNew')) {
        modelObject.sifOboljenje = {};
        modelObject.sifZaposleni = {};

        // spremi objekat
        this.set('zaposleniObj.naziv', "");
        this.set('zaposleniObj.id', null);
      } else {
        // prepakuj zaposlenog
        this.set("zaposleniObj.naziv", modelObject.sifZaposleni.sifLica.ime + ' ' + modelObject.sifZaposleni.sifLica.prezime);
        this.set('zaposleniObj.id', modelObject.sifZaposleni.idZaposleni);
      }

      Ember['default'].showModalPanel('#' + this.get('id'));
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('#' + this.get('id')).data('dialog').close();
    },

    zaposleniColumns: Ember['default'].computed(function () {

      var self = this;

      var idColumn = ColumnDefinition['default'].create({
        key: 'idLice',
        savedWidth: 50,
        supportSort: true,
        headerCellName: 'ID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var imePrezimeColumn = ColumnDefinition['default'].create({
        key: 'ime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Ime i prezime',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var imePrezime = row.get("ime") + " " + row.get('prezime');
          var sufiks = self.ohsNameSuffix(row.get("liceZaBzr"));
          return imePrezime + sufiks;
        }
      });
      var maticniBrojColumn = ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'JMBG',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var datumRodjenjaColumn = ColumnDefinition['default'].create({
        key: 'datumRodjenja',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Datum rodjenja',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      });
      var polColumn = ColumnDefinition['default'].create({
        key: 'pol',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Pol',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.genderValueNames(row.get(this.key).naziv);
        }
      });
      var adresaColumn = ColumnDefinition['default'].create({
        key: 'adresa',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Adresa',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      return [idColumn, imePrezimeColumn, maticniBrojColumn, datumRodjenjaColumn, polColumn, adresaColumn];
    })
  });

});