define('zaris-frontend/controllers/obr-obrazac06-sacuvani', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    refreshObr06: false,

    actions: {
      refreshTable: function refreshTable() {
        this.toggleProperty('refreshObr06');
      }
    },

    tableColumns: Ember['default'].computed(function () {
      var self = this;
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'obrObrazac06Obuka',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.imeIPrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get('obrObrazac06Obuka.sifZaposleni.sifLica.ime') + ' ' + row.get('obrObrazac06Obuka.sifZaposleni.sifLica.prezime');
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'obrObrazac06Obuka.sifZaposleni.sifRadnoMestoStruktura.sifRadnoMesto.naziv',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.radnoMesto'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'obrObrazac06Obuka.datumTeorijskogOsposobljavanja',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.teoretskaObuka'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'obrObrazac06Obuka.datumPrakticnogOsposobljavanja',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.prakticnaObuka'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'obrObrazac06Obuka.datumTeorijskeProvere',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.teoretskaProvera'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'obrObrazac06Obuka.datumPrakticneProvere',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.prakticnaProvera'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'obrObrazac06Obuka.datumPotpisaObrasca',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.potpisana'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'skeniranFileName',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.skeniran'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) != null) {
            return Ember['default'].String.htmlSafe('<div class="circle bg-emerald" />');
          } else {
            return Ember['default'].String.htmlSafe('<div class="circle bg-red" />');
          }
        }
      }));

      return columns;
    })

  });

});