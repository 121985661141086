define('zaris-frontend/components/select-datatable', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    i18n: Ember['default'].inject.service('i18n'),

    renderButtonObrisiIzbor: true,

    required: false,

    placeholderLabelTranslated: Ember['default'].computed('placeholderLabel', function () {
      var i18n = this.get('i18n');
      var placeholder = this.get('placeholderLabel');
      return i18n.t(placeholder).string;
    }),

    actions: {
      openModal: function openModal() {
        this.set('objToSet', this.get('obj'));
        this.set('objToSet.objNameToSet', this.get('objNameToSet'));
        //this.toggleProperty('refresh');
        this.toggleProperty('modalPanelShow');
      },
      defaultId: function defaultId() {
        var i18n = this.get('i18n');
        var obj = {};
        obj.id = null;
        obj.naziv = i18n.t(this.get('label')).string;
        this.set('obj', obj);
      }
    }
  });

});