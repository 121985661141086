define('zaris-frontend/components/sif-elementi-pregleda-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifElementiPregledaModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifElementPregledaModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            sifElementPregledaModel = Models['default'].SifElementPregleda.create({ errorHandlerController: _this });

            sifElementPregledaModel.create(_this.get('modelObject'), _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('sifElementiPregleda.dodavanjeHeader').string,
                content: i18n.t('sifElementiPregleda.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifElementiPregleda.dodavanjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      },
      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifElementPregledaModel;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');

            sifElementPregledaModel = Models['default'].SifElementPregleda.create({ errorHandlerController: _this2 });

            sifElementPregledaModel.update(_this2.get('modelObject'), _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("sifElementiPregleda.azuriranjeHeader").string,
                content: i18n.t("sifElementiPregleda.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifElementiPregleda.azuriranjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      Ember['default'].showModalPanel('.sifElementiPregledaModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifElementiPregledaModal').data('dialog').close();
    }
  });

});