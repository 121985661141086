define('zaris-frontend/components/obr-obrazac1-unos', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'obrObrazac1Unos', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",

    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    /*didInsertElement: function(){
      this._super();
      Ember.run.scheduleOnce('afterRender', this, this.initSelectItems);
    },
      initSelectItems: function() {
      // dovlacenje itema za select listu
      var loginService = this.get('login');
      var sifElementPregledaModel = Models.SifElementPregleda.create({errorHandlerController: this});
      this.set('selectItems', sifElementPregledaModel.findAllGetResult(loginService.getAccessToken()));
    },*/

    initCreate: function initCreate() {},

    initUpdate: function initUpdate() {},

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      if (this.get('isNew')) {
        this.initCreate();
      } else {
        this.initUpdate();
      }

      Ember['default'].showModalPanel('.obrObrazac1Unos');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.obrObrazac1Unos').data('dialog').close();
    }
  });

});