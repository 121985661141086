define('zaris-frontend/tests/helpers/resolver', ['exports', 'ember/resolver', 'zaris-frontend/config/environment'], function (exports, Resolver, config) {

  'use strict';

  var resolver = Resolver['default'].create({ errorHandlerController: undefined });

  resolver.namespace = {
    modulePrefix: config['default'].modulePrefix,
    podModulePrefix: config['default'].podModulePrefix
  };

  exports['default'] = resolver;

});