define('zaris-frontend/components/select-datatable-lov-modal2', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel5', 'sifDatatableLovModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "50%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel5Overlay",
    "data-type": "modal",

    moveVertical: false,
    content: Em.A(),
    modelObject: {},
    myModalPanelShow: false,

    refresh: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    feed: Ember['default'].inject.service('feed'),

    listen: (function () {
      this.get('feed').on(this.get('id'), this, 'setObj');
    }).on('init'),

    setObj: function setObj(obj) {
      this.set('objToSet', obj);
    },

    cleanup: (function () {
      this.get('feed').off(this.get('id'), this, 'setObj');
    }).on('willDestroyElement'),

    saveEnabled: false,

    saveButtonConditionChanged: Ember['default'].observer('selectedRow', function () {
      this.set('saveEnabled', this.get('selectedRow') !== null && Object.keys(this.get('selectedRow')).length !== 0);
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      choose: function choose() {

        var obj = {};
        obj.naziv = "";

        var nameArray = this.get('objNameArray').split(" ");

        for (var i = 0; i < nameArray.length; i++) {
          obj.naziv += this.get('selectedRow')[nameArray[i]] ? this.get('selectedRow')[nameArray[i]] : "";
          obj.naziv += i < nameArray.length - 1 ? " " : "";
        }

        obj.id = this.get('selectedRow')[this.get('objId')];
        obj.selectedRow = this.get('selectedRow');

        this.set('objToSet.selectedRow', obj.selectedRow);
        this.set('objToSet.id', obj.id);
        this.set('objToSet.naziv', obj.naziv);

        $('#' + this.get('id')).data('dialog').close();
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refresh');

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('#' + this.get('id'));

      if (this.get('moveVertical') == true) {
        var offset = window.pageYOffset;
        $('#' + this.get('id')).css({ top: offset + 'px' });
      }
    }),

    closePanel: function closePanel() {
      $('#' + this.get('id')).data('dialog').close();
    }

  });

});