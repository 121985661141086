define('zaris-frontend/routes/sem-podesavanje-obavestenja', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    login: Ember['default'].inject.service('login'),

    model: function model() {
      var loginService = this.get('login');
      var semPodesavanjaModel = Models['default'].SemSemaforPodesavanja.create({ errorHandlerController: this });
      var items = semPodesavanjaModel.findAllGetResult(loginService.getAccessToken());

      return Ember['default'].RSVP.hash({
        podesavanja: items
      });
    },

    actions: {
      refreshData: function refreshData() {
        this.refresh();
      }
    }
  });

});