define('zaris-frontend/controllers/sem-podesavanje-obavestenja', ['exports', 'ember', 'zaris-frontend/models/models', 'zaris-frontend/conf'], function (exports, Ember, Models, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service('i18n'),
    login: Ember['default'].inject.service('login'),

    actions: {
      save: function save() {
        var i18n = this.get('i18n');
        var podesavanja = this.get('model.podesavanja');

        podesavanja.forEach(function (val, index) {
          if (val.fgPeriodicno) {
            if (val.brojDana === 0) {
              val.fgPrikazi = false;
            } else {
              val.fgPrikazi = true;
            }
          }
        });

        var semPodesavanjaModel = Models['default'].SemSemaforPodesavanja.create({ errorHandlerController: this });
        semPodesavanjaModel.postResult('azuriranjePodesavanja', podesavanja, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('sem.podesavanjeObavestenjaHeader').string,
            content: i18n.t('sem.podesavanjeObavestenjaUspesno').string,
            keepOpen: false,
            type: 'success'
          });
        }, function (e) {
          $.Notify({
            caption: i18n.t('sem.podesavanjeObavestenjaHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: false,
            type: 'alert'
          });
        });
      },

      reset: function reset() {
        var i18n = this.get('i18n');
        var outter = this;

        var semPodesavanjaModel = Models['default'].SemSemaforPodesavanja.create({ errorHandlerController: this });
        semPodesavanjaModel.postResult('resetPodesavanja', null, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('sem.podesavanjeObavestenjaHeader').string,
            content: i18n.t('sem.resetovanjeObavestenjaUspesno').string,
            keepOpen: false,
            type: 'success'
          });

          outter.send("refreshData");
        }, function (e) {
          console.log(e);
          $.Notify({
            caption: i18n.t('sem.podesavanjeObavestenjaHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: false,
            type: 'alert'
          });
        });
      }
    }

  });

});