define('zaris-frontend/components/sif-lzo-velicine-lov-new-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'sifLzoVelicineLovNewModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",

    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this._super();
      //Ember.run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {
      /* dovlacenje itema za select listu*/
      var loginService = this.get('login');
      var SifLzoVrstaVelicineTreeModel = Models['default'].SifLzoVrstaVelicineTree.create({ errorHandlerController: this });
      this.set('selectItems', SifLzoVrstaVelicineTreeModel.selectItemsGetResult(loginService.getAccessToken(), this.get('customId')));
    },

    initCreate: function initCreate() {
      console.log('initCreate ', this.get('customId'));

      var idLice = this.get('customId');
      if (idLice != null) {
        this.initSelectItems();
      }

      var obj = {};
      obj[this.get('referenceDomainName')] = {};
      obj[this.get('referenceDomainName')][this.get('referenceDomainId')] = this.get('customId');
      obj.sifLzoVrstaVelicine = {};
      obj.sifLzoVrstaVelicine.idLzoVrstaVelicine = this.get('selectItems') !== null && this.get('selectItems')[0] !== null && this.get('selectItems')[0] !== undefined ? this.get('selectItems')[0].value : 0;

      this.set('modelObjectLzoVelicine', obj);
    },

    initUpdate: function initUpdate() {
      var selected = jQuery.extend(true, {}, this.get('selectedRow'));
      console.log('initUpdate ', this.get('customId'));
      this.set('modelObjectLzoVelicine', selected);
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      merge: function merge() {
        var outter = this;
        var isNew = this.get('isNew');
        var i18n = this.get('i18n');

        var obj = this.get('modelObjectLzoVelicine');

        var sifLicaLzoVelicineModel = Models['default'][this.get('domainName')].create({ errorHandlerController: this });
        sifLicaLzoVelicineModel.create(obj, this.get('login').getAccessToken(), function () {
          if (isNew) {
            $.Notify({
              caption: i18n.t('sifLzoVrsteVelicina.dodavanjeHeader').string,
              content: i18n.t('sifLzoVrsteVelicina.dodavanjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
          } else {
            $.Notify({
              caption: i18n.t('sifLzoVrsteVelicina.azuriranjeHeader').string,
              content: i18n.t('sifLzoVrsteVelicina.azuriranjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
          }

          outter.closePanel();
          outter.toggleProperty('refreshLzoVelicine');
          outter.toggleProperty('refreshWoResetSelection');
        }, function (e) {
          if (isNew) {
            $.Notify({
              caption: i18n.t('sifLzoVrsteVelicina.dodavanjeHeader').string,
              content: i18n.t(e.responseJSON.messages[0]).string,
              keepOpen: true,
              type: 'alert'
            });
          } else {
            $.Notify({
              caption: i18n.t('sifLzoVrsteVelicina.azuriranjeHeader').string,
              content: i18n.t(e.responseJSON.messages[0]).string,
              keepOpen: true,
              type: 'alert'
            });
          }
        });
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      if (this.get('isNew')) {
        this.initCreate();
      } else {
        this.initUpdate();
      }

      Ember['default'].showModalPanel('.sifLzoVelicineLovNewModal');
    }),

    closePanel: function closePanel() {
      $('.sifLzoVelicineLovNewModal').data('dialog').close();
    }
  });

});