define('zaris-frontend/components/obr-obrazac14-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac14Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-width": "60%",
    "data-drag-element": ".window-caption",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      save: function save() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var modelObject;
          var ObrObrazac14Model;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            modelObject = _this.get('modelObject');
            ObrObrazac14Model = Models['default'].ObrObrazac14PrijavaPojave.create({ errorHandlerController: _this });

            ObrObrazac14Model.create(modelObject, _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('obrObrazac14.snimanjeHeader').string,
                content: i18n.t('obrObrazac14.snimanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      var modelObject = this.get('modelObject');

      this.set('modelObject', jQuery.extend(true, {}, this.get('selectedRow')));

      Ember['default'].showModalPanel('#' + this.get('id'));
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('#' + this.get('id')).data('dialog').close();
    }

  });

});