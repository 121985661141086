define('zaris-frontend/components/sif-lzo-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifLzoModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},
    selectedRow: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    didInsertElement: function didInsertElement() {
      this._super();
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {
      var loginService = this.get('login');
      var i18n = this.get('i18n');
      var vsifLzoModel = Models['default'].SifLzoVrstaVelicineTree.create({ errorHandlerController: this });
      var selectItemsArray = vsifLzoModel.findAllGetResult(loginService.getAccessToken());
      var nemaVrstuItem = { idLzoVrstaVelicine: null, naziv: i18n.t('sifLzoKategorija.nemaVrstuVelicine').string };
      selectItemsArray.unshift(nemaVrstuItem);
      this.set('selectItems', selectItemsArray);
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var ids;
          var obj;
          var vsifLzoModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');
            ids = _this.get('selectedRow.id').split('|');
            obj = {};

            obj.naziv = _this.get('modelObject.naziv');
            obj.nivo = _this.get('selectedRow.level') + 1;
            obj.sifLzoKategorija = {};
            obj.sifLzoKategorija.idLzoKategorija = ids[1];
            obj.rok = _this.get('modelObject.rok');
            obj.intervalPregleda = _this.get('modelObject.interval');

            vsifLzoModel = Models['default'].SifLzoTree.create({ errorHandlerController: _this });

            vsifLzoModel.create(obj, _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('sifLzoVrsteVelicina.dodavanjeHeader').string,
                content: i18n.t('sifLzoVrsteVelicina.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifLzoVrsteVelicina.dodavanjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      },
      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var vsifLzoModel;
          var ids;
          var obj;

          (function () {
            var i18n = _this2.get('i18n');
            outter = _this2;
            vsifLzoModel = Models['default'].SifLzoTree.create({ errorHandlerController: _this2 });
            ids = _this2.get('selectedRow.id').split('|');
            obj = {};

            obj.id = ids[1];
            obj.naziv = _this2.get('modelObject.naziv');
            obj.idLzo = _this2.get('modelObject.id_lzo');
            obj.idParent = _this2.get('modelObject.id_lzo_kategorija');
            obj.rokTrajanja = _this2.get('modelObject.rok');
            obj.intervalIspitivanja = _this2.get('modelObject.interval');
            obj.idVrstaVelicine = _this2.get('modelObject.id_lzo_vrsta_velicine');

            vsifLzoModel.update(obj, _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("sifLzoVrsteVelicina.azuriranjeHeader").string,
                content: i18n.t("sifLzoVrsteVelicina.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifLzoVrsteVelicina.azuriranjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {

      if (!this.get('isNew')) {
        var obj = this.get('modelObject');
        if (obj.id_lzo) {
          obj.naziv = obj.group_value;
          obj.interval = obj.values[2].value;
          obj.rok = obj.values[1].value;
        } else {
          obj.naziv = obj.group_value;
        }

        this.set('modelObject', obj);
      }

      $('.sifLzoModal').data('dialog').open();
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifLzoModal').data('dialog').close();
    }
  });

});