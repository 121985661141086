define('zaris-frontend/locales/en/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    "app.title": "Bazis",

    "app.home.welcomeMsg": "Welcome to information system BAZIS",
    "app.home.sifarnici": "Codebooks",
    "app.home.obrasci": "Forms",
    "app.home.obavestenja": "Notification",
    "app.home.obavestenjaNemaRezultata": "No result",
    "app.home.obavestenjaDisclaimer": "Notice: data is refreshed every 5 min. Click on the icon in table header for on-demand data refresh.",

    "app.login.username": "Username",
    "app.login.password": "Password",
    "app.login.login": "Login",

    "language-select.language.sr": "Latin - Serbian",
    "language-select.language.en": "English",
    "language-select.language.sr-cyrl": "Cyrilic - Serbian",

    "app.menu.evidencija_o_korisicki_definisanim_obukama": "Records on user-defined trainings",
    "app.menu.unos": "Data entry",
    "app.menu.opasnosti_i_stetnosti": "Dangers and hazards",
    "app.menu.korisnicki_definisane_obuke": "User-defined training",
    "app.menu.evidencija_o_izvrsenim_pregledima_i_ispitivanjima_sredstava_i_opreme_za_licnu_zastitu_na_radu": "Personal safety equipment inspection",
    "app.menu.evidencija_o_izdatim_sredstvima_i_lzo": "Personal safety equipment assignment",
    "app.menu.izvestaji": "Reports",
    "app.menu.parametri_radne_okoline": "Environmental parameters",
    "app.menu.podesavanja": "Settings",
    "app.menu.izvestaji_za_sifarnike": "Izveštaji za šifarnike",
    "app.menu.pocetna": "Home",
    "app.menu.evidencija_o_prijavama_bolesti_u_vezi_sa_radom": "Records on reported work-related illnesses",
    "app.menu.baza_znanja": "Knowledge DB",
    "app.menu.elementi_pregleda": "Medical evaluation elements",
    "app.menu.evidencija_o_radnim_mestima_sa_povecanim_rizikom": "Hazardous Workplaces",
    "app.menu.nivoi_rizika": "Levels of risk",
    "app.menu.evidencija_o_zaposlenima": "Employee",
    "app.menu.odjava": "Logout",
    "app.menu.sifarnici": "Code Tables",
    "app.menu.evidencija_o_prijavama_opasnih_pojava_koje_bi_mogle_da_ugroze_bezbednost_i_zdravlje_zaposlenih": "Records on reported events that could endanger employees safety and health",
    "app.menu.zaposleni": "Employee",
    "app.menu.evidencija_o_korisnicki_definisanim_pregledima": "Records on user-defined medical evaluations",
    "app.menu.evidencija_o_korisnicki_definisanim_obukama": "Evidencija o korisnički definisanim obukama",
    "app.menu.oprema_za_rad": "Equipment",
    "app.menu.evidencija_o_organizacionoj_strukturi": "Evidencija o organizacionoj strukturi",
    "app.menu.evidencija_o_opasnim_materijama_koje_se_koriste_u_toku_dana": "Hazardous substances used at workplace",
    "app.menu.organizaciona_struktura": "Organizational structure",
    "app.menu.korisnicki_definisani_pregledi": "User-defined medical evaluations",
    "app.menu.evidencija_o_licima": "Personal records",
    "app.menu.evidencija_o_radnim_mestima": "Evidencija o radnim mestima",
    "app.menu.evidencija_o_zaposlenima_rasporedjenim": "Employees working on significant risk workplaces (medical evaluation)",
    "app.menu.evidencija_o_izvrsenim_pregledima_i_ispitivanjima_opreme_za_rad": "Equiment inspections",
    "app.menu.reversi": "Assigned еquipment",
    "app.menu.lica": "Persons",
    "app.menu.opasne_materije": "Hazardous materials",
    "app.menu.evidencija_o_prijavama_profesionalnih_oboljenja": "Records on reported professional diseases",
    "app.menu.sacuvani_izvestaji": "Saved forms",
    "app.menu.radna_mesta_u_strukturi": "Workplaces in organizational structure",
    "app.menu.evidencija_o_radnim_mestima_u_strukturi": "Records on working places in structure",
    "app.menu.radna_mesta": "Workplaces",
    "app.menu.evidencija_o_povredama_na_radu": "Work related injuries",
    "app.menu.evidencija_o_profesionalnim_oboljenjima": "Work-Related professional diseases",
    "app.menu.licna_zastitna_oprema": "Personal protective equipment",
    "app.menu.vrste_velicina_za_lzo": "PPE size types",
    "app.menu.evidencija_o_prijavama_povreda_na_radu": "Reported work-related injuries",
    "app.menu.evidencija_o_bolestima_u_vezi_sa_radom": "Work-Related Ilnesses",
    "app.menu.evidencija_o_zaposlenima_osposobljenim_za_bezbedan_i_zdrav_rad": "Training record",
    "app.menu.pomoc": "Help",
    "app.menu.evidencija_o_stetnostima_na_svim_radnim_mestima": "All Workplaces",
    "app.menu.evidencija_o_izvrsenim_ispitivanjima_radne_okoline": "Environmental parameters inspections",
    "app.menu.dodeljivanje_pregleda_zaposlenima": "Add medical exam to employees",
    "app.menu.dodeljivanje_obuke_za_bezbedan_i_zdrav_rad": "Add safe and protective training to employees",
    "app.menu.dodeljivanje_obuka_zaposlenima": "Add training to employees",
    "app.menu.evidencija_o_izvesenim_ispitivanjima_radne_okoline": "Completed environmental parameters inspections",
    "app.menu.pravnaLica": "Client",
    "app.menu.korisniciZaPravnoLice": "User for client",
    "app.menu.promena_passworda": "Change password",
    "app.menu.podesavanja_obavestenja": "Notice settings",
    "app.menu.promena_jezika": "Change language",
    "app.menu.izvestaj_o_licnoj_zastitnoj_opremi": "PPE report",
    "app.menu.vertikalni_izvestaj": "Vertical report",

    "app": {
      "menu": {
        "akronim": {
          "ob_01": "FM 01",
          "ob_02": "FM 02",
          "ob_03": "FM 03",
          "ob_04": "FM 04",
          "ob_05": "FM 05",
          "ob_06": "FM 06",
          "ob_07": "FM 07",
          "ob_08": "FM 08",
          "ob_09": "FM 09",
          "ob_10": "FM 10",
          "ob_11": "FM 11",
          "ob_12": "FM 12",
          "ob_13": "FM 13",
          "ob_14": "FM 14"
        }
      }
    },

    "app.common.modalYes": "YES",
    "app.common.modalNo": "NO",
    "app.common.create": "Save",
    "app.common.cancel": "Cancel",
    "app.common.close": "Close",
    "app.common.choose": "Choose",
    "app.common.chooseAll": "Choose all",
    "app.common.Yes": "Yes",
    "app.common.No": "No",
    "app.common.unauthorized": "Unauthorized!!!",
    "app.common.emptyString": "",

    "app.common.dodaj": "Add",
    "app.common.izmeni": "Edit",
    "app.common.obrisi": "Delete",
    "app.common.obrisiIzbor": "Cancel choice",
    "app.common.preuzmi": "Download",
    "app.common.generisi": "Generate",
    "app.common.svi": "All",
    "app.common.aktivni": "Active",
    "app.common.arhivirani": "Inactive",
    "app.common.sacuvaj": "Save",
    "app.common.unosPodataka": "Data input",
    "app.common.skeniraniDokument": "Scan document",

    "app.common.fileUploadError": "File upload error!",
    "app.common.fileDownloadError": "File download error!",
    "app.common.fileDeleteError": "File delete error!",

    "app.common.radnoMesto": "Workplace",
    "app.common.radnoMestoStruktura": "Workplaces in organizational structure",

    "app.common.izvestajLekara": "Medical evaluation request form",
    "app.common.odaberiteJezik": "Choose language",

    "app.errors": {
      "dataIntegrityViolationException": "Cant delete selected row, because it is used in other parts of system!",
      "entityReferenced": {
        "obr_obrazac02a_specijalan_pregled": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 2a!",
        "obr_obrazac02_element_zdravstvenog_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 2!",
        "obr_obrazac01a_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 1a!",
        "obr_obrazac02_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 2!",
        "obr_obrazac03_povredni_list": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 3!",
        "obr_obrazac02a_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 2a!",
        "obr_obrazac02_zdravstveni_pregled": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 2!",
        "obr_obrazac01_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 1!",
        "obr_obrazac04_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 4!",
        "obr_obrazac03_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 3!",
        "obr_obrazac05_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 5!",
        "obr_obrazac06a_specijalna_obuka": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6a!",
        "obr_obrazac04_zaposleni_oboljenje": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 4!",
        "obr_obrazac06_element_zakazane_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "obr_obrazac06_obuka_zakazana": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "obr_obrazac06_obuka": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "obr_obrazac07_opasna_materija_rm": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac05_zaposleni_bolest": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 5!",
        "obr_obrazac06_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 6!",
        "obr_obrazac07_rms": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac08_ispitivanje_okoline": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 8!",
        "obr_obrazac09_pregled_opreme": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 9!",
        "obr_obrazac08_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 8!",
        "obr_obrazac10_lzo": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 10!",
        "obr_obrazac09_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 9!",
        "obr_obrazac10_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 10!",
        "obr_obrazac07_rm": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac10_lzo_pregledi": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 10!",
        "obr_obrazac07_opasna_materija_rms": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac07_rms_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac12_prijava_oboljenje": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 12!",
        "obr_obrazac13_prijava_bolest": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 13!",
        "obr_obrazac12_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 12!",
        "obr_obrazac13_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 13!",
        "obr_obrazac1_rm_opasnosti": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 1!",
        "obr_obrazac14_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 14!",
        "obr_obrazac1_rms_opasnosti": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 1!",
        "obr_obrazac11_prijava_povrede": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 11!",
        "obr_obrazac14_prijava_pojave": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 14!",
        "sif_lzo": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku LZO!",
        "sif_bolest": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku bolesti!",
        "sif_element_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku elemenata pregleda!",
        "sif_izvor_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku izvora povreda!",
        "sif_lica": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku lica!",
        "sif_lica_lzo_kategorije": "Ne možete obrisati odabrani entitet jer se koristi za LZO kategorije lica!",
        "sif_lica_lzo_velicine": "Ne možete obrisati odabrani entitet jer se koristi za LZO veličine lica!",
        "sif_lica_pregledi": "Ne možete obrisati odabrani entitet jer se koristi za preglede lica!",
        "sif_lica_obuke": "Ne možete obrisati odabrani entitet jer se koristi za obuke lica!",
        "sif_opasna_materija": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku opasnih materija!",
        "sif_mesto_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku mesta povrede!",
        "sif_nivo_rizika": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku nivoa rizika!",
        "sif_opasnosti_stetnosti": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku opasnosti i štetnosti!",
        "sif_lzo_kategorija": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku kategorija LZO!",
        "sif_oboljenje": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku oboljenja!",
        "sif_obuka": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku obuka!",
        "sif_lzo_vrsta_velicine": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku LZO vrsta veličina!",
        "sif_opasna_materija_klasa": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku klasa opasnih materija!",
        "sif_revers": "Ne možete obrisati odabrani entitet jer se koristi u reversima!",
        "sif_radno_mesto_str_lzo": "Ne možete obrisati odabrani entitet jer se koristi u LZO za radna mesta u strukturi!",
        "sif_radno_mesto": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku radnih mesta!",
        "sif_radno_mesto_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obukama za radna mesta!",
        "sif_radno_mesto_pregledi": "Ne možete obrisati odabrani entitet jer se koristi u pregledima za radna mesta!",
        "sif_radno_mesto_struktura": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku radnih mesta u strukturi!",
        "sif_radno_mesto_str_pregledi": "Ne možete obrisati odabrani entitet jer se koristi u pregledima za radna mesta u strukturi!",
        "sif_oprema_za_rad": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku opreme za rad!",
        "sif_parametar_okoline": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku parametara okoline!",
        "sif_revers_lzo": "Ne možete obrisati odabrani entitet jer se koristi u LZO za reverse!",
        "sif_radno_mesto_str_el_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u elementima pregleda za radna mesta u strukturi!",
        "sif_radno_mesto_el_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u elementima pregleda za radna mesta!",
        "sif_radno_mesto_str_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obukama za radna mesta u strukturi!",
        "sif_tezina_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku težine povrede!",
        "sif_zaposleni": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku zaposlenih!",
        "sif_zdravstveni_pregled": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku zdravstvenih pregleda!",
        "sif_zdravstvena_sposobnost": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku zdravstvene sposobnosti!",
        "sif_uzrok_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku uzroka povrede!",
        "obr_obrazac03_povredni_list_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 3!",
        "sif_lica_el_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u elementima pregleda lica!",
        "obr_obrazac07_rm_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 7!",
        "obr_obrazac06_element_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "sif_obuka_zakazana": "Ne možete obrisati odabrani entitet jer se koristi u zakazanim obukama!",
        "obr_obrazac11_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 11!",
        "sif_radno_mesto_lzo": "Ne možete obrisati odabrani entitet jer se koristi u LZO za radna mesta!",
        "sif_zdravstveni_pregled_zakazan": "Ne možete obrisati odabrani entitet jer se koristi u zakazanim pregledima!",
        "sys_organizaciona_jedinica": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku organizacionih jedinica!"
      }
    },

    "pol": {
      "muski": "Male",
      "zenski": "Female"
    },

    "bzr": "OHS",

    "sifNivoiRizika": {
      "brisanjeHeader": "Delete level of risk",
      "brisanjePitanje": "Are you sure to delete selected level of risk?",
      "brisanjeUspesno": "Successful data delete!",
      "dodavanjeHeader": "Add level of risk",
      "dodavanjeUspesno": "Level of risk added!",
      "azuriranjeHeader": "Update level of risk",
      "azuriranjeUspesno": "Level of risk updated!",
      "inputSifra": "Ordinal number",
      "inputNaziv": "Name",
      "inputNapomena": "Remark"
    },

    "sifKorisnickiDefinisaneObuke": {
      "brisanjeHeader": "Brisanje obuke",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu obuku?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Add a user-defined training",
      "dodavanjeUspesno": "Uspešno ste dodali obuku!",
      "azuriranjeHeader": "Ažuriranje obuke",
      "azuriranjeUspesno": "Uspešno ste ažurirali obuku!",
      "inputNaziv": "Name",
      "inputOpis": "Description",
      "inputTip": "Type",
      "inputNazivObuke": "Name",
      "inputIntervalMeseci": "Interval (in months)",
      "sifarnikObuke": "Šifarnik obuka",
      "periodicno": "Periodically",
      "nijePeriodicno": "Odabrana obuka nije periodična",
      "obukaSeObavljaJednom": "Obuka se obavlja jednom",
      "sinhronizacijaObukaHeader": "Sinhronizacija obuka",
      "sinhronizacijaObukaMsg": "Da li želite da izvršite sinhronizaciju obuka u obrascima prema tekućoj promeni?",
      "sinhronizacijaObukaSucc": "Sinhronizacija obuka uspešna",
      "sinhronizacijaObukaErr": "Sinhronizacija obuka neuspešna"
    },

    "sifKorisnickiDefinisaniPregledi": {
      "brisanjeHeader": "Brisanje pregleda",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani pregled?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Add a user-defined evaluation",
      "dodavanjeUspesno": "Uspešno ste dodali pregled!",
      "azuriranjeHeader": "Ažuriranje pregleda",
      "azuriranjeUspesno": "Uspešno ste ažurirali pregled!",
      "inputNaziv": "Name",
      "inputOpis": "Description",
      "inputTip": "Type",
      "inputNazivPregledi": "Name",
      "inputIntervalMeseci": "Interval (in months)",
      "sifarnikPregleda": "Šifarnik pregleda",
      "periodicno": "Periodically",
      "nijePeriodicno": "Odabrani pregled nije periodičan",
      "pregledSeObavljaJednom": "Pregled se obavlja jednom",
      "sinhronizacijaPregledaHeader": "Sinhronizacija pregleda",
      "sinhronizacijaPregledaMsg": "Da li želite da izvršite sinhronizaciju pregleda u obrascima prema tekućoj promeni?",
      "sinhronizacijaPregledaSucc": "Sinhronizacija pregleda uspešna",
      "sinhronizacijaPregledaErr": "Sinhronizacija pregleda neuspešna"
    },

    "sifParametriRadneOkoline": {
      "brisanjeHeader": "Brisanje parametra",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani parametar?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Dodavanje parametra",
      "dodavanjeUspesno": "Uspešno ste dodali parametar!",
      "azuriranjeHeader": "Ažuriranje parametra",
      "azuriranjeUspesno": "Uspešno ste ažurirali parametar!",
      "inputNaziv": "Naziv",
      "inputSifra": "Šifra",
      "inputKategorija": "Kategorija"
    },

    "sifOpasneMaterije": {
      "brisanjeHeader": "Brisanje opasne materije",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu materiju?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Dodavanje opasne materije",
      "dodavanjeUspesno": "Uspešno ste dodali opasnu materiju!",
      "azuriranjeHeader": "Ažuriranje opasne materije",
      "azuriranjeUspesno": "Uspešno ste ažurirali opasnu materiju!",
      "inputNaziv": "Naziv",
      "inputHemijskoIme": "Hemijsko ime",
      "inputUN": "UN",
      "inputADR": "ADR",
      "inputRID": "RID",
      "inputCAS": "CAS",
      "napomena1": "Napomena 1",
      "napomena2": "Napomena 2",
      "obelezavanjeDodatno": "Obeležavanje dodatno",
      "obelezavanjeObavestenje": "Obeležavanje obaveštenje",
      "obelezavanjePiktogram": "Obeležavanje piktogram",
      "klasifikacijaObavestenje": "Klasifikacija obaveštavanje",
      "klasifikacijaKlase": "Klasifikacija klase",
      "specificneGranicneKoncentracije": "Specifične granične konc",
      "granicneKoncentracije": "Granične koncentracije",
      "obelezavanje": "Obeležavanje",
      "klasifikacija": "Klasifikacija",
      "ecBroj": "EC broj",
      "indeksBroj": "Indeks broj",
      "casBroj": "CAS broj"
    },

    "sifOrganizacionaJedinica": {
      "brisanjeHeader": "Brisanje organizacione jedinice",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu organizacionu jedinicu?",
      "brisanjeUspesno": "Uspešno ste obrisali odabranu organizacionu jedinicu!",
      "dodavanjeHeader": "Dodavanje organizacione jedinice",
      "dodavanjeUspesno": "Uspešno ste dodali organizacionu jedinicu!",
      "azuriranjeHeader": "Ažuriranje organizacione jedinice",
      "azuriranjeUspesno": "Uspešno ste ažurirali organizacionu jedinicu!",
      "inputNaziv": "Naziv",
      "izabraniNivoImaDecu": "Izabrani nivo organizacione strukture se ne može izbrisati jer je za njega vezan barem jedan niži nivo."
    },

    "sifElementiPregleda": {
      "brisanjeHeader": "Brisanje elementa pregleda",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani element pregleda?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Dodavanje elementa pregleda",
      "dodavanjeUspesno": "Uspešno ste dodali element pregleda!",
      "azuriranjeHeader": "Ažuriranje elementa pregleda",
      "azuriranjeUspesno": "Uspešno ste ažurirali element pregleda!",
      "inputIntervalMeseci": "Interval lekarskog pregleda",
      "inputElementPregleda": "Element lekarskog pregleda",
      "napomena": "*Napomena: Intervali za elemente lekarskog pregleda ne mogu da se postave" + " na vrednosti veće od propisane, mogu samo da budu manji ili jednaki propisanim intervalima.",
      "opisZaPrethodniPregled": "Opis za prethodni pregled",
      "opisZaPeriodicniPregled": "Opis za periodični pregled",
      "sifarnikElemenataPregleda": "Šifarnik el. pregleda",
      "sinhronizacijaElPregledaHeader": "Sinhronizacija elementa pregleda",
      "sinhronizacijaElPregledaSucc": "Sinhronizacija elementa pregleda uspešna",
      "sinhronizacijaElPregledaErr": "Sinhronizacija elementa pregleda neuspešna",
      "intervalObavestenje": "Intervali za elemente lekarskog pregleda ne mogu da se postave" + " na vrednosti veće od propisane."
    },

    "sifRadnoMestoStruktura": {
      "radnoMestoNaziv": "Radno mesto",
      "organizacionaJedinicaNaziv": "Organizaciona struktura",
      "sifra": "Šifra",
      "elementiPregledaUkupno": "El. preg.",
      "obukeUkupno": "Obuke",
      "preglediUkupno": "Pregledi",
      "FgRizicno": "Rizično",
      "novoRadnoMestoUOrganizacionojStrukturi": "Novo radno mesto u organizacionoj strukturi",
      "azuriranjeHeader": "Ažuriranje strukture radnog mesta",
      "azuriranjeUspesno": "Uspešno ste ažurirali strukturu radnog mesta!",
      "inputRadnoMestoUStrukturi": "Radno mesto u strukturi",
      "inputRizik": "Radno mesto sa povećanim rizikom",
      "adresa": "Adresa",
      "mesto": "Mesto",
      "drzava": "Država",
      "inputOpis": "Opis",
      "inputObavestenja": "Obaveštenja",
      "inputMere": "Mere",
      "inputZdravstveniUslovi": "Zdravstveni uslovi",
      "dodavanjeHeader": "Dodavanje strukture radnog mesta",
      "dodavanjeUspesno": "Uspešno ste dodali strukturu radnog mesta!",
      "brisanjeHeader": "Brisanje radnog mesta",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrano radno mesto?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "sinhronizacijaObukaHeader": "Sinhronizacija obuka",
      "sinhronizacijaObukaMsg": "Uspešno ste dodali obuku. Da li želite da je dodate za zaposlene (za ovo radno mesto u strukturi)?",
      "sinhronizacijaPregledaHeader": "Sinhronizacija pregleda",
      "sinhronizacijaPregledaMsg": "Uspešno ste dodali pregled. Da li želite da ga dodate za zaposlene (za ovo radno mesto u strukturi)?",
      "sinhronizacijaElPregledaHeader": "Sinhronizacija elementa pregleda",
      "sinhronizacijaElPregledaMsg": "Uspešno ste dodali element pregleda. Da li želite da ga dodate za zaposlene (za ovo radno mesto u strukturi)?"
    },

    "sifRadnaMesta": {
      "brisanjeHeader": "Brisanje radnog mesta",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrano radno mesto?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Dodavanje radnog mesta",
      "dodavanjeUspesno": "Uspešno ste dodali radno mesto!",
      "azuriranjeHeader": "Ažuriranje radnog mesta",
      "azuriranjeUspesno": "Uspešno ste ažurirali radno mesto!",
      "inputNaziv": "Naziv",
      "inputSifra": "Šifra",
      "inputRizik": "Radno mesto sa povećanim rizikom",
      "inputOpis": "Opis",
      "inputObavestenja": "Obaveštenja",
      "inputMere": "Mere",
      "inputZdravstveniUslovi": "Zdravstveni uslovi",
      "sinhronizacijaObukaHeader": "Sinhronizacija obuka",
      "sinhronizacijaObukaMsg": "Uspešno ste dodali obuku. Da li želite da je dodate za postojeća radna mesta u strukturi i zaposlene na njima?",
      "sinhronizacijaPregledaHeader": "Sinhronizacija pregleda",
      "sinhronizacijaPregledaMsg": "Uspešno ste dodali pregled. Da li želite da ga dodate za postojeća radna mesta u strukturi i zaposlene na njima?",
      "sinhronizacijaElPregledaHeader": "Sinhronizacija elementa pregleda",
      "sinhronizacijaElPregledaMsg": "Uspešno ste dodali element pregled. Da li želite da ga dodate za postojeća radna mesta u strukturi i zaposlene na njima?"
    },

    "sifLica": {
      "brisanjeHeader": "Brisanje lica",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrano lice?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Dodavanje lica",
      "dodavanjeUspesno": "Uspešno ste dodali lice!",
      "azuriranjeHeader": "Ažuriranje lica",
      "azuriranjeUspesno": "Uspešno ste ažurirali lice!",
      "inputIme": "First name",
      "inputPrezime": "Last name",
      "inputOcevoIme": "Fathers name",
      "inputDatumRodjenja": "Date of birth",
      "inputMestoRodjenja": "Mesto rođenja",
      "inputOpstinaRodjenja": "Opština rođenja",
      "inputMaticniBroj": "PIN",
      "inputDatumPocetkaRada": "Start date",
      "inputInterniMaticniBroj": "Internal identification number",
      "inputZanimanje": "Zanimanje",
      "inputAdresa": "Address",
      "inputMesto": "Origin",
      "inputDrzava": "State",
      "inputDatumZavrsetkaRada": "End date",
      "inputPol": "Gender",
      "inputLiceZaBzr": "OHS representative"
    },

    "sifZaposleni": {
      "brisanjeHeader": "Brisanje zaposlenog",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranog zaposlenog?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Dodavanje zaposlenog",
      "dodavanjeUspesno": "Uspešno ste dodali zaposlenog!",
      "azuriranjeHeader": "Ažuriranje zaposlenog",
      "azuriranjeUspesno": "Uspešno ste ažurirali zaposlenog!",
      "interniMaticniBroj": "Interni matični broj",
      "maticniBroj": "JMBG",
      "imePrezime": "Name and surname",
      "radnoMestoNaziv": "Workplaces in organizational structure",
      "organizacionaJedinicaNaziv": "Org. jedinica",
      "inputInterniMaticniBroj": "Interni matični broj",
      "povezivanjeLicaIRadnihMesta": "Povezivanje lica i radnih mesta"
    },

    "sifOpasnostiStetnosti": {
      "brisanjeHeader": "Brisanje opasnosti",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu opasnost?",
      "brisanjeUspesno": "Uspešno ste obrisali odabranu opasnost!",
      "dodavanjeHeader": "Dodavanje opasnosti",
      "dodavanjeUspesno": "Uspešno ste dodali opasnost!",
      "azuriranjeHeader": "Ažuriranje opasnosti",
      "azuriranjeUspesno": "Uspešno ste ažurirali opasnost!",
      "inputNaziv": "Naziv",
      "inputAktivno": "Aktivno",
      "izabraniNivoImaDecu": "Izabrani nivo se ne može izbrisati jer je za njega vezan barem jedan niži nivo.",
      "aktiviranjeUspesno": "Opasnost aktivirana",
      "deaktiviranjeUspesno": "Opasnost deaktivirana"
    },

    "sifOpremaZaRad": {
      "brisanjeHeader": "Brisanje opreme za rad",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu opremu za rad?",
      "brisanjeUspesno": "Uspešno ste obrisali opremu za rad!",
      "dodavanjeHeader": "Dodavanje opreme za rad",
      "dodavanjeUspesno": "Uspešno ste dodali opremu za rad!",
      "azuriranjeHeader": "Ažuriranje opreme za rad",
      "azuriranjeUspesno": "Uspešno ste ažurirali opremu za rad!",
      "inputVrsta": "Vrsta",
      "inputFabrickiBroj": "Fabrički broj",
      "inputGodinaProizvodnje": "Godina proizvodnje",
      "inputLokacijaUStrukturi": "Lokacija u strukturi",
      "inputLokacijaBlize": "Lokacija(bliže)",
      "inputNamena": "Namena",
      "namena": "Namena",
      "vrsta": "Vrsta",
      "fabrickiBroj": "Fabrički broj",
      "godinaProizvodnje": "Godina proizvodnje",
      "lokacijaUStrukturi": "Lokacija u strukturi",
      "lokacijaPlaceholder": "Izaberite lokaciju iz strukture",
      "lokacijaHint": "Organizaciona jedinica nije izabrana!"
    },

    "sifLzoVrsteVelicina": {
      "brisanjeHeader": "Brisanje veličine za LZO",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu veličinu za LZO?",
      "brisanjeUspesno": "Uspešno ste obrisali odabranu veličinu za LZO!",
      "dodavanjeHeader": "Dodavanje nove veličine za LZO",
      "dodavanjeUspesno": "Uspešno ste dodali veličinu za LZO!",
      "azuriranjeHeader": "Ažuriranje veličine za LZO",
      "azuriranjeUspesno": "Uspešno ste ažurirali veličinu za LZO!",
      "inputImeVrstaVelicine": "Ime vrste veličine",
      "inputImeVelicina": "Ime veličine",
      "inputPozicija": "Pozicija",
      "porukaDodavanjeNove": "Dodavane nove veličine za ",
      "izabraniNivoImaDecu": "Izabrani nivo se ne može izbrisati jer je za njega vezan barem jedan niži nivo.",
      "vrstaVelicine": "Vrsta veličine",
      "velicina": "Veličina",
      "lzoVelicine": "Velicine za LZO"
    },

    "sifLzoKategorija": {
      "nemaVrstuVelicine": "Nema vrstu veličine",
      "kategorijaLZO": "Kategorija LZO",
      "kolicina": "Kolicina",
      "dodavanjeHeader": "Dodavanje kategorije LZO",
      "dodavanjeUspesno": "Uspešno ste dodali kategoriju LZO!",
      "brisanjeHeader": "Brisanje kategorije LZO",
      "brisanjeUspesno": "Uspešno ste obrisali kategoriju LZO!"
    },

    "sifLzoTree": {
      "LZO": "LZO",
      "dodavanjeHeader": "Dodavanje LZO",
      "dodavanjeUspesno": "Uspešno ste dodali LZO!",
      "brisanjeHeader": "Brisanje LZO",
      "brisanjeUspesno": "Uspešno ste obrisali LZO!",
      "povezivanjeRadnihMesta": "Povezivanje radnih mesta sa LZO",
      "postojiVelicinaSaOdabranimRednimBrojem": "Već postoji veličina sa odabranim rednim brojem!",
      "imaUputstvo": "Ima uputstvo",
      "imaAtest": "Ima atest",
      "ucitanoUputstvo": "Učitano uputstvo",
      "ucitanAtest": "Učitan atest",
      "rokMeseci": "Rok (meseci)",
      "intervalMeseci": "Interval (meseci)"
    },

    "sifLzo": {
      "tab2": {
        "createValidationMsg": "Morate izabrati radno mesto i kategoriju LZO!",
        "updateValidationMsg": "Morate izabrati kategoriju LZO!",
        "radnaMesta": "Radna mesta",
        "kategorijeLicneZastitneOpreme": "Kategorije lične zaštitne opreme",
        "radnoMesto": "Radno mesto",
        "radnoMestoStruktura": "Radno mesto struktura",
        "dodavanjeKategorijeLzo": "Dodavanje kategorije LZO",
        "lzoKategorija": "LZO kategorija:",
        "organizacionaJedinica": "Organizaciona jedinica",
        "odaberiteRadnoMesto": "Odaberite radno mesto",
        "odaberiteRadnoMestoStruktura": "Odaberite radno mesto u strukturi",
        "nazivKategorijeLzo": "Naziv kategorije LZO",
        "dodeljeneKategorijeLzo": "Dodeljene kategorije LZO",
        "dodavanjeHeader": "Dodavanje kategorije LZO",
        "dodavanjeUspesno": "Kategorija LZO je uspešno dodata",
        "brisanjeKategorijePitanje": "Da li ste sigurni da želite da obrišete izabranu kategoriju LZO?",
        "brisanjeKategorijeUspesno": "Uspešno ste obrisali izabrani podatak!",
        "brisanjeKategorijeHeader": "Brisanje kategorije LZO",
        "brisanjeViewPitanje": "Da li ste sigurni da želite da obrišete izabrani podatak?",
        "brisanjeViewHeader": "Potvrda brisanja",
        "brisanjeViewUspesno": "Veza između radnog mesta i LZO kategorije je uspešno obrisana!"
      },
      "izvestaji": {
        "lzoKojiJePotrebnoNabaviti": "LZO koji je potrebno nabaviti",
        "rokZaLzoIstice": "Rok za LZO ističe",
        "rokTrajanjaJeIstekao": "Rok trajanja je istekao",
        "nedodeljeniLzo": "Nedodeljeni LZO",
        "narednogMeseca": "narednog meseca",
        "uNarednaDvaMeseca": "u naredna dva meseca",
        "uNarednaTriMeseca": "u naredna tri meseca",
        "prikaziZaposleneSortiranePo": "Prikaži zaposleni sortirane po",
        "imenuIPrezimenu": "Imenu i prezimenu",
        "evidencionomBroju": "evidencionom broju",
        "internomMaticnojBroju": "IMB-u"
      }
    },

    "toolbars": {
      "elementiPregleda": "Elementi pregleda",
      "obuke": "Obuke",
      "pregledi": "Pregledi",
      "lzoKategorije": "Kategorije za LZO",
      "dodajLzoKategoriju": "Dodaj kategoriju za LZO",
      "lzoVelicine": "Velicine za LZO",
      "uputstvoNaSrpskom": "Uputstvo na srpskom",
      "atest": "Atest",
      "dodaj": "Dodaj",
      "preuzmi": "Preuzmi",
      "obrisi": "Obriši",
      "uputstvoPostoji": "Uputstvo postoji",
      "uputstvoNePostoji": "Uputstvo ne postoji",
      "atestPostoji": "Atest postoji",
      "atestNePostoji": "Atest ne postoji",
      "aktiviraj": "Aktiviraj",
      "deaktiviraj": "Deaktiviraj",
      "vrstaVelicineZaLzo": "Dodaj vrstu veličine",
      "grupnoDodavanjePregleda": "Grupno dodavanje pregleda",
      "grupnoDodavanjeObuke": "Grupno dodavanje obuke",
      "korisnikRola": "Korisničke role",
      "resetLozinke": "Reset lozinke"
    },

    "sifLicnaZastitnaOprema": {
      "aktiviraj": "Aktiviraj",
      "deaktiviraj": "Deaktiviraj",
      "azuriranjeHeader": "Ažuriranje lične zaštitne opreme",
      "azuriranjeUspesno": "Uspešno ste ažurirali ličnu zaštitnu opremu",
      "inputNaziv": "Naziv",
      "inputKategorija": "Kategorija",
      "inputRokTrajanja": "Rok trajanja (u mesecima)",
      "inputIntervalIspitivanja": "Interval ispitivanja (u mesecima)",
      "dodavanjeHeader": "Dodavanje lične zaštitne opreme",
      "dodavanjeUspesno": "Uspešno ste dodali ličnu zaštitnu opremu",
      "inputVrsteVelicinaZaLzo": "Vrste veličina za LZO"
    },

    "sifRevers": {
      "dodeljenaLZO": "Dodeljena LZO",
      "nijeDodeljenaLZO": "Licu nije dodeljena sva potrebna LZO",
      "datum": "Datum"
    },

    "sysPravnoLice": {
      "izborPravnogLicaHeader": "Izbor pravnog lica",
      "inputPravnoLice": "Pravno lice"
    },

    "fileUpload": {
      "dodavanjeHeader": "Dodavanje fajla",
      "dodavanjeUspesno": "Uspešno ste dodali fajl",
      "odaberiteFajl": "Odaberite fajl",
      "brisanjeHeader": "Brisanje fajla",
      "brisanjeUspesno": "Uspešno ste obrisali fajl"
    },

    "validationMsg": {
      "required": "Morate popuniti ovo polje!",
      "email": "Morate uneti validnu e-mail adresu!",
      "date": "Morate uneti validan datum!",
      "min": "Minimalna moguća vrednost je ",
      "max": "Maksimalna moguća vrednost je ",
      "minlength": "Minimalna dužina ovog polja je ",
      "maxlength": "Maksimalna dužina ovog polja je ",
      "number": "Morate uneti broj!",
      "jmbg": "Morate uneti validan JMBG!",
      "characters": " characters!",
      "positive": "Morate uneti broj veći od 0!",
      "required_maxlength": "Morate popuniti ovo polje, maksimalna dužina polja je ",
      "year": "Morate uneti godinu!",
      "mb": "Morate uneti 8 cifara!",
      "undefined": "Polje nije u ispravnom formatu!"
    },

    "obrazac1": {
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "radnoMestoSaRizikom": "Radno mesto sa povećanim rizikom",
      "opasnostiStetnosti": "Opasnost i štetnost",
      "nivoRizika": "Nivo rizika",
      "napomena": "Napomena",
      "azuriranjeRadnogMestaHeader": "Ažuriranje radnog mesta",
      "azuriranjeRadnogMestaUspesno": "Uspešno ste ažurirali radno mesto!",
      "dodavanjeHeader": "Dodavanje opasnosti/štetnosti za radno mesto",
      "dodavanjeUspesno": "Uspešno ste dodali opasnost/štetnost radnom mestu!",
      "azuriranjeHeader": "Ažuriranje opasnosti/štetnosti za radno mesto",
      "azuriranjeNeuspesno": "Uspešno ste ažurirali opasnost/štetnost!",
      "brojOpasnosti": "Broj opasnosti",
      "brojZaposlenih": "Broj zaposlenih"
    },

    "obrazac1.izvestaji": {
      "radnaMesta": "Sva radna mesta",
      "radnaMestaSaRizikom": "Radna mesta sa povećanim rizikom",
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "sveOpasnostiStetnosti": "Sve opasnosti i štetnosti",
      "sviNivoiRizika": "Svi nivoi rizika",
      "nivoRizikaManjiOd": "Nivo rizika manji od",
      "nivoRizikaVeciOd": "Nivo rizika veći od",
      "nivoRizikaJednak": "Nivo rizika jednak",
      "grupisiIzvestajPo": "Grupiši izveštaj po:",
      "nivoimaRizika": "Nivoima rizika",
      "opasnostimaStetnostima": "Opasnostima i štetnostima",
      "organizacionojStrukturi": "Organizacionoj strukturi",
      "radnimMestima": "Radnim mestima",
      "headerStruktura": "Struktura:",
      "headerRadnaMesta": "Radna mesta:",
      "headerNivoiRizika": "Nivoi rizika:",
      "headerOpasnostiStetnosti": "Opasnosti štetnosti:"
    },

    "obrazac2.izvestaji": {
      "radnaMesta": "Sva radna mesta",
      "radnaMestaSaRizikom": "Radna mesta sa povećanim rizikom",
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "grupisiIzvestajPo": "Grupiši izveštaj po:",
      "organizacionojStrukturi": "Organizacionoj strukturi",
      "radnimMestima": "Radnim mestima",
      "headerStruktura": "Struktura:",
      "headerRadnaMesta": "Radna mesta:",
      "vrstePregleda": "Vrste pregleda:",
      "sviPregledi": "Svi pregledi",
      "nemaPreglede": "Nema preglede",
      "imaPreglede": "Ima preglede",
      "headerZaposleni": "Zaposleni:",
      "headerStatus": "Status:",
      "zaposlenima": "Zaposlenima",
      "headerLekarskiPregledi": "Lekarski pregledi:",
      "lekarskiPreglediKojiTrebaDaSeObave": "Pregledi koji treba da se obave",
      "obavljeniPregledi": "Obavljeni pregledi",
      "neobavljeniPregledi": "Neobavljeni pregledi",
      "lekarskiPreglediUIntervalu": "Pregledi u intervalu",
      "labelPocetak": "Početak:",
      "labelKraj": "Kraj:",
      "narednogMeseca": "narednog meseca",
      "uNarednaDvaMeseca": "u naredna dva meseca",
      "uNarednaTriMeseca": "u naredna tri meseca",
      "izborZaposlenog": "Izbor zaposlenog",
      "odaberiteZaposlenog": "Odaberite zaposlenog"
    },

    "obrazac3.izvestaji": {
      "headerZaposleni": "Zaposleni:",
      "headerStruktura": "Struktura:",
      "headerMestoPovrede": "Mesto povrede:",
      "headerRadnaMesta": "Radna mesta:",
      "headerIzvorPovrede": "Izvor povrede:",
      "headerUzrokPovrede": "Uzrok povrede:",
      "headerVrstaIzvestaja": "Vrsta izveštaja:",
      "headerGrupisiIzvestajPo": "Grupiši izveštaj po:",
      "naSvimMestima": "Na svim mestima",
      "radnaMesta": "Sva radna mesta",
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "sviIzvoriPovreda": "Svi izvori povreda",
      "sviUzrociPovreda": "Svi uzroci povreda",
      "radio1": "Bez nadgrupisanja",
      "radio2": "Da li je u momentu povrede povređeni radio na radnom mestu sa povećanim rizikom",
      "radio3": "Da li je povređeni ispunjavao propisane uslove za rad na radnom mestu sa povećanim rizikom",
      "radio4": "Da li je povređeni podvrgnut prethodnom i periodičnom lekarskom pregledu",
      "radio5": "Da li je povređeni osposobljen za bezbedan i zdrav rad za poslove na kojima je došlo do povrede",
      "radio6": "Da li je za poslove na kojim se desila povreda propisano korišćenje sredstava i opreme za ličnu zaštitu na radu",
      "radio7": "Da li su povređenom bila obezbeđena sredstva i oprema za ličnu zaštitu na radu",
      "radio8": "Da li je u vreme povređivanja povređeni koristio obezbeđena sredstva i opremu za ličnu zaštitu na radu",
      "sumarni": "Sumarni",
      "detaljan": "Detaljan",
      "radnimMestima": "Radnim mestima",
      "organizacionojStrukturi": "Organizacionoj strukturi",
      "zaposlenima": "Zaposlenima",
      "generisi": "Generiši",
      "odaberiteZaposlenog": "Odaberite zaposlenog",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "izborZaposlenog": "Izbor zaposlenog"
    },

    "obrazac4.izvestaji": {
      "radnaMesta": "Sva radna mesta",
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "svaOboljenja": "Sva oboljenja",
      "sviNivoiRadneSposobnosti": "Svi nivoi radne sposobnosti",
      "radnaSposobnostManjaOd": "Radna sposobnost manja od",
      "radnaSposobnostVecaOd": "Radna sposobnost veća od",
      "radnaSposobnostJednaka": "Radna sposobnost jednaka",
      "grupisiIzvestajPo": "Grupiši izveštaj po:",
      "nivoimaRizika": "Nivoima rizika",
      "zaposlenima": "Zaposlenima",
      "organizacionojStrukturi": "Organizacionoj strukturi",
      "radnimMestima": "Radnim mestima",
      "headerStruktura": "Struktura:",
      "headerRadnaMesta": "Radna mesta:",
      "headerDijagnoza": "Dijagnoza:",
      "headerPreostalaRadnaSposobnost": "Preostala radna sposobnost:"
    },

    "obrazac5.izvestaji": {
      "radnaMesta": "Sva radna mesta",
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "svaOboljenja": "Sva oboljenja",
      "sviNivoiRadneSposobnosti": "Svi nivoi radne sposobnosti",
      "radnaSposobnostManjaOd": "Radna sposobnost manja od",
      "radnaSposobnostVecaOd": "Radna sposobnost veća od",
      "radnaSposobnostJednaka": "Radna sposobnost jednaka",
      "grupisiIzvestajPo": "Grupiši izveštaj po:",
      "nivoimaRizika": "Nivoima rizika",
      "zaposlenima": "Zaposlenima",
      "organizacionojStrukturi": "Organizacionoj strukturi",
      "radnimMestima": "Radnim mestima",
      "headerStruktura": "Struktura:",
      "headerRadnaMesta": "Radna mesta:",
      "headerMedjunarodnaSifraOboljenja": "Međunarodna šifra oboljenja:",
      "headerPreostalaRadnaSposobnost": "Preostala radna sposobnost:"
    },

    "obrazac6.izvestaji": {
      "radnaMesta": "Sva radna mesta",
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "grupisiIzvestajPo": "Grupiši izveštaj po:",
      "organizacionojStrukturi": "Organizacionoj strukturi",
      "radnimMestima": "Radnim mestima",
      "headerStruktura": "Struktura:",
      "headerRadnaMesta": "Radna mesta:",
      "headerZaposleni": "Zaposleni:",
      "headerStatus": "Status:",
      "zaposlenima": "Zaposlenima",
      "labelPocetak": "Početak:",
      "labelKraj": "Kraj:",
      "nemaObuku": "Nema obuku",
      "obukaZavrsena": "Obuka završena",
      "obukaUIntervalu": "Obuka u intervalu",
      "imaObuku": "Ima obuku",
      "headerVrstaObuke": "Vrsta obuke:",
      "sveObuke": "Sve obuke",
      "izborZaposlenog": "Izbor zaposlenog",
      "odaberiteZaposlenog": "Odaberite zaposlenog"
    },

    "obrObrazac02": {
      "uput": "Uput",
      "novUputPrethodni": "Nov uput za prethodni pregled",
      "novUputPeriodicni": "Nov uput za periodični pregled",
      "pregledIIzmena": "Pregled i Izmena",
      "preuzmi": "Preuzmi",
      "pregled": "Pregled",
      "potpisanUput": "Potpisan uput",
      "izvestajLekara": "Izveštaj lekara",
      "vrstaPregleda": "Vrsta pregleda",
      "datumUputa": "Datum uputa",
      "datumPregleda": "Datum pregleda",
      "datumSledecegPregleda": "Sledeći pregled",
      "datumPrethodnogPregleda": "Prethodni pregled",
      "brojLekarskogIzvestaja": "Broj izveštaja",
      "ocenaSposobnosti": "Ocena sposobnosti",
      "mere": "Mere",
      "preduzeteMere": "Preduzete mere",
      "sifra": "Šifra",
      "interval": "Interval",
      "elementPregleda": "Element pregleda",
      "brojUputa": "Broj uputa",
      "imeIPrezime": "Ime i prezime",
      "ime": "Ime",
      "prezime": "Prezime",
      "radnoMesto": "Radno mesto",
      "organizacionaJedinica": "Org. struktura",
      "zanimanje": "Zanimanje",
      "maticniBroj": "Matični broj",
      "kratakOpisPosla": "Kratak opis posla",
      "opasnostiIRizici": "Opasnosti i rizici",
      "potrebniZdravstveniUslovi": "Potrebni zdravstveni uslovi",
      "infoOPoslednjemPregledu": "Informacije o poslednjem lekarskom pregledu",
      "nazivZdrUstanove": "Naziv zdravstvene ustanove",
      "datum": "Datum",
      "brojIzvestaja": "Broj izveštaja",
      "brisanjeHeader": "Brisanje zdravstvenog pregleda",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani zdravstveni pregled?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrani zdravstveni pregled!",
      "dodavanjeHeader": "Dodavanje novog zdravstvenog pregleda",
      "dodavanjeUspesno": "Uspešno ste dodali zdravstveni pregled!",
      "azuriranjeHeader": "Ažuriranje zdravstvenog pregleda",
      "azuriranjeUspesno": "Uspešno ste ažurirali zdravstveni pregled!",
      "morateOdabratiElementePregleda": "Morate odabrati bar jedan element pregleda!",
      "nazivPregleda": "Naziv pregleda",
      "organizacionaStruktura": "Organizaciona struktura",
      "pregledi": "Pregledi",
      "napomena": "Napomena",
      "intervalPregleda": "Interval pregleda",
      "grupnoDodavanjePregledaHeader": "Grupno dodavanje pregleda",
      "grupnoDodavanjePregledaUspesno": "Uspešno ste dodali pregled izabranim zaposlenima!",
      "grupnoDodavanjePregledaNeuspesno": "Greška prilikom dodavanja pregleda zaposlenima!",
      "grupnoDodavanjeOrgJedinicaFilter": "Pregled zaposlenih na osnovu organizacione strukture",
      "grupnoDodavanjeRadnoMestoFilter": "Pregled zaposlenih na osnovu radnog mesta",
      "grupnoDodavanjeRadnoMestoStrukturaFilter": "Pregled zaposlenih na osnovu radnog mesta u strukturi",
      "grupnoDodavanjeZaposlenihFilter": "Pregled zaposlenih po imenu, prezimenu i evidencionom broju lica",
      "grupnoDodavanjeZaposleniBezPregledaFilter": "Pregled zaposlenih koji nemaju pregled",
      "grupnoDodavanjeZaposleniBezPregledaOption1": "Zaposleni bez dodeljenog pregleda",
      "grupnoDodavanjeZaposleniBezPregledaOption2": "Zaposleni kojima je pregled istekao",
      "grupnoDodavanjeZaposleniBezPregledaOption3": "Zaposleni bez dodeljenog pregleda i zaposleni sa isteklim pregledom"
    },

    "obrObrazac03": {
      "radnoMestoPovecaniRizik": "Radno mesto je evidentirano kao radno mesto sa povećanim rizikom",
      "radnoMestoNijePovecaniRizik": "Radno mesto nije evidentirano kao radno mesto sa povećanim rizikom",
      "povredjeniNemaSveObuke": "Povredjeni nema sve potrebne korisnički definisane obuke",
      "povredjeniImaSveObuke": "Povredjeni ima sve potrebne korisnički definisane obuke",
      "povredjeniNemaSvePreglede": "Povredjeni nema sve potrebne korisnički definisane preglede",
      "povredjeniImaSvePreglede": "Povredjeni ima sve potrebne korisnički definisane preglede",
      "rmsImaLzo": "Korišćenje lične zaštitne opreme na radnom mestu je propisano",
      "rmsNemaLzo": "Korišćenje lične zaštitne opreme na radnom mestu nije propisano",
      "povredaNaRadu": "Povreda na radu",
      "preuzmiPovredniList": "Preuzmi povredni list",
      "preuzmiPovredniListA4": "Preuzmi povredni list A4",
      "preuzmiPovredniListA3": "Preuzmi povredni list A3",
      "povredniList": {
        "naslov": "IZVEŠTAJ O POVREDI NA RADU",
        "podaciOPoslodavcu": "1. PODACI O POSLODAVCU",
        "obveznikPodnosenjaPoslodavac": "1.Obveznik podnošenja <br>&nbsp;&nbsp;&nbsp; o povredi na radu<br>&nbsp;&nbsp;&nbsp;(poslodavac)",
        "punNazivPoslodavca": "Pun naziv poslodavca (poslovno ime, odnosno lično ime ili drugi naziv pod kojim preduzetnik obavlja delatnost)",
        "sedisteAdresaPoslodavca": "Sedište i adresa poslodavca(ulica,<br>i broj, opština, mesto, država)",
        "regBrojObveznika": "Reg. broj obveznika doprinosa",
        "pib": "PIB",
        "sifraDelatnosti": "Šifra delatnosti",
        "ukupnoZaposlenih": "Ukupno zaposlenih",
        "podaciOBZRLicuPoslodavac": "2. PODACI O LICU ODREĐENOM ZA BEZBEDNOST I ZDRAVLJE NA RADU<br>KOD POSLODAVCA <br/>",
        "imePrezime": "Ime i prezime",
        "jmbg": "JMBG",
        "adresaPrebivalista": "Adresa prebivališta",
        "podaciOPovredjenom": "PODACI O POVREĐENOM",
        "polMZ": "Pol (muški - ženski)",
        "danMesecGodRodjenja": "Dan, mesec i godina rođenja",
        "prebivaliste": "Prebivalište",
        "ulicaIBroj": "Ulica i broj",
        "mestoOpstina": "Mesto, opština",
        "drzava": "Država",
        "vrstaStepenSS": "Vrsta i stepen stručne spreme povređenog",
        "vrstaStepenPropisaneSS": "Vrsta i stepen stručne spreme propisane za obavljanje poslova<br>&nbsp;&nbsp;&nbsp;na kojima je povređeni pretrpeo povredu na radu",
        "statusPovredjenog": "Status povređenog",
        "podaciONeposrednomRukovodiocu": "PODACI O NEPOSREDNOM RUKOVODIOCU POVREĐENOG",
        "posloviKojeObavlja": "Poslovi koje obavlja",
        "radnoMestoNastupanjePovrede": "PODACI O RADNOM MESTU I POSLOVIMA POVREĐENOG I VREMENU<br>I MESTU NASTUPANJA NJEGOVE POVREDE NA RADU <br>",
        "radnoMestoPovredjeniRasporedjen": "Radno mesto na koje je povređeni raspoređen",
        "labela24": "Posao koji je obavljao",
        "labela25": "Radno iskustvo povređenog na poslovima na kojima se povredio",
        "labela26": "Ukupan penzijski staž povređenog",
        "labela27": "Radno vreme povređenog u satima (dnevni prosek)",
        "labela28": "Da li je povređeni ranije pretrpeo povredu na radu i koliko puta",
        "labela29": "Vreme nastupanja povrede",
        "labela30": "Dan u sedmici",
        "labela31": "Doba dana - sat (0-24)",
        "labela32": "Radni sat od početka rada",
        "labela33": "Naziv mesta sa adresom gde se povreda dogodila sa bližom<br>&nbsp;&nbsp;&nbsp;&nbsp;identifikacijom lokacije (objekat, gradilište, skladište i sl)",
        "datum": "Datum",
        "mesto": "Mesto",
        "labela36-41": "Mesto povrede",
        "labela41": "Ostalo",
        "MP": "M.P.",
        "redBrojIzEvidencije": "Red. br. iz evidencije o povredama na radu",
        "potpisOdgovornogLica": "POTPIS ODGOVORNOG LICA",
        "labela45": "Koliko je ukupno zaposlenih povređeno na radu <br>&nbsp;&nbsp;&nbsp;&nbsp; (zajedno sa povređenim)",
        "labela46": "Koliko je ukupno poginulih na mestu događaja<br>&nbsp;&nbsp;&nbsp;&nbsp; odnosno umrlo na putu do zdravstvene ustanove",
        "labela47": "Da li se slična povreda desila ranije na istim poslovima<br>&nbsp;&nbsp;&nbsp;&nbsp; - zadacima i kada",
        "labela48": "Izvor povrede - materijalni uzročnik (međunarodna šifra)",
        "labela49": "Uzrok povrede - način povređivanja (međunarodna šifra)",
        "labela50": "Kratak opis nastanka povrede",
        "labela51": "Da li je u momentu povrede povređeni radio na radnom mestu<br>&nbsp;&nbsp;&nbsp;&nbsp; sa povećanim rizikom",
        "labela52": "Da li je povređeni ispunjavao propisane uslove za rad<br>&nbsp;&nbsp;&nbsp;&nbsp; na radnom mestu sa povećanim rizikom",
        "labela53": "Da li je povređeni podvrgnut prethodnom i periodičnom<br>&nbsp;&nbsp;&nbsp;&nbsp; lekarskom pregledu",
        "labela54": "Da li je povređeni osposobljen za bezbedan i zdrav rad<br>&nbsp;&nbsp;&nbsp;&nbsp; za poslove na kojima je došlo do povrede",
        "labela55": "Da li je za poslove na kojima se desila povreda propisano<br>&nbsp;&nbsp;&nbsp;&nbsp; korišćenje sredstava i opreme za ličnu zaštitu na radu",
        "labela56": "Da li su povređenom bila obezbeđena sredstva i oprema<br>&nbsp;&nbsp;&nbsp;&nbsp; za ličnu zaštitu na radu",
        "labela57": "Da li je u vreme povređivanja povređeni koristio obezbeđena<br>&nbsp;&nbsp;&nbsp;&nbsp; sredstva i opremu za ličnu zaštitu na radu",
        "labela58": "Navesti koje su mere bezbednosti i zdravlja na radu bile<br>&nbsp;&nbsp;&nbsp;&nbsp;sprovedene na poslovima gde je došlo do povrede",
        "labela59": "Naziv i adresa zdravstvene ustanove u kojoj je povređeni pregledan",
        "labela60": "Ime i prezime lekara koji je pregledao povređenog",
        "labela61": "Priroda i lokalizacija povrede - dijagnoza (upisati naziv<br>&nbsp;&nbsp;&nbsp;&nbsp; i šifru dijagnoze po MKB10, od najteže ka najlakšoj)",
        "labela62": "Spoljni uzrok povrede po klasifikaciji",
        "labela63": "Da li je povreda sa smrtnim ishodom (na mestu povrede,<br>&nbsp;&nbsp;&nbsp;&nbsp; odnosno na putu do zdravstvene ustanove)",
        "labela64": "Ocena težine povrede (laka - teška)",
        "labela65": "Da li je povređeni pre povređivanja imao telesnih<br>&nbsp;&nbsp;&nbsp;&nbsp; ili psihičkih povreda ili oboljenja i kojih",
        "labela66": "Druge primedbe lekara (da li je povređeni pod uticajem alkohola i sl.)",
        "labela67": "Da li je povređeni sprečen za rad više od tri uzastopna radna dana",
        "podaciOOcevicu": "PODACI O OČEVICU",
        "labelaNaslov7": "PODACI O POVREDI NA RADU I MERAMA BEZBEDNOSTI I ZDRAVLJA NA RADU<br>KOJE SU PRIMENJIVANE NA RADNOM MESTU NA KOJEM JE POVREĐENI RADIO",
        "labelaNaslov8": "NALAZ I MIŠLJENJE LEKARA KOJI JE PRVI PREGLEDAO POVREĐENOG",
        "redniBrojLekarskogDnevnika": "Redni broj lekarskog dnevnika",
        "redniBrojUKnjiziEvidencije": "Redni broj u knjizi evidencije zdravstvene ustanove",
        "potpisLekara": "POTPIS LEKARA",
        "orgZdravstvenogOsiguranja": "ORGANIZACIJA ZDRAVSTVENOG OSIGURANJA",
        "redBrIzDnevnika": "Red. br. iz dnevnika"
      }
    },

    "obrObrazac04": {
      "imePrezime": "Ime i prezime",
      "radnoMesto": "Radno mesto",
      "dijagnoza": "Dijagnoza",
      "zdravstvenaUstanova": "Zdravstvena ustanova",
      "stepenOstecenja": "Stepen oštećenja",
      "preostalaSposobnost": "Preostala sposobnost",
      "inputDijagnoza": "Dijagnoza",
      "inputZdravstvenaUstanova": "Zdravstvena ustanova",
      "inputStepenOstecenja": "Stepen oštećenja",
      "inputPreostalaSposobnost": "Preostala sposobnost",
      "brisanjeHeader": "Brisanje podatka",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani podatak?",
      "brisanjeUspesno": "Uspešno ste obrisali izabrani podatak!",
      "dodavanjeHeader": "Dodavanje novog podatka",
      "dodavanjeUspesno": "Uspešno ste dodali podatak!",
      "azuriranjeHeader": "Ažuriranje podatka",
      "azuriranjeUspesno": "Uspešno ste ažurirali podatak!"
    },

    "obrObrazac05": {
      "imePrezime": "Ime i prezime",
      "radnoMesto": "Radno mesto",
      "dijagnoza": "Dijagnoza",
      "zdravstvenaUstanova": "Zdravstvena ustanova",
      "stepenOstecenja": "Stepen oštećenja",
      "preostalaSposobnost": "Preostala sposobnost",
      "inputDijagnoza": "Dijagnoza",
      "inputZdravstvenaUstanova": "Zdravstvena ustanova",
      "inputStepenOstecenja": "Stepen oštećenja",
      "inputPreostalaSposobnost": "Preostala sposobnost",
      "brisanjeHeader": "Brisanje podatka",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani podatak?",
      "brisanjeUspesno": "Uspešno ste obrisali izabrani podatak!",
      "dodavanjeHeader": "Dodavanje novog podatka",
      "dodavanjeUspesno": "Uspešno ste dodali podatak!",
      "azuriranjeHeader": "Ažuriranje podatka",
      "azuriranjeUspesno": "Uspešno ste ažurirali podatak!"
    },

    "obrObrazac06": {
      "sveObuke": "Sve obuke",
      "obuka": "Obuka",
      "dijagnoza": "Dijagnoza",
      "zdravstvenaUstanova": "Zdravstvena ustanova",
      "stepenOstecenja": "Stepen oštećenja",
      "preostalaSposobnost": "Preostala sposobnost",
      "inputDijagnoza": "Dijagnoza",
      "brisanjeHeader": "Brisanje obuke",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu obuku?",
      "brisanjeUspesno": "Uspešno ste obrisali izabranu obuku!",
      "dodavanjeHeader": "Dodavanje nove obuke",
      "dodavanjeUspesno": "Uspešno ste dodali obuku!",
      "azuriranjeHeader": "Ažuriranje obuke",
      "azuriranjeUspesno": "Uspešno ste ažurirali obuku!",
      "potpisana": "Potpisana",
      "teoretskaObuka": "Teoretska obuka",
      "prakticnaObuka": "Praktična obuka",
      "teoretskaProvera": "Teoretska provera",
      "prakticnaProvera": "Praktična provera",
      "razlogOsposobljavanja": "Razlog osposobljavanja",
      "opisPoslovaNaRadnomMestu": "Opis poslova na radnom mestu",
      "razloziOsposobljavanjaHeader": "Razlozi izvršenog osposobljavanja osoblja za bezbedan i zdrav rad",
      "rizici": "Rizici sa kojima je zaposleni upoznat",
      "obavestenja": "Obaveštenja, uputstva i instrukcije",
      "mere": "Konkretne mere za bezbedan i zdrav rad",
      "zaposleniPotpisaoObrazac": "Zaposleni je potpisao obrazac",
      "morateOdabratiRazlogOsposobljavanja": "Morate odabrati bar jedan razlog osposobljavanja!",
      "grupnoDodavanjeZaposleniBezObukeOption1": "Zaposleni koji nemaju nijednu obuku",
      "grupnoDodavanjeZaposleniBezObukeOption2": "Zaposleni koji nemaju datu obuku",
      "datumUputa": "Datum uputa",
      "datumObuke": "Datum obuke",
      "datumSledeceObuke": "Sledeća obuka",
      "sifra": "Šifra",
      "interval": "Interval",
      "imeIPrezime": "Ime i prezime",
      "ime": "Ime",
      "prezime": "Prezime",
      "radnoMesto": "Radno mesto",
      "zanimanje": "Zanimanje",
      "maticniBroj": "Matični broj",
      "kratakOpisPosla": "Kratak opis posla",
      "opasnostiIRizici": "Opasnosti i rizici",
      "potrebniZdravstveniUslovi": "Potrebni zdravstveni uslovi",
      "infoOPoslednjemPregledu": "Informacije o poslednjem lekarskom pregledu",
      "nazivZdrUstanove": "Naziv zdravstvene ustanove",
      "datum": "Datum",
      "nazivObuke": "Naziv obuke",
      "organizacionaStruktura": "Organizaciona struktura",
      "obuke": "Obuke",
      "napomena": "Napomena",
      "intervalObuke": "Interval obuke",
      "skeniran": "Skeniran",
      "grupnoDodavanjeZaposleniBezObukeFilter": "Pregled zaposlenih koji nemaju obuku",
      "grupnoDodavanjeObukeHeader": "Grupno dodavanje obuke",
      "grupnoDodavanjeObukeUspesno": "Uspešno ste dodali obuku izabranim zaposlenima",
      "grupnoDodavanjeObukeGreska": "Greška prilikom dodavanja obuke zaposlenima!",
      "grupnoDodavanjeZaposleniOption1": "Zaposleni bez dodeljene obuka",
      "grupnoDodavanjeZaposleniOption2": "Zaposleni kojima je obuka istekla",
      "grupnoDodavanjeZaposleniOption3": "Zaposleni bez dodeljene obuke i zaposleni sa isteklom obukom"
    },

    "obrObrazac07": {
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto struktura",
      "organizacionaJedinica": "Organizaciona struktura",
      "brojEvidentiranihOpasnihMaterija": "Broj evidentiranih opasnih materija",
      "brisanjeHeader": "Brisanje radnog mesta",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrano radno mesto?",
      "brisanjeUspesno": "Uspešno ste obrisali izabrano radno mesto!",
      "dodavanjeHeader": "Dodavanje novog radnog mesta",
      "dodavanjeUspesno": "Uspešno ste dodali radno mesto!",
      "azuriranjeHeader": "Ažuriranje opasnih materija na radnim mestima",
      "azuriranjeUspesno": "Uspešno ste ažurirali opasne materije na radnim mestima!",
      "evidencijaOpasnihMaterija": "Evidencija opasnih materija na radnim mestima",
      "dodavanjeAzuriranjeOpasneMaterije": "Evidencija opasne materije",
      "inputOpasnaMaterija": "Opasna materija",
      "inputNacinUpotrebe": "Način upotrebe",
      "inputNapomena": "Napomena",
      "inputDnevnaKolicina": "Dnevna količina",
      "brisanjeOpasnaMaterijaHeader": "Brisanje opasne materije",
      "brisanjeOpasnaMaterijaPitanje": "Da li ste sigurni da želite da obrišete izabranu opasnu materiju?",
      "brisanjeOpasnaMaterijaUspesno": "Uspešno ste obrisali izabranu opasnu materiju!",
      "dodavanjeOpasnaMaterijaHeader": "Dodavanje nove opasne materije",
      "dodavanjeOpasnaMaterijaUspesno": "Uspešno ste dodali opasnu materiju!",
      "azuriranjeOpasnaMaterijaHeader": "Ažuriranje opasnih materija",
      "azuriranjeOpasnaMaterijaUspesno": "Uspešno ste ažurirali opasne materije!",
      "redniBroj": "Redni broj",
      "korisnik": "Korisnik",
      "datum": "Datum",
      "vreme": "Vreme",
      "skeniran": "Skeniran"
    },

    "obrazac07.izvestaji": {
      "headerStruktura": "Struktura:",
      "headerRadnaMesta": "Radna mesta:",
      "headerZaposleni": "Zaposleni:",
      "headerNazivOpasneMaterije": "Naziv opasne materije:",
      "headerKlasaOpasneMaterije": "Klasa opasne materije:",
      "headerGrupisiIzvestajPo": "Grupiši izveštaj po:",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "radnaMesta": "Sva radna mesta",
      "radnoMesto": "Radno mesto",
      "radnoMestoStruktura": "Radno mesto u strukturi",
      "odaberiteZaposlenog": "Odaberite zaposlenog",
      "nazivOpasneMaterije": "Naziv opasne materije:",
      "klasaOpasneMaterije": "Klasa opasne materije:",
      "odaberiteSveOpasneMaterije": "Sve opasne materije",
      "odaberiteSveKlaseOpasnihMaterija": "Sve klase opasnih materija",
      "radnimMestima": "Radnim mestima",
      "organizacionojStrukturi": "Organizacionoj strukturi",
      "zaposlenima": "Zaposlenima",
      "nazivimaOpasnihMaterija": "Nazivima opasnih materija",
      "izborZaposlenog": "Izbor zaposlenog"
    },

    "obrObrazac08": {
      "ispitivaniParametar": "Ispitivani parametar",
      "datumIspitivanja": "Datum ispitivanja",
      "datumSledecegIspitivanja": "Datum sledećeg ispitivanja",
      "napomena": "Napomena",
      "brojIspitivanja": "Broj ispitivanja",
      "brisanjeHeader": "Brisanje ispitivanja parametra okoline",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrano ispitivanje parametra okoline?",
      "brisanjeUspesno": "Uspešno ste obrisali izabrano ispitivanje parametra okoline!",
      "dodavanjeHeader": "Dodavanje novog ispitivanja parametra okoline",
      "dodavanjeUspesno": "Uspešno ste dodali ispitivanje parametra okoline!",
      "azuriranjeHeader": "Ažuriranje ispitivanja parametra okoline",
      "azuriranjeUspesno": "Uspešno ste ažurirali ispitivanje parametra okoline!",
      "evidencijaIspitivanjaRadneOkoline": "Evidencija ispitivanja radne okoline"
    },

    "obrazac08.izvestaji": {
      "headerParametri": "Parametri:",
      "headerIspitivanja": "Ispitivanja:",
      "sviParametri": "Svi parametri",
      "ispitivanjaKojaTreba": "Ispitivanja koja treba da se obave",
      "narednogMeseca": "narednog meseca",
      "uNarednaDvaMeseca": "u naredna dva meseca",
      "uNarednaTriMeseca": "u naredna tri meseca",
      "obavljenaIspitivanja": "Obavljena ispitivanja",
      "neobavljenaIspitivanja": "Neobavljena ispitivanja"
    },

    "obrObrazac09": {
      "nazivIBrojOpreme": "Naziv i broj opreme",
      "lokacija": "Lokacija",
      "brojNalaza": "Broj nalaza",
      "datumPregleda": "Datum pregleda",
      "datumSledecegPregleda": "Datum sledećeg pregleda",
      "napomena": "Napomena",
      "evidencijaPregledaOpreme": "Evidencija pregleda opreme",
      "brisanjeHeader": "Brisanje pregleda opreme",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani pregled opreme?",
      "brisanjeUspesno": "Uspešno ste obrisali izabrani pregled opreme!",
      "dodavanjeHeader": "Dodavanje novog pregleda opreme",
      "dodavanjeUspesno": "Uspešno ste dodali pregled opreme!",
      "azuriranjeHeader": "Ažuriranje pregleda opreme",
      "azuriranjeUspesno": "Uspešno ste ažurirali pregled opreme!"
    },

    "obrazac09.izvestaji": {
      "headerOpremaZaRad": "Oprema za rad:",
      "headerStruktura": "Struktura:",
      "headerIspitivanja": "Ispitivanja:",
      "svaOprema": "Sva oprema",
      "sviNivoiStrukture": "Svi nivoi strukture",
      "ispitivanjaKojaTreba": "Ispitivanja koja treba da se obave",
      "narednogMeseca": "narednog meseca",
      "uNarednaDvaMeseca": "u naredna dva meseca",
      "uNarednaTriMeseca": "u naredna tri meseca",
      "svaIspitivanja": "Sva ispitivanja",
      "neobavljenaIspitivanja": "Neobavljena ispitivanja"
    },

    "obrObrazac10": {
      "brisanjeHeader": "Brisanje lične zaštitne opštine",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu ličnu zaštitnu opremu?",
      "brisanjeUspesno": "Uspešno ste obrisali ličnu zaštitnu opremu!",
      "dodavanjeHeader": "Dodavanje nove lične zaštitne opštine",
      "dodavanjeUspesno": "Uspešno ste dodali ličnu zaštitnu opremu!",
      "azuriranjeHeader": "Ažuriranje lične zaštitne opreme",
      "azuriranjeUspesno": "Uspešno ste ažurirali ličnu zaštitnu opremu!",
      "licnaZastitnaOprema": "Lična zaštitna oprema",
      "fabrickiBroj": "Fabrički broj",
      "datumPregleda": "Datum pregleda",
      "datumSledecegPregleda": "Datum sledećeg pregleda",
      "datumNabavke": "Datum nabavke",
      "vaziDo": "Važi do",
      "potpis": "Potpis",
      "napomena": "Napomena",
      "inputVrsta": "Vrsta",
      "inputDatumNabavke": "Datum nabavke",
      "inputFabrickiBroj": "Fabrički broj",
      "inputRok": "Rok",
      "inputGodinaProizvodnje": "Godina proizvodnje",
      "inputVaziDo": "Važi do",
      "brisanjePreglediHeader": "Brisanje pregleda",
      "brisanjePreglediPitanje": "Da li ste sigurni da želite da obrišete izabrani pregled?",
      "brisanjePreglediUspesno": "Uspešno ste obrisali pregled!",
      "dodavanjePreglediHeader": "Dodavanje novog pregleda",
      "dodavanjePreglediUspesno": "Uspešno ste dodali pregled!",
      "azuriranjePreglediHeader": "Ažuriranje pregleda",
      "azuriranjePreglediUspesno": "Uspešno ste ažurirali pregled!",
      "lzoKojaPodlezePregledima": "LZO koja podleže pregledima",
      "lzoKojaNePodlezePregledima": "LZO koja ne podleže pregledima",
      "novPregledLicneZastitneOpreme": "Nov pregled lične zaštitne opreme",
      "evidencijaPregledaLicneZastitneOpreme": "Evidencija pregleda lične zaštitne opreme"
    },

    "obrazac10.izvestaji": {
      "headerSredstva": "Sredstva i oprema za ličnu zaštitu na radu",
      "headerLicnaZastitnaOprema": "Lična zaštitna oprema",
      "headerGrupisiIzvestajPo": "Grupiši izveštaj po:",
      "svaOprema": "Sva oprema",
      "lzoObavljenaIspitivanja": "LZO - obavljena ispitivanja",
      "lzoBezAtesta": "LZO bez atesta ili uputstva",
      "lzoIstekaoRokTrajanja": "LZO kojoj je istekao rok trajanja",
      "lzoIstekaoRokIspitivanja": "LZO kojoj je istekao rok za ispitivanje ili nije ispitana",
      "lzoIsticeRokTrajanja": "LZO kojoj rok trajanja ističe",
      "lzoIsticeRokIspitivanja": "LZO kojoj rok za ispitivanje ističe",
      "uNarednih14Dana": "u narednih 14 dana",
      "uNarednih30Dana": "u narednih 30 dana",
      "uNarednih60Dana": "u narednih 60 dana",
      "opremiZaZastitu": "Opremi za zaštitu",
      "vremenu": "Vremenu"
    },

    "obrObrazac11": {
      "imePrezime": "Ime i prezime",
      "radnoMestoNaziv": "Radno mesto",
      "datumPregleda": "Datum pregleda",
      "ocenaPovrede": "Ocena povrede",
      "ocenaPovredeTeska": "Teška",
      "ocenaPovredeLaka": "Laka",
      "vrstaPovrede": "Vrsta povrede",
      "vrstaPovredeKolektivna": "Kolektivna",
      "vrstaPovredePojedinacna": "Pojedinačna",

      "detailPrijava": "Prijava",
      "detailDatum": "Datum",
      "detailNadlezniOrgan": "Nadležni organ kome je prijava podneta",
      "detailNapomena": "Napomena",
      "detailInspekcijaRada": "Inspekcija rada",
      "detailOUP": "OUP",
      "detailSedisteNadleznogORgana": "Sedište nadležnog organa<br/>kome je prijava odneta",
      "detailLicePrimiloPrijavu": "Lice koje je prijavu primilo",
      "detailUsmeno": "Usmeno",
      "detailPismeno": "Pismeno",

      "usmenaPrijava": "Usmena prijava",
      "pismenaPrijava": "Pismena prijava",
      "datumPodnosenjaPrijave": "Datum podnošenja prijave",
      "inspekcijaRadaSediste": "Inspekcija rada - sedište",
      "inspekcijaRadaLice": "Inspekcija rada - lice",
      "oupSediste": "OPU - sedište",
      "oupLice": "OPU - lice",
      "napomena": "Napomena",

      "snimanjeHeader": "Evidencija o prijavama povreda na radu",
      "snimanjeUspesno": "Uspešno ste snimili prijavu povrede na radu"
    },

    "obrObrazac12": {
      "imePrezime": "Ime i prezime",
      "radnoMestoNaziv": "Radno mesto",
      "dijagnoza": "Dijagnoza",

      "detailPrijava": "Prijava",
      "detailDatum": "Datum",
      "detailNadlezniOrgan": "Nadležni organ kome je prijava podneta",
      "detailNapomena": "Napomena",
      "detailInspekcijaRada": "Inspekcija rada",
      "detailSedisteNadleznogORgana": "Sedište nadležnog organa<br/>kome je prijava odneta",
      "detailLicePrimiloPrijavu": "Lice koje je prijavu primilo",
      "detailUsmeno": "Usmeno",
      "detailPismeno": "Pismeno",

      "usmenaPrijava": "Usmena prijava",
      "pismenaPrijava": "Pismena prijava",
      "datumPodnosenjaPrijave": "Datum podnošenja prijave",
      "inspekcijaRadaSediste": "Inspekcija rada - sedište",
      "inspekcijaRadaLice": "Inspekcija rada - lice",
      "napomena": "Napomena",

      "snimanjeHeader": "Evidencija o prijavama profesionalnih oboljenja",
      "snimanjeUspesno": "Uspešno ste snimili prijavu oboljenja u vezi sa radom"
    },

    "obrObrazac13": {
      "imePrezime": "Ime i prezime",
      "radnoMestoNaziv": "Radno mesto",
      "dijagnoza": "Dijagnoza",

      "detailPrijava": "Prijava",
      "detailDatum": "Datum",
      "detailNadlezniOrgan": "Nadležni organ kome je prijava podneta",
      "detailNapomena": "Napomena",
      "detailInspekcijaRada": "Inspekcija rada",
      "detailSedisteNadleznogORgana": "Sedište nadležnog organa<br/>kome je prijava odneta",
      "detailLicePrimiloPrijavu": "Lice koje je prijavu primilo",
      "detailUsmeno": "Usmeno",
      "detailPismeno": "Pismeno",

      "usmenaPrijava": "Usmena prijava",
      "pismenaPrijava": "Pismena prijava",
      "datumPodnosenjaPrijave": "Datum podnošenja prijave",
      "inspekcijaRadaSediste": "Inspekcija rada - sedište",
      "inspekcijaRadaLice": "Inspekcija rada - lice",
      "napomena": "Napomena",

      "snimanjeHeader": "Evidencija o prijavama bolesti u vezi sa radom",
      "snimanjeUspesno": "Uspešno ste snimili prijavu bolesti u vezi sa radom"
    },

    "obrObrazac14": {
      "opis": "Opis pojave",

      "detailPrijava": "Prijava",
      "detailDatum": "Datum",
      "detailNadlezniOrgan": "Nadležni organ kome je prijava podneta",
      "detailNapomena": "Napomena",
      "detailInspekcijaRada": "Inspekcija rada",
      "detailOUP": "OUP",
      "detailSedisteNadleznogORgana": "Sedište nadležnog organa<br/>kome je prijava odneta",
      "detailLicePrimiloPrijavu": "Lice koje je prijavu primilo",
      "detailUsmeno": "Usmeno",
      "detailPismeno": "Pismeno",

      "usmenaPrijava": "Usmena prijava",
      "pismenaPrijava": "Pismena prijava",
      "datumPodnosenjaPrijave": "Datum podnošenja prijave",
      "inspekcijaRadaSediste": "Inspekcija rada - sedište",
      "inspekcijaRadaLice": "Inspekcija rada - lice",
      "oupSediste": "OPU - sedište",
      "oupLice": "OPU - lice",
      "napomena": "Napomena",

      "snimanjeHeader": "Evidencija o prijavama opasnih pojava",
      "snimanjeUspesno": "Uspešno ste snimili prijavu opasne pojave",
      "brisanjeHeader": "Brisanje opasne pojave",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabranu opasnu pojavu?",
      "brisanjeUspesno": "Uspešno ste obrisali opasnu pojavu!",
      "dodavanjeHeader": "Dodavanje nove opasne pojave",
      "dodavanjeUspesno": "Uspešno ste dodali opasnu pojavu!",
      "azuriranjeHeader": "Ažuriranje opasne pojave",
      "azuriranjeUspesno": "Uspešno ste ažurirali opasnu pojavu!"
    },

    "obrazacSacuvani": {
      "brisanjePitanje": "Želite li da obrišete izabrani sačuvani obrazac?",
      "brisanjeUspesno": "Uspešno ste obrisali izabrani sačuvani obrazac!",
      "brisanjeHeader": "Brisanje sačuvanog obrasca",
      "id": "ID",
      "korisnik": "Korisnik",
      "datumVreme": "Datum i vreme",
      "skeniran": "Skeniran"
    },

    "xsysPravnoLice": {
      "id": "ID",
      "naziv": "Naziv",
      "pib": "PIB",
      "adresa": "Adresa",
      "mesto": "Mesto",
      "drzava": "Država",
      "sifraDelatnosti": "Šifra delatnosti",
      "maticniBroj": "Matični broj",
      "arhivirano": "Arhivirano",
      "dodavanjeHeader": "Dodavanje novog pravnog lica",
      "dodavanjeUspesno": "Uspešno ste dodali pravno lice!",
      "azuriranjeHeader": "Ažuriranje pravnog lica",
      "azuriranjeUspesno": "Uspešno ste ažurirali pravno lice!",
      "brisanjePitanje": "Želite li da obrišete izabrano pravno lice?",
      "brisanjeHeader": "Brisanje pravnog lica",
      "brisanjeUspesno": "Uspešno ste obrisali izabrano pravno lice!"
    },

    "sysKorisnikRola": {
      "pregledRola": "Pregled rola",
      "izmenaRolaHeader": "Izmena rola",
      "izmenaRolaUspesno": "Uspešno ste izmenili role korisniku. Korisnik će biti automatski izlogovan iz aplikacije, kako bi promene postale vidljive."
    },

    "sysKorisnik": {
      "id": "ID",
      "ime": "Ime",
      "prezime": "Prezime",
      "username": "Username",
      "arhivirano": "Arhivirano",
      "trenutakSnimanja": "Trenutak snimanja",
      "brisanjePitanje": "Želite li da obrišete izabranog korisnika?",
      "brisanjeHeader": "Brisanje korisnika",
      "brisanjeUspesno": "Uspešno ste obrisali izabranog korisnika!",
      "dodajPostojeceg": "Dodaj postojećeg",
      "stariPassword": "Stara lozinka",
      "noviPassword": "Nova lozinka",
      "noviPasswordPonovo": "Nova lozinka(ponovo)",
      "promenaPasswordaHeader": "Promena lozinke",
      "promenaPasswordaUspesno": "Uspešno ste promenili lozinku!",
      "promenaPasswordaRazlicitiPasswordi": "Vrednosti koje ste uneli za novu lozinku se ne poklapaju!",
      "noviPasswordMin6Karaktera": "Nova lozinka mora imati najmanje 6 karaktera!",
      "promenaPasswordaPogresanStari": "Morate uneti validnu postojeću lozinku",
      "resetLozinkeHeader": "Reset lozinke",
      "resetLozinkeUspesno": "Uspešno ste resetovali lozinku",
      "resetLozinkeNeuspesno": "Korisnik ne može resetovati svoju lozinku!",
      "dodavanjeHeader": "Dodavanje korisnika",
      "dodavanjeUspesno": "Uspešno ste dodali korisnika",
      "azuriranjeHeader": "Ažuriranje korisnika",
      "azuriranjeUspesno": "Uspešno ste ažurirali korisnika!",
      "usernameObavestenje": "Username: ",
      "lozinkaObavestenje": "Lozinka: "
    },

    "commonIzvestaji": {
      "sortirajPo": "Sortiraj izveštaj po:",
      "rizicnaRadnaMesta": "Rizičnim radnim mestima",
      "opis": "Opis",
      "obavestenja": "Obaveštenja",
      "mere": "Mere",
      "opasneMaterije": "Opasne materije",
      "opasnostiIStetnosti": "Opasnosti i štetnosti",
      "elementiPregleda": "Elementi lekarskog pregleda",
      "korisnickiPregledi": "Korisnički definisani lekarski pregledi",
      "korisnickeObuke": "Korisnički definisane obuke"
    },
    "izvestaji": {
      "lzoKojiJePotrebnoNabaviti": "LZO koji je potrebno nabaviti",
      "rokZaLzoIstice": "Rok za LZO ističe",
      "rokTrajanjaJeIstekao": "Rok trajanja je istekao",
      "nedodeljeniLzo": "Nedodeljeni LZO",
      "narednogMeseca": "narednog meseca",
      "uNarednaDvaMeseca": "u naredna dva meseca",
      "uNarednaTriMeseca": "u naredna tri meseca",
      "prikaziZaposleneSortiranePo": "Prikaži zaposleni sortirane po",
      "imenuIPrezimenu": "Imenu i prezimenu",
      "evidencionomBroju": "evidencionom broju",
      "internomMaticnojBroju": "IMB-u"
    },

    "sem": {
      "sif_organizaciona_struktura": "Šifarnik",
      "sif_organizaciona_struktura_opis": "Organizaciona struktura",
      "sif_organizaciona_struktura_nije_uneto_naziv": "ŠIF Organizaciona struktura: Podaci nisu uneti",

      "sif_radna_mesta": "Šifarnik",
      "sif_radna_mesta_opis": "Radna mesta",
      "sif_radna_mesta_nije_uneto_naziv": "ŠIF Radna mesta: Podaci nisu uneti – aplikacija se bez ovih podataka ne može koristiti",

      "sif_lica": "Šifarnik",
      "sif_lica_opis": "Lica",
      "sif_lica_nije_uneto_naziv": "ŠIF Lica: Podaci nisu uneti – aplikacija se bez ovih podataka ne može koristiti",

      "sif_radno_mesto_struktura": "Šifarnik",
      "sif_radno_mesto_struktura_opis": "Radna mesta u strukturi",
      "sif_radno_mesto_struktura_nije_uneto_naziv": "ŠIF Radna mesta u strukturi: Podaci nisu uneti – aplikacija se ne može koristiti",

      "sif_zaposleni": "Šifarnik",
      "sif_zaposleni_opis": "Zaposleni",
      "sif_zaposleni_nije_uneto_naziv": "ŠIF Zaposleni: Podaci nisu uneti – aplikacija se bez ovih podataka ne može koristiti",

      "sif_opasne_materije": "Šifarnik",
      "sif_opasne_materije_opis": "Opasne materije",
      "sif_opasne_materije_nije_uneto_naziv": "ŠIF Opasne materije: Podaci nisu uneti",

      "sif_parametri_radne_okoline": "Šifarnik",
      "sif_parametri_radne_okoline_opis": "Parametri radne okoline",
      "sif_parametri_radne_okoline_nije_uneto_naziv": "ŠIF Parametri radne okoline: Podaci nisu uneti",

      "sif_oprema_za_rad": "Šifarnik",
      "sif_oprema_za_rad_opis": "Oprema za rad",
      "sif_oprema_za_rad_nije_uneto_naziv": "ŠIF Oprema za rad: Podaci nisu uneti",

      "sif_lzo_vrsta_velicine": "Šifarnik",
      "sif_lzo_vrsta_velicine_opis": "Vrsta veličina za LZO",
      "sif_lzo_vrsta_velicine_nije_uneto_naziv": "ŠIF Vrste veličina za ličnu zaštitnu opremu: Podaci nisu uneti",

      "sif_licna_zastitna_oprema": "Šifarnik",
      "sif_licna_zastitna_oprema_opis": "Lična zaštitna oprema",
      "sif_licna_zastitna_oprema_nije_uneto_naziv": "ŠIF Lična zaštitna oprema: Kategorije lične zaštitne opreme nisu unete",
      "sif_licna_zastitna_oprema_kategorije_nisu_unete_naziv": "ŠIF Lična zaštitna oprema: Lična zaštitna oprema nije uneta",

      "sif_obuke": "Šifarnik",
      "sif_obuke_opis": "Obuke",
      "sif_obuke_nije_uneto_naziv": "ŠIF Korisnički definisane obuke: Podaci nisu uneti",

      "sif_pregledi": "Šifarnik",
      "sif_pregledi_opis": "Pregledi",
      "sif_pregledi_nije_uneto_naziv": "ŠIF Korisnički definisani pregledi: Podaci nisu uneti",

      "obr_obrazac01": "Obrazac 1",
      "obr_obrazac01_opis": "Evidencija o radnim mestima sa povećanim rizikom",
      "obr_obrazac01_rizicna_rm_naziv": "OB 01: Broj rizičnih radnih mesta bez dodeljenih opasnosti/štetnosti",
      "obr_obrazac01_nerizicna_rm_naziv": "OB 01: Broj nerizičnih radnih mesta bez dodeljenih opasnosti/štetnosti",

      "obr_obrazac02": "Obrazac 2",
      "obr_obrazac02_opis": "Evidencija o zaposlenima raspoređenim na radna mesta sa povećanim rizikom i lekarskim pregledima",
      "obr_obrazac02_neobavljeni_istekli_pregledi_naziv": "OB 02: Broj neobavljenih ili isteklih lekarskih pregleda",
      "obr_obrazac02_predstojeci_pregledi_naziv": "OB 02: Broj lekarskih pregleda koji uskoro treba da se obave",
      "obr_obrazac02_nema_izvestaj_lekara_naziv": "OB 02: Broj lekarskih pregleda koji nemaju izveštaj lekara",
      "obr_obrazac02_pregled_nesposobni_naziv": "OB 02: Broj zaposlenih koji su na lekarskom pregledu ocenjeni kao nesposobni",

      "obr_obrazac13": "Obrazac 13",
      "obr_obrazac13_opis": "Evidencija o korisnički definisanim pregledima",
      "obr_obrazac13_bez_pregleda_naziv": "OB 02 b: Broj zaposlenih bez obavljenih korisnički definisanih pregleda",
      "obr_obrazac13_pregled_istice_naziv": "OB 02 b: Broj zaposlenih kojima obavljen korisnički definisan pregled uskoro ističe",

      "obr_obrazac06": "Obrazac 6",
      "obr_obrazac06_opis": "Evidencija o zaposlenima osposobljenim za bezbedan i zdrav rad",
      "obr_obrazac06_bez_obuke_naziv": "OB 06: Broj zaposlenih bez obuke",
      "obr_obrazac06_u_procesu_obuke_naziv": "OB 06: Broj zaposlenih u procesu obuke",

      "obr_obrazac06a": "Obrazac 6a",
      "obr_obrazac06a_opis": "Evidencija o korisnički definisanim obukama",
      "obr_obrazac06a_zaposleni_bez_obuka_naziv": "OB 06a: Broj zaposlenih bez obavljenih korisnički definisanih obuka",
      "obr_obrazac06a_zaposleni_obuke_isticu_naziv": "OB 06a: Broj zaposlenih kojima obavljena korisnički definisana obuka uskoro ističe",

      "obr_obrazac07": "Obrazac 7",
      "obr_obrazac07_opis": "Evidencija o opasnim materijama koje se koriste u toku rada",
      "obr_obrazac07_radna_mesta_nedodeljene_materije_naziv": "OB 07: Broj radnih mesta sa definisanim, a nedodeljenim opasnim materijama",
      "obr_obrazac07_definisane_nedodeljene_materije_naziv": "OB 07: Broj definisanih, a nedodeljenih opasnih materija",

      "obr_obrazac08": "Obrazac 8",
      "obr_obrazac08_opis": "Evidencija o izvršenim ispitivanjima radne okoline",
      "obr_obrazac08_neispitani_parametri_naziv": "OB 08: Broj neispitanih parametara radne okoline",
      "obr_obrazac08_predstojeci_parametri_naziv": "OB 08: Broj parametara radne okoline koji uskoro trebaju da se ispitaju",

      "obr_obrazac09": "Obrazac 9",
      "obr_obrazac09_opis": "Evidencija o izvršenim pregledima i ispitivanjima opreme za rad",
      "obr_obrazac09_istekli_neobavljeni_pregledi_naziv": "OB 09: Broj isteklih ili neobavljenih pregleda opreme za rad",
      "obr_obrazac09_pregledi_koji_isticu_naziv": "OB 09: Broj pregleda opreme za rad koji uskoro ističu",

      "obr_obrazac10": "Obrazac 10",
      "obr_obrazac10_opis": "Evidencija o izvršenim pregledima i ispitivanjima sredstava i opreme za ličnu zaštitu na radu",
      "obr_obrazac10_lzo_bez_atesta_naziv": "OB 10: Broj lične zaštitne opreme bez atesta",
      "obr_obrazac10_lzo_bez_uputstva_naziv": "OB 10: Broj lične zaštitne opreme bez uputstva na srpskom",
      "obr_obrazac10_zaposleni_bez_lzo_naziv": "OB 10: Broj zaposlenih bez dodeljene predviđene LZO",
      "obr_obrazac10_zaposleni_lzo_istekao_rok_trajanja_naziv": "OB 10: Broj zaposlenih sa LZO kojoj je istekao rok trajanja",
      "obr_obrazac10_zaposleni_lzo_istice_rok_trajanja_naziv": "OB 10: Broj zaposlenih sa LZO kojoj uskoro ističe rok trajanja",
      "obr_obrazac10_zaposleni_lzo_istekao_rok_ispitivanja_naziv": "OB 10: Broj zaposlenih sa LZO kojoj je istekao rok za ispitivanje",
      "obr_obrazac10_zaposleni_lzo_istice_rok_ispitivanja_naziv": "OB 10: Broj zaposlenih sa LZO kojoj uskoro ističe rok za ispitivanje",
      "obr_obrazac10_lzo_istekao_rok_trajanja_naziv": "OB 10: Broj LZO kojoj je istekao rok trajanja",
      "obr_obrazac10_lzo_istice_rok_trajanja_naziv": "OB 10: Broj LZO kojoj uskoro ističe rok trajanja",
      "obr_obrazac10_lzo_istekao_rok_ispitivanja_naziv": "OB 10: Broj LZO kojoj je istekao rok za ispitivanje",
      "obr_obrazac10_lzo_istice_rok_ispitivanja_naziv": "OB 10: Broj LZO kojoj uskoro ističe rok za ispitivanje",

      "obr_obrazac11": "Obrazac 11",
      "obr_obrazac11_opis": "Evidencija o prijavama povreda na radu",
      "obr_obrazac11_neprijavljene_povrede_naziv": "OB 11: Broj neprijavljenih povreda na radu:",

      "obr_obrazac12": "Obrazac 12",
      "obr_obrazac12_opis": "Evidencija o prijavama profesionalnih oboljenja",
      "obr_obrazac12_neprijavljena_oboljenja_naziv": "OB 12: Broj neprijavljenih profesionalnih oboljenja:",

      "obr_obrazac13": "Obrazac 13",
      "obr_obrazac13_opis": "Evidencija o prijavama bolesti u vezi sa radom",
      "obr_obrazac13_neprijavljene_bolesti_naziv": "OB 13: Broj neprijavljenih bolesti u vezi sa radom:",

      "prikazujObavestenje": "Prikazuj obaveštenje",
      "nePrikazujObavestenje": "Ne prikazuj obaveštenje",

      "dana7": "7 dana",
      "dana14": "14 dana",
      "dana30": "30 dana",
      "dana60": "60 dana",
      "dana90": "90 dana",
      "dana180": "180 dana",

      "podesavanjeObavestenjaHeader": "Podešavanje obaveštenja",
      "podesavanjeObavestenjaUspesno": "Uspešno ste izmenili podešavanja obaveštenja",
      "resetovanjeObavestenjaUspesno": "Uspešno ste resetovali obaveštenja",
      "resetujPodesavanja": "Reset podešavanja"
    },

    "vertikalniIzvestaj": {
      "osnovniPodaci": "Osnovni podaci",
      "podaciORadnomMestuZaposlenog": "Podaci o radnom mestu zaposlenog",
      "opisRadnogMesta": "Opis radnog mesta",
      "opasnostiIStetnostiNaRadnomMestuZaposlenog": "Opasnosti i štetnosti na radnom mestu zaposlenog",
      "mereZaBezbedanIZdravRad": "Mere za bezbedan i zdrav rad",
      "obavestenja": "Obaveštenja, uputstva ili instrukcije sa kojima je zaposleni upoznat radi obavljanja procesa rada na bezbedan način",
      "lekarskiPregledi": "Lekarski pregledi",
      "obavezniLekarskiPregledi": "Obavezni lekarski pregledi",
      "korisnickiDefinisaniLekarskiPregledi": "Korisnički definisani lekarski pregledi",
      "profesionalnaOboljenja": "Profesionalna oboljenja",
      "bolestiUVeziSaRadom": "Bolesti u vezi sa radom",
      "povredeNaRadu": "Povrede na radu",
      "osposobljenosti": "Osposobljenosti",
      "obavezneObuke": "Obavezne obuke",
      "korisnickiDefinisaneObuke": "Korisnički definisane obuke",
      "opasneMaterije": "Opasne materije",
      "licnaZastitnaOprema": "Lična zaštitna oprema"
    },

    "porukaUspesnoSacuvanObrazac": "Uspešno ste sačuvali obrazac",

    "coreErrorMessage": "Došlo je do nepredviđene greške!",

    "commonPanel": {
      "brisanjeHeader": "Brisanje podataka",
      "brisanjePitanje": "Da li ste sigurni da želite da obrišete izabrani podatak?",
      "brisanjeUspesno": "Uspešno ste obrisali odabrane podatke!",
      "dodavanjeHeader": "Dodavanje",
      "dodavanjeUspesno": "Uspešno ste dodali podatak!",
      "azuriranjeHeader": "Ažuriranje",
      "azuriranjeUspesno": "Uspešno ste ažurirali podatak!"
    }
  };

});