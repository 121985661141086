define('zaris-frontend/components/obr-obrazac02-uput-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'obrObrazac02UputModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "95%",
    "data-width": "95%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",
    "data-type": "modal",

    elementiPregledaContent: Em.A(),
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    didInsertElement: function didInsertElement() {
      this._super();
      $('.dialog').css({ top: '0px' });
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {
      /* dovlacenje itema za select listu*/
      var loginService = this.get('login');
      var sifZdravstvenaSposobnostModel = Models['default'].SifZdravstvenaSposobnost.create({ errorHandlerController: this });
      this.set('selectItemsZdravstvenaSposobnost', sifZdravstvenaSposobnostModel.findAllGetResult(loginService.getAccessToken()));
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        var i18n = this.get('i18n');

        if (this.get('validate').validate(this)) {
          var outter = this;
          var modelObject = this.get('modelObject');

          if (this.get('fgPeriodicni')) {
            modelObject.sifVrstaLekarskogPregleda = { idVrstaLekarskogPregleda: 2 };
          } else {
            modelObject.sifVrstaLekarskogPregleda = { idVrstaLekarskogPregleda: 1 };
          }

          modelObject.sifZaposleni = { idZaposleni: this.get('selektovaniZaposleni.idZaposleni') };

          modelObject.obrObrazac02ElementZdravstvenogPregledas = [];
          outter.get('elementiPregledaContent').forEach(function (val, index) {
            if (val.get('isSelected')) {
              modelObject.obrObrazac02ElementZdravstvenogPregledas.push({
                'sifElementPregleda': { 'idElementPregleda': val.get('id.idElementPregleda') },
                'interval': val.get('interval') !== null ? val.get('interval') : 0
              });
            }
          });

          //provera da li je odabran bar jedan element pregleda
          if (modelObject.obrObrazac02ElementZdravstvenogPregledas.length === 0) {
            outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac02.dodavanjeHeader', 'obrObrazac02.morateOdabratiElementePregleda', 'warning');
            return;
          }

          var obrObrazac02ZdravstveniPregledModel = Models['default'].ObrObrazac02ZdravstveniPregled.create({ errorHandlerController: this });
          obrObrazac02ZdravstveniPregledModel.create(modelObject, this.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('sifLica.dodavanjeHeader').string,
              content: i18n.t('sifLica.dodavanjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
            outter.closePanel();
            outter.toggleProperty('refreshObrazac02Modal');
          });
        }
      },

      update: function update() {
        var i18n = this.get('i18n');

        if (this.get('validate').validate(this)) {
          var outter = this;
          var modelObject = this.get('modelObject');

          var obrObrazac02ZdravstveniPregledModel = Models['default'].ObrObrazac02ZdravstveniPregled.create({ errorHandlerController: this });
          obrObrazac02ZdravstveniPregledModel.update(modelObject, this.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('sifLica.azuriranjeHeader').string,
              content: i18n.t('sifLica.azuriranjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
            outter.closePanel();
            outter.toggleProperty('refreshObrazac02Modal');
          });
        }
      }

    },

    initPanel: function initPanel() {
      var outter = this;

      if (this.get('fgUnos')) {
        this.set('modelObject', {});
      } else {
        this.set('modelObject', this.get('selektovaniPregled'));
      }

      var sifLicaModel = Models['default']['SifLica'].create({ errorHandlerController: this });
      sifLicaModel.getById('dohvatiLiceZaZaposlenog', this.get('selektovaniZaposleni.idZaposleni'), this.get('login').getAccessToken(), 'GET', function (o) {
        outter.set('modelObject.imePrezime', o.ime + ' ' + o.prezime);
        outter.set('modelObject.srednjeIme', o.srednjeIme);
        outter.set('modelObject.maticniBroj', o.maticniBroj);
        outter.set('modelObject.mestoRodjenja', o.mestoRodjenja);
        outter.set('modelObject.datumRodjenja', o.datumRodjenja);
        outter.set('modelObject.opstinaRodjenja', o.opstinaRodjenja);
        outter.set('modelObject.zanimanje', o.zanimanje);

        outter.set('modelObject.radnoMesto', outter.get('selektovaniZaposleni.radnoMestoNaziv'));
      });

      var sifRadnoMestoStrukturaModel = Models['default']['SifRadnoMestoStruktura'].create({ errorHandlerController: this });
      sifRadnoMestoStrukturaModel.getById('dohvatiRmsZaZaposlenog', this.get('selektovaniZaposleni.idZaposleni'), this.get('login').getAccessToken(), 'GET', function (o) {
        if (outter.get('fgUnos')) {
          outter.set('modelObject.kratakOpisPosla', o.opis);
        }

        outter.set('modelObject.potrebniZdravstveniUslovi', o.zdravstveniUslovi);
      });

      var obrObrazac1RmsOpasnostiModel = Models['default']['ObrObrazac1RmsOpasnosti'].create({ errorHandlerController: this });
      obrObrazac1RmsOpasnostiModel.getById('getSveOpasnostiString', this.get('selektovaniZaposleni.idRadnoMestoStruktura'), this.get('login').getAccessToken(), 'GET', function (o) {
        console.log(o);
        if (outter.get('fgUnos')) {
          var str = o;

          outter.set('modelObject.opasnostiIRizici', str);
        }
      });
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshElementiPregleda');

      this.get('validate').hideValidations(this);

      this.initPanel();

      var outter = this;
      setTimeout(function () {
        outter.set('componentName', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac02UputModal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshObrazac02UputModal', function () {
      this.toggleProperty('refreshObrazac02Modal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.obrObrazac02UputModal').data('dialog').close();
    },

    tableElementiPregledaColumns: Ember['default'].computed('fgUnos', function () {
      var columns = [];

      if (this.get('fgUnos')) {
        columns.push(ColumnDefinition['default'].create({
          savedWidth: 100,
          supportSort: false,
          tableCellView: 'zaris-datatable-checkbox-cell',
          getCellContent: function getCellContent(row) {
            return row.get('content.isSelected');
          },
          setCellContent: function setCellContent(row, value) {
            return row.set('content.isSelected', value);
          }
        }));
      }

      columns.push(ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.sifra'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.elementPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPrethodnogPregleda',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.datumPrethodnogPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'interval',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.interval'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledecegPregleda',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.datumSledecegPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      return columns;
    })
  });

});