define('zaris-frontend/templates/components/obr-obrazac03-report', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 219,
                "column": 20
              },
              "end": {
                "line": 221,
                "column": 20
              }
            },
            "moduleName": "zaris-frontend/templates/components/obr-obrazac03-report.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" [");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("]\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["content","rm.radnoMestoNaziv",["loc",[null,[220,22],[220,44]]]],
            ["content","rm.organizacionaStruktura",["loc",[null,[220,46],[220,75]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 218,
              "column": 18
            },
            "end": {
              "line": 222,
              "column": 18
            }
          },
          "moduleName": "zaris-frontend/templates/components/obr-obrazac03-report.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","equals",[["get","rm.idRadnoMestoStruktura",["loc",[null,[219,34],[219,58]]]],["get","idRadnoMestoStruktura",["loc",[null,[219,59],[219,80]]]]],[],["loc",[null,[219,26],[219,81]]]]],[],0,null,["loc",[null,[219,20],[221,27]]]]
        ],
        locals: ["rm"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 621,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/obr-obrazac03-report.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","obrObrazac03PovredniList");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"align","left");
        dom.setAttribute(el3,"style","margin-top:1px");
        var el4 = dom.createTextNode("\n\n     	");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","obrazac");
        dom.setAttribute(el4,"style","display: none;");
        var el5 = dom.createTextNode("\n  	    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"id","mojGrid");
        dom.setAttribute(el5,"style","{display:block}");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"id","button-grid");
        dom.setAttribute(el6,"class"," x-panel x-grid-panel");
        dom.setAttribute(el6,"style","");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","x-panel-tl");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","x-panel-tr");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-panel-tc");
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","x-panel-header x-unselectable x-panel-icon gridUnosIcon");
        dom.setAttribute(el10,"id","ext-gen72");
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-tool x-tool-refresh");
        dom.setAttribute(el11,"id","ext-gen79");
        var el12 = dom.createTextNode(" ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","x-panel-header-text");
        dom.setAttribute(el11,"id","ext-gen96");
        var el12 = dom.createTextNode("OB 03 Evidencija o povredama na radu");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","x-panel-bwrap");
        dom.setAttribute(el7,"id","ext-gen73");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","x-panel-ml");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-panel-mr");
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","x-panel-mc");
        dom.setAttribute(el10,"id","ext-gen78");
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-panel-tbar");
        dom.setAttribute(el11,"id","ext-gen74");
        dom.setAttribute(el11,"style","width: 978px;");
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"id","ext-comp-1120");
        dom.setAttribute(el12,"class","x-toolbar x-small-editor x-toolbar-layout-ct");
        dom.setAttribute(el12,"style","width: 972px;");
        var el13 = dom.createElement("table");
        dom.setAttribute(el13,"cellspacing","0");
        dom.setAttribute(el13,"class","x-toolbar-ct");
        var el14 = dom.createElement("tbody");
        var el15 = dom.createElement("tr");
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-left");
        dom.setAttribute(el16,"align","left");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        dom.setAttribute(el19,"class","x-toolbar-left-row");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen98");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1121");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon ");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen99");
        dom.setAttribute(el26,"class"," x-btn-text extButtonDodaj");
        var el27 = dom.createTextNode("Dodaj");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen100");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1122");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon x-item-disabled");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen101");
        dom.setAttribute(el26,"class"," x-btn-text extButtonIzmeni");
        var el27 = dom.createTextNode("Pregled i izmena");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen102");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1123");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon x-item-disabled");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen103");
        dom.setAttribute(el26,"class"," x-btn-text extButtonObrisi");
        var el27 = dom.createTextNode("Obriši");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen104");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen105");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen106");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1125");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen107");
        dom.setAttribute(el26,"class"," x-btn-text extButtonPdf");
        var el27 = dom.createTextNode("Sačuvaj OB 03");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen108");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1126");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon x-item-disabled");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","x-btn-arrow");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen109");
        dom.setAttribute(el26,"class"," x-btn-text extButtonPreuzmi");
        var el27 = dom.createTextNode("Preuzmi povredni list");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen110");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1130");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen111");
        dom.setAttribute(el26,"class"," x-btn-text extButtonSacuvaniObrasci");
        var el27 = dom.createTextNode("Sačuvani obrasci");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen112");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1131");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-text-icon");
        dom.setAttribute(el21,"style","width: auto;");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen113");
        dom.setAttribute(el26,"class"," x-btn-text extButtonIzvestaji");
        var el27 = dom.createTextNode("Izveštaji");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-right");
        dom.setAttribute(el16,"align","right");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"class","x-toolbar-right-ct");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-right-row");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-extras-row");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-panel-body");
        dom.setAttribute(el11,"id","ext-gen75");
        dom.setAttribute(el11,"style","width: 976px; height: 408px;");
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","x-grid3");
        dom.setAttribute(el12,"hidefocus","true");
        dom.setAttribute(el12,"id","ext-gen83");
        dom.setAttribute(el12,"style","width: 976px; height: 408px;");
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","x-grid3-viewport");
        dom.setAttribute(el13,"id","ext-gen84");
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-grid3-header");
        dom.setAttribute(el14,"id","ext-gen85");
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","x-grid3-header-inner");
        dom.setAttribute(el15,"id","ext-gen91");
        dom.setAttribute(el15,"style","width: 976px;");
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-header-offset");
        dom.setAttribute(el16,"style","width:976px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("thead");
        var el19 = dom.createElement("tr");
        dom.setAttribute(el19,"class","x-grid3-hd-row");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-checker");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-hd-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-id");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-obid");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-lice");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Ime i prezime");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Radno mesto");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-datum");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Datum povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-ocena");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Ocena težine povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Vrsta povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Izvor povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-hd x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-hd-inner x-grid3-hd-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        dom.setAttribute(el21,"style","");
        var el22 = dom.createElement("a");
        dom.setAttribute(el22,"class","x-grid3-hd-btn");
        dom.setAttribute(el22,"href","#");
        dom.appendChild(el21, el22);
        var el22 = dom.createTextNode("Uzrok povrede");
        dom.appendChild(el21, el22);
        var el22 = dom.createElement("img");
        dom.setAttribute(el22,"class","x-grid3-sort-icon");
        dom.setAttribute(el22,"src","../../resources/images/default/s.gif");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","x-clear");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-grid3-scroller");
        dom.setAttribute(el14,"id","ext-gen86");
        dom.setAttribute(el14,"style","width: 976px; height: 384px;");
        var el15 = dom.createElement("div");
        dom.setAttribute(el15,"class","x-grid3-body");
        dom.setAttribute(el15,"style","width:957px;");
        dom.setAttribute(el15,"id","ext-gen87");
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row  x-grid3-row-first ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("5");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode(" ");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Milan Laketić");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("23.12.2015");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Nije izabrano");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Kolektivna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("10 - Mašine i oprema - stabilni, neodređeni");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("31 - Razbijanje materjala - na zglobu , šavu");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("3");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("3");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Aleksandar Ilić");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("27.01.2015");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Nije izabrano");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Pojedinačna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("01 - Zgrade, građevine, površine na ravnoj podlozi (spolja ili unutra) - neodređeno");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("11 - Problemi sa električnom strujom zbog kvara na opremi - posredni kontakt");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("4");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("4");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Aleksandra Jovanović");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("01.01.2015");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Nije izabrano");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Pojedinačna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("02 - Zgrade, građevine,površine iznad ravni tla (spolja ili unutra) - neodređene");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("11 - Problemi sa električnom strujom zbog kvara na opremi - posredni kontakt");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("div");
        dom.setAttribute(el16,"class","x-grid3-row  x-grid3-row-last ");
        dom.setAttribute(el16,"style","width:957px;");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"class","x-grid3-row-table");
        dom.setAttribute(el17,"border","0");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"cellpadding","0");
        dom.setAttribute(el17,"style","width:957px;");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-checker x-grid3-cell-first ");
        dom.setAttribute(el20,"style","width:20px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-checker");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createElement("div");
        dom.setAttribute(el22,"class","x-grid3-row-checker");
        var el23 = dom.createTextNode(" ");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-id ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-id");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("2");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-obid ");
        dom.setAttribute(el20,"style","width:5px;display:none;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-obid");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("2");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-lice ");
        dom.setAttribute(el20,"style","width:167px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-lice");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Aleksandar Ilić");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-rmesto ");
        dom.setAttribute(el20,"style","width:250px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-rmesto");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Operater na finalizaciji (Cooper Standard)");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-datum ");
        dom.setAttribute(el20,"style","width:90px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-datum");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("01.01.2011");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-ocena ");
        dom.setAttribute(el20,"style","width:120px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-ocena");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Laka");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-vrsta ");
        dom.setAttribute(el20,"style","width:110px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-vrsta");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("Kolektivna");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-izvor_povrede ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-izvor_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("06 - Ručno oruđe, bez spoljašnje energije - neodređeno");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-grid3-col x-grid3-cell x-grid3-td-uzrok_povrede x-grid3-cell-last ");
        dom.setAttribute(el20,"style","width:100px;");
        dom.setAttribute(el20,"tabindex","0");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","x-grid3-cell-inner x-grid3-col-uzrok_povrede");
        dom.setAttribute(el21,"unselectable","on");
        var el22 = dom.createTextNode("19 - Drugi oblici nezgoda, obuhvaćenih šifrom 10");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("a");
        dom.setAttribute(el15,"href","#");
        dom.setAttribute(el15,"class","x-grid3-focus");
        dom.setAttribute(el15,"tabindex","-1");
        dom.setAttribute(el15,"id","ext-gen88");
        dom.setAttribute(el15,"style","left: 1px; top: 1px;");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","x-grid3-resize-marker");
        dom.setAttribute(el13,"id","ext-gen89");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","x-grid3-resize-proxy");
        dom.setAttribute(el13,"id","ext-gen90");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","x-panel-bbar");
        dom.setAttribute(el11,"id","ext-gen76");
        dom.setAttribute(el11,"style","width: 978px;");
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"id","ext-comp-1106");
        dom.setAttribute(el12,"class","x-toolbar x-small-editor x-toolbar-layout-ct");
        dom.setAttribute(el12,"style","width: 972px;");
        var el13 = dom.createElement("table");
        dom.setAttribute(el13,"cellspacing","0");
        dom.setAttribute(el13,"class","x-toolbar-ct");
        var el14 = dom.createElement("tbody");
        var el15 = dom.createElement("tr");
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-left");
        dom.setAttribute(el16,"align","left");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        dom.setAttribute(el19,"class","x-toolbar-left-row");
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen114");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1107");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen115");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-first");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen116");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1108");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen117");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-prev");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen118");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen119");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen120");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","xtb-text");
        dom.setAttribute(el21,"id","ext-comp-1116");
        var el22 = dom.createTextNode("Strana");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen121");
        var el21 = dom.createElement("input");
        dom.setAttribute(el21,"type","text");
        dom.setAttribute(el21,"size","20");
        dom.setAttribute(el21,"autocomplete","off");
        dom.setAttribute(el21,"id","ext-comp-1109");
        dom.setAttribute(el21,"class"," x-form-text x-form-field x-form-num-field x-tbar-page-number");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen122");
        var el21 = dom.createElement("div");
        dom.setAttribute(el21,"class","xtb-text");
        dom.setAttribute(el21,"id","ext-comp-1110");
        var el22 = dom.createTextNode("od 1");
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen123");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen124");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen125");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1111");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen126");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-next");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen127");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1112");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon x-item-disabled");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen128");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-page-last");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen129");
        var el21 = dom.createElement("span");
        dom.setAttribute(el21,"class","xtb-sep");
        dom.setAttribute(el21,"id","ext-gen130");
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        dom.setAttribute(el20,"class","x-toolbar-cell");
        dom.setAttribute(el20,"id","ext-gen131");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"id","ext-comp-1113");
        dom.setAttribute(el21,"cellspacing","0");
        dom.setAttribute(el21,"class","x-btn   x-btn-icon ");
        var el22 = dom.createElement("tbody");
        dom.setAttribute(el22,"class","x-btn-small x-btn-icon-small-left");
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-tr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-ml");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mc");
        var el25 = dom.createElement("em");
        dom.setAttribute(el25,"class","");
        dom.setAttribute(el25,"unselectable","on");
        var el26 = dom.createElement("button");
        dom.setAttribute(el26,"type","button");
        dom.setAttribute(el26,"id","ext-gen132");
        dom.setAttribute(el26,"class"," x-btn-text x-tbar-loading");
        var el27 = dom.createTextNode(" ");
        dom.appendChild(el26, el27);
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-mr");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        var el23 = dom.createElement("tr");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bl");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-bc");
        dom.appendChild(el23, el24);
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-btn-br");
        var el25 = dom.createElement("i");
        var el26 = dom.createTextNode(" ");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("td");
        dom.setAttribute(el16,"class","x-toolbar-right");
        dom.setAttribute(el16,"align","right");
        var el17 = dom.createElement("table");
        dom.setAttribute(el17,"cellspacing","0");
        dom.setAttribute(el17,"class","x-toolbar-right-ct");
        var el18 = dom.createElement("tbody");
        var el19 = dom.createElement("tr");
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-right-row");
        var el24 = dom.createElement("td");
        dom.setAttribute(el24,"class","x-toolbar-cell");
        dom.setAttribute(el24,"id","ext-gen133");
        var el25 = dom.createElement("div");
        dom.setAttribute(el25,"class","xtb-text");
        dom.setAttribute(el25,"id","ext-comp-1114");
        var el26 = dom.createTextNode("Prikaz 1 - 4 od 4");
        dom.appendChild(el25, el26);
        dom.appendChild(el24, el25);
        dom.appendChild(el23, el24);
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        var el20 = dom.createElement("td");
        var el21 = dom.createElement("table");
        dom.setAttribute(el21,"cellspacing","0");
        var el22 = dom.createElement("tbody");
        var el23 = dom.createElement("tr");
        dom.setAttribute(el23,"class","x-toolbar-extras-row");
        dom.appendChild(el22, el23);
        dom.appendChild(el21, el22);
        dom.appendChild(el20, el21);
        dom.appendChild(el19, el20);
        dom.appendChild(el18, el19);
        dom.appendChild(el17, el18);
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","x-panel-bl x-panel-nofooter");
        dom.setAttribute(el8,"id","ext-gen77");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-panel-br");
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","x-panel-bc");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  	");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  	");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","div-forma");
        dom.setAttribute(el4,"style","display: block;");
        var el5 = dom.createTextNode("\n  	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("table");
        dom.setAttribute(el5,"border","0");
        dom.setAttribute(el5,"cellpadding","0");
        dom.setAttribute(el5,"cellspacing","0");
        dom.setAttribute(el5,"width","");
        dom.setAttribute(el5,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el6 = dom.createTextNode("\n  		");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tbody");
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"align","center");
        dom.setAttribute(el8,"class","fnaslov");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"width","25%");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"width","25%");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"width","50%");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("2.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(", ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(", ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("3.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("4.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("5.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("6.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align: middle; font-weight: bold;");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  				");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("7.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("8.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("9.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"id","ext-gen137");
        var el9 = dom.createTextNode("\n              	3. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap hideInnerComponents");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","ftextbn");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("10.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("11.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("12.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              	");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("13.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"id","ext-gen149");
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el9,"id","ext-gen150");
        dom.setAttribute(el9,"style","width: 195px;");
        var el10 = dom.createTextNode("\n          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"rowspan","2");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("14.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"rowspan","3");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align:top");
        var el9 = dom.createTextNode("15.Mesto rada");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("16.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("17.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"style","vertical-align:top");
        var el9 = dom.createTextNode("18.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  			");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"id","ext-gen140");
        var el9 = dom.createTextNode("4. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                     	");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap hideInnerComponents");
        dom.setAttribute(el9,"id","ext-gen141");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("19.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("20.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("21.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              	");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("22.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  			");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"name","rmesto_kombo_povredjeni");
        dom.setAttribute(el8,"id","rmesto_kombo_povredjeni");
        var el9 = dom.createTextNode("5.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        dom.setAttribute(el8,"colspan","2");
        var el9 = dom.createTextNode("23.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control-compact dropdownClearStyle");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("24.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("25.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("26.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("<td class=\"ftext\"><input type=\"text\" name=\"ukupan_staz\" id=\"ukupan_staz\" value=\"\" class=\"polja\" maxlength=\"3\" onkeyup=\"this.value=isNumeric(this.value); if(this.value > 100 || this.value < 0) { this.value = ''; alert('Morate uneti broj izmedju 0 i 100')}\"  style=\"width:200px\"/></td>");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("27.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("28.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"rowspan","3");
        dom.setAttribute(el8,"class","ftextbn");
        var el9 = dom.createTextNode("29.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("Dan, mesec, godina");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              	");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("30.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              	");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n             ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("31.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("32.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("33.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("34.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("35.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("36-41. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("41.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              	");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n             	");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        dom.setAttribute(el8,"id","ext-gen143");
        var el9 = dom.createTextNode("6. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              	");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("input");
        dom.setAttribute(el9,"type","hidden");
        dom.setAttribute(el9,"name","ocevidac_id");
        dom.setAttribute(el9,"id","ocevidac_id");
        dom.setAttribute(el9,"value","");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","x-form-field-wrap x-form-field-trigger-wrap hideInnerComponents");
        dom.setAttribute(el9,"id","ext-gen144");
        dom.setAttribute(el9,"style","width: 971px;");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("42.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("43.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("44.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        var el9 = dom.createTextNode("7.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("45.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("46.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("47.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            	");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("48.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              	 ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                   ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("49.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("50.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("51.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n              	");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("52.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("53.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("54.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("55.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n  			        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("56.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n    				    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("57.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("\n  				      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("58.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createTextNode("\n  				");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("table");
        dom.setAttribute(el9,"border","0");
        dom.setAttribute(el9,"cellpadding","0");
        dom.setAttribute(el9,"cellspacing","0");
        dom.setAttribute(el9,"width","100%");
        dom.setAttribute(el9,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el10 = dom.createTextNode("\n  					");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tbody");
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode("\n                          	");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":                            ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        dom.setAttribute(el13,"id","ext-gen155");
        var el14 = dom.createTextNode("\n                          		");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el14,"id","ext-gen156");
        dom.setAttribute(el14,"style","width: 209px;");
        var el15 = dom.createTextNode("\n                              ");
        dom.appendChild(el14, el15);
        var el15 = dom.createComment("");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                              ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          	");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                          ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","10%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(": ______________________");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"style","text-align: center;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  				");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n  			");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","fmalinaslov");
        var el9 = dom.createTextNode("8. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("59. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("60. ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("61.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("62.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("63.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("64.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("65.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("66.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","2");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createTextNode("67.");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        var el9 = dom.createTextNode("\n  				");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("table");
        dom.setAttribute(el9,"border","0");
        dom.setAttribute(el9,"cellpadding","0");
        dom.setAttribute(el9,"cellspacing","0");
        dom.setAttribute(el9,"width","100%");
        dom.setAttribute(el9,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el10 = dom.createTextNode("\n  					");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tbody");
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode("\n                          	");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":                  ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        dom.setAttribute(el13,"id","ext-gen158");
        var el14 = dom.createTextNode("\n                          		");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","x-form-field-wrap x-form-field-trigger-wrap");
        dom.setAttribute(el14,"id","ext-gen159");
        dom.setAttribute(el14,"style","width: 209px;");
        var el15 = dom.createTextNode("\n                                ");
        dom.appendChild(el14, el15);
        var el15 = dom.createComment("");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                              ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          	");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                          ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","10%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode("\n                          	");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":                              ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        var el14 = dom.createTextNode("\n                          		");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          	");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                          ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"style","text-align: center;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  				");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n  			");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","ftext");
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n  			");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.setAttribute(el8,"colspan","3");
        dom.setAttribute(el8,"class","okvir");
        var el9 = dom.createTextNode("\n  				");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("table");
        dom.setAttribute(el9,"border","0");
        dom.setAttribute(el9,"cellpadding","0");
        dom.setAttribute(el9,"cellspacing","0");
        dom.setAttribute(el9,"width","100%");
        dom.setAttribute(el9,"style","color:#000; font-size:12px; background-color:#ffffff;");
        var el10 = dom.createTextNode("\n  					");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tbody");
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"colspan","5");
        dom.setAttribute(el12,"class","fmalinaslov");
        dom.setAttribute(el12,"style","text-align: center; border: none;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(" |_|_|");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(":");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","10%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","20%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"width","25%");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(": ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("span");
        dom.setAttribute(el13,"style","width:70%; border-bottom:solid 1px black");
        var el14 = dom.createTextNode(" ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"style","text-align: center;");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  					");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftextbn");
        dom.setAttribute(el12,"align","center");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  						");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12,"class","ftext");
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n  					");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n  				");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n  			");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n  		");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  	");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n  									");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n                  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1, 1, 1, 3, 1, 1]);
        var element1 = dom.childAt(element0, [6]);
        var element2 = dom.childAt(element0, [8]);
        var element3 = dom.childAt(element2, [5]);
        var element4 = dom.childAt(element0, [10]);
        var element5 = dom.childAt(element0, [12]);
        var element6 = dom.childAt(element0, [14]);
        var element7 = dom.childAt(element0, [16]);
        var element8 = dom.childAt(element0, [22]);
        var element9 = dom.childAt(element0, [24]);
        var element10 = dom.childAt(element0, [26]);
        var element11 = dom.childAt(element10, [5]);
        var element12 = dom.childAt(element0, [28, 1]);
        var element13 = dom.childAt(element0, [32]);
        var element14 = dom.childAt(element0, [34]);
        var element15 = dom.childAt(element0, [36]);
        var element16 = dom.childAt(element0, [38]);
        var element17 = dom.childAt(element0, [40]);
        var element18 = dom.childAt(element0, [42]);
        var element19 = dom.childAt(element0, [44]);
        var element20 = dom.childAt(element0, [46]);
        var element21 = dom.childAt(element0, [48]);
        var element22 = dom.childAt(element0, [50]);
        var element23 = dom.childAt(element0, [52]);
        var element24 = dom.childAt(element0, [54]);
        var element25 = dom.childAt(element0, [56]);
        var element26 = dom.childAt(element0, [58, 1]);
        var element27 = dom.childAt(element0, [60]);
        var element28 = dom.childAt(element0, [62]);
        var element29 = dom.childAt(element0, [64]);
        var element30 = dom.childAt(element29, [5]);
        var element31 = dom.childAt(element0, [66]);
        var element32 = dom.childAt(element0, [70]);
        var element33 = dom.childAt(element0, [72]);
        var element34 = dom.childAt(element0, [74]);
        var element35 = dom.childAt(element0, [76]);
        var element36 = dom.childAt(element0, [78]);
        var element37 = dom.childAt(element0, [80]);
        var element38 = dom.childAt(element0, [82]);
        var element39 = dom.childAt(element0, [84]);
        var element40 = dom.childAt(element0, [86]);
        var element41 = dom.childAt(element0, [88]);
        var element42 = dom.childAt(element0, [90]);
        var element43 = dom.childAt(element0, [92]);
        var element44 = dom.childAt(element0, [94]);
        var element45 = dom.childAt(element0, [96]);
        var element46 = dom.childAt(element0, [98]);
        var element47 = dom.childAt(element0, [100, 1]);
        var element48 = dom.childAt(element0, [102]);
        var element49 = dom.childAt(element0, [104]);
        var element50 = dom.childAt(element0, [106]);
        var element51 = dom.childAt(element50, [3]);
        var element52 = dom.childAt(element0, [110]);
        var element53 = dom.childAt(element0, [112]);
        var element54 = dom.childAt(element0, [114]);
        var element55 = dom.childAt(element0, [116]);
        var element56 = dom.childAt(element0, [118]);
        var element57 = dom.childAt(element0, [120]);
        var element58 = dom.childAt(element0, [122]);
        var element59 = dom.childAt(element0, [124]);
        var element60 = dom.childAt(element0, [126]);
        var element61 = dom.childAt(element0, [128]);
        var element62 = dom.childAt(element0, [130]);
        var element63 = dom.childAt(element0, [132]);
        var element64 = dom.childAt(element0, [134]);
        var element65 = dom.childAt(element0, [136]);
        var element66 = dom.childAt(element0, [140, 1, 1, 1]);
        var element67 = dom.childAt(element66, [0, 1]);
        var element68 = dom.childAt(element66, [4]);
        var element69 = dom.childAt(element0, [146]);
        var element70 = dom.childAt(element0, [148]);
        var element71 = dom.childAt(element0, [150]);
        var element72 = dom.childAt(element0, [152]);
        var element73 = dom.childAt(element0, [154]);
        var element74 = dom.childAt(element0, [156]);
        var element75 = dom.childAt(element0, [158]);
        var element76 = dom.childAt(element0, [160]);
        var element77 = dom.childAt(element0, [162]);
        var element78 = dom.childAt(element0, [166, 1, 1, 1]);
        var element79 = dom.childAt(element78, [0, 1]);
        var element80 = dom.childAt(element78, [2, 1]);
        var element81 = dom.childAt(element78, [8]);
        var element82 = dom.childAt(element0, [170, 1, 1, 1]);
        var element83 = dom.childAt(element82, [6]);
        var morphs = new Array(175);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [0, 1]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
        morphs[4] = dom.createMorphAt(element3,1,1);
        morphs[5] = dom.createMorphAt(element3,3,3);
        morphs[6] = dom.createMorphAt(element3,5,5);
        morphs[7] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element4, [5]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element7, [5]),1,1);
        morphs[15] = dom.createUnsafeMorphAt(dom.childAt(element0, [18, 1]),1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element0, [20, 1]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element8, [1]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element8, [5]),0,0);
        morphs[19] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element9, [5]),0,0);
        morphs[21] = dom.createMorphAt(dom.childAt(element10, [1]),1,1);
        morphs[22] = dom.createMorphAt(element11,1,1);
        morphs[23] = dom.createMorphAt(element11,3,3);
        morphs[24] = dom.createMorphAt(element11,5,5);
        morphs[25] = dom.createMorphAt(element12,1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element12, [5]),1,1);
        morphs[27] = dom.createMorphAt(dom.childAt(element13, [1]),1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element13, [5]),1,1);
        morphs[29] = dom.createMorphAt(dom.childAt(element14, [1]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element14, [5]),0,0);
        morphs[31] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
        morphs[32] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
        morphs[33] = dom.createMorphAt(dom.childAt(element16, [1]),1,1);
        morphs[34] = dom.createMorphAt(dom.childAt(element16, [5, 0]),1,1);
        morphs[35] = dom.createMorphAt(dom.childAt(element17, [1]),1,1);
        morphs[36] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
        morphs[37] = dom.createMorphAt(dom.childAt(element17, [5]),0,0);
        morphs[38] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
        morphs[39] = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
        morphs[40] = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
        morphs[41] = dom.createMorphAt(dom.childAt(element19, [5]),0,0);
        morphs[42] = dom.createMorphAt(dom.childAt(element20, [3]),0,0);
        morphs[43] = dom.createMorphAt(dom.childAt(element20, [5]),0,0);
        morphs[44] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
        morphs[45] = dom.createMorphAt(dom.childAt(element21, [3]),0,0);
        morphs[46] = dom.createMorphAt(dom.childAt(element22, [1]),0,0);
        morphs[47] = dom.createMorphAt(dom.childAt(element22, [3]),0,0);
        morphs[48] = dom.createMorphAt(dom.childAt(element23, [1]),1,1);
        morphs[49] = dom.createMorphAt(dom.childAt(element23, [3]),0,0);
        morphs[50] = dom.createMorphAt(dom.childAt(element24, [1]),1,1);
        morphs[51] = dom.createMorphAt(dom.childAt(element24, [3]),0,0);
        morphs[52] = dom.createMorphAt(dom.childAt(element25, [1]),1,1);
        morphs[53] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
        morphs[54] = dom.createMorphAt(element26,1,1);
        morphs[55] = dom.createMorphAt(dom.childAt(element26, [5]),1,1);
        morphs[56] = dom.createMorphAt(dom.childAt(element27, [1]),1,1);
        morphs[57] = dom.createMorphAt(dom.childAt(element27, [5]),0,0);
        morphs[58] = dom.createMorphAt(dom.childAt(element28, [1]),1,1);
        morphs[59] = dom.createMorphAt(dom.childAt(element28, [5]),0,0);
        morphs[60] = dom.createMorphAt(dom.childAt(element29, [1]),1,1);
        morphs[61] = dom.createMorphAt(element30,1,1);
        morphs[62] = dom.createMorphAt(element30,3,3);
        morphs[63] = dom.createMorphAt(element30,5,5);
        morphs[64] = dom.createMorphAt(dom.childAt(element31, [1]),1,1);
        morphs[65] = dom.createMorphAt(dom.childAt(element31, [5]),0,0);
        morphs[66] = dom.createMorphAt(dom.childAt(element0, [68, 1]),1,1);
        morphs[67] = dom.createMorphAt(dom.childAt(element32, [1]),1,1);
        morphs[68] = dom.createMorphAt(dom.childAt(element32, [3, 1]),1,1);
        morphs[69] = dom.createMorphAt(dom.childAt(element33, [1]),1,1);
        morphs[70] = dom.createMorphAt(dom.childAt(element33, [3]),0,0);
        morphs[71] = dom.createMorphAt(dom.childAt(element34, [1]),1,1);
        morphs[72] = dom.createMorphAt(dom.childAt(element34, [3]),0,0);
        morphs[73] = dom.createMorphAt(dom.childAt(element35, [1]),1,1);
        morphs[74] = dom.createMorphAt(dom.childAt(element35, [5]),0,0);
        morphs[75] = dom.createMorphAt(dom.childAt(element36, [1]),1,1);
        morphs[76] = dom.createMorphAt(dom.childAt(element36, [3]),1,1);
        morphs[77] = dom.createMorphAt(dom.childAt(element37, [1]),1,1);
        morphs[78] = dom.createMorphAt(dom.childAt(element37, [3]),0,0);
        morphs[79] = dom.createMorphAt(dom.childAt(element38, [1]),1,1);
        morphs[80] = dom.createMorphAt(dom.childAt(element38, [5]),1,1);
        morphs[81] = dom.createMorphAt(dom.childAt(element39, [1]),1,1);
        morphs[82] = dom.createMorphAt(dom.childAt(element39, [3]),1,1);
        morphs[83] = dom.createMorphAt(dom.childAt(element40, [1]),1,1);
        morphs[84] = dom.createMorphAt(dom.childAt(element40, [3]),0,0);
        morphs[85] = dom.createMorphAt(dom.childAt(element41, [3]),1,1);
        morphs[86] = dom.createMorphAt(dom.childAt(element41, [5]),0,0);
        morphs[87] = dom.createMorphAt(dom.childAt(element42, [1]),1,1);
        morphs[88] = dom.createMorphAt(dom.childAt(element42, [3]),1,1);
        morphs[89] = dom.createMorphAt(dom.childAt(element43, [1]),1,1);
        morphs[90] = dom.createMorphAt(dom.childAt(element43, [3]),1,1);
        morphs[91] = dom.createMorphAt(dom.childAt(element44, [1]),1,1);
        morphs[92] = dom.createMorphAt(dom.childAt(element44, [3]),1,1);
        morphs[93] = dom.createMorphAt(dom.childAt(element45, [1]),1,1);
        morphs[94] = dom.createMorphAt(dom.childAt(element45, [3]),1,1);
        morphs[95] = dom.createMorphAt(dom.childAt(element46, [1]),1,1);
        morphs[96] = dom.createMorphAt(dom.childAt(element46, [3]),1,1);
        morphs[97] = dom.createMorphAt(element47,1,1);
        morphs[98] = dom.createMorphAt(dom.childAt(element47, [7]),1,1);
        morphs[99] = dom.createMorphAt(dom.childAt(element48, [1]),1,1);
        morphs[100] = dom.createMorphAt(dom.childAt(element48, [3]),0,0);
        morphs[101] = dom.createMorphAt(dom.childAt(element49, [1]),1,1);
        morphs[102] = dom.createMorphAt(dom.childAt(element49, [3]),0,0);
        morphs[103] = dom.createMorphAt(dom.childAt(element50, [1]),1,1);
        morphs[104] = dom.createMorphAt(element51,1,1);
        morphs[105] = dom.createMorphAt(element51,3,3);
        morphs[106] = dom.createMorphAt(element51,5,5);
        morphs[107] = dom.createMorphAt(dom.childAt(element0, [108, 1]),1,1);
        morphs[108] = dom.createMorphAt(dom.childAt(element52, [1]),1,1);
        morphs[109] = dom.createMorphAt(dom.childAt(element52, [3]),0,0);
        morphs[110] = dom.createMorphAt(dom.childAt(element53, [1]),1,1);
        morphs[111] = dom.createMorphAt(dom.childAt(element53, [3]),0,0);
        morphs[112] = dom.createMorphAt(dom.childAt(element54, [1]),1,1);
        morphs[113] = dom.createMorphAt(dom.childAt(element54, [3]),1,1);
        morphs[114] = dom.createMorphAt(dom.childAt(element55, [1]),1,1);
        morphs[115] = dom.createMorphAt(dom.childAt(element55, [3]),1,1);
        morphs[116] = dom.createMorphAt(dom.childAt(element56, [1]),1,1);
        morphs[117] = dom.createMorphAt(dom.childAt(element56, [3]),1,1);
        morphs[118] = dom.createMorphAt(dom.childAt(element57, [1]),1,1);
        morphs[119] = dom.createMorphAt(dom.childAt(element57, [3]),0,0);
        morphs[120] = dom.createMorphAt(dom.childAt(element58, [1]),1,1);
        morphs[121] = dom.createMorphAt(dom.childAt(element58, [3]),1,1);
        morphs[122] = dom.createMorphAt(dom.childAt(element59, [1]),1,1);
        morphs[123] = dom.createMorphAt(dom.childAt(element59, [3]),1,1);
        morphs[124] = dom.createMorphAt(dom.childAt(element60, [1]),1,1);
        morphs[125] = dom.createMorphAt(dom.childAt(element60, [3]),1,1);
        morphs[126] = dom.createMorphAt(dom.childAt(element61, [1]),1,1);
        morphs[127] = dom.createMorphAt(dom.childAt(element61, [3]),1,1);
        morphs[128] = dom.createMorphAt(dom.childAt(element62, [1]),1,1);
        morphs[129] = dom.createMorphAt(dom.childAt(element62, [3]),1,1);
        morphs[130] = dom.createMorphAt(dom.childAt(element63, [1]),1,1);
        morphs[131] = dom.createMorphAt(dom.childAt(element63, [3]),1,1);
        morphs[132] = dom.createMorphAt(dom.childAt(element64, [1]),1,1);
        morphs[133] = dom.createMorphAt(dom.childAt(element64, [3]),1,1);
        morphs[134] = dom.createMorphAt(dom.childAt(element65, [1]),1,1);
        morphs[135] = dom.createMorphAt(dom.childAt(element65, [3]),0,0);
        morphs[136] = dom.createMorphAt(element67,1,1);
        morphs[137] = dom.createMorphAt(dom.childAt(element67, [3, 1]),1,1);
        morphs[138] = dom.createMorphAt(dom.childAt(element66, [2, 1]),0,0);
        morphs[139] = dom.createMorphAt(dom.childAt(element68, [1]),0,0);
        morphs[140] = dom.createMorphAt(dom.childAt(element68, [5]),0,0);
        morphs[141] = dom.createMorphAt(dom.childAt(element68, [9]),0,0);
        morphs[142] = dom.createMorphAt(dom.childAt(element0, [144, 1]),1,1);
        morphs[143] = dom.createMorphAt(dom.childAt(element69, [1]),1,1);
        morphs[144] = dom.createMorphAt(dom.childAt(element69, [3]),0,0);
        morphs[145] = dom.createMorphAt(dom.childAt(element70, [1]),1,1);
        morphs[146] = dom.createMorphAt(dom.childAt(element70, [3]),0,0);
        morphs[147] = dom.createMorphAt(dom.childAt(element71, [1]),1,1);
        morphs[148] = dom.createMorphAt(dom.childAt(element71, [3]),0,0);
        morphs[149] = dom.createMorphAt(dom.childAt(element72, [1]),1,1);
        morphs[150] = dom.createMorphAt(dom.childAt(element72, [3]),0,0);
        morphs[151] = dom.createMorphAt(dom.childAt(element73, [1]),1,1);
        morphs[152] = dom.createMorphAt(dom.childAt(element73, [3]),0,0);
        morphs[153] = dom.createMorphAt(dom.childAt(element74, [1]),1,1);
        morphs[154] = dom.createMorphAt(dom.childAt(element74, [3]),0,0);
        morphs[155] = dom.createMorphAt(dom.childAt(element75, [1]),1,1);
        morphs[156] = dom.createMorphAt(dom.childAt(element75, [3]),0,0);
        morphs[157] = dom.createMorphAt(dom.childAt(element76, [1]),1,1);
        morphs[158] = dom.createMorphAt(dom.childAt(element76, [3]),0,0);
        morphs[159] = dom.createMorphAt(dom.childAt(element77, [1]),1,1);
        morphs[160] = dom.createMorphAt(dom.childAt(element77, [3]),0,0);
        morphs[161] = dom.createMorphAt(element79,1,1);
        morphs[162] = dom.createMorphAt(dom.childAt(element79, [3, 1]),1,1);
        morphs[163] = dom.createMorphAt(element80,1,1);
        morphs[164] = dom.createMorphAt(dom.childAt(element80, [3]),1,1);
        morphs[165] = dom.createMorphAt(dom.childAt(element78, [4, 1]),0,0);
        morphs[166] = dom.createMorphAt(dom.childAt(element78, [6, 5]),0,0);
        morphs[167] = dom.createMorphAt(dom.childAt(element81, [1]),0,0);
        morphs[168] = dom.createMorphAt(dom.childAt(element81, [9]),0,0);
        morphs[169] = dom.createMorphAt(dom.childAt(element82, [0, 1]),0,0);
        morphs[170] = dom.createMorphAt(dom.childAt(element82, [2, 1]),0,0);
        morphs[171] = dom.createMorphAt(dom.childAt(element82, [4, 1]),0,0);
        morphs[172] = dom.createMorphAt(dom.childAt(element83, [1]),0,0);
        morphs[173] = dom.createMorphAt(dom.childAt(element83, [5]),0,0);
        morphs[174] = dom.createMorphAt(dom.childAt(element83, [9]),0,0);
        return morphs;
      },
      statements: [
        ["inline","t",["obrObrazac03.povredniList.podaciOPoslodavcu"],[],["loc",[null,[14,52],[14,103]]]],
        ["inline","t",["obrObrazac03.povredniList.obveznikPodnosenjaPoslodavac"],[],["loc",[null,[25,37],[25,99]]]],
        ["inline","t",["obrObrazac03.povredniList.punNazivPoslodavca"],[],["loc",[null,[26,35],[26,87]]]],
        ["inline","t",["obrObrazac03.povredniList.sedisteAdresaPoslodavca"],[],["loc",[null,[31,26],[31,83]]]],
        ["content","pravnoLice.adresa",["loc",[null,[32,83],[32,104]]]],
        ["content","pravnoLice.mesto",["loc",[null,[32,106],[32,126]]]],
        ["content","pravnoLice.drzava",["loc",[null,[32,128],[32,149]]]],
        ["inline","t",["obrObrazac03.povredniList.regBrojObveznika"],[],["loc",[null,[36,26],[36,76]]]],
        ["content","pravnoLice.maticniBroj",["loc",[null,[37,83],[37,109]]]],
        ["inline","t",["obrObrazac03.povredniList.pib"],[],["loc",[null,[41,26],[41,63]]]],
        ["content","pravnoLice.pib",["loc",[null,[42,83],[42,101]]]],
        ["inline","t",["obrObrazac03.povredniList.sifraDelatnosti"],[],["loc",[null,[46,26],[46,75]]]],
        ["content","pravnoLice.sifraDelatnosti",["loc",[null,[47,83],[47,113]]]],
        ["inline","t",["obrObrazac03.povredniList.ukupnoZaposlenih"],[],["loc",[null,[51,23],[51,73]]]],
        ["content","brojZaposlenih",["loc",[null,[52,80],[52,98]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciOBZRLicuPoslodavac"],[],["loc",[null,[57,10],[57,70]]]],
        ["inline","select-items-labela",[],["nullLabel","app.common.emptyString","saveFirstItemValue",true,"firstItemValue",["subexpr","@mut",[["get","firstIdLiceZaBzr",["loc",[null,[62,110],[62,126]]]]],[],[]],"nullChoice",false,"value",["subexpr","@mut",[["get","idLiceZaBzr",["loc",[null,[62,150],[62,161]]]]],[],[]],"domainName","SifLica","servicePath","selectItemsBzr","styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[62,14],[62,267]]]],
        ["inline","t",["obrObrazac03.povredniList.imePrezime"],[],["loc",[null,[66,34],[66,78]]]],
        ["content","liceZaBzr.ime",["loc",[null,[68,32],[68,49]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[72,34],[72,72]]]],
        ["content","liceZaBzr.maticniBroj",["loc",[null,[74,32],[74,57]]]],
        ["inline","t",["obrObrazac03.povredniList.adresaPrebivalista"],[],["loc",[null,[78,34],[78,86]]]],
        ["content","liceZaBzr.adresa",["loc",[null,[81,16],[81,36]]]],
        ["content","liceZaBzr.mesto",["loc",[null,[82,16],[82,35]]]],
        ["content","liceZaBzr.drzava",["loc",[null,[83,16],[83,36]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciOPovredjenom"],[],["loc",[null,[90,18],[90,70]]]],
        ["inline","select-datatable",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[92,47],[92,69]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[93,35],[93,52]]]]],[],[]],"obj",["subexpr","@mut",[["get","objPovredjeni",["loc",[null,[94,24],[94,37]]]]],[],[]],"objToSet",["subexpr","@mut",[["get","controllerObj",["loc",[null,[95,29],[95,42]]]]],[],[]],"objNameToSet","objPovredjeni","placeholderLabel","obrazac2.izvestaji.odaberiteZaposlenog"],["loc",[null,[92,20],[98,20]]]],
        ["inline","t",["obrObrazac03.povredniList.imePrezime"],[],["loc",[null,[106,26],[106,70]]]],
        ["content","objPovredjeni.naziv",["loc",[null,[109,10],[109,33]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[114,26],[114,64]]]],
        ["content","objPovredjeni.selectedRow.maticniBroj",["loc",[null,[116,35],[116,76]]]],
        ["inline","t",["obrObrazac03.povredniList.polMZ"],[],["loc",[null,[120,26],[120,65]]]],
        ["content","objPovredjeni.selectedRow.pol.naziv",["loc",[null,[123,15],[123,54]]]],
        ["inline","t",["obrObrazac03.povredniList.danMesecGodRodjenja"],[],["loc",[null,[127,26],[127,79]]]],
        ["inline","pikaday-input",[],["useUTC",true,"disabled",true,"value",["subexpr","@mut",[["get","objPovredjeni.selectedRow.datumRodjenja",["loc",[null,[130,58],[130,97]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input inputClearStyle"],["loc",[null,[130,10],[130,158]]]],
        ["inline","t",["obrObrazac03.povredniList.prebivaliste"],[],["loc",[null,[134,40],[134,86]]]],
        ["inline","t",["obrObrazac03.povredniList.ulicaIBroj"],[],["loc",[null,[135,23],[135,67]]]],
        ["content","objPovredjeni.selectedRow.adresa",["loc",[null,[136,23],[136,59]]]],
        ["inline","t",["obrObrazac03.povredniList.mestoOpstina"],[],["loc",[null,[139,23],[139,69]]]],
        ["content","objPovredjeni.selectedRow.mesto",["loc",[null,[140,23],[140,58]]]],
        ["inline","t",["obrObrazac03.povredniList.drzava"],[],["loc",[null,[144,23],[144,63]]]],
        ["content","objPovredjeni.selectedRow.drzava",["loc",[null,[145,23],[145,59]]]],
        ["inline","t",["obrObrazac03.povredniList.ulicaIBroj"],[],["loc",[null,[149,23],[149,67]]]],
        ["content","modelObject.rmAdresa",["loc",[null,[150,23],[150,47]]]],
        ["inline","t",["obrObrazac03.povredniList.mestoOpstina"],[],["loc",[null,[153,23],[153,69]]]],
        ["content","modelObject.rmMesto",["loc",[null,[154,23],[154,46]]]],
        ["inline","t",["obrObrazac03.povredniList.drzava"],[],["loc",[null,[157,23],[157,63]]]],
        ["content","modelObject.rmDrzava",["loc",[null,[158,23],[158,47]]]],
        ["inline","t",["obrObrazac03.povredniList.vrstaStepenSS"],[],["loc",[null,[161,38],[161,85]]]],
        ["inline","select-items-labela",[],["nullLabel","app.common.emptyString","nullChoice",true,"domainName","SifVrstaStepenSs","value",["subexpr","@mut",[["get","modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs",["loc",[null,[162,132],[162,193]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[162,23],[162,249]]]],
        ["inline","t",["obrObrazac03.povredniList.vrstaStepenPropisaneSS"],[],["loc",[null,[165,38],[165,94]]]],
        ["inline","select-items-labela",[],["nullLabel","app.common.emptyString","nullChoice",true,"domainName","SifVrstaStepenSs","value",["subexpr","@mut",[["get","modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs",["loc",[null,[166,132],[166,202]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[166,23],[166,258]]]],
        ["inline","t",["obrObrazac03.povredniList.statusPovredjenog"],[],["loc",[null,[169,65],[169,116]]]],
        ["inline","select-items-labela",[],["nullLabel","app.common.emptyString","nullChoice",true,"domainName","SifStatusZaposlenog","value",["subexpr","@mut",[["get","modelObject.sifStatusZaposlenog.idStatusZaposlenog",["loc",[null,[170,138],[170,188]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[170,26],[170,244]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciONeposrednomRukovodiocu"],[],["loc",[null,[173,60],[173,123]]]],
        ["inline","select-datatable",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[175,51],[175,73]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[176,39],[176,56]]]]],[],[]],"obj",["subexpr","@mut",[["get","objRukovodioc",["loc",[null,[177,28],[177,41]]]]],[],[]],"objToSet",["subexpr","@mut",[["get","controllerObj",["loc",[null,[178,33],[178,46]]]]],[],[]],"objNameToSet","objRukovodioc","placeholderLabel","obrazac2.izvestaji.odaberiteZaposlenog"],["loc",[null,[175,24],[181,26]]]],
        ["inline","t",["obrObrazac03.povredniList.imePrezime"],[],["loc",[null,[186,26],[186,70]]]],
        ["content","objRukovodioc.naziv",["loc",[null,[188,32],[188,55]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[191,26],[191,64]]]],
        ["content","objRukovodioc.selectedRow.maticniBroj",["loc",[null,[193,32],[193,73]]]],
        ["inline","t",["obrObrazac03.povredniList.adresaPrebivalista"],[],["loc",[null,[196,26],[196,78]]]],
        ["content","objRukovodioc.selectedRow.adresa",["loc",[null,[199,15],[199,51]]]],
        ["content","objRukovodioc.selectedRow.mesto",["loc",[null,[200,16],[200,51]]]],
        ["content","objRukovodioc.selectedRow.drzava",["loc",[null,[201,16],[201,52]]]],
        ["inline","t",["obrObrazac03.povredniList.posloviKojeObavlja"],[],["loc",[null,[205,26],[205,78]]]],
        ["content","rukovodiocRadnaMesta",["loc",[null,[207,32],[207,56]]]],
        ["inline","t",["obrObrazac03.povredniList.radnoMestoNastupanjePovrede"],[],["loc",[null,[210,103],[210,164]]]],
        ["inline","t",["obrObrazac03.povredniList.radnoMestoPovredjeniRasporedjen"],[],["loc",[null,[214,38],[214,103]]]],
        ["block","each",[["get","povredjeniRadnaMesta",["loc",[null,[218,26],[218,46]]]]],[],0,null,["loc",[null,[218,18],[222,27]]]],
        ["inline","t",["obrObrazac03.povredniList.labela24"],[],["loc",[null,[227,38],[227,80]]]],
        ["content","modelObject.posaoKojiJeObavljao",["loc",[null,[228,32],[228,67]]]],
        ["inline","t",["obrObrazac03.povredniList.labela25"],[],["loc",[null,[232,38],[232,80]]]],
        ["content","modelObject.radnoIskustvo",["loc",[null,[233,32],[233,61]]]],
        ["inline","t",["obrObrazac03.povredniList.labela26"],[],["loc",[null,[236,38],[236,80]]]],
        ["content","modelObject.ukupanStaz",["loc",[null,[238,32],[238,58]]]],
        ["inline","t",["obrObrazac03.povredniList.labela27"],[],["loc",[null,[241,38],[241,80]]]],
        ["content","modelObject.radnoVremeProsek",["loc",[null,[242,33],[242,65]]]],
        ["inline","t",["obrObrazac03.povredniList.labela28"],[],["loc",[null,[246,38],[246,80]]]],
        ["content","modelObject.ranijePovrede",["loc",[null,[247,32],[247,61]]]],
        ["inline","t",["obrObrazac03.povredniList.labela29"],[],["loc",[null,[252,40],[252,82]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.vremePovrede",["loc",[null,[255,49],[255,73]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input inputClearStyle"],["loc",[null,[255,15],[255,133]]]],
        ["inline","t",["obrObrazac03.povredniList.labela30"],[],["loc",[null,[259,26],[259,68]]]],
        ["content","formatedDatumPovrede",["loc",[null,[261,15],[261,39]]]],
        ["inline","t",["obrObrazac03.povredniList.labela31"],[],["loc",[null,[265,26],[265,68]]]],
        ["content","modelObject.dobaDanaSat",["loc",[null,[266,23],[266,50]]]],
        ["inline","t",["obrObrazac03.povredniList.labela32"],[],["loc",[null,[271,26],[271,68]]]],
        ["content","modelObject.radniSatOdPocetkaRada",["loc",[null,[272,23],[272,60]]]],
        ["inline","t",["obrObrazac03.povredniList.labela33"],[],["loc",[null,[276,38],[276,80]]]],
        ["content","modelObject.mestoPovredeSaAdresom",["loc",[null,[278,16],[278,53]]]],
        ["inline","t",["obrObrazac03.povredniList.opstina"],[],["loc",[null,[282,38],[282,79]]]],
        ["content","modelObject.mestoPovredeOpstina",["loc",[null,[284,16],[284,51]]]],
        ["inline","t",["obrObrazac03.povredniList.drzava"],[],["loc",[null,[288,38],[288,78]]]],
        ["content","modelObject.mestoPovredeDrzava",["loc",[null,[290,16],[290,50]]]],
        ["inline","t",["obrObrazac03.povredniList.labela33"],[],["loc",[null,[296,42],[296,84]]]],
        ["inline","select-items-labela",[],["nullLabel","app.common.emptyString","nullChoice",true,"domainName","SifMestoPovrede","value",["subexpr","@mut",[["get","modelObject.sifMestoPovrede.idMestoPovrede",["loc",[null,[298,124],[298,166]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[298,16],[298,222]]]],
        ["inline","t",["obrObrazac03.povredniList.labela41"],[],["loc",[null,[302,38],[302,80]]]],
        ["content","modelObject.gdeSePovredioOstalo",["loc",[null,[304,15],[304,50]]]],
        ["inline","t",["obrObrazac03.povredniList.podaciOOcevicu"],[],["loc",[null,[309,60],[309,108]]]],
        ["inline","select-datatable",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[312,47],[312,69]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[313,35],[313,52]]]]],[],[]],"obj",["subexpr","@mut",[["get","objOcevidac",["loc",[null,[314,24],[314,35]]]]],[],[]],"objToSet",["subexpr","@mut",[["get","controllerObj",["loc",[null,[315,29],[315,42]]]]],[],[]],"objNameToSet","objOcevidac","placeholderLabel","obrazac2.izvestaji.odaberiteZaposlenog"],["loc",[null,[312,20],[318,20]]]],
        ["inline","t",["obrObrazac03.povredniList.imeIPrezime"],[],["loc",[null,[324,38],[324,83]]]],
        ["content","objOcevidac.naziv",["loc",[null,[325,32],[325,53]]]],
        ["inline","t",["obrObrazac03.povredniList.jmbg"],[],["loc",[null,[328,38],[328,76]]]],
        ["content","objOcevidac.selectedRow.maticniBroj",["loc",[null,[329,32],[329,71]]]],
        ["inline","t",["obrObrazac03.povredniList.adresaPrebivaliste"],[],["loc",[null,[332,38],[332,90]]]],
        ["content","objOcevidac.selectedRow.adresa",["loc",[null,[334,10],[334,44]]]],
        ["content","objOcevidac.selectedRow.mesto",["loc",[null,[335,10],[335,43]]]],
        ["content","objOcevidac.selectedRow.drzava",["loc",[null,[336,10],[336,44]]]],
        ["inline","t",["obrObrazac03.povredniList.labelaNaslov7"],[],["loc",[null,[341,43],[341,90]]]],
        ["inline","t",["obrObrazac03.povredniList.labela45"],[],["loc",[null,[344,38],[344,80]]]],
        ["content","modelObject.ukupanBrojPovredjenih",["loc",[null,[345,32],[345,69]]]],
        ["inline","t",["obrObrazac03.povredniList.labela46"],[],["loc",[null,[348,38],[348,80]]]],
        ["content","modelObject.ukupanBrojPoginulih",["loc",[null,[349,32],[349,67]]]],
        ["inline","t",["obrObrazac03.povredniList.labela47"],[],["loc",[null,[352,38],[352,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgRanijaSlicnaPovreda",["loc",[null,[355,37],[355,70]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[355,16],[355,126]]]],
        ["inline","t",["obrObrazac03.povredniList.labela48"],[],["loc",[null,[359,38],[359,80]]]],
        ["inline","select-items-labela",[],["domainName","SifIzvorPovrede","value",["subexpr","@mut",[["get","modelObject.sifIzvorPovrede.idIzvorPovrede",["loc",[null,[361,76],[361,118]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[361,19],[361,174]]]],
        ["inline","t",["obrObrazac03.povredniList.labela49"],[],["loc",[null,[365,38],[365,80]]]],
        ["inline","select-items-labela",[],["domainName","SifUzrokPovrede","value",["subexpr","@mut",[["get","modelObject.sifUzrokPovrede.idUzrokPovrede",["loc",[null,[367,73],[367,115]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[367,16],[367,171]]]],
        ["inline","t",["obrObrazac03.povredniList.labela50"],[],["loc",[null,[371,38],[371,80]]]],
        ["content","modelObject.kratakOpisNastankaPovrede",["loc",[null,[372,32],[372,73]]]],
        ["inline","t",["obrObrazac03.povredniList.labela51"],[],["loc",[null,[376,38],[376,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgPovredaNaRmSaRizikom",["loc",[null,[378,36],[378,70]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[378,15],[378,126]]]],
        ["inline","t",["obrObrazac03.povredniList.labela52"],[],["loc",[null,[382,38],[382,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgPropisaniUslovi",["loc",[null,[384,37],[384,66]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[384,16],[384,122]]]],
        ["inline","t",["obrObrazac03.povredniList.labela53"],[],["loc",[null,[388,38],[388,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgRanijiPregledi",["loc",[null,[390,37],[390,65]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[390,16],[390,121]]]],
        ["inline","t",["obrObrazac03.povredniList.labela54"],[],["loc",[null,[394,38],[394,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgOsposobljenZaBezbRad",["loc",[null,[396,37],[396,71]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[396,16],[396,127]]]],
        ["inline","t",["obrObrazac03.povredniList.labela55"],[],["loc",[null,[400,38],[400,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgPropisanaSredstvaZaRad",["loc",[null,[402,34],[402,70]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[402,13],[402,126]]]],
        ["inline","t",["obrObrazac03.povredniList.labela56"],[],["loc",[null,[406,38],[406,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgObezbedjanaSredstvaZaRad",["loc",[null,[408,33],[408,71]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[408,12],[408,127]]]],
        ["inline","t",["obrObrazac03.povredniList.labela57"],[],["loc",[null,[412,38],[412,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgKoriscenaSredstvaZaRad",["loc",[null,[414,33],[414,69]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[414,12],[414,125]]]],
        ["inline","t",["obrObrazac03.povredniList.labela58"],[],["loc",[null,[418,38],[418,80]]]],
        ["content","modelObject.mereBezbednostiPreduzete",["loc",[null,[419,32],[419,72]]]],
        ["inline","t",["obrObrazac03.povredniList.datum"],[],["loc",[null,[430,27],[430,66]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datum1",["loc",[null,[432,64],[432,82]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input inputClearStyle"],["loc",[null,[432,30],[432,142]]]],
        ["inline","t",["obrObrazac03.povredniList.mesto"],[],["loc",[null,[442,28],[442,67]]]],
        ["inline","t",["obrObrazac03.povredniList.redBrojIzEvidencije"],[],["loc",[null,[449,28],[449,81]]]],
        ["inline","t",["obrObrazac03.povredniList.MP"],[],["loc",[null,[451,28],[451,64]]]],
        ["inline","t",["obrObrazac03.povredniList.potpisOdgovornogLica"],[],["loc",[null,[453,56],[453,110]]]],
        ["inline","t",["obrObrazac03.povredniList.labelaNaslov8"],[],["loc",[null,[468,44],[468,91]]]],
        ["inline","t",["obrObrazac03.povredniList.labela59"],[],["loc",[null,[471,39],[471,81]]]],
        ["content","modelObject.ustanova",["loc",[null,[472,32],[472,56]]]],
        ["inline","t",["obrObrazac03.povredniList.labela60"],[],["loc",[null,[475,39],[475,81]]]],
        ["content","modelObject.imeLekara",["loc",[null,[476,32],[476,57]]]],
        ["inline","t",["obrObrazac03.povredniList.labela61"],[],["loc",[null,[479,38],[479,80]]]],
        ["content","modelObject.dijagnoza",["loc",[null,[480,32],[480,57]]]],
        ["inline","t",["obrObrazac03.povredniList.labela62"],[],["loc",[null,[483,38],[483,80]]]],
        ["content","modelObject.spoljniUzrokPovrede",["loc",[null,[484,32],[484,67]]]],
        ["inline","t",["obrObrazac03.povredniList.labela63"],[],["loc",[null,[488,38],[488,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgSmrtniIshod",["loc",[null,[489,53],[489,78]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[489,32],[489,134]]]],
        ["inline","t",["obrObrazac03.povredniList.labela64"],[],["loc",[null,[493,38],[493,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgTeskaPovreda",["loc",[null,[494,53],[494,79]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[494,32],[494,135]]]],
        ["inline","t",["obrObrazac03.povredniList.labela65"],[],["loc",[null,[498,38],[498,80]]]],
        ["content","modelObject.prethodnePovredeOboljenja",["loc",[null,[499,32],[499,73]]]],
        ["inline","t",["obrObrazac03.povredniList.labela66"],[],["loc",[null,[502,38],[502,80]]]],
        ["content","modelObject.drugePrimedbe",["loc",[null,[503,32],[503,61]]]],
        ["inline","t",["obrObrazac03.povredniList.labela67"],[],["loc",[null,[506,38],[506,80]]]],
        ["inline","da-ne-labela",[],["value",["subexpr","@mut",[["get","modelObject.fgSprecenDaRadiTriDana",["loc",[null,[507,53],[507,87]]]]],[],[]],"styleClass","input-control-compact dropdownClearStyle"],["loc",[null,[507,32],[507,143]]]],
        ["inline","t",["obrObrazac03.povredniList.datum"],[],["loc",[null,[517,27],[517,66]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datum1",["loc",[null,[519,66],[519,84]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input inputClearStyle"],["loc",[null,[519,32],[519,144]]]],
        ["inline","t",["obrObrazac03.povredniList.mesto"],[],["loc",[null,[530,27],[530,66]]]],
        ["content","modelObject.mesto1",["loc",[null,[531,28],[531,50]]]],
        ["inline","t",["obrObrazac03.povredniList.redniBrojLekarskogDnevnika"],[],["loc",[null,[540,28],[540,88]]]],
        ["inline","t",["obrObrazac03.povredniList.MP"],[],["loc",[null,[549,43],[549,79]]]],
        ["inline","t",["obrObrazac03.povredniList.redniBrojUKnjiziEvidencije"],[],["loc",[null,[554,28],[554,88]]]],
        ["inline","t",["obrObrazac03.povredniList.potpisLekara"],[],["loc",[null,[558,56],[558,102]]]],
        ["inline","t",["obrObrazac03.povredniList.orgZdravstvenogOsiguranja"],[],["loc",[null,[578,86],[578,145]]]],
        ["inline","t",["obrObrazac03.povredniList.datum"],[],["loc",[null,[581,40],[581,79]]]],
        ["inline","t",["obrObrazac03.povredniList.mesto"],[],["loc",[null,[588,28],[588,67]]]],
        ["inline","t",["obrObrazac03.povredniList.redBrIzDnevnika"],[],["loc",[null,[595,28],[595,77]]]],
        ["inline","t",["obrObrazac03.povredniList.MP"],[],["loc",[null,[597,43],[597,79]]]],
        ["inline","t",["obrObrazac03.povredniList.potpisLekara"],[],["loc",[null,[599,56],[599,102]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});