define('zaris-frontend/components/sif-elementi-pregleda-lov-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'sifElementiPregledaLovModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "70%",
    "data-width": "70%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentElPregleda: Em.A(),
    modelObjectElPregleda: {},
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.sifElementiPregledaLovNewModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      initUpdate: function initUpdate() {
        console.log('init update');
      },

      initDelete: function initDelete() {
        console.log('init delete');
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshElPregleda');

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.sifElementiPregledaLovModal');
    }),

    closePanel: function closePanel() {
      $('.sifElementiPregledaLovModal').data('dialog').close();
    },

    tableColumnsElPregleda: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'sifElementPregleda',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifElementiPregleda.sifra'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).sifra;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'sifElementPregleda',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifElementiPregleda.elementPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).naziv;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'intervalMeseci',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifElementiPregleda.interval'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});