define('zaris-frontend/templates/obr-obrazac03-sacuvani', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 96,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac03-sacuvani.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","tabcontrol zarisPanel");
        dom.setAttribute(el1,"data-role","tabcontrol");
        dom.setAttribute(el1,"data-on-tab-change","tab_change");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"class","tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","active");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#frame_1");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","heading");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","icon mif-cabinet");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#frame_2");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","heading");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","icon mif-cabinet");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","frames");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","frame");
        dom.setAttribute(el3,"id","frame_1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el5,"class","content panelCentered");
        var el6 = dom.createTextNode("\n\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","frame");
        dom.setAttribute(el3,"id","frame_2");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el5,"class","content panelCentered");
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element1, [3, 1]);
        var element4 = dom.childAt(element0, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 3]),0,0);
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [1, 3]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [1, 1, 1]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [3, 1, 1]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["openTab1"],[],["loc",[null,[6,13],[6,34]]]],
        ["inline","t",["app.menu.evidencija_o_povredama_na_radu"],[],["loc",[null,[9,36],[9,83]]]],
        ["element","action",["openTab2"],[],["loc",[null,[14,13],[14,34]]]],
        ["inline","t",["app.menu.povredni_listovi"],[],["loc",[null,[17,36],[17,69]]]],
        ["inline","component",[["get","componentTab1",["loc",[null,[29,26],[29,39]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[30,23],[30,27]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[31,24],[31,36]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","ObrObrazac03Sacuvan","domainId","idObrazac03Sacuvan","titleMsgKey","obrazacSacuvani.brisanjeHeader","descYesNoMsgKey","obrazacSacuvani.brisanjePitanje","descDeleteSuccessMsgKey","obrazacSacuvani.brisanjeUspesno","titleDeleteMsgKey","obrazacSacuvani.brisanjeHeader","descDeleteQuestionMsgKey","obrazacSacuvani.brisanjePitanje","idModalPanel","obrazacSacuvaniModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[43,22],[43,27]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[44,28],[44,39]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshObr03",["loc",[null,[45,24],[45,36]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[46,31],[46,47]]]]],[],[]],"renderIzvestaji",true,"routeIzvestaji","obrObrazac03Izvestaji","routeUnos","obrObrazac03Unos","renderSearch",false,"renderFilterArhivirano",false,"renderCreate",false,"renderUpdate",false,"toolbarComponent","skeniran-toolbar","modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[55,37],[55,51]]]]],[],[]]],["loc",[null,[29,14],[56,16]]]],
        ["inline","component",[["get","componentTab2",["loc",[null,[66,26],[66,39]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[67,23],[67,27]]]]],[],[]],"content",["subexpr","@mut",[["get","contentPovredniList",["loc",[null,[68,26],[68,45]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumnsPovredniList",["loc",[null,[69,24],[69,48]]]]],[],[]],"domainName","ObrObrazac03","domainId","idObrazac03PovredniList","isNew",["subexpr","@mut",[["get","isNewPovredniList",["loc",[null,[72,22],[72,39]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObjectPovredniList",["loc",[null,[73,28],[73,51]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRowPovredniList",["loc",[null,[74,28],[74,51]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshPovredniList",["loc",[null,[75,24],[75,43]]]]],[],[]],"titleMsgKey","obrazacSacuvani.brisanjeHeader","descYesNoMsgKey","obrazacSacuvani.brisanjePitanje","descDeleteSuccessMsgKey","obrazacSacuvani.brisanjeUspesno","titleDeleteMsgKey","obrazacSacuvani.brisanjeHeader","descDeleteQuestionMsgKey","obrazacSacuvani.brisanjePitanje","routeIzvestaji","obrObrazac03Izvestaji","routeUnos","obrObrazac03Unos","renderDelete",false,"hasHeader",true,"hasFooter",false,"perPage",15,"toolbarComponent","skeniran-toolbar"],["loc",[null,[66,14],[88,16]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});