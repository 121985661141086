define('zaris-frontend/controllers/obr-obrazac02-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    // izbor radnih mesta
    radnaMesta: "radnaMesta",

    // izbor lekarskih pregleda
    lekarskiPregledi: "lekarskiPreglediKojiTreba",

    // izabrani period za preglede
    periodZaPreglede: 30,

    // datum za pocetak
    datumPocetak: null,

    // datum za kraj
    datumKraj: null,

    // idRadnoMesto - ukoliko je izabrano radno mesto
    idRadnoMesto: null,

    // idRadnoMestoStruktura - ukoliko je izabrano radno mesto u strukturi
    idRadnoMestoStruktura: null,

    labelPocetak: Ember['default'].computed(function () {
      var i18n = this.get('i18n');
      return i18n.t("obrazac2.izvestaji.labelPocetak");
    }),

    labelKraj: Ember['default'].computed(function () {
      var i18n = this.get('i18n');
      return i18n.t("obrazac2.izvestaji.labelKraj");
    }),

    // po cemu se grupise izvestaj
    groupBy: 'rm',

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;
    },

    actions: {
      generate: function generate() {

        // prepakovanje objekta
        var pObj = {};

        /*
         organizaciona struktura
         salje se:
                p_id_organizaciona_jedinica
         znacenje:
                p_id_organizaciona_jedinica - number
                  not null - id izabrane organizacione jedinice
                  null     - svi nivoi strukture
        */
        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        /*
         radna mesta
         salje se:
                p_sva_radna_mesta
                p_id_radno_mesto
                p_id_radno_mesto_struktura
         znacenje:
                p_sva_radna_mesta - boolean(0|1)
                  1 - izabrana opcija
                  0 - nije izabrana opcija
                p_id_radno_mesto - number
                  not null - id izabranog radnog mesta
                  null - nije izabrana opcija
                p_id_radno_mesto_struktura - number
                  not null - id izabranog radnog mesta u strukturi
                  null - nije izabrana opcija
        */
        var radnaMesta = this.get('radnaMesta');
        var idRadnoMesto = this.get('idRadnoMesto');
        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        pObj.p_sva_radna_mesta = 0;
        pObj.p_id_radno_mesto = null;
        pObj.p_id_radno_mesto_struktura = null;

        switch (radnaMesta) {
          case "radnaMesta":
            pObj.p_sva_radna_mesta = 1;
            break;
          case "radnoMesto":
            pObj.p_id_radno_mesto = idRadnoMesto;
            break;
          case "radnoMestoStruktura":
            pObj.p_id_radno_mesto_struktura = idRadnoMestoStruktura;
            break;
          default:
            console.log('error ', radnaMesta);
        }

        /*
        Lekarski pregledi
        salje se:
            p_pregled_datum_od
            p_pregled_datum_do
            p_obavljeni_pregledi
        znacenje:
            p_pregled_datum_od - string
              DD.MM.YYYY - ukoliko treba da se gleda
              null       - ne treba da se gleda
            p_pregled_datum_do - string
              DD.MM.YYYY - ukoliko treba da se gleda
              null       - ne treba da se gleda
            p_obavljeni_pregledi - boolean(0|1)
              1 - traze se obavljeni pregledi
              0 - traze se neobavljeni pregledi
              null  - ne gleda se
        */
        var imaPreglede = this.get('imaPreglede');
        var datumPocetak = this.get('datumPocetak');
        var datumKraj = this.get('datumKraj');
        var lekarskiPregledi = this.get('lekarskiPregledi');
        var periodZaPreglede = this.get('periodZaPreglede');

        pObj.p_pregled_datum_od = null;
        pObj.p_pregled_datum_do = null;
        pObj.p_obavljeni_pregledi = null;

        switch (lekarskiPregledi) {
          case "lekarskiPreglediKojiTreba":
            // periodZaPreglede - broj dana od sada
            pObj.p_pregled_datum_od = moment().format("DD.MM.YYYY");
            pObj.p_pregled_datum_do = moment().add(periodZaPreglede, 'days').format("DD.MM.YYYY");
            break;
          case "obavljeniPregledi":
            pObj.p_obavljeni_pregledi = 1;
            break;
          case "neobavljeniPregledi":
            pObj.p_obavljeni_pregledi = 0;
            break;
          case "lekarskiPreglediInterval":
            // traze se pregledi u intervalu koji je izabrao korisnik
            if (datumPocetak != null) {
              pObj.p_pregled_datum_od = moment(datumPocetak).format("DD.MM.YYYY");
            }
            if (datumKraj != null) {
              pObj.p_pregled_datum_do = moment(datumKraj).format("DD.MM.YYYY");
            }
            break;
          default:
            console.log('error ', lekarskiPregledi);
        }

        /*
        Grupisi po
        salje se:
            p_group_by
        znacenje:
            p_group_by - string
                'radnaMesta'             - grupisi po radnim mestima
                'organizacionaStruktura' - grupisi po organizacionoj strukturi
                'zaposleni'              - grupisi po zaposlenima
        */
        var groupBy = this.get('groupBy');
        pObj.p_labela = groupBy;

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_02';
        izvestajiDTO.reportParameters = pObj;

        //this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});