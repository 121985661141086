define('zaris-frontend/components/sif-obuke-lov-new-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'sifObukeLovNewModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",

    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    objId: null,

    title: Ember['default'].computed('isPeriodicno', function () {
      var i18n = this.get('i18n');
      return !this.get('isPeriodicno') ? i18n.t("sifKorisnickiDefinisaneObuke.nijePeriodicno").string : "";
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {
      /* dovlacenje itema za select listu*/
      var loginService = this.get('login');
      var sifObukaModel = Models['default'].SifObuka.create({ errorHandlerController: this });
      this.set('selectItems', sifObukaModel.findAllGetResult(loginService.getAccessToken()));
    },

    initCreate: function initCreate() {
      var obj = {};
      this.set('isPeriodicno', this.get('selectItems') !== null && this.get('selectItems')[0] !== null && this.get('selectItems')[0] !== undefined ? this.get('selectItems')[0].fgPeriodicno : false);
      obj[this.get('referenceDomainName')] = {};
      obj[this.get('referenceDomainName')][this.get('referenceDomainId')] = this.get('customId');
      obj.sifObuka = {};
      obj.sifObuka.idObuka = this.get('selectItems') !== null && this.get('selectItems')[0] !== null && this.get('selectItems')[0] !== undefined ? this.get('selectItems')[0].idObuka : 0;
      obj.intervalMeseci = this.get('selectItems') !== null && this.get('selectItems')[0] !== null && this.get('isPeriodicno') && this.get('selectItems')[0] !== undefined ? this.get('selectItems')[0].intervalMeseci : null;

      this.set('modelObjectObuka', obj);
    },

    initUpdate: function initUpdate() {
      var selected = jQuery.extend(true, {}, this.get('selectedRow'));
      this.set('modelObjectObuka', selected);
      this.set('isPeriodicno', this.get('selectedRow').sifObuka.fgPeriodicno);
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      merge: function merge() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var isNew;
          var obj;
          var sifRadnoMestoStrObukeModel;

          (function () {
            outter = _this;
            isNew = _this.get('isNew');

            var i18n = _this.get('i18n');

            obj = _this.get('modelObjectObuka');
            sifRadnoMestoStrObukeModel = Models['default'][_this.get('domainName')].create({ errorHandlerController: _this });

            sifRadnoMestoStrObukeModel.create(obj, _this.get('login').getAccessToken(), function (data) {
              if (isNew) {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisaneObuke.dodavanjeHeader').string,
                  content: i18n.t('sifKorisnickiDefinisaneObuke.dodavanjeUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
                // pamti se idRadnoMestoObuke u objId - treba nam da posaljemo zahtev za sinhronizaciju
                outter.set('objId', data[outter.get('target').get('domainId')]);
                if (outter.get('domainName') == 'SifRadnoMestoObuke') {
                  outter.get("target").send('openYesNoModal', 'idYesNoDialog', 'sifRadnaMesta.sinhronizacijaObukaHeader', 'sifRadnaMesta.sinhronizacijaObukaMsg', outter, 'createRadnoMestoObukaConfirm');
                }
                if (outter.get('domainName') == 'SifRadnoMestoStrObuke') {
                  outter.get("target").send('openYesNoModal', 'idYesNoDialog', 'sifRadnoMestoStruktura.sinhronizacijaObukaHeader', 'sifRadnoMestoStruktura.sinhronizacijaObukaMsg', outter, 'createRadnoMestoStrObukaConfirm');
                }
              } else {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisaneObuke.azuriranjeHeader').string,
                  content: i18n.t('sifKorisnickiDefinisaneObuke.azuriranjeUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
                if (outter.get('domainName') == 'SifLicaObuke') {
                  outter.get("target").send('openYesNoModal', 'idYesNoDialog', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaHeader', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaMsg', outter, 'updateConfirm');
                }
              }

              outter.closePanel();
              outter.toggleProperty('refreshObuke');
              outter.toggleProperty('refreshWoResetSelection');
            }, function (e) {
              if (isNew) {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisaneObuke.dodavanjeHeader').string,
                  content: i18n.t(e.responseJSON.messages[0]).string,
                  keepOpen: true,
                  type: 'alert'
                });
              } else {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisaneObuke.azuriranjeHeader').string,
                  content: i18n.t(e.responseJSON.messages[0]).string,
                  keepOpen: true,
                  type: 'alert'
                });
              }
            });
          })();
        }
      },

      itemSelected: function itemSelected(selection, component) {
        var result = $.grep(this.get('selectItems'), function (e) {
          return e.idObuka == selection;
        });
        this.set('modelObjectObuka.intervalMeseci', result[0].intervalMeseci);
        this.set('isPeriodicno', result[0].fgPeriodicno);
      }
    },

    createRadnoMestoObukaConfirm: function createRadnoMestoObukaConfirm(self) {
      var loginService = self.get('login');
      var outter = self;
      var i18n = outter.get('i18n');

      var model = Models['default']["SifRadnoMestoObuke"].create({ errorHandlerController: outter });

      model.getById('sinhronizacija', outter.get('objId'), loginService.getAccessToken(), 'POST', function () {
        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaHeader', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaSucc', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaHeader', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaErr', 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    createRadnoMestoStrObukaConfirm: function createRadnoMestoStrObukaConfirm(self) {
      var loginService = self.get('login');
      var outter = self;
      var i18n = outter.get('i18n');

      var model = Models['default']["SifRadnoMestoStrObuke"].create({ errorHandlerController: outter });
      model.getById('sinhronizacija', outter.get('objId'), loginService.getAccessToken(), 'POST', function () {
        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaHeader', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaSucc', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaHeader', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaErr', 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    updateConfirm: function updateConfirm(self) {
      var loginService = self.get('login');
      var outter = self;
      var i18n = outter.get('i18n');

      var obj = outter.get('modelObjectObuka');
      var domainId = outter.get('target').get('domainId');

      var model = Models['default'][outter.get('domainName')].create({ errorHandlerController: outter });
      model.getById('sinhronizacija', obj[domainId], loginService.getAccessToken(), 'POST', function () {

        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaHeader', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaSucc', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaHeader', 'sifKorisnickiDefinisaneObuke.sinhronizacijaObukaErr', 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }

      /*function(o){
        $.Notify({
            caption: i18n.t("sifKorisnickiDefinisanePregledi.sinhronizacijaPregledaSucc").string,
            content: i18n.t("sifKorisnickiDefinisanePregledi.sinhronizacijaPregledaErr").string,
            keepOpen: false,
            type: 'success'
        });
      }*/);
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      if (this.get('isNew')) {
        this.initCreate();
      } else {
        this.initUpdate();
      }

      Ember['default'].showModalPanel('.sifObukeLovNewModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifObukeLovNewModal').data('dialog').close();
    }
  });

});