define('zaris-frontend/controllers/sif-oprema-za-rad', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service('i18n'),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'vrsta',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifOpremaZaRad.vrsta'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'fabrickiBroj',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifOpremaZaRad.fabrickiBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'godinaProizvodnje',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifOpremaZaRad.godinaProizvodnje'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'lokacijaBlize',
        savedWidth: 180,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifOpremaZaRad.lokacijaUStrukturi'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var orgJedinica = row.get('sysOrganizacionaJedinica.naziv');
          var lokacijaBlize = row.get('lokacijaBlize');

          if (lokacijaBlize === null) {
            lokacijaBlize = '';
          } else {
            lokacijaBlize = '(' + lokacijaBlize + ')';
          }

          return orgJedinica + lokacijaBlize;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'namena',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifOpremaZaRad.namena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    }),

    actions: {
      initOrgJedinica: function initOrgJedinica() {
        var obj = {};
        obj.group_value = this.get('modelObject.sysOrganizacionaJedinica.naziv');
        obj.id = this.get('modelObject.sysOrganizacionaJedinica.id');
        this.set('selectedOrgJedinica', obj);
      }
    }
  });

});