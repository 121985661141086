define('zaris-frontend/controllers/obr-obrazac08-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    idParametar: null,

    ispitivanje: "ispitivanjaKojaTreba",

    periodZaIspitivanja: 30,

    actions: {

      generate: function generate() {

        var pObj = {};

        var idParametar = this.get('idParametar');
        pObj.p_id_parametar_okoline = idParametar;

        pObj.p_datum_od = null;
        pObj.p_datum_do = null;
        pObj.p_obavljena_ispitivanja = "0";
        pObj.p_neobavljena_ispitivanja = "0";

        var period = this.get('periodZaIspitivanja');

        var ispitivanja = {
          'ispitivanjaKojaTreba': function ispitivanjaKojaTreba() {
            pObj.p_datum_od = moment().format("DD.MM.YYYY");
            pObj.p_datum_do = moment().add(period, 'days').format("DD.MM.YYYY");
          },
          'obavljenaIspitivanja': function obavljenaIspitivanja() {
            pObj.p_obavljena_ispitivanja = "1";
          },
          'neobavljenaIspitivanja': function neobavljenaIspitivanja() {
            pObj.p_neobavljena_ispitivanja = "1";
          }
        };

        ispitivanja[this.get('ispitivanje')]();

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_08';
        izvestajiDTO.reportParameters = pObj;

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});