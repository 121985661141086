define('zaris-frontend/templates/components/sys-korisnik-rola-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sys-korisnik-rola-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","clearFloat");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [5]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element2,3,3);
        morphs[3] = dom.createElementMorph(element4);
        morphs[4] = dom.createMorphAt(element4,2,2);
        morphs[5] = dom.createElementMorph(element5);
        morphs[6] = dom.createMorphAt(element5,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["sysKorisnikRola.pregledRola"],[],["loc",[null,[6,10],[6,45]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","component",[["get","componentLov",["loc",[null,[15,14],[15,26]]]]],["target",["subexpr","@mut",[["get","target",["loc",[null,[16,11],[16,17]]]]],[],[]],"domainName","SysKorisnikRola","domainId","idKorisnikRola","content",["subexpr","@mut",[["get","contentKorisnikRola",["loc",[null,[19,12],[19,31]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumnsKorisnikRola",["loc",[null,[20,12],[20,36]]]]],[],[]],"titleMsgKey","obrObrazac07.brisanjeHeader","descYesNoMsgKey","obrObrazac07.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac07.brisanjeOpasnaMaterijaUspesno","titleDeleteMsgKey","obrObrazac07.brisanjeOpasnaMaterijaHeader","descDeleteQuestionMsgKey","obrObrazac07.brisanjeOpasnaMaterijaPitanje","descDeleteSuccessMsgKey","obrObrazac07.brisanjeOpasnaMaterijaUspesno","hasHeader",true,"hasFooter",false,"perPage",10,"isNew",["subexpr","@mut",[["get","isNewUnos",["loc",[null,[30,10],[30,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showModalPanelOpasnaMaterija",["loc",[null,[31,21],[31,49]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selektovanaOpasnaMaterija",["loc",[null,[32,16],[32,41]]]]],[],[]],"idRowSelected",["subexpr","@mut",[["get","idRowSelectedObr7",["loc",[null,[33,18],[33,35]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[34,12],[34,19]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[35,28],[35,51]]]]],[],[]],"renderOnlySearch",true,"customId",["subexpr","@mut",[["get","selectedRowId",["loc",[null,[37,13],[37,26]]]]],[],[]]],["loc",[null,[15,2],[38,4]]]],
        ["element","action",["save"],[],["loc",[null,[42,12],[42,29]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[42,81],[42,106]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[43,12],[43,38]]]],
        ["inline","t",["app.common.close"],[],["loc",[null,[43,86],[43,110]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});