define('zaris-frontend/templates/obr-obrazac03-unos', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac03-unos.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("iframe");
        dom.setAttribute(el1,"class","preview-pane");
        dom.setAttribute(el1,"type","application/pdf");
        dom.setAttribute(el1,"style","position:relative;z-index:999");
        dom.setAttribute(el1,"frameborder","0");
        dom.setAttribute(el1,"height","650");
        dom.setAttribute(el1,"width","100%");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_povredama_na_radu"],[],["loc",[null,[4,28],[4,75]]]],
        ["inline","component",["zaris-datatable"],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"domainName","ObrObrazac03","domainId","idObrazac03PovredniList","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[14,16],[14,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[15,22],[15,33]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[16,22],[16,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[17,18],[17,25]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"idModalPanel","obrObrazac03Modal","renderSacuvajObrazac",true,"renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac03Sacuvani","renderIzvestaji",true,"routeIzvestaji","obrObrazac03Izvestaji","toolbarComponent","obr-obrazac03-toolbar","modalPanelShow",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[28,27],[28,41]]]]],[],[]],"titleMsgKey","obrObrazac03.brisanjeHeader","descYesNoMsgKey","obrObrazac03.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac03.brisanjeUspesno","titleDeleteMsgKey","obrObrazac03.brisanjeHeader","descDeleteQuestionMsgKey","obrObrazac03.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac03.brisanjeUspesno"],["loc",[null,[9,8],[35,10]]]],
        ["inline","obr-obrazac03-modal",[],["id","obrObrazac03Modal","domainId","idObrazac03PovredniList","target",["subexpr","@mut",[["get","this",["loc",[null,[45,11],[45,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[46,10],[46,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[47,16],[47,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[48,12],[48,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[49,21],[49,35]]]]],[],[]],"objPovredjeni",["subexpr","@mut",[["get","objPovredjeni",["loc",[null,[50,18],[50,31]]]]],[],[]],"objRukovodioc",["subexpr","@mut",[["get","objRukovodioc",["loc",[null,[51,18],[51,31]]]]],[],[]],"objOcevidac",["subexpr","@mut",[["get","objOcevidac",["loc",[null,[52,16],[52,27]]]]],[],[]],"controllerObj",["subexpr","@mut",[["get","controllerObj",["loc",[null,[53,18],[53,31]]]]],[],[]]],["loc",[null,[42,0],[54,2]]]],
        ["inline","obr-obrazac03-report",[],["id","obrObrazac03Report","domainId","idObrazac03PovredniList","target",["subexpr","@mut",[["get","this",["loc",[null,[59,11],[59,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[60,10],[60,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[61,16],[61,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[62,12],[62,19]]]]],[],[]],"reportPanelShow",["subexpr","@mut",[["get","customRefresh",["loc",[null,[63,22],[63,35]]]]],[],[]],"objPovredjeni",["subexpr","@mut",[["get","objPovredjeni",["loc",[null,[64,18],[64,31]]]]],[],[]],"objRukovodioc",["subexpr","@mut",[["get","objRukovodioc",["loc",[null,[65,18],[65,31]]]]],[],[]],"objOcevidac",["subexpr","@mut",[["get","objOcevidac",["loc",[null,[66,16],[66,27]]]]],[],[]],"controllerObj",["subexpr","@mut",[["get","controllerObj",["loc",[null,[67,18],[67,31]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[68,10],[68,15]]]]],[],[]]],["loc",[null,[56,0],[69,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});