define('zaris-frontend/locales/sr-cyrl/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    "app.title": "Базис",

    "app.home.welcomeMsg": "Добродошли на информациони систем БАЗИС",
    "app.home.sifarnici": "Шифарници",
    "app.home.obrasci": "Обрасци",
    "app.home.obavestenja": "Обавештења",
    "app.home.obavestenjaNemaRezultata": "Нема обавештења",
    "app.home.obavestenjaDisclaimer": "Напомена: подаци се освежавају на сваких 5 минута. Освежавање можете покренути и кликом на иконицу у наслову табеле.",

    "app.login.username": "Корисничко име",
    "app.login.password": "Лозинка",
    "app.login.login": "Улогуј ме",

    "language-select.language.sr": "Латиница - Српски",
    "language-select.language.en": "Енглески",
    "language-select.language.sr-cyrl": "Ћирилица - Српски",

    "app.menu.evidencija_o_korisicki_definisanim_obukama": "Евиденција о кориснички дефинисаним обукама",
    "app.menu.unos": "Унос",
    "app.menu.opasnosti_i_stetnosti": "Опасности и штетности",
    "app.menu.korisnicki_definisane_obuke": "Кориснички дефинисане обуке",
    "app.menu.evidencija_o_izvrsenim_pregledima_i_ispitivanjima_sredstava_i_opreme_za_licnu_zastitu_na_radu": "Набавка опреме за личну заштиту",
    "app.menu.evidencija_o_izdatim_sredstvima_i_lzo": "Evidencija o izdatim sredstvima i opremi za ličnu zaštitu na radu",
    "app.menu.izvestaji": "Извештаји",
    "app.menu.parametri_radne_okoline": "Параметри радне околине",
    "app.menu.podesavanja": "Подешавања",
    "app.menu.izvestaji_za_sifarnike": "Извештаји за шифарнике",
    "app.menu.pocetna": "Почетна",
    "app.menu.evidencija_o_prijavama_bolesti_u_vezi_sa_radom": "Евиденција о пријавама болести у вези са радом",
    "app.menu.baza_znanja": "База Знања",
    "app.menu.elementi_pregleda": "Елементи прегледа",
    "app.menu.evidencija_o_radnim_mestima_sa_povecanim_rizikom": "Евиденција о радним местима са повећаним ризиком",
    "app.menu.nivoi_rizika": "Нивои ризика",
    "app.menu.evidencija_o_zaposlenima": "Евиденција о запосленима",
    "app.menu.odjava": "Одјава",
    "app.menu.sifarnici": "Шифарници",
    "app.menu.evidencija_o_prijavama_opasnih_pojava_koje_bi_mogle_da_ugroze_bezbednost_i_zdravlje_zaposlenih": "Евиденција о пријавама опасних појава које би могле да угрозе безбедност и здравље запослених",
    "app.menu.zaposleni": "Запослени",
    "app.menu.evidencija_o_korisnicki_definisanim_pregledima": "Евиденција о кориснички дефинисаним прегледима",
    "app.menu.evidencija_o_korisnicki_definisanim_obukama": "Евиденција о кориснички дефинисаним обукама",
    "app.menu.oprema_za_rad": "Опрема за рад",
    "app.menu.evidencija_o_organizacionoj_strukturi": "Евиденција о организационој структури",
    "app.menu.evidencija_o_opasnim_materijama_koje_se_koriste_u_toku_dana": "Евиденција о опасним материјама које се користе у току дана",
    "app.menu.organizaciona_struktura": "Организациона структура",
    "app.menu.korisnicki_definisani_pregledi": "Кориснички дефинисани прегледи",
    "app.menu.evidencija_o_licima": "Евиденција о лицима",
    "app.menu.evidencija_o_radnim_mestima": "Евиденција о радним местима",
    "app.menu.evidencija_o_zaposlenima_rasporedjenim": "Евиденција о запосленима распоређеним на радна места са повећаним ризиком и лекарским прегледима",
    "app.menu.evidencija_o_izvrsenim_pregledima_i_ispitivanjima_opreme_za_rad": "Евиденција о извршеним прегледима и испитивањима опреме за рад",
    "app.menu.reversi": "Evidencija o izdatim sredstvima i opremi za ličnu zaštitu na radu",
    "app.menu.lica": "Лица",
    "app.menu.opasne_materije": "Опасне материје",
    "app.menu.evidencija_o_prijavama_profesionalnih_oboljenja": "Евиденција о пријавама професионалних обољења",
    "app.menu.sacuvani_izvestaji": "Сачувани обрасци",
    "app.menu.radna_mesta_u_strukturi": "Радна места у структури",
    "app.menu.evidencija_o_radnim_mestima_u_strukturi": "Евиденција о радним местима у структури",
    "app.menu.radna_mesta": "Радна места",
    "app.menu.evidencija_o_povredama_na_radu": "Евиденција о повредама на раду",
    "app.menu.evidencija_o_profesionalnim_oboljenjima": "Евиденција о професионалним обољењима",
    "app.menu.licna_zastitna_oprema": "Лична заштитна опрема",
    "app.menu.vrste_velicina_za_lzo": "Врсте величина за ЛЗО",
    "app.menu.evidencija_o_prijavama_povreda_na_radu": "Евиденција о пријавама повреда на раду",
    "app.menu.evidencija_o_bolestima_u_vezi_sa_radom": "Евиденција о болестима у вези са радом",
    "app.menu.evidencija_o_zaposlenima_osposobljenim_za_bezbedan_i_zdrav_rad": "Евиденција о запосленима оспособљеним за безбедан и здрав рад",
    "app.menu.pomoc": "Помоћ",
    "app.menu.evidencija_o_stetnostima_na_svim_radnim_mestima": "Евиденција о штетностима на свим радним местима",
    "app.menu.evidencija_o_izvrsenim_ispitivanjima_radne_okoline": "Евиденција о извршеним испитивањима радне околине",
    "app.menu.dodeljivanje_pregleda_zaposlenima": "Додељивање прегледа запосленима",
    "app.menu.dodeljivanje_obuke_za_bezbedan_i_zdrav_rad": "Додељивање обуке за безбедан и здрав рад запосленима",
    "app.menu.dodeljivanje_obuka_zaposlenima": "Додељивање обуке запосленима",
    "app.menu.evidencija_o_izvesenim_ispitivanjima_radne_okoline": "Евиденција о извршеним испитивањима радне околине",
    "app.menu.pravnaLica": "Правна лица",
    "app.menu.korisniciZaPravnoLice": "Корисници за правно лице",
    "app.menu.promena_passworda": "Промена лозинке",
    "app.menu.podesavanja_obavestenja": "Подешавање обавештења",
    "app.menu.promena_jezika": "Промена језика",
    "app.menu.izvestaj_o_licnoj_zastitnoj_opremi": "Извештај о личној заштитној опреми",
    "app.menu.vertikalni_izvestaj": "Вертикални извештај",
    "app.menu.povredni_listovi": "Повредни листови",
    "app.menu.odabir_potpisnika": "Одабир потписника",

    "app": {
      "menu": {
        "akronim": {
          "ob_01": "ОБ 01",
          "ob_02": "ОБ 02",
          "ob_03": "ОБ 03",
          "ob_04": "ОБ 04",
          "ob_05": "ОБ 05",
          "ob_06": "ОБ 06",
          "ob_07": "ОБ 07",
          "ob_08": "ОБ 08",
          "ob_09": "ОБ 09",
          "ob_10": "ОБ 10",
          "ob_11": "ОБ 11",
          "ob_12": "ОБ 12",
          "ob_13": "ОБ 13",
          "ob_14": "ОБ 14"
        }
      }
    },

    "app.common.modalYes": "ДА",
    "app.common.modalNo": "НЕ",
    "app.common.create": "Сними",
    "app.common.cancel": "Одустани",
    "app.common.close": "Затвори",
    "app.common.choose": "Одабери",
    "app.common.chooseAll": "Одабери све",
    "app.common.Yes": "Да",
    "app.common.No": "Не",
    "app.common.unauthorized": "Забрањен приступ!!!",
    "app.common.emptyString": "",

    "app.common.dodaj": "Додај",
    "app.common.izmeni": "Измени",
    "app.common.obrisi": "Обриши",
    "app.common.obrisiIzbor": "Обриши избор",
    "app.common.preuzmi": "Преузми",
    "app.common.generisi": "Генериши",
    "app.common.svi": "Сви",
    "app.common.aktivni": "Активни",
    "app.common.arhivirani": "Архивирани",
    "app.common.sacuvaj": "Сачувај",
    "app.common.sacuvajObrazac": "Сачувај образац",
    "app.common.unosPodataka": "Унос података",
    "app.common.skeniraniDokument": "Скенирани документ",

    "app.common.fileUploadError": "Грешка приликом слања фајла!",
    "app.common.fileDownloadError": "Грешка приликом преузимања фајла!",
    "app.common.fileDeleteError": "Грешка приликом брисања фајла!",

    "app.common.radnoMesto": "Радно место",
    "app.common.radnoMestoStruktura": "Радно место структура",

    "app.common.izvestajLekara": "Извештај лекара",
    "app.common.odaberiteJezik": "Одаберите језик",

    "app.errors": {
      "dataIntegrityViolationException": "Не можете обрисати одабрани ентитет јер се користи у другим деловима система!",
      "uniqueIndexConstraint_xak1obr_obrazac07_rm": "Дато радно место је већ евидентирано!",
      "uniqueIndexConstraint_xak1obr_obrazac07_rms": "Дато радно место у структури је већ евидентирано!",
      "entityReferenced": {
        "obr_obrazac02a_specijalan_pregled": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 2a!",
        "obr_obrazac02_element_zdravstvenog_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 2!",
        "obr_obrazac01a_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 1a!",
        "obr_obrazac02_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 2!",
        "obr_obrazac03_povredni_list": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 3!",
        "obr_obrazac02a_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 2a!",
        "obr_obrazac02_zdravstveni_pregled": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 2!",
        "obr_obrazac01_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 1!",
        "obr_obrazac04_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 4!",
        "obr_obrazac03_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 3!",
        "obr_obrazac05_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 5!",
        "obr_obrazac06a_specijalna_obuka": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6a!",
        "obr_obrazac04_zaposleni_oboljenje": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 4!",
        "obr_obrazac06_element_zakazane_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "obr_obrazac06_obuka_zakazana": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "obr_obrazac06_obuka": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "obr_obrazac07_opasna_materija_rm": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac05_zaposleni_bolest": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 5!",
        "obr_obrazac06_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 6!",
        "obr_obrazac07_rms": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac08_ispitivanje_okoline": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 8!",
        "obr_obrazac09_pregled_opreme": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 9!",
        "obr_obrazac08_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 8!",
        "obr_obrazac10_lzo": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 10!",
        "obr_obrazac09_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 9!",
        "obr_obrazac10_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 10!",
        "obr_obrazac07_rm": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac10_lzo_pregledi": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 10!",
        "obr_obrazac07_opasna_materija_rms": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac07_rms_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 7!",
        "obr_obrazac12_prijava_oboljenje": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 12!",
        "obr_obrazac13_prijava_bolest": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 13!",
        "obr_obrazac12_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 12!",
        "obr_obrazac13_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 13!",
        "obr_obrazac1_rm_opasnosti": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 1!",
        "obr_obrazac14_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 14!",
        "obr_obrazac1_rms_opasnosti": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 1!",
        "obr_obrazac11_prijava_povrede": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 11!",
        "obr_obrazac14_prijava_pojave": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 14!",
        "sif_lzo": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku LZO!",
        "sif_bolest": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku bolesti!",
        "sif_element_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku elemenata pregleda!",
        "sif_izvor_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku izvora povreda!",
        "sif_lica": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku lica!",
        "sif_lica_lzo_kategorije": "Ne možete obrisati odabrani entitet jer se koristi za LZO kategorije lica!",
        "sif_lica_lzo_velicine": "Ne možete obrisati odabrani entitet jer se koristi za LZO veličine lica!",
        "sif_lica_pregledi": "Ne možete obrisati odabrani entitet jer se koristi za preglede lica!",
        "sif_lica_obuke": "Ne možete obrisati odabrani entitet jer se koristi za obuke lica!",
        "sif_opasna_materija": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku opasnih materija!",
        "sif_mesto_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku mesta povrede!",
        "sif_nivo_rizika": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku nivoa rizika!",
        "sif_opasnosti_stetnosti": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku opasnosti i štetnosti!",
        "sif_lzo_kategorija": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku kategorija LZO!",
        "sif_oboljenje": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku oboljenja!",
        "sif_obuka": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku obuka!",
        "sif_lzo_vrsta_velicine": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku LZO vrsta veličina!",
        "sif_opasna_materija_klasa": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku klasa opasnih materija!",
        "sif_revers": "Ne možete obrisati odabrani entitet jer se koristi u reversima!",
        "sif_radno_mesto_str_lzo": "Ne možete obrisati odabrani entitet jer se koristi u LZO za radna mesta u strukturi!",
        "sif_radno_mesto": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku radnih mesta!",
        "sif_radno_mesto_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obukama za radna mesta!",
        "sif_radno_mesto_pregledi": "Ne možete obrisati odabrani entitet jer se koristi u pregledima za radna mesta!",
        "sif_radno_mesto_struktura": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku radnih mesta u strukturi!",
        "sif_radno_mesto_str_pregledi": "Ne možete obrisati odabrani entitet jer se koristi u pregledima za radna mesta u strukturi!",
        "sif_oprema_za_rad": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku opreme za rad!",
        "sif_parametar_okoline": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku parametara okoline!",
        "sif_revers_lzo": "Ne možete obrisati odabrani entitet jer se koristi u LZO za reverse!",
        "sif_radno_mesto_str_el_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u elementima pregleda za radna mesta u strukturi!",
        "sif_radno_mesto_el_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u elementima pregleda za radna mesta!",
        "sif_radno_mesto_str_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obukama za radna mesta u strukturi!",
        "sif_tezina_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku težine povrede!",
        "sif_zaposleni": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku zaposlenih!",
        "sif_zdravstveni_pregled": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku zdravstvenih pregleda!",
        "sif_zdravstvena_sposobnost": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku zdravstvene sposobnosti!",
        "sif_uzrok_povrede": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku uzroka povrede!",
        "obr_obrazac03_povredni_list_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 3!",
        "sif_lica_el_pregleda": "Ne možete obrisati odabrani entitet jer se koristi u elementima pregleda lica!",
        "obr_obrazac07_rm_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 7!",
        "obr_obrazac06_element_obuke": "Ne možete obrisati odabrani entitet jer se koristi u obrascu 6!",
        "sif_obuka_zakazana": "Ne možete obrisati odabrani entitet jer se koristi u zakazanim obukama!",
        "obr_obrazac11_sacuvan": "Ne možete obrisati odabrani entitet jer se koristi u sačuvanom obrascu 11!",
        "sif_radno_mesto_lzo": "Ne možete obrisati odabrani entitet jer se koristi u LZO za radna mesta!",
        "sif_zdravstveni_pregled_zakazan": "Ne možete obrisati odabrani entitet jer se koristi u zakazanim pregledima!",
        "sys_organizaciona_jedinica": "Ne možete obrisati odabrani entitet jer se koristi u šifarniku organizacionih jedinica!"
      }
    },

    "pol": {
      "muski": "Мушки",
      "zenski": "Женски"
    },

    "bzr": "БЗР",

    "sifNivoiRizika": {
      "brisanjeHeader": "Брисање нивоа ризика",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани извор ризика?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање нивоа ризика",
      "dodavanjeUspesno": "Успешно сте додали ниво ризика!",
      "azuriranjeHeader": "Ажурирање нивоа ризика",
      "azuriranjeUspesno": "Успешно сте ажурирали ниво ризика!",
      "inputSifra": "Шифра",
      "inputNaziv": "Назив",
      "inputNapomena": "Напомена"
    },

    "sifKorisnickiDefinisaneObuke": {
      "brisanjeHeader": "Брисање обуке",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану обуку?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање обуке",
      "dodavanjeUspesno": "Успешно сте додали обуку!",
      "azuriranjeHeader": "Ажурирање обуке",
      "azuriranjeUspesno": "Успешно сте ажурирали обуку!",
      "inputNaziv": "Назив",
      "inputOpis": "Опис",
      "inputTip": "Тип",
      "inputNazivObuke": "Назив обуке",
      "inputIntervalMeseci": "Интервал (у месецима)",
      "interval": "Интервал",
      "sifarnikObuke": "Шифарник обука",
      "periodicno": "Периодично",
      "nijePeriodicno": "Одабрана обука није периодична",
      "obukaSeObavljaJednom": "Обука се обавља једном",
      "sinhronizacijaObukaHeader": "Синхронизација обука",
      "sinhronizacijaObukaMsg": "Да ли желите да извршите синхронизацију обука у обрасцима према текућој промени?",
      "sinhronizacijaObukaSucc": "Синхронизација обука успешна",
      "sinhronizacijaObukaErr": "Синхронизација обука неуспешна"
    },

    "sifKorisnickiDefinisaniPregledi": {
      "brisanjeHeader": "Брисање прегледа",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани преглед?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање прегледа",
      "dodavanjeUspesno": "Успешно сте додали преглед!",
      "azuriranjeHeader": "Ажурирање прегледа",
      "azuriranjeUspesno": "Успешно сте ажурирали преглед!",
      "inputNaziv": "Назив",
      "inputOpis": "Опис",
      "inputTip": "Тип",
      "inputNazivPregledi": "Назив прегледа",
      "interval": "Интервал",
      "inputIntervalMeseci": "Интервал (у месецима)",
      "sifarnikPregleda": "Шифарник прегледа",
      "periodicno": "Периодично",
      "nijePeriodicno": "Одабрани преглед није периодичан",
      "pregledSeObavljaJednom": "Преглед се обавља једном",
      "sinhronizacijaPregledaHeader": "Синхронизација прегледа",
      "sinhronizacijaPregledaMsg": "Да ли желите да извршите синхронизацију прегледа у обрасцима према текућој промени?",
      "sinhronizacijaPregledaSucc": "Синхронизација прегледа успешна",
      "sinhronizacijaPregledaErr": "Синхронизација прегледа неуспешна"
    },

    "sifParametriRadneOkoline": {
      "brisanjeHeader": "Брисање параметра",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани параметар?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање параметра",
      "dodavanjeUspesno": "Успешно сте додали параметар!",
      "azuriranjeHeader": "Ажурирање параметра",
      "azuriranjeUspesno": "Успешно сте ажурирали параметар!",
      "inputNaziv": "Назив",
      "inputSifra": "Шифра",
      "inputKategorija": "Категорија"
    },

    "sifOpasneMaterije": {
      "brisanjeHeader": "Брисање опасне материје",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану материју?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање опасне материје",
      "dodavanjeUspesno": "Успешно сте додали опасну материју!",
      "azuriranjeHeader": "Ажурирање опасне материје",
      "azuriranjeUspesno": "Успешно сте ажурирали опасну материју!",
      "inputNaziv": "Назив",
      "inputHemijskoIme": "Хемијско име",
      "inputUN": "УН",
      "inputADR": "АДР",
      "inputRID": "РИД",
      "inputCAS": "ЦАС",
      "napomena1": "Напомена 1",
      "napomena2": "Напомена 2",
      "obelezavanjeDodatno": "Обележавање додатно",
      "obelezavanjeObavestenje": "Обележавање обавештење",
      "obelezavanjePiktogram": "Обележавање пиктограм",
      "klasifikacijaObavestenje": "Класификација обавештавање",
      "klasifikacijaKlase": "Класификација класе",
      "specificneGranicneKoncentracije": "Специфичне граничне конц",
      "granicneKoncentracije": "Граничне концентрације",
      "obelezavanje": "Обележавање",
      "klasifikacija": "Класификација",
      "ecBroj": "ЕЦ број",
      "indeksBroj": "Индекс број",
      "casBroj": "ЦАС број"
    },

    "sifOrganizacionaJedinica": {
      "brisanjeHeader": "Брисање организационе јединице",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану организациону јединицу?",
      "brisanjeUspesno": "Успешно сте обрисали одабрану организациону јединицу!",
      "dodavanjeHeader": "Додавање организационе јединице",
      "dodavanjeUspesno": "Успешно сте додали организациону јединицу!",
      "azuriranjeHeader": "Ажурирање организационе јединице",
      "azuriranjeUspesno": "Успешно сте ажурирали организациону јединицу!",
      "inputNaziv": "Назив",
      "izabraniNivoImaDecu": "Изабрани ниво организационе структуре се не може избрисати јер је за њега везан барем један нижи ниво.",
      "dodavanjeNovePoruka": "Додавање нове организационе јединице у оквиру јединице "
    },

    "sifElementiPregleda": {
      "brisanjeHeader": "Брисање елемента прегледа",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани елемент прегледа?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање елемента прегледа",
      "dodavanjeUspesno": "Успешно сте додали елемент прегледа!",
      "azuriranjeHeader": "Ажурирање елемента прегледа",
      "azuriranjeUspesno": "Успешно сте ажурирали елемент прегледа!",
      "inputIntervalMeseci": "Интервал лекарског прегледа",
      "inputElementPregleda": "Елемент лекарског прегледа",
      "napomena": "*Напомена: Интервали за елементе лекарског прегледа не могу да се поставе" + " на вредности веће од прописане, могу само да буду мањи или једнаки прописаним интервалима.",
      "opisZaPrethodniPregled": "Опис за претходни преглед",
      "opisZaPeriodicniPregled": "Опис за периодични преглед",
      "sifarnikElemenataPregleda": "Шифарник ел. прегледа",
      "sinhronizacijaElPregledaHeader": "Синхронизација елемента прегледа",
      "sinhronizacijaElPregledaSucc": "Синхронизација елемента прегледа успешна",
      "sinhronizacijaElPregledaErr": "Синхронизација елемента прегледа неуспешна",
      "intervalObavestenje": "Интервали за елементе лекарског прегледа не могу да се поставе" + " на вредности веће од прописане.",
      "sifra": "Шифра",
      "elementPregleda": "Елемент прегледа",
      "interval": "Интервал",
      "propisanInterval": "Прописан интервал"
    },

    "sifRadnoMestoStruktura": {
      "radnoMestoNaziv": "Радно место",
      "organizacionaJedinicaNaziv": "Организациона структура",
      "sifra": "Шифра",
      "elementiPregledaUkupno": "Ел. прег.",
      "obukeUkupno": "Обуке",
      "preglediUkupno": "Прегледи",
      "FgRizicno": "Ризично",
      "novoRadnoMestoUOrganizacionojStrukturi": "Ново радно место у организационој структури",
      "azuriranjeHeader": "Ажурирање структуре радног места",
      "azuriranjeUspesno": "Успешно сте ажурирали структуру радног места!",
      "inputRadnoMestoUStrukturi": "Радно место у структури",
      "inputRizik": "Радно место са повећаним ризиком",
      "adresa": "Адреса",
      "mesto": "Место",
      "drzava": "Држава",
      "inputOpis": "Опис",
      "inputObavestenja": "Обавештења",
      "inputMere": "Мере",
      "inputZdravstveniUslovi": "Здравствени услови",
      "dodavanjeHeader": "Додавање структуре радног места",
      "dodavanjeUspesno": "Успешно сте додали структуру радног места!",
      "brisanjeHeader": "Брисање радног места",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрано радно место из организационе структуре?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "sinhronizacijaObukaHeader": "Синхронизација обука",
      "sinhronizacijaObukaMsg": "Успешно сте додали обуку. Да ли желите да је додате за запослене (за ово радно место у структури)?",
      "sinhronizacijaPregledaHeader": "Синхронизација прегледа",
      "sinhronizacijaPregledaMsg": "Успешно сте додали преглед. Да ли желите да га додате за запослене (за ово радно место у структури)?",
      "sinhronizacijaElPregledaHeader": "Синхронизација елемента прегледа",
      "sinhronizacijaElPregledaMsg": "Успешно сте додали елемент прегледа. Да ли желите да га додате за запослене (за ово радно место у структури)?"
    },

    "sifRadnaMesta": {
      "brisanjeHeader": "Брисање радног места",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрано радно место?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање радног места",
      "dodavanjeUspesno": "Успешно сте додали радно место!",
      "azuriranjeHeader": "Ажурирање радног места",
      "azuriranjeUspesno": "Успешно сте ажурирали радно место!",
      "inputNaziv": "Назив",
      "inputSifra": "Шифра",
      "inputRizik": "Радно место са повећаним ризиком",
      "inputOpis": "Опис",
      "inputObavestenja": "Обавештења",
      "inputMere": "Мере",
      "inputZdravstveniUslovi": "Здравствени услови",
      "sinhronizacijaObukaHeader": "Синхронизација обука",
      "sinhronizacijaObukaMsg": "Успешно сте додали обуку. Да ли желите да је додате за постојећа радна места у структури и запослене на њима?",
      "sinhronizacijaPregledaHeader": "Синхронизација прегледа",
      "sinhronizacijaPregledaMsg": "Успешно сте додали преглед. Да ли желите да га додате за постојећа радна места у структури и запослене на њима?",
      "sinhronizacijaElPregledaHeader": "Синхронизација елемента прегледа",
      "sinhronizacijaElPregledaMsg": "Успешно сте додали елемент прегледа. Да ли желите да га додате за постојећа радна места у структури и запослене на њима?"
    },

    "sifLica": {
      "brisanjeHeader": "Брисање лица",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрано лице?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање лица",
      "dodavanjeUspesno": "Успешно сте додали лице!",
      "azuriranjeHeader": "Ажурирање лица",
      "azuriranjeUspesno": "Успешно сте ажурирали лице!",
      "inputIme": "Име",
      "inputPrezime": "Презиме",
      "inputOcevoIme": "Очево име",
      "inputDatumRodjenja": "Датум рођења",
      "inputMestoRodjenja": "Место рођења",
      "inputOpstinaRodjenja": "Општина рођења",
      "inputMaticniBroj": "ЈМБГ",
      "inputDatumPocetkaRada": "Датум почетка рада",
      "inputInterniMaticniBroj": "Интерни матични број",
      "inputZanimanje": "Занимање",
      "inputAdresa": "Адреса",
      "inputMesto": "Место",
      "inputDrzava": "Држава",
      "inputDatumZavrsetkaRada": "Датум завршетка рада",
      "inputPol": "Пол",
      "inputLiceZaBzr": "Лице за БЗР",
      "imePrezime": "Име и презиме",
      "aktiviranjeUspesno": "Лице успешно активирано",
      "deaktiviranjeUspesno": "Лице успешно деактивирано"
    },

    "sifZaposleni": {
      "brisanjeHeader": "Брисање запосленог",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабраног запосленог?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање запосленог",
      "dodavanjeUspesno": "Успешно сте додали запосленог!",
      "azuriranjeHeader": "Ажурирање запосленог",
      "azuriranjeUspesno": "Успешно сте ажурирали запосленог!",
      "interniMaticniBroj": "Интерни матични број",
      "maticniBroj": "ЈМБГ",
      "imePrezime": "Име и презиме",
      "radnoMestoNaziv": "Радно место",
      "organizacionaJedinicaNaziv": "Орг. јединица",
      "inputInterniMaticniBroj": "Интерни матични број",
      "povezivanjeLicaIRadnihMesta": "Повезивање лица и радних места"
    },

    "sifOpasnostiStetnosti": {
      "brisanjeHeader": "Брисање опасности",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану опасност?",
      "brisanjeUspesno": "Успешно сте обрисали одабрану опасност!",
      "dodavanjeHeader": "Додавање опасности",
      "dodavanjeUspesno": "Успешно сте додали опасност!",
      "azuriranjeHeader": "Ажурирање опасности",
      "azuriranjeUspesno": "Успешно сте ажурирали опасност!",
      "inputNaziv": "Назив",
      "inputAktivno": "Активно",
      "izabraniNivoImaDecu": "Изабрани ниво се не може избрисати јер је за њега везан барем један нижи ниво.",
      "aktiviranjeUspesno": "Опасност активирана",
      "deaktiviranjeUspesno": "Опасност деактивирана"
    },

    "sifOpremaZaRad": {
      "brisanjeHeader": "Брисање опреме за рад",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану опрему за рад?",
      "brisanjeUspesno": "Успешно сте обрисали опрему за рад!",
      "dodavanjeHeader": "Додавање опреме за рад",
      "dodavanjeUspesno": "Успешно сте додали опрему за рад!",
      "azuriranjeHeader": "Ажурирање опреме за рад",
      "azuriranjeUspesno": "Успешно сте ажурирали опрему за рад!",
      "inputVrsta": "Врста",
      "inputFabrickiBroj": "Фабрички број",
      "inputGodinaProizvodnje": "Година производње",
      "inputLokacijaUStrukturi": "Локација у структури",
      "inputLokacijaBlize": "Локација(ближе)",
      "inputNamena": "Намена",
      "namena": "Намена",
      "vrsta": "Врста",
      "fabrickiBroj": "Фабрички број",
      "godinaProizvodnje": "Година производње",
      "lokacijaUStrukturi": "Локација у структури",
      "lokacijaPlaceholder": "Изаберите локацију из структуре",
      "lokacijaHint": "Организациона јединица није изабрана!"
    },

    "sifLzoVrsteVelicina": {
      "brisanjeHeader": "Брисање величине за ЛЗО",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану величину за ЛЗО?",
      "brisanjeUspesno": "Успешно сте обрисали одабрану величину за ЛЗО!",
      "dodavanjeHeader": "Додавање нове величине за ЛЗО",
      "dodavanjeUspesno": "Успешно сте додали величину за ЛЗО!",
      "azuriranjeHeader": "Ажурирање величине за ЛЗО",
      "azuriranjeUspesno": "Успешно сте ажурирали величину за ЛЗО!",
      "inputImeVrstaVelicine": "Име врсте величине",
      "inputImeVelicina": "Име величине",
      "inputPozicija": "Позиција",
      "porukaDodavanjeNove": "Додавање нове величине за ",
      "izabraniNivoImaDecu": "Изабрани ниво се не може избрисати јер је за њега везан барем један нижи ниво.",
      "vrstaVelicine": "Врста величине",
      "velicina": "Величина",
      "lzoVelicine": "Величине за ЛЗО"
    },

    "sifLzoKategorija": {
      "nemaVrstuVelicine": "Нема врсту величине",
      "kategorijaLZO": "Категорија ЛЗО",
      "kolicina": "Количина",
      "dodavanjeHeader": "Додавање категорије ЛЗО",
      "dodavanjeUspesno": "Успешно сте додали категорију ЛЗО!",
      "brisanjeHeader": "Брисање категорије ЛЗО",
      "brisanjeUspesno": "Успешно сте обрисали категорију ЛЗО!"
    },

    "sifLzoTree": {
      "LZO": "ЛЗО",
      "dodavanjeHeader": "Додавање ЛЗО",
      "dodavanjeUspesno": "Успешно сте додали ЛЗО!",
      "brisanjeHeader": "Брисање ЛЗО",
      "brisanjeUspesno": "Успешно сте обрисали ЛЗО!",
      "povezivanjeRadnihMesta": "Повезивање радних места са ЛЗО",
      "postojiVelicinaSaOdabranimRednimBrojem": "Већ постоји величина са одабраним редним бројем!",
      "imaUputstvo": "Има упутство",
      "imaAtest": "Има атест",
      "ucitanoUputstvo": "Учитано упутство",
      "ucitanAtest": "Учитан атест",
      "rokMeseci": "Рок (месеци)",
      "intervalMeseci": "Интервал (месеци)"
    },

    "sifLzo": {
      "tab2": {
        "createValidationMsg": "Морате изабрати радно место и категорију ЛЗО!",
        "updateValidationMsg": "Морате изабрати категорију ЛЗО!",
        "radnaMesta": "Радна места",
        "kategorijeLicneZastitneOpreme": "Категорије личне заштитне опреме",
        "radnoMesto": "Радно место",
        "radnoMestoStruktura": "Радно место структура",
        "dodavanjeKategorijeLzo": "Додавање категорије ЛЗО",
        "lzoKategorija": "ЛЗО категорија:",
        "organizacionaJedinica": "Организациона јединица",
        "odaberiteRadnoMesto": "Одаберите радно место",
        "odaberiteRadnoMestoStruktura": "Одаберите радно место у структури",
        "nazivKategorijeLzo": "Назив категорије ЛЗО",
        "dodeljeneKategorijeLzo": "Додељене категорије ЛЗО",
        "dodavanjeHeader": "Додавање категорије ЛЗО",
        "dodavanjeUspesno": "Категорија ЛЗО је успешно додата",
        "brisanjeKategorijePitanje": "Да ли сте сигурни да желите да обришете изабрану категорију ЛЗО?",
        "brisanjeKategorijeUspesno": "Успешно сте обрисали изабрани податак!",
        "brisanjeKategorijeHeader": "Брисање категорије ЛЗО",
        "brisanjeViewPitanje": "Да ли сте сигурни да желите да обришете изабрани податак?",
        "brisanjeViewHeader": "Потврда брисања",
        "brisanjeViewUspesno": "Веза између радног места и ЛЗО категорије је успешно обрисана!"
      },
      "izvestaji": {
        "lzoKojiJePotrebnoNabaviti": "ЛЗО који је потребно набавити",
        "rokZaLzoIstice": "Рок за ЛЗО истиче",
        "rokTrajanjaJeIstekao": "Рок трајања је истекао",
        "nedodeljeniLzo": "Недодељени ЛЗО",
        "narednogMeseca": "наредног месеца",
        "uNarednaDvaMeseca": "у наредна два месеца",
        "uNarednaTriMeseca": "у наредна три месеца",
        "prikaziZaposleneSortiranePo": "Прикажи запослени сортиране по",
        "imenuIPrezimenu": "Имену и презимену",
        "evidencionomBroju": "евиденционом броју",
        "internomMaticnojBroju": "ИМБ-у"
      }
    },

    "toolbars": {
      "elementiPregleda": "Елементи прегледа",
      "obuke": "Обуке",
      "pregledi": "Прегледи",
      "lzoKategorije": "Категорије за ЛЗО",
      "dodajLzoKategoriju": "Додај категорију за ЛЗО",
      "lzoVelicine": "Величине за ЛЗО",
      "uputstvoNaSrpskom": "Упутство на српском",
      "atest": "Атест",
      "dodaj": "Додај",
      "preuzmi": "Преузми",
      "obrisi": "Обриши",
      "uputstvoPostoji": "Упутство постоји",
      "uputstvoNePostoji": "Упутство не постоји",
      "atestPostoji": "Атест постоји",
      "atestNePostoji": "Атест не постоји",
      "aktiviraj": "Активирај",
      "deaktiviraj": "Деактивирај",
      "vrstaVelicineZaLzo": "Додај врсту величине",
      "grupnoDodavanjePregleda": "Групно додавање прегледа",
      "grupnoDodavanjeObuke": "Групно додавање обуке",
      "korisnikRola": "Корисничке роле",
      "resetLozinke": "Ресет лозинке"
    },

    "sifLicnaZastitnaOprema": {
      "aktiviraj": "Активирај",
      "deaktiviraj": "Деактивирај",
      "azuriranjeHeader": "Ажурирање личне заштитне опреме",
      "azuriranjeUspesno": "Успешно сте ажурирали личну заштитну опрему",
      "inputNaziv": "Назив",
      "inputKategorija": "Категорија",
      "inputRokTrajanja": "Рок трајања (у месецима)",
      "inputIntervalIspitivanja": "Интервал испитивања (у месецима)",
      "dodavanjeHeader": "Додавање личне заштитне опреме",
      "dodavanjeUspesno": "Успешно сте додали личну заштитну опрему",
      "inputVrsteVelicinaZaLzo": "Врсте величина за ЛЗО"
    },

    "sifRevers": {
      "dodeljenaLZO": "Додељена ЛЗО",
      "nijeDodeljenaLZO": "Лицу није додељена сва потребна ЛЗО",
      "datum": "Датум"
    },

    "sysPravnoLice": {
      "izborPravnogLicaHeader": "Избор правног лица",
      "inputPravnoLice": "Правно лице"
    },

    "sysPotpisnik": {
      "odaberitePotpisnika": "Одаберите потписника",
      "odabirPotpisnika": "Одабир потписника",
      "odabirPotpisnikaUspesno": "Успешно сте одабрали потписника!"
    },

    "fileUpload": {
      "dodavanjeHeader": "Додавање фајла",
      "dodavanjeUspesno": "Успешно сте додали фајл",
      "odaberiteFajl": "Одаберите фајл",
      "brisanjeHeader": "Брисање фајла",
      "brisanjeUspesno": "Успешно сте обрисали фајл"
    },

    "validationMsg": {
      "required": "Морате попунити ово поље!",
      "email": "Морате унети валидну е-маил адресу!",
      "date": "Морате унети валидан датум!",
      "min": "Минимална могућа вредност је ",
      "max": "Максимална могућа вредност је ",
      "minlength": "Минимална дужина овог поља је ",
      "maxlength": "Максимална дужина овог поља је ",
      "number": "Морате унети број!",
      "jmbg": "Морате унети валидан ЈМБГ!",
      "characters": " карактера!",
      "positive": "Морате унети број већи од 0!",
      "required_maxlength": "Морате попунити ово поље, максимална дужина поља је ",
      "year": "Морате унети годину!",
      "mb": "Морате унети 8 цифара!",
      "undefined": "Поље није у исправном формату!"
    },

    "obrazac1": {
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "radnoMestoSaRizikom": "Радно место са повећаним ризиком",
      "opasnostiStetnosti": "Опасност и штетност",
      "nivoRizika": "Ниво ризика",
      "napomena": "Напомена",
      "azuriranjeRadnogMestaHeader": "Ажурирање радног места",
      "azuriranjeRadnogMestaUspesno": "Успешно сте ажурирали радно место!",
      "dodavanjeHeader": "Додавање опасности/штетности за радно место",
      "dodavanjeUspesno": "Успешно сте додали опасност/штетност радном месту!",
      "azuriranjeHeader": "Ажурирање опасности/штетности за радно место",
      "azuriranjeNeuspesno": "Успешно сте ажурирали опасност/штетност!",
      "brojOpasnosti": "Број опасности",
      "brojZaposlenih": "Број запослених"
    },

    "obrazac1.izvestaji": {
      "radnaMesta": "Сва радна места",
      "radnaMestaSaRizikom": "Радна места са повећаним ризиком",
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "sviNivoiStrukture": "Сви нивои структуре",
      "sveOpasnostiStetnosti": "Све опасности и штетности",
      "sviNivoiRizika": "Сви нивои ризика",
      "nivoRizikaManjiOd": "Ниво ризика мањи од",
      "nivoRizikaVeciOd": "Ниво ризика већи од",
      "nivoRizikaJednak": "Ниво ризика једнак",
      "grupisiIzvestajPo": "Групиши извештај по:",
      "nivoimaRizika": "Нивоима ризика",
      "opasnostimaStetnostima": "Опасностима и штетностима",
      "organizacionojStrukturi": "Организационој структури",
      "radnimMestima": "Радним местима",
      "headerStruktura": "Структура:",
      "headerRadnaMesta": "Радна места:",
      "headerNivoiRizika": "Нивои ризика:",
      "headerOpasnostiStetnosti": "Опасности штетности:"
    },

    "obrazac2.izvestaji": {
      "radnaMesta": "Сва радна места",
      "radnaMestaSaRizikom": "Радна места са повећаним ризиком",
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "sviNivoiStrukture": "Сви нивои структуре",
      "grupisiIzvestajPo": "Групиши извештај по:",
      "organizacionojStrukturi": "Организационој структури",
      "radnimMestima": "Радним местима",
      "headerStruktura": "Структура:",
      "headerRadnaMesta": "Радна места:",
      "vrstePregleda": "Врсте прегледа:",
      "sviPregledi": "Сви прегледи",
      "nemaPreglede": "Нема прегледе",
      "imaPreglede": "Има прегледе",
      "headerZaposleni": "Запослени:",
      "headerStatus": "Статус:",
      "zaposlenima": "Запосленима",
      "headerLekarskiPregledi": "Лекарски прегледи:",
      "lekarskiPreglediKojiTrebaDaSeObave": "Прегледи који треба да се обаве",
      "obavljeniPregledi": "Обављени прегледи",
      "neobavljeniPregledi": "Необављени прегледи",
      "lekarskiPreglediUIntervalu": "Прегледи у интервалу",
      "labelPocetak": "Почетак:",
      "labelKraj": "Крај:",
      "narednogMeseca": "наредног месеца",
      "uNarednaDvaMeseca": "у наредна два месеца",
      "uNarednaTriMeseca": "у наредна три месеца",
      "izborZaposlenog": "Избор запосленог",
      "odaberiteZaposlenog": "Одаберите запосленог"
    },

    "obrazac3.izvestaji": {
      "headerZaposleni": "Запослени:",
      "headerStruktura": "Структура:",
      "headerMestoPovrede": "Место повреде:",
      "headerRadnaMesta": "Радна места:",
      "headerIzvorPovrede": "Извор повреде:",
      "headerUzrokPovrede": "Узрок повреде:",
      "headerVrstaIzvestaja": "Врста извештаја:",
      "headerGrupisiIzvestajPo": "Групиши извештај по:",
      "naSvimMestima": "На свим местима",
      "radnaMesta": "Сва радна места",
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "sviIzvoriPovreda": "Сви извори повреда",
      "sviUzrociPovreda": "Сви узроци повреда",
      "radio1": "Без надгруписања",
      "radio2": "Да ли је у моменту повреде повређени радио на радном месту са повећаним ризиком",
      "radio3": "Да ли је повређени испуњавао прописане услове за рад на радном месту са повећаним ризиком",
      "radio4": "Да ли је повређени подвргнут претходном и периодичном лекарском прегледу",
      "radio5": "Да ли је повређени оспособљен за безбедан и здрав рад за послове на којима је дошло до повреде",
      "radio6": "Да ли је за послове на којим се десила повреда прописано коришћење средстава и опреме за личну заштиту на раду",
      "radio7": "Да ли су повређеном била обезбеђена средства и опрема за личну заштиту на раду",
      "radio8": "Да ли је у време повређивања повређени користио обезбеђена средства и опрему за личну заштиту на раду",
      "sumarni": "Сумарни",
      "detaljan": "Детаљан",
      "radnimMestima": "Радним местима",
      "organizacionojStrukturi": "Организационој структури",
      "zaposlenima": "Запосленима",
      "generisi": "Генериши",
      "odaberiteZaposlenog": "Одаберите запосленог",
      "sviNivoiStrukture": "Сви нивои структуре",
      "izborZaposlenog": "Избор запосленог"
    },

    "obrazac4.izvestaji": {
      "radnaMesta": "Сва радна места",
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "sviNivoiStrukture": "Сви нивои структуре",
      "svaOboljenja": "Сва обољења",
      "sviNivoiRadneSposobnosti": "Сви нивои радне способности",
      "radnaSposobnostManjaOd": "Радна способност мања од",
      "radnaSposobnostVecaOd": "Радна способност већа од",
      "radnaSposobnostJednaka": "Радна способност једнака",
      "grupisiIzvestajPo": "Групиши извештај по:",
      "nivoimaRizika": "Нивоима ризика",
      "zaposlenima": "Запосленима",
      "organizacionojStrukturi": "Организационој структури",
      "radnimMestima": "Радним местима",
      "headerStruktura": "Структура:",
      "headerRadnaMesta": "Радна места:",
      "headerDijagnoza": "Дијагноза:",
      "headerPreostalaRadnaSposobnost": "Преостала радна способност:"
    },

    "obrazac5.izvestaji": {
      "radnaMesta": "Сва радна места",
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "sviNivoiStrukture": "Сви нивои структуре",
      "svaOboljenja": "Сва обољења",
      "sviNivoiRadneSposobnosti": "Сви нивои радне способности",
      "radnaSposobnostManjaOd": "Радна способност мања од",
      "radnaSposobnostVecaOd": "Радна способност већа од",
      "radnaSposobnostJednaka": "Радна способност једнака",
      "grupisiIzvestajPo": "Групиши извештај по:",
      "nivoimaRizika": "Нивоима ризика",
      "zaposlenima": "Запосленима",
      "organizacionojStrukturi": "Организационој структури",
      "radnimMestima": "Радним местима",
      "headerStruktura": "Структура:",
      "headerRadnaMesta": "Радна места:",
      "headerMedjunarodnaSifraOboljenja": "Међународна шифра обољења:",
      "headerPreostalaRadnaSposobnost": "Преостала радна способност:"
    },

    "obrazac6.izvestaji": {
      "radnaMesta": "Сва радна места",
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "sviNivoiStrukture": "Сви нивои структуре",
      "grupisiIzvestajPo": "Групиши извештај по:",
      "organizacionojStrukturi": "Организационој структури",
      "radnimMestima": "Радним местима",
      "headerStruktura": "Структура:",
      "headerRadnaMesta": "Радна места:",
      "headerZaposleni": "Запослени:",
      "headerStatus": "Статус:",
      "zaposlenima": "Запосленима",
      "labelPocetak": "Почетак:",
      "labelKraj": "Крај:",
      "nemaObuku": "Нема обуку",
      "obukaZavrsena": "Обука завршена",
      "obukaUIntervalu": "Обука у интервалу",
      "imaObuku": "Има обуку",
      "headerVrstaObuke": "Врста обуке:",
      "sveObuke": "Све обуке",
      "izborZaposlenog": "Избор запосленог",
      "odaberiteZaposlenog": "Одаберите запосленог"
    },

    "obrObrazac02": {
      "uput": "Упут",
      "novUputPrethodni": "Нов упут за претходни преглед",
      "novUputPeriodicni": "Нов упут за периодични преглед",
      "pregledIIzmena": "Преглед и Измена",
      "preuzmi": "Преузми",
      "pregled": "Преглед",
      "potpisanUput": "Потписан упут",
      "izvestajLekara": "Извештај лекара",
      "vrstaPregleda": "Врста прегледа",
      "datumUputa": "Датум упута",
      "datumPregleda": "Датум прегледа",
      "datumSledecegPregleda": "Следећи преглед",
      "datumPrethodnogPregleda": "Претходни преглед",
      "brojLekarskogIzvestaja": "Број извештаја",
      "ocenaSposobnosti": "Оцена способности",
      "mere": "Мере",
      "preduzeteMere": "Предузете мере",
      "sifra": "Шифра",
      "interval": "Интервал",
      "elementPregleda": "Елемент прегледа",
      "brojUputa": "Број упута",
      "imeIPrezime": "Име и презиме",
      "ime": "Име",
      "prezime": "Презиме",
      "radnoMesto": "Радно место",
      "organizacionaJedinica": "Орг. структура",
      "zanimanje": "Занимање",
      "maticniBroj": "Матични број",
      "kratakOpisPosla": "Кратак опис посла",
      "opasnostiIRizici": "Опасности и ризици",
      "potrebniZdravstveniUslovi": "Потребни здравствени услови",
      "infoOPoslednjemPregledu": "Информације о последњем лекарском прегледу",
      "nazivZdrUstanove": "Назив здравствене установе",
      "datum": "Датум",
      "brojIzvestaja": "Број извештаја",
      "brisanjeHeader": "Брисање здравственог прегледа",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани здравствени преглед?",
      "brisanjeUspesno": "Успешно сте обрисали одабрани здравствени преглед!",
      "dodavanjeHeader": "Додавање новог здравственог прегледа",
      "dodavanjeUspesno": "Успешно сте додали здравствени преглед!",
      "azuriranjeHeader": "Ажурирање здравственог прегледа",
      "azuriranjeUspesno": "Успешно сте ажурирали здравствени преглед!",
      "morateOdabratiElementePregleda": "Морате одабрати бар један елемент прегледа!",
      "nazivPregleda": "Назив прегледа",
      "organizacionaStruktura": "Организациона структура",
      "pregledi": "Прегледи",
      "napomena": "Напомена",
      "intervalPregleda": "Интервал прегледа",
      "grupnoDodavanjePregledaHeader": "Групно додавање прегледа",
      "grupnoDodavanjePregledaUspesno": "Успешно сте додали преглед изабраним запосленима!",
      "grupnoDodavanjePregledaNeuspesno": "Грешка приликом додавања прегледа запосленима!",
      "grupnoDodavanjeOrgJedinicaFilter": "Преглед запослених на основу организационе структуре",
      "grupnoDodavanjeRadnoMestoFilter": "Преглед запослених на основу радног места",
      "grupnoDodavanjeRadnoMestoStrukturaFilter": "Преглед запослених на основу радног места у структури",
      "grupnoDodavanjeZaposlenihFilter": "Преглед запослених по имену, презимену и евиденционом броју лица",
      "grupnoDodavanjeZaposleniBezPregledaFilter": "Преглед запослених који немају преглед",
      "grupnoDodavanjeZaposleniBezPregledaOption1": "Запослени без додељеног прегледа",
      "grupnoDodavanjeZaposleniBezPregledaOption2": "Запослени којима је преглед истекао",
      "grupnoDodavanjeZaposleniBezPregledaOption3": "Запослени без додељеног прегледа и запослени са истеклим прегледом"
    },

    "obrObrazac03": {
      "radnoMestoPovecaniRizik": "Радно место је евидентирано као радно место са повећаним ризиком",
      "radnoMestoNijePovecaniRizik": "Радно место није евидентирано као радно место са повећаним ризиком",
      "povredjeniNemaSveObuke": "Повређени нема све потребне кориснички дефинисане обуке",
      "povredjeniImaSveObuke": "Повређени има све потребне кориснички дефинисане обуке",
      "povredjeniNemaSvePreglede": "Повређени нема све потребне кориснички дефинисане прегледе",
      "povredjeniImaSvePreglede": "Повређени има све потребне кориснички дефинисане прегледе",
      "rmsImaLzo": "Коришћење личне заштитне опреме на радном месту је прописано",
      "rmsNemaLzo": "Коришћење личне заштитне опреме на радном месту није прописано",
      "povredaNaRadu": "Повреда на раду",
      "preuzmiPovredniList": "Преузми повредни лист",
      "preuzmiPovredniListA4": "Преузми повредни лист А4",
      "preuzmiPovredniListA3": "Преузми повредни лист А3",
      "id": "ИД",
      "imePrezime": "Име и презиме",
      "radnoMesto": "Радно место",
      "datumPovrede": "Датум повреде",
      "izvorPovrede": "Извор повреде",
      "uzrokPovrede": "Узрок повреде",
      "brisanjeHeader": "Брисање податка",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани податак?",
      "brisanjeUspesno": "Успешно сте обрисали изабрани податак!",
      "povredniList": {
        "naslov": "ИЗВЕШТАЈ О ПОВРЕДИ НА РАДУ",
        "podaciOPoslodavcu": "1. ПОДАЦИ О ПОСЛОДАВЦУ",
        "obveznikPodnosenjaPoslodavac": "1.Обвезник подношења <br>&nbsp;&nbsp;&nbsp; о повреди на раду<br>&nbsp;&nbsp;&nbsp;(послодавац)",
        "punNazivPoslodavca": "Пун назив послодавца (пословно име, односно лично име или други назив под којим предузетник обавља делатност)",
        "sedisteAdresaPoslodavca": "Седиште и адреса послодавца(улица,<br>и број, општина, место, држава)",
        "regBrojObveznika": "Рег. број обвезника доприноса",
        "pib": "ПИБ",
        "sifraDelatnosti": "Шифра делатности",
        "ukupnoZaposlenih": "Укупно запослених",
        "podaciOBZRLicuPoslodavac": "2. ПОДАЦИ О ЛИЦУ ОДРЕЂЕНОМ ЗА БЕЗБЕДНОСТ И ЗДРАВЉЕ НА РАДУ<br>КОД ПОСЛОДАВЦА <br/>",
        "imePrezime": "Име и презиме",
        "jmbg": "ЈМБГ",
        "adresaPrebivalista": "Адреса пребивалишта",
        "podaciOPovredjenom": "ПОДАЦИ О ПОВРЕЂЕНОМ",
        "polMZ": "Пол (мушки - женски)",
        "danMesecGodRodjenja": "Дан, месец и година рођења",
        "prebivaliste": "Пребивалиште",
        "ulicaIBroj": "Улица и број",
        "mestoOpstina": "Место, општина",
        "drzava": "Држава",
        "vrstaStepenSS": "Врста и степен стручне спреме повређеног",
        "vrstaStepenPropisaneSS": "Врста и степен стручне спреме прописане за обављање послова<br>&nbsp;&nbsp;&nbsp;на којима је повређени претрпео повреду на раду",
        "statusPovredjenog": "Статус повређеног",
        "podaciONeposrednomRukovodiocu": "ПОДАЦИ О НЕПОСРЕДНОМ РУКОВОДИОЦУ ПОВРЕЂЕНОГ",
        "posloviKojeObavlja": "Послови које обавља",
        "radnoMestoNastupanjePovrede": "ПОДАЦИ О РАДНОМ МЕСТУ И ПОСЛОВИМА ПОВРЕЂЕНОГ И ВРЕМЕНУ<br>И МЕСТУ НАСТУПАЊА ЊЕГОВЕ ПОВРЕДЕ НА РАДУ <br>",
        "radnoMestoPovredjeniRasporedjen": "Радно место на које је повређени распоређен",
        "labela24": "Посао који је обављао",
        "labela25": "Радно искуство повређеног на пословима на којима се повредио",
        "labela26": "Укупан пензијски стаж повређеног",
        "labela27": "Радно време повређеног у сатима (дневни просек)",
        "labela28": "Да ли је повређени раније претрпео повреду на раду и колико пута",
        "labela29": "Време наступања повреде",
        "labela30": "Дан у седмици",
        "labela31": "Доба дана - сат (0-24)",
        "labela32": "Радни сат од почетка рада",
        "labela33": "Назив места са адресом где се повреда догодила са ближом<br>&nbsp;&nbsp;&nbsp;&nbsp;идентификацијом локације (објекат, градилиште, складиште и сл)",
        "datum": "Датум",
        "mesto": "Место",
        "labela36-41": "Место повреде",
        "labela41": "Остало",
        "MP": "М.П.",
        "redBrojIzEvidencije": "Ред. бр. из евиденције о повредама на раду",
        "potpisOdgovornogLica": "ПОТПИС ОДГОВОРНОГ ЛИЦА",
        "labela45": "Колико је укупно запослених повређено на раду <br>&nbsp;&nbsp;&nbsp;&nbsp; (заједно са повређеним)",
        "labela46": "Колико је укупно погинулих на месту догађаја<br>&nbsp;&nbsp;&nbsp;&nbsp; односно умрло на путу до здравствене установе",
        "labela47": "Да ли се слична повреда десила раније на истим пословима<br>&nbsp;&nbsp;&nbsp;&nbsp; - задацима и када",
        "labela48": "Извор повреде - материјални узрочник (међународна шифра)",
        "labela49": "Узрок повреде - начин повређивања (међународна шифра)",
        "labela50": "Кратак опис настанка повреде",
        "labela51": "Да ли је у моменту повреде повређени радио на радном месту<br>&nbsp;&nbsp;&nbsp;&nbsp; са повећаним ризиком",
        "labela52": "Да ли је повређени испуњавао прописане услове за рад<br>&nbsp;&nbsp;&nbsp;&nbsp; на радном месту са повећаним ризиком",
        "labela53": "Да ли је повређени подвргнут претходном и периодичном<br>&nbsp;&nbsp;&nbsp;&nbsp; лекарском прегледу",
        "labela54": "Да ли је повређени оспособљен за безбедан и здрав рад<br>&nbsp;&nbsp;&nbsp;&nbsp; за послове на којима је дошло до повреде",
        "labela55": "Да ли је за послове на којима се десила повреда прописано<br>&nbsp;&nbsp;&nbsp;&nbsp; коришћење средстава и опреме за личну заштиту на раду",
        "labela56": "Да ли су повређеном била обезбеђена средства и опрема<br>&nbsp;&nbsp;&nbsp;&nbsp; за личну заштиту на раду",
        "labela57": "Да ли је у време повређивања повређени користио обезбеђена<br>&nbsp;&nbsp;&nbsp;&nbsp; средства и опрему за личну заштиту на раду",
        "labela58": "Навести које су мере безбедности и здравља на раду биле<br>&nbsp;&nbsp;&nbsp;&nbsp;спроведене на пословима где је дошло до повреде",
        "labela59": "Назив и адреса здравствене установе у којој је повређени прегледан",
        "labela60": "Име и презиме лекара који је прегледао повређеног",
        "labela61": "Природа и локализација повреде - дијагноза (уписати назив<br>&nbsp;&nbsp;&nbsp;&nbsp; и шифру дијагнозе по МКБ10, од најтеже ка најлакшој)",
        "labela62": "Спољни узрок повреде по класификацији",
        "labela63": "Да ли је повреда са смртним исходом (на месту повреде,<br>&nbsp;&nbsp;&nbsp;&nbsp; односно на путу до здравствене установе)",
        "labela64": "Оцена тежине повреде (лака - тешка)",
        "labela65": "Да ли је повређени пре повређивања имао телесних<br>&nbsp;&nbsp;&nbsp;&nbsp; или психичких повреда или обољења и којих",
        "labela66": "Друге примедбе лекара (да ли је повређени под утицајем алкохола и сл.)",
        "labela67": "Да ли је повређени спречен за рад више од три узастопна радна дана",
        "podaciOOcevicu": "ПОДАЦИ О ОЧЕВИЦУ",
        "labelaNaslov7": "ПОДАЦИ О ПОВРЕДИ НА РАДУ И МЕРАМА БЕЗБЕДНОСТИ И ЗДРАВЉА НА РАДУ<br>КОЈЕ СУ ПРИМЕЊИВАНЕ НА РАДНОМ МЕСТУ НА КОЈЕМ ЈЕ ПОВРЕЂЕНИ РАДИО",
        "labelaNaslov8": "НАЛАЗ И МИШЉЕЊЕ ЛЕКАРА КОЈИ ЈЕ ПРВИ ПРЕГЛЕДАО ПОВРЕЂЕНОГ",
        "redniBrojLekarskogDnevnika": "Редни број лекарског дневника",
        "redniBrojUKnjiziEvidencije": "Редни број у књизи евиденције здравствене установе",
        "potpisLekara": "ПОТПИС ЛЕКАРА",
        "orgZdravstvenogOsiguranja": "ОРГАНИЗАЦИЈА ЗДРАВСТВЕНОГ ОСИГУРАЊА",
        "redBrIzDnevnika": "Ред. бр. из дневника"

      }

    },

    "obrObrazac03.sacuvani": {
      "povredniList": {
        "imePrezimePovredjenog": "Име и презиме повређеног",
        "datumPovrede": "Датум повреде",
        "vremePovrede": "Време повреде",
        "vrstaPovrede": "Врста повреде",
        "ocenaTezinePovrede": "Оцена тежине повреде",
        "skeniran": "Скениран"
      }
    },

    "obrObrazac04": {
      "imePrezime": "Име и презиме",
      "radnoMesto": "Радно место",
      "dijagnoza": "Дијагноза",
      "zdravstvenaUstanova": "Здравствена установа",
      "stepenOstecenja": "Степен оштећења",
      "preostalaSposobnost": "Преостала способност",
      "inputDijagnoza": "Дијагноза",
      "inputZdravstvenaUstanova": "Здравствена установа",
      "inputStepenOstecenja": "Степен оштећења",
      "inputPreostalaSposobnost": "Преостала способност",
      "brisanjeHeader": "Брисање податка",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани податак?",
      "brisanjeUspesno": "Успешно сте обрисали изабрани податак!",
      "dodavanjeHeader": "Додавање новог податка",
      "dodavanjeUspesno": "Успешно сте додали податак!",
      "azuriranjeHeader": "Ажурирање податка",
      "azuriranjeUspesno": "Успешно сте ажурирали податак!"
    },

    "obrObrazac05": {
      "imePrezime": "Име и презиме",
      "radnoMesto": "Радно место",
      "dijagnoza": "Дијагноза",
      "zdravstvenaUstanova": "Здравствена установа",
      "stepenOstecenja": "Степен оштећења",
      "preostalaSposobnost": "Преостала способност",
      "inputDijagnoza": "Дијагноза",
      "inputZdravstvenaUstanova": "Здравствена установа",
      "inputStepenOstecenja": "Степен оштећења",
      "inputPreostalaSposobnost": "Преостала способност",
      "brisanjeHeader": "Брисање податка",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани податак?",
      "brisanjeUspesno": "Успешно сте обрисали изабрани податак!",
      "dodavanjeHeader": "Додавање новог податка",
      "dodavanjeUspesno": "Успешно сте додали податак!",
      "azuriranjeHeader": "Ажурирање податка",
      "azuriranjeUspesno": "Успешно сте ажурирали податак!"
    },

    "obrObrazac06": {
      "sveObuke": "Све обуке",
      "obuka": "Обука",
      "dijagnoza": "Дијагноза",
      "zdravstvenaUstanova": "Здравствена установа",
      "stepenOstecenja": "Степен оштећења",
      "preostalaSposobnost": "Преостала способност",
      "inputDijagnoza": "Дијагноза",
      "brisanjeHeader": "Брисање обуке",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану обуку?",
      "brisanjeUspesno": "Успешно сте обрисали изабрану обуку!",
      "dodavanjeHeader": "Додавање нове обуке",
      "dodavanjeUspesno": "Успешно сте додали обуку!",
      "azuriranjeHeader": "Ажурирање обуке",
      "azuriranjeUspesno": "Успешно сте ажурирали обуку!",
      "potpisana": "Потписана",
      "teoretskaObuka": "Теоретска обука",
      "prakticnaObuka": "Практична обука",
      "teoretskaProvera": "Теоретска провера",
      "prakticnaProvera": "Практична провера",
      "razlogOsposobljavanja": "Разлог оспособљавања",
      "opisPoslovaNaRadnomMestu": "Опис послова на радном месту",
      "razloziOsposobljavanjaHeader": "Разлози извршеног оспособљавања особља за безбедан и здрав рад",
      "rizici": "Ризици са којима је запослени упознат",
      "obavestenja": "Обавештења, упутства и инструкције",
      "mere": "Конкретне мере за безбедан и здрав рад",
      "zaposleniPotpisaoObrazac": "Запослени је потписао образац",
      "morateOdabratiRazlogOsposobljavanja": "Морате одабрати бар један разлог оспособљавања!",
      "grupnoDodavanjeZaposleniBezObukeOption1": "Запослени који немају ниједну обуку",
      "grupnoDodavanjeZaposleniBezObukeOption2": "Запослени који немају дату обуку",
      "datumUputa": "Датум упута",
      "datumObuke": "Датум обуке",
      "datumSledeceObuke": "Следећа обука",
      "sifra": "Шифра",
      "interval": "Интервал",
      "imeIPrezime": "Име и презиме",
      "ime": "Име",
      "prezime": "Презиме",
      "radnoMesto": "Радно место",
      "zanimanje": "Занимање",
      "maticniBroj": "Матични број",
      "kratakOpisPosla": "Кратак опис посла",
      "opasnostiIRizici": "Опасности и ризици",
      "potrebniZdravstveniUslovi": "Потребни здравствени услови",
      "infoOPoslednjemPregledu": "Информације о последњем лекарском прегледу",
      "nazivZdrUstanove": "Назив здравствене установе",
      "datum": "Датум",
      "nazivObuke": "Назив обуке",
      "organizacionaStruktura": "Организациона структура",
      "obuke": "Обуке",
      "napomena": "Напомена",
      "intervalObuke": "Интервал обуке",
      "skeniran": "Скениран",
      "grupnoDodavanjeZaposleniBezObukeFilter": "Преглед запослених који немају обуку",
      "grupnoDodavanjeObukeHeader": "Групно додавање обуке",
      "grupnoDodavanjeObukeUspesno": "Успешно сте додали обуку изабраним запосленима",
      "grupnoDodavanjeObukeGreska": "Грешка приликом додавања обуке запосленима!",
      "grupnoDodavanjeZaposleniOption1": "Запослени без додељене обука",
      "grupnoDodavanjeZaposleniOption2": "Запослени којима је обука истекла",
      "grupnoDodavanjeZaposleniOption3": "Запослени без додељене обуке и запослени са истеклом обуком"
    },

    "obrObrazac07": {
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место структура",
      "organizacionaJedinica": "Организациона структура",
      "brojEvidentiranihOpasnihMaterija": "Број евидентираних опасних материја",
      "brisanjeHeader": "Брисање радног места",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрано радно место?",
      "brisanjeUspesno": "Успешно сте обрисали изабрано радно место!",
      "dodavanjeHeader": "Додавање новог радног места",
      "dodavanjeUspesno": "Успешно сте додали радно место!",
      "azuriranjeHeader": "Ажурирање опасних материја на радним местима",
      "azuriranjeUspesno": "Успешно сте ажурирали опасне материје на радним местима!",
      "evidencijaOpasnihMaterija": "Евиденција опасних материја на радним местима",
      "dodavanjeAzuriranjeOpasneMaterije": "Евиденција опасне материје",
      "inputOpasnaMaterija": "Опасна материја",
      "inputNacinUpotrebe": "Начин употребе",
      "inputNapomena": "Напомена",
      "inputDnevnaKolicina": "Дневна количина",
      "brisanjeOpasnaMaterijaHeader": "Брисање опасне материје",
      "brisanjeOpasnaMaterijaPitanje": "Да ли сте сигурни да желите да обришете изабрану опасну материју?",
      "brisanjeOpasnaMaterijaUspesno": "Успешно сте обрисали изабрану опасну материју!",
      "dodavanjeOpasnaMaterijaHeader": "Додавање нове опасне материје",
      "dodavanjeOpasnaMaterijaUspesno": "Успешно сте додали опасну материју!",
      "azuriranjeOpasnaMaterijaHeader": "Ажурирање опасних материја",
      "azuriranjeOpasnaMaterijaUspesno": "Успешно сте ажурирали опасне материје!",
      "redniBroj": "Редни број",
      "korisnik": "Корисник",
      "datum": "Датум",
      "vreme": "Време",
      "skeniran": "Скениран"
    },

    "obrazac07.izvestaji": {
      "headerStruktura": "Структура:",
      "headerRadnaMesta": "Радна места:",
      "headerZaposleni": "Запослени:",
      "headerNazivOpasneMaterije": "Назив опасне материје:",
      "headerKlasaOpasneMaterije": "Класа опасне материје:",
      "headerGrupisiIzvestajPo": "Групиши извештај по:",
      "sviNivoiStrukture": "Сви нивои структуре",
      "radnaMesta": "Сва радна места",
      "radnoMesto": "Радно место",
      "radnoMestoStruktura": "Радно место у структури",
      "odaberiteZaposlenog": "Одаберите запосленог",
      "nazivOpasneMaterije": "Назив опасне материје:",
      "klasaOpasneMaterije": "Класа опасне материје:",
      "odaberiteSveOpasneMaterije": "Све опасне материје",
      "odaberiteSveKlaseOpasnihMaterija": "Све класе опасних материја",
      "radnimMestima": "Радним местима",
      "organizacionojStrukturi": "Организационој структури",
      "zaposlenima": "Запосленима",
      "nazivimaOpasnihMaterija": "Називима опасних материја",
      "izborZaposlenog": "Избор запосленог"
    },

    "obrObrazac08": {
      "ispitivaniParametar": "Испитивани параметар",
      "datumIspitivanja": "Датум испитивања",
      "datumSledecegIspitivanja": "Датум следећег испитивања",
      "napomena": "Напомена",
      "brojIspitivanja": "Број испитивања",
      "brisanjeHeader": "Брисање испитивања параметра околине",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрано испитивање параметра околине?",
      "brisanjeUspesno": "Успешно сте обрисали изабрано испитивање параметра околине!",
      "dodavanjeHeader": "Додавање новог испитивања параметра околине",
      "dodavanjeUspesno": "Успешно сте додали испитивање параметра околине!",
      "azuriranjeHeader": "Ажурирање испитивања параметра околине",
      "azuriranjeUspesno": "Успешно сте ажурирали испитивање параметра околине!",
      "evidencijaIspitivanjaRadneOkoline": "Евиденција испитивања радне околине"
    },

    "obrazac08.izvestaji": {
      "headerParametri": "Параметри:",
      "headerIspitivanja": "Испитивања:",
      "sviParametri": "Сви параметри",
      "ispitivanjaKojaTreba": "Испитивања која треба да се обаве",
      "narednogMeseca": "наредног месеца",
      "uNarednaDvaMeseca": "у наредна два месеца",
      "uNarednaTriMeseca": "у наредна три месеца",
      "obavljenaIspitivanja": "Обављена испитивања",
      "neobavljenaIspitivanja": "Необављена испитивања"
    },

    "obrObrazac09": {
      "nazivIBrojOpreme": "Назив и број опреме",
      "lokacija": "Локација",
      "brojNalaza": "Број налаза",
      "datumPregleda": "Датум прегледа",
      "datumSledecegPregleda": "Датум следећег прегледа",
      "napomena": "Напомена",
      "evidencijaPregledaOpreme": "Евиденција прегледа опреме",
      "brisanjeHeader": "Брисање прегледа опреме",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани преглед опреме?",
      "brisanjeUspesno": "Успешно сте обрисали изабрани преглед опреме!",
      "dodavanjeHeader": "Додавање новог прегледа опреме",
      "dodavanjeUspesno": "Успешно сте додали преглед опреме!",
      "azuriranjeHeader": "Ажурирање прегледа опреме",
      "azuriranjeUspesno": "Успешно сте ажурирали преглед опреме!"
    },

    "obrazac09.izvestaji": {
      "headerOpremaZaRad": "Опрема за рад:",
      "headerStruktura": "Структура:",
      "headerIspitivanja": "Испитивања:",
      "svaOprema": "Сва опрема",
      "sviNivoiStrukture": "Сви нивои структуре",
      "ispitivanjaKojaTreba": "Испитивања која треба да се обаве",
      "narednogMeseca": "наредног месеца",
      "uNarednaDvaMeseca": "у наредна два месеца",
      "uNarednaTriMeseca": "у наредна три месеца",
      "svaIspitivanja": "Сва испитивања",
      "neobavljenaIspitivanja": "Необављена испитивања"
    },

    "obrObrazac10": {
      "brisanjeHeader": "Брисање личне заштитне општине",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану личну заштитну опрему?",
      "brisanjeUspesno": "Успешно сте обрисали личну заштитну опрему!",
      "dodavanjeHeader": "Додавање нове личне заштитне општине",
      "dodavanjeUspesno": "Успешно сте додали личну заштитну опрему!",
      "azuriranjeHeader": "Ажурирање личне заштитне опреме",
      "azuriranjeUspesno": "Успешно сте ажурирали личну заштитну опрему!",
      "licnaZastitnaOprema": "Лична заштитна опрема",
      "fabrickiBroj": "Фабрички број",
      "datumPregleda": "Датум прегледа",
      "datumSledecegPregleda": "Датум следећег прегледа",
      "datumNabavke": "Датум набавке",
      "vaziDo": "Важи до",
      "potpis": "Потпис",
      "napomena": "Напомена",
      "inputVrsta": "Врста",
      "inputDatumNabavke": "Датум набавке",
      "inputFabrickiBroj": "Фабрички број",
      "inputRok": "Рок",
      "inputGodinaProizvodnje": "Година производње",
      "inputVaziDo": "Важи до",
      "brisanjePreglediHeader": "Брисање прегледа",
      "brisanjePreglediPitanje": "Да ли сте сигурни да желите да обришете изабрани преглед?",
      "brisanjePreglediUspesno": "Успешно сте обрисали преглед!",
      "dodavanjePreglediHeader": "Додавање новог прегледа",
      "dodavanjePreglediUspesno": "Успешно сте додали преглед!",
      "azuriranjePreglediHeader": "Ажурирање прегледа",
      "azuriranjePreglediUspesno": "Успешно сте ажурирали преглед!",
      "lzoKojaPodlezePregledima": "ЛЗО која подлеже прегледима",
      "lzoKojaNePodlezePregledima": "ЛЗО која не подлеже прегледима",
      "novPregledLicneZastitneOpreme": "Нов преглед личне заштитне опреме",
      "evidencijaPregledaLicneZastitneOpreme": "Евиденција прегледа личне заштитне опреме"
    },

    "obrazac10.izvestaji": {
      "headerSredstva": "Средства и опрема за личну заштиту на раду",
      "headerLicnaZastitnaOprema": "Лична заштитна опрема",
      "headerGrupisiIzvestajPo": "Групиши извештај по:",
      "svaOprema": "Сва опрема",
      "lzoObavljenaIspitivanja": "ЛЗО - обављена испитивања",
      "lzoBezAtesta": "ЛЗО без атеста или упутства",
      "lzoIstekaoRokTrajanja": "ЛЗО којој је истекао рок трајања",
      "lzoIstekaoRokIspitivanja": "ЛЗО којој је истекао рок за испитивање или није испитана",
      "lzoIsticeRokTrajanja": "ЛЗО којој рок трајања истиче",
      "lzoIsticeRokIspitivanja": "ЛЗО којој рок за испитивање истиче",
      "uNarednih14Dana": "у наредних 14 дана",
      "uNarednih30Dana": "у наредних 30 дана",
      "uNarednih60Dana": "у наредних 60 дана",
      "opremiZaZastitu": "Опреми за заштиту",
      "vremenu": "Времену"
    },

    "obrObrazac11": {
      "imePrezime": "Име и презиме",
      "radnoMestoNaziv": "Радно место",
      "datumPregleda": "Датум прегледа",
      "ocenaPovrede": "Оцена повреде",
      "ocenaPovredeTeska": "Тешка",
      "ocenaPovredeLaka": "Лака",
      "vrstaPovrede": "Врста повреде",
      "vrstaPovredeKolektivna": "Колективна",
      "vrstaPovredePojedinacna": "Појединачна",

      "detailPrijava": "Пријава",
      "detailDatum": "Датум",
      "detailNadlezniOrgan": "Надлежни орган коме је пријава поднета",
      "detailNapomena": "Напомена",
      "detailInspekcijaRada": "Инспекција рада",
      "detailOUP": "ОУП",
      "detailSedisteNadleznogORgana": "Седиште надлежног органа<br/>коме је пријава однета",
      "detailLicePrimiloPrijavu": "Лице које је пријаву примило",
      "detailUsmeno": "Усмено",
      "detailPismeno": "Писмено",

      "usmenaPrijava": "Усмена пријава",
      "pismenaPrijava": "Писмена пријава",
      "datumPodnosenjaPrijave": "Датум подношења пријаве",
      "inspekcijaRadaSediste": "Инспекција рада - седиште",
      "inspekcijaRadaLice": "Инспекција рада - лице",
      "oupSediste": "ОПУ - седиште",
      "oupLice": "ОПУ - лице",
      "napomena": "Напомена",

      "snimanjeHeader": "Евиденција о пријавама повреда на раду",
      "snimanjeUspesno": "Успешно сте снимили пријаву повреде на раду"
    },

    "obrObrazac12": {
      "imePrezime": "Име и презиме",
      "radnoMestoNaziv": "Радно место",
      "dijagnoza": "Дијагноза",

      "detailPrijava": "Пријава",
      "detailDatum": "Датум",
      "detailNadlezniOrgan": "Надлежни орган коме је пријава поднета",
      "detailNapomena": "Напомена",
      "detailInspekcijaRada": "Инспекција рада",
      "detailSedisteNadleznogORgana": "Седиште надлежног органа<br/>коме је пријава однета",
      "detailLicePrimiloPrijavu": "Лице које је пријаву примило",
      "detailUsmeno": "Усмено",
      "detailPismeno": "Писмено",

      "usmenaPrijava": "Усмена пријава",
      "pismenaPrijava": "Писмена пријава",
      "datumPodnosenjaPrijave": "Датум подношења пријаве",
      "inspekcijaRadaSediste": "Инспекција рада - седиште",
      "inspekcijaRadaLice": "Инспекција рада - лице",
      "napomena": "Напомена",

      "snimanjeHeader": "Евиденција о пријавама професионалних обољења",
      "snimanjeUspesno": "Успешно сте снимили пријаву обољења у вези са радом"
    },

    "obrObrazac13": {
      "imePrezime": "Име и презиме",
      "radnoMestoNaziv": "Радно место",
      "dijagnoza": "Дијагноза",

      "detailPrijava": "Пријава",
      "detailDatum": "Датум",
      "detailNadlezniOrgan": "Надлежни орган коме је пријава поднета",
      "detailNapomena": "Напомена",
      "detailInspekcijaRada": "Инспекција рада",
      "detailSedisteNadleznogORgana": "Седиште надлежног органа<br/>коме је пријава однета",
      "detailLicePrimiloPrijavu": "Лице које је пријаву примило",
      "detailUsmeno": "Усмено",
      "detailPismeno": "Писмено",

      "usmenaPrijava": "Усмена пријава",
      "pismenaPrijava": "Писмена пријава",
      "datumPodnosenjaPrijave": "Датум подношења пријаве",
      "inspekcijaRadaSediste": "Инспекција рада - седиште",
      "inspekcijaRadaLice": "Инспекција рада - лице",
      "napomena": "Напомена",

      "snimanjeHeader": "Евиденција о пријавама болести у вези са радом",
      "snimanjeUspesno": "Успешно сте снимили пријаву болести у вези са радом"
    },

    "obrObrazac14": {
      "opis": "Опис појаве",

      "detailPrijava": "Пријава",
      "detailDatum": "Датум",
      "detailNadlezniOrgan": "Надлежни орган коме је пријава поднета",
      "detailNapomena": "Напомена",
      "detailInspekcijaRada": "Инспекција рада",
      "detailOUP": "ОУП",
      "detailSedisteNadleznogORgana": "Седиште надлежног органа<br/>коме је пријава однета",
      "detailLicePrimiloPrijavu": "Лице које је пријаву примило",
      "detailUsmeno": "Усмено",
      "detailPismeno": "Писмено",

      "usmenaPrijava": "Усмена пријава",
      "pismenaPrijava": "Писмена пријава",
      "datumPodnosenjaPrijave": "Датум подношења пријаве",
      "inspekcijaRadaSediste": "Инспекција рада - седиште",
      "inspekcijaRadaLice": "Инспекција рада - лице",
      "oupSediste": "ОПУ - седиште",
      "oupLice": "ОПУ - лице",
      "napomena": "Напомена",

      "snimanjeHeader": "Евиденција о пријавама опасних појава",
      "snimanjeUspesno": "Успешно сте снимили пријаву опасне појаве",
      "brisanjeHeader": "Брисање опасне појаве",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрану опасну појаву?",
      "brisanjeUspesno": "Успешно сте обрисали опасну појаву!",
      "dodavanjeHeader": "Додавање нове опасне појаве",
      "dodavanjeUspesno": "Успешно сте додали опасну појаву!",
      "azuriranjeHeader": "Ажурирање опасне појаве",
      "azuriranjeUspesno": "Успешно сте ажурирали опасну појаву!"
    },

    "obrazacSacuvani": {
      "brisanjePitanje": "Желите ли да обришете изабрани сачувани образац?",
      "brisanjeUspesno": "Успешно сте обрисали изабрани сачувани образац!",
      "brisanjeHeader": "Брисање сачуваног обрасца",
      "id": "ИД",
      "korisnik": "Корисник",
      "datumVreme": "Датум и време",
      "skeniran": "Скениран"
    },

    "xsysPravnoLice": {
      "id": "ИД",
      "naziv": "Назив",
      "pib": "ПИБ",
      "adresa": "Адреса",
      "mesto": "Место",
      "drzava": "Држава",
      "sifraDelatnosti": "Шифра делатности",
      "maticniBroj": "Матични број",
      "arhivirano": "Архивирано",
      "dodavanjeHeader": "Додавање новог правног лица",
      "dodavanjeUspesno": "Успешно сте додали правно лице!",
      "azuriranjeHeader": "Ажурирање правног лица",
      "azuriranjeUspesno": "Успешно сте ажурирали правно лице!",
      "brisanjePitanje": "Желите ли да обришете изабрано правно лице?",
      "brisanjeHeader": "Брисање правног лица",
      "brisanjeUspesno": "Успешно сте обрисали изабрано правно лице!"
    },

    "sysKorisnikRola": {
      "pregledRola": "Преглед рола",
      "izmenaRolaHeader": "Измена рола",
      "izmenaRolaUspesno": "Успешно сте изменили роле кориснику. Корисник ће бити аутоматски излогован из апликације, како би промене постале видљиве."
    },

    "sysKorisnik": {
      "id": "ИД",
      "ime": "Име",
      "prezime": "Презиме",
      "username": "Усернаме",
      "arhivirano": "Архивирано",
      "trenutakSnimanja": "Тренутак снимања",
      "brisanjePitanje": "Желите ли да обришете изабраног корисника?",
      "brisanjeHeader": "Брисање корисника",
      "brisanjeUspesno": "Успешно сте обрисали изабраног корисника!",
      "dodajPostojeceg": "Додај постојећег",
      "stariPassword": "Стара лозинка",
      "noviPassword": "Нова лозинка",
      "noviPasswordPonovo": "Нова лозинка(поново)",
      "promenaPasswordaHeader": "Промена лозинке",
      "promenaPasswordaUspesno": "Успешно сте променили лозинку!",
      "promenaPasswordaRazlicitiPasswordi": "Вредности које сте унели за нову лозинку се не поклапају!",
      "noviPasswordMin6Karaktera": "Нова лозинка мора имати најмање 6 карактера!",
      "promenaPasswordaPogresanStari": "Морате унети валидну постојећу лозинку",
      "resetLozinkeHeader": "Ресет лозинке",
      "resetLozinkeUspesno": "Успешно сте ресетовали лозинку",
      "resetLozinkeNeuspesno": "Корисник не може ресетовати своју лозинку!",
      "dodavanjeHeader": "Додавање корисника",
      "dodavanjeUspesno": "Успешно сте додали корисника",
      "azuriranjeHeader": "Ажурирање корисника",
      "azuriranjeUspesno": "Успешно сте ажурирали корисника!",
      "usernameObavestenje": "Усернаме: ",
      "lozinkaObavestenje": "Лозинка: "
    },

    "commonIzvestaji": {
      "sortirajPo": "Сортирај извештај по:",
      "rizicnaRadnaMesta": "Ризичним радним местима",
      "opis": "Опис",
      "obavestenja": "Обавештења",
      "mere": "Мере",
      "opasneMaterije": "Опасне материје",
      "opasnostiIStetnosti": "Опасности и штетности",
      "elementiPregleda": "Елементи лекарског прегледа",
      "korisnickiPregledi": "Кориснички дефинисани лекарски прегледи",
      "korisnickeObuke": "Кориснички дефинисане обуке"
    },
    "izvestaji": {
      "lzoKojiJePotrebnoNabaviti": "ЛЗО који је потребно набавити",
      "rokZaLzoIstice": "Рок за ЛЗО истиче",
      "rokTrajanjaJeIstekao": "Рок трајања је истекао",
      "nedodeljeniLzo": "Недодељени ЛЗО",
      "narednogMeseca": "наредног месеца",
      "uNarednaDvaMeseca": "у наредна два месеца",
      "uNarednaTriMeseca": "у наредна три месеца",
      "prikaziZaposleneSortiranePo": "Прикажи запослени сортиране по",
      "imenuIPrezimenu": "Имену и презимену",
      "evidencionomBroju": "евиденционом броју",
      "internomMaticnojBroju": "ИМБ-у"
    },

    "sem": {
      "sif_organizaciona_struktura": "Шифарник",
      "sif_organizaciona_struktura_opis": "Организациона структура",
      "sif_organizaciona_struktura_nije_uneto_naziv": "ШИФ Организациона структура: Подаци нису унети",

      "sif_radna_mesta": "Шифарник",
      "sif_radna_mesta_opis": "Радна места",
      "sif_radna_mesta_nije_uneto_naziv": "ШИФ Радна места: Подаци нису унети – апликација се без ових података не може користити",

      "sif_lica": "Шифарник",
      "sif_lica_opis": "Лица",
      "sif_lica_nije_uneto_naziv": "ШИФ Лица: Подаци нису унети – апликација се без ових података не може користити",

      "sif_radno_mesto_struktura": "Шифарник",
      "sif_radno_mesto_struktura_opis": "Радна места у структури",
      "sif_radno_mesto_struktura_nije_uneto_naziv": "ШИФ Радна места у структури: Подаци нису унети – апликација се не може користити",

      "sif_zaposleni": "Шифарник",
      "sif_zaposleni_opis": "Запослени",
      "sif_zaposleni_nije_uneto_naziv": "ШИФ Запослени: Подаци нису унети – апликација се без ових података не може користити",

      "sif_opasne_materije": "Шифарник",
      "sif_opasne_materije_opis": "Опасне материје",
      "sif_opasne_materije_nije_uneto_naziv": "ШИФ Опасне материје: Подаци нису унети",

      "sif_parametri_radne_okoline": "Шифарник",
      "sif_parametri_radne_okoline_opis": "Параметри радне околине",
      "sif_parametri_radne_okoline_nije_uneto_naziv": "ШИФ Параметри радне околине: Подаци нису унети",

      "sif_oprema_za_rad": "Шифарник",
      "sif_oprema_za_rad_opis": "Опрема за рад",
      "sif_oprema_za_rad_nije_uneto_naziv": "ШИФ Опрема за рад: Подаци нису унети",

      "sif_lzo_vrsta_velicine": "Шифарник",
      "sif_lzo_vrsta_velicine_opis": "Врста величина за ЛЗО",
      "sif_lzo_vrsta_velicine_nije_uneto_naziv": "ШИФ Врсте величина за личну заштитну опрему: Подаци нису унети",

      "sif_licna_zastitna_oprema": "Шифарник",
      "sif_licna_zastitna_oprema_opis": "Лична заштитна опрема",
      "sif_licna_zastitna_oprema_nije_uneto_naziv": "ШИФ Лична заштитна опрема: Категорије личне заштитне опреме нису унете",
      "sif_licna_zastitna_oprema_kategorije_nisu_unete_naziv": "ШИФ Лична заштитна опрема: Лична заштитна опрема није унета",

      "sif_obuke": "Шифарник",
      "sif_obuke_opis": "Обуке",
      "sif_obuke_nije_uneto_naziv": "ШИФ Кориснички дефинисане обуке: Подаци нису унети",

      "sif_pregledi": "Шифарник",
      "sif_pregledi_opis": "Прегледи",
      "sif_pregledi_nije_uneto_naziv": "ШИФ Кориснички дефинисани прегледи: Подаци нису унети",

      "obr_obrazac01": "Образац 1",
      "obr_obrazac01_opis": "Евиденција о радним местима са повећаним ризиком",
      "obr_obrazac01_rizicna_rm_naziv": "ОБ 01: Број ризичних радних места без додељених опасности/штетности",
      "obr_obrazac01_nerizicna_rm_naziv": "ОБ 01: Број неризичних радних места без додељених опасности/штетности",

      "obr_obrazac02": "Образац 2",
      "obr_obrazac02_opis": "Евиденција о запосленима распоређеним на радна места са повећаним ризиком и лекарским прегледима",
      "obr_obrazac02_neobavljeni_istekli_pregledi_naziv": "ОБ 02: Број необављених или истеклих лекарских прегледа",
      "obr_obrazac02_predstojeci_pregledi_naziv": "ОБ 02: Број лекарских прегледа који ускоро треба да се обаве",
      "obr_obrazac02_nema_izvestaj_lekara_naziv": "ОБ 02: Број лекарских прегледа који немају извештај лекара",
      "obr_obrazac02_pregled_nesposobni_naziv": "ОБ 02: Број запослених који су на лекарском прегледу оцењени као неспособни",

      "obr_obrazac13": "Образац 2а",
      "obr_obrazac13_opis": "Евиденција о кориснички дефинисаним прегледима",
      "obr_obrazac13_bez_pregleda_naziv": "ОБ 02 б: Број запослених без обављених кориснички дефинисаних прегледа",
      "obr_obrazac13_pregled_istice_naziv": "ОБ 02 б: Број запослених којима обављен кориснички дефинисан преглед ускоро истиче",

      "obr_obrazac06": "Образац 6",
      "obr_obrazac06_opis": "Евиденција о запосленима оспособљеним за безбедан и здрав рад",
      "obr_obrazac06_bez_obuke_naziv": "ОБ 06: Број запослених без обуке",
      "obr_obrazac06_u_procesu_obuke_naziv": "ОБ 06: Број запослених у процесу обуке",

      "obr_obrazac06a": "Образац 6а",
      "obr_obrazac06a_opis": "Евиденција о кориснички дефинисаним обукама",
      "obr_obrazac06a_zaposleni_bez_obuka_naziv": "ОБ 06а: Број запослених без обављених кориснички дефинисаних обука",
      "obr_obrazac06a_zaposleni_obuke_isticu_naziv": "ОБ 06а: Број запослених којима обављена кориснички дефинисана обука ускоро истиче",

      "obr_obrazac07": "Образац 7",
      "obr_obrazac07_opis": "Евиденција о опасним материјама које се користе у току рада",
      "obr_obrazac07_radna_mesta_nedodeljene_materije_naziv": "ОБ 07: Број радних места са дефинисаним, а недодељеним опасним материјама",
      "obr_obrazac07_definisane_nedodeljene_materije_naziv": "ОБ 07: Број дефинисаних, а недодељених опасних материја",

      "obr_obrazac08": "Образац 8",
      "obr_obrazac08_opis": "Евиденција о извршеним испитивањима радне околине",
      "obr_obrazac08_neispitani_parametri_naziv": "ОБ 08: Број неиспитаних параметара радне околине",
      "obr_obrazac08_predstojeci_parametri_naziv": "ОБ 08: Број параметара радне околине који ускоро требају да се испитају",

      "obr_obrazac09": "Образац 9",
      "obr_obrazac09_opis": "Евиденција о извршеним прегледима и испитивањима опреме за рад",
      "obr_obrazac09_istekli_neobavljeni_pregledi_naziv": "ОБ 09: Број истеклих или необављених прегледа опреме за рад",
      "obr_obrazac09_pregledi_koji_isticu_naziv": "ОБ 09: Број прегледа опреме за рад који ускоро истичу",

      "obr_obrazac10": "Образац 10",
      "obr_obrazac10_opis": "Евиденција о извршеним прегледима и испитивањима средстава и опреме за личну заштиту на раду",
      "obr_obrazac10_lzo_bez_atesta_naziv": "ОБ 10: Број личне заштитне опреме без атеста",
      "obr_obrazac10_lzo_bez_uputstva_naziv": "ОБ 10: Број личне заштитне опреме без упутства на српском",
      "obr_obrazac10_zaposleni_bez_lzo_naziv": "ОБ 10: Број запослених без додељене предвиђене ЛЗО",
      "obr_obrazac10_zaposleni_lzo_istekao_rok_trajanja_naziv": "ОБ 10: Број запослених са ЛЗО којој је истекао рок трајања",
      "obr_obrazac10_zaposleni_lzo_istice_rok_trajanja_naziv": "ОБ 10: Број запослених са ЛЗО којој ускоро истиче рок трајања",
      "obr_obrazac10_zaposleni_lzo_istekao_rok_ispitivanja_naziv": "ОБ 10: Број запослених са ЛЗО којој је истекао рок за испитивање",
      "obr_obrazac10_zaposleni_lzo_istice_rok_ispitivanja_naziv": "ОБ 10: Број запослених са ЛЗО којој ускоро истиче рок за испитивање",
      "obr_obrazac10_lzo_istekao_rok_trajanja_naziv": "ОБ 10: Број ЛЗО којој је истекао рок трајања",
      "obr_obrazac10_lzo_istice_rok_trajanja_naziv": "ОБ 10: Број ЛЗО којој ускоро истиче рок трајања",
      "obr_obrazac10_lzo_istekao_rok_ispitivanja_naziv": "ОБ 10: Број ЛЗО којој је истекао рок за испитивање",
      "obr_obrazac10_lzo_istice_rok_ispitivanja_naziv": "ОБ 10: Број ЛЗО којој ускоро истиче рок за испитивање",

      "obr_obrazac11": "Образац 11",
      "obr_obrazac11_opis": "Евиденција о пријавама повреда на раду",
      "obr_obrazac11_neprijavljene_povrede_naziv": "ОБ 11: Број непријављених повреда на раду:",

      "obr_obrazac12": "Образац 12",
      "obr_obrazac12_opis": "Евиденција о пријавама професионалних обољења",
      "obr_obrazac12_neprijavljena_oboljenja_naziv": "ОБ 12: Број непријављених професионалних обољења:",

      "obr_obrazac13": "Образац 13",
      "obr_obrazac13_opis": "Евиденција о пријавама болести у вези са радом",
      "obr_obrazac13_neprijavljene_bolesti_naziv": "ОБ 13: Број непријављених болести у вези са радом:",

      "prikazujObavestenje": "Приказуј обавештење",
      "nePrikazujObavestenje": "Не приказуј обавештење",

      "dana7": "7 дана",
      "dana14": "14 дана",
      "dana30": "30 дана",
      "dana60": "60 дана",
      "dana90": "90 дана",
      "dana180": "180 дана",

      "podesavanjeObavestenjaHeader": "Подешавање обавештења",
      "podesavanjeObavestenjaUspesno": "Успешно сте изменили подешавања обавештења",
      "resetovanjeObavestenjaUspesno": "Успешно сте ресетовали обавештења",
      "resetujPodesavanja": "Ресет подешавања"
    },

    "vertikalniIzvestaj": {
      "osnovniPodaci": "Основни подаци",
      "podaciORadnomMestuZaposlenog": "Подаци о радном месту запосленог",
      "opisRadnogMesta": "Опис радног места",
      "opasnostiIStetnostiNaRadnomMestuZaposlenog": "Опасности и штетности на радном месту запосленог",
      "mereZaBezbedanIZdravRad": "Мере за безбедан и здрав рад",
      "obavestenja": "Обавештења, упутства или инструкције са којима је запослени упознат ради обављања процеса рада на безбедан начин",
      "lekarskiPregledi": "Лекарски прегледи",
      "obavezniLekarskiPregledi": "Обавезни лекарски прегледи",
      "korisnickiDefinisaniLekarskiPregledi": "Кориснички дефинисани лекарски прегледи",
      "profesionalnaOboljenja": "Професионална обољења",
      "bolestiUVeziSaRadom": "Болести у вези са радом",
      "povredeNaRadu": "Повреде на раду",
      "osposobljenosti": "Оспособљености",
      "obavezneObuke": "Обавезне обуке",
      "korisnickiDefinisaneObuke": "Кориснички дефинисане обуке",
      "opasneMaterije": "Опасне материје",
      "licnaZastitnaOprema": "Лична заштитна опрема",
      "validacijaPoruka": "За вертикални извештај морате одабрати запосленог"
    },

    "porukaUspesnoSacuvanObrazac": "Успешно сте сачували образац",

    "coreErrorMessage": "Дошло је до непредвиђене грешке!",

    "commonPanel": {
      "brisanjeHeader": "Брисање података",
      "brisanjePitanje": "Да ли сте сигурни да желите да обришете изабрани податак?",
      "brisanjeUspesno": "Успешно сте обрисали одабране податке!",
      "dodavanjeHeader": "Додавање",
      "dodavanjeUspesno": "Успешно сте додали податак!",
      "azuriranjeHeader": "Ажурирање",
      "azuriranjeUspesno": "Успешно сте ажурирали податак!"
    }
  };

});