define('zaris-frontend/templates/components/obr-obrazac13-grupno-dodavanje-pregleda-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 8
              },
              "end": {
                "line": 47,
                "column": 36
              }
            },
            "moduleName": "zaris-frontend/templates/components/obr-obrazac13-grupno-dodavanje-pregleda-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 8
              },
              "end": {
                "line": 48,
                "column": 96
              }
            },
            "moduleName": "zaris-frontend/templates/components/obr-obrazac13-grupno-dodavanje-pregleda-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrObrazac02.grupnoDodavanjeZaposleniBezPregledaOption1"],[],["loc",[null,[48,32],[48,95]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 8
              },
              "end": {
                "line": 49,
                "column": 96
              }
            },
            "moduleName": "zaris-frontend/templates/components/obr-obrazac13-grupno-dodavanje-pregleda-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrObrazac02.grupnoDodavanjeZaposleniBezPregledaOption2"],[],["loc",[null,[49,32],[49,95]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 8
              },
              "end": {
                "line": 50,
                "column": 96
              }
            },
            "moduleName": "zaris-frontend/templates/components/obr-obrazac13-grupno-dodavanje-pregleda-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrObrazac02.grupnoDodavanjeZaposleniBezPregledaOption3"],[],["loc",[null,[50,32],[50,95]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 6
            },
            "end": {
              "line": 51,
              "column": 6
            }
          },
          "moduleName": "zaris-frontend/templates/components/obr-obrazac13-grupno-dodavanje-pregleda-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","null"],0,null,["loc",[null,[47,8],[47,49]]]],
          ["block","x-option",[],["value","1"],1,null,["loc",[null,[48,8],[48,109]]]],
          ["block","x-option",[],["value","2"],2,null,["loc",[null,[49,8],[49,109]]]],
          ["block","x-option",[],["value","3"],3,null,["loc",[null,[50,8],[50,109]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 141,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/obr-obrazac13-grupno-dodavanje-pregleda-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-panel-content ui equal width grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","column");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","column");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control select full-size");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panelLeft");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panelRight");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","clearFloat");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button success");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element2, [3]);
        var element7 = dom.childAt(element2, [9]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element7, [3]);
        var morphs = new Array(22);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        morphs[3] = dom.createMorphAt(element4,3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
        morphs[5] = dom.createMorphAt(element4,7,7);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [9]),0,0);
        morphs[7] = dom.createMorphAt(element4,11,11);
        morphs[8] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[9] = dom.createMorphAt(element5,3,3);
        morphs[10] = dom.createMorphAt(dom.childAt(element5, [5]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
        morphs[14] = dom.createElementMorph(element8);
        morphs[15] = dom.createMorphAt(element8,2,2);
        morphs[16] = dom.createElementMorph(element9);
        morphs[17] = dom.createMorphAt(element9,2,2);
        morphs[18] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[21] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["sifZaposleni.azuriranjeHeader"],[],["loc",[null,[6,10],[6,47]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["obrObrazac02.grupnoDodavanjeOrgJedinicaFilter"],[],["loc",[null,[15,13],[15,66]]]],
        ["inline","select-tree",[],["strukturaModalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[17,34],[17,57]]]]],[],[]],"obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[18,14],[18,26]]]]],[],[]],"renderButtonOdaberiSve",false],["loc",[null,[16,6],[20,8]]]],
        ["inline","t",["obrObrazac02.grupnoDodavanjeRadnoMestoFilter"],[],["loc",[null,[22,13],[22,65]]]],
        ["inline","select-datatable",[],["modalPanelShow",["subexpr","@mut",[["get","radnoMestoModalPanelShow",["loc",[null,[24,25],[24,49]]]]],[],[]],"obj",["subexpr","@mut",[["get","radnoMestoObj",["loc",[null,[25,14],[25,27]]]]],[],[]],"renderButtonObrisiIzbor",false],["loc",[null,[23,6],[27,8]]]],
        ["inline","t",["obrObrazac02.grupnoDodavanjeRadnoMestoStrukturaFilter"],[],["loc",[null,[29,13],[29,74]]]],
        ["inline","select-datatable",[],["modalPanelShow",["subexpr","@mut",[["get","radnoMestoStrukturaModalPanelShow",["loc",[null,[31,25],[31,58]]]]],[],[]],"obj",["subexpr","@mut",[["get","radnoMestoStrukturaObj",["loc",[null,[32,14],[32,36]]]]],[],[]],"renderButtonObrisiIzbor",false],["loc",[null,[30,6],[34,8]]]],
        ["inline","t",["obrObrazac02.grupnoDodavanjeZaposlenihFilter"],[],["loc",[null,[37,13],[37,65]]]],
        ["inline","select-datatable",[],["modalPanelShow",["subexpr","@mut",[["get","zaposleniModalPanelShow",["loc",[null,[39,25],[39,48]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[40,14],[40,26]]]]],[],[]],"renderButtonObrisiIzbor",false],["loc",[null,[38,6],[42,8]]]],
        ["inline","t",["obrObrazac02.grupnoDodavanjeZaposleniBezPregledaFilter"],[],["loc",[null,[44,13],[44,75]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","selectedZaposleniBezPregledaOption",["loc",[null,[46,24],[46,58]]]]],[],[]],"action","zaposleniBezPregledaFilterSelected"],0,null,["loc",[null,[46,6],[51,19]]]],
        ["inline","component",[["get","componentName1",["loc",[null,[58,18],[58,32]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[59,15],[59,19]]]]],[],[]],"columns",["subexpr","@mut",[["get","licaColumns",["loc",[null,[60,16],[60,27]]]]],[],[]],"domainName","SifLicaZaPreglede","domainId","idLice","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[63,14],[63,19]]]]],[],[]],"perPage",10,"selectedRow",["subexpr","@mut",[["get","selectedLice",["loc",[null,[65,20],[65,32]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshLica",["loc",[null,[66,16],[66,27]]]]],[],[]],"renderControlButtons",false,"hasHeader",true,"hasFooter",false,"content",["subexpr","@mut",[["get","licaContent",["loc",[null,[70,16],[70,27]]]]],[],[]],"bodyParams",["subexpr","@mut",[["get","datatableFilters",["loc",[null,[71,19],[71,35]]]]],[],[]]],["loc",[null,[58,6],[72,8]]]],
        ["inline","component",[["get","componentName2",["loc",[null,[76,18],[76,32]]]]],["hasHeader",true,"hasFooter",false,"columns",["subexpr","@mut",[["get","licaIzabranaColumns",["loc",[null,[79,16],[79,35]]]]],[],[]],"content",["subexpr","@mut",[["get","selectedLicaContent",["loc",[null,[80,16],[80,35]]]]],[],[]]],["loc",[null,[76,6],[81,8]]]],
        ["element","action",["create"],[],["loc",[null,[91,12],[91,31]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[91,91],[91,116]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[92,12],[92,38]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[92,86],[92,111]]]],
        ["inline","select-tree-lov-modal",[],["id","strukturaTreeLovModal","rootView",true,"domainName","SysOrganizacionaJedinicaTree","idModalPanel","sifOrganizacionaJedinicaModal","target",["subexpr","@mut",[["get","this",["loc",[null,[101,11],[101,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew1",["loc",[null,[102,10],[102,16]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject1",["loc",[null,[103,16],[103,28]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh1",["loc",[null,[104,12],[104,20]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[105,19],[105,42]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow1",["loc",[null,[106,16],[106,28]]]]],[],[]],"obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[107,8],[107,20]]]]],[],[]],"displayName","app.menu.organizaciona_struktura"],["loc",[null,[96,0],[109,2]]]],
        ["inline","select-radno-mesto-lov-modal",[],["idModalPanel","sifRadnoMestoLovModal","target",["subexpr","@mut",[["get","this",["loc",[null,[113,11],[113,15]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","radnoMestoModalPanelShow",["loc",[null,[114,19],[114,43]]]]],[],[]],"obj",["subexpr","@mut",[["get","radnoMestoObj",["loc",[null,[115,8],[115,21]]]]],[],[]]],["loc",[null,[111,0],[116,2]]]],
        ["inline","select-radno-mesto-struktura-lov-modal",[],["idModalPanel","sifRadnoMestoStrukturaLovModal","target",["subexpr","@mut",[["get","this",["loc",[null,[120,11],[120,15]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","radnoMestoStrukturaModalPanelShow",["loc",[null,[121,19],[121,52]]]]],[],[]],"obj",["subexpr","@mut",[["get","radnoMestoStrukturaObj",["loc",[null,[122,8],[122,30]]]]],[],[]]],["loc",[null,[118,0],[123,2]]]],
        ["inline","select-zaposleni-lov-modal",[],["id","sifZaposleniLovModal","target",["subexpr","@mut",[["get","this",["loc",[null,[127,11],[127,15]]]]],[],[]],"isNew",false,"domainName","SifZaposleni","domainId","idZaposleni","modelObject",["subexpr","@mut",[["get","modelObjectSifZaposleniLov",["loc",[null,[131,16],[131,42]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[132,8],[132,20]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[133,12],[133,34]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","zaposleniModalPanelShow",["loc",[null,[134,19],[134,42]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[135,13],[135,36]]]]],[],[]],"referenceDomainName","sifZaposleni","referenceDomainId","idZaposleni","refreshBaseTable",["subexpr","@mut",[["get","refreshBaseTable",["loc",[null,[138,21],[138,37]]]]],[],[]],"displayName","obrazac2.izvestaji.izborZaposlenog"],["loc",[null,[125,0],[140,2]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});