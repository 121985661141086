define('zaris-frontend/templates/components/ember-table', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "zaris-frontend/templates/components/ember-table.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","header-table-container",[],["numFixedColumns",["subexpr","@mut",[["get","numFixedColumns",["loc",[null,[3,21],[3,36]]]]],[],[]],"tableColumns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[4,18],[4,30]]]]],[],[]],"fixedColumns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[5,18],[5,30]]]]],[],[]],"fixedBlockWidth",["subexpr","@mut",[["get","_fixedBlockWidth",["loc",[null,[6,21],[6,37]]]]],[],[]],"tableBlockWidth",["subexpr","@mut",[["get","_tableBlockWidth",["loc",[null,[7,21],[7,37]]]]],[],[]],"headerHeight",["subexpr","@mut",[["get","_headerHeight",["loc",[null,[8,18],[8,31]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","_rowWidth",["loc",[null,[9,14],[9,23]]]]],[],[]],"tableContainerWidth",["subexpr","@mut",[["get","_tableContainerWidth",["loc",[null,[10,25],[10,45]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[11,16],[11,32]]]]],[],[]],"enableColumnReorder",["subexpr","@mut",[["get","enableColumnReorder",["loc",[null,[12,25],[12,44]]]]],[],[]],"isShowingSortableIndicator",["subexpr","@mut",[["get","_isShowingSortableIndicator",["loc",[null,[13,32],[13,59]]]]],[],[]],"sortableIndicatorLeft",["subexpr","@mut",[["get","_sortableIndicatorLeft",["loc",[null,[14,27],[14,49]]]]],[],[]],"columnMode",["subexpr","@mut",[["get","columnMode",["loc",[null,[15,16],[15,26]]]]],[],[]],"columnsFillTable",["subexpr","@mut",[["get","columnsFillTable",["loc",[null,[16,22],[16,38]]]]],[],[]],"toggleTableCollapse","toggleTableCollapse","columnDidSort","columnDidSort"],["loc",[null,[2,2],[19,4]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "zaris-frontend/templates/components/ember-table.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","footer-table-container",[],["footerContent",["subexpr","@mut",[["get","footerContent",["loc",[null,[39,20],[39,33]]]]],[],[]],"numFixedColumns",["subexpr","@mut",[["get","numFixedColumns",["loc",[null,[40,22],[40,37]]]]],[],[]],"itemViewClass",["subexpr","@mut",[["get","tableRowViewClass",["loc",[null,[41,20],[41,37]]]]],[],[]],"tableColumns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[42,19],[42,31]]]]],[],[]],"fixedColumns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[43,19],[43,31]]]]],[],[]],"fixedBlockWidth",["subexpr","@mut",[["get","_fixedBlockWidth",["loc",[null,[44,22],[44,38]]]]],[],[]],"tableBlockWidth",["subexpr","@mut",[["get","_tableBlockWidth",["loc",[null,[45,22],[45,38]]]]],[],[]],"footerHeight",["subexpr","@mut",[["get","footerHeight",["loc",[null,[46,19],[46,31]]]]],[],[]],"tableContainerWidth",["subexpr","@mut",[["get","_tableContainerWidth",["loc",[null,[47,26],[47,46]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[48,17],[48,33]]]]],[],[]],"headerHeight",["subexpr","@mut",[["get","_headerHeight",["loc",[null,[49,19],[49,32]]]]],[],[]],"tableContentHeight",["subexpr","@mut",[["get","_tableContentHeight",["loc",[null,[50,25],[50,44]]]]],[],[]],"bodyHeight",["subexpr","@mut",[["get","_bodyHeight",["loc",[null,[51,17],[51,28]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","_rowWidth",["loc",[null,[52,15],[52,24]]]]],[],[]]],["loc",[null,[38,2],[53,4]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 69,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/ember-table.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[4] = dom.createMorphAt(fragment,6,6,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","hasHeader",["loc",[null,[1,6],[1,15]]]]],[],0,null,["loc",[null,[1,0],[20,7]]]],
        ["inline","body-table-container",[],["bodyContent",["subexpr","@mut",[["get","bodyContent",["loc",[null,[22,14],[22,25]]]]],[],[]],"numFixedColumns",["subexpr","@mut",[["get","numFixedColumns",["loc",[null,[23,18],[23,33]]]]],[],[]],"itemViewClass",["subexpr","@mut",[["get","tableRowViewClass",["loc",[null,[24,16],[24,33]]]]],[],[]],"tableColumns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[25,14],[25,26]]]]],[],[]],"fixedColumns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[26,14],[26,26]]]]],[],[]],"fixedBlockWidth",["subexpr","@mut",[["get","_fixedBlockWidth",["loc",[null,[27,17],[27,33]]]]],[],[]],"tableBlockWidth",["subexpr","@mut",[["get","_tableBlockWidth",["loc",[null,[28,17],[28,33]]]]],[],[]],"bodyHeight",["subexpr","@mut",[["get","_bodyHeight",["loc",[null,[29,13],[29,24]]]]],[],[]],"bodyWidth",["subexpr","@mut",[["get","_width",["loc",[null,[30,12],[30,18]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","rowHeight",["loc",[null,[31,12],[31,21]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","_rowWidth",["loc",[null,[32,11],[32,20]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[33,13],[33,29]]]]],[],[]],"rowDidClick","rowDidClick","toggleRowCollapse","toggleRowCollapse"],["loc",[null,[21,0],[36,2]]]],
        ["block","if",[["get","hasFooter",["loc",[null,[37,6],[37,15]]]]],[],1,null,["loc",[null,[37,0],[54,7]]]],
        ["inline","scroll-container",[],["scrollContainerWidth",["subexpr","@mut",[["get","_scrollContainerWidth",["loc",[null,[56,25],[56,46]]]]],[],[]],"fixedColumnsWidth",["subexpr","@mut",[["get","_fixedColumnsWidth",["loc",[null,[57,22],[57,40]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[58,15],[58,31]]]]],[],[]],"tableColumnsWidth",["subexpr","@mut",[["get","_tableColumnsWidth",["loc",[null,[59,22],[59,40]]]]],[],[]],"tableContentHeight",["subexpr","@mut",[["get","_tableContentHeight",["loc",[null,[60,23],[60,42]]]]],[],[]],"scrollLeftDidChange","scrollLeftDidChange"],["loc",[null,[55,0],[63,2]]]],
        ["inline","column-sortable-indicator",[],["sortableIndicatorLeft",["subexpr","@mut",[["get","_sortableIndicatorLeft",["loc",[null,[65,25],[65,47]]]]],[],[]],"tableHeight",["subexpr","@mut",[["get","_height",["loc",[null,[66,15],[66,22]]]]],[],[]],"isShowingSortableIndicator",["subexpr","@mut",[["get","_isShowingSortableIndicator",["loc",[null,[67,30],[67,57]]]]],[],[]]],["loc",[null,[64,0],[68,2]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});