define('zaris-frontend/routes/application', ['exports', 'zaris-frontend/models/models', 'zaris-frontend/routes/authenticated', 'ember', 'zaris-frontend/config/environment'], function (exports, Models, Authenticated, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    i18n: Ember['default'].inject.service(),
    afterModel: function afterModel(user) {
      if (localStorage.locale == undefined) {
        localStorage.locale = ENV['default'].i18n.defaultLocale;
      }
      this.set('i18n.locale', localStorage.locale);
    },
    actions: {
      openYesNoModal: function openYesNoModal(target, titleMsgKey, descMsgKey, controller, action, messageType) {
        this.controller.openYesNoModal(target, titleMsgKey, descMsgKey, controller, action, messageType);
      },
      closeYesNoModal: function closeYesNoModal(target) {
        this.controller.closeYesNoModal(target);
      },
      openOkModal: function openOkModal(target, titleMsgKey, descMsgKey, messageType) {
        this.controller.openYesNoModal(target, titleMsgKey, descMsgKey, messageType);
      },
      closeOkModal: function closeOkModal(target) {
        this.controller.closeYesNoModal(target);
      },

      errorRestCall: function errorRestCall(reason, transition) {
        if (reason.status === 401 /*|| reason.status === 400*/ || reason.status === 0) {
          this.transitionTo('login');
        }

        return false;
      },

      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('login');
        }
      }
    }
  });

});