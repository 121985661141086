define('zaris-frontend/templates/components/sif-revers-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-revers-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","clearFloat");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [5, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element2,3,3);
        morphs[3] = dom.createElementMorph(element3);
        morphs[4] = dom.createMorphAt(element3,2,2);
        morphs[5] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.reversi"],[],["loc",[null,[6,10],[6,34]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","component",[["get","componentLov",["loc",[null,[15,14],[15,26]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[16,11],[16,15]]]]],[],[]],"domainName","SifRevers","domainId","idRevers","content",["subexpr","@mut",[["get","contentObuke",["loc",[null,[19,12],[19,24]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableReversPregled",["loc",[null,[20,12],[20,30]]]]],[],[]],"titleMsgKey","commonPanel.brisanjeHeader","descYesNoMsgKey","commonPanel.brisanjePitanje","titleDeleteMsgKey","commonPanel.brisanjeHeader","descDeleteQuestionMsgKey","commonPanel.brisanjePitanje","descDeleteSuccessMsgKey","commonPanel.brisanjeUspesno","hasHeader",true,"hasFooter",false,"perPage",10,"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[29,10],[29,15]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showReversLzoModalPanel",["loc",[null,[30,19],[30,42]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[31,16],[31,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshRevers",["loc",[null,[32,12],[32,25]]]]],[],[]],"renderFilterArhivirano",false,"renderSearch",false,"customId",["subexpr","@mut",[["get","selektovanoLice.idLice",["loc",[null,[35,13],[35,35]]]]],[],[]],"toolbarComponent","sif-revers-toolbar"],["loc",[null,[15,2],[37,4]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[41,12],[41,38]]]],
        ["inline","t",["app.common.close"],[],["loc",[null,[41,86],[41,110]]]],
        ["inline","sif-revers-modal-lzo",[],["id","sifReversLzoModal","target",["subexpr","@mut",[["get","this",["loc",[null,[49,11],[49,15]]]]],[],[]],"refreshRevers",["subexpr","@mut",[["get","refreshRevers",["loc",[null,[50,18],[50,31]]]]],[],[]],"refreshReversLzoModal",["subexpr","@mut",[["get","refreshReversLzoModal",["loc",[null,[51,26],[51,47]]]]],[],[]],"showReversLzoModalPanel",["subexpr","@mut",[["get","showReversLzoModalPanel",["loc",[null,[52,30],[52,53]]]]],[],[]],"isNewUnos",["subexpr","@mut",[["get","isNew",["loc",[null,[53,16],[53,21]]]]],[],[]],"idSelectedLice",["subexpr","@mut",[["get","selektovanoLice.idLice",["loc",[null,[54,19],[54,41]]]]],[],[]],"idSelectedReversReal",["subexpr","@mut",[["get","selectedRow.idRevers",["loc",[null,[55,25],[55,45]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[56,28],[56,51]]]]],[],[]]],["loc",[null,[47,0],[57,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});