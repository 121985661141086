define('zaris-frontend/components/select-tree-lov-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'selectTreeLovModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "30%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",
    rootView: false,

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    modalContent: Em.A(),

    componentName1: null,

    selectedRow: null,
    saveEnabled: false,

    contentPath: 'group_value',

    saveButtonConditionChanged: Ember['default'].observer('selectedRow', function () {
      this.set('saveEnabled', this.get('selectedRow') !== null);
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      choose: function choose() {
        var contentPath = this.get('contentPath');

        var newObj = {};
        newObj.naziv = this.get('selectedRow')[contentPath];
        newObj.id = this.get('selectedRow').id;
        this.set('obj', newObj);
        this.closePanel();
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      var outter = this;
      this.set('componentName1', 'zaris-tree-table');
      setTimeout(function () {
        Ember['default'].showModalPanel('#' + outter.get('id'));
      }, 120);
    }),

    closePanel: function closePanel() {
      var id = this.get('id');
      $('#' + id).data('dialog').close();
    }
  });

});