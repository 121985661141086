define('zaris-frontend/templates/obr-obrazac1-unos', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 10
              },
              "end": {
                "line": 10,
                "column": 93
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac1-unos.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","t",["app.menu.radna_mesta"],[],["loc",[null,[10,65],[10,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 10
              },
              "end": {
                "line": 11,
                "column": 114
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac1-unos.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","t",["app.menu.radna_mesta_u_strukturi"],[],["loc",[null,[11,74],[11,114]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 12,
              "column": 8
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac1-unos.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","radnoMesto","class","xSelectBoxItem"],0,null,["loc",[null,[10,10],[10,106]]]],
          ["block","x-option",[],["value","radnoMestoStruktura","class","xSelectBoxItem"],1,null,["loc",[null,[11,10],[11,127]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac1-unos.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(element1,1,1);
        morphs[2] = dom.createMorphAt(element1,3,3);
        morphs[3] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_radnim_mestima_sa_povecanim_rizikom"],[],["loc",[null,[4,28],[4,93]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","selectedDomainName",["loc",[null,[9,26],[9,44]]]]],[],[]]],0,null,["loc",[null,[9,8],[12,21]]]],
        ["inline","zaris-tree-table",[],["groupColumnWidth",600,"usePage",true,"target",["subexpr","@mut",[["get","this",["loc",[null,[17,17],[17,21]]]]],[],[]],"domainName",["subexpr","@mut",[["get","selectedDomainView",["loc",[null,[18,21],[18,39]]]]],[],[]],"domainId","id","tableCellView","zaris-margin-left-tree-cell","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[21,16],[21,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[22,22],[22,33]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[23,22],[23,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[24,18],[24,25]]]]],[],[]],"valueFactors",["subexpr","@mut",[["get","valueFactors",["loc",[null,[25,23],[25,35]]]]],[],[]],"maxLevelEdit",3,"minLevelEdit",1,"collapseAfterLevel",1,"displayName","app.common.radnoMesto","renderSacuvajObrazac",true,"renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac1Sacuvani","renderIzvestaji",true,"routeIzvestaji","obrObrazac1Izvestaji","renderSifarnici",true,"routeSifarnici",["subexpr","@mut",[["get","selectedSifarniciRoute",["loc",[null,[36,27],[36,49]]]]],[],[]],"modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[37,31],[37,45]]]]],[],[]],"customColumnName","id","customColumnValue",true,"renderCreate",false,"renderDelete",false,"renderSearch",true],["loc",[null,[14,8],[43,10]]]],
        ["inline","obr-obrazac1-modal",[],["id","obrObrazac1Modal","domainId","idObrazac1RmOpasnosti","target",["subexpr","@mut",[["get","this",["loc",[null,[52,11],[52,15]]]]],[],[]],"isNew",false,"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[54,16],[54,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[55,12],[55,19]]]]],[],[]],"modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[56,25],[56,39]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idRadnoMesto",["loc",[null,[57,15],[57,39]]]]],[],[]],"selektovanoRadnoMesto",["subexpr","@mut",[["get","selectedRow",["loc",[null,[58,28],[58,39]]]]],[],[]],"selectedDomain",["subexpr","@mut",[["get","selectedDomain",["loc",[null,[59,19],[59,33]]]]],[],[]],"selectedDomainId",["subexpr","@mut",[["get","selectedDomainId",["loc",[null,[60,21],[60,37]]]]],[],[]],"selectedReferencedDomain",["subexpr","@mut",[["get","selectedReferencedDomain",["loc",[null,[61,29],[61,53]]]]],[],[]],"selectedReferencedDomainId",["subexpr","@mut",[["get","selectedReferencedDomainId",["loc",[null,[62,31],[62,57]]]]],[],[]]],["loc",[null,[49,0],[63,2]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});