define('zaris-frontend/templates/obr-obrazac06a-izvestaji', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 12
            },
            "end": {
              "line": 13,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac06a-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.common.unosPodataka"],[],["loc",[null,[11,14],[11,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 12
            },
            "end": {
              "line": 19,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac06a-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.menu.sacuvani_izvestaji"],[],["loc",[null,[17,14],[17,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 111,
                "column": 24
              },
              "end": {
                "line": 111,
                "column": 90
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac06a-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac6.izvestaji.radnimMestima"],[],["loc",[null,[111,49],[111,89]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 112,
                "column": 24
              },
              "end": {
                "line": 112,
                "column": 101
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac06a-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac6.izvestaji.organizacionojStrukturi"],[],["loc",[null,[112,50],[112,100]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 113,
                "column": 24
              },
              "end": {
                "line": 113,
                "column": 89
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac06a-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac6.izvestaji.zaposlenima"],[],["loc",[null,[113,50],[113,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 110,
              "column": 22
            },
            "end": {
              "line": 114,
              "column": 22
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac06a-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","rm"],0,null,["loc",[null,[111,24],[111,103]]]],
          ["block","x-option",[],["value","org"],1,null,["loc",[null,[112,24],[112,114]]]],
          ["block","x-option",[],["value","zap"],2,null,["loc",[null,[113,24],[113,102]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 160,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac06a-izvestaji.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 90%; ");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row 2");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","flex-grid ");
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row flex-just-sa cells2");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        dom.setAttribute(el9,"style","color:red;");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row flex-just-sa cells2");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2 flex-just-sa last-row");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h4");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell obr-izv-cell-generate colspan6");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button success izv-generate-button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-checkmark");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element6, [3, 1]);
        var element8 = dom.childAt(element6, [5, 1]);
        var element9 = dom.childAt(element3, [3]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element9, [3]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element11, [3]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element15, [1, 1]);
        var element17 = dom.childAt(element15, [3, 1]);
        var element18 = dom.childAt(element14, [3]);
        var element19 = dom.childAt(element3, [5]);
        var element20 = dom.childAt(element19, [1, 1]);
        var element21 = dom.childAt(element19, [3, 1]);
        var morphs = new Array(29);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
        morphs[4] = dom.createMorphAt(element5,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
        morphs[6] = dom.createMorphAt(element7,1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [5]),0,0);
        morphs[8] = dom.createMorphAt(element8,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element8, [5]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
        morphs[11] = dom.createMorphAt(element10,3,3);
        morphs[12] = dom.createMorphAt(dom.childAt(element10, [5]),0,0);
        morphs[13] = dom.createMorphAt(element10,7,7);
        morphs[14] = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
        morphs[15] = dom.createMorphAt(element13,1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element13, [5]),0,0);
        morphs[17] = dom.createMorphAt(element16,1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element16, [5]),0,0);
        morphs[19] = dom.createMorphAt(element17,1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element17, [5]),0,0);
        morphs[21] = dom.createMorphAt(element18,1,1);
        morphs[22] = dom.createMorphAt(element18,3,3);
        morphs[23] = dom.createMorphAt(dom.childAt(element20, [1, 1]),0,0);
        morphs[24] = dom.createMorphAt(dom.childAt(element20, [3, 1]),1,1);
        morphs[25] = dom.createElementMorph(element21);
        morphs[26] = dom.createMorphAt(element21,2,2);
        morphs[27] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[28] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_korisnicki_definisanim_obukama"],[],["loc",[null,[4,28],[4,88]]]],
        ["block","link-to",["obrObrazac06aUnos"],["tagName","button","class","image-button info"],0,null,["loc",[null,[10,12],[13,24]]]],
        ["block","link-to",["obrObrazac06Sacuvani"],["tagName","button","class","image-button info"],1,null,["loc",[null,[16,12],[19,24]]]],
        ["inline","t",["obrazac2.izvestaji.headerZaposleni"],[],["loc",[null,[26,21],[26,63]]]],
        ["inline","select-zaposleni",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[27,45],[27,67]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[28,35],[28,52]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[29,24],[29,36]]]]],[],[]],"placeholderLabel","obrazac6.izvestaji.odaberiteZaposlenog"],["loc",[null,[27,18],[31,20]]]],
        ["inline","t",["obrazac6.izvestaji.headerStatus"],[],["loc",[null,[34,21],[34,60]]]],
        ["inline","radio-button",[],["value",false,"checked",["subexpr","@mut",[["get","imaObuku",["loc",[null,[37,55],[37,63]]]]],[],[]]],["loc",[null,[37,20],[37,65]]]],
        ["inline","t",["obrazac6.izvestaji.nemaObuku"],[],["loc",[null,[39,61],[39,97]]]],
        ["inline","radio-button",[],["value",true,"checked",["subexpr","@mut",[["get","imaObuku",["loc",[null,[44,54],[44,62]]]]],[],[]]],["loc",[null,[44,20],[44,64]]]],
        ["inline","t",["obrazac6.izvestaji.imaObuku"],[],["loc",[null,[46,42],[46,77]]]],
        ["inline","t",["obrazac6.izvestaji.headerStruktura"],[],["loc",[null,[55,22],[55,64]]]],
        ["inline","select-tree",[],["strukturaModalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[57,44],[57,67]]]]],[],[]],"label","obrazac6.izvestaji.sviNivoiStrukture","obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[59,24],[59,36]]]]],[],[]]],["loc",[null,[56,18],[60,22]]]],
        ["inline","t",["obrazac6.izvestaji.headerVrstaObuke"],[],["loc",[null,[61,22],[61,65]]]],
        ["inline","select-items",[],["domainName","SifObuka","nullLabel","obrazac6.izvestaji.sveObuke","value",["subexpr","@mut",[["get","idObuka",["loc",[null,[62,101],[62,108]]]]],[],[]]],["loc",[null,[62,18],[62,110]]]],
        ["inline","t",["obrazac6.izvestaji.headerRadnaMesta"],[],["loc",[null,[67,24],[67,67]]]],
        ["inline","radio-button",[],["value","radnaMesta","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[69,64],[69,74]]]]],[],[]]],["loc",[null,[69,22],[69,76]]]],
        ["inline","t",["obrazac6.izvestaji.radnaMesta"],[],["loc",[null,[71,44],[71,81]]]],
        ["inline","radio-button",[],["value","radnoMesto","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[80,64],[80,74]]]]],[],[]]],["loc",[null,[80,22],[80,76]]]],
        ["inline","t",["obrazac6.izvestaji.radnoMesto"],[],["loc",[null,[82,44],[82,81]]]],
        ["inline","radio-button",[],["value","radnoMestoStruktura","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[87,73],[87,83]]]]],[],[]]],["loc",[null,[87,22],[87,85]]]],
        ["inline","t",["obrazac6.izvestaji.radnoMestoStruktura"],[],["loc",[null,[89,44],[89,90]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMesto","value",["subexpr","@mut",[["get","idRadnoMesto",["loc",[null,[94,85],[94,97]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[94,120],[94,130]]]],"radnoMesto"],[],["loc",[null,[94,112],[94,144]]]]],[],["loc",[null,[94,107],[94,145]]]]],["loc",[null,[94,20],[94,147]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMestoStruktura","value",["subexpr","@mut",[["get","idRadnoMestoStruktura",["loc",[null,[95,94],[95,115]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[95,138],[95,148]]]],"radnoMestoStruktura"],[],["loc",[null,[95,130],[95,171]]]]],[],["loc",[null,[95,125],[95,172]]]]],["loc",[null,[95,20],[95,174]]]],
        ["inline","t",["obrazac6.izvestaji.grupisiIzvestajPo"],[],["loc",[null,[106,24],[106,68]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","groupBy",["loc",[null,[110,40],[110,47]]]]],[],[]]],2,null,["loc",[null,[110,22],[114,35]]]],
        ["element","action",["generate"],[],["loc",[null,[120,24],[120,45]]]],
        ["inline","t",["app.common.generisi"],[],["loc",[null,[120,125],[120,152]]]],
        ["inline","select-tree-lov-modal",[],["id","strukturaTreeLovModal","rootView",true,"domainName","SysOrganizacionaJedinicaTree","idModalPanel","sifOrganizacionaJedinicaModal","target",["subexpr","@mut",[["get","this",["loc",[null,[134,11],[134,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[135,10],[135,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[136,16],[136,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[137,12],[137,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[138,19],[138,42]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[139,16],[139,27]]]]],[],[]],"obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[140,8],[140,20]]]]],[],[]],"displayName","app.menu.organizaciona_struktura"],["loc",[null,[129,0],[142,2]]]],
        ["inline","select-zaposleni-lov-modal",[],["id","sifZaposleniLovModal","target",["subexpr","@mut",[["get","this",["loc",[null,[146,11],[146,15]]]]],[],[]],"isNew",false,"domainName","SifZaposleni","domainId","idZaposleni","modelObject",["subexpr","@mut",[["get","modelObjectSifZaposleniLov",["loc",[null,[150,16],[150,42]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[151,8],[151,20]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[152,12],[152,34]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[153,19],[153,36]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[154,13],[154,36]]]]],[],[]],"referenceDomainName","sifZaposleni","referenceDomainId","idZaposleni","refreshBaseTable",["subexpr","@mut",[["get","refreshBaseTable",["loc",[null,[157,21],[157,37]]]]],[],[]],"displayName","obrazac6.izvestaji.izborZaposlenog"],["loc",[null,[144,0],[159,2]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});