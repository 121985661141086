define('zaris-frontend/controllers/navigation', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var NavigationController = Ember['default'].Controller.extend({
    menuService: Ember['default'].inject.service('menu'),
    menuHTML: Ember['default'].computed.reads('menuService.menuHTML'),

    loginController: Ember['default'].inject.controller('login'),
    imeIPrezime: Ember['default'].computed.reads('loginController.imeIPrezime'),

    idPravnoLice: localStorage.idPravnoLice,
    idPravnoLiceChanged: (function () {
      localStorage.idPravnoLice = this.get('idPravnoLice');
    }).observes('idPravnoLice'),

    pravnoLiceNaziv: localStorage.pravnoLiceNaziv,
    pravnoLiceNazivChanged: (function () {
      localStorage.pravnoLiceNaziv = this.get('pravnoLiceNaziv');
    }).observes('pravnoLiceNaziv'),

    formatedDatum: Ember['default'].computed('imeIPrezime', function () {
      return moment(new Date()).format('DD.MM.YYYY.');
    }),

    actions: {
      openPravnoLiceModal: function openPravnoLiceModal() {
        this.toggleProperty('pravnoLiceModalPanelShow');
      }
    }
  });

  exports['default'] = NavigationController;

});