define('zaris-frontend/templates/obr-obrazac09-izvestaji', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 12
            },
            "end": {
              "line": 13,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac09-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.common.unosPodataka"],[],["loc",[null,[11,14],[11,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 12
            },
            "end": {
              "line": 19,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac09-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.menu.sacuvani_izvestaji"],[],["loc",[null,[17,14],[17,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 20
              },
              "end": {
                "line": 63,
                "column": 86
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac09-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac09.izvestaji.narednogMeseca"],[],["loc",[null,[63,43],[63,85]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 20
              },
              "end": {
                "line": 64,
                "column": 89
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac09-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac09.izvestaji.uNarednaDvaMeseca"],[],["loc",[null,[64,43],[64,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 20
              },
              "end": {
                "line": 65,
                "column": 89
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac09-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac09.izvestaji.uNarednaTriMeseca"],[],["loc",[null,[65,43],[65,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 18
            },
            "end": {
              "line": 66,
              "column": 18
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac09-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value",30],0,null,["loc",[null,[63,20],[63,99]]]],
          ["block","x-option",[],["value",60],1,null,["loc",[null,[64,20],[64,102]]]],
          ["block","x-option",[],["value",90],2,null,["loc",[null,[65,20],[65,102]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 132,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac09-izvestaji.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel zarisIzvestaj07");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 90%; ");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row 2");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","flex-grid");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells1");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan12 ");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 ");
        var el7 = dom.createTextNode("\n\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 ");
        var el7 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        dom.setAttribute(el9,"style","color:red;");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell obr-izv-cell-generate colspan12");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button success izv-generate-button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-checkmark");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element8, [1, 1, 1]);
        var element10 = dom.childAt(element7, [5, 1]);
        var element11 = dom.childAt(element7, [7, 1]);
        var element12 = dom.childAt(element3, [5, 1, 1]);
        var morphs = new Array(19);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
        morphs[4] = dom.createMorphAt(element4,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        morphs[6] = dom.createMorphAt(element6,3,3);
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
        morphs[8] = dom.createMorphAt(element9,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element9, [5]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element8, [3, 1]),1,1);
        morphs[11] = dom.createMorphAt(element10,1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element10, [5]),0,0);
        morphs[13] = dom.createMorphAt(element11,1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element11, [5]),0,0);
        morphs[15] = dom.createElementMorph(element12);
        morphs[16] = dom.createMorphAt(element12,2,2);
        morphs[17] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_izvrsenim_pregledima_i_ispitivanjima_opreme_za_rad"],[],["loc",[null,[4,28],[4,108]]]],
        ["block","link-to",["obrObrazac09Unos"],["tagName","button","class","image-button info"],0,null,["loc",[null,[10,12],[13,24]]]],
        ["block","link-to",["obrObrazac09Sacuvani"],["tagName","button","class","image-button info"],1,null,["loc",[null,[16,12],[19,24]]]],
        ["inline","t",["obrazac09.izvestaji.headerOpremaZaRad"],[],["loc",[null,[25,19],[25,64]]]],
        ["inline","select-items",[],["domainName","SifOpremaZaRad","nullLabel","obrazac09.izvestaji.svaOprema","value",["subexpr","@mut",[["get","idOpremaZaRad",["loc",[null,[29,22],[29,35]]]]],[],[]]],["loc",[null,[26,14],[30,16]]]],
        ["inline","t",["obrazac09.izvestaji.headerStruktura"],[],["loc",[null,[36,18],[36,61]]]],
        ["inline","select-tree",[],["strukturaModalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[38,40],[38,63]]]]],[],[]],"label","obrazac09.izvestaji.sviNivoiStrukture","obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[40,20],[40,32]]]]],[],[]]],["loc",[null,[37,14],[41,18]]]],
        ["inline","t",["obrazac09.izvestaji.headerIspitivanja"],[],["loc",[null,[49,17],[49,62]]]],
        ["inline","radio-button",[],["value","ispitivanjaKojaTreba","checked",["subexpr","@mut",[["get","ispitivanjaOpreme",["loc",[null,[54,72],[54,89]]]]],[],[]]],["loc",[null,[54,20],[54,91]]]],
        ["inline","t",["obrazac09.izvestaji.ispitivanjaKojaTreba"],[],["loc",[null,[56,42],[56,90]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","periodZaIspitivanja",["loc",[null,[62,36],[62,55]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","ispitivanjaOpreme",["loc",[null,[62,78],[62,95]]]],"ispitivanjaKojaTreba"],[],["loc",[null,[62,70],[62,119]]]]],[],["loc",[null,[62,65],[62,120]]]]],2,null,["loc",[null,[62,18],[66,31]]]],
        ["inline","radio-button",[],["value","svaIspitivanja","checked",["subexpr","@mut",[["get","ispitivanjaOpreme",["loc",[null,[72,62],[72,79]]]]],[],[]]],["loc",[null,[72,16],[72,81]]]],
        ["inline","t",["obrazac09.izvestaji.svaIspitivanja"],[],["loc",[null,[74,38],[74,80]]]],
        ["inline","radio-button",[],["value","neobavljenaIspitivanja","checked",["subexpr","@mut",[["get","ispitivanjaOpreme",["loc",[null,[79,70],[79,87]]]]],[],[]]],["loc",[null,[79,16],[79,89]]]],
        ["inline","t",["obrazac09.izvestaji.neobavljenaIspitivanja"],[],["loc",[null,[81,57],[81,107]]]],
        ["element","action",["generate"],[],["loc",[null,[92,22],[92,43]]]],
        ["inline","t",["app.common.generisi"],[],["loc",[null,[92,123],[92,150]]]],
        ["inline","select-tree-lov-modal",[],["id","strukturaTreeLovModal","rootView",true,"domainName","SysOrganizacionaJedinicaTree","idModalPanel","sifOrganizacionaJedinicaModal","target",["subexpr","@mut",[["get","this",["loc",[null,[106,11],[106,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[107,10],[107,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[108,16],[108,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[109,12],[109,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[110,19],[110,42]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[111,16],[111,27]]]]],[],[]],"obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[112,8],[112,20]]]]],[],[]],"displayName","app.menu.organizaciona_struktura"],["loc",[null,[101,0],[114,2]]]],
        ["inline","select-zaposleni-lov-modal",[],["id","sifZaposleniLovModal","target",["subexpr","@mut",[["get","this",["loc",[null,[118,11],[118,15]]]]],[],[]],"isNew",false,"domainName","SifZaposleni","domainId","idZaposleni","modelObject",["subexpr","@mut",[["get","modelObjectSifZaposleniLov",["loc",[null,[122,16],[122,42]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[123,8],[123,20]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[124,12],[124,34]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[125,19],[125,36]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[126,13],[126,36]]]]],[],[]],"referenceDomainName","sifZaposleni","referenceDomainId","idZaposleni","refreshBaseTable",["subexpr","@mut",[["get","refreshBaseTable",["loc",[null,[129,21],[129,37]]]]],[],[]],"displayName","obrazac2.izvestaji.izborZaposlenog"],["loc",[null,[116,0],[131,2]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});