define('zaris-frontend/templates/components/sif-opasnosti-stetnosti-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-opasnosti-stetnosti-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-checkmark");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-cross");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [5]);
        var element4 = dom.childAt(element2, [7]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        morphs[4] = dom.createElementMorph(element3);
        morphs[5] = dom.createMorphAt(element3,2,2);
        morphs[6] = dom.createElementMorph(element4);
        morphs[7] = dom.createMorphAt(element4,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",[["subexpr","if",[["get","isNew",["loc",[null,[6,18],[6,23]]]],"sifOpasnostiStetnosti.dodavanjeHeader","sifOpasnostiStetnosti.azuriranjeHeader"],[],["loc",[null,[6,14],[6,105]]]]],[],["loc",[null,[6,10],[6,107]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["sifOpasnostiStetnosti.inputNaziv"],[],["loc",[null,[13,9],[13,49]]]],
        ["inline","input-validation",[],["hintPosition","top","value",["subexpr","@mut",[["get","modelObject.group_value",["loc",[null,[15,50],[15,73]]]]],[],[]]],["loc",[null,[15,6],[15,76]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[18,23],[18,28]]]],"create","update"],[],["loc",[null,[18,19],[18,47]]]]],[],["loc",[null,[18,10],[18,49]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[18,101],[18,126]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[19,10],[19,36]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[19,84],[19,109]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});