define('zaris-frontend/templates/components/sif-revers-modal-lzo', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-revers-modal-lzo.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"style","width: 95%;");
        dom.setAttribute(el2,"class","content panelCentered");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","input-control text");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clearFloat");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clearFloat");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clearFloat");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clearFloat");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2, 1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [9]);
        var element5 = dom.childAt(element2, [15]);
        var element6 = dom.childAt(element2, [17]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element3,1,1);
        morphs[3] = dom.createMorphAt(element3,3,3);
        morphs[4] = dom.createMorphAt(element2,5,5);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
        morphs[7] = dom.createElementMorph(element5);
        morphs[8] = dom.createMorphAt(element5,2,2);
        morphs[9] = dom.createElementMorph(element6);
        morphs[10] = dom.createMorphAt(element6,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.reversi"],[],["loc",[null,[6,10],[6,34]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datum",["loc",[null,[14,40],[14,57]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[14,6],[14,79]]]],
        ["inline","input-validation",[],["type","hidden","validType","required","arg","8","value",["subexpr","@mut",[["get","modelObject.datum",["loc",[null,[15,74],[15,91]]]]],[],[]]],["loc",[null,[15,6],[15,94]]]],
        ["inline","component",[["get","componentLov",["loc",[null,[19,12],[19,24]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[20,9],[20,13]]]]],[],[]],"domainName","VsifReversLzo","domainId","idObrazac10Lzo","content",["subexpr","@mut",[["get","contentReversLzo",["loc",[null,[23,10],[23,26]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableReversLzoPregled",["loc",[null,[24,10],[24,31]]]]],[],[]],"titleMsgKey","obrObrazac08.brisanjeHeader","descYesNoMsgKey","obrObrazac08.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac08.brisanjeUspesno","titleDeleteMsgKey","obrObrazac08.brisanjeHeader","descDeleteQuestionMsgKey","obrObrazac08.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac08.brisanjeUspesno","hasHeader",true,"hasFooter",false,"perPage",10,"isNew",["subexpr","@mut",[["get","isNewUnos",["loc",[null,[34,8],[34,17]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[35,14],[35,25]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshReversLzo",["loc",[null,[36,10],[36,26]]]]],[],[]],"renderFilterArhivirano",false,"renderSearch",false,"renderCreate",false,"renderDelete",false,"renderUpdate",false,"customId",["subexpr","@mut",[["get","idSelectedLice",["loc",[null,[42,11],[42,25]]]]],[],[]],"customId2",["subexpr","@mut",[["get","idSelectedRevers",["loc",[null,[43,12],[43,28]]]]],[],[]]],["loc",[null,[19,0],[44,2]]]],
        ["inline","t",["obrObrazac02.napomena"],[],["loc",[null,[48,11],[48,40]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.napomena",["loc",[null,[50,25],[50,45]]]]],[],[]]],["loc",[null,[50,8],[50,48]]]],
        ["element","action",[["subexpr","if",[["get","isNewReversUnos",["loc",[null,[56,23],[56,38]]]],"create","update"],[],["loc",[null,[56,19],[56,57]]]]],[],["loc",[null,[56,10],[56,59]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[56,111],[56,136]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[57,10],[57,36]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[57,84],[57,109]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});