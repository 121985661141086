define('zaris-frontend/controllers/login', ['exports', 'ember', 'zaris-frontend/models/models', 'zaris-frontend/conf'], function (exports, Ember, Models, conf) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({
    menuService: Ember['default'].inject.service('menu'),
    loginService: Ember['default'].inject.service('login'),
    homeService: Ember['default'].inject.service('home'),
    roleService: Ember['default'].inject.service('roles'),
    i18n: Ember['default'].inject.service('i18n'),

    imeIPrezime: localStorage.imeIPrezime,
    imeIPrezimeChanged: (function () {
      localStorage.imeIPrezime = this.get('imeIPrezime');
    }).observes('imeIPrezime'),

    idPravnoLice: localStorage.idPravnoLice,
    idPravnoLiceChanged: (function () {
      localStorage.idPravnoLice = this.get('idPravnoLice');
    }).observes('idPravnoLice'),

    pravnoLiceNaziv: localStorage.pravnoLiceNaziv,
    pravnoLiceNazivChanged: (function () {
      localStorage.pravnoLiceNaziv = this.get('pravnoLiceNaziv');
    }).observes('pravnoLiceNaziv'),

    reset: function reset() {
      this.setProperties({
        username: "",
        password: "",
        errorMessage: ""
      });
    },

    actions: {
      authenticate: function authenticate() {
        var self = this;
        var data = this.getProperties('identification', 'password');
        var menuService = this.get('menuService');
        var loginService = this.get('loginService');
        var homeService = this.get('homeService');
        var roleService = this.get('roleService');

        loginService.authenticate(data.identification, data.password);
        if (loginService.errorMessage) {
          self.set('errorMessage', loginService.errorMessage);
          self.transitionToRoute('login');
          return;
        }

        var accessToken = loginService.getAccessToken();

        //generisemo html meni
        menuService.generateHTMLMenu(accessToken);
        homeService.generateMenu(accessToken);
        roleService.getRoles(accessToken);

        //dohvatamo podatke o pravnom licu
        var xsysPravnoLiceModel = Models['default'].XsysPravnoLice.create({ errorHandlerController: this });
        var pravnaLica = xsysPravnoLiceModel.findAllGetResult(accessToken);
        self.set('idPravnoLice', pravnaLica[0].idPravnoLice);
        self.set('pravnoLiceNaziv', pravnaLica[0].naziv);

        //dohvatamo i podatke o ulogovanom korisniku
        var userDetailsModel = Models['default'].UserDetails.create({ errorHandlerController: this });
        var userDetails = userDetailsModel.getResult('details', accessToken, 'POST');
        self.set('imeIPrezime', userDetails.ime + " " + userDetails.prezime);

        //na kraju idemo na pocetnu stranu
        //self.transitionToRoute('home');
        window.location.href = '';
      }
    }

  });

});