define('zaris-frontend/components/obr-obrazac-sacuvaj-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    loginService: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    objectId: null,
    servicePath: null,
    refresh: false,
    modalPanelShow: false,

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0 && this.get('selectedRow')[this.get('domainId')] !== null;
    }),

    actions: {
      sacuvajObrazac: function sacuvajObrazac() {
        var outter = this;
        var i18n = this.get('i18n');
        var domainId = this.get('domainId');
        var id = this.get('selectedRow')[domainId];
        var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });
        model.getById('sacuvajObrazac', id, this.get('loginService').getAccessToken(), 'GET', function () {
          $('#sacuvaniObrasci').click();
          setTimeout(function () {
            $.Notify({
              content: i18n.t('porukaUspesnoSacuvanObrazac').string,
              keepOpen: false,
              type: 'success'
            });
          }, 100);
        });
      }
    }
  });

});