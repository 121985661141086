define('zaris-frontend/components/zaris-header-row', ['exports', 'ember-table/components/header-row'], function (exports, HeaderRow) {

  'use strict';

  exports['default'] = HeaderRow['default'].extend({
    actions: {
      sortByColumn: function sortByColumn(content) {
        this.set('action', 'sortByColumn');
        this.sendAction('action', content);
      }
    }
  });

});