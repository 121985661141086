define('zaris-frontend/components/sif-radna-mesta-struktura-new-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifRadnaMestaStrukturaNewModal'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    radnoMestoStrukturaRadnoMestoContent: Em.A(),
    radnoMestoStrukturaOrgJedContent: Em.A(),

    componentName1: null,
    componentName2: null,

    selectedOrgJedinica: null,
    selectedRadnoMesto: null,
    saveEnabled: false,

    tableRadnaMestaColumns: Ember['default'].computed(function () {
      var self = this;

      var idColumn = ColumnDefinition['default'].create({
        key: 'idRadnoMesto',
        savedWidth: 50,
        supportSort: true,
        headerCellName: 'ID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var nazivColumn = ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Naziv',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var sifraColumn = ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Šifra',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var opisColumn = ColumnDefinition['default'].create({
        key: 'opis',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Opis',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var rizicnoColumn = ColumnDefinition['default'].create({
        key: 'fgRizicno',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Rizicno',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var i18n = this.get('i18n');
          return self.booleanValueNames(row.get(this.key));
        }
      });
      return [idColumn, nazivColumn, sifraColumn, opisColumn, rizicnoColumn];
    }),

    saveButtonConditionChanged: Ember['default'].observer('selectedOrgJedinica', 'selectedRadnoMesto', function () {
      this.set('saveEnabled', this.get('selectedRadnoMesto') !== null && Object.keys(this.get('selectedRadnoMesto')).length !== 0 && this.get('selectedOrgJedinica') !== null && Object.keys(this.get('selectedOrgJedinica')).length !== 0);
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifRadnoMestoStrukturaModel = Models['default'].SifRadnoMestoStruktura.create({ errorHandlerController: this });

        var obj = {};
        console.log(this.get('selectedOrgJedinica'));console.log(this.get('selectedRadnoMesto'));
        obj.idOrganizacionaJedinica = this.get('selectedOrgJedinica').id;
        obj.idRadnoMesto = this.get('selectedRadnoMesto').idRadnoMesto;

        sifRadnoMestoStrukturaModel.create(obj, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('sifRadnoMestoStruktura.dodavanjeHeader').string,
            content: i18n.t('sifRadnoMestoStruktura.dodavanjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
          outter.toggleProperty('refresh');
        }, function (e) {
          $.Notify({
            caption: i18n.t('sifRadnoMestoStruktura.dodavanjeHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: true,
            type: 'alert'
          });
        });
      },
      update: function update() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifRadnoMestoStrukturaModel = Models['default'].SifRadnoMestoStruktura.create({ errorHandlerController: this });
        sifRadnoMestoStrukturaModel.update(this.get('modelObject'), this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t("sifRadnoMestoStruktura.azuriranjeHeader").string,
            content: i18n.t("sifRadnoMestoStruktura.azuriranjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
          outter.toggleProperty('refresh');
        });
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.set('componentName1', 'zaris-tree-table');
      var outter = this;
      setTimeout(function () {
        outter.set('componentName2', 'zaris-datatable');
      }, 100);

      setTimeout(function () {
        Ember['default'].showModalPanel('#sifRadnaMestaStrukturaNewModal');
      }, 120);
    }),

    closePanel: function closePanel() {
      $('.sifRadnaMestaStrukturaNewModal').data('dialog').close();
    }
  });

});