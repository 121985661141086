define('zaris-frontend/components/sys-korisnici-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sysKorisniciModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sysKorisniciModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            sysKorisniciModel = Models['default'].SysKorisnik.create({ errorHandlerController: _this });

            sysKorisniciModel.postResult("r/create/" + _this.get('pravnoLice'), _this.get('modelObject'), _this.get('login').getAccessToken(), function (o) {
              $.Notify({
                caption: i18n.t("sysKorisnik.dodavanjeHeader").string,
                content: i18n.t("sysKorisnik.dodavanjeUspesno").string + "<br/>" + i18n.t("sysKorisnik.usernameObavestenje") + outter.get('modelObject').username + "<br/>" + i18n.t("sysKorisnik.lozinkaObavestenje") + o,
                keepOpen: true,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refreshBaseTable');
            });
          })();
        }
      },
      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sysKorisniciModel;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');

            sysKorisniciModel = Models['default'].SysKorisnik.create({ errorHandlerController: _this2 });

            sysKorisniciModel.postResult("r/update/" + _this2.get('pravnoLice'), _this2.get('modelObject'), _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("sysKorisnik.azuriranjeHeader").string,
                content: i18n.t("sysKorisnik.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refreshBaseTable');
            });
          })();
        }
      }

    },
    openPanel: Ember['default'].observer('modalPanelShow', function () {
      Ember['default'].showModalPanel('#' + this.get('id'));
      if (!this.get('isNew')) {
        var outter = this;
        // dovlaci sysKorisnikPravnoLice po idKorisnik i idPravnoLice da bi izvukao fgArhivirano
        var sysKorisnikPravnoLiceModel = Models['default'].SysKorisnikPravnoLice.create({ errorHandlerController: this });

        var sysKorisnikPravnoLice = sysKorisnikPravnoLiceModel.getResult('e/' + this.get('pravnoLice') + '/' + this.get('modelObject').idKorisnik, this.get('login').getAccessToken(), 'GET');
        this.set('modelObject.fgArhivirano', sysKorisnikPravnoLice.fgArhivirano);

        setTimeout(function () {
          outter.set('componentName', 'zaris-datatable');
        }, 100);
      }
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('#' + this.get('id')).data('dialog').close();
    }

  });

});