define('zaris-frontend/templates/obr-obrazac08-unos', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac08-unos.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_izvesenim_ispitivanjima_radne_okoline"],[],["loc",[null,[4,28],[4,95]]]],
        ["inline","zaris-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[11,17],[11,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[12,18],[12,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","VobrObrazac08","domainId","idParametarOkoline","idModalPanel","obrObrazac08Modal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[19,16],[19,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[20,22],[20,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[21,18],[21,25]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[22,25],[22,41]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[23,22],[23,33]]]]],[],[]],"renderIzvestaji",true,"routeIzvestaji","obrObrazac08Izvestaji","renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac08Sacuvani","renderSearch",true,"renderCreate",false,"renderSacuvajObrazac",true,"renderDelete",false,"renderFilterArhivirano",true,"renderSifarnici",true,"routeSifarnici","sifParametriRadneOkoline","refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[35,34],[35,57]]]]],[],[]]],["loc",[null,[10,8],[36,10]]]],
        ["inline","obr-obrazac08-modal",[],["id","obrObrazac08Modal","target",["subexpr","@mut",[["get","this",["loc",[null,[44,11],[44,15]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[45,12],[45,19]]]]],[],[]],"isNewUnos",["subexpr","@mut",[["get","isNewUnos",["loc",[null,[46,14],[46,23]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[47,21],[47,37]]]]],[],[]],"selektovaniParametar",["subexpr","@mut",[["get","selectedRow",["loc",[null,[48,27],[48,38]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[49,28],[49,51]]]]],[],[]]],["loc",[null,[42,0],[50,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});