define('zaris-frontend/components/select-zaposleni-lov-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel5', 'sifZaposleniLovModal', 'panelHidden', 'windowTop'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "60%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel5Overlay",
    "data-type": "modal",

    contentZaposleni: Em.A(),
    modelObjectZaposleni: {},
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    saveEnabled: false,

    idModalPanel: 'idZaposleni',

    saveButtonConditionChanged: Ember['default'].observer('selectedRowZaposleni', function () {
      this.set('saveEnabled', this.get('selectedRowZaposleni') !== null && Object.keys(this.get('selectedRowZaposleni')).length !== 0);
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      choose: function choose() {
        var newObj = {};
        newObj.naziv = this.get('selectedRowZaposleni').imePrezime;
        newObj.id = this.get('selectedRowZaposleni').idZaposleni;

        this.set('obj', newObj);

        $('#' + this.get('id')).data('dialog').close();
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshZaposleni');

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);
      console.log(this.get('idModalPanel'));
      Ember['default'].showModalPanel('#' + this.get('id'));
    }),

    closePanel: function closePanel() {
      $('#' + this.get('id')).data('dialog').close();
    },

    tableColumnsZaposleni: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'interniMaticniBroj',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.interniMaticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'imePrezime',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.maticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.radnoMestoNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'organizacionaJedinicaNaziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.organizacionaJedinicaNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      return columns;
    })
  });

});