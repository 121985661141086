define('zaris-frontend/services/login', ['exports', 'zaris-frontend/models/models', 'zaris-frontend/conf', 'zaris-frontend/config/environment'], function (exports, Models, conf, ENV) {

  'use strict';

  exports['default'] = Ember.Service.extend({
    menuService: Ember.inject.service('menu'),
    token: localStorage.token,
    i18n: Ember.inject.service('i18n'),
    tokenChanged: (function () {
      localStorage.token = this.get('token');
    }).observes('token'),

    accessToken: localStorage.accessToken,
    accessTokenChanged: (function () {
      localStorage.accessToken = this.get('accessToken');
    }).observes('accessToken'),

    accessTokenExpires: localStorage.accessTokenExpires,
    accessTokenExpiresChanged: (function () {
      localStorage.accessTokenExpires = this.get('accessTokenExpires');
    }).observes('accessTokenExpires'),

    authenticate: function authenticate(identification, password) {
      var self = this;

      self.set('errorMessage', undefined);

      $.ajax({
        url: conf['default'].serverBaseUrl + '/oauth/token?grant_type=password',
        type: 'POST',
        accepts: 'application/json',
        async: false,
        data: { 'username': identification, 'password': password },
        crossDomain: true,
        headers: conf['default'].corsHeadersToken,
        success: function success(response) {
          //dohvatanje i snimanje refresh tokena
          self.set('token', response.refresh_token);
          self.set('accessToken', response.access_token);
          var accessTokenExpires = new Date().valueOf() + response.expires_in * 1000;
          self.set('accessTokenExpires', accessTokenExpires);

          var url = conf['default'].serverBaseUrl + '/korisnik/details?' + "access_token=" + self.getAccessToken();

          $.ajax({
            url: url,
            type: 'GET',
            accepts: 'application/json',
            crossDomain: true,
            async: false,
            headers: conf['default'].corsHeaders,
            success: function success(data) {

              if (data.locale == null || data.locale == undefined) {
                data.locale = ENV['default'].i18n.defaultLocale;
              }
              localStorage.locale = data.locale;
              self.set('i18n.locale', localStorage.locale);
              moment.locale(localStorage.locale);

              localStorage.activeSpinnerRequests = 0;

              var menuService = self.get('menuService');
              menuService.generateHTMLMenu();
            },
            error: function error(e) {
              console.log('DEBUG: GET Failed', e);
            }
          });
        },
        error: function error(e) {
          console.log(e);
          if (e.status == 401) {
            self.set('errorMessage', 'Bad credentials');
          }
          if (e.status == 403) {
            self.set('errorMessage', 'Unauthorized');
          }
          if (e.status == 0) {
            self.set('errorMessage', 'Service is unavailable or request is cancelled');
          }
        }
      });
    },

    getAccessToken: function getAccessToken() {
      var accessTokenExpires = null;

      //dohvatamo refresh token - ako nije validan idemo na login
      var refreshToken = this.get('token');
      if (refreshToken === undefined || refreshToken === null || refreshToken === 'null') {
        return null;
      }

      //dohvatamo access token
      var accessToken = this.get('accessToken');
      if (accessToken === undefined || accessToken === null || accessToken === 'null') {
        accessToken = null;
      }

      //gleda se da li je postojeci access token validan - ako jeste, vracamo njega
      var currentTimestamp = new Date().valueOf();
      if (this.get('accessTokenExpires') > currentTimestamp && accessToken !== null) {
        return this.get('accessToken');
      }

      $.ajax({
        url: conf['default'].serverBaseUrl + '/oauth/token?grant_type=refresh_token',
        type: 'POST',
        data: { 'refresh_token': refreshToken },
        accepts: 'application/json',
        async: false,
        crossDomain: true,
        headers: conf['default'].corsHeadersToken,
        success: function success(data) {
          accessToken = data.access_token;
          accessTokenExpires = new Date().valueOf() + data.expires_in * 1000;
        },
        error: function error() {
          console.log('error getting token');
          if (localStorage.idPravnoLice == '' || localStorage.idPravnoLice == undefined) {
            localStorage.menuHTML = '';
            var menuService = self.get('menuService');
            menuService.set('menuHTML', '');
          }
        }
      });

      this.set('accessToken', accessToken);
      this.set('accessTokenExpires', accessTokenExpires);

      return accessToken;
    }

  });

});