define('zaris-frontend/routes/obr-obrazac02a-unos', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    login: Ember['default'].inject.service('login'),

    model: function model() {
      var loginService = this.get('login');
      var sifZdravstveniPregledModel = Models['default'].SifZdravstveniPregled.create({ errorHandlerController: this });
      var items = sifZdravstveniPregledModel.findAllGetResult(loginService.getAccessToken());

      return Ember['default'].RSVP.hash({
        selectItems: items
      });
    }
  });

});