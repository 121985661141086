define('zaris-frontend/controllers/obr-obrazac03-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    // zaposleni
    zaposleniObj: {},

    // izbor radnih mesta
    radnaMesta: "radnaMesta",

    // idRadnoMesto - ukoliko je izabrano radno mesto
    idRadnoMesto: null,

    // idRadnoMestoStruktura - ukoliko je izabrano radno mesto u strukturi
    idRadnoMestoStruktura: null,

    // id mesto povrede
    idMestoPovrede: null,

    // id izvor povrede
    idIzvorPovrede: null,

    // id uzrok povrede
    idUzrokPovrede: null,

    // radio button odabir
    odabir: 'bez_nadgrupisanja',

    // po cemu se grupise izvestaj
    groupBy: 'rm',

    // sumarni ili detaljan
    vrstaIzv: "0",

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;

      var zaposleniObj = this.get('zaposleniObj');
      zaposleniObj.id = null;
      zaposleniObj.naziv = "";
    },

    actions: {

      generate: function generate() {

        // prepakovanje objekta
        var pObj = {};

        pObj.p_id_lice = null;
        var zaposleniObj = this.get('zaposleniObj');
        var zaposleniModel = Models['default'].SifZaposleni.create({ errorHandlerController: this });
        if (zaposleniObj.id) {
          var lice = zaposleniModel.getResult('liceByZaposleni/' + zaposleniObj.id, this.get('loginService').getAccessToken(), 'GET');
          pObj.p_id_lice = lice.idLice;
        }

        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        var idMestoPovrede = this.get('idMestoPovrede');
        pObj.p_id_mesto_povrede = idMestoPovrede;

        var idUzrokPovrede = this.get('idUzrokPovrede');
        pObj.p_id_uzrok_povrede = idUzrokPovrede;

        var idIzvorPovrede = this.get('idIzvorPovrede');
        pObj.p_id_izvor_povrede = idIzvorPovrede;

        var radnaMesta = this.get('radnaMesta');
        var idRadnoMesto = this.get('idRadnoMesto');
        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        //  pObj.p_sva_radna_mesta = 0;
        pObj.p_id_radno_mesto = null;
        pObj.p_id_radno_mesto_struktura = null;

        switch (radnaMesta) {
          case "radnaMesta":
            //    pObj.p_sva_radna_mesta = 1;
            break;
          case "radnoMesto":
            pObj.p_id_radno_mesto = idRadnoMesto;
            break;
          case "radnoMestoStruktura":
            pObj.p_id_radno_mesto_struktura = idRadnoMestoStruktura;
            break;
          default:
            console.log('error ', radnaMesta);
            break;
        }

        var odabir = this.get('odabir');
        pObj.p_odabir = odabir;

        var groupBy = this.get('groupBy');
        pObj.p_labela = groupBy;

        var vrstaIzv = this.get('vrstaIzv');
        pObj.p_fg_sumarni = vrstaIzv;

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_03';
        izvestajiDTO.reportParameters = pObj;

        //this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});