define('zaris-frontend/templates/bz-opasne-materije', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/bz-opasne-materije.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 580px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_opasnim_materijama_koje_se_koriste_u_toku_dana"],[],["loc",[null,[5,28],[5,104]]]],
        ["inline","zaris-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","BzOpasnaMaterija","domainId","id","titleMsgKey","sifOpasneMaterije.brisanjeHeader","descYesNoMsgKey","sifOpasneMaterije.brisanjePitanje","descDeleteSuccessMsgKey","sifOpasneMaterije.brisanjeUspesno","titleDeleteMsgKey","sifOpasneMaterije.brisanjeHeader","descDeleteQuestionMsgKey","sifOpasneMaterije.brisanjePitanje","descDeleteSuccessMsgKey","sifOpasneMaterije.brisanjeUspesno","idModalPanel","bzOpasneMaterijeModal","renderCreate",false,"renderDelete",false,"renderFilterArhivirano",false,"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[27,16],[27,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[28,22],[28,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[29,18],[29,25]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[30,25],[30,41]]]]],[],[]],"datatableWriteRole","ROLE_USER"],["loc",[null,[9,8],[32,10]]]],
        ["inline","bz-opasne-materije-modal",[],["id","bzOpasneMaterijeModal","target",["subexpr","@mut",[["get","this",["loc",[null,[39,11],[39,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[40,10],[40,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[41,16],[41,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[42,12],[42,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[43,19],[43,35]]]]],[],[]]],["loc",[null,[37,0],[44,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});