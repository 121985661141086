define('zaris-frontend/controllers/obr-obrazac13-unos-old', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/models/models'], function (exports, Ember, ColumnDefinition, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    isNewUnos: true,
    selectedRow: null,

    usmenoDatumFormatted: Ember['default'].computed('selectedRow', function () {
      return this.get('selectedRow.usmenoDatum') !== null ? moment(this.get('selectedRow.usmenoDatum')).format('DD.MM.YYYY.') : '';
    }),

    pismenoDatumFormatted: Ember['default'].computed('selectedRow', function () {
      return this.get('selectedRow.pismenoDatum') !== null ? moment(this.get('selectedRow.pismenoDatum')).format('DD.MM.YYYY.') : '';
    }),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];
      var outter = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'imePrezime',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac13.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac13.radnoMestoNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'dijagnoza',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac13.dijagnoza'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});