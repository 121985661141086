define('zaris-frontend/templates/components/sys-korisnik-postojeci-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sys-korisnik-postojeci-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element4, 'class');
        morphs[4] = dom.createElementMorph(element4);
        morphs[5] = dom.createMorphAt(element4,2,2);
        morphs[6] = dom.createElementMorph(element5);
        morphs[7] = dom.createMorphAt(element5,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["sysKorisnik.dodavanjePostojecegKorisnika"],[],["loc",[null,[6,10],[6,58]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","component",[["get","componentLov",["loc",[null,[13,14],[13,26]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[14,11],[14,15]]]]],[],[]],"content",["subexpr","@mut",[["get","contentPostojeci",["loc",[null,[15,12],[15,28]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[16,12],[16,24]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName",["subexpr","@mut",[["get","domainName",["loc",[null,[20,15],[20,25]]]]],[],[]],"domainId",["subexpr","@mut",[["get","domainId",["loc",[null,[21,13],[21,21]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRowPostojeci",["loc",[null,[22,16],[22,36]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshPostojeci",["loc",[null,[23,12],[23,28]]]]],[],[]],"renderOnlySearch",true,"customId",["subexpr","@mut",[["get","customId",["loc",[null,[25,13],[25,21]]]]],[],[]]],["loc",[null,[13,2],[26,4]]]],
        ["attribute","class",["concat",["button ",["subexpr","if",[["get","saveEnabled",["loc",[null,[30,51],[30,62]]]],"success","zarisDisableButton"],[],["loc",[null,[30,46],[30,95]]]]]]],
        ["element","action",["choose"],[],["loc",[null,[30,12],[30,31]]]],
        ["inline","t",["app.common.choose"],[],["loc",[null,[30,133],[30,158]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[31,12],[31,38]]]],
        ["inline","t",["app.common.close"],[],["loc",[null,[31,86],[31,110]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});