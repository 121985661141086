define('zaris-frontend/controllers/obr-obrazac1-unos', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;
  exports['default'] = Ember['default'].Controller.extend({
    //selected domain name
    i18n: Ember['default'].inject.service('i18n'),
    selectedDomainName: "radnoMesto",

    selectedDomain: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'ObrObrazac1RmOpasnosti' : 'ObrObrazac1RmsOpasnosti';
    }),

    selectedDomainId: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'idObrazac1RmOpasnosti' : 'idObrazac1RmsOpasnosti';
    }),

    selectedDomainView: Ember['default'].computed('selectedDomain', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'VobrObrazac1Rm' : 'VobrObrazac1Rms';
    }),

    selectedReferencedDomain: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'SifRadnoMesto' : 'SifRadnoMestoStruktura';
    }),

    selectedReferencedDomainId: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'idRadnoMesto' : 'idRadnoMestoStruktura';
    }),

    selectedSifarniciRoute: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'sifRadnaMesta' : 'sifRadnoMestoStruktura';
    }),

    valueFactors: Ember['default'].computed('valueFactors', function () {
      var i18n = this.get('i18n');
      return Ember['default'].A([{
        'display_name': i18n.t('obrazac1.nivoRizika').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('obrazac1.napomena').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('obrazac1.brojOpasnosti').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('obrazac1.brojZaposlenih').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }]);
    })

  });

});