define('zaris-frontend/controllers/sif-nivoi-rizika', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service('i18n'),

    tableColumns: Ember['default'].computed(function () {
      var nazivColumn = ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifNivoiRizika.inputNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get('sifra') + ' - ' + row.get(this.key);
        }
      });
      var napomenaColumn = ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 600,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifNivoiRizika.inputNapomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      return [nazivColumn, napomenaColumn];
    })

  });

});