define('zaris-frontend/helpers/not-null', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var arg1 = params[0];

    return arg1 !== null;
  });

});