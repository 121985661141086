define('zaris-frontend/controllers/sys-odabir-potpisnika', ['exports', 'ember', 'zaris-frontend/conf', 'zaris-frontend/models/models'], function (exports, Ember, conf, Models) {

  'use strict';

  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({
    i18n: inject.service(),
    login: Ember['default'].inject.service('login'),

    actions: {
      update: function update() {
        var i18n = this.get('i18n');

        var pravnoLiceModel = Models['default'].XsysPravnoLice.create({ errorHandlerController: this });
        pravnoLiceModel.getResultById('setIdPotpisnika', this.get('model.idLicePotpisnik'), this.get('login').getAccessToken(), 'POST', function () {
          $.Notify({
            caption: i18n.t('sysPotpisnik.odabirPotpisnika').string,
            content: i18n.t('sysPotpisnik.odabirPotpisnikaUspesno').string,
            keepOpen: false,
            type: 'success'
          });
        });
      }
    }
  });

});