define('zaris-frontend/components/obr-obrazac13-pregled-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'obrObrazac13PregledModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "55%",
    "data-width": "55%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",
    "data-type": "modal",

    elementiPregledaContent: Em.A(),
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    setSledeciPregled: Ember['default'].observer('modelObject.datumPregleda', function () {

      if (this.get('isNew')) {
        var interval = this.get('selektovaniRed.intervalMeseci');
        if (this.get('modelObject.datumPregleda') && interval && !isNaN(interval)) {
          this.set('modelObject.datumSledecegPregleda', moment(this.get('modelObject.datumPregleda')).add(interval, 'months').toDate());
        }
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      this.$('.obrObrazac13PregledModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      merge: function merge() {
        var i18n = this.get('i18n');

        if (this.get('validate').validate(this)) {
          var outter = this;
          var modelObject = this.get('modelObject');

          modelObject.sifLica = {};
          modelObject.sifLica.idLice = this.get('selektovaniRed.id.idLice');
          modelObject.sifZdravstveniPregled = {};
          modelObject.sifZdravstveniPregled.idZdravstveniPregled = this.get('selektovaniRed.id.idZdravstveniPregled');;

          var obrObrazac13SpecijalanPregledModel = Models['default'].ObrObrazac13SpecijalanPregled.create({ errorHandlerController: this });
          obrObrazac13SpecijalanPregledModel.create(modelObject, this.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('obrObrazac02.azuriranjeHeader').string,
              content: i18n.t('obrObrazac02.azuriranjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
            outter.closePanel();
            outter.toggleProperty('refreshObrazac13Modal');
          });
        }
      }

    },

    initPanel: function initPanel() {
      var outter = this;

      if (this.get('isNew')) {
        this.set('modelObject', {});
      } else {
        this.set('modelObject', jQuery.extend(true, {}, this.get('selectedRowPregled')));
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.get('validate').hideValidations(this);

      this.initPanel();

      var outter = this;
      setTimeout(function () {
        outter.set('componentName', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac13PregledModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.obrObrazac13PregledModal').data('dialog').close();
    }

  });

});