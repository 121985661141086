define('zaris-frontend/templates/components/sif-obuka-dokument-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 4
            },
            "end": {
              "line": 53,
              "column": 4
            }
          },
          "moduleName": "zaris-frontend/templates/components/sif-obuka-dokument-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","input-control file");
          dom.setAttribute(el1,"data-role","input");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","file");
          dom.setAttribute(el2,"name","file");
          dom.setAttribute(el2,"id","obuka_dokument");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","button");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","mif-folder");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),3,3);
          return morphs;
        },
        statements: [
          ["inline","t",["fileUpload.odaberiteFajl"],[],["loc",[null,[47,11],[47,43]]]],
          ["inline","input-validation",[],["type","hidden","hint","app.common.fileValidationMsg","value",["subexpr","@mut",[["get","fileUploaded",["loc",[null,[50,83],[50,95]]]]],[],[]],"showState",false],["loc",[null,[50,8],[50,113]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-obuka-dokument-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control textarea full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2,"id","form_id1");
        dom.setAttribute(el2,"method","POST");
        dom.setAttribute(el2,"enctype","multipart/form-data");
        dom.setAttribute(el2,"action","http://localhost:8080/zaris-backend/files/upload");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" data-on-before-submit=\"function pd(){console.log('ssssssssssss'); return false;}\"  novalidate=\"novalidate\" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [28]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element3, [5]);
        var morphs = new Array(19);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [6]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [8]),1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [11]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [13]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [16]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element2, [18]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element2, [20]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element2, [22]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element2, [24]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element2, [26]),1,1);
        morphs[14] = dom.createMorphAt(element3,1,1);
        morphs[15] = dom.createElementMorph(element4);
        morphs[16] = dom.createMorphAt(element4,2,2);
        morphs[17] = dom.createElementMorph(element5);
        morphs[18] = dom.createMorphAt(element5,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",[["subexpr","if",[["get","isNew",["loc",[null,[6,18],[6,23]]]],"sifKorisnickiDefinisaneObuke.drugiTab.dodavanjeHeader","sifKorisnickiDefinisaneObuke.drugiTab.azuriranjeHeader"],[],["loc",[null,[6,14],[6,137]]]]],[],["loc",[null,[6,10],[6,139]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.drugiTab.inputNaziv"],[],["loc",[null,[15,11],[15,67]]]],
        ["inline","input-validation",[],["value",["subexpr","@mut",[["get","modelObject.naziv",["loc",[null,[17,33],[17,50]]]]],[],[]]],["loc",[null,[17,8],[17,52]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.drugiTab.inputSifra"],[],["loc",[null,[20,11],[20,67]]]],
        ["inline","input-validation",[],["validType","maxlength","arg",8,"showState",false,"value",["subexpr","@mut",[["get","modelObject.sifra",["loc",[null,[22,77],[22,94]]]]],[],[]]],["loc",[null,[22,8],[22,96]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.drugiTab.inputVerzija"],[],["loc",[null,[25,11],[25,69]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","modelObject.verzija",["loc",[null,[27,22],[27,41]]]]],[],[]]],["loc",[null,[27,8],[27,43]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.drugiTab.inputVrstaDokumenta"],[],["loc",[null,[30,11],[30,76]]]],
        ["inline","select-items",[],["nullLabel","app.common.choose","nullChoice",true,"domainName","SifVrstaDokumenta","value",["subexpr","@mut",[["get","modelObject.sifVrstaDokumenta.idVrstaDokumenta",["loc",[null,[32,104],[32,150]]]]],[],[]]],["loc",[null,[32,6],[32,152]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.drugiTab.inputDatum"],[],["loc",[null,[35,11],[35,67]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datumVreme",["loc",[null,[37,42],[37,64]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[37,8],[37,86]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.drugiTab.inputOpis"],[],["loc",[null,[40,11],[40,66]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.opis",["loc",[null,[42,23],[42,39]]]]],[],[]]],["loc",[null,[42,6],[42,41]]]],
        ["block","if",[["get","isNew",["loc",[null,[46,10],[46,15]]]]],[],0,null,["loc",[null,[46,4],[53,11]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[55,25],[55,30]]]],"create","update"],[],["loc",[null,[55,21],[55,49]]]]],[],["loc",[null,[55,12],[55,51]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[55,103],[55,128]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[56,12],[56,38]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[56,86],[56,111]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});