define('zaris-frontend/controllers/home', ['exports', 'ember', 'zaris-frontend/models/models', 'zaris-frontend/conf'], function (exports, Ember, Models, conf) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  var HomeController = Ember['default'].Controller.extend({
    homeService: Ember['default'].inject.service('home'),
    loginService: Ember['default'].inject.service('login'),
    obrasci: Ember['default'].computed.reads('homeService.menuItemObrasci'),
    sifarnici: Ember['default'].computed.reads('homeService.menuItemSifarnici'),

    actions: {
      refreshObavestenja: function refreshObavestenja() {
        var outter = this;

        $("#refreshObavestenjaButton").addClass("mif-ani-pulse");

        var semRezultatModel = Models['default'].SemSemaforRezultat.create({ errorHandlerController: this });
        semRezultatModel.postResult("refreshObavestenja", null, this.get('loginService').getAccessToken(), function () {
          setTimeout(function () {
            $("#refreshObavestenjaButton").removeClass("mif-ani-pulse");
          }, 600);
          outter.send("refreshData");
        }, function (e) {

          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });

          $("#refreshObavestenjaButton").removeClass("mif-ani-pulse");
        });
      }
    }

  });

  exports['default'] = HomeController;

});