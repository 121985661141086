define('zaris-frontend/services/validate', ['exports', 'zaris-frontend/conf'], function (exports, conf) {

    'use strict';

    exports['default'] = Ember.Service.extend({

        validate: function validate(form, divClass) {
            var that = this,
                element = form.element,
                o = this.options;
            var selector = divClass !== undefined ? "div." + divClass + " [data-validate-func]" : "[data-validate-func]";
            var inputs = form.$(selector);
            var submit = form.$(":submit").attr('disabled', 'disabled').addClass('disabled');

            var result = 0;
            $('.validator-hint').hide();
            inputs.removeClass('error success');
            $.each(inputs, function (i, v) {
                var input = $(v);
                if (input.parent().hasClass('input-control')) {
                    input.parent().removeClass('error success');
                }
            });

            $.each(inputs, function (i, v) {
                var input = $(v);

                var func = input.data('validateFunc'),
                    arg = input.data('validateArg');
                if (!input.attr('disabled')) {

                    var this_result = that.funcs[func](input.val(), arg);

                    if (!this_result) {
                        if (typeof o.onErrorInput === 'function') {
                            o.onErrorInput(input);
                        } else {
                            if (typeof window[o.onErrorInput] === 'function') {
                                window[o.onErrorInput](input);
                            } else {
                                result = eval("(function(){" + o.onErrorInput + "})");
                                result.call(input);
                            }
                        }
                    }

                    if (!this_result && o.showErrorState) {
                        that._showError(input);
                    }
                    if (!this_result && o.showErrorHint) {
                        setTimeout(function () {
                            that._showErrorHint(input);
                        }, i * 100);
                    }
                    if (this_result && o.showSuccessState) {
                        that._showSuccess(input);
                    }
                    if (!this_result && i == 0 && o.focusInput) {
                        input.focus();
                    }
                    result += !this_result ? 1 : 0;
                }
            });

            if (typeof o.onBeforeSubmit === 'function') {
                result += !o.onBeforeSubmit(element, result) ? 1 : 0;
            } else {
                if (typeof window[o.onBeforeSubmit] === 'function') {
                    result += window[o.onBeforeSubmit](element, result) ? 1 : 0;
                } else {
                    var f0 = eval("(function(){" + o.onBeforeSubmit + "})");
                    result += f0.call(element, result) ? 1 : 0;
                }
            }

            if (result !== 0) {
                submit.removeAttr('disabled').removeClass('disabled');
                return false;
            }

            if (typeof o.onSubmit === 'function') {
                result = o.onSubmit(element[0]);
            } else {
                if (typeof window[o.onSubmit] === 'function') {
                    result = window[o.onSubmit](element[0]);
                } else {
                    var f = eval("(function(){" + o.onSubmit + "})");
                    result = f.call(element[0]);
                }
            }

            submit.removeAttr('disabled').removeClass('disabled');

            return result;
        },

        hideValidations: function hideValidations(form) {
            $('.validator-hint').remove();
            $('.input-control').removeClass('success');
            $('.input-control').removeClass('error');
        },

        funcs: {
            required: function required(val) {
                return val.trim() !== "";
            },
            minlength: function minlength(val, len) {
                if (len == undefined || isNaN(len) || len <= 0) {
                    return false;
                }
                return val.trim().length >= len;
            },
            maxlength: function maxlength(val, len) {
                if (len == undefined || isNaN(len) || len <= 0) {
                    return false;
                }
                return val.trim().length <= len;
            },
            required_maxlength: function required_maxlength(val, len) {
                if (len == undefined || isNaN(len) || len <= 0) {
                    return false;
                }
                var length = val.trim().length;
                return length <= len && length > 0;
            },
            min: function min(val, min_value) {
                if (min_value == undefined || isNaN(min_value)) {
                    return false;
                }
                if (val.trim() === "") {
                    return false;
                }
                if (isNaN(val)) {
                    return false;
                }
                return val >= min_value;
            },
            max: function max(val, max_value) {
                if (max_value == undefined || isNaN(max_value)) {
                    return false;
                }
                if (val.trim() === "") {
                    return false;
                }
                if (isNaN(val)) {
                    return false;
                }
                return val <= max_value;
            },
            positive_max: function positive_max(val, max_value) {
                if (max_value == undefined || isNaN(max_value)) {
                    return false;
                }
                if (val.trim() === "") {
                    return false;
                }
                if (isNaN(val)) {
                    return false;
                }
                return val <= max_value && val >= 1;
            },
            positive: function positive(val) {
                if (val.trim() === "") {
                    return false;
                }
                if (isNaN(val)) {
                    return false;
                }
                return val >= 1;
            },
            email: function email(val) {
                return (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(val)
                );
            },
            url: function url(val) {
                return (/^(?:[a-z]+:)?\/\//i.test(val)
                );
            },
            date: function date(val) {
                return !!(new Date(val) !== "Invalid Date" && !isNaN(new Date(val)));
            },
            number: function number(val) {
                return val - 0 == val && ('' + val).trim().length > 0;
            },
            non_required_number: function non_required_number(val) {
                return val - 0 == val;
            },
            digits: function digits(val) {
                return (/^\d+$/.test(val)
                );
            },
            hexcolor: function hexcolor(val) {
                return (/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(val)
                );
            },
            year: function year(val) {
                return (/^[0-9]{4}$/.test(val)
                );
            },
            maticniBroj: function maticniBroj(val) {
                if (val.trim() === "" || /^\d+$/.test(val) && ('' + val).trim().length == 8) return true;
                return false;
            },
            jmbg: function myself(_x) {
                var _again = true;

                _function: while (_again) {
                    var val = _x;
                    _again = false;

                    if (val.trim() === "") return true;

                    if (val.length != 13) return false;

                    if (val.substring(7, 9) === "66") {
                        _x = val.substring(0, 7) + "06" + val.substring(9);
                        _again = true;
                        continue _function;
                    }

                    var p = new Array();
                    var p13 = 0;

                    p[0] = parseInt("" + val.charAt(0)) * 7;
                    p[1] = parseInt("" + val.charAt(1)) * 6;
                    p[2] = parseInt("" + val.charAt(2)) * 5;
                    p[3] = parseInt("" + val.charAt(3)) * 4;
                    p[4] = parseInt("" + val.charAt(4)) * 3;
                    p[5] = parseInt("" + val.charAt(5)) * 2;
                    p[6] = parseInt("" + val.charAt(6)) * 7;
                    p[7] = parseInt("" + val.charAt(7)) * 6;
                    p[8] = parseInt("" + val.charAt(8)) * 5;
                    p[9] = parseInt("" + val.charAt(9)) * 4;
                    p[10] = parseInt("" + val.charAt(10)) * 3;
                    p[11] = parseInt("" + val.charAt(11)) * 2;

                    for (var i = 0; i < 12; i++) {
                        p13 += p[i];
                    }p13 = p13 % 11;
                    p13 = 11 - p13;

                    if (p13 == 11) p13 = 0;

                    if (p13 == 10 || p13 != parseInt("" + val.charAt(12))) return false;else return true;
                }
            },
            pattern: function pattern(val, pat) {
                if (pat == undefined) {
                    return false;
                }
                var reg = new RegExp(pat);
                return reg.test(val);
            }
        },

        options: {
            showErrorState: true,
            showErrorHint: true,
            showRequiredState: true,
            showSuccessState: true,
            hintSize: 0,
            hintBackground: '#FFFCC0',
            hintColor: '#000000',
            hideError: 2000,
            hideHint: 5000,
            hintEasing: 'easeInQuad',
            hintEasingTime: 400,
            hintMode: 'line', // hint, line
            hintPosition: 'right',
            focusInput: true,
            onBeforeSubmit: function onBeforeSubmit(form, result) {
                return true;
            },
            onErrorInput: function onErrorInput(input) {},
            onSubmit: function onSubmit(form) {
                return true;
            }
        },

        _scroll: 0,

        _showSuccess: function _showSuccess(input) {
            if (input.parent().hasClass('input-control')) {
                input.parent().addClass('success');
            } else {
                input.addClass('success');
            }
        },

        _showError: function _showError(input) {
            var o = this.options;

            if (input.parent().hasClass('input-control')) {
                input.parent().addClass('error');
            } else {
                input.addClass('error');
            }

            if (o.hideError && o.hideError > 0) {
                setTimeout(function () {
                    input.parent().removeClass('error');
                }, o.hideError);
            }
        },

        _showErrorHint: function _showErrorHint(input) {
            var o = this.options,
                msg = input.data('validateHint'),
                pos = input.data('validateHintPosition') || o.hintPosition,
                mode = input.data('validateHintMode') || o.hintMode,
                background = input.data('validateHintBackground') || o.hintBackground,
                color = input.data('validateHintColor') || o.hintColor;

            var hint, top, left;

            if (msg === undefined) {
                return false;
            }

            hint = $("<div/>").addClass(mode + ' validator-hint'); //.appendTo(input.parent());
            hint.html(msg !== undefined ? this._format(msg, input.val()) : '');
            hint.css({
                'min-width': o.hintSize
            });

            if (background.isColor()) {
                hint.css('background-color', background);
            } else {
                hint.addClass(background);
            }

            if (color.isColor()) {
                hint.css('color', color);
            } else {
                hint.addClass(color);
            }

            // Position
            if (mode === 'line') {
                hint.addClass('hint2').addClass('line');
                hint.css({
                    'width': input.parent().hasClass('input-control') ? input.parent().width() : input.width(),
                    'z-index': 100
                });
                hint.appendTo(input.parent());
                hint.fadeIn(o.hintEasingTime, function () {
                    setTimeout(function () {
                        hint.hide().remove();
                    }, o.hideHint);
                });
            } else {
                hint.appendTo("body");
                // right
                if (pos === 'right') {
                    left = input.offset().left + input.outerWidth() + 15 - $(window).scrollLeft();
                    top = input.offset().top + input.outerHeight() / 2 - hint.outerHeight() / 2 - $(window).scrollTop() - 10;

                    hint.addClass(pos);
                    hint.css({
                        top: top,
                        left: $(window).width() + 100
                    });
                    hint.show().animate({
                        left: left
                    }, o.hintEasingTime, o.hintEasing, function () {
                        setTimeout(function () {
                            hint.hide().remove();
                        }, o.hideHint);
                    });
                } else if (pos === 'left') {
                    left = input.offset().left - hint.outerWidth() - 10 - $(window).scrollLeft();
                    top = input.offset().top + input.outerHeight() / 2 - hint.outerHeight() / 2 - $(window).scrollTop() - 10;

                    hint.addClass(pos);
                    hint.css({
                        top: top,
                        left: -input.offset().left - hint.outerWidth() - 10
                    });
                    hint.show().animate({
                        left: left
                    }, o.hintEasingTime, o.hintEasing, function () {
                        setTimeout(function () {
                            hint.hide().remove();
                        }, o.hideHint);
                    });
                } else if (pos === 'top') {
                    left = input.offset().left + input.outerWidth() / 2 - hint.outerWidth() / 2 - $(window).scrollLeft();
                    top = input.offset().top - $(window).scrollTop() - hint.outerHeight() - 20;

                    hint.addClass(pos);
                    hint.css({
                        top: -hint.outerHeight(),
                        left: left
                    }).show().animate({
                        top: top
                    }, o.hintEasingTime, o.hintEasing, function () {
                        setTimeout(function () {
                            hint.hide().remove();
                        }, o.hideHint);
                    });
                } else /*bottom*/{
                        left = input.offset().left + input.outerWidth() / 2 - hint.outerWidth() / 2 - $(window).scrollLeft();
                        top = input.offset().top - $(window).scrollTop() + input.outerHeight();

                        hint.addClass(pos);
                        hint.css({
                            top: $(window).height(),
                            left: left
                        }).show().animate({
                            top: top
                        }, o.hintEasingTime, o.hintEasing, function () {
                            setTimeout(function () {
                                hint.hide().remove();
                            }, o.hideHint);
                        });
                    }
            }

            //adding z-index for usage on modal panels
            hint.css('z-index', 1999);
        },

        _format: function _format(source, params) {
            if (arguments.length === 1) {
                return function () {
                    var args = $.makeArray(arguments);
                    args.unshift(source);
                    return $.validator.format.apply(this, args);
                };
            }
            if (arguments.length > 2 && params.constructor !== Array) {
                params = $.makeArray(arguments).slice(1);
            }
            if (params.constructor !== Array) {
                params = [params];
            }
            $.each(params, function (i, n) {
                source = source.replace(new RegExp("\\{" + i + "\\}", "g"), function () {
                    return n;
                });
            });
            return source;
        },

        _destroy: function _destroy() {},

        _setOption: function _setOption(key, value) {
            this._super('_setOption', key, value);
        }

    });

});