define('zaris-frontend/components/sif-oprema-za-rad-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifOpremaZaRadModal'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    organizacionaJedinicaContent: Em.A(),

    componentName1: null,

    selectedOrgJedinica: null,
    saveEnabled: false,

    saveButtonConditionChanged: Ember['default'].observer('selectedOrgJedinica', function () {
      this.set('saveEnabled', this.get('selectedOrgJedinica') !== null);
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifOpremaZaRadModel;
          var obj;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            sifOpremaZaRadModel = Models['default'].SifOpremaZaRad.create({ errorHandlerController: _this });
            obj = _this.get('modelObject');

            obj.sysOrganizacionaJedinica = {};
            obj.sysOrganizacionaJedinica.idOrganizacionaJedinica = _this.get('selectedOrgJedinica').id;

            sifOpremaZaRadModel.create(obj, _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('sifOpremaZaRad.dodavanjeHeader').string,
                content: i18n.t('sifOpremaZaRad.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.toggleProperty('refresh');
              outter.closePanel();
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifOpremaZaRad.dodavanjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      },
      update: function update() {
        var _this2 = this;

        console.log('azuriranje');
        if (this.get('validate').validate(this)) {
          var outter;
          var obj;
          var sifOpremaZaRadModel;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');
            obj = _this2.get('modelObject');
            sifOpremaZaRadModel = Models['default'].SifOpremaZaRad.create({ errorHandlerController: _this2 });

            sifOpremaZaRadModel.update(obj, _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("sifOpremaZaRad.azuriranjeHeader").string,
                content: i18n.t("sifOpremaZaRad.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.toggleProperty('refresh');
              outter.closePanel();
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifOpremaZaRad.azuriranjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.set('componentName1', 'zaris-tree-table');

      setTimeout(function () {
        Ember['default'].showModalPanel('#sifOpremaZaRadModal');
      }, 120);
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifOpremaZaRadModal').data('dialog').close();
    }
  });

});