define('zaris-frontend/components/select-radno-mesto-lov-modal', ['exports', 'ember', 'zaris-frontend/components/select-datatable-lov-modal', 'ember-table/models/column-definition'], function (exports, Ember, SelectDatatableLovModal, ColumnDefinition) {

  'use strict';

  exports['default'] = SelectDatatableLovModal['default'].extend({

    i18n: Ember['default'].inject.service('i18n'),

    radnoMestoLovColumns: Ember['default'].computed(function () {
      var outter = this;
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnaMesta.inputSifra'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifRadnaMesta.inputNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});