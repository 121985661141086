define('zaris-frontend/controllers/vertikalni-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    zaposleniObj: {},

    osnovniPodaci: true,
    podaciORadnomMestuZaposlenog: true,
    opisRadnogMesta: true,
    opasnostiIStetnostiNaRadnomMestuZaposlenog: true,
    mereZaBezbedanIZdravRad: true,
    obavestenja: true,
    lekarskiPregledi: true,
    obavezniLekarskiPregledi: true,
    korisnickiDefinisaniLekarskiPregledi: true,
    profesionalnaOboljenja: true,
    bolestiUVeziSaRadom: true,
    povredeNaRadu: true,
    osposobljenosti: true,
    obavezneObuke: true,
    korisnickiDefinisaneObuke: true,
    opasneMaterije: true,
    licnaZastitnaOprema: true,

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var zaposleniObj = this.get('zaposleniObj');
      zaposleniObj.id = null;
      zaposleniObj.naziv = "";
    },

    actions: {

      generate: function generate() {
        var i18n = this.get('i18n');
        var pObj = {};

        var osnovniPodaci = this.get('osnovniPodaci');
        var podaciORadnomMestuZaposlenog = this.get('podaciORadnomMestuZaposlenog');
        var opisRadnogMesta = this.get('opisRadnogMesta');
        var opasnostiIStetnostiNaRadnomMestuZaposlenog = this.get('opasnostiIStetnostiNaRadnomMestuZaposlenog');
        var mereZaBezbedanIZdravRad = this.get('mereZaBezbedanIZdravRad');
        var obavestenja = this.get('obavestenja');
        var lekarskiPregledi = this.get('lekarskiPregledi');
        var obavezniLekarskiPregledi = this.get('obavezniLekarskiPregledi');
        var korisnickiDefinisaniLekarskiPregledi = this.get('korisnickiDefinisaniLekarskiPregledi');
        var profesionalnaOboljenja = this.get('profesionalnaOboljenja');
        var bolestiUVeziSaRadom = this.get('bolestiUVeziSaRadom');
        var povredeNaRadu = this.get('povredeNaRadu');
        var osposobljenosti = this.get('osposobljenosti');
        var obavezneObuke = this.get('obavezneObuke');
        var korisnickiDefinisaneObuke = this.get('korisnickiDefinisaneObuke');
        var opasneMaterije = this.get('opasneMaterije');
        var licnaZastitnaOprema = this.get('licnaZastitnaOprema');

        pObj.p_id_lice = null;
        var zaposleniObj = this.get('zaposleniObj');
        var zaposleniModel = Models['default'].SifZaposleni.create({ errorHandlerController: this });
        if (zaposleniObj.id) {
          var lice = zaposleniModel.getResult('liceByZaposleni/' + zaposleniObj.id, this.get('loginService').getAccessToken(), 'GET');
          pObj.p_id_lice = lice.idLice;
        }

        if (pObj.p_id_lice == null) {
          $.Notify({
            content: i18n.t('vertikalniIzvestaj.validacijaPoruka').string,
            keepOpen: false,
            type: 'alert'
          });

          return;
        }

        pObj.p_osnovni_podaci = osnovniPodaci ? "1" : "0";
        pObj.p_o_radnom_mestu = podaciORadnomMestuZaposlenog ? "1" : "0";
        pObj.p_opis = podaciORadnomMestuZaposlenog && opisRadnogMesta ? "1" : "0";
        pObj.p_opasnosti = podaciORadnomMestuZaposlenog && opasnostiIStetnostiNaRadnomMestuZaposlenog ? "1" : "0";
        pObj.p_mere = podaciORadnomMestuZaposlenog && mereZaBezbedanIZdravRad ? "1" : "0";
        pObj.p_obavestenja = podaciORadnomMestuZaposlenog && obavestenja ? "1" : "0";
        pObj.p_pregledi = lekarskiPregledi ? "1" : "0";
        pObj.p_obavezni_pregledi = lekarskiPregledi && obavezniLekarskiPregledi ? "1" : "0";
        pObj.p_kd_pregledi = lekarskiPregledi && korisnickiDefinisaniLekarskiPregledi ? "1" : "0";
        pObj.p_oboljenja = profesionalnaOboljenja ? "1" : "0";
        pObj.p_bolesti = bolestiUVeziSaRadom ? "1" : "0";
        pObj.p_povrede = povredeNaRadu ? "1" : "0";
        pObj.p_osposobljenosti = osposobljenosti ? "1" : "0";
        pObj.p_obavezne_obuke = osposobljenosti && obavezneObuke ? "1" : "0";
        pObj.p_kd_obuke = osposobljenosti && korisnickiDefinisaneObuke ? "1" : "0";
        pObj.p_opasne_materije = opasneMaterije ? "1" : "0";
        pObj.p_lzo = licnaZastitnaOprema ? "1" : "0";

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'vertikalni_izvestaj';
        izvestajiDTO.reportParameters = pObj;

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});