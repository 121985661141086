define('zaris-frontend/components/obr-obrazac09-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac09Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentObuke: Em.A(),
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.obrObrazac09PregledModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      novaObuka: function novaObuka() {
        //    this.set('fgUnos', true);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelPregled');
        }, 100);
      },

      azurirajObuku: function azurirajObuku() {
        //      this.set('fgUnos', false);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelPregled');
        }, 100);
      },

      initDelete: function initDelete() {
        //prepare and show yes/no modal panel
        this.get("target").send('openYesNoModal', 'idYesNoDialog', 'obrObrazac09.brisanjeHeader', 'obrObrazac09.brisanjePitanje', this, 'deleteConfirm');
      }

    },

    deleteConfirm: function deleteConfirm(self) {
      var loginService = self.get('login');
      var outter = self;

      var obrObrazac09ObukaModel = Models['default'].ObrObrazac09Obuka.create({ errorHandlerController: this });
      obrObrazac09ObukaModel['delete'](self.get('idRowSelectedObr6'), loginService.getAccessToken(), function () {
        //call ws to get new results, without deleted one
        outter.toggleProperty('refreshObrazac09Modal');

        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac09.brisanjeHeader', 'obrObrazac09.dodavanjeUspesno', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac09.brisanjeHeader', e.responseJSON.messages[0], 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshObrazac09Modal');
      this.set('idRowSelectedObr9', 0);

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac09Modal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshObrazac09Modal', function () {
      this.toggleProperty('refreshWoResetSelection');
    }),

    closePanel: function closePanel() {
      $('.obrObrazac09Modal').data('dialog').close();
    },

    tableColumnsPregled: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'brojNalaza',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.brojNalaza'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPregleda',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.datumPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledecegPregleda',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.datumSledecegPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.napomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});