define('zaris-frontend/routes/home', ['exports', 'zaris-frontend/models/models', 'zaris-frontend/routes/authenticated', 'ember'], function (exports, Models, Authenticated, Ember) {

  'use strict';

  exports['default'] = Authenticated['default'].extend({
    loginService: Ember['default'].inject.service('login'),
    homeService: Ember['default'].inject.service('home'),

    model: function model() {
      var homeService = this.get('homeService');
      var loginService = this.get('loginService');

      var sifarnici = homeService.menuItemSifarnici;
      var obrasci = homeService.menuItemObrasci;

      if (sifarnici == '') {}

      var semRezultatModel = Models['default'].SemSemaforRezultat.create({ errorHandlerController: this });
      var obavestenja = semRezultatModel.findAllGetResult(loginService.getAccessToken());

      return Ember['default'].RSVP.hash({
        sifarnici: sifarnici,
        obrasci: obrasci,
        obavestenja: obavestenja
      });
    },

    setupController: function setupController(controller, model) {
      this.set('controller.menuItems', model);
    },

    actions: {
      refreshData: function refreshData() {
        this.refresh();
      }
    }
  });

});