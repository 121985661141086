define('zaris-frontend/components/select-items', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    tagName: "",

    // login servis
    login: Ember['default'].inject.service('login'),

    selectItems: {},

    // da li je ukljucen null izbor
    nullChoice: true,
    nullLabel: 'app.common.chooseAll',

    // ime domena
    domainName: "",

    // ful-size select
    fullSize: true,

    styleClass: "",

    disabled: false,

    servicePath: null,

    firstItemValue: null,
    saveFirstItemValue: false,

    divClass: Ember['default'].computed('fullSize', 'styleClass', function () {
      var fullSize = this.get('fullSize');
      var styleClass = this.get('styleClass');
      return "input-control select" + (fullSize ? " full-size " : " ") + styleClass;
    }),

    didInsertElement: function didInsertElement() {

      var nullChoice = this.get('nullChoice');
      var domainName = this.get('domainName');
      var servicePath = this.get('servicePath');

      if (domainName !== "") {
        var loginService = this.get('login');

        var model = Models['default'][domainName].create({ errorHandlerController: this });

        if (servicePath == null) {
          this.set('selectItems', model.selectItemsGetResult(loginService.getAccessToken()));
        } else {
          this.set('selectItems', model.getAllResults(servicePath, loginService.getAccessToken(), 'GET'));
        }

        if (nullChoice) {
          this.set('value', null);
        } else {
          this.set('value', this.get('selectItems') !== undefined && this.get('selectItems')[0] !== undefined && this.get('selectItems') !== null && this.get('selectItems')[0] !== null ? this.get('selectItems')[0].value : null);
        }

        if (this.get('saveFirstItemValue')) {
          this.set('firstItemValue', this.get('value'));
        }
      }

      if (this.get('value') == undefined) {
        this.set('value', null);
      }
    }

  });

});