define('zaris-frontend/templates/components/body-table-container', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          },
          "moduleName": "zaris-frontend/templates/components/body-table-container.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","lazy-table-block",[],["content",["subexpr","@mut",[["get","bodyContent",["loc",[null,[6,18],[6,29]]]]],[],[]],"columns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[7,18],[7,30]]]]],[],[]],"blockWidth",["subexpr","@mut",[["get","fixedBlockWidth",["loc",[null,[8,21],[8,36]]]]],[],[]],"numItemsShowing",["subexpr","@mut",[["get","_numItemsShowing",["loc",[null,[9,26],[9,42]]]]],[],[]],"scrollTop",["subexpr","@mut",[["get","_scrollTop",["loc",[null,[10,20],[10,30]]]]],[],[]],"startIndex",["subexpr","@mut",[["get","_startIndex",["loc",[null,[11,21],[11,32]]]]],[],[]],"itemViewClass",["subexpr","@mut",[["get","itemViewClass",["loc",[null,[12,24],[12,37]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","rowHeight",["loc",[null,[13,20],[13,29]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","rowWidth",["loc",[null,[14,19],[14,27]]]]],[],[]],"rowDidClick","rowDidClick","toggleRowCollapse","toggleRowCollapse","class","ember-table-left-table-block"],["loc",[null,[5,8],[18,10]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/body-table-container.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","antiscroll-box");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","antiscroll-inner");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ember-table-table-scrollable-wrapper");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0,1,1);
        morphs[1] = dom.createMorphAt(element0,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","numFixedColumns",["loc",[null,[4,12],[4,27]]]]],[],0,null,["loc",[null,[4,6],[19,13]]]],
        ["inline","lazy-table-block",[],["content",["subexpr","@mut",[["get","bodyContent",["loc",[null,[21,16],[21,27]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[22,16],[22,28]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","scrollLeft",["loc",[null,[23,19],[23,29]]]]],[],[]],"blockWidth",["subexpr","@mut",[["get","tableBlockWidth",["loc",[null,[24,19],[24,34]]]]],[],[]],"numItemsShowing",["subexpr","@mut",[["get","_numItemsShowing",["loc",[null,[25,24],[25,40]]]]],[],[]],"scrollTop",["subexpr","@mut",[["get","_scrollTop",["loc",[null,[26,18],[26,28]]]]],[],[]],"startIndex",["subexpr","@mut",[["get","_startIndex",["loc",[null,[27,19],[27,30]]]]],[],[]],"itemViewClass",["subexpr","@mut",[["get","itemViewClass",["loc",[null,[28,22],[28,35]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","rowHeight",["loc",[null,[29,18],[29,27]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","rowWidth",["loc",[null,[30,17],[30,25]]]]],[],[]],"rowDidClick","rowDidClick","toggleRowCollapse","toggleRowCollapse","class","ember-table-right-table-block"],["loc",[null,[20,6],[34,8]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});