define('zaris-frontend/components/sys-korisnik-rola-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    selectedRowPostojeci: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    descDeleteSuccessMsgKey: "sysKorisnik.brisanjeUspesno",
    titleDeleteMsgKey: "sysKorisnik.brisanjeHeader",
    descDeleteQuestionMsgKey: "sysKorisnik.brisanjePitanje",

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0;
    }),

    didInsertElement: function didInsertElement() {
      this.$('.sysKorisnikRolaModal').appendTo(this.$(document.body));
      this.$('.sysPostojeciLovModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    deleteConfirm: function deleteConfirm(self) {
      var loginService = self.get('login');
      var outter = self;

      var sysKorisnikModel = Models['default'].SysKorisnik.create({ errorHandlerController: this });
      sysKorisnikModel.customDelete("delete/" + self.get('idRowSelected') + "/" + self.get('customId'), loginService.getAccessToken(), function () {
        //call ws to get new results, without deleted one
        outter.toggleProperty('refresh');
        outter.toggleProperty('refreshWoResetSelection');

        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), outter.get('descDeleteSuccessMsgKey'), 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');

        //reseting selection, as selected item does not exist anymore
        //outter.resetSelection();
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), e.responseJSON.messages[0], 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    actions: {
      initKorisnikRole: function initKorisnikRole() {
        this.toggleProperty('refreshKorisnikRolaModal');
        this.toggleProperty('myModalPanelShow');
      },
      initPostojeci: function initPostojeci() {
        this.toggleProperty('refreshPostojeci');
        this.toggleProperty('myModalPanel2Show');
      },
      initDelete: function initDelete() {
        this.get("target").send('openYesNoModal', 'idYesNoDialog', this.get('titleDeleteMsgKey'), this.get('descDeleteQuestionMsgKey'), this, 'deleteConfirm');
      },
      resetPassword: function resetPassword() {
        var outter = this;
        var i18n = this.get('i18n');
        var userModel = Models['default'].SysKorisnik.create({ errorHandlerController: this });
        userModel.getById('resetPassword', this.get('selectedRow').idKorisnik, this.get('login').getAccessToken(), 'GET', function (o) {
          $.Notify({
            caption: i18n.t('sysKorisnik.resetLozinkeHeader').string,
            //  content: i18n.t('sysKorisnik.resetLozinkeUspesno').string + outter.get('selectedRow').username + '<br/>' + i18n.t('sysKorisnik.resetLozinkeNovaLozinka').string + o,
            content: i18n.t("sysKorisnik.resetLozinkeUspesno").string + "<br/>" + i18n.t("sysKorisnik.usernameObavestenje") + outter.get('selectedRow').username + "<br/>" + i18n.t("sysKorisnik.lozinkaObavestenje") + o,
            keepOpen: true,
            type: 'success'
          });
        }, function (e) {
          $.Notify({
            caption: i18n.t('sysKorisnik.resetLozinkeHeader').string,
            content: i18n.t('sysKorisnik.resetLozinkeNeuspesno').string,
            keepOpen: false,
            type: 'alert'
          });
        });
      }
    }
  });

});