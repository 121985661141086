define('zaris-frontend/controllers/sif-zaposleni', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    i18n: Ember['default'].inject.service('i18n'),

    zaposleniContent: Ember['default'].A(),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'interniMaticniBroj',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.interniMaticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'imePrezime',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.imePrezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.maticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.radnoMestoNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'organizacionaJedinicaNaziv',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifZaposleni.organizacionaJedinicaNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});