define('zaris-frontend/components/zaris-datatable', ['exports', 'ember', 'ember-table/components/ember-table', 'zaris-frontend/models/models', 'zaris-frontend/conf'], function (exports, Ember, EmberTable, Models, conf) {

  'use strict';

  exports['default'] = EmberTable['default'].extend({
    classNames: ['zaris-table-tables-container'],
    classNameBindings: ['enableContentSelection:zaris-table-content-selectable'],

    //name of domain for CRUD
    domainName: 'domainName',
    //name of domain id
    domainId: 'domainId',
    rowHeight: 25,
    i18n: Ember['default'].inject.service('i18n'),

    //id for yes/no panel
    idYesNoDialog: Ember['default'].computed('domainName', function () {
      return 'idYesNoDialog' + this.get('domainName');
    }),
    //id for ok modal panel
    idDialogOk: Ember['default'].computed('domainName', function () {
      return 'idDialogOk' + this.get('domainName');
    }),
    //panel type for ok panel(ERROR => 'alert', SUCCESS => 'success', WARN: 'warning', 'INFO' => 'info')
    modalOkType: 'alert',
    //defines if modal panel is in mode for create or update
    isNew: true,

    searchInputValue: "",

    //messages for deleting entity
    titleDeleteMsgKey: 'titleDeleteMsgKey',
    descDeleteQuestionMsgKey: 'descDeleteQuestionMsgKey',
    descDeleteSuccessMsgKey: 'descDeleteSuccessMsgKey',

    //id of modal panel for add/edit
    modalPanelShow: 'modalPanelShow',
    //id of modal panel for add
    modalPanelShowNew: 'modalPanelShowNew',
    //id of modal panel for edit
    modalPanelShowEdit: 'modalPanelShowEdit',

    //array for generating pages component
    totalPages: Em.A(),
    //content
    content: Ember['default'].A(),
    //current page
    page: 0,
    //results per page
    perPage: 10,
    //initial sort order - DO NOT CHANGE
    sortAscending: true,
    //selected sort column
    sortColumn: null,
    //filter value - bound to input component
    filterValue: "",
    //archived - filter by fg_arhivirano column
    isArchived: "false",
    //filter by custom id
    customId: null,
    //filter by custom id
    customId2: null,
    //post data to be sent in POST get data request
    bodyParams: null,
    //id of currently selected row - ZERO if nothing selected
    idRowSelected: 0,
    //selected datatable element
    selectedRow: {},

    totalCountNumber: 0,

    currentElements: Ember['default'].computed('page', 'perPage', 'totalCountNumber', function () {
      var start = this.get('page') * this.get('perPage') + 1;
      var end = Math.min((this.get('page') + 1) * this.get('perPage'), this.get('totalCountNumber'));
      return start + '..' + end;
    }),

    //toggle this variable to refresh data - value is not important
    refresh: false,

    //toggle this variable to refresh data but keep selected row
    refreshWoResetSelection: false,

    //disabled create option in datatable toolbar
    disabledCreate: false,

    //disabled delete option in datatable toolbar
    disabledDelete: false,

    //readOnly mode - create, update and delete disabled
    readOnly: false,

    //flag that defines if control buttons for add, create and delete are rendered
    renderControlButtons: true,

    //flag that defines if select for filtering by fg_arhivirano is rendered
    renderFilterArhivirano: true,

    //flag that defines if search box should be rendered
    renderSearch: true,

    // flag that defines if search box should be only thing that is rendered
    renderOnlySearch: false,

    //flag that defines if button link to the code table should be rendered
    renderSifarnikLink: false,
    //title to the code table link button
    sifarnikLinkTitle: null,
    //route to the code table
    sifarnikLinkRoute: null,

    // render create button
    renderCreate: true,

    // render update button
    renderUpdate: true,

    // render delete button
    renderDelete: true,

    //flag that defines if button for saving form should be rendered
    renderSacuvajObrazac: false,
    //flag that defines if button for routing to iObrasci should be rendered
    renderSacuvaniObrasci: false,
    //flag that defines if button for routing to izvestaji should be rendered
    renderIzvestaji: false,
    //flag that defines if button for routing to sifarnici should be rendered
    renderSifarnici: false,

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    //injected routing service
    routing: Ember['default'].inject.service('-routing'),

    // returns whether creation of new sub-elements is allowed
    createAllowed: Ember['default'].computed(function () {
      var readOnly = this.get('readOnly');
      var disabledCreate = this.get('disabledCreate');

      return !readOnly && !disabledCreate;
    }),

    // returns whether update of element is allowed
    updateAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('isRowSelected');
      var readOnly = this.get('readOnly');

      return isRowSelected && !readOnly;
    }),

    // returns whether delete of element is allowed
    deleteAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('isRowSelected');
      var readOnly = this.get('readOnly');
      var disabledDelete = this.get('disabledDelete');

      return isRowSelected && !readOnly && !disabledDelete;
    }),

    //init function called before opening modal panel
    initModal: function initModal() {},

    //transforms json object to Ember object
    createTableItem: function createTableItem(event) {
      var row = Ember['default'].Object.create({ errorHandlerController: this });
      row.setProperties(event);

      return row;
    },

    /*
    searchInputId: Ember.computed('domainName', function() {
      return 'filterDatatable' + this.get('domainName');
    }),
    */

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0;
    }),

    cellNumCssClass: Ember['default'].computed('', function () {
      var n = (this.get('renderControlButtons') ? 3 : 0) + (this.get('renderSifarnikLink') ? 1 : 0) + (this.get('renderFilterArhivirano') ? 1 : 0) + (this.get('renderSearch') ? 1 : 0) + (this.get('renderSacuvajObrazac') ? 1 : 0) + (this.get('renderSacuvaniObrasci') ? 1 : 0) + (this.get('renderIzvestaji') ? 1 : 0) + (this.get('renderSifarnici') ? 1 : 0);

      return 'cells' + n;
    }),

    //listens for param changes and triggers table data update upon filter, sort or paging change
    tableStateChanged: Ember['default'].observer('page', 'sortColumn', 'sortAscending', 'filterValue', 'isArchived', 'refresh', 'refreshWoResetSelection', 'domainName', 'bodyParams', function () {
      Ember['default'].run.once(this, 'fetchTableData');
    }),

    //gets data for one datatable page
    fetchTableData: (function () {
      var loginService = this.get('loginService');
      var page,
          url,
          i,
          self = this;
      var model = new Models['default'][this.domainName]();
      page = this.get('page');
      var content = this.get('content');
      content.clear();

      var servicePath = this.get('bodyParams') === null ? model.getPathFindPaged() : model.getPathFindPagedPost();

      url = conf['default'].serverBaseUrl + '/' + servicePath + "?page=" + page + "&size=" + this.get('perPage') + "&idPravnoLice=" + localStorage.idPravnoLice + "&value=" + this.get('filterValue') + "&arhivirano=" + this.get('isArchived') + "&access_token=" + loginService.getAccessToken() + (this.get('customId') !== null ? "&id=" + this.get('customId') : "") + (this.get('customId2') !== null ? "&id2=" + (this.get('customId2') !== undefined ? this.get('customId2') : "") : "");
      if (this.get('sortColumn')) {
        var asc = this.get('sortAscending');
        url = url + '&sort=' + this.get('sortColumn.key') + (asc ? ',asc' : ',desc');
      }

      self.startSpinner();

      var bodyParams = this.get('bodyParams');
      if (bodyParams === null) {
        $.ajax({
          url: url,
          type: model.getVerbFindPaged(),
          accepts: 'application/json',
          crossDomain: true,
          async: true,
          headers: conf['default'].corsHeaders,
          success: function success(data) {
            self.stopSpinner();
            self.set('totalPages', self.getPagingObject(data.page.totalPages));
            self.set('totalPageNumber', data.page.totalPages);
            self.set('totalCountNumber', data.page.totalElements);

            data.content.forEach(function (entity) {
              var a = self.createTableItem(entity);
              content.pushObject(a);
            });
          },
          error: function error(e) {
            self.stopSpinner();
            console.log('DEBUG: GET ' + e + ' Failed');
          }
        });
      } else {
        $.ajax({
          url: url,
          type: 'POST',
          accepts: 'application/json',
          contentType: "application/json",
          data: JSON.stringify(bodyParams),
          dataType: "json",
          crossDomain: true,
          async: true,
          headers: conf['default'].corsHeaders,
          success: function success(data) {
            self.stopSpinner();
            self.set('totalPages', self.getPagingObject(data.page.totalPages));
            self.set('totalPageNumber', data.page.totalPages);
            self.set('totalCountNumber', data.page.totalElements);

            data.content.forEach(function (entity) {
              var a = self.createTableItem(entity);
              content.pushObject(a);
            });
          },
          error: function error(e) {
            self.stopSpinner();
            console.log('DEBUG: GET ' + e + ' Failed');
          }
        });
      }
    }).on('init'),

    startSpinner: function startSpinner() {
      if (isNaN(localStorage.activeSpinnerRequests)) localStorage.activeSpinnerRequests = 0;

      var n = parseInt(localStorage.activeSpinnerRequests);
      n = n + 1;
      localStorage.activeSpinnerRequests = n;

      if (n === 1) {
        $('#appSpinner').data('dialog').open();
      }
    },
    stopSpinner: function stopSpinner() {
      var n = parseInt(localStorage.activeSpinnerRequests);
      n = n - 1;
      localStorage.activeSpinnerRequests = n;

      if (n === 0) {
        $('#appSpinner').data('dialog').close();
      }
    },

    getPagingObject: function getPagingObject(totalPages) {
      var paging = Em.A();
      var page = this.get('page');
      //up to 10 pages we display all links
      if (totalPages <= 10) {
        for (var i = 0; i < totalPages; i++) paging.push({});
      }
      //else we display first, last and five middle pages
      else {
          paging[0] = {};paging[1] = {};

          if (page < 5) {
            paging[2] = {};paging[3] = {};paging[4] = {};paging[5] = {};paging[6] = {};

            paging[7] = { delimiter: true };
          } else if (totalPages - page < 5) {
            paging[totalPages - 8] = { delimiter: true };

            paging[totalPages - 7] = {};paging[totalPages - 6] = {};paging[totalPages - 5] = {};
            paging[totalPages - 4] = {};paging[totalPages - 3] = {};
          } else {
            paging[page - 3] = { delimiter: true };

            paging[page - 2] = {};paging[page - 1] = {};paging[page] = {};paging[page + 1] = {};paging[page + 2] = {};

            paging[page + 3] = { delimiter: true };
          }

          paging[totalPages - 2] = {};paging[totalPages - 1] = {};
        }

      return paging;
    },

    actions: {
      sacuvajObrazac: function sacuvajObrazac() {

        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });

        model.sacuvajObrazac(this.get('loginService').getAccessToken(), function () {
          $('#sacuvaniObrasci').click();
          setTimeout(function () {
            $.Notify({
              content: i18n.t('porukaUspesnoSacuvanObrazac').string,
              keepOpen: false,
              type: 'success'
            });
          }, 100);
        });
      },
      rowDidClick: function rowDidClick(row, event) {
        var domainId = this.get('domainId');
        this._super(row, event);
        this.set('idRowSelected', row.get('content')[domainId]);
        this.set('selectedRow', row.get('content'));
      },
      nextPage: function nextPage() {
        this.resetSelection();
        this.incrementProperty('page');
      },
      previousPage: function previousPage() {
        this.resetSelection();
        this.decrementProperty('page');
      },
      changePage: function changePage(pageNum) {
        if (pageNum == this.get('page')) return;
        this.resetSelection();
        this.set('page', pageNum);
      },
      filterArhivirano: function filterArhivirano(value, component) {
        this.resetSelection();
        this.set('page', 0);
        this.set('isArchived', value);
      },
      filter: function filter() {
        this.resetSelection();
        this.set('page', 0);
        this.set('filterValue', this.get('searchInputValue'));
      },
      sortByColumn: function sortByColumn(column) {
        if (!column.get('supportSort')) {
          return;
        }

        if (this.get('sortColumn') !== column) {
          //console.log('New column');
          this.get('columns').setEach('sorted', false);
          column.set('sorted', true);
          column.set('sortAscending', true);
          this.set('sortAscending', true);
          this.set('sortColumn', column);
        } else if (this.get('sortColumn') === column) {
          // Handle disabling sorts
          if (this.get('sortAscending') === false) {
            //console.log('disabling sort');
            this.set('sortColumn', undefined);
            this.set('sortAscending', true);
            this.get('columns').setEach('sorted', false);
            column.set('sorted', false);
          } else {
            //console.log('change sort');
            this.toggleProperty('sortAscending');
            column.set('sortAscending', this.get('sortAscending'));
          }
        }

        this.resetSelection();
      },

      initDelete: function initDelete() {
        //prepare and show yes/no modal panel
        this.get("target").send('openYesNoModal', 'idYesNoDialog', this.get('titleDeleteMsgKey'), this.get('descDeleteQuestionMsgKey'), this, 'deleteConfirm');
      },
      initCreate: function initCreate() {
        this.set('isNew', true);

        // ukoliko postoji akcija u kontroleru za kreiranje specificnog modelObjecta
        // prosledi joj se ovaj objekat pa ona tamo kreira strukturu - initModelObject akcija u kontroleru
        var specModelObject = {};
        this.sendAction("onModalCreate", specModelObject);
        this.set('modelObject', specModelObject);

        // dovlacenje liste za select - akcija u kontroleru
        this.sendAction("onModalOpen", this.get('modelObject'));

        this.handleWindowResize({ target: { id: 'undefined' } });

        this.get('modalPanelShow') === 'modalPanelShow' ? this.toggleProperty('modalPanelShowNew') : this.toggleProperty('modalPanelShow');
      },
      createConfirm: function createConfirm() {
        console.log('CONFIRM CREATE');
      },
      initUpdate: function initUpdate() {
        this.set('isNew', false);
        var selected = jQuery.extend(true, {}, this.get('selectedRow'));
        this.set('modelObject', selected);

        // dovlacenje liste za select - akcija u kontroleru
        this.sendAction("onModalOpen");

        this.get('modalPanelShow') === 'modalPanelShow' ? this.toggleProperty('modalPanelShowEdit') : this.toggleProperty('modalPanelShow');
      },

      updateConfirm: function updateConfirm() {
        console.log('UPDATE CONFIRM');
      }
    },

    deleteConfirm: function deleteConfirm(self) {
      var loginService = self.get('loginService');
      var outter = self;

      var model = new Models['default'][self.domainName]();
      model['delete'](self.get('idRowSelected'), loginService.getAccessToken(), function () {
        //call ws to get new results, without deleted one
        outter.toggleProperty('refresh');
        outter.toggleProperty('refreshWoResetSelection');

        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), outter.get('descDeleteSuccessMsgKey'), 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');

        //reseting selection, as selected item does not exist anymore
        //outter.resetSelection();
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), e.responseJSON.messages[0], 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    refreshDidChange: Ember['default'].observer('refresh', function () {
      Ember['default'].run.once(this, 'resetSelection');
    }),

    resetSelection: function resetSelection() {
      this.set('idRowSelected', 0);
      this.set('selectedRow', {});
    }
  });

});