define('zaris-frontend/components/sys-promena-passworda-page', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, Models, ColumnDefinition, conf) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({

    i18n: Ember['default'].inject.service('i18n'),
    login: Ember['default'].inject.service('login'),
    validate: Ember['default'].inject.service('validate'),

    actions: {
      changePassword: function changePassword() {
        var i18n = this.get('i18n');

        if (this.get('validate').validate(this)) {
          var modelObject = {};
          modelObject.oldPassword = this.get('oldPassword');
          modelObject.newPassword = this.get('newPassword');
          modelObject.newPasswordConfirm = this.get('newPasswordConfirm');

          if (modelObject.newPassword !== modelObject.newPasswordConfirm) {
            $.Notify({
              caption: i18n.t('sysKorisnik.promenaPasswordaHeader').string,
              content: i18n.t('sysKorisnik.promenaPasswordaRazlicitiPasswordi').string,
              keepOpen: false,
              type: 'alert'
            });

            return;
          }

          if (modelObject.newPassword.length < 6) {
            $.Notify({
              caption: i18n.t('sysKorisnik.promenaPasswordaHeader').string,
              content: i18n.t('sysKorisnik.noviPasswordMin6Karaktera').string,
              keepOpen: false,
              type: 'alert'
            });

            return;
          }

          var korisnikModel = Models['default'].SysKorisnik.create({ errorHandlerController: this.get('target') });
          korisnikModel.postResult('changePassword', modelObject, this.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('sysKorisnik.promenaPasswordaHeader').string,
              content: i18n.t('sysKorisnik.promenaPasswordaUspesno').string,
              keepOpen: false,
              type: 'success'
            });
          }, function (e) {
            console.log(e);
            $.Notify({
              caption: i18n.t('sysKorisnik.promenaPasswordaHeader').string,
              content: i18n.t(e.responseJSON.messages[0]).string,
              keepOpen: false,
              type: 'alert'
            });
          });
        }
      }
    }

  });

});