define('zaris-frontend/templates/components/sif-zaposleni-new-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-zaposleni-new-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panelLeft");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panelRight");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","clearFloat");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [11]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [7]),1,1);
        morphs[6] = dom.createAttrMorph(element5, 'class');
        morphs[7] = dom.createElementMorph(element5);
        morphs[8] = dom.createMorphAt(element5,2,2);
        morphs[9] = dom.createElementMorph(element6);
        morphs[10] = dom.createMorphAt(element6,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["sifZaposleni.povezivanjeLicaIRadnihMesta"],[],["loc",[null,[6,10],[6,58]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","component",[["get","componentName1",["loc",[null,[15,18],[15,32]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[16,15],[16,19]]]]],[],[]],"columns",["subexpr","@mut",[["get","licaColumns",["loc",[null,[17,16],[17,27]]]]],[],[]],"domainName","SifLica","domainId","idLica","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[20,14],[20,19]]]]],[],[]],"isArchived",false,"selectedRow",["subexpr","@mut",[["get","selectedLice",["loc",[null,[22,20],[22,32]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[23,16],[23,23]]]]],[],[]],"renderOnlySearch",true,"hasHeader",true,"hasFooter",false,"content",["subexpr","@mut",[["get","zaposleniLicaContent",["loc",[null,[27,16],[27,36]]]]],[],[]]],["loc",[null,[15,6],[28,8]]]],
        ["inline","component",[["get","componentName1",["loc",[null,[32,18],[32,32]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[33,15],[33,19]]]]],[],[]],"columns",["subexpr","@mut",[["get","radnoMestoStrukturaColumns",["loc",[null,[34,16],[34,42]]]]],[],[]],"domainName","SifRadnoMestoStruktura","domainId","idRadnoMestoStruktura","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[37,14],[37,19]]]]],[],[]],"isArchived",false,"selectedRow",["subexpr","@mut",[["get","selectedRadnoMestoStruktura",["loc",[null,[39,20],[39,47]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[40,16],[40,23]]]]],[],[]],"renderOnlySearch",true,"hasHeader",true,"hasFooter",false,"content",["subexpr","@mut",[["get","zaposleniRadnoMestoStrukturaContent",["loc",[null,[44,16],[44,51]]]]],[],[]]],["loc",[null,[32,6],[45,8]]]],
        ["inline","t",["sifZaposleni.inputInterniMaticniBroj"],[],["loc",[null,[51,9],[51,53]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.interniMaticniBroj",["loc",[null,[53,32],[53,62]]]]],[],[]]],["loc",[null,[53,6],[53,65]]]],
        ["attribute","class",["concat",["button ",["subexpr","if",[["get","saveEnabled",["loc",[null,[60,51],[60,62]]]],"success","zarisDisableButton"],[],["loc",[null,[60,46],[60,95]]]]]]],
        ["element","action",["create"],[],["loc",[null,[60,12],[60,31]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[60,133],[60,158]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[61,12],[61,38]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[61,86],[61,111]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});