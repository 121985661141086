define('zaris-frontend/templates/components/footer-table-container', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "zaris-frontend/templates/components/footer-table-container.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","footer-block",[],["itemViewClass",["subexpr","@mut",[["get","itemViewClass",["loc",[null,[4,20],[4,33]]]]],[],[]],"content",["subexpr","@mut",[["get","footerContent",["loc",[null,[5,14],[5,27]]]]],[],[]],"columns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[6,14],[6,26]]]]],[],[]],"blockWidth",["subexpr","@mut",[["get","fixedBlockWidth",["loc",[null,[7,17],[7,32]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","rowWidth",["loc",[null,[8,15],[8,23]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","footerHeight",["loc",[null,[9,16],[9,28]]]]],[],[]],"class","ember-table-left-table-block"],["loc",[null,[3,4],[11,6]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/footer-table-container.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ember-table-table-fixed-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0,1,1);
        morphs[1] = dom.createMorphAt(element0,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","numFixedColumns",["loc",[null,[2,8],[2,23]]]]],[],0,null,["loc",[null,[2,2],[12,9]]]],
        ["inline","footer-block",[],["itemViewClass",["subexpr","@mut",[["get","itemViewClass",["loc",[null,[14,18],[14,31]]]]],[],[]],"content",["subexpr","@mut",[["get","footerContent",["loc",[null,[15,12],[15,25]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[16,12],[16,24]]]]],[],[]],"blockWidth",["subexpr","@mut",[["get","tableBlockWidth",["loc",[null,[17,15],[17,30]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","scrollLeft",["loc",[null,[18,15],[18,25]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","rowWidth",["loc",[null,[19,13],[19,21]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","footerHeight",["loc",[null,[20,14],[20,26]]]]],[],[]],"class","ember-table-right-table-block"],["loc",[null,[13,2],[22,4]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});