define('zaris-frontend/components/obr-obrazac03-toolbar', ['exports', 'ember', 'zaris-frontend/models/models', 'zaris-frontend/utils/template-to-string'], function (exports, Ember, Models, templateToString) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0;
    }),

    actions: {
      preuzmiPovredniListA4: function preuzmiPovredniListA4() {
        this.preuzmiPovredniList('a4');
      },

      preuzmiPovredniListA3: function preuzmiPovredniListA3() {
        this.preuzmiPovredniList('a3');
      }
    },

    preuzmiPovredniList: function preuzmiPovredniList(format) {
      var obrObrazac03Model = Models['default'].ObrObrazac03.create({ errorHandlerController: this });
      obrObrazac03Model.postResult('dohvatiPovredniList', { 'id': this.get('idRowSelected') }, this.get('login').getAccessToken(), function (hash) {
        var link = document.createElement('a');
        link.href = obrObrazac03Model.generateDownloadLink(hash);

        document.body.appendChild(link);
        link.click();
      });
    }
  });

});