define('zaris-frontend/components/obr-obrazac07-edit-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac07EditModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentOpasneMaterije: Em.A(),
    modelObjectOpasneMaterije: {},
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.obrObrazac07OpasnaMaterijaModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    selectedOpasnaMaterijaDomainId: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'idOpasnaMaterijaRm' : 'idOpasnaMaterijaRms';
    }),

    selectedOpasnaMaterijaDomainName: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'ObrObrazac07OpasnaMaterijaRm' : 'ObrObrazac07OpasnaMaterijaRms';
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      }
    },

    openPanel: Ember['default'].observer('modalPanelShowEdit', function () {
      this.toggleProperty('refreshObrazac07EditModal');

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      this.set('selectedRowId', this.get('selectedDomainName') === 'radnoMesto' ? this.get('selektovanoRadnoMesto.idObrazac07Rm') : this.get('selektovanoRadnoMesto.idObrazac07Rms'));

      Ember['default'].showModalPanel('.obrObrazac07EditModal');
    }),

    closePanel: function closePanel() {
      $('.obrObrazac07EditModal').data('dialog').close();
    },

    tableColumnsOpasneMaterije: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnaMaterija.naziv',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Naziv',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnaMaterija.hemijskoIme',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Hemijsko ime',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnaMaterija.un',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'UN',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnaMaterija.adr',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'ADR',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnaMaterija.rid',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'RID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'sifOpasnaMaterija.cas',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'CAS',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'nacinUpotrebe',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Nacin upotrebe',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Napomena',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'dnevnaKolicina',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Dnevna kolicina',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});