define('zaris-frontend/templates/components/zaris-datatable-client', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "zaris-frontend/templates/components/zaris-datatable-client.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","header-table-container",[],["numFixedColumns",["subexpr","@mut",[["get","numFixedColumns",["loc",[null,[5,21],[5,36]]]]],[],[]],"tableColumns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[6,18],[6,30]]]]],[],[]],"fixedColumns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[7,18],[7,30]]]]],[],[]],"fixedBlockWidth",["subexpr","@mut",[["get","_fixedBlockWidth",["loc",[null,[8,21],[8,37]]]]],[],[]],"tableBlockWidth",["subexpr","@mut",[["get","_tableBlockWidth",["loc",[null,[9,21],[9,37]]]]],[],[]],"headerHeight",["subexpr","@mut",[["get","_headerHeight",["loc",[null,[10,18],[10,31]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","_rowWidth",["loc",[null,[11,14],[11,23]]]]],[],[]],"tableContainerWidth",["subexpr","@mut",[["get","_tableContainerWidth",["loc",[null,[12,25],[12,45]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[13,16],[13,32]]]]],[],[]],"enableColumnReorder",["subexpr","@mut",[["get","enableColumnReorder",["loc",[null,[14,25],[14,44]]]]],[],[]],"isShowingSortableIndicator",["subexpr","@mut",[["get","_isShowingSortableIndicator",["loc",[null,[15,32],[15,59]]]]],[],[]],"sortableIndicatorLeft",["subexpr","@mut",[["get","_sortableIndicatorLeft",["loc",[null,[16,27],[16,49]]]]],[],[]],"columnMode",["subexpr","@mut",[["get","columnMode",["loc",[null,[17,16],[17,26]]]]],[],[]],"columnsFillTable",["subexpr","@mut",[["get","columnsFillTable",["loc",[null,[18,22],[18,38]]]]],[],[]],"toggleTableCollapse","toggleTableCollapse","columnDidSort","columnDidSort","sortByColumn","sortByColumn"],["loc",[null,[4,2],[22,4]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 0
            },
            "end": {
              "line": 57,
              "column": 0
            }
          },
          "moduleName": "zaris-frontend/templates/components/zaris-datatable-client.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","footer-table-container",[],["footerContent",["subexpr","@mut",[["get","footerContent",["loc",[null,[42,20],[42,33]]]]],[],[]],"numFixedColumns",["subexpr","@mut",[["get","numFixedColumns",["loc",[null,[43,22],[43,37]]]]],[],[]],"itemViewClass",["subexpr","@mut",[["get","tableRowViewClass",["loc",[null,[44,20],[44,37]]]]],[],[]],"tableColumns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[45,19],[45,31]]]]],[],[]],"fixedColumns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[46,19],[46,31]]]]],[],[]],"fixedBlockWidth",["subexpr","@mut",[["get","_fixedBlockWidth",["loc",[null,[47,22],[47,38]]]]],[],[]],"tableBlockWidth",["subexpr","@mut",[["get","_tableBlockWidth",["loc",[null,[48,22],[48,38]]]]],[],[]],"footerHeight",["subexpr","@mut",[["get","footerHeight",["loc",[null,[49,19],[49,31]]]]],[],[]],"tableContainerWidth",["subexpr","@mut",[["get","_tableContainerWidth",["loc",[null,[50,26],[50,46]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[51,17],[51,33]]]]],[],[]],"headerHeight",["subexpr","@mut",[["get","_headerHeight",["loc",[null,[52,19],[52,32]]]]],[],[]],"tableContentHeight",["subexpr","@mut",[["get","_tableContentHeight",["loc",[null,[53,25],[53,44]]]]],[],[]],"bodyHeight",["subexpr","@mut",[["get","_bodyHeight",["loc",[null,[54,17],[54,28]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","_rowWidth",["loc",[null,[55,15],[55,24]]]]],[],[]]],["loc",[null,[41,2],[56,4]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 74,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/zaris-datatable-client.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"style","clear: both;");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0,1,1);
        morphs[1] = dom.createMorphAt(element0,2,2);
        morphs[2] = dom.createMorphAt(element0,4,4);
        morphs[3] = dom.createMorphAt(element0,5,5);
        morphs[4] = dom.createMorphAt(element0,7,7);
        return morphs;
      },
      statements: [
        ["block","if",[["get","hasHeader",["loc",[null,[3,6],[3,15]]]]],[],0,null,["loc",[null,[3,0],[23,7]]]],
        ["inline","body-table-container",[],["bodyContent",["subexpr","@mut",[["get","bodyContent",["loc",[null,[25,14],[25,25]]]]],[],[]],"numFixedColumns",["subexpr","@mut",[["get","numFixedColumns",["loc",[null,[26,18],[26,33]]]]],[],[]],"itemViewClass",["subexpr","@mut",[["get","tableRowViewClass",["loc",[null,[27,16],[27,33]]]]],[],[]],"tableColumns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[28,14],[28,26]]]]],[],[]],"fixedColumns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[29,14],[29,26]]]]],[],[]],"fixedBlockWidth",["subexpr","@mut",[["get","_fixedBlockWidth",["loc",[null,[30,17],[30,33]]]]],[],[]],"tableBlockWidth",["subexpr","@mut",[["get","_tableBlockWidth",["loc",[null,[31,17],[31,33]]]]],[],[]],"bodyHeight",["subexpr","@mut",[["get","_bodyHeight",["loc",[null,[32,13],[32,24]]]]],[],[]],"bodyWidth",["subexpr","@mut",[["get","_width",["loc",[null,[33,12],[33,18]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","rowHeight",["loc",[null,[34,12],[34,21]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","_rowWidth",["loc",[null,[35,11],[35,20]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[36,13],[36,29]]]]],[],[]],"rowDidClick","rowDidClick","toggleRowCollapse","toggleRowCollapse"],["loc",[null,[24,0],[39,2]]]],
        ["block","if",[["get","hasFooter",["loc",[null,[40,6],[40,15]]]]],[],1,null,["loc",[null,[40,0],[57,7]]]],
        ["inline","scroll-container",[],["scrollContainerWidth",["subexpr","@mut",[["get","_scrollContainerWidth",["loc",[null,[59,25],[59,46]]]]],[],[]],"fixedColumnsWidth",["subexpr","@mut",[["get","_fixedColumnsWidth",["loc",[null,[60,22],[60,40]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","_tableScrollLeft",["loc",[null,[61,15],[61,31]]]]],[],[]],"tableColumnsWidth",["subexpr","@mut",[["get","_tableColumnsWidth",["loc",[null,[62,22],[62,40]]]]],[],[]],"tableContentHeight",["subexpr","@mut",[["get","_tableContentHeight",["loc",[null,[63,23],[63,42]]]]],[],[]],"scrollLeftDidChange","scrollLeftDidChange"],["loc",[null,[58,0],[66,2]]]],
        ["inline","column-sortable-indicator",[],["sortableIndicatorLeft",["subexpr","@mut",[["get","_sortableIndicatorLeft",["loc",[null,[68,25],[68,47]]]]],[],[]],"tableHeight",["subexpr","@mut",[["get","_height",["loc",[null,[69,15],[69,22]]]]],[],[]],"isShowingSortableIndicator",["subexpr","@mut",[["get","_isShowingSortableIndicator",["loc",[null,[70,30],[70,57]]]]],[],[]]],["loc",[null,[67,0],[71,2]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});