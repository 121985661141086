define('zaris-frontend/components/sif-zaposleni-new-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifZaposleniNewModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    zaposleniLicaContent: Em.A(),
    zaposleniRadnoMestoStrukturaContent: Em.A(),

    componentName1: null,
    componentName2: null,

    selectedLice: null,
    selectedRadnoMestoStruktura: null,
    saveEnabled: false,

    licaColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'ime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'ime',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'prezime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'prezime',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      return columns;
    }),

    radnoMestoStrukturaColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 300,
        supportSort: true,
        headerCellName: 'Radno mesto',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      })), columns.push(ColumnDefinition['default'].create({
        key: 'organizacionaJedinicaNaziv',
        savedWidth: 300,
        supportSort: true,
        headerCellName: 'Organizaciona struktura',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      return columns;
    }),

    saveButtonConditionChanged: Ember['default'].observer('selectedLice', 'selectedRadnoMestoStruktura', function () {
      this.set('saveEnabled', this.get('selectedLice') !== null && Object.keys(this.get('selectedLice')).length !== 0 && this.get('selectedRadnoMestoStruktura') !== null && Object.keys(this.get('selectedRadnoMestoStruktura')).length !== 0);
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifZaposleniModel = Models['default'].SifZaposleni.create({ errorHandlerController: this });

        var obj = {};
        obj.idLice = this.get('selectedLice').idLice;
        obj.idRadnoMestoStruktura = this.get('selectedRadnoMestoStruktura').idRadnoMestoStruktura;
        obj.interniMaticniBroj = this.get('modelObject.interniMaticniBroj');

        sifZaposleniModel.create(obj, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('sifZaposleni.dodavanjeHeader').string,
            content: i18n.t('sifZaposleni.dodavanjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
          outter.toggleProperty('refresh');
        }, function (e) {
          $.Notify({
            caption: i18n.t('sifZaposleni.dodavanjeHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: true,
            type: 'alert'
          });
        });
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.set('componentName1', 'zaris-datatable');
      var outter = this;
      setTimeout(function () {
        outter.set('componentName2', 'zaris-datatable');
      }, 100);

      setTimeout(function () {
        Ember['default'].showModalPanel('.sifZaposleniNewModal');
      }, 120);
    }),

    closePanel: function closePanel() {
      $('.sifZaposleniNewModal').data('dialog').close();
    }
  });

});