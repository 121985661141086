define('zaris-frontend/controllers/sif-korisnicki-definisane-obuke', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    i18n: Ember['default'].inject.service('i18n'),

    content: Em.A(),

    tableColumns: Ember['default'].computed(function () {
      var self = this;

      var nazivColumn = ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.inputNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var opisColumn = ColumnDefinition['default'].create({
        key: 'opis',
        savedWidth: 350,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.inputOpis'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var FgPeriodicnoColumn = ColumnDefinition['default'].create({
        key: 'fgPeriodicno',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.periodicno'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var i18n = this.get('i18n');
          return self.booleanValueNames(row.get(this.key));
        }
      });
      var intervalMeseciColumn = ColumnDefinition['default'].create({
        key: 'intervalMeseci',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.inputIntervalMeseci'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });

      return [nazivColumn, opisColumn, FgPeriodicnoColumn, intervalMeseciColumn];
    })

  });

});