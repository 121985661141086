define('zaris-frontend/controllers/obr-obrazac05-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    radnaMesta: "radnaMesta",

    idRadnoMesto: null,

    idRadnoMestoStruktura: null,

    idBolest: null,

    sposobnostOperacija: '<>',

    sposobnost: null,

    // po cemu se grupise izvestaj
    groupBy: 'rm',

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;
    },

    actions: {
      generate: function generate() {

        var pObj = {};
        var outter = this;
        var loginService = this.get('loginService');

        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        var idRadnoMesto = this.get('idRadnoMesto');
        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        pObj.p_id_radno_mesto = null;
        pObj.p_id_radno_mesto_struktura = null;

        var radnaMesta = this.get('radnaMesta');

        switch (radnaMesta) {
          case "radnoMesto":
            pObj.p_id_radno_mesto = idRadnoMesto;
            break;
          case "radnoMestoStruktura":
            pObj.p_id_radno_mesto_struktura = idRadnoMestoStruktura;
            break;
          default:
            break;
        }

        var idBolest = this.get('idBolest');
        pObj.p_id_bolest = idBolest;

        var sposobnostOperacija = this.get('sposobnostOperacija');
        var sposobnost = this.get('sposobnost');
        pObj.p_sposobnost_operacija = sposobnostOperacija;

        // sposobnost = integer 0-100
        pObj.p_sposobnost = null;
        if (sposobnostOperacija != "<>" && sposobnost == parseInt(sposobnost, 10)) {
          if (sposobnost > 100) {
            sposobnost = 100;
          } else if (sposobnost < 0) {
            sposobnost = 0;
          }
          pObj.p_sposobnost = sposobnost;
        }

        pObj.p_labela = this.get('groupBy');

        this.set('pObj', pObj);

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_05';
        izvestajiDTO.reportParameters = pObj;

        this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});