define('zaris-frontend/services/menu', ['exports', 'zaris-frontend/models/models', 'zaris-frontend/config/environment'], function (exports, Models, ENV) {

  'use strict';

  exports['default'] = Ember.Service.extend({
    i18n: Ember.inject.service('i18n'),
    login: Ember.inject.service('login'),
    routing: Ember.inject.service('-routing'),

    menuHTML: localStorage.menuHTML,
    menuHTMLChanged: (function () {
      localStorage.menuHTML = this.get('menuHTML');
    }).observes('menuHTML'),

    generateHTMLMenu: function generateHTMLMenu(accessToken) {
      var i18n = this.get('i18n');
      var login = this.get('login');
      var routing = this.get('routing');

      if (accessToken === undefined || accessToken === null || accessToken === 'null') {
        accessToken = login.getAccessToken();
      }

      var menuItemsModel = Models['default'].MenuItem.create({ errorHandlerController: this });
      var menuItems = menuItemsModel.findAllGetResult(accessToken);

      //generisemo html meni
      var menuHTML = '<div class="app-bar"> <ul class="app-bar-menu small-dropdown">';

      for (var property in menuItems) {
        if (menuItems.hasOwnProperty(property)) {
          if (!isNaN(property)) {
            var currentItem = menuItems[property];
            var nextId = '' + (parseInt(property) + 1);
            var nextItem = menuItems[nextId];

            var linkNaziv = '';
            if (currentItem.nivo === 0) {
              linkNaziv = i18n.t(currentItem.keyNaziv);
            } else {
              linkNaziv = '<span class="menuLinkAcronim">' + (currentItem.keyAkronim != null ? i18n.t(currentItem.keyAkronim) + ' - ' : '') + '</span>' + i18n.t(currentItem.keyNaziv);
            }

            var routeName = currentItem.uri !== null ? currentItem.uri : 'home';
            var defaultLink = '<li> <a href="' + routing.get('router').generate(routeName) + '">' + '<span class="menuIcon ' + currentItem.styleClass + '"></span>' + linkNaziv + '</a>';
            var dropdownLink = '<li> <a class="dropdown-toggle" href="' + routing.get('router').generate(routeName) + '">' + '<span class="menuIcon ' + currentItem.styleClass + '"></span>' + linkNaziv + '</a>';

            if (nextItem) {
              if (nextItem.nivo > currentItem.nivo) {
                menuHTML = menuHTML + dropdownLink;
                menuHTML = menuHTML + '<ul class="d-menu" data-role="dropdown">';
              } else if (nextItem.nivo < currentItem.nivo) {
                menuHTML = menuHTML + defaultLink + ' </li>';

                for (var i = 0; i < currentItem.nivo - nextItem.nivo; i++) {
                  menuHTML = menuHTML + '</ul>';
                }

                menuHTML = menuHTML + '</li>';
              } else {
                menuHTML = menuHTML + defaultLink + ' </li>';
              }
            } else {
              menuHTML = menuHTML + defaultLink;
            }
          }
        }
      }

      menuHTML = menuHTML + '</ul> </div>';

      this.set('menuHTML', menuHTML);
    }

  });

});