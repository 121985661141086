define('zaris-frontend/helpers/and', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.helper(function (params) {
    var arg1 = params[0];
    var arg2 = params[1];

    return arg1 && arg2;
  });

});