define('zaris-frontend/controllers/sif-radna-mesta', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    tableColumns: Ember['default'].computed(function () {
      var self = this;

      var nazivColumn = ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Naziv',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var sifraColumn = ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Šifra',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var opisColumn = ColumnDefinition['default'].create({
        key: 'opis',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Opis',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var rizicnoColumn = ColumnDefinition['default'].create({
        key: 'fgRizicno',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Rizicno',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var i18n = this.get('i18n');
          return self.booleanValueNames(row.get(this.key));
        }
      });
      return [nazivColumn, sifraColumn, opisColumn, rizicnoColumn];
    })
  });

});