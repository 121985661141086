define('zaris-frontend/components/obr-obrazac10-pregledi-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel3', 'obrObrazac10PreglediModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-width": "60%",
    "data-drag-element": ".window-caption",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel3Overlay",
    "data-type": "modal",

    isNewPregledi: true,
    modelObjectPregledi: {},
    preglediContent: Em.A(),

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    didInsertElement: function didInsertElement() {
      this._super();
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var modelObject;
          var ObrObrazac10PreglediModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            modelObject = _this.get('modelObjectPregledi');

            modelObject.obrObrazac10Lzo = _this.get('selectedLzoRow');

            ObrObrazac10PreglediModel = Models['default'].ObrObrazac10LzoPregledi.create({ errorHandlerController: _this });

            ObrObrazac10PreglediModel.create(modelObject, _this.get('login').getAccessToken(), function () {
              if (outter.get('isNewPregledi')) {
                $.Notify({
                  caption: i18n.t('obrObrazac10.dodavanjePreglediHeader').string,
                  content: i18n.t('obrObrazac10.dodavanjePreglediUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
              } else {
                $.Notify({
                  caption: i18n.t('obrObrazac10.azuriranjePreglediHeader').string,
                  content: i18n.t('obrObrazac10.azuriranjePreglediUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
              }

              outter.closePanel();
              outter.toggleProperty('refreshPregledi');
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShowPregledi', function () {
      Ember['default'].showModalPanel('#' + this.get('id'));
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('#' + this.get('id')).data('dialog').close();
    }

  });

});