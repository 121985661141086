define('zaris-frontend/mixins/language-transformations', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({

    i18n: Ember['default'].inject.service('i18n'),

    // returns proper form of yes/no string depending on boolean value
    booleanValueNames: function booleanValueNames(value) {
      var i18n = this.get('i18n');
      if (value == null) return '';
      return value ? i18n.t('app.common.Yes').string : i18n.t('app.common.No').string;
    },

    // returns proper form of gender string depending on string value(muski/zenski)
    genderValueNames: function genderValueNames(value) {
      var i18n = this.get('i18n');
      return value === 'muski' ? i18n.t('pol.muski').string : i18n.t('pol.zenski').string;
    },

    // returns proper form of name suffix if name should have it
    ohsNameSuffix: function ohsNameSuffix(value) {
      var i18n = this.get('i18n');
      return value ? "(" + i18n.t('bzr').string + ")" : "";
    },

    // returns DD.MM.YYYY from date object
    dateOutputFormat: function dateOutputFormat(date) {
      return date != null ? moment(date).format("DD.MM.YYYY") : "";
    },

    // retursn DD.MM.YYYY HH:MM:SS from date object
    dateTimeOutputFormat: function dateTimeOutputFormat(date) {
      return date != null ? moment(date).format("DD.MM.YYYY HH:mm:ss") : "";
    },

    nullToStr: function nullToStr(str) {
      return str != null ? str : "";
    },

    translateMessage: function translateMessage(msg) {
      var i18n = this.get('i18n');
      return i18n.t(msg).string;
    },

    intToHour: function intToHour(i) {
      return i;
    }

  });

});