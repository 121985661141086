define('zaris-frontend/controllers/obr-obrazac07-sacuvani', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    refreshObr07: false,

    //selected domain name
    selectedDomainName: "radnoMesto",

    selectedDomain: Ember['default'].computed('selectedDomainName', function () {
      this.toggleProperty('refreshObr06');
      return this.get('selectedDomainName') === 'radnoMesto' ? 'ObrObrazac07RmSacuvan' : 'ObrObrazac07RmsSacuvan';
    }),

    selectedDomainCapsDown: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'obrObrazac07RmSacuvan' : 'obrObrazac07RmsSacuvan';
    }),

    selectedDomainId: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'idObrazac07RmSacuvan' : 'idObrazac07RmsSacuvan';
    }),

    actions: {
      refreshTable: function refreshTable() {
        this.toggleProperty('refreshObr06');
      }
    },

    tableColumns: Ember['default'].computed('selectedDomainName', function () {
      var self = this;
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'redniBroj',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac07.redniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      if (this.get('selectedDomainName') === 'radnoMesto') {
        columns.push(ColumnDefinition['default'].create({
          key: 'obrObrazac07Rm.sifRadnoMesto',
          savedWidth: 250,
          supportSort: true,
          headerCellName: this.get('i18n').t('obrObrazac07.radnoMesto'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            console.log(row.get('obrObrazac07Rm'));
            return row.get(this.key).naziv;
          }
        }));
      } else {
        columns.push(ColumnDefinition['default'].create({
          key: 'obrObrazac07Rms.sifRadnoMestoStruktura',
          savedWidth: 300,
          supportSort: true,
          headerCellName: this.get('i18n').t('obrObrazac07.radnoMestoStruktura'),
          headerCellView: 'zaris-header-cell',
          getCellContent: function getCellContent(row) {
            console.log(row.get(this.key + '.sifRadnoMesto'));console.log(row.get(this.key + '.sysOrganizacionaJedinica'));
            return row.get(this.key + '.sifRadnoMesto.naziv') + '(' + row.get(this.key + '.sysOrganizacionaJedinica.naziv') + ')';
          }
        }));
      }

      columns.push(ColumnDefinition['default'].create({
        key: 'sysKorisnik',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac07.korisnik'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).username;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumVreme',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac07.datum'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumVreme',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac07.vreme'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('H:m:s') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'skeniranFileName',
        savedWidth: 90,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac07.skeniran'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) != null) {
            return Ember['default'].String.htmlSafe('<div class="circle bg-emerald" />');
          } else {
            return Ember['default'].String.htmlSafe('<div class="circle bg-red" />');
          }
        }
      }));

      return columns;
    })

  });

});