define('zaris-frontend/components/zaris-header-table-container', ['exports', 'ember-table/components/header-table-container'], function (exports, HeaderTableContainer) {

  'use strict';

  exports['default'] = HeaderTableContainer['default'].extend({
    actions: {
      sortByColumn: function sortByColumn(content) {
        this.set('action', 'sortByColumn');
        this.sendAction('action', content);
      }
    }
  });

});