define('zaris-frontend/components/sif-revers-modal-lzo', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'sifReversLzoModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "90%",
    "data-height": "500px",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",
    "data-type": "modal",

    contentReversLzo: Em.A(),
    myModalPanelShow: false,
    idSelectedLice: null,
    idSelectedReversReal: null,
    contentHashmap: Em.A(),

    idSelectedRevers: Ember['default'].computed('refreshReversLzo', function () {
      if (this.get('target').get('isNew')) {
        return undefined;
      } else {
        return this.get('idSelectedReversReal');
      }
    }),

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        console.log(this.get('contentHashmap'));

        var outter = this;
        var i18n = this.get('i18n');

        var obj = this.get('modelObject');
        obj.idLice = this.get('idSelectedLice');

        var reversiMap = this.get('contentHashmap');
        var reversi = [];
        var i = 0;
        for (var m in reversiMap) {
          reversi[i] = reversiMap[m];
          i++;
        }

        obj.reversi = reversi;

        var vsifReversLzo = Models['default'].VsifReversLzo.create({ errorHandlerController: this });
        vsifReversLzo.create(obj, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('app.menu.reversi').string,
            content: i18n.t('commonPanel.dodavanjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
          outter.toggleProperty('refresh');
          outter.toggleProperty('refreshRevers');
        }, function (e) {
          $.Notify({
            caption: i18n.t('commonPanel.dodavanjeUspesno').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: true,
            type: 'alert'
          });
        });
      },
      update: function update() {
        console.log(this.get('contentHashmap'));

        var outter = this;
        var i18n = this.get('i18n');

        var obj = this.get('modelObject');
        obj.id = this.get('idSelectedRevers');
        obj.idLice = this.get('idSelectedLice');

        var reversiMap = this.get('contentHashmap');
        var reversi = [];
        var i = 0;
        for (var m in reversiMap) {
          reversi[i] = reversiMap[m];
          i++;
        }

        obj.reversi = reversi;

        var vsifReversLzo = Models['default'].VsifReversLzo.create({ errorHandlerController: this });
        vsifReversLzo.update(obj, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('app.menu.reversi').string,
            content: i18n.t('commonPanel.dodavanjeUspesno').string,
            keepOpen: false,
            type: 'success'
          }, function (e) {
            $.Notify({
              caption: i18n.t('commonPanel.dodavanjeUspesno').string,
              content: i18n.t(e.responseJSON.messages[0]).string,
              keepOpen: true,
              type: 'alert'
            });
          });
          console.log('closePaneL');
          outter.closePanel();
          outter.toggleProperty('refresh');
        });
      }

    },

    openPanel: Ember['default'].observer('showReversLzoModalPanel', function () {
      if (this.get('target').get('isNew')) {}
      this.set('modelObject', {});
      this.set('modelObject.datum', moment().toDate());

      this.toggleProperty('refreshReversLzo');

      this.set('contentHashmap', {});

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.sifReversLzoModal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshReversLzo', function () {
      this.toggleProperty('refreshWoResetSelection');
    }),

    isNewReversUnos: Ember['default'].computed('refreshReversLzo', function () {
      return this.get('target').get('isNew');
    }),

    closePanel: function closePanel() {
      $('.sifReversLzoModal').data('dialog').close();
    },

    tableReversLzoPregledUnos: Ember['default'].computed(function () {
      var columns = [];
      var self = this;
      columns.push(ColumnDefinition['default'].create({
        key: 'fgAktivan',
        savedWidth: 100,
        supportSort: true,
        headerCellName: '',
        headerCellView: 'zaris-header-cell',
        tableCellViewClass: 'zaris-datatable-checkbox-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        },
        setCellContent: function setCellContent(row, value) {
          var res = row.set(this.key, value);

          self.get('contentHashmap')[row.get('idObrazac10Lzo')] = row.content;

          return res;
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'kategorija',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'kategorija',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'lzo',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'LZO',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'kolicina',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Kolicina',
        headerCellView: 'zaris-header-cell',
        tableCellViewClass: 'zaris-editable-table-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        },
        setCellContent: function setCellContent(row, value) {
          self.get('contentHashmap')[row.get('idObrazac10Lzo')] = row.content;
          return row.set(this.key, value);
        }
      }));

      return columns;
    }),
    tableReversLzoPregledIzmena: Ember['default'].computed(function () {
      var columns = [];
      var self = this;
      columns.push(ColumnDefinition['default'].create({
        key: 'fgAktivan',
        savedWidth: 100,
        supportSort: true,
        headerCellName: '',
        headerCellView: 'zaris-header-cell',
        tableCellViewClass: 'zaris-datatable-checkbox-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        },
        setCellContent: function setCellContent(row, value) {
          var res = row.set(this.key, value);
          self.get('contentHashmap')[row.get('idObrazac10Lzo')] = row.content;

          return res;
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'kategorija',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'kategorija',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'lzo',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'LZO',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'kolicina',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'Kolicina',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'datumRazduzivanja',
        savedWidth: 180,
        supportSort: true,
        headerCellName: 'datumRazduzivanja',
        headerCellView: 'zaris-header-cell',
        tableCellViewClass: 'zaris-datatable-date-cell',
        getCellContent: function getCellContent(row) {
          return row.get('datumRazduzivanja');
        },
        setCellContent: function setCellContent(row, value) {
          var res = row.set('datumRazduzivanja', value);
          self.get('contentHashmap')[row.get('idObrazac10Lzo')] = row.content;
          return res;
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'napomena',
        headerCellView: 'zaris-header-cell',
        tableCellViewClass: 'zaris-editable-table-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        },
        setCellContent: function setCellContent(row, value) {
          self.get('contentHashmap')[row.get('idObrazac10Lzo')] = row.content;
          return row.set(this.key, value);
        }
      }));

      return columns;
    }),
    tableReversLzoPregled: Ember['default'].computed('isNewUnos', function () {
      if (this.get('target').get('isNew')) {
        return this.get('tableReversLzoPregledUnos');
      } else {
        return this.get('tableReversLzoPregledIzmena');
      }
    })
  });

});