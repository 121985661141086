define('zaris-frontend/templates/components/sif-radna-mesta-struktura-edit-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 76,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-radna-mesta-struktura-edit-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control text full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3,"class","ember-view ember-text-field");
        dom.setAttribute(el3,"readonly","true");
        dom.setAttribute(el3,"type","text");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","input-control checkbox");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","check");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","caption");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row cells3");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","cell");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","input-control text");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","cell");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","input-control text");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","cell");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","input-control text");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control textarea full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control textarea full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control textarea full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-control textarea full-size");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-checkmark");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-cross");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [3, 1]);
        var element4 = dom.childAt(element2, [5, 1]);
        var element5 = dom.childAt(element2, [7, 1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element5, [5]);
        var element9 = dom.childAt(element2, [27]);
        var element10 = dom.childAt(element2, [29]);
        var morphs = new Array(24);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        morphs[3] = dom.createAttrMorph(element3, 'value');
        morphs[4] = dom.createMorphAt(element4,1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element2, [9]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element2, [11]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element2, [13]),0,0);
        morphs[15] = dom.createMorphAt(dom.childAt(element2, [15]),1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element2, [17]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element2, [19]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element2, [21]),0,0);
        morphs[19] = dom.createMorphAt(dom.childAt(element2, [23]),1,1);
        morphs[20] = dom.createElementMorph(element9);
        morphs[21] = dom.createMorphAt(element9,2,2);
        morphs[22] = dom.createElementMorph(element10);
        morphs[23] = dom.createMorphAt(element10,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["sifRadnoMestoStruktura.azuriranjeHeader"],[],["loc",[null,[6,10],[6,57]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["sifRadnoMestoStruktura.inputRadnoMestoUStrukturi"],[],["loc",[null,[13,9],[13,65]]]],
        ["attribute","value",["concat",[["get","modelObject.organizacionaJedinicaNaziv",["loc",[null,[15,86],[15,124]]]],"(",["get","modelObject.radnoMestoNaziv",["loc",[null,[15,129],[15,156]]]],")"]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","modelObject.FgRizicno",["loc",[null,[20,38],[20,59]]]]],[],[]]],["loc",[null,[20,6],[20,62]]]],
        ["inline","t",["sifRadnoMestoStruktura.inputRizik"],[],["loc",[null,[22,28],[22,69]]]],
        ["inline","t",["sifRadnoMestoStruktura.adresa"],[],["loc",[null,[29,19],[29,56]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.adresa",["loc",[null,[31,42],[31,60]]]]],[],[]]],["loc",[null,[31,16],[31,63]]]],
        ["inline","t",["sifRadnoMestoStruktura.mesto"],[],["loc",[null,[35,19],[35,55]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.mesto",["loc",[null,[37,42],[37,59]]]]],[],[]]],["loc",[null,[37,16],[37,62]]]],
        ["inline","t",["sifRadnoMestoStruktura.drzava"],[],["loc",[null,[41,19],[41,56]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.drzava",["loc",[null,[43,42],[43,60]]]]],[],[]]],["loc",[null,[43,16],[43,63]]]],
        ["inline","t",["sifRadnoMestoStruktura.inputOpis"],[],["loc",[null,[49,9],[49,49]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.opis",["loc",[null,[51,23],[51,39]]]]],[],[]]],["loc",[null,[51,6],[51,41]]]],
        ["inline","t",["sifRadnoMestoStruktura.inputObavestenja"],[],["loc",[null,[54,9],[54,56]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.obavestenja",["loc",[null,[56,23],[56,46]]]]],[],[]]],["loc",[null,[56,6],[56,49]]]],
        ["inline","t",["sifRadnoMestoStruktura.inputMere"],[],["loc",[null,[59,9],[59,49]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.mere",["loc",[null,[61,23],[61,39]]]]],[],[]]],["loc",[null,[61,6],[61,41]]]],
        ["inline","t",["sifRadnoMestoStruktura.inputZdravstveniUslovi"],[],["loc",[null,[65,9],[65,62]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.zdravstveniUslovi",["loc",[null,[67,23],[67,52]]]]],[],[]]],["loc",[null,[67,6],[67,55]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[72,23],[72,28]]]],"create","update"],[],["loc",[null,[72,19],[72,47]]]]],[],["loc",[null,[72,10],[72,49]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[72,101],[72,126]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[73,10],[73,36]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[73,84],[73,109]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});