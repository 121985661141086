define('zaris-frontend/controllers/sif-licna-zastitna-oprema', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, conf) {

  'use strict';

  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;
  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service('i18n'),

    objRadnoMesto: {},
    objRadnoMestoStruktura: {},

    content1: Em.A(),

    init: function init() {
      this._super();

      var objRadnoMesto = this.get('objRadnoMesto');
      objRadnoMesto.id = null;
      objRadnoMesto.naziv = "";

      var objRadnoMestoStruktura = this.get('objRadnoMestoStruktura');
      objRadnoMestoStruktura.id = null;
      objRadnoMestoStruktura.naziv = "";
    },

    componentTab1: 'zaris-tree-table',
    componentTab2: null,

    actions: {
      openTab1: function openTab1() {
        this.set('componentTab1', 'zaris-tree-table');
        this.set('componentTab2', null);
      },

      openTab2: function openTab2() {
        this.set('componentTab1', null);
        this.set('componentTab2', 'zaris-datatable');
      }
    },

    valueFactors: Ember['default'].computed('valueFactors', function () {
      var i18n = this.get('i18n');

      return Em.A([{
        'display_name': i18n.t('sifLzoVrsteVelicina.vrstaVelicine').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('sifLzoTree.rokMeseci').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('sifLzoTree.intervalMeseci').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('sifLzoTree.imaUputstvo').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('sifLzoTree.imaAtest').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('sifLzoTree.ucitanoUputstvo').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }, {
        'display_name': i18n.t('sifLzoTree.ucitanAtest').string,
        'is_time_series': false,
        'template_id': 'personal_value_factor'
      }]);
    }),

    zarisColumnsDefinition: [{}, { savedWidth: 75 }, { savedWidth: 75 }, { savedWidth: 75, tableCellView: 'zaris-tree-table-cell-boolean-icon', translateBoolean: false }, { savedWidth: 75, tableCellView: 'zaris-tree-table-cell-boolean-icon', translateBoolean: false }, { savedWidth: 75, tableCellView: 'zaris-tree-table-cell-file', translateBoolean: false }, { savedWidth: 75, tableCellView: 'zaris-tree-table-cell-file', translateBoolean: false }],

    /****************** tab2 ****************/

    selectedDomainName: "radnoMesto",

    // refresh tabele na promenu domena (radna mesta / radna mesta u strukturi)
    selectedDomainChanged: Ember['default'].observer('selectedDomainView', function () {
      this.toggleProperty('refresh2');
    }),

    selectedDomainView: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'VsifRadnoMestoLzo' : 'VsifRadnoMestoStrukturaLzo';
    }),

    radnaMestaColumns: Ember['default'].computed(function () {
      var nazivColumn = ColumnDefinition['default'].create({
        key: 'radnoMestoNaziv',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLzo.tab2.radnaMesta'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var kategorijeColumn = ColumnDefinition['default'].create({
        key: 'kategorije',
        savedWidth: 500,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifLzo.tab2.kategorijeLicneZastitneOpreme'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      return [nazivColumn, kategorijeColumn];
    })

  });

});