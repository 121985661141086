define('zaris-frontend/components/sif-korisnicki-definisane-obuke-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'sifKorisnickiDefinisaneObukeModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    isNew: true,
    isNew2: true,
    modelObject: {},

    yesNoModalPanelShow: false,

    selectedRowObukaDokument: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    componentName: null,
    myModalPanelShow2: false,

    didInsertElement: function didInsertElement() {
      this._super();
      this.$('#modalDokumentacijaYesNo').appendTo(this.$(document.body));
      this.$('#sifObukaDokumentModal').appendTo(this.$(document.body));
    },

    tab2Content: Em.A(),

    tableColumns2: Ember['default'].computed(function () {
      var self = this;

      var nazivColumn = ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.drugiTab.inputNaziv'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var sifraColumn = ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.drugiTab.inputSifra'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var verzijaColumn = ColumnDefinition['default'].create({
        key: 'verzija',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.drugiTab.inputVerzija'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var vrstaColumn = ColumnDefinition['default'].create({
        key: 'sifVrstaDokumenta',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.drugiTab.vrsta'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (!row.get(this.key) || !row.get(this.key).idVrstaDokumenta) return "";
          return row.get(this.key).naziv;
        }
      });
      var nazivFajlaColumn = ColumnDefinition['default'].create({
        key: 'dokumentFileName',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.drugiTab.nazivFajla'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var datumColumn = ColumnDefinition['default'].create({
        key: 'datumVreme',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.drugiTab.inputDatum'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.dateOutputFormat(row.get(this.key));
        }
      });
      var opisColumn = ColumnDefinition['default'].create({
        key: 'opis',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sifKorisnickiDefinisaneObuke.drugiTab.inputOpis'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });

      return [nazivColumn, sifraColumn, verzijaColumn, vrstaColumn, nazivFajlaColumn, datumColumn, opisColumn];
    }),

    actions: {
      openTab1: function openTab1() {
        this.set('componentName', null);
      },

      openTab2: function openTab2() {
        this.set('componentName', 'zaris-datatable');
        this.toggleProperty('refresh2');
      },

      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      selectTipObuke: function selectTipObuke(value, component) {
        if (!this.get('modelObject.fgPeriodicno')) {
          this.set('modelObject.intervalMeseci', null);
        } else {
          this.set('modelObject.intervalMeseci', 1);
        }
      },
      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifObukaModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');
            sifObukaModel = Models['default'].SifObuka.create({ errorHandlerController: _this });

            sifObukaModel.create(_this.get('modelObject'), _this.get('login').getAccessToken(), function (d) {
              // kreirana je obuka - isNew postaje false - omoguceno slobodno kretanje po tabovima
              outter.set('isNew', false);

              // setovanje odgovora u modelObject
              outter.set('modelObject', d.sifObuka);

              // pitas da li hoce dokumentaciju
              //outter.get("target").send('openYesNoModal', 'idYesNoDialog', 'obrObrazac02.brisanjeHeader', 'obrObrazac02.brisanjePitanje', outter, 'gotoTab2');
              outter.toggleProperty('yesNoModalPanelShow');
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifKorisnickiDefinisaneObuke.dodavanjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      },
      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifObukaModel;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');
            sifObukaModel = Models['default'].SifObuka.create({ errorHandlerController: _this2 });

            sifObukaModel.update(_this2.get('modelObject'), _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("sifKorisnickiDefinisaneObuke.azuriranjeHeader").string,
                content: i18n.t("sifKorisnickiDefinisaneObuke.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifKorisnickiDefinisaneObuke.azuriranjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      var link = document.getElementById("a_frame1");
      link.click();
      Ember['default'].showModalPanel('.sifKorisnickiDefinisaneObukeModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifKorisnickiDefinisaneObukeModal').data('dialog').close();
    }
  });

});