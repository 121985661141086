define('zaris-frontend/components/sif-obuka-zakazana-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifObukaZakazanaModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    setSledecaObuka: Ember['default'].observer('setSledeca', 'modelObject.datumObuke', function () {
      if (this.get('isNew')) {
        var interval = this.get('modelObject.intervalMeseci');
        if (this.get('modelObject.datumObuke') && interval && !isNaN(interval)) {
          this.set('modelObject.datumSledeceObuke', moment(this.get('modelObject.datumObuke')).add(interval, 'months').toDate());
        }
      }
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      odabranaObuka: function odabranaObuka(selected) {
        var outter = this;
        var items = this.get('selectItems');
        items.forEach(function (val, index) {
          if (val.idObuka === selected) {
            if (val.fgPeriodicno) {
              outter.set('modelObject.intervalMeseci', val.intervalMeseci);
            } else {
              outter.set('modelObject.intervalMeseci', outter.get('i18n').t('sifKorisnickiDefinisaniPregledi.pregledSeObavljaJednom'));
            }

            outter.set('modelObject.opis', val.opis);

            outter.toggleProperty('setSledeca');
          }
        });
      },
      merge: function merge() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var sifObukaZakazanaModel;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            sifObukaZakazanaModel = Models['default'].SifObukaZakazana.create({ errorHandlerController: _this });

            sifObukaZakazanaModel.create(_this.get('modelObject'), _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('sifKorisnickiDefinisaniPregledi.dodavanjeHeader').string,
                content: i18n.t('sifKorisnickiDefinisaniPregledi.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refreshZakazan');
            }, function (e) {
              $.Notify({
                caption: i18n.t('sifKorisnickiDefinisaniPregledi.dodavanjeHeader').string,
                content: i18n.t(e.responseJSON.messages[0]).string,
                keepOpen: true,
                type: 'alert'
              });
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      if (this.get('isNew')) {
        this.set('modelObject', {});
        this.set('modelObject.sifObuka', {});
      } else {
        this.set('modelObject', jQuery.extend(true, {}, this.get('selectedZakazanaObuka')));
      }

      Ember['default'].showModalPanel('.sifObukaZakazanaModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifObukaZakazanaModal').data('dialog').close();
    }
  });

});