define('zaris-frontend/controllers/application', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        openYesNoModal: function openYesNoModal(target, titleMsgKey, descMsgKey, controller, action, messageType) {
            var modal = this.get('comp-' + target);
            modal.send('openModal', titleMsgKey, descMsgKey, controller, action, messageType);
        },
        closeYesNoModal: function closeYesNoModal(target) {
            var modal = this.get('comp-' + target);
            modal.send('closeModal');
        },
        openOkModal: function openOkModal(target, titleMsgKey, descMsgKey, messageType) {
            var modal = this.get('comp-' + target);
            modal.send('openModal', titleMsgKey, descMsgKey, messageType);
        },
        closeOkModal: function closeOkModal(target) {
            var modal = this.get('comp-' + target);
            modal.send('closeModal');
        },

        login: Ember['default'].inject.service('login'),
        actions: {
            resetSesije: function resetSesije() {
                var userModel = Models['default'].UserDetails.create({ errorHandlerController: this });
                var result = userModel.getResult('resetCurrentUserSesion', this.get('login').getAccessToken(), 'GET');
            }
        }

    });

});