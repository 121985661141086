define('zaris-frontend/components/obr-obrazac07-opasna-materija-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'obrObrazac07OpasnaMaterijaModal', 'modalPanelLevel2', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-width": "60%",
    "data-drag-element": ".window-caption",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",

    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    opasnaMaterijaSelectItems: Em.A(),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      merge: function merge() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var model;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            model = Models['default'][_this.get('selectedDomainName') === 'radnoMesto' ? 'ObrObrazac07OpasnaMaterijaRm' : 'ObrObrazac07OpasnaMaterijaRms'].create({ errorHandlerController: _this });

            model.create(_this.get('opasnaMaterijaObr07Obj'), _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('obrObrazac04.dodavanjeHeader').string,
                content: i18n.t('obrObrazac04.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.toggleProperty('refreshObrazac07EditModal');
              outter.toggleProperty('refreshWoResetSelection');
              outter.closePanel();
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('showModalPanelOpasnaMaterija', function () {
      var outter = this;

      var loginService = this.get('login');

      // dovuci sve opasne materije - selectItems,
      var model = Models['default']['SifOpasnaMaterija'].create({ errorHandlerController: this });
      var selectItems = model.selectItemsGetResult(loginService.getAccessToken());

      // dovuci content
      var content = this.get('content');

      // prolazi kroz content
      // izbaci iz selectItems ako taj item postoji u contentu
      var filteredItems = selectItems.filter(function (item, index, self) {
        if (content.findBy('sifOpasnaMaterija.idOpasnaMaterija', item.value) == undefined) {
          return true;
        }
      });
      this.set('opasnaMaterijaSelectItems', filteredItems);

      setTimeout(function () {
        if (outter.get('isNewUnos')) {
          var opasnaMaterijaObr07Obj = {};
          opasnaMaterijaObr07Obj.sifOpasnaMaterija = {};
          opasnaMaterijaObr07Obj[outter.get('selectedDomainCapsDown')] = outter.get('selektovanoRadnoMesto');

          outter.set('opasnaMaterijaObr07Obj', opasnaMaterijaObr07Obj);
        } else {
          var obj = jQuery.extend(true, {}, outter.get('selektovanaOpasnaMaterija'));
          obj[outter.get('selectedDomainCapsDown')] = outter.get('selektovanoRadnoMesto');
          outter.set('opasnaMaterijaObr07Obj', obj);
        }

        Ember['default'].showModalPanel('#' + outter.get('id'));
      }, 100);
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('#' + this.get('id')).data('dialog').close();
    }

  });

});