define('zaris-frontend/controllers/sys-promena-jezika', ['exports', 'ember', 'zaris-frontend/conf'], function (exports, Ember, conf) {

  'use strict';

  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({
    i18n: inject.service(),
    current: localStorage.locale,
    menuService: Ember['default'].inject.service('menu'),
    login: Ember['default'].inject.service('login'),

    locales: Ember['default'].computed('i18n.locale', 'current', function () {
      var i18n = this.get('i18n');
      var current = this.get('current');
      return this.get('i18n.locales').map(function (loc) {
        return { id: loc, text: i18n.t('language-select.language.' + loc), current: current };
      });
    }),

    // It would be nice to do this with `{{action "setLocale" on="change"}}`
    // in the template, but the template doesn't include the component's own
    // tag yet. See https://github.com/emberjs/rfcs/pull/60
    actions: {
      change: function change(val, item) {
        this.set('current', val);
      },
      update: function update() {
        var val = this.get('current');
        localStorage.locale = val;
        this.get('i18n').set('locale', val);
        this.set('i18n.locale', val);

        console.log(this.get('i18n.locale'));
        moment.locale(val);

        var menuService = this.get('menuService');
        menuService.generateHTMLMenu();

        var url = conf['default'].serverBaseUrl + '/korisnik/update-locale/' + val + "?access_token=" + this.get('login').getAccessToken();

        $.ajax({
          url: url,
          type: 'POST',
          accepts: 'application/json',
          crossDomain: true,
          async: false,
          headers: conf['default'].corsHeaders,
          success: function success(data) {},
          error: function error(e) {
            console.log('DEBUG: GET Failed', e);
          }
        });
      }
    }
  });

});