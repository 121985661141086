define('zaris-frontend/controllers/sif-parametri-radne-okoline', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/models/models'], function (exports, Ember, ColumnDefinition, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    tableColumns: Ember['default'].computed(function () {

      var nazivColumn = ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 400,
        supportSort: true,
        headerCellName: 'Naziv',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get('xsifKategorijaParametraOkoline.naziv') + " -> " + row.get('naziv');
        }
      });
      var sifraColumn = ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 400,
        supportSort: true,
        headerCellName: 'Sifra',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });

      return [nazivColumn, sifraColumn];
    })

  });

});