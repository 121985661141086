define('zaris-frontend/conf', ['exports'], function (exports) {

  'use strict';

  var conf = {
    serverBaseUrl: 'https://app.bazisonline.com/backend',
    corsHeadersToken: {
      "Access-Control-Request-Headers": "x-requested-with",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "http://147.91.15.139:4200 https://app.bazisonline.com",
      "Authorization": "Basic emFyaXNfY2xpZW50OjEyMw=="
    },
    corsHeaders: {
      "Access-Control-Request-Headers": "x-requested-with",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "http://147.91.15.139:4200 https://app.bazisonline.com"
    },
    corsHeadersMultipart: {
      "Access-Control-Request-Headers": "x-requested-with",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "http://147.91.15.139:4200 https://app.bazisonline.com",
      "Authorization": "Basic emFyaXNfY2xpZW50OjEyMw==",
      "enctype": "multipart/form-data",
      "Content-Type": "Content-Type:multipart/form-data"
    }
  };

  exports['default'] = conf;

});