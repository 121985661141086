define('zaris-frontend/templates/components/sif-lica-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 6
              },
              "end": {
                "line": 85,
                "column": 47
              }
            },
            "moduleName": "zaris-frontend/templates/components/sif-lica-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["pol.muski"],[],["loc",[null,[85,28],[85,45]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 6
              },
              "end": {
                "line": 86,
                "column": 47
              }
            },
            "moduleName": "zaris-frontend/templates/components/sif-lica-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["pol.zenski"],[],["loc",[null,[86,28],[86,46]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 4
            },
            "end": {
              "line": 87,
              "column": 4
            }
          },
          "moduleName": "zaris-frontend/templates/components/sif-lica-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value",1],0,null,["loc",[null,[85,6],[85,60]]]],
          ["block","x-option",[],["value",2],1,null,["loc",[null,[86,6],[86,60]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 108,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-lica-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-panel-content ui equal width grid");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","column");
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","column");
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control select full-size");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","input-control text full-size");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","input-control checkbox");
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","caption");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-checkmark");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"class","button");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-cross");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [33, 1]);
        var element7 = dom.childAt(element2, [3]);
        var element8 = dom.childAt(element2, [5]);
        var morphs = new Array(38);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [7]),1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [9]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element4, [11]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element4, [13]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element4, [15]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element4, [17]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element4, [19]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element4, [21]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element4, [23]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element4, [25]),0,0);
        morphs[15] = dom.createMorphAt(dom.childAt(element4, [27]),1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element5, [5]),0,0);
        morphs[19] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element5, [9]),0,0);
        morphs[21] = dom.createMorphAt(dom.childAt(element5, [11]),1,1);
        morphs[22] = dom.createMorphAt(dom.childAt(element5, [13]),0,0);
        morphs[23] = dom.createMorphAt(dom.childAt(element5, [15]),1,1);
        morphs[24] = dom.createMorphAt(dom.childAt(element5, [17]),0,0);
        morphs[25] = dom.createMorphAt(dom.childAt(element5, [19]),1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element5, [21]),0,0);
        morphs[27] = dom.createMorphAt(dom.childAt(element5, [23]),1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element5, [25]),0,0);
        morphs[29] = dom.createMorphAt(dom.childAt(element5, [27]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element5, [29]),0,0);
        morphs[31] = dom.createMorphAt(dom.childAt(element5, [31]),1,1);
        morphs[32] = dom.createMorphAt(element6,1,1);
        morphs[33] = dom.createMorphAt(dom.childAt(element6, [5]),0,0);
        morphs[34] = dom.createElementMorph(element7);
        morphs[35] = dom.createMorphAt(element7,2,2);
        morphs[36] = dom.createElementMorph(element8);
        morphs[37] = dom.createMorphAt(element8,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",[["subexpr","if",[["get","isNew",["loc",[null,[6,18],[6,23]]]],"sifLica.dodavanjeHeader","sifLica.azuriranjeHeader"],[],["loc",[null,[6,14],[6,77]]]]],[],["loc",[null,[6,10],[6,79]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["sifLica.inputIme"],[],["loc",[null,[14,9],[14,33]]]],
        ["inline","input-validation",[],["id","modalLicaIme","type","text","value",["subexpr","@mut",[["get","modelObject.ime",["loc",[null,[16,61],[16,76]]]]],[],[]]],["loc",[null,[16,6],[16,79]]]],
        ["inline","t",["sifLica.inputPrezime"],[],["loc",[null,[19,9],[19,37]]]],
        ["inline","input-validation",[],["id","modalLicaPrezime","type","text","value",["subexpr","@mut",[["get","modelObject.prezime",["loc",[null,[21,65],[21,84]]]]],[],[]]],["loc",[null,[21,6],[21,87]]]],
        ["inline","t",["sifLica.inputOcevoIme"],[],["loc",[null,[24,9],[24,38]]]],
        ["inline","input",[],["id","modalLicaSrednjeIme","type","text","value",["subexpr","@mut",[["get","modelObject.srednjeIme",["loc",[null,[26,57],[26,79]]]]],[],[]]],["loc",[null,[26,6],[26,82]]]],
        ["inline","t",["sifLica.inputDatumRodjenja"],[],["loc",[null,[29,9],[29,43]]]],
        ["inline","pikaday-input",[],["useUTC",true,"id","modalLicaDatumRodjenja","value",["subexpr","@mut",[["get","modelObject.datumRodjenja",["loc",[null,[31,68],[31,93]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[31,6],[31,115]]]],
        ["inline","t",["sifLica.inputMaticniBroj"],[],["loc",[null,[34,9],[34,41]]]],
        ["inline","input-validation",[],["id","modalLicaJMBG","validType","jmbg","value",["subexpr","@mut",[["get","modelObject.maticniBroj",["loc",[null,[36,67],[36,90]]]]],[],[]]],["loc",[null,[36,6],[36,93]]]],
        ["inline","t",["sifLica.inputMestoRodjenja"],[],["loc",[null,[39,9],[39,43]]]],
        ["inline","input",[],["id","modalLicaMestoRodjenja","type","text","value",["subexpr","@mut",[["get","modelObject.mestoRodjenja",["loc",[null,[41,60],[41,85]]]]],[],[]]],["loc",[null,[41,6],[41,88]]]],
        ["inline","t",["sifLica.inputOpstinaRodjenja"],[],["loc",[null,[44,9],[44,45]]]],
        ["inline","input",[],["id","modalLicaOpstinaRodjenja","type","text","value",["subexpr","@mut",[["get","modelObject.opstinaRodjenja",["loc",[null,[46,62],[46,89]]]]],[],[]]],["loc",[null,[46,6],[46,92]]]],
        ["inline","t",["sifLica.inputDatumPocetkaRada"],[],["loc",[null,[52,9],[52,46]]]],
        ["inline","pikaday-input",[],["useUTC",true,"id","modalLicaDatumPocetkaRada","value",["subexpr","@mut",[["get","modelObject.datumPocetkaRada",["loc",[null,[54,71],[54,99]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input"],["loc",[null,[54,6],[54,143]]]],
        ["inline","t",["sifLica.inputInterniMaticniBroj"],[],["loc",[null,[57,9],[57,48]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","modelObject.interniMaticniBroj",["loc",[null,[59,32],[59,62]]]]],[],[]]],["loc",[null,[59,6],[59,65]]]],
        ["inline","t",["sifLica.inputZanimanje"],[],["loc",[null,[62,9],[62,39]]]],
        ["inline","input",[],["id","modalLicaZanimanje","type","text","value",["subexpr","@mut",[["get","modelObject.zanimanje",["loc",[null,[64,56],[64,77]]]]],[],[]]],["loc",[null,[64,6],[64,80]]]],
        ["inline","t",["sifLica.inputAdresa"],[],["loc",[null,[67,9],[67,36]]]],
        ["inline","input",[],["id","modalLicaAdresa","type","text","value",["subexpr","@mut",[["get","modelObject.adresa",["loc",[null,[69,53],[69,71]]]]],[],[]]],["loc",[null,[69,6],[69,74]]]],
        ["inline","t",["sifLica.inputMesto"],[],["loc",[null,[72,9],[72,35]]]],
        ["inline","input",[],["id","modalLicaMesto","type","text","value",["subexpr","@mut",[["get","modelObject.mesto",["loc",[null,[74,52],[74,69]]]]],[],[]]],["loc",[null,[74,6],[74,72]]]],
        ["inline","t",["sifLica.inputDrzava"],[],["loc",[null,[77,9],[77,36]]]],
        ["inline","input",[],["id","modalLicaDrzava","type","text","value",["subexpr","@mut",[["get","modelObject.drzava",["loc",[null,[79,53],[79,71]]]]],[],[]]],["loc",[null,[79,6],[79,74]]]],
        ["inline","t",["sifLica.inputPol"],[],["loc",[null,[82,9],[82,33]]]],
        ["block","x-select",[],["id","modalLicaPol","value",["subexpr","@mut",[["get","modelObject.pol.idPol",["loc",[null,[84,40],[84,61]]]]],[],[]]],0,null,["loc",[null,[84,4],[87,17]]]],
        ["inline","t",["sifLica.inputDatumZavrsetkaRada"],[],["loc",[null,[90,9],[90,48]]]],
        ["inline","pikaday-input",[],["useUTC",true,"id","modalLicaDatumZavrsetkaRada","value",["subexpr","@mut",[["get","modelObject.datumZavrsetkaRada",["loc",[null,[92,73],[92,103]]]]],[],[]],"format","DD.MM.YYYY","class","pikaday-input"],["loc",[null,[92,6],[92,147]]]],
        ["inline","input",[],["id","modalLicaLiceZaBzr","type","checkbox","checked",["subexpr","@mut",[["get","modelObject.liceZaBzr",["loc",[null,[97,60],[97,81]]]]],[],[]]],["loc",[null,[97,4],[97,84]]]],
        ["inline","t",["sifLica.inputLiceZaBzr"],[],["loc",[null,[99,26],[99,56]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[105,21],[105,26]]]],"create","update"],[],["loc",[null,[105,17],[105,45]]]]],[],["loc",[null,[105,8],[105,47]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[105,99],[105,124]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[106,8],[106,34]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[106,82],[106,107]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});