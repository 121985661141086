define('zaris-frontend/templates/components/obr-obrazac07-new-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/obr-obrazac07-new-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [5]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createAttrMorph(element4, 'class');
        morphs[4] = dom.createElementMorph(element4);
        morphs[5] = dom.createMorphAt(element4,2,2);
        morphs[6] = dom.createElementMorph(element5);
        morphs[7] = dom.createMorphAt(element5,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["obrObrazac07.azuriranjeHeader"],[],["loc",[null,[6,10],[6,47]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","component",[["get","componentName",["loc",[null,[13,16],[13,29]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[14,13],[14,17]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableIzborRmRmsColumns",["loc",[null,[15,14],[15,36]]]]],[],[]],"domainName",["subexpr","@mut",[["get","selectedReferencedDomain",["loc",[null,[16,17],[16,41]]]]],[],[]],"domainId",["subexpr","@mut",[["get","selectedReferencedDomainId",["loc",[null,[17,15],[17,41]]]]],[],[]],"titleMsgKey","sifRadnoMestoStruktura.brisanjeHeader","descYesNoMsgKey","sifRadnoMestoStruktura.brisanjePitanje","descDeleteSuccessMsgKey","sifRadnoMestoStruktura.brisanjeUspesno","titleDeleteMsgKey","sifRadnoMestoStruktura.brisanjeHeader","descDeleteQuestionMsgKey","sifRadnoMestoStruktura.brisanjePitanje","descDeleteSuccessMsgKey","sifRadnoMestoStruktura.brisanjeUspesno","idModalPanel","sifOrganizacionaJedinicaModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[25,12],[25,17]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRmRms",["loc",[null,[26,18],[26,31]]]]],[],[]],"idRowSelected",["subexpr","@mut",[["get","idRowSelectedRmRms",["loc",[null,[27,20],[27,38]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshObrazac07NewModal",["loc",[null,[28,14],[28,38]]]]],[],[]],"renderControlButtons",false,"hasHeader",true,"hasFooter",false,"content",["subexpr","@mut",[["get","radnoMestoIzborContent",["loc",[null,[32,14],[32,36]]]]],[],[]],"perPage",15],["loc",[null,[13,4],[34,6]]]],
        ["attribute","class",["concat",["button ",["subexpr","if",[["get","isRowSelected",["loc",[null,[39,51],[39,64]]]],"success","zarisDisableButton"],[],["loc",[null,[39,46],[39,97]]]]]]],
        ["element","action",["create"],[],["loc",[null,[39,12],[39,31]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[39,135],[39,160]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[40,12],[40,38]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[40,86],[40,111]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});