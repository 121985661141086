define('zaris-frontend/components/sys-korisnik-rola-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'sysKorisnikRolaModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentKorisnikRola: Em.A(),
    alteredRows: Em.A(),
    modelObjectKorisnikRola: {},
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      //this.$('.obrObrazac07EditModal').appendTo(this.$(document.body));
    },

    selectedOpasnaMaterijaDomainId: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'idOpasnaMaterijaRm' : 'idOpasnaMaterijaRms';
    }),

    selectedOpasnaMaterijaDomainName: Ember['default'].computed('selectedDomainName', function () {
      return this.get('selectedDomainName') === 'radnoMesto' ? 'ObrObrazac07OpasnaMaterijaRm' : 'ObrObrazac07OpasnaMaterijaRms';
    }),

    actions: {
      save: function save() {
        var outter = this;

        var sysKorisnikRolaModal = Models['default'].SysKorisnikRola.create({ errorHandlerController: this });
        sysKorisnikRolaModal.create(this.get('alteredRows'), this.get('login').getAccessToken(), function () {
          var userModel = Models['default'].UserDetails.create({ errorHandlerController: outter });
          var result = userModel.getResult('resetUserSession/' + outter.get('selectedRow.username'), outter.get('login').getAccessToken(), 'GET');

          outter.send('openOkModal', 'idDialogOk', 'sysKorisnikRola.izmenaRolaHeader', 'sysKorisnikRola.izmenaRolaUspesno', 'success');
          outter.closePanel();
        });
      },
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshKorisnikRolaModal');

      this.set('alteredRows', Em.A());

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.sysKorisnikRolaModal');
    }),

    closePanel: function closePanel() {
      $('.sysKorisnikRolaModal').data('dialog').close();
    },

    tableColumnsKorisnikRola: Ember['default'].computed(function () {
      var columns = [];
      var outter = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'id.idRola',
        savedWidth: 150,
        supportSort: true,
        headerCellName: 'ID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 300,
        supportSort: true,
        headerCellName: 'Naziv',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));
      columns.push(ColumnDefinition['default'].create({
        savedWidth: 100,
        supportSort: false,
        tableCellView: 'zaris-datatable-checkbox-cell',
        getCellContent: function getCellContent(row) {
          return row.get('content.fgImaRolu');
        },
        setCellContent: function setCellContent(row, value) {
          var res = row.set('content.fgImaRolu', value);

          if (outter.get('alteredRows')[row.content.id.idRola] === undefined || outter.get('alteredRows')[row.content.id.idRola] === null) {
            outter.get('alteredRows')[row.content.id.idRola] = row.content;
          } else {
            outter.get('alteredRows')[row.content.id.idRola] = null;
          }

          return res;
        }
      }));

      return columns;
    })
  });

});