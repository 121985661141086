define('zaris-frontend/components/zaris-datatable-remove-cell', ['exports', 'ember-table/components/table-cell'], function (exports, TableCell) {

  'use strict';

  exports['default'] = TableCell['default'].extend({
    actions: {
      removeDatatableItem: function removeDatatableItem() {
        this.toggleProperty('cellContent');
      }
    }
  });

});