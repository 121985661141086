define('zaris-frontend/templates/components/sif-korisnicki-definisane-obuke-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 8
            },
            "end": {
              "line": 33,
              "column": 8
            }
          },
          "moduleName": "zaris-frontend/templates/components/sif-korisnicki-definisane-obuke-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"id","a_frame2");
          dom.setAttribute(el2,"href","#frame_2");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","heading");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","icon mif-cabinet");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
          return morphs;
        },
        statements: [
          ["element","action",["openTab2"],[],["loc",[null,[26,13],[26,34]]]],
          ["inline","t",["sifKorisnickiDefinisaneObuke.dokumentacija"],[],["loc",[null,[29,36],[29,86]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 10
              },
              "end": {
                "line": 54,
                "column": 58
              }
            },
            "moduleName": "zaris-frontend/templates/components/sif-korisnicki-definisane-obuke-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Obuka se obavlja jednom");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 10
              },
              "end": {
                "line": 55,
                "column": 62
              }
            },
            "moduleName": "zaris-frontend/templates/components/sif-korisnicki-definisane-obuke-modal.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Obuka se periodično ponavlja");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 8
            },
            "end": {
              "line": 56,
              "column": 8
            }
          },
          "moduleName": "zaris-frontend/templates/components/sif-korisnicki-definisane-obuke-modal.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value",false],0,null,["loc",[null,[54,10],[54,71]]]],
          ["block","x-option",[],["value",true],1,null,["loc",[null,[55,10],[55,75]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 126,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-korisnicki-definisane-obuke-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","tabcontrol zarisPanel");
        dom.setAttribute(el1,"data-role","tabcontrol");
        dom.setAttribute(el1,"data-on-tab-change","tab_change");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"class","tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","active");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"id","a_frame1");
        dom.setAttribute(el4,"href","#frame_1");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","heading");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","icon mif-cabinet");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","frames");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","frame");
        dom.setAttribute(el3,"id","frame_1");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","window-content");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","content panelCentered");
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","input-control text full-size");
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","input-control textarea full-size");
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","input-control select full-size");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","input-control text full-size");
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-checkmark");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-cross");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","frame");
        dom.setAttribute(el3,"id","frame_2");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel");
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","window-content");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el6,"class","content panelCentered");
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [5]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1, 1]);
        var element6 = dom.childAt(element3, [3]);
        var element7 = dom.childAt(element6, [1, 1, 1, 1]);
        var element8 = dom.childAt(element7, [17]);
        var element9 = dom.childAt(element7, [19]);
        var morphs = new Array(20);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element2);
        morphs[2] = dom.createElementMorph(element5);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1, 3]),0,0);
        morphs[4] = dom.createMorphAt(element4,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [5]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element7, [7]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element7, [9]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element7, [11]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element7, [13]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element7, [15]),1,1);
        morphs[13] = dom.createElementMorph(element8);
        morphs[14] = dom.createMorphAt(element8,2,2);
        morphs[15] = dom.createElementMorph(element9);
        morphs[16] = dom.createMorphAt(element9,2,2);
        morphs[17] = dom.createMorphAt(dom.childAt(element6, [3, 1, 1, 1]),1,1);
        morphs[18] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",[["subexpr","if",[["get","isNew",["loc",[null,[6,18],[6,23]]]],"sifKorisnickiDefinisaneObuke.dodavanjeHeader","sifKorisnickiDefinisaneObuke.azuriranjeHeader"],[],["loc",[null,[6,14],[6,119]]]]],[],["loc",[null,[6,10],[6,121]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["element","action",["openTab1"],[],["loc",[null,[17,13],[17,34]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.osnovniPodaci"],[],["loc",[null,[20,36],[20,86]]]],
        ["block","unless",[["get","isNew",["loc",[null,[24,18],[24,23]]]]],[],0,null,["loc",[null,[24,8],[33,19]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.inputNaziv"],[],["loc",[null,[41,13],[41,60]]]],
        ["inline","input-validation",[],["type","text","value",["subexpr","@mut",[["get","modelObject.naziv",["loc",[null,[43,47],[43,64]]]]],[],[]]],["loc",[null,[43,10],[43,67]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.inputOpis"],[],["loc",[null,[46,13],[46,59]]]],
        ["inline","input-validation",[],["textarea",true,"value",["subexpr","@mut",[["get","modelObject.opis",["loc",[null,[48,49],[48,65]]]]],[],[]]],["loc",[null,[48,10],[48,67]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.inputTip"],[],["loc",[null,[51,13],[51,58]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","modelObject.fgPeriodicno",["loc",[null,[53,26],[53,50]]]]],[],[]],"action","selectTipObuke"],1,null,["loc",[null,[53,8],[56,21]]]],
        ["inline","t",["sifKorisnickiDefinisaneObuke.inputIntervalMeseci"],[],["loc",[null,[59,13],[59,69]]]],
        ["inline","input-validation",[],["validType","positive","disabled",["subexpr","not",[["get","modelObject.fgPeriodicno",["loc",[null,[61,64],[61,88]]]]],[],["loc",[null,[61,59],[61,89]]]],"value",["subexpr","@mut",[["get","modelObject.intervalMeseci",["loc",[null,[61,96],[61,122]]]]],[],[]]],["loc",[null,[61,10],[61,125]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[65,27],[65,32]]]],"create","update"],[],["loc",[null,[65,23],[65,51]]]]],[],["loc",[null,[65,14],[65,53]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[65,105],[65,130]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[66,14],[66,40]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[66,88],[66,113]]]],
        ["inline","component",[["get","componentName",["loc",[null,[75,20],[75,33]]]]],["target",["subexpr","@mut",[["get","target",["loc",[null,[76,17],[76,23]]]]],[],[]],"content",["subexpr","@mut",[["get","tab2Content",["loc",[null,[77,18],[77,29]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns2",["loc",[null,[78,18],[78,31]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","SifObukaDokument","domainId","idObukaDokument","titleMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.brisanjeHeader","descYesNoMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.brisanjePitanje","descDeleteSuccessMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.brisanjeUspesno","titleDeleteMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.brisanjeHeader","descDeleteQuestionMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.brisanjePitanje","descDeleteSuccessMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.brisanjeUspesno","idModalPanel","sifObukaDokumentModal","selectedRow",["subexpr","@mut",[["get","selectedRowObukaDokument",["loc",[null,[91,22],[91,46]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew2",["loc",[null,[92,16],[92,22]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject2",["loc",[null,[93,22],[93,34]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh2",["loc",[null,[94,18],[94,26]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow2",["loc",[null,[95,25],[95,42]]]]],[],[]],"customId",["subexpr","@mut",[["get","modelObject.idObuka",["loc",[null,[96,19],[96,38]]]]],[],[]],"toolbarComponent","sif-obuka-dokument-toolbar","datatableWriteRole","ROLE_SIF_NIVOI_RIZIKA_WRITE"],["loc",[null,[75,8],[99,10]]]],
        ["inline","sif-obuka-dokument-modal",[],["id","sifObukaDokumentModal","target",["subexpr","@mut",[["get","target",["loc",[null,[109,11],[109,17]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew2",["loc",[null,[110,10],[110,16]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject2",["loc",[null,[111,16],[111,28]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh2",["loc",[null,[112,12],[112,20]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow2",["loc",[null,[113,19],[113,36]]]]],[],[]],"customId",["subexpr","@mut",[["get","modelObject.idObuka",["loc",[null,[114,13],[114,32]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRowObukaDokument",["loc",[null,[115,16],[115,40]]]]],[],[]]],["loc",[null,[107,0],[116,2]]]],
        ["inline","modal-dokumentacija-yes-no",[],["id","modalDokumentacijaYesNo","titleMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.dokumentacijaTitle","descMsgKey","sifKorisnickiDefinisaneObuke.drugiTab.dokumentacijaPitanje","modalPanelShow",["subexpr","@mut",[["get","yesNoModalPanelShow",["loc",[null,[122,17],[122,36]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[123,8],[123,13]]]]],[],[]],"outterComponent",["subexpr","@mut",[["get","this",["loc",[null,[124,18],[124,22]]]]],[],[]]],["loc",[null,[118,0],[125,2]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});