define('zaris-frontend/routes/sys-korisnici', ['exports', 'ember', 'zaris-frontend/conf', 'zaris-frontend/models/models'], function (exports, Ember, conf, Models) {

  'use strict';

  var SysKorisniciRoute = Ember['default'].Route.extend({

    loginService: Ember['default'].inject.service('login'),

    model: function model(params) {
      // params.id_pravno_lice - id iz rute
      // preko njega se dovodi i ceo objekat - potreban zbog naslova u hbs fajlu (naziv pravnog lica za koje se prikazuju korisnici)
      var myModel = Models['default'].XsysPravnoLice.create({ errorHandlerController: this });
      params.selectedRow = myModel.findGetResult(params.id_pravno_lice, this.get('loginService').getAccessToken());

      this.set('params', params);
    },
    setupController: function setupController(controller, model) {
      controller.set('params', this.get('params'));
      this._super(controller, model);
    }
  });

  exports['default'] = SysKorisniciRoute;

});