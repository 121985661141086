define('zaris-frontend/components/sif-lzo-nabavka-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac10Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "dialog",
    "data-width": "60%",
    "data-drag-element": ".window-caption",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    isNew: true,
    modelObject: {},
    preglediContent: Em.A(),

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    didInsertElement: function didInsertElement() {
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
      this.$('.obrObrazac10PreglediModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    initSelectItems: Ember['default'].observer('selectedDomainName', function () {
      /* dovlacenje itema za select listu */
      var loginService = this.get('login');
      var sifLzoTreeModel = Models['default'].SifLzoTree.create({ errorHandlerController: this });
      if (this.get('selectedDomainName') == 'ObrObrazac10Lzo') this.set('selectItemsLzo', sifLzoTreeModel.getAllResults('selectItemsPodlozni', loginService.getAccessToken(), 'GET'));else this.set('selectItemsLzo', sifLzoTreeModel.getAllResults('selectItemsNisuPodlozni', loginService.getAccessToken(), 'GET'));
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var modelObject;
          var ObrObrazac10Model;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            modelObject = _this.get('modelObject');

            if (_this.get('selectedDomainName') == "ObrObrazac10Lzo") {
              modelObject.fgPodlezePregledu = true;
            } else {
              modelObject.fgPodlezePregledu = false;
            }

            ObrObrazac10Model = Models['default'].ObrObrazac10Lzo.create({ errorHandlerController: _this });

            ObrObrazac10Model.create(modelObject, _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('obrObrazac10.dodavanjeHeader').string,
                content: i18n.t('obrObrazac10.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      },

      update: function update() {
        var _this2 = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var modelObject;
          var ObrObrazac05Model;

          (function () {
            outter = _this2;

            var i18n = _this2.get('i18n');

            modelObject = _this2.get('modelObject');
            ObrObrazac05Model = Models['default'].ObrObrazac10Lzo.create({ errorHandlerController: _this2 });

            ObrObrazac05Model.update(modelObject, _this2.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t("obrObrazac10.azuriranjeHeader").string,
                content: i18n.t("obrObrazac10.azuriranjeUspesno").string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      },

      lzoSelected: function lzoSelected(value, component) {
        this.lzoSelectedFunc(value, component);
      }
    },

    setVaziDo: Ember['default'].observer('modelObject.datumNabavke', function () {
      this.setVaziDoFunc();
    }),

    setVaziDoFunc: function setVaziDoFunc() {
      var interval = this.get('modelObject.rok');
      if (this.get('modelObject.datumNabavke') && interval && !isNaN(interval)) {
        this.set('modelObject.vaziDo', moment(this.get('modelObject.datumNabavke')).add(interval, 'months').toDate());
      }
    },

    lzoSelectedFunc: function lzoSelectedFunc(value, component) {
      var outter = this;
      var idLzoIzabrani = this.get('modelObject.sifLzo.idLzo');
      var selectItemsLzo = this.get('selectItemsLzo');
      selectItemsLzo.forEach(function (val, index) {
        if (val.idLzo === idLzoIzabrani) {
          outter.set('modelObject.rok', val.rok);
        }
      });

      this.setVaziDoFunc();
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      var modelObject = this.get('modelObject');

      if (this.get('isNew')) {
        modelObject.sifLzo = {};
      } else {
        this.lzoSelectedFunc(1, null);
      }

      Ember['default'].showModalPanel('#' + this.get('id'));
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('#' + this.get('id')).data('dialog').close();
    },

    tableColumnsPregledi: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'potpis',
        savedWidth: 220,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.potpis'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPregleda',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.datumPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledecegPregleda',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.datumSledecegPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.napomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});