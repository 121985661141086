define('zaris-frontend/components/select-tree', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    i18n: Ember['default'].inject.service('i18n'),

    renderButtonOdaberiSve: true,

    required: false,

    actions: {
      openModal: function openModal() {
        this.toggleProperty('strukturaModalPanelShow');
      },
      defaultId: function defaultId() {
        var i18n = this.get('i18n');
        var obj = {};
        obj.id = null;
        obj.naziv = i18n.t(this.get('label')).string;
        this.set('obj', obj);
      }
    }
  });

});