define('zaris-frontend/services/home', ['exports', 'zaris-frontend/models/models', 'zaris-frontend/config/environment'], function (exports, Models, ENV) {

  'use strict';

  exports['default'] = Ember.Service.extend({
    login: Ember.inject.service('login'),
    routing: Ember.inject.service('-routing'),

    menuItemObrasci: localStorage.menuItemObrasci !== undefined ? JSON.parse(localStorage.menuItemObrasci) : '',
    menuItemObrasciChanged: (function () {
      localStorage.menuItemObrasci = JSON.stringify(this.get('menuItemObrasci'));
    }).observes('menuItemObrasci'),

    menuItemSifarnici: localStorage.menuItemSifarnici !== undefined ? JSON.parse(localStorage.menuItemSifarnici) : '',
    menuItemSifarniciChanged: (function () {
      localStorage.menuItemSifarnici = JSON.stringify(this.get('menuItemSifarnici'));
    }).observes('menuItemSifarnici'),

    generateMenu: function generateMenu(accessToken) {
      var login = this.get('login');
      var routing = this.get('routing');

      if (accessToken === undefined || accessToken === null || accessToken === 'null') {
        accessToken = login.getAccessToken();
      }

      var menuItemsModel = Models['default'].MenuItem.create({ errorHandlerController: this });
      var result = menuItemsModel.findAllGetResult(accessToken);

      var sifarnici = [];
      var obrasci = {};
      var glavniMeni = {};
      var obrasciArray = [];

      //  menuItems.then(function(result) {

      for (var i = 0; i < result.length; ++i) {
        switch (result[i].code) {
          case 'g':
            var item = result[i];
            if (item.grupa == 'obr') {
              glavniMeni[item.sortOrder] = item;
            }
            break;
        }
      }

      for (var i = 0; i < result.length; ++i) {
        switch (result[i].code) {

          case 'obr':
            var item = result[i];
            var parentItem = glavniMeni[Math.floor(item.sortOrder)];
            var groupItem = obrasci[item.grupa];
            if (groupItem === undefined) {
              var array = {};
              for (var stavka in glavniMeni) {
                array[stavka] = { 'keyNaziv': null, 'uri': null };
              }
              array[parentItem.sortOrder] = { 'keyNaziv': parentItem.keyNaziv, 'uri': item.uri };
              obrasci[item.grupa] = { 'item': item, 'niz': array };
            } else {
              var array = groupItem['niz'];
              array[parentItem.sortOrder] = { 'keyNaziv': parentItem.keyNaziv, 'uri': item.uri };
              obrasci[item.grupa] = { 'item': item, 'niz': array };
            }
            break;
          case 'sif':
            sifarnici.addObject(result[i]);
            break;
        }
      }

      for (var key in obrasci) {
        obrasciArray.addObject(obrasci[key]);
      }

      this.set('menuItemSifarnici', sifarnici);
      this.set('menuItemObrasci', obrasciArray);
    }

  });

});