define('zaris-frontend/templates/sif-zaposleni-izvestaji', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 12
            },
            "end": {
              "line": 13,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/sif-zaposleni-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.menu.zaposleni"],[],["loc",[null,[11,14],[11,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 22
              },
              "end": {
                "line": 86,
                "column": 103
              }
            },
            "moduleName": "zaris-frontend/templates/sif-zaposleni-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac1.izvestaji.radnimMestima"],[],["loc",[null,[86,62],[86,102]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 22
              },
              "end": {
                "line": 87,
                "column": 91
              }
            },
            "moduleName": "zaris-frontend/templates/sif-zaposleni-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["izvestaji.imenuIPrezimenu"],[],["loc",[null,[87,57],[87,90]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 88,
                "column": 22
              },
              "end": {
                "line": 88,
                "column": 97
              }
            },
            "moduleName": "zaris-frontend/templates/sif-zaposleni-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["izvestaji.evidencionomBroju"],[],["loc",[null,[88,61],[88,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 22
            },
            "end": {
              "line": 90,
              "column": 22
            }
          },
          "moduleName": "zaris-frontend/templates/sif-zaposleni-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","radno_mesto_naziv"],0,null,["loc",[null,[86,22],[86,116]]]],
          ["block","x-option",[],["value","ime, prezime"],1,null,["loc",[null,[87,22],[87,104]]]],
          ["block","x-option",[],["value","evidencioni_broj"],2,null,["loc",[null,[88,22],[88,110]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 138,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sif-zaposleni-izvestaji.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel zarisIzvestaj07");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 90%; ");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row 1");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","flex-grid ");
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row flex-just-sa");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan12 obr-izv-cell");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row flex-just-sa cells2");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row flex-just-sa");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan12 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","column");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","column");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","input-control select full-size");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row last-row");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell obr-izv-cell-generate colspan12");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button success izv-generate-button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-checkmark");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element2, [3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element6, [3]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [1, 1]);
        var element12 = dom.childAt(element10, [3, 1]);
        var element13 = dom.childAt(element9, [3]);
        var element14 = dom.childAt(element2, [5, 1]);
        var element15 = dom.childAt(element2, [7, 1, 1]);
        var morphs = new Array(21);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
        morphs[3] = dom.createMorphAt(element3,3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[5] = dom.createMorphAt(element5,3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
        morphs[7] = dom.createMorphAt(element8,1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [5]),0,0);
        morphs[9] = dom.createMorphAt(element11,1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element11, [5]),0,0);
        morphs[11] = dom.createMorphAt(element12,1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element12, [5]),0,0);
        morphs[13] = dom.createMorphAt(element13,1,1);
        morphs[14] = dom.createMorphAt(element13,3,3);
        morphs[15] = dom.createMorphAt(dom.childAt(element14, [1, 1]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element14, [3, 1]),1,1);
        morphs[17] = dom.createElementMorph(element15);
        morphs[18] = dom.createMorphAt(element15,2,2);
        morphs[19] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_zaposlenima"],[],["loc",[null,[4,28],[4,69]]]],
        ["block","link-to",["sifZaposleni"],["tagName","button","class","image-button info"],0,null,["loc",[null,[10,12],[13,24]]]],
        ["inline","t",["obrazac6.izvestaji.headerZaposleni"],[],["loc",[null,[20,21],[20,63]]]],
        ["inline","select-zaposleni",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[21,45],[21,67]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[22,35],[22,52]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[23,24],[23,36]]]]],[],[]],"placeholderLabel","obrazac6.izvestaji.odaberiteZaposlenog"],["loc",[null,[21,18],[25,20]]]],
        ["inline","t",["obrazac6.izvestaji.headerStruktura"],[],["loc",[null,[32,22],[32,64]]]],
        ["inline","select-tree",[],["strukturaModalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[34,44],[34,67]]]]],[],[]],"label","obrazac6.izvestaji.sviNivoiStrukture","obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[36,24],[36,36]]]]],[],[]]],["loc",[null,[33,18],[37,22]]]],
        ["inline","t",["obrazac6.izvestaji.headerRadnaMesta"],[],["loc",[null,[43,24],[43,67]]]],
        ["inline","radio-button",[],["value","radnaMesta","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[45,64],[45,74]]]]],[],[]]],["loc",[null,[45,22],[45,76]]]],
        ["inline","t",["obrazac6.izvestaji.radnaMesta"],[],["loc",[null,[47,44],[47,81]]]],
        ["inline","radio-button",[],["value","radnoMesto","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[56,64],[56,74]]]]],[],[]]],["loc",[null,[56,22],[56,76]]]],
        ["inline","t",["obrazac6.izvestaji.radnoMesto"],[],["loc",[null,[58,44],[58,81]]]],
        ["inline","radio-button",[],["value","radnoMestoStruktura","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[63,73],[63,83]]]]],[],[]]],["loc",[null,[63,22],[63,85]]]],
        ["inline","t",["obrazac6.izvestaji.radnoMestoStruktura"],[],["loc",[null,[65,44],[65,90]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMesto","value",["subexpr","@mut",[["get","idRadnoMesto",["loc",[null,[70,85],[70,97]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[70,120],[70,130]]]],"radnoMesto"],[],["loc",[null,[70,112],[70,144]]]]],[],["loc",[null,[70,107],[70,145]]]]],["loc",[null,[70,20],[70,147]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMestoStruktura","value",["subexpr","@mut",[["get","idRadnoMestoStruktura",["loc",[null,[71,94],[71,115]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[71,138],[71,148]]]],"radnoMestoStruktura"],[],["loc",[null,[71,130],[71,171]]]]],[],["loc",[null,[71,125],[71,172]]]]],["loc",[null,[71,20],[71,174]]]],
        ["inline","t",["commonIzvestaji.sortirajPo"],[],["loc",[null,[81,24],[81,58]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","groupBy",["loc",[null,[85,40],[85,47]]]]],[],[]]],1,null,["loc",[null,[85,22],[90,35]]]],
        ["element","action",["generate"],[],["loc",[null,[97,24],[97,45]]]],
        ["inline","t",["app.common.generisi"],[],["loc",[null,[97,125],[97,152]]]],
        ["inline","select-tree-lov-modal",[],["id","strukturaTreeLovModal","rootView",true,"domainName","SysOrganizacionaJedinicaTree","idModalPanel","sifOrganizacionaJedinicaModal","target",["subexpr","@mut",[["get","this",["loc",[null,[111,11],[111,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[112,10],[112,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[113,16],[113,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[114,12],[114,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[115,19],[115,42]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[116,16],[116,27]]]]],[],[]],"obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[117,8],[117,20]]]]],[],[]],"displayName","app.menu.organizaciona_struktura"],["loc",[null,[106,0],[119,2]]]],
        ["inline","select-zaposleni-lov-modal",[],["id","sifZaposleniLovModal","target",["subexpr","@mut",[["get","this",["loc",[null,[124,11],[124,15]]]]],[],[]],"isNew",false,"domainName","SifZaposleni","domainId","idZaposleni","modelObject",["subexpr","@mut",[["get","modelObjectSifZaposleniLov",["loc",[null,[128,16],[128,42]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[129,8],[129,20]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[130,12],[130,34]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[131,19],[131,36]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[132,13],[132,36]]]]],[],[]],"referenceDomainName","sifZaposleni","referenceDomainId","idZaposleni","refreshBaseTable",["subexpr","@mut",[["get","refreshBaseTable",["loc",[null,[135,21],[135,37]]]]],[],[]],"displayName","obrazac6.izvestaji.izborZaposlenog"],["loc",[null,[122,0],[137,2]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});