define('zaris-frontend/components/zaris-datatable-client', ['exports', 'ember', 'ember-table/components/ember-table'], function (exports, Ember, EmberTable) {

    'use strict';

    exports['default'] = EmberTable['default'].extend({
        classNames: ['zaris-table-tables-container', 'zaris-datatable-client'],
        classNameBindings: ['enableContentSelection:zaris-table-content-selectable'],

        //array for generating pages component
        totalPages: Em.A(),
        //content
        content: Ember['default'].A(),

        //initial sort order - DO NOT CHANGE
        sortAscending: true,
        //selected sort column
        sortColumn: null,

        //id of currently selected row - ZERO if nothing selected
        idRowSelected: 0,
        //selected datatable element
        selectedRow: {},

        //toggles rerender
        refresh: false,

        //tells if any row is selected
        isRowSelected: Ember['default'].computed('idRowSelected', function () {
            return this.get('idRowSelected') !== 0;
        }),

        actions: {
            rowDidClick: function rowDidClick(row, event) {
                var domainId = this.get('domainId');
                this._super(row, event);
                this.set('idRowSelected', row.get('content')[domainId]);
                this.set('selectedRow', row.get('content'));
            },

            sortByColumn: function sortByColumn(column) {
                if (!column.get('supportSort')) {
                    return;
                }

                if (this.get('sortColumn') !== column) {
                    //console.log('New column');
                    this.get('columns').setEach('sorted', false);
                    column.set('sorted', true);
                    column.set('sortAscending', true);
                    this.set('sortAscending', true);
                    this.set('sortColumn', column);
                } else if (this.get('sortColumn') === column) {
                    // Handle disabling sorts
                    if (this.get('sortAscending') === false) {
                        //console.log('disabling sort');
                        this.set('sortColumn', undefined);
                        this.set('sortAscending', true);
                        this.get('columns').setEach('sorted', false);
                        column.set('sorted', false);
                    } else {
                        //console.log('change sort');
                        this.toggleProperty('sortAscending');
                        column.set('sortAscending', this.get('sortAscending'));
                    }
                }

                this.resetSelection();
            }
        },

        resetSelection: function resetSelection() {
            this.set('idRowSelected', 0);
            this.set('selectedRow', {});
        }
    });

});