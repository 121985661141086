define('zaris-frontend/services/roles', ['exports', 'zaris-frontend/models/models', 'zaris-frontend/config/environment'], function (exports, Models, ENV) {

    'use strict';

    exports['default'] = Ember.Service.extend({
        i18n: Ember.inject.service('i18n'),
        login: Ember.inject.service('login'),
        routing: Ember.inject.service('-routing'),

        userRoles: localStorage.userRoles,
        userRolesChanged: (function () {
            localStorage.userRoles = this.get('userRoles');
        }).observes('userRoles'),

        getRoles: function getRoles(accessToken) {
            var i18n = this.get('i18n');
            var login = this.get('login');
            var routing = this.get('routing');

            if (accessToken === undefined || accessToken === null || accessToken === 'null') {
                accessToken = login.getAccessToken();
            }

            var userModel = Models['default'].UserDetails.create({ errorHandlerController: this });
            var result = userModel.getResult('getRoleByKorisnik', accessToken, 'GET');

            var userRoles = '';
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    userRoles += result[key] + '|';
                }
            }

            this.set('userRoles', userRoles);
        },

        userHasRoleForRoute: function userHasRoleForRoute(routeName) {
            var userRoles = this.get('userRoles') !== undefined ? this.get('userRoles').split('|') : [];

            if (this.get('roleRouteMapping')[routeName] === undefined) return false;

            return this.containsAny(userRoles, this.get('roleRouteMapping')[routeName]);
        },

        userHasRole: function userHasRole(roleName) {
            var userRoles = this.get('userRoles').split('|');

            return this.containsAny([roleName], userRoles);
        },

        containsAny: function containsAny(source, target) {
            var result = source.filter(function (item) {
                return target.indexOf(item) > -1;
            });
            return result.length > 0;
        },

        roleRouteMapping: {
            'home': ['ROLE_SYS_HOME'],
            'login': ['ROLE_SYS_LOGIN'],
            'logout': ['ROLE_SYS_LOGOUT'],

            'sifKorisnickiDefinisaniPregledi': ['ROLE_SIF_KORISNICKI_DEFINISANI_PREGLEDI_READ', 'ROLE_SIF_KORISNICKI_DEFINISANI_PREGLEDI_WRITE'],
            'sifKorisnickiDefinisaneObuke': ['ROLE_SIF_KORISNICKI_DEFINISANE_OBUKE_READ', 'ROLE_SIF_KORISNICKI_DEFINISANE_OBUKE_WRITE'],
            'sifLicnaZastitnaOprema': ['ROLE_SIF_LICNA_ZASTITNA_OPREMA_READ', 'ROLE_SIF_LICNA_ZASTITNA_OPREMA_WRITE'],
            'sifVrsteVelicinaZaLzo': ['ROLE_SIF_VRSTE_VELICINA_ZA_LZO_READ', 'ROLE_SIF_VRSTE_VELICINA_ZA_LZO_WRITE'],
            'sifOpremaZaRad': ['ROLE_SIF_OPREMA_ZA_RAD_READ', 'ROLE_SIF_OPREMA_ZA_RAD_WRITE'],
            'sifParametriRadneOkoline': ['ROLE_SIF_PARAMETRI_RADNE_OKOLINE_READ', 'ROLE_SIF_PARAMETRI_RADNE_OKOLINE_WRITE'],
            'sifOpasneMaterije': ['ROLE_SIF_OPASNE_MATERIJE_READ', 'ROLE_SIF_OPASNE_MATERIJE_WRITE'],
            'sifNivoiRizika': ['ROLE_SIF_NIVOI_RIZIKA_READ', 'ROLE_SIF_NIVOI_RIZIKA_WRITE'],
            'sifOpasnostiStetnosti': ['ROLE_SIF_OPASNOSTI_I_STETNOSTI_READ', 'ROLE_SIF_OPASNOSTI_I_STETNOSTI_WRITE'],
            'sifZaposleni': ['ROLE_SIF_ZAPOSLENI_READ', 'ROLE_SIF_ZAPOSLENI_WRITE'],
            'sifLica': ['ROLE_SIF_LICA_READ', 'ROLE_SIF_LICA_WRITE'],
            'sifRadnoMestoStruktura': ['ROLE_SIF_RADNA_MESTA_U_STRUKTURI_READ', 'ROLE_SIF_RADNA_MESTA_U_STRUKTURI_WRITE'],
            'sifOrganizacionaStruktura': ['ROLE_SIF_ORGANIZACIONA_STRUKTURA_READ', 'ROLE_SIF_ORGANIZACIONA_STRUKTURA_WRITE'],
            'sifRadnaMesta': ['ROLE_SIF_RADNA_MESTA_READ', 'ROLE_SIF_RADNA_MESTA_WRITE'],
            'sifElementiPregleda': ['ROLE_SIF_ELEMENTI_PREGLEDA_READ', 'ROLE_SIF_ELEMENTI_PREGLEDA_WRITE'],

            'obrObrazac1Unos': ['ROLE_OBR_OBRAZAC_01_UNOS'],
            'obrObrazac1Sacuvani': ['ROLE_OBR_OBRAZAC_01_SACUVANI'],
            'obrObrazac1Izvestaji': ['ROLE_IZV_OBRAZAC_01_IZVESTAJI'],
            'obrObrazac1aUnos': ['ROLE_OBR_OBRAZAC_01a_UNOS'],
            'obrObrazac1aSacuvani': ['ROLE_OBR_OBRAZAC_01a_SACUVANI'],
            'obrObrazac1aIzvestaji': ['ROLE_IZV_OBRAZAC_01a_IZVESTAJI'],

            'obrObrazac02Unos': ['ROLE_OBR_OBRAZAC_02_UNOS'],
            'obrObrazac02Sacuvani': ['ROLE_OBR_OBRAZAC_02_SACUVANI'],
            'obrObrazac02Izvestaji': ['ROLE_IZV_OBRAZAC_02_IZVESTAJI'],
            'obrObrazac13Unos': ['ROLE_OBR_OBRAZAC_13_UNOS'],
            'obrObrazac13Sacuvani': ['ROLE_OBR_OBRAZAC_13_SACUVANI'],
            'obrObrazac13Izvestaji': ['ROLE_IZV_OBRAZAC_13_IZVESTAJI'],

            'obrObrazac03Unos': ['ROLE_OBR_OBRAZAC_03_UNOS'],
            'obrObrazac03Sacuvani': ['ROLE_OBR_OBRAZAC_03_SACUVANI'],
            'obrObrazac03Izvestaji': ['ROLE_IZV_OBRAZAC_03_IZVESTAJI'],

            'obrObrazac06Izvestaji': ['ROLE_OBR_OBRAZAC_06_UNOS'],
            'obrObrazac06aIzvestaji': ['ROLE_IZV_OBRAZAC_06a_IZVESTAJI'],

            'obrObrazac04Unos': ['ROLE_OBR_OBRAZAC_04_UNOS'],
            'obrObrazac04Sacuvani': ['ROLE_OBR_OBRAZAC_04_SACUVANI'],
            'obrObrazac04Izvestaji': ['ROLE_IZV_OBRAZAC_04_IZVESTAJI'],

            'obrObrazac05Unos': ['ROLE_OBR_OBRAZAC_05_UNOS'],
            'obrObrazac05Sacuvani': ['ROLE_OBR_OBRAZAC_05_SACUVANI'],
            'obrObrazac05Izvestaji': ['ROLE_IZV_OBRAZAC_05_IZVESTAJI'],

            'obrObrazac06Unos': ['ROLE_OBR_OBRAZAC_06_UNOS'],
            'obrObrazac06Sacuvani': ['ROLE_OBR_OBRAZAC_06_SACUVANI'],
            'obrObrazac06aUnos': ['ROLE_IZV_OBRAZAC_06_IZVESTAJI'],

            'obrObrazac07Unos': ['ROLE_OBR_OBRAZAC_07_UNOS'],
            'obrObrazac07Sacuvani': ['ROLE_OBR_OBRAZAC_07_SACUVANI'],
            'obrObrazac07Izvestaji': ['ROLE_IZV_OBRAZAC_07_IZVESTAJI'],

            'obrObrazac08Unos': ['ROLE_OBR_OBRAZAC_08_UNOS'],
            'obrObrazac08Sacuvani': ['ROLE_OBR_OBRAZAC_08_SACUVANI'],
            'obrObrazac08Izvestaji': ['ROLE_IZV_OBRAZAC_08_IZVESTAJI'],

            'obrObrazac09Unos': ['ROLE_OBR_OBRAZAC_09_UNOS'],
            'obrObrazac09Sacuvani': ['ROLE_OBR_OBRAZAC_09_SACUVANI'],
            'obrObrazac09Izvestaji': ['ROLE_IZV_OBRAZAC_09_IZVESTAJI'],

            'obrObrazac10Unos': ['ROLE_OBR_OBRAZAC_10_UNOS'],
            'sifLzoNabavka': ['ROLE_OBR_OBRAZAC_10_UNOS'],
            'obrObrazac10Sacuvani': ['ROLE_OBR_OBRAZAC_10_SACUVANI'],
            'obrObrazac10Izvestaji': ['ROLE_IZV_OBRAZAC_10_IZVESTAJI'],

            'obrObrazac11Unos': ['ROLE_OBR_OBRAZAC_11_UNOS'],
            'obrObrazac11Sacuvani': ['ROLE_OBR_OBRAZAC_11_SACUVANI'],
            'obrObrazac11Izvestaji': ['ROLE_IZV_OBRAZAC_11_IZVESTAJI'],

            'obrObrazac12Unos': ['ROLE_OBR_OBRAZAC_12_UNOS'],
            'obrObrazac12Sacuvani': ['ROLE_OBR_OBRAZAC_12_SACUVANI'],
            'obrObrazac12Izvestaji': ['ROLE_IZV_OBRAZAC_12_IZVESTAJI'],

            'obrObrazac13Unos': ['ROLE_OBR_OBRAZAC_13_UNOS'],
            'obrObrazac13Sacuvani': ['ROLE_OBR_OBRAZAC_13_SACUVANI'],
            'obrObrazac13Izvestaji': ['ROLE_IZV_OBRAZAC_13_IZVESTAJI'],

            'obrObrazac14Unos': ['ROLE_OBR_OBRAZAC_14_UNOS'],
            'obrObrazac14Sacuvani': ['ROLE_OBR_OBRAZAC_14_SACUVANI'],
            'obrObrazac14Izvestaji': ['ROLE_IZV_OBRAZAC_14_IZVESTAJI'],

            'sifLzoIzvestaji': ['ROLE_IZV_SIF_LZO_IZVESTAJI'],

            'sifRevers': ['ROLE_OBR_OBRAZAC_REVERSI_UNOS'],

            'sifOrganizacionaStrukturaIzvestaji': ['ROLE_IZV_SIFARNICI_ORG_STRUKTURA'],
            'sifZaposleniIzvestaji': ['ROLE_IZV_SIFARNICI_ZAPOSLENI'],
            'sifLicaIzvestaji': ['ROLE_IZV_SIFARNICI_LICA'],
            'sifRadnaMestaIzvestaji': ['ROLE_IZV_SIFARNICI_RADNA_MESTA'],
            'sifRadnaMestaStrukturaIzvestaji': ['ROLE_IZV_SIFARNICI_RADNA_MESTA_STRUKTURA'],

            'sysPravnaLica': ['ROLE_SYS_PRAVNA_LICA'],

            'sysKorisnici': ['ROLE_SYS_KORISNICI'],

            'sysPromenaPassworda': ['ROLE_USER'],

            'semPodesavanjeObavestenja': ['ROLE_SEM_OBAVESTENJA'],

            'sysPromenaJezika': ['ROLE_USER'],

            'bzOpasneMaterije': ['ROLE_BAZA_ZNANJA'],
            'bzZakoni': ['ROLE_BAZA_ZNANJA'],

            'vertikalniIzvestaji': ['ROLE_IZV_VERTIKALNI_IZVESTAJI'],

            'sysOdabirPotpisnika': ['ROLE_SYS_IZBOR_POTPISNIKA']

        }

    });

});