define('zaris-frontend/templates/sif-zaposleni', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sif-zaposleni.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.zaposleni"],[],["loc",[null,[5,28],[5,54]]]],
        ["inline","component",["zaris-datatable"],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","SifZaposleni","domainId","idZaposleni","titleMsgKey","sifZaposleni.brisanjeHeader","descYesNoMsgKey","sifZaposleni.brisanjePitanje","descDeleteSuccessMsgKey","sifZaposleni.brisanjeUspesno","titleDeleteMsgKey","sifZaposleni.brisanjeHeader","descDeleteQuestionMsgKey","sifZaposleni.brisanjePitanje","descDeleteSuccessMsgKey","sifZaposleni.brisanjeUspesno","idModalPanelEdit","sifZaposleniEditModal","idModalPanelNew","sifZaposleniNewModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[25,16],[25,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[26,22],[26,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[27,18],[27,25]]]]],[],[]],"content",["subexpr","@mut",[["get","zaposleniContent",["loc",[null,[28,18],[28,34]]]]],[],[]],"modalPanelShowNew",["subexpr","@mut",[["get","showZaposleniNewModalPanel",["loc",[null,[29,28],[29,54]]]]],[],[]],"modalPanelShowEdit",["subexpr","@mut",[["get","showZaposleniEditModalPanel",["loc",[null,[30,29],[30,56]]]]],[],[]],"datatableWriteRole","ROLE_SIF_ZAPOSLENI_WRITE"],["loc",[null,[9,8],[32,10]]]],
        ["inline","sif-zaposleni-edit-modal",[],["id","sifZaposleniEditModal","target",["subexpr","@mut",[["get","this",["loc",[null,[39,11],[39,15]]]]],[],[]],"isNew",false,"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[41,16],[41,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[42,12],[42,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showZaposleniEditModalPanel",["loc",[null,[43,19],[43,46]]]]],[],[]]],["loc",[null,[37,0],[44,2]]]],
        ["inline","sif-zaposleni-new-modal",[],["id","sifZaposleniNewModal","isNew",true,"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[49,12],[49,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showZaposleniNewModalPanel",["loc",[null,[50,19],[50,45]]]]],[],[]]],["loc",[null,[46,0],[51,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});