define('zaris-frontend/templates/obr-obrazac06-unos', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 111,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac06-unos.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","tabcontrol zarisPanel");
        dom.setAttribute(el1,"data-role","tabcontrol");
        dom.setAttribute(el1,"data-on-tab-change","tab_change");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"class","tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","active");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#frame_1");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","heading");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","icon mif-cabinet");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"href","#frame_2");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","heading");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","icon mif-cabinet");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","frames");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","frame");
        dom.setAttribute(el3,"id","frame_1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el5,"class","content panelCentered");
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","frame");
        dom.setAttribute(el3,"id","frame_2");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el5,"class","content panelCentered");
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element1, [3, 1]);
        var element4 = dom.childAt(element0, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 3]),0,0);
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [1, 3]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [1, 1, 1]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [3, 1, 1]),1,1);
        morphs[6] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[7] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["openTab1"],[],["loc",[null,[6,13],[6,34]]]],
        ["inline","t",["app.menu.evidencija_o_zaposlenima_osposobljenim_za_bezbedan_i_zdrav_rad"],[],["loc",[null,[9,36],[9,115]]]],
        ["element","action",["openTab2"],[],["loc",[null,[14,13],[14,34]]]],
        ["inline","t",["app.menu.dodeljivanje_obuke_za_bezbedan_i_zdrav_rad"],[],["loc",[null,[17,36],[17,95]]]],
        ["inline","component",[["get","componentTab1",["loc",[null,[28,26],[28,39]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[29,23],[29,27]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[30,24],[30,36]]]]],[],[]],"domainName","VobrObrazac06","domainId","idZaposleni","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[33,22],[33,27]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[34,28],[34,39]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[35,28],[35,39]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[36,24],[36,31]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"disabledCreate",true,"disabledDelete",true,"renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac06Sacuvani","renderIzvestaji",true,"routeIzvestaji","obrObrazac06Izvestaji","modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[46,37],[46,51]]]]],[],[]],"customId",["subexpr","@mut",[["get","idPregledFilter",["loc",[null,[47,27],[47,42]]]]],[],[]],"renderCreate",false,"renderDelete",false],["loc",[null,[28,14],[50,16]]]],
        ["inline","component",[["get","componentTab2",["loc",[null,[60,26],[60,39]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[61,23],[61,27]]]]],[],[]],"content",["subexpr","@mut",[["get","contentZakazana",["loc",[null,[62,26],[62,41]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumnsZakazana",["loc",[null,[63,24],[63,44]]]]],[],[]],"domainName","ObrObrazac06ObukaZakazana","domainId","idObukaZakazana","isNew",["subexpr","@mut",[["get","isNewZakazana",["loc",[null,[66,22],[66,35]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObjectZakazana",["loc",[null,[67,28],[67,47]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRowZakazana",["loc",[null,[68,28],[68,47]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshZakazana",["loc",[null,[69,24],[69,39]]]]],[],[]],"titleMsgKey","obrObrazac06.brisanjeHeader","descYesNoMsgKey","obrObrazac06.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac06.brisanjeUspesno","titleDeleteMsgKey","obrObrazac06.brisanjeHeader","descDeleteQuestionMsgKey","obrObrazac06.brisanjePitanje","descDeleteSuccessMsgKey","obrObrazac06.brisanjeUspesno","hasHeader",true,"hasFooter",false,"perPage",15,"idModalPanel","obrObrazac06ObukaZakazanaModal","modalPanelShow",["subexpr","@mut",[["get","showModalPanelZakazana",["loc",[null,[80,33],[80,55]]]]],[],[]],"toolbarComponent","obr-obrazac06-toolbar"],["loc",[null,[60,14],[82,16]]]],
        ["inline","obr-obrazac06-modal",[],["id","obrObrazac13Modal","target",["subexpr","@mut",[["get","this",["loc",[null,[92,11],[92,15]]]]],[],[]],"isNew",false,"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[94,16],[94,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[95,12],[95,19]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[96,28],[96,51]]]]],[],[]],"modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[97,25],[97,39]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[98,15],[98,38]]]]],[],[]],"selektovaniZaposleni",["subexpr","@mut",[["get","selectedRow",["loc",[null,[99,27],[99,38]]]]],[],[]]],["loc",[null,[90,0],[100,2]]]],
        ["inline","obr-obrazac06-obuka-zakazana-modal",[],["id","sifZdravstveniPregledZakazanModal","target",["subexpr","@mut",[["get","this",["loc",[null,[104,11],[104,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNewZakazana",["loc",[null,[105,10],[105,23]]]]],[],[]],"selectedZakazanaObuka",["subexpr","@mut",[["get","selectedRowZakazana",["loc",[null,[106,26],[106,45]]]]],[],[]],"refreshZakazana",["subexpr","@mut",[["get","refreshZakazana",["loc",[null,[107,20],[107,35]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showModalPanelZakazana",["loc",[null,[108,21],[108,43]]]]],[],[]],"selectItems",["subexpr","@mut",[["get","model.selectItems",["loc",[null,[109,18],[109,35]]]]],[],[]]],["loc",[null,[102,0],[110,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});