define('zaris-frontend/components/obr-obrazac06-obuka-zakazana-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'obrObrazac06ObukaZakazanaModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "95%",
    "data-width": "95%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",
    "data-type": "modal",

    razlogOsposobljavanjaContent: Em.A(),
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    didInsertElement: function didInsertElement() {
      this._super();
      this.$('.obrObrazac06ObukaZakazanaModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
      Ember['default'].run.scheduleOnce('afterRender', this, this.initDatableItems);
    },

    initDatableItems: function initDatableItems() {},

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      create: function create() {
        var i18n = this.get('i18n');

        if (this.get('validate').validate(this)) {
          var outter = this;
          var modelObject = this.get('modelObject');

          modelObject.sifZaposleni = { idZaposleni: this.get('selektovaniZaposleni.idZaposleni') };

          modelObject.obrObrazac06ElementZakazaneObukes = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = this.get('selectedRazloziOsposobljavanja')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var item = _step.value;

              modelObject.obrObrazac06ElementZakazaneObukes.push({ 'sifRazlogOsposobljavanja': { 'idRazlogOsposobljavanja': item } });
            }

            //provera da li je odabran bar jedan element pregleda
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator['return']) {
                _iterator['return']();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          if (modelObject.obrObrazac06ElementZakazaneObukes.length === 0) {
            outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac06.dodavanjeHeader', 'obrObrazac06.morateOdabratiRazlogOsposobljavanja', 'warning');
            return;
          }

          var obrObrazac06ObukaZakazanaModel = Models['default'].ObrObrazac06ObukaZakazana.create({ errorHandlerController: this });
          obrObrazac06ObukaZakazanaModel.create(modelObject, this.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('obrObrazac06.dodavanjeHeader').string,
              content: i18n.t('obrObrazac06.dodavanjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
            outter.closePanel();
            outter.toggleProperty('refreshObrazac06ObukaZakazanaModal');
          });
        }
      },

      update: function update() {
        var i18n = this.get('i18n');

        if (this.get('validate').validate(this)) {
          var outter = this;
          var modelObject = this.get('modelObject');

          modelObject.obrObrazac06ElementZakazaneObukes = [];
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = this.get('selectedRazloziOsposobljavanja')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var item = _step2.value;

              modelObject.obrObrazac06ElementZakazaneObukes.push({ 'sifRazlogOsposobljavanja': { 'idRazlogOsposobljavanja': item } });
            }

            //provera da li je odabran bar jedan element pregleda
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                _iterator2['return']();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          if (modelObject.obrObrazac06ElementZakazaneObukes.length === 0) {
            outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac06.dodavanjeHeader', 'obrObrazac06.morateOdabratiRazlogOsposobljavanja', 'warning');
            return;
          }

          var obrObrazac06ObukaZakazanaModel = Models['default'].ObrObrazac06ObukaZakazana.create({ errorHandlerController: this });
          obrObrazac06ObukaZakazanaModel.update(modelObject, this.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('obrObrazac06.azuriranjeHeader').string,
              content: i18n.t('obrObrazac06.azuriranjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
            outter.closePanel();
            outter.toggleProperty('refreshObrazac06ObukaZakazanaModal');
          });
        }
      }

    },

    initPanel: function initPanel() {
      var outter = this;
      this.set('selectedRazloziOsposobljavanja', new Set());

      if (this.get('isNew')) {
        this.set('modelObject', {});
      } else {
        var selectedZakazanaObuka = this.get('selectedZakazanaObuka');
        this.set('modelObject', selectedZakazanaObuka);

        var obrObrazac06ElementZakazaneObukeModel = Models['default']['ObrObrazac06ElementZakazaneObuke'].create({ errorHandlerController: this });
        obrObrazac06ElementZakazaneObukeModel.getByIdCore('dohvatiElementeObuke', selectedZakazanaObuka.idObukaZakazana, this.get('login').getAccessToken(), 'GET', false, function (elementiObuke) {
          elementiObuke.forEach(function (val, index) {
            outter.get('selectedRazloziOsposobljavanja').add(val.sifRazlogOsposobljavanja.idRazlogOsposobljavanja);
          });
        });
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.get('validate').hideValidations(this);

      this.initPanel();

      this.toggleProperty('refreshRazloziOsposobljavanja');

      var outter = this;
      setTimeout(function () {
        outter.set('componentName', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac06ObukaZakazanaModal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshObrazac06ObukaZakazanaModal', function () {
      this.toggleProperty('refreshZakazana');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      this.set('componentName', null);
      $('.obrObrazac06ObukaZakazanaModal').data('dialog').close();
    },

    selectedRazloziOsposobljavanja: new Set(),

    tableRazloziOsposobljavanjaColumns: Ember['default'].computed('isNew', function () {
      var outter = this;
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'idRazlogOsposobljavanja',
        savedWidth: 75,
        supportSort: false,
        tableCellView: 'zaris-datatable-checkbox-cell',
        getCellContent: function getCellContent(row) {
          return outter.get('selectedRazloziOsposobljavanja').has(row.get(this.key));
        },
        setCellContent: function setCellContent(row, value) {
          if (outter.get('selectedRazloziOsposobljavanja').has(row.get(this.key))) {
            outter.get('selectedRazloziOsposobljavanja')['delete'](row.get(this.key));
          } else {
            outter.get('selectedRazloziOsposobljavanja').add(row.get(this.key));
          }
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'sifra',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.sifra'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'naziv',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.razlogOsposobljavanja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});