define('zaris-frontend/controllers/sif-organizaciona-struktura-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;
    },

    actions: {
      generate: function generate() {

        var pObj = {};
        var outter = this;
        var loginService = this.get('loginService');

        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_organizaciona_struktura';
        izvestajiDTO.reportParameters = pObj;

        this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});