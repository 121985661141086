define('zaris-frontend/templates/components/header-table-container', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "zaris-frontend/templates/components/header-table-container.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","header-block",[],["columns",["subexpr","@mut",[["get","fixedColumns",["loc",[null,[4,14],[4,26]]]]],[],[]],"blockWidth",["subexpr","@mut",[["get","fixedBlockWidth",["loc",[null,[5,17],[5,32]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","rowWidth",["loc",[null,[6,15],[6,23]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","headerHeight",["loc",[null,[7,16],[7,28]]]]],[],[]],"headerHeight",["subexpr","@mut",[["get","headerHeight",["loc",[null,[8,19],[8,31]]]]],[],[]],"enableColumnReorder",["subexpr","@mut",[["get","enableColumnReorder",["loc",[null,[9,26],[9,45]]]]],[],[]],"isShowingSortableIndicator",["subexpr","@mut",[["get","isShowingSortableIndicator",["loc",[null,[10,33],[10,59]]]]],[],[]],"sortableIndicatorLeft",["subexpr","@mut",[["get","sortableIndicatorLeft",["loc",[null,[11,28],[11,49]]]]],[],[]],"columnMode",["subexpr","@mut",[["get","columnMode",["loc",[null,[12,17],[12,27]]]]],[],[]],"columnsFillTable",["subexpr","@mut",[["get","columnsFillTable",["loc",[null,[13,23],[13,39]]]]],[],[]],"toggleTableCollapse","toggleTableCollapse","columnDidSort","columnDidSort","class","ember-table-left-table-block"],["loc",[null,[3,4],[17,6]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/header-table-container.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ember-table-table-fixed-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0,1,1);
        morphs[1] = dom.createMorphAt(element0,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","numFixedColumns",["loc",[null,[2,8],[2,23]]]]],[],0,null,["loc",[null,[2,2],[18,9]]]],
        ["inline","header-block",[],["columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[20,12],[20,24]]]]],[],[]],"scrollLeft",["subexpr","@mut",[["get","scrollLeft",["loc",[null,[21,15],[21,25]]]]],[],[]],"blockWidth",["subexpr","@mut",[["get","tableBlockWidth",["loc",[null,[22,15],[22,30]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","rowWidth",["loc",[null,[23,13],[23,21]]]]],[],[]],"rowHeight",["subexpr","@mut",[["get","headerHeight",["loc",[null,[24,14],[24,26]]]]],[],[]],"headerHeight",["subexpr","@mut",[["get","headerHeight",["loc",[null,[25,17],[25,29]]]]],[],[]],"enableColumnReorder",["subexpr","@mut",[["get","enableColumnReorder",["loc",[null,[26,24],[26,43]]]]],[],[]],"isShowingSortableIndicator",["subexpr","@mut",[["get","isShowingSortableIndicator",["loc",[null,[27,31],[27,57]]]]],[],[]],"sortableIndicatorLeft",["subexpr","@mut",[["get","sortableIndicatorLeft",["loc",[null,[28,26],[28,47]]]]],[],[]],"columnMode",["subexpr","@mut",[["get","columnMode",["loc",[null,[29,15],[29,25]]]]],[],[]],"columnDidSort","columnDidSort","class","ember-table-right-table-block"],["loc",[null,[19,2],[32,4]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});