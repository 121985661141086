define('zaris-frontend/models/models', ['exports', 'zaris-frontend/models/base'], function (exports, BaseModel) {

  'use strict';

  var Models = {};

  /************************************************/

  var User = BaseModel['default'].extend({
    modelName: 'User',
    pathFindAll: 'user/e',
    verbFindAll: 'GET',
    pathFind: 'user/e',
    verbFind: 'GET'
  });

  Models['User'] = User;

  /************************************************/

  var UserDetails = BaseModel['default'].extend({
    modelName: 'UserDetails',
    basePath: 'korisnik'
  });

  Models['UserDetails'] = UserDetails;

  /************************************************/

  var MenuItem = BaseModel['default'].extend({
    modelName: 'MenuItem',
    pathFindAll: 'menuItem/e',
    verbFindAll: 'GET',
    pathFind: 'menuItem/e',
    verbFind: 'GET'
  });

  Models['MenuItem'] = MenuItem;

  /************************************************/
  var SifObuka = BaseModel['default'].extend({
    modelName: 'SifObuka',
    pathFindAll: 'sifObuka/e',
    verbFindAll: 'GET',
    pathFind: 'sifObuka/e',
    verbFind: 'GET',
    pathFindPaged: 'sifObuka/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifObuka/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifObuka/r',
    verbCreate: 'POST',
    pathUpdate: 'sifObuka/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifObuka/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifObuka'] = SifObuka;
  /************************************************/

  var SifNivoRizika = BaseModel['default'].extend({
    modelName: 'SifNivoRizika',
    pathFindAll: 'sifNivoRizika/e',
    verbFindAll: 'GET',
    pathFind: 'sifNivoRizika/e',
    verbFind: 'GET',
    pathFindPaged: 'sifNivoRizika/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifNivoRizika/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifNivoRizika/r',
    verbCreate: 'POST',
    pathUpdate: 'sifNivoRizika/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifNivoRizika/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifNivoRizika'] = SifNivoRizika;
  /************************************************/
  var SifZdravstveniPregled = BaseModel['default'].extend({
    modelName: 'SifZdravstveniPregled',
    pathFindAll: 'sifZdravstveniPregled/e',
    verbFindAll: 'GET',
    pathFind: 'sifZdravstveniPregled/e',
    verbFind: 'GET',
    pathFindPaged: 'sifZdravstveniPregled/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifZdravstveniPregled/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifZdravstveniPregled/r',
    verbCreate: 'POST',
    pathUpdate: 'sifZdravstveniPregled/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifZdravstveniPregled/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifZdravstveniPregled'] = SifZdravstveniPregled;
  /************************************************/
  var SifParametarOkoline = BaseModel['default'].extend({
    modelName: 'SifParametarOkoline',
    pathFindAll: 'sifParametarOkoline/e',
    verbFindAll: 'GET',
    pathFind: 'sifParametarOkoline/e',
    verbFind: 'GET',
    pathFindPaged: 'sifParametarOkoline/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifParametarOkoline/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifParametarOkoline/r',
    verbCreate: 'POST',
    pathUpdate: 'sifParametarOkoline/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifParametarOkoline/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifParametarOkoline'] = SifParametarOkoline;
  /************************************************/
  var SifOpasnaMaterija = BaseModel['default'].extend({
    modelName: 'SifOpasnaMaterija',
    pathFindAll: 'sifOpasnaMaterija/e',
    verbFindAll: 'GET',
    pathFind: 'sifOpasnaMaterija/e',
    verbFind: 'GET',
    pathFindPaged: 'sifOpasnaMaterija/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifOpasnaMaterija/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifOpasnaMaterija/r',
    verbCreate: 'POST',
    pathUpdate: 'sifOpasnaMaterija/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifOpasnaMaterija/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifOpasnaMaterija'] = SifOpasnaMaterija;

  /************************************************/
  var BzOpasnaMaterija = BaseModel['default'].extend({
    modelName: 'BzOpasnaMaterija',
    pathFindAll: 'bzOpasnaMaterija/e',
    verbFindAll: 'GET',
    pathFind: 'bzOpasnaMaterija/e',
    verbFind: 'GET',
    pathFindPaged: 'bzOpasnaMaterija/ep',
    verbFindPaged: 'GET'

  });

  Models['BzOpasnaMaterija'] = BzOpasnaMaterija;

  /************************************************/

  var XsifKategorijaParametraOkoline = BaseModel['default'].extend({
    modelName: 'XsifKategorijaParametraOkoline',
    pathFindAll: 'xsifKategorijaParametraOkoline/e',
    verbFindAll: 'GET',
    pathFind: 'xsifKategorijaParametraOkoline/e',
    verbFind: 'GET',
    pathFindPaged: 'xsifKategorijaParametraOkoline/ep',
    verbFindPaged: 'GET',
    pathDelete: 'xsifKategorijaParametraOkoline/r',
    verbDelete: 'DELETE',
    pathCreate: 'xsifKategorijaParametraOkoline/r',
    verbCreate: 'POST',
    pathUpdate: 'xsifKategorijaParametraOkoline/r',
    verbUpdate: 'PATCH'
  });

  Models['XsifKategorijaParametraOkoline'] = XsifKategorijaParametraOkoline;
  /************************************************/
  var SysOrganizacionaJedinicaTree = BaseModel['default'].extend({
    modelName: 'SysOrganizacionaJedinicaTree',
    pathFind: 'sysOrganizacionaJedinicaTree/r',
    verbFind: 'GET',
    pathDelete: 'sysOrganizacionaJedinicaTree/r',
    verbDelete: 'DELETE',
    pathCreate: 'sysOrganizacionaJedinicaTree/r',
    verbCreate: 'POST',
    pathUpdate: 'sysOrganizacionaJedinicaTree/r',
    verbUpdate: 'PATCH',
    pathFindPaged: 'sysOrganizacionaJedinicaTree/r',
    verbFindPaged: 'GET'
  });

  Models['SysOrganizacionaJedinicaTree'] = SysOrganizacionaJedinicaTree;
  /************************************************/
  var SifElementPregleda = BaseModel['default'].extend({
    modelName: 'SifElementPregleda',
    pathFindAll: 'sifElementPregleda/e',
    verbFindAll: 'GET',
    pathFind: 'sifElementPregleda/e',
    verbFind: 'GET',
    pathFindPaged: 'sifElementPregleda/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifElementPregleda/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifElementPregleda/r',
    verbCreate: 'POST',
    pathUpdate: 'sifElementPregleda/r',
    verbUpdate: 'PATCH'
  });

  Models['SifElementPregleda'] = SifElementPregleda;
  /************************************************/
  var SifRadnoMesto = BaseModel['default'].extend({
    modelName: 'SifRadnoMesto',
    pathFindAll: 'sifRadnoMesto/e',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMesto/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMesto/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMesto/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMesto/r',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMesto/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifRadnoMesto/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifRadnoMesto'] = SifRadnoMesto;

  /************************************************/

  var SifRadnoMestoStruktura = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoStruktura',
    pathFindAll: 'sifRadnoMestoStruktura/r',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMestoStruktura/r',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMestoStruktura/rp',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoStruktura/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoStruktura/r',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoStruktura/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifRadnoMestoStruktura/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifRadnoMestoStruktura'] = SifRadnoMestoStruktura;

  /************************************************/

  var SifLica = BaseModel['default'].extend({
    modelName: 'SifLica',
    pathFindAll: 'sifLica/e',
    verbFindAll: 'GET',
    pathFind: 'sifLica/e',
    verbFind: 'GET',
    pathFindPaged: 'sifLica/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifLica/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifLica/e',
    verbCreate: 'POST',
    pathUpdate: 'sifLica/e',
    verbUpdate: 'PATCH'
  });

  Models['SifLica'] = SifLica;

  /************************************************/

  var SifZaposleni = BaseModel['default'].extend({
    modelName: 'SifZaposleni',
    pathFindAll: 'sifZaposleni/r',
    verbFindAll: 'GET',
    pathFind: 'sifZaposleni/r',
    verbFind: 'GET',
    pathFindPaged: 'sifZaposleni/rp',
    verbFindPaged: 'GET',
    pathDelete: 'sifZaposleni/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifZaposleni/r',
    verbCreate: 'POST',
    pathUpdate: 'sifZaposleni/r',
    verbUpdate: 'PATCH'
  });

  Models['SifZaposleni'] = SifZaposleni;

  /************************************************/

  var SifOpremaZaRad = BaseModel['default'].extend({
    modelName: 'SifOpremaZaRad',
    pathFindAll: 'sifOpremaZaRad/e',
    verbFindAll: 'GET',
    pathFind: 'sifOpremaZaRad/e',
    verbFind: 'GET',
    pathFindPaged: 'sifOpremaZaRad/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifOpremaZaRad/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifOpremaZaRad/e',
    verbCreate: 'POST',
    pathUpdate: 'sifOpremaZaRad/e',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifOpremaZaRad/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifOpremaZaRad'] = SifOpremaZaRad;

  /************************************************/

  var SifOpasnostiStetnostiTree = BaseModel['default'].extend({
    modelName: 'SifOpasnostiStetnostiTree',
    pathFind: 'sifOpasnostiStetnostiTree/r',
    verbFind: 'GET',
    pathDelete: 'sifOpasnostiStetnostiTree/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifOpasnostiStetnostiTree/r',
    verbCreate: 'POST',
    pathUpdate: 'sifOpasnostiStetnostiTree/r',
    verbUpdate: 'PATCH',
    pathFindPaged: 'sifOpasnostiStetnostiTree/r',
    verbFindPaged: 'GET'
  });

  Models['SifOpasnostiStetnostiTree'] = SifOpasnostiStetnostiTree;

  /************************************************/

  var SifRadnoMestoStrElPregleda = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoStrElPregleda',
    pathFindAll: 'sifRadnoMestoStrElPregleda/e',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMestoStrElPregleda/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMestoStrElPregleda/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoStrElPregleda/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoStrElPregleda/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoStrElPregleda/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoStrElPregleda'] = SifRadnoMestoStrElPregleda;

  /*****************************************************************/

  var SifLzoVrstaVelicineTree = BaseModel['default'].extend({
    modelName: 'SifLzoVrstaVelicineTree',
    pathFind: 'sifLzoVrstaVelicineTree/r',
    verbFind: 'GET',
    pathFindAll: 'sifLzoVrstaVelicineTree/e',
    verbFindAll: 'GET',
    pathDelete: 'sifLzoVrstaVelicineTree/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifLzoVrstaVelicineTree/r',
    verbCreate: 'POST',
    pathUpdate: 'sifLzoVrstaVelicineTree/r',
    verbUpdate: 'PATCH',
    pathFindPaged: 'sifLzoVrstaVelicineTree/r',
    verbFindPaged: 'GET',
    pathSelectItems: 'sifLzoVrstaVelicineTree/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifLzoVrstaVelicineTree'] = SifLzoVrstaVelicineTree;

  /*****************************************************************/

  var SifLzoTree = BaseModel['default'].extend({
    modelName: 'SifLzoTree',
    pathFind: 'sifLzoTree/r',
    verbFind: 'GET',
    pathDelete: 'sifLzoTree/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifLzoTree/r',
    verbCreate: 'POST',
    pathUpdate: 'sifLzoTree/r',
    verbUpdate: 'PATCH',
    pathFindPaged: 'sifLzoTree/r',
    verbFindPaged: 'GET',
    pathSelectItems: 'sifLzoTree/selectItems'
  });

  Models['SifLzoTree'] = SifLzoTree;

  /*****************************************************************/

  var VsifRadnoMestoLzo = BaseModel['default'].extend({
    modelName: 'VsifRadnoMestoLzo',
    pathFindAll: 'vsifRadnoMestoLzo/e',
    verbFindAll: 'GET',
    pathFind: 'vsifRadnoMestoLzo/e',
    verbFind: 'GET',
    pathFindPaged: 'vsifRadnoMestoLzo/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vsifRadnoMestoLzo/e',
    verbDelete: 'DELETE',
    pathCreate: 'vsifRadnoMestoLzo/e',
    verbCreate: 'POST',
    pathUpdate: 'vsifRadnoMestoLzo/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vsifRadnoMestoLzo/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VsifRadnoMestoLzo'] = VsifRadnoMestoLzo;

  /*****************************************************************/

  var VsifRadnoMestoStrukturaLzo = BaseModel['default'].extend({
    modelName: 'VsifRadnoMestoStrukturaLzo',
    pathFindAll: 'vsifRadnoMestoStrukturaLzo/e',
    verbFindAll: 'GET',
    pathFind: 'vsifRadnoMestoStrukturaLzo/e',
    verbFind: 'GET',
    pathFindPaged: 'vsifRadnoMestoStrukturaLzo/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vsifRadnoMestoStrukturaLzo/e',
    verbDelete: 'DELETE',
    pathCreate: 'vsifRadnoMestoStrukturaLzo/e',
    verbCreate: 'POST',
    pathUpdate: 'vsifRadnoMestoStrukturaLzo/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vsifRadnoMestoStrukturaLzo/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VsifRadnoMestoStrukturaLzo'] = VsifRadnoMestoStrukturaLzo;

  /************************************************/

  var SifRadnoMestoStrObuke = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoStrObuke',
    pathFindAll: 'sifRadnoMestoStrObuke/e',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMestoStrObuke/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMestoStrObuke/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoStrObuke/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoStrObuke/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoStrObuke/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoStrObuke'] = SifRadnoMestoStrObuke;

  /*****************************************************************/

  var SifRadnoMestoStrPregledi = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoStrPregledi',
    pathFindAll: 'sifRadnoMestoStrPregledi/e',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMestoStrPregledi/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMestoStrPregledi/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoStrPregledi/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoStrPregledi/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoStrPregledi/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoStrPregledi'] = SifRadnoMestoStrPregledi;

  /*****************************************************************/

  var SifRadnoMestoElPregleda = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoElPregleda',
    pathFindAll: 'sifRadnoMestoElPregleda/e',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMestoElPregleda/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMestoElPregleda/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoElPregleda/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoElPregleda/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoElPregleda/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoElPregleda'] = SifRadnoMestoElPregleda;

  /*****************************************************************/

  var SifRadnoMestoObuke = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoObuke',
    pathFindAll: 'sifRadnoMestoObuke/e',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMestoObuke/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMestoObuke/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoObuke/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoObuke/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoObuke/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoObuke'] = SifRadnoMestoObuke;

  /*****************************************************************/

  var SifRadnoMestoPregledi = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoPregledi',
    pathFindAll: 'sifRadnoMestoPregledi/e',
    verbFindAll: 'GET',
    pathFind: 'sifRadnoMestoPregledi/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRadnoMestoPregledi/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoPregledi/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoPregledi/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoPregledi/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoPregledi'] = SifRadnoMestoPregledi;

  /*****************************************************************/

  var SifLicaElPregleda = BaseModel['default'].extend({
    modelName: 'SifLicaElPregleda',
    pathFindAll: 'sifLicaElPregleda/e',
    verbFindAll: 'GET',
    pathFind: 'sifLicaElPregleda/e',
    verbFind: 'GET',
    pathFindPaged: 'sifLicaElPregleda/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifLicaElPregleda/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifLicaElPregleda/e',
    verbCreate: 'POST',
    pathUpdate: 'sifLicaElPregleda/e',
    verbUpdate: 'PATCH'
  });

  Models['SifLicaElPregleda'] = SifLicaElPregleda;

  /*****************************************************************/

  var SifLicaObuke = BaseModel['default'].extend({
    modelName: 'SifLicaObuke',
    pathFindAll: 'sifLicaObuke/e',
    verbFindAll: 'GET',
    pathFind: 'sifLicaObuke/e',
    verbFind: 'GET',
    pathFindPaged: 'sifLicaObuke/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifLicaObuke/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifLicaObuke/e',
    verbCreate: 'POST',
    pathUpdate: 'sifLicaObuke/e',
    verbUpdate: 'PATCH'
  });

  Models['SifLicaObuke'] = SifLicaObuke;

  /*****************************************************************/

  var SifLicaPregledi = BaseModel['default'].extend({
    modelName: 'SifLicaPregledi',
    pathFindAll: 'sifLicaPregledi/e',
    verbFindAll: 'GET',
    pathFind: 'sifLicaPregledi/e',
    verbFind: 'GET',
    pathFindPaged: 'sifLicaPregledi/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifLicaPregledi/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifLicaPregledi/e',
    verbCreate: 'POST',
    pathUpdate: 'sifLicaPregledi/e',
    verbUpdate: 'PATCH'
  });

  Models['SifLicaPregledi'] = SifLicaPregledi;

  /*****************************************************************/

  var SifLicaLzoKategorije = BaseModel['default'].extend({
    modelName: 'SifLicaLzoKategorije',
    pathFindAll: 'sifLicaLzoKategorije/e',
    verbFindAll: 'GET',
    pathFind: 'sifLicaLzoKategorije/e',
    verbFind: 'GET',
    pathFindPaged: 'sifLicaLzoKategorije/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifLicaLzoKategorije/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifLicaLzoKategorije/e',
    verbCreate: 'POST',
    pathUpdate: 'sifLicaLzoKategorije/e',
    verbUpdate: 'PATCH'
  });

  Models['SifLicaLzoKategorije'] = SifLicaLzoKategorije;

  /*****************************************************************/

  var SifLicaLzoVelicine = BaseModel['default'].extend({
    modelName: 'SifLicaLzoVelicine',
    pathFindAll: 'sifLicaLzoVelicine/e',
    verbFindAll: 'GET',
    pathFind: 'sifLicaLzoVelicine/e',
    verbFind: 'GET',
    pathFindPaged: 'sifLicaLzoVelicine/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifLicaLzoVelicine/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifLicaLzoVelicine/e',
    verbCreate: 'POST',
    pathUpdate: 'sifLicaLzoVelicine/e',
    verbUpdate: 'PATCH'
  });

  Models['SifLicaLzoVelicine'] = SifLicaLzoVelicine;

  /*****************************************************************/

  var SifLzoKategorija = BaseModel['default'].extend({
    modelName: 'SifLzoKategorija',
    pathFindAll: 'sifLzoKategorija/e',
    verbFindAll: 'GET',
    pathFind: 'sifLzoKategorija/e',
    verbFind: 'GET',
    pathFindPaged: 'sifLzoKategorija/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifLzoKategorija/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifLzoKategorija/e',
    verbCreate: 'POST',
    pathUpdate: 'sifLzoKategorija/e',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifLzoKategorija/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifLzoKategorija'] = SifLzoKategorija;

  /*****************************************************************/

  var XsifKlaseOpasnihMaterija = BaseModel['default'].extend({
    modelName: 'XsifKlaseOpasnihMaterija',
    pathFindAll: 'xsifKlaseOpasnihMaterija/e',
    verbFindAll: 'GET',
    pathFind: 'xsifKlaseOpasnihMaterija/e',
    verbFind: 'GET',
    pathFindPaged: 'xsifKlaseOpasnihMaterija/ep',
    verbFindPaged: 'GET',
    pathDelete: 'xsifKlaseOpasnihMaterija/e',
    verbDelete: 'DELETE',
    pathCreate: 'xsifKlaseOpasnihMaterija/e',
    verbCreate: 'POST',
    pathUpdate: 'xsifKlaseOpasnihMaterija/e',
    verbUpdate: 'PATCH',
    pathSelectItems: 'xsifKlaseOpasnihMaterija/selectItems',
    verbSelectItems: 'GET'
  });

  Models['XsifKlaseOpasnihMaterija'] = XsifKlaseOpasnihMaterija;

  /*****************************************************************/

  var XsysPravnoLice = BaseModel['default'].extend({
    modelName: 'XsysPravnoLice',
    pathFindAll: 'xsysPravnoLice/e',
    verbFindAll: 'GET',
    pathFind: 'xsysPravnoLice/e',
    verbFind: 'GET',
    pathFindPaged: 'xsysPravnoLice/ep',
    verbFindPaged: 'GET',
    pathDelete: 'xsysPravnoLice/e',
    verbDelete: 'DELETE',
    pathCreate: 'xsysPravnoLice/e',
    verbCreate: 'POST',
    pathUpdate: 'xsysPravnoLice/e',
    verbUpdate: 'PATCH'
  });

  Models['XsysPravnoLice'] = XsysPravnoLice;

  /*****************************************************************/

  var SysKorisnik = BaseModel['default'].extend({
    modelName: 'SysKorisnik',
    basePath: 'korisnik',
    pathFindAll: 'korisnik/e',
    verbFindAll: 'GET',
    pathFind: 'korisnik/e',
    verbFind: 'GET',
    pathFindPaged: 'korisnik/ep',
    verbFindPaged: 'GET',
    pathDelete: 'korisnik/delete',
    verbDelete: 'DELETE',
    pathCreate: 'korisnik/r/save',
    verbCreate: 'POST',
    pathUpdate: 'korisnik/r/save',
    verbUpdate: 'PATCH'
  });

  Models['SysKorisnik'] = SysKorisnik;

  /*****************************************************************/

  var SysKorisnikPostojeci = BaseModel['default'].extend({
    modelName: 'SysKorisnikPostojeci',
    basePath: 'korisnik',
    pathFindPaged: 'korisnik/ep/postojeciVanPravnogLica',
    verbFindPaged: 'GET'
  });

  Models['SysKorisnikPostojeci'] = SysKorisnikPostojeci;

  /*****************************************************************/

  var SysKorisnikPravnoLice = BaseModel['default'].extend({
    modelName: 'SysKorisnikPravnoLice',
    basePath: 'sysKorisnikPravnoLice'
  });

  Models['SysKorisnikPravnoLice'] = SysKorisnikPravnoLice;

  /*****************************************************************/

  var VobrObrazac1Rm = BaseModel['default'].extend({
    modelName: 'VobrObrazac1Rm',
    pathFindAll: 'vobrObrazac1Rm/r',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac1Rm/r',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac1Rm/rp',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac1Rm/r',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac1Rm/r',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac1Rm/r',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac1Rm/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac1Rm'] = VobrObrazac1Rm;

  /*****************************************************************/

  var VobrObrazac1aRm = BaseModel['default'].extend({
    modelName: 'VobrObrazac1Rm',
    pathFindAll: 'vobrObrazac1Rm/r',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac1Rm/r',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac1Rm/rp',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac1Rm/r',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac1Rm/r',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac1Rm/r',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac1Rm/sacuvajObrazac1a',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac1aRm'] = VobrObrazac1aRm;

  /*****************************************************************/

  var ObrObrazac1RmOpasnosti = BaseModel['default'].extend({
    modelName: 'ObrObrazac1RmOpasnosti',
    pathFindAll: 'obrObrazac1RmOpasnosti/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac1RmOpasnosti/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac1RmOpasnosti/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac1RmOpasnosti/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac1RmOpasnosti/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac1RmOpasnosti/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac1RmOpasnosti'] = ObrObrazac1RmOpasnosti;

  /*****************************************************************/

  var VobrObrazac1Rms = BaseModel['default'].extend({
    modelName: 'VobrObrazac1Rms',
    pathFindAll: 'vobrObrazac1Rms/r',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac1Rms/r',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac1Rms/rp',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac1Rms/r',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac1Rms/r',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac1Rms/r',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac1Rms/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac1Rms'] = VobrObrazac1Rms;

  /*****************************************************************/

  var VobrObrazac1aRms = BaseModel['default'].extend({
    modelName: 'VobrObrazac1Rms',
    pathFindAll: 'vobrObrazac1Rms/r',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac1Rms/r',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac1Rms/rp',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac1Rms/r',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac1Rms/r',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac1Rms/r',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac1Rms/sacuvajObrazac1a',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac1aRms'] = VobrObrazac1aRms;

  /*****************************************************************/

  var ObrObrazac1RmsOpasnosti = BaseModel['default'].extend({
    modelName: 'ObrObrazac1RmsOpasnosti',
    pathFindAll: 'obrObrazac1RmsOpasnosti/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac1RmsOpasnosti/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac1RmsOpasnosti/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac1RmsOpasnosti/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac1RmsOpasnosti/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac1RmsOpasnosti/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac1RmsOpasnosti'] = ObrObrazac1RmsOpasnosti;

  /*****************************************************************/

  var VobrObrazac02 = BaseModel['default'].extend({
    modelName: 'VobrObrazac02',
    pathFindAll: 'vobrObrazac02/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac02/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac02/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac02/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac02/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac02/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac02/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac02'] = VobrObrazac02;

  /*****************************************************************/

  var ObrObrazac02ZdravstveniPregled = BaseModel['default'].extend({
    modelName: 'ObrObrazac02ZdravstveniPregled',
    pathFindAll: 'obrObrazac02ZdravstveniPregled/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac02ZdravstveniPregled/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac02ZdravstveniPregled/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac02ZdravstveniPregled/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac02ZdravstveniPregled/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac02ZdravstveniPregled/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac02ZdravstveniPregled'] = ObrObrazac02ZdravstveniPregled;

  /*****************************************************************/

  var VobrObrazac02ElementiPregleda = BaseModel['default'].extend({
    modelName: 'VobrObrazac02ElementiPregleda',
    pathFindAll: 'vobrObrazac02ElementiPregleda/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac02ElementiPregleda/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac02ElementiPregleda/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac02ElementiPregleda/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac02ElementiPregleda/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac02ElementiPregleda/e',
    verbUpdate: 'PATCH'
  });

  Models['VobrObrazac02ElementiPregleda'] = VobrObrazac02ElementiPregleda;

  /*****************************************************************/

  var SifZdravstvenaSposobnost = BaseModel['default'].extend({
    modelName: 'SifZdravstvenaSposobnost',
    pathFindAll: 'sifZdravstvenaSposobnost/e',
    verbFindAll: 'GET',
    pathFind: 'sifZdravstvenaSposobnost/e',
    verbFind: 'GET',
    pathFindPaged: 'sifZdravstvenaSposobnost/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifZdravstvenaSposobnost/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifZdravstvenaSposobnost/e',
    verbCreate: 'POST',
    pathUpdate: 'sifZdravstvenaSposobnost/e',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifZdravstvenaSposobnost/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifZdravstvenaSposobnost'] = SifZdravstvenaSposobnost;

  /*****************************************************************/

  var ObrObrazac02ElementZdravstvenogPregleda = BaseModel['default'].extend({
    modelName: 'ObrObrazac02ElementZdravstvenogPregleda',
    pathFindAll: 'obrObrazac02ElementZdravstvenogPregleda/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac02ElementZdravstvenogPregleda/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac02ElementZdravstvenogPregleda/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac02ElementZdravstvenogPregleda/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac02ElementZdravstvenogPregleda/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac02ElementZdravstvenogPregleda/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac02ElementZdravstvenogPregleda'] = ObrObrazac02ElementZdravstvenogPregleda;

  /*****************************************************************/

  var ObrObrazac13SpecijalanPregled = BaseModel['default'].extend({
    modelName: 'ObrObrazac13SpecijalanPregled',
    pathFindAll: 'obrObrazac13SpecijalanPregled/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac13SpecijalanPregled/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac13SpecijalanPregled/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac13SpecijalanPregled/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac13SpecijalanPregled/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac13SpecijalanPregled/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac13SpecijalanPregled'] = ObrObrazac13SpecijalanPregled;

  /*****************************************************************/

  var VobrObrazac13 = BaseModel['default'].extend({
    modelName: 'VobrObrazac13',
    pathFindAll: 'vobrObrazac13/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac13/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac13/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac13/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac13/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac13/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac13/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac13'] = VobrObrazac13;

  /*****************************************************************/

  var SifZdravstveniPregledZakazan = BaseModel['default'].extend({
    modelName: 'SifZdravstveniPregledZakazan',
    pathFindAll: 'sifZdravstveniPregledZakazan/e',
    verbFindAll: 'GET',
    pathFind: 'sifZdravstveniPregledZakazan/e',
    verbFind: 'GET',
    pathFindPaged: 'sifZdravstveniPregledZakazan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifZdravstveniPregledZakazan/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifZdravstveniPregledZakazan/e',
    verbCreate: 'POST',
    pathUpdate: 'sifZdravstveniPregledZakazan/e',
    verbUpdate: 'PATCH'
  });

  Models['SifZdravstveniPregledZakazan'] = SifZdravstveniPregledZakazan;
  /*****************************************************************/

  var ObrObrazac03 = BaseModel['default'].extend({
    modelName: 'ObrObrazac03',
    pathFindAll: 'obrObrazac03/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac03/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac03/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac03/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac03/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac03/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'obrObrazac03/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['ObrObrazac03'] = ObrObrazac03;

  /*****************************************************************/

  var ObrObrazac04 = BaseModel['default'].extend({
    modelName: 'ObrObrazac04',
    pathFindAll: 'obrObrazac04/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac04/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac04/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac04/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac04/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac04/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'obrObrazac04/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['ObrObrazac04'] = ObrObrazac04;

  /*****************************************************************/

  var ObrObrazac05 = BaseModel['default'].extend({
    modelName: 'ObrObrazac05',
    pathFindAll: 'obrObrazac05/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac05/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac05/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac05/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac05/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac05/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'obrObrazac05/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['ObrObrazac05'] = ObrObrazac05;

  /*****************************************************************/

  var SifUzrokPovrede = BaseModel['default'].extend({
    modelName: 'SifUzrokPovrede',
    pathFindAll: 'sifUzrokPovrede/e',
    verbFindAll: 'GET',
    pathFind: 'sifUzrokPovrede/e',
    verbFind: 'GET',
    pathFindPaged: 'sifUzrokPovrede/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifUzrokPovrede/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifUzrokPovrede/r',
    verbCreate: 'POST',
    pathUpdate: 'sifUzrokPovrede/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifUzrokPovrede/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifUzrokPovrede'] = SifUzrokPovrede;

  /*****************************************************************/

  var SifIzvorPovrede = BaseModel['default'].extend({
    modelName: 'SifIzvorPovrede',
    pathFindAll: 'sifIzvorPovrede/e',
    verbFindAll: 'GET',
    pathFind: 'sifIzvorPovrede/e',
    verbFind: 'GET',
    pathFindPaged: 'sifIzvorPovrede/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifIzvorPovrede/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifIzvorPovrede/r',
    verbCreate: 'POST',
    pathUpdate: 'sifIzvorPovrede/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifIzvorPovrede/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifIzvorPovrede'] = SifIzvorPovrede;

  /*****************************************************************/

  var SifMestoPovrede = BaseModel['default'].extend({
    modelName: 'SifMestoPovrede',
    pathFindAll: 'sifMestoPovrede/e',
    verbFindAll: 'GET',
    pathFind: 'sifMestoPovrede/e',
    verbFind: 'GET',
    pathFindPaged: 'sifMestoPovrede/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifMestoPovrede/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifMestoPovrede/r',
    verbCreate: 'POST',
    pathUpdate: 'sifMestoPovrede/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifMestoPovrede/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifMestoPovrede'] = SifMestoPovrede;

  /*****************************************************************/

  var SifStatusZaposlenog = BaseModel['default'].extend({
    modelName: 'SifStatusZaposlenog',
    pathFindAll: 'sifStatusZaposlenog/e',
    verbFindAll: 'GET',
    pathFind: 'sifStatusZaposlenog/e',
    verbFind: 'GET',
    pathFindPaged: 'sifStatusZaposlenog/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifStatusZaposlenog/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifStatusZaposlenog/r',
    verbCreate: 'POST',
    pathUpdate: 'sifStatusZaposlenog/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifStatusZaposlenog/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifStatusZaposlenog'] = SifStatusZaposlenog;

  /*****************************************************************/

  var SifVrstaStepenSs = BaseModel['default'].extend({
    modelName: 'SifVrstaStepenSs',
    pathFindAll: 'sifVrstaStepenSs/e',
    verbFindAll: 'GET',
    pathFind: 'sifVrstaStepenSs/e',
    verbFind: 'GET',
    pathFindPaged: 'sifVrstaStepenSs/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifVrstaStepenSs/r',
    verbDelete: 'DELETE',
    pathCreate: 'sifVrstaStepenSs/r',
    verbCreate: 'POST',
    pathUpdate: 'sifVrstaStepenSs/r',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifVrstaStepenSs/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifVrstaStepenSs'] = SifVrstaStepenSs;

  /*****************************************************************/

  var ObrObrazac06aSpecijalnaObuka = BaseModel['default'].extend({
    modelName: 'ObrObrazac06aSpecijalnaObuka',
    pathFindAll: 'obrObrazac06aSpecijalnaObuka/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac06aSpecijalnaObuka/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac06aSpecijalnaObuka/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac06aSpecijalnaObuka/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac06aSpecijalnaObuka/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac06aSpecijalnaObuka/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac06aSpecijalnaObuka'] = ObrObrazac06aSpecijalnaObuka;

  /*****************************************************************/

  var VobrObrazac06a = BaseModel['default'].extend({
    modelName: 'VobrObrazac06a',
    pathFindAll: 'vobrObrazac06a/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac06a/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac06a/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac06a/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac06a/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac06a/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac06a/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac06a'] = VobrObrazac06a;

  /*****************************************************************/

  var SifObukaZakazana = BaseModel['default'].extend({
    modelName: 'SifObukaZakazana',
    pathFindAll: 'sifObukaZakazana/e',
    verbFindAll: 'GET',
    pathFind: 'sifObukaZakazana/e',
    verbFind: 'GET',
    pathFindPaged: 'sifObukaZakazana/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifObukaZakazana/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifObukaZakazana/e',
    verbCreate: 'POST',
    pathUpdate: 'sifObukaZakazana/e',
    verbUpdate: 'PATCH'
  });

  Models['SifObukaZakazana'] = SifObukaZakazana;
  /*****************************************************************/

  var ObrObrazac01Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac01Sacuvan',
    pathFindAll: 'obrObrazac01Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac01Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac01Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac01Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac01Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac01Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac01Sacuvan'] = ObrObrazac01Sacuvan;

  /***************************************************************/

  var ObrObrazac01aSacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac01aSacuvan',
    pathFindAll: 'obrObrazac01aSacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac01aSacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac01aSacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac01aSacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac01aSacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac01aSacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac01aSacuvan'] = ObrObrazac01aSacuvan;

  /***************************************************************/

  var ObrObrazac02Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac02Sacuvan',
    pathFindAll: 'obrObrazac02Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac02Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac02Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac02Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac02Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac02Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac02Sacuvan'] = ObrObrazac02Sacuvan;

  /***************************************************************/

  var ObrObrazac13Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac13Sacuvan',
    pathFindAll: 'obrObrazac13Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac13Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac13Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac13Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac13Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac13Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac13Sacuvan'] = ObrObrazac13Sacuvan;

  /***************************************************************/

  var ObrObrazac03Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac03Sacuvan',
    pathFindAll: 'obrObrazac03Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac03Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac03Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac03Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac03Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac03Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac03Sacuvan'] = ObrObrazac03Sacuvan;

  /***************************************************************/

  var ObrObrazac04Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac04Sacuvan',
    pathFindAll: 'obrObrazac04Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac04Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac04Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac04Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac04Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac04Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac04Sacuvan'] = ObrObrazac04Sacuvan;

  /***************************************************************/

  var ObrObrazac05Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac05Sacuvan',
    pathFindAll: 'obrObrazac05Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac05Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac05Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac05Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac05Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac05Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac05Sacuvan'] = ObrObrazac05Sacuvan;

  /***************************************************************/

  var ObrObrazac08Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac08Sacuvan',
    pathFindAll: 'obrObrazac08Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac08Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac08Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac08Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac08Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac08Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac08Sacuvan'] = ObrObrazac08Sacuvan;

  /***************************************************************/

  var ObrObrazac09Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac09Sacuvan',
    pathFindAll: 'obrObrazac09Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac09Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac09Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac09Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac09Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac09Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac09Sacuvan'] = ObrObrazac09Sacuvan;

  /***************************************************************/

  var ObrObrazac10Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac10Sacuvan',
    pathFindAll: 'obrObrazac10Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac10Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac10Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac10Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac10Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac10Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac10Sacuvan'] = ObrObrazac10Sacuvan;

  /***************************************************************/

  var ObrObrazac11Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac11Sacuvan',
    pathFindAll: 'obrObrazac11Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac11Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac11Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac11Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac11Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac11Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac11Sacuvan'] = ObrObrazac11Sacuvan;

  /***************************************************************/

  var ObrObrazac12Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac12Sacuvan',
    pathFindAll: 'obrObrazac12Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac12Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac12Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac12Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac12Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac12Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac12Sacuvan'] = ObrObrazac12Sacuvan;

  /***************************************************************/

  var ObrObrazac13Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac13Sacuvan',
    pathFindAll: 'obrObrazac13Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac13Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac13Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac13Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac13Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac13Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac13Sacuvan'] = ObrObrazac13Sacuvan;

  /***************************************************************/

  var ObrObrazac14Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac14Sacuvan',
    pathFindAll: 'obrObrazac14Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac14Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac14Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac14Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac14Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac14Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac14Sacuvan'] = ObrObrazac14Sacuvan;

  /***************************************************************/

  var VobrObrazac06 = BaseModel['default'].extend({
    modelName: 'VobrObrazac06',
    pathFindAll: 'vobrObrazac06/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac06/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac06/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac06/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac06/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac06/e',
    verbUpdate: 'PATCH'
  });

  Models['VobrObrazac06'] = VobrObrazac06;

  /***************************************************************/

  var ObrObrazac06ElementObuke = BaseModel['default'].extend({
    modelName: 'ObrObrazac06ElementObuke',
    pathFindAll: 'obrObrazac06ElementObuke/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac06ElementObuke/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac06ElementObuke/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac06ElementObuke/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac06ElementObuke/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac06ElementObuke/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac06ElementObuke'] = ObrObrazac06ElementObuke;

  /***************************************************************/

  var ObrObrazac06Obuka = BaseModel['default'].extend({
    modelName: 'ObrObrazac06Obuka',
    pathFindAll: 'obrObrazac06Obuka/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac06Obuka/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac06Obuka/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac06Obuka/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac06Obuka/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac06Obuka/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac06Obuka'] = ObrObrazac06Obuka;

  /***************************************************************/

  var SifRazlogOsposobljavanja = BaseModel['default'].extend({
    modelName: 'SifRazlogOsposobljavanja',
    pathFindAll: 'sifRazlogOsposobljavanja/e',
    verbFindAll: 'GET',
    pathFind: 'sifRazlogOsposobljavanja/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRazlogOsposobljavanja/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRazlogOsposobljavanja/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRazlogOsposobljavanja/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRazlogOsposobljavanja/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRazlogOsposobljavanja'] = SifRazlogOsposobljavanja;

  /***************************************************************/

  var ObrObrazac06ObukaZakazana = BaseModel['default'].extend({
    modelName: 'ObrObrazac06ObukaZakazana',
    pathFindAll: 'obrObrazac06ObukaZakazana/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac06ObukaZakazana/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac06ObukaZakazana/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac06ObukaZakazana/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac06ObukaZakazana/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac06ObukaZakazana/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac06ObukaZakazana'] = ObrObrazac06ObukaZakazana;

  /***************************************************************/

  var ObrObrazac06ElementZakazaneObuke = BaseModel['default'].extend({
    modelName: 'ObrObrazac06ElementZakazaneObuke',
    pathFindAll: 'obrObrazac06ElementZakazaneObuke/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac06ElementZakazaneObuke/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac06ElementZakazaneObuke/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac06ElementZakazaneObuke/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac06ElementZakazaneObuke/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac06ElementZakazaneObuke/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac06ElementZakazaneObuke'] = ObrObrazac06ElementZakazaneObuke;

  /***************************************************************/

  var SifLicaZaPreglede = BaseModel['default'].extend({
    modelName: 'SifLica',
    verbFind: 'GET',
    verbFindPaged: 'GET',
    pathFindPagedPost: 'sifLica/advancedFilterPregledi'
  });

  Models['SifLicaZaPreglede'] = SifLicaZaPreglede;

  /************************************************/

  var SifLicaZaObuke = BaseModel['default'].extend({
    modelName: 'SifLica',
    verbFind: 'GET',
    verbFindPaged: 'GET',
    pathFindPagedPost: 'sifLica/advancedFilterObuke'
  });

  Models['SifLicaZaObuke'] = SifLicaZaObuke;

  /************************************************/

  var SifZaposleniZaObuke = BaseModel['default'].extend({
    modelName: 'VsifZaposleni',
    verbFind: 'GET',
    verbFindPaged: 'GET',
    pathFindPagedPost: 'sifZaposleni/advancedFilterObuke'
  });

  Models['SifZaposleniZaObuke'] = SifZaposleniZaObuke;

  /************************************************/

  var SifLicaZaObuke = BaseModel['default'].extend({
    modelName: 'SifLice',
    verbFind: 'GET',
    verbFindPaged: 'GET',
    pathFindPagedPost: 'sifLica/advancedFilterObuke'
  });

  Models['SifLicaZaObuke'] = SifLicaZaObuke;

  /************************************************/

  var ObrObrazac06Sacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac06Sacuvan',
    pathFindAll: 'obrObrazac06Sacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac06Sacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac06Sacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac06Sacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac06Sacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac06Sacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac06Sacuvan'] = ObrObrazac06Sacuvan;

  /************************************************/

  var ObrObrazac09PregledOpreme = BaseModel['default'].extend({
    modelName: 'ObrObrazac09PregledOpreme',
    pathFindAll: 'obrObrazac09PregledOpreme/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac09PregledOpreme/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac09PregledOpreme/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac09PregledOpreme/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac09PregledOpreme/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac09PregledOpreme/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac09PregledOpreme'] = ObrObrazac09PregledOpreme;

  /************************************************/

  var VobrObrazac09 = BaseModel['default'].extend({
    modelName: 'VobrObrazac09',
    pathFindAll: 'vobrObrazac09/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac09/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac09/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac09/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac09/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac09/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac09/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac09'] = VobrObrazac09;

  /************************************************/

  var ObrObrazac07Rm = BaseModel['default'].extend({
    modelName: 'ObrObrazac07Rm',
    pathFindAll: 'obrObrazac07Rm/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac07Rm/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac07Rm/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac07Rm/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac07Rm/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac07Rm/e',
    verbUpdate: 'PATCH',
    basePath: 'obrObrazac07Rm'
  });

  Models['ObrObrazac07Rm'] = ObrObrazac07Rm;

  /************************************************/

  var ObrObrazac07Rms = BaseModel['default'].extend({
    modelName: 'ObrObrazac07Rms',
    pathFindAll: 'obrObrazac07Rms/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac07Rms/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac07Rms/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac07Rms/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac07Rms/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac07Rms/e',
    verbUpdate: 'PATCH',
    basePath: 'obrObrazac07Rms'
  });

  Models['ObrObrazac07Rms'] = ObrObrazac07Rms;

  /************************************************/

  var ObrObrazac07OpasnaMaterijaRms = BaseModel['default'].extend({
    modelName: 'ObrObrazac07OpasnaMaterijaRms',
    pathFindAll: 'obrObrazac07OpasnaMaterijaRms/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac07OpasnaMaterijaRms/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac07OpasnaMaterijaRms/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac07OpasnaMaterijaRms/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac07OpasnaMaterijaRms/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac07OpasnaMaterijaRms/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac07OpasnaMaterijaRms'] = ObrObrazac07OpasnaMaterijaRms;

  /************************************************/

  var ObrObrazac07OpasnaMaterijaRm = BaseModel['default'].extend({
    modelName: 'ObrObrazac07OpasnaMaterijaRm',
    pathFindAll: 'obrObrazac07OpasnaMaterijaRm/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac07OpasnaMaterijaRm/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac07OpasnaMaterijaRm/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac07OpasnaMaterijaRm/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac07OpasnaMaterijaRm/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac07OpasnaMaterijaRm/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac07OpasnaMaterijaRm'] = ObrObrazac07OpasnaMaterijaRm;

  /************************************************/

  var ObrObrazac07RmSacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac07RmSacuvan',
    pathFindAll: 'obrObrazac07RmSacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac07RmSacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac07RmSacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac07RmSacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac07RmSacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac07RmSacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac07RmSacuvan'] = ObrObrazac07RmSacuvan;

  /************************************************/

  var ObrObrazac07RmsSacuvan = BaseModel['default'].extend({
    modelName: 'ObrObrazac07RmsSacuvan',
    pathFindAll: 'obrObrazac07RmsSacuvan/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac07RmsSacuvan/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac07RmsSacuvan/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac07RmsSacuvan/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac07RmsSacuvan/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac07RmsSacuvan/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac07RmsSacuvan'] = ObrObrazac07RmsSacuvan;

  /************************************************/

  var VobrObrazac08 = BaseModel['default'].extend({
    modelName: 'VobrObrazac08',
    pathFindAll: 'vobrObrazac08/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac08/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac08/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac08/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac08/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac08/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac08/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac08'] = VobrObrazac08;

  /************************************************/

  var ObrObrazac08IspitivanjeOkoline = BaseModel['default'].extend({
    modelName: 'ObrObrazac08IspitivanjeOkoline',
    pathFindAll: 'obrObrazac08IspitivanjeOkoline/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac08IspitivanjeOkoline/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac08IspitivanjeOkoline/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac08IspitivanjeOkoline/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac08IspitivanjeOkoline/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac08IspitivanjeOkoline/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac08IspitivanjeOkoline'] = ObrObrazac08IspitivanjeOkoline;

  /***********************************************/

  var SifRadnoMestoLzo = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoLzo',
    pathFindPaged: 'sifRadnoMestoLzo/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoLzo/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoLzo/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoLzo/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoLzo'] = SifRadnoMestoLzo;

  /************************************************/

  var SifRadnoMestoStrLzo = BaseModel['default'].extend({
    modelName: 'SifRadnoMestoStrLzo',
    pathFindPaged: 'sifRadnoMestoStrLzo/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRadnoMestoStrLzo/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifRadnoMestoStrLzo/e',
    verbCreate: 'POST',
    pathUpdate: 'sifRadnoMestoStrLzo/e',
    verbUpdate: 'PATCH'
  });

  Models['SifRadnoMestoStrLzo'] = SifRadnoMestoStrLzo;

  /*******************************************************/

  Models['Util'] = BaseModel['default'].extend({
    pathGenerateReport: 'izvestaji/generisiIzvestaj',
    verbGenerateReport: 'POST'
  });

  /************************************************/
  Models['SifBolest'] = BaseModel['default'].extend({
    modelName: 'SifBolest',
    pathSelectItems: 'sifBolest/selectItems',
    verbSelectItems: 'GET'
  });

  /*************************************************/
  Models['SifOboljenje'] = BaseModel['default'].extend({
    modelName: 'SifBolest',
    pathSelectItems: 'sifOboljenje/selectItems',
    verbSelectItems: 'GET'
  });

  /*************************************************/

  var ObrObrazac10Lzo = BaseModel['default'].extend({
    modelName: 'ObrObrazac10Lzo',
    pathFindAll: 'obrObrazac10Lzo/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac10Lzo/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac10Lzo/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac10Lzo/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac10Lzo/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac10Lzo/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'obrObrazac10Lzo/sacuvajObrazac',
    verbSacuvajObrazac: 'GET',
    pathSelectItems: 'obrObrazac10Lzo/selectItems',
    verbSelectItems: 'GET'
  });

  Models['ObrObrazac10Lzo'] = ObrObrazac10Lzo;

  /*************************************************/

  var ObrObrazac10LzoNePodleze = Models['ObrObrazac10Lzo'].extend({
    pathFindPaged: 'obrObrazac10Lzo/ep/nePodleze',
    verbFindPaged: 'GET'
  });

  Models['ObrObrazac10LzoNePodleze'] = ObrObrazac10LzoNePodleze;

  /************************************************/

  var ObrObrazac10LzoPregledi = BaseModel['default'].extend({
    modelName: 'ObrObrazac10LzoPregledi',
    pathFindAll: 'obrObrazac10LzoPregledi/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac10LzoPregledi/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac10LzoPregledi/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac10LzoPregledi/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac10LzoPregledi/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac10LzoPregledi/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac10LzoPregledi'] = ObrObrazac10LzoPregledi;

  /************************************************/

  var VobrObrazac11 = BaseModel['default'].extend({
    modelName: 'VobrObrazac11',
    pathFindAll: 'vobrObrazac11/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac11/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac11/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac11/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac11/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac11/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac11/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac11'] = VobrObrazac11;

  /************************************************/

  var VobrObrazac12 = BaseModel['default'].extend({
    modelName: 'VobrObrazac12',
    pathFindAll: 'vobrObrazac12/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac12/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac12/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac12/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac12/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac12/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac12/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac12'] = VobrObrazac12;

  /************************************************/

  var VobrObrazac13 = BaseModel['default'].extend({
    modelName: 'VobrObrazac13',
    pathFindAll: 'vobrObrazac13/e',
    verbFindAll: 'GET',
    pathFind: 'vobrObrazac13/e',
    verbFind: 'GET',
    pathFindPaged: 'vobrObrazac13/ep',
    verbFindPaged: 'GET',
    pathDelete: 'vobrObrazac13/e',
    verbDelete: 'DELETE',
    pathCreate: 'vobrObrazac13/e',
    verbCreate: 'POST',
    pathUpdate: 'vobrObrazac13/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'vobrObrazac13/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['VobrObrazac13'] = VobrObrazac13;

  /************************************************/
  var ObrObrazac11PrijavaPovrede = BaseModel['default'].extend({
    modelName: 'ObrObrazac11PrijavaPovrede',
    pathFindAll: 'obrObrazac11PrijavaPovrede/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac11PrijavaPovrede/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac11PrijavaPovrede/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac11PrijavaPovrede/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac11PrijavaPovrede/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac11PrijavaPovrede/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac11PrijavaPovrede'] = ObrObrazac11PrijavaPovrede;

  /************************************************/
  var ObrObrazac12PrijavaOboljenje = BaseModel['default'].extend({
    modelName: 'ObrObrazac12PrijavaOboljenje',
    pathFindAll: 'obrObrazac12PrijavaOboljenje/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac12PrijavaOboljenje/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac12PrijavaOboljenje/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac12PrijavaOboljenje/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac12PrijavaOboljenje/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac12PrijavaOboljenje/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac12PrijavaOboljenje'] = ObrObrazac12PrijavaOboljenje;

  /************************************************/
  var ObrObrazac13PrijavaBolest = BaseModel['default'].extend({
    modelName: 'ObrObrazac13PrijavaBolest',
    pathFindAll: 'obrObrazac13PrijavaBolest/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac13PrijavaBolest/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac13PrijavaBolest/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac13PrijavaBolest/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac13PrijavaBolest/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac13PrijavaBolest/e',
    verbUpdate: 'PATCH'
  });

  Models['ObrObrazac13PrijavaBolest'] = ObrObrazac13PrijavaBolest;

  /************************************************/

  var VsifRevers = BaseModel['default'].extend({
    modelName: 'VsifRevers',
    pathFindAll: 'vsifRevers/e',
    verbFindAll: 'GET',
    pathFind: 'vsifRevers/e',
    verbFind: 'GET',
    pathFindPaged: 'vsifRevers/ep',
    verbFindPaged: 'GET'

  });

  Models['VsifRevers'] = VsifRevers;

  /************************************************/

  var SifRevers = BaseModel['default'].extend({
    modelName: 'SifRevers',
    pathFindAll: 'sifRevers/e',
    verbFindAll: 'GET',
    pathFind: 'sifRevers/e',
    verbFind: 'GET',
    pathFindPaged: 'sifRevers/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifRevers/e',
    verbDelete: 'DELETE'

  });

  Models['SifRevers'] = SifRevers;

  /************************************************/

  var VsifReversLzo = BaseModel['default'].extend({
    modelName: 'VsifReversLzo',
    pathFindAll: 'vsifReversLzo/e',
    verbFindAll: 'GET',
    pathFind: 'vsifReversLzo/e',
    verbFind: 'GET',
    pathFindPaged: 'vsifReversLzo/ep',
    verbFindPaged: 'GET',
    pathCreate: 'vsifReversLzo/e',
    verbCreate: 'POST',
    pathUpdate: 'vsifReversLzo/e',
    verbUpdate: 'PATCH'

  });

  Models['VsifReversLzo'] = VsifReversLzo;

  /************************************************/

  var ObrObrazac14PrijavaPojave = BaseModel['default'].extend({
    modelName: 'ObrObrazac14PrijavaPojave',
    pathFindAll: 'obrObrazac14PrijavaPojave/e',
    verbFindAll: 'GET',
    pathFind: 'obrObrazac14PrijavaPojave/e',
    verbFind: 'GET',
    pathFindPaged: 'obrObrazac14PrijavaPojave/ep',
    verbFindPaged: 'GET',
    pathDelete: 'obrObrazac14PrijavaPojave/e',
    verbDelete: 'DELETE',
    pathCreate: 'obrObrazac14PrijavaPojave/e',
    verbCreate: 'POST',
    pathUpdate: 'obrObrazac14PrijavaPojave/e',
    verbUpdate: 'PATCH',
    pathSacuvajObrazac: 'obrObrazac14PrijavaPojave/sacuvajObrazac',
    verbSacuvajObrazac: 'GET'
  });

  Models['ObrObrazac14PrijavaPojave'] = ObrObrazac14PrijavaPojave;

  /************************************************/

  var SysKorisnikRola = BaseModel['default'].extend({
    modelName: 'SysKorisnikRola',
    pathFindAll: 'sysKorisnikRola/e',
    verbFindAll: 'GET',
    pathFind: 'sysKorisnikRola/e',
    verbFind: 'GET',
    pathFindPaged: 'sysKorisnikRola/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sysKorisnikRola/e',
    verbDelete: 'DELETE',
    pathCreate: 'sysKorisnikRola/e',
    verbCreate: 'POST',
    pathUpdate: 'sysKorisnikRola/e',
    verbUpdate: 'PATCH'
  });

  Models['SysKorisnikRola'] = SysKorisnikRola;

  /************************************************/

  var SemSemaforPodesavanja = BaseModel['default'].extend({
    modelName: 'SemSemaforPodesavanja',
    pathFindAll: 'semSemaforPodesavanja/r',
    verbFindAll: 'GET'
  });

  Models['SemSemaforPodesavanja'] = SemSemaforPodesavanja;

  /************************************************/

  var SemSemaforRezultat = BaseModel['default'].extend({
    modelName: 'SemSemaforRezultat',
    pathFindAll: 'semSemaforRezultat/r',
    verbFindAll: 'GET',
    pathFind: 'semSemaforRezultat/r',
    verbFind: 'GET',
    pathFindPaged: 'semSemaforRezultat/r',
    verbFindPaged: 'GET'
  });

  Models['SemSemaforRezultat'] = SemSemaforRezultat;

  /************************************************/

  var SifObukaDokument = BaseModel['default'].extend({
    modelName: 'SifObukaDokument',
    pathFindAll: 'sifObukaDokument/e',
    verbFindAll: 'GET',
    pathFind: 'sifObukaDokument/e',
    verbFind: 'GET',
    pathFindPaged: 'sifObukaDokument/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifObukaDokument/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifObukaDokument/e',
    verbCreate: 'POST',
    pathUpdate: 'sifObukaDokument/e',
    verbUpdate: 'PATCH'
  });

  Models['SifObukaDokument'] = SifObukaDokument;

  /************************************************/

  var SifVrstaDokumenta = BaseModel['default'].extend({
    modelName: 'SifVrstaDokumenta',
    pathFindAll: 'sifVrstaDokumenta/e',
    verbFindAll: 'GET',
    pathFind: 'sifVrstaDokumenta/e',
    verbFind: 'GET',
    pathFindPaged: 'sifVrstaDokumenta/ep',
    verbFindPaged: 'GET',
    pathDelete: 'sifVrstaDokumenta/e',
    verbDelete: 'DELETE',
    pathCreate: 'sifVrstaDokumenta/e',
    verbCreate: 'POST',
    pathUpdate: 'sifVrstaDokumenta/e',
    verbUpdate: 'PATCH',
    pathSelectItems: 'sifVrstaDokumenta/selectItems',
    verbSelectItems: 'GET'
  });

  Models['SifVrstaDokumenta'] = SifVrstaDokumenta;
  /************************************************/

  var BzZakon = BaseModel['default'].extend({
    modelName: 'BzZakon',
    pathFindAll: 'bzZakon/e',
    verbFindAll: 'GET',
    pathFind: 'bzZakon/e',
    verbFind: 'GET',
    pathFindPaged: 'bzZakon/ep',
    verbFindPaged: 'GET'
  });

  Models['BzZakon'] = BzZakon;

  /************************************************/

  exports['default'] = Models;

});