define('zaris-frontend/routes/sys-odabir-potpisnika', ['exports', 'zaris-frontend/models/models', 'zaris-frontend/routes/authenticated', 'ember'], function (exports, Models, Authenticated, Ember) {

  'use strict';

  exports['default'] = Authenticated['default'].extend({
    loginService: Ember['default'].inject.service('login'),

    model: function model() {
      var loginService = this.get('loginService');

      var sifLicaModel = Models['default'].SifLica.create({ errorHandlerController: this });
      var lica = sifLicaModel.getAllResults('eBzr', loginService.getAccessToken(), 'GET');

      var pravnoLiceModel = Models['default'].XsysPravnoLice.create({ errorHandlerController: this });
      var pravnoLice = pravnoLiceModel.findGetResult(localStorage.idPravnoLice, loginService.getAccessToken());

      return Ember['default'].RSVP.hash({
        lica: lica,
        idLicePotpisnik: pravnoLice.idLicePotpisnik
      });
    },

    setupController: function setupController(controller, model) {
      console.log(model);
      this.set('controller.model', model);
    }

  });

});