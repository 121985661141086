define('zaris-frontend/components/izvestaj-lekara-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    objectId: null,
    servicePath: null,
    refresh: false,
    modalPanelShow: false,

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {

      return this.get('idRowSelected') !== 0 && this.get('selectedRow')[this.get('domainId')] !== null;
    }),

    isIzvestajExist: Ember['default'].computed('selectedRow', function () {
      return this.get('selectedRow.izvestajFileName');
    }),

    didInsertElement: function didInsertElement() {
      this.$('.fileUploadModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {

      initUploadIzvestaj: function initUploadIzvestaj() {
        var domainId = this.get('domainId');
        this.set('objectId', this.get('selectedRow')[domainId]);
        console.log(this.get('domainId'));
        this.set('servicePath', 'uploadIzvestajLekara');
        this.toggleProperty('fileUploadModalPanelShow');
      },

      downloadIzvestaj: function downloadIzvestaj() {
        var outter = this;
        var i18n = this.get('i18n');
        var domainId = this.get('domainId');

        var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });

        model.getById('downloadIzvestajLekara', this.get('selectedRow')[domainId], this.get('login').getAccessToken(), 'GET', function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      deleteIzvestaj: function deleteIzvestaj() {
        var outter = this;
        var i18n = this.get('i18n');
        var domainId = this.get('domainId');

        var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this });
        model.getById('deleteIzvestajLekara', this.get('selectedRow')[domainId], this.get('login').getAccessToken(), 'POST', function (o) {
          $.Notify({
            caption: i18n.t("fileUpload.brisanjeHeader").string,
            content: i18n.t("fileUpload.brisanjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      }

    }
  });

});