define('zaris-frontend/components/obr-obrazac02-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac02Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentPregledi: Em.A(),
    modelObjectPregledi: {},
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.obrObrazac02Modal').appendTo(this.$(document.body));
      this.$('.obrObrazac02UputModal').appendTo(this.$(document.body));
      this.$('.obrObrazac02PregledModal').appendTo(this.$(document.body));
      this.$('.fileUploadModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    isPotpisanUputExist: Ember['default'].computed('selectedRowObr2', function () {
      return this.get('selectedRowObr2.fgUputFileName');
    }),

    isIzvestajExist: Ember['default'].computed('selectedRowObr2', function () {
      return this.get('selectedRowObr2.fgIzvestajFileName');
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      dodajPotpisanUput: function dodajPotpisanUput() {
        this.set('objectId', this.get('selectedRowObr2.idZdravstveniPregled'));
        this.set('servicePath', 'uploadPotpisanUput');
        this.toggleProperty('fileUploadModalPanelShow');
      },

      dodajIzvestaj: function dodajIzvestaj() {
        this.set('objectId', this.get('selectedRowObr2.idZdravstveniPregled'));
        this.set('servicePath', 'uploadIzvestaj');
        this.toggleProperty('fileUploadModalPanelShow');
      },

      preuzmiPotpisanUput: function preuzmiPotpisanUput() {
        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default'].ObrObrazac02ZdravstveniPregled.create({ errorHandlerController: this });

        model.getById('downloadPotpisanUput', this.get('selectedRowObr2.idZdravstveniPregled'), this.get('login').getAccessToken(), 'GET', function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      preuzmiIzvestaj: function preuzmiIzvestaj() {
        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default'].ObrObrazac02ZdravstveniPregled.create({ errorHandlerController: this });

        model.getById('downloadIzvestaj', this.get('selectedRowObr2.idZdravstveniPregled'), this.get('login').getAccessToken(), 'GET', function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      obrisiPotpisanUput: function obrisiPotpisanUput() {
        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default'].ObrObrazac02ZdravstveniPregled.create({ errorHandlerController: this });
        model.getById('deletePotpisanUput', this.get('selectedRowObr2.idZdravstveniPregled'), this.get('login').getAccessToken(), 'POST', function (o) {
          $.Notify({
            caption: i18n.t("fileUpload.brisanjeHeader").string,
            content: i18n.t("fileUpload.brisanjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refreshObrazac02Modal');
        });
      },

      obrisiIzvestaj: function obrisiIzvestaj() {

        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default'].ObrObrazac02ZdravstveniPregled.create({ errorHandlerController: this });
        model.getById('deleteIzvestaj', this.get('selectedRowObr2.idZdravstveniPregled'), this.get('login').getAccessToken(), 'POST', function (o) {
          $.Notify({
            caption: i18n.t("fileUpload.brisanjeHeader").string,
            content: i18n.t("fileUpload.brisanjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refreshObrazac02Modal');
        });
      },

      novUputPrethodni: function novUputPrethodni() {
        this.set('fgUnos', true);
        this.set('fgPeriodicni', false);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelUput');
        }, 100);
      },

      novUputPeriodicni: function novUputPeriodicni() {
        this.set('fgUnos', true);
        this.set('fgPeriodicni', true);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelUput');
        }, 100);
      },

      pregledIIzmena: function pregledIIzmena() {
        this.set('fgUnos', false);

        if (this.get('selectedRowObr2.sifVrstaLekarskogPregleda.idVrstaLekarskogPregleda') === 1) {
          this.set('fgPeriodicni', false);
        } else {
          this.set('fgPeriodicni', true);
        }

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelUput');
        }, 100);
      },

      initEditPregled: function initEditPregled() {
        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelPregled');
        }, 100);
      },

      initDelete: function initDelete() {
        //prepare and show yes/no modal panel
        this.get("target").send('openYesNoModal', 'idYesNoDialog', 'obrObrazac02.brisanjeHeader', 'obrObrazac02.brisanjePitanje', this, 'deleteConfirm');
      }

    },

    deleteConfirm: function deleteConfirm(self) {
      var loginService = self.get('login');
      var outter = self;

      var obrObrazac02ZdravstveniPregledModel = Models['default'].ObrObrazac02ZdravstveniPregled.create({ errorHandlerController: this });
      obrObrazac02ZdravstveniPregledModel['delete'](self.get('idRowSelectedObr2'), loginService.getAccessToken(), function () {
        //call ws to get new results, without deleted one
        outter.toggleProperty('refreshObrazac02Modal');

        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac02.brisanjeHeader', 'obrObrazac02.dodavanjeUspesno', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac02.brisanjeHeader', e.responseJSON.messages[0], 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    openPanel: Ember['default'].observer('modalPanelShowEdit', function () {
      this.toggleProperty('refreshObrazac02Modal');

      this.set('idSelectedZaposleni', this.get('selektovaniZaposleni.idZaposleni'));
      this.set('idRowSelectedObr2', 0);

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac02Modal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshObrazac02Modal', function () {
      this.toggleProperty('refreshWoResetSelection');
    }),

    closePanel: function closePanel() {
      $('.obrObrazac02Modal').data('dialog').close();
    },

    isRowPreglediSelected: Ember['default'].computed('idRowSelectedObr2', function () {
      return this.get('idRowSelectedObr2') !== 0;
    }),

    tableColumnsPregledi: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'sifVrstaLekarskogPregleda.naziv',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.vrstaPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumUputa',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.datumUputa'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPregleda',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.datumPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledecegPregleda',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.datumSledecegPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'brojLekarskogIzvestaja',
        savedWidth: 100,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.brojLekarskogIzvestaja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'sifZdravstvenaSposobnostByIdZdravstvenaSposobnost.naziv',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.ocenaSposobnosti'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'preduzeteMere',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.mere'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })
  });

});