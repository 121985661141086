define('zaris-frontend/components/zaris-tree-table-row', ['exports', 'ember-table/components/table-row'], function (exports, TableRow) {

  'use strict';

  exports['default'] = TableRow['default'].extend({
    templateName: 'zaris-tree-table-row',
    actions: {
      toggleCollapse: function toggleCollapse(row) {
        this.set('action', 'toggleCollapse');
        this.sendAction('action', row);
      }
    }
  });

});