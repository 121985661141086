define('zaris-frontend/components/input-validation', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    // ne sme da bude u div-u zbog prikazivanja error stanja
    tagName: "",

    // tip validacije - podrazumevano required
    // required, email, date, min, max, minlength, maxlength, number, jmbg
    // argument za validacije koje to zahtevaju se prosledjuje preko arg
    validType: "required",

    // podrazumevani input type - text
    type: "text",

    // textarea
    textarea: false,

    // showState
    showState: true,

    i18n: Ember['default'].inject.service('i18n'),

    // eksplicitna poruka za hint - ukoliko se ne prosledi, koristice se genericka
    hint: "",
    hintPosition: "right",
    placeholder: "",

    placeholderTranslated: Ember['default'].computed('placeholder', function () {
      var i18n = this.get('i18n');
      var placeholder = this.get('placeholder');
      return i18n.t(placeholder).string;
    }),

    hintMsg: Ember['default'].computed('arg', function () {
      var i18n = this.get('i18n');
      var hint = this.get('hint');
      var validType = this.get('validType');
      var arg = this.get('arg');

      // definisana je eksplicitna poruka
      if (hint !== "") {
        return i18n.t(hint).string;
      }

      // koristi se vec definisana genericka poruka u zavisnosti od tipa
      switch (validType) {
        case "required":
          return i18n.t("validationMsg.required").string;
        case "email":
          return i18n.t("validationMsg.email").string;
        case "date":
          return i18n.t("validationMsg.date").string;
        case "min":
          return i18n.t("validationMsg.min").string + arg;
        case "max":
          return i18n.t("validationMsg.max").string + arg;
        case "minlength":
          return i18n.t("validationMsg.minlength").string + arg + i18n.t("validationMsg.characters").string;
        case "maxlength":
          return i18n.t("validationMsg.maxlength").string + arg + i18n.t("validationMsg.characters").string;
        case "required_maxlength":
          return i18n.t("validationMsg.required_maxlength").string + arg + i18n.t("validationMsg.characters").string;
        case "number":
          return i18n.t("validationMsg.number").string;
        case "non_required_number":
          return i18n.t("validationMsg.number").string;
        case "jmbg":
          return i18n.t("validationMsg.jmbg").string;
        case "positive":
          return i18n.t("validationMsg.positive").string;
        case "year":
          return i18n.t("validationMsg.year").string;
        case "maticniBroj":
          return i18n.t("validationMsg.mb").string;
        default:
          return i18n.t("validationMsg.undefined").string;
      }
    })
  });

});