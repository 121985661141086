define('zaris-frontend/components/obr-obrazac06a-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0;
    }),

    didInsertElement: function didInsertElement() {
      this.$('#obrObrazac06aGrupnoDodavanjeObukeModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      initGrupnoDodavanjePregleda: function initGrupnoDodavanjePregleda() {
        this.toggleProperty('myModalPanelGrupnoDodavanjeObukeShow');
      }

    }
  });

});