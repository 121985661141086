define('zaris-frontend/components/obr-obrazac03-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {

    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'zarisWindowFullSize', 'obrObrazac03Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-top'],
    "data-role": "dialog",
    "data-drag-element": ".window-caption",
    "data-width": "90%",
    "data-top": "2rem",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    idRadnoMestoStruktura: null,

    idLiceZaBzr: null,

    firstIdLiceZaBzr: null,

    liceZaBzr: {},

    fgRizicnoPoruka: "",
    fgPovredjeniObukePoruka: "",

    didInsertElement: function didInsertElement() {
      this.$('.sifDatatableLovModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    radnoMestoStrukturaImaLzo: Ember['default'].observer('idRadnoMestoStruktura', function () {

      var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');
      var isNew = this.get('isNew');
      var loginService = this.get('login');
      var outter = this;
      var i18n = this.get('i18n');

      if (isNew) {
        if (idRadnoMestoStruktura) {
          var modelRmsLzo = Models['default']["SifRadnoMestoStrLzo"].create({ errorHandlerController: this });
          modelRmsLzo.getById("rmsImaLzo", idRadnoMestoStruktura, loginService.getAccessToken(), 'GET', function (imaLzo) {
            // postavi true/false
            outter.set('modelObject.fgPropisanaSredstvaZaRad', imaLzo);
            outter.set('fgPropisanaSredstvaZaRadPoruka', imaLzo ? i18n.t("obrObrazac03.rmsImaLzo").string : i18n.t("obrObrazac03.rmsNemaLzo").string);
          });
        } else {
          // obrisi
          outter.set('modelObject.fgPropisanaSredstvaZaRad', undefined);
          outter.set('fgPropisanaSredstvaZaRadPoruka', "");
        }
      } // else nista
    }),

    povredjeniImaObukePreglede: Ember['default'].observer('objPovredjeni', 'idRadnoMestoStruktura', function () {
      var idPovredjeni = this.get('objPovredjeni').id;
      var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');
      var isNew = this.get('isNew');
      var loginService = this.get('login');
      var outter = this;
      var i18n = this.get('i18n');

      if (isNew && idPovredjeni && idRadnoMestoStruktura) {
        var modelLicaObuke = Models['default']["SifLicaObuke"].create({ errorHandlerController: this });
        modelLicaObuke.getById("povredjeniImaObukeByRms/" + idPovredjeni, idRadnoMestoStruktura, loginService.getAccessToken(), 'GET', function (booleanResponse) {
          // mora jos jedna provera jer je callback
          if (outter.get('idRadnoMestoStruktura')) {
            outter.set('modelObject.fgOsposobljenZaBezbRad', booleanResponse);
            outter.set('fgPovredjeniObukePoruka', booleanResponse ? i18n.t("obrObrazac03.povredjeniImaSveObuke").string : i18n.t("obrObrazac03.povredjeniNemaSveObuke").string);
          }
        });
        /*
        var modelLicaPregledi = Models["SifLicaPregledi"].create({errorHandlerController: this});
        modelLicaPregledi.getById("povredjeniImaPregledeByRms/" + idPovredjeni, idRadnoMestoStruktura, loginService.getAccessToken(), 'GET',
        function(booleanResponse){
          if (outter.get('idRadnoMestoStruktura')) {
            outter.set('modelObject.fgRanijiPregledi', booleanResponse);
            outter.set('fgPovredjeniPreglediPoruka', booleanResponse ? i18n.t("obrObrazac03.povredjeniImaSvePreglede").string
                                                                                : i18n.t("obrObrazac03.povredjeniNemaSvePreglede").string);
          }
        });
        */
      } else {
          this.set('fgPovredjeniObukePoruka', "");
          //  this.set('fgPovredjeniPreglediPoruka', "");
          if (isNew) {
            this.set('modelObject.fgOsposobljenZaBezbRad', undefined);
            //  this.set('modelObject.fgRanijiPregledi', undefined);
          }
        }
    }),

    promenaLiceZaBzr: Ember['default'].observer('idLiceZaBzr', function () {
      // promenilo se lice za bzr - ako nije null dovuci ceo objekat
      var idLiceZaBzr = this.get('idLiceZaBzr');
      var self = this;
      if (idLiceZaBzr !== null) {
        var model = Models['default'].SifLica.create({ errorHandlerController: this });
        model.find(idLiceZaBzr, this.get('login').getAccessToken(), function (entity) {
          entity.ime = entity.ime + ' ' + entity.prezime;
          self.set('liceZaBzr', entity);
        });
      }
    }),

    fgRanijaSlicnaPovredaObserver: Ember['default'].observer('modelObject.fgRanijaSlicnaPovreda', function () {
      if (!this.get('modelObject.fgRanijaSlicnaPovreda')) this.set('modelObject.ranijaSlicnaPovredaKada', null);
    }),

    povredjeniRadnaMesta: Ember['default'].computed('objPovredjeni', 'modelObject', function () {
      var idPovredjeni = this.get('objPovredjeni.id');
      var loginService = this.get('login');
      var radnaMesta = Em.A();
      var i18n = this.get('i18n');
      var url;
      if (idPovredjeni != undefined && idPovredjeni != null) {
        url = conf['default'].serverBaseUrl + "/sifRadnoMestoStruktura/radnaMestaZaLice?idPravnoLice=" + localStorage.idPravnoLice + "&idLice=" + idPovredjeni + "&access_token=" + loginService.getAccessToken();

        $.ajax({
          url: url,
          type: 'GET',
          accepts: 'application/json',
          crossDomain: true,
          async: false,
          headers: conf['default'].corsHeaders,
          success: function success(data) {
            data.forEach(function (entity) {
              radnaMesta.pushObject(entity);
            });
          },
          error: function error(e) {
            console.log('DEBUG: GET Failed', e);
          }
        });

        // dovlaci se lista mogucih rms za to lice
        // ako nije novi (izmena)  i ima modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura, treba da se postavi na njegovo rms
        // ako je novi (kreiranje) - onda se samo postavi na prvi iz liste

        var idRms = this.get('modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura');
        this.set('idRadnoMestoStruktura', null);

        if (!this.get('isNew') && idRms != undefined && idRms != null) {
          this.set('idRadnoMestoStruktura', idRms);
        } else {
          var radnoMesto = radnaMesta.get('firstObject');
          if (radnoMesto != undefined && radnoMesto != null) {
            this.set('idRadnoMestoStruktura', radnoMesto.idRadnoMestoStruktura);
            // inicijalno fgRizicno
            this.set('modelObject.fgPovredaNaRmSaRizikom', radnoMesto.fgRizicno);
            // postavi obavestenje
            if (radnoMesto.fgRizicno) {
              this.set('fgRizicnoPoruka', i18n.t("obrObrazac03.radnoMestoPovecaniRizik").string);
            } else if (!radnoMesto.fgRizicno) {
              this.set('fgRizicnoPoruka', i18n.t("obrObrazac03.radnoMestoNijePovecaniRizik").string);
            }
          }
        }

        console.log(this.get('idRadnoMestoStruktura'));

        return radnaMesta;
      }
    }),

    rukovodiocRadnaMesta: Ember['default'].computed('objRukovodioc', 'modelObject', function () {
      var idRukovodioc = this.get('objRukovodioc.id');
      var loginService = this.get('login');
      var radnaMesta = "";

      var url;
      if (idRukovodioc != undefined && idRukovodioc != null) {
        url = conf['default'].serverBaseUrl + "/sifRadnoMestoStruktura/radnaMestaZaLice?idPravnoLice=" + localStorage.idPravnoLice + "&idLice=" + idRukovodioc + "&access_token=" + loginService.getAccessToken();

        $.ajax({
          url: url,
          type: 'GET',
          accepts: 'application/json',
          crossDomain: true,
          async: false,
          headers: conf['default'].corsHeaders,
          success: function success(data) {
            data.forEach(function (entity, i) {
              radnaMesta += entity.radnoMestoNaziv + '[' + entity.organizacionaStruktura + ']';
              if (i < data.length - 1) radnaMesta += ', ';
            });
          },
          error: function error(e) {
            console.log('DEBUG: GET Failed', e);
          }
        });

        return radnaMesta;
      }
    }),

    formatedDatumPovrede: Ember['default'].computed('modelObject.vremePovrede', function () {
      var datumPovrede = this.get('modelObject.vremePovrede');
      if (datumPovrede) {
        return moment(datumPovrede).format('dddd');
      }
    }),

    tableColumns: Ember['default'].computed(function () {

      var self = this;

      var idColumn = ColumnDefinition['default'].create({
        key: 'idLice',
        savedWidth: 50,
        supportSort: true,
        headerCellName: 'ID',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var imePrezimeColumn = ColumnDefinition['default'].create({
        key: 'ime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Ime i prezime',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var imePrezime = row.get("ime") + " " + row.get('prezime');
          var sufiks = self.ohsNameSuffix(row.get("liceZaBzr"));
          return imePrezime + sufiks;
        }
      });
      var maticniBrojColumn = ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'JMBG',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      var datumRodjenjaColumn = ColumnDefinition['default'].create({
        key: 'datumRodjenja',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Datum rodjenja',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      });
      var polColumn = ColumnDefinition['default'].create({
        key: 'pol',
        savedWidth: 100,
        supportSort: true,
        headerCellName: 'Pol',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.genderValueNames(row.get(this.key).naziv);
        }
      });
      var adresaColumn = ColumnDefinition['default'].create({
        key: 'adresa',
        savedWidth: 200,
        supportSort: true,
        headerCellName: 'Adresa',
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      });
      return [idColumn, imePrezimeColumn, maticniBrojColumn, datumRodjenjaColumn, polColumn, adresaColumn];
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      izborRadnogMestaUStrukturi: function izborRadnogMestaUStrukturi(selection) {
        if (this.get('isNew')) {
          var i18n = this.get('i18n');
          var radnaMesta = this.get('povredjeniRadnaMesta');
          // iz radnih mesta nalazimo ono sa id = selection
          var radnoMesto = radnaMesta.findBy('idRadnoMestoStruktura', selection);

          if (radnoMesto) {
            // inicijalizujemo fgRizicno
            this.set('modelObject.fgPovredaNaRmSaRizikom', radnoMesto.fgRizicno);
            if (radnoMesto.fgRizicno) {
              this.set('fgRizicnoPoruka', i18n.t("obrObrazac03.radnoMestoPovecaniRizik").string);
            } else if (!radnoMesto.fgRizicno) {
              this.set('fgRizicnoPoruka', i18n.t("obrObrazac03.radnoMestoNijePovecaniRizik").string);
            }
          }
        }
      },
      create: function create() {

        var outter = this;
        var i18n = this.get('i18n');

        var objPovredjeni = this.get('objPovredjeni');
        var objRukovodioc = this.get('objRukovodioc');
        var objOcevidac = this.get('objOcevidac');
        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        var mestoPovrede = this.get('modelObject.sifMestoPovrede.idMestoPovrede');
        var uzrokPovrede = this.get('modelObject.sifUzrokPovrede.idUzrokPovrede');
        var izvorPovrede = this.get('modelObject.sifIzvorPovrede.idIzvorPovrede');
        var vrstaStepenSsPovredjeni = this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs');
        var vrstaStepenSsPropisano = this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs');
        var statusZaposlenog = this.get('modelObject.sifStatusZaposlenog.idStatusZaposlenog');

        var modelObject = this.get('modelObject');
        if (!objPovredjeni.naziv || !objRukovodioc.naziv || !idRadnoMestoStruktura || modelObject.vremePovrede === undefined || modelObject.vremePovrede === null || modelObject.vremePovrede === "" || modelObject.dobaDanaSat === undefined || modelObject.dobaDanaSat === null || modelObject.dobaDanaSat === "" || modelObject.radniSatOdPocetkaRada === undefined || modelObject.radniSatOdPocetkaRada === null || modelObject.radniSatOdPocetkaRada === "" || modelObject.ukupanBrojPovredjenih === undefined || modelObject.ukupanBrojPovredjenih === null || modelObject.ukupanBrojPovredjenih === "" || modelObject.ukupanBrojPoginulih === undefined || modelObject.ukupanBrojPoginulih === null || modelObject.ukupanBrojPoginulih === "" || modelObject.fgRanijaSlicnaPovreda === undefined || modelObject.fgRanijaSlicnaPovreda === null || modelObject.fgPovredaNaRmSaRizikom === undefined || modelObject.fgPovredaNaRmSaRizikom === null || modelObject.fgPropisaniUslovi === undefined || modelObject.fgPropisaniUslovi === null || modelObject.fgRanijiPregledi === undefined || modelObject.fgRanijiPregledi === null || modelObject.fgOsposobljenZaBezbRad === undefined || modelObject.fgOsposobljenZaBezbRad === null || modelObject.fgPropisanaSredstvaZaRad === undefined || modelObject.fgPropisanaSredstvaZaRad === null || modelObject.fgObezbedjanaSredstvaZaRad === undefined || modelObject.fgObezbedjanaSredstvaZaRad === null || modelObject.fgKoriscenaSredstvaZaRad === undefined || modelObject.fgKoriscenaSredstvaZaRad === null || !modelObject.sifIzvorPovrede.idIzvorPovrede || !modelObject.sifUzrokPovrede.idUzrokPovrede) {
          $.Notify({
            caption: i18n.t('obrObrazac03.povredniList.validationCaption').string,
            content: i18n.t('obrObrazac03.povredniList.validationContent').string,
            keepOpen: false,
            type: 'alert'
          });
          return;
        }

        // povredjeni
        this.set('modelObject.sifLicaByIdLicePovredjeni', {});
        this.set('modelObject.sifLicaByIdLicePovredjeni.idLice', objPovredjeni.selectedRow.idLice);

        // rukovodioc
        if (objRukovodioc.selectedRow.idLice === undefined || objRukovodioc.selectedRow.idLice === null) {
          this.set('modelObject.sifMestoPovrede', null);
        } else {
          this.set('modelObject.sifLicaByIdLiceRukovodioc', {});
          this.set('modelObject.sifLicaByIdLiceRukovodioc.idLice', objRukovodioc.selectedRow.idLice);
        }

        // ocevidac
        this.set('modelObject.ocevidacIme', objOcevidac.naziv);
        this.set('modelObject.ocevidacJmbg', objOcevidac.selectedRow.maticniBroj);
        this.set('modelObject.ocevidacAdresa', objOcevidac.selectedRow.adresa);
        this.set('modelObject.ocevidacMesto', objOcevidac.selectedRow.mesto);
        this.set('modelObject.ocevidacDrzava', objOcevidac.selectedRow.drzava);

        if (idRadnoMestoStruktura === undefined || idRadnoMestoStruktura === null) {
          this.set('modelObject.sifRadnoMestoStruktura', null);
        } else {
          this.set('modelObject.sifRadnoMestoStruktura', {});
          this.set('modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura', this.get('idRadnoMestoStruktura'));
        }

        this.set('modelObject.sifLicaByIdLiceBzr', {});
        this.set('modelObject.sifLicaByIdLiceBzr.idLice', this.get('idLiceZaBzr'));

        if (mestoPovrede === undefined || mestoPovrede === null) {
          this.set('modelObject.sifMestoPovrede', null);
        }

        if (uzrokPovrede === undefined || uzrokPovrede === null) {
          this.set('modelObject.sifUzrokPovrede', null);
        }

        if (izvorPovrede === undefined || izvorPovrede === null) {
          this.set('modelObject.sifIzvorPovrede', null);
        }

        if (vrstaStepenSsPovredjeni === undefined || vrstaStepenSsPovredjeni === null) {
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs', null);
        }

        if (vrstaStepenSsPropisano === undefined || vrstaStepenSsPropisano === null) {
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani', null);
        }

        if (statusZaposlenog === undefined || statusZaposlenog === null) {
          this.set('modelObject.sifStatusZaposlenog', null);
        }

        var pdf = new jsPDF('p', 'pt', 'a4');

        $('#obrObrazac03Report').removeClass('reportPanelHidden');

        pdf.addHTML($('#obrObrazac03Report #div-forma table').first(), function () {
          $('#obrObrazac03Report').addClass('reportPanelHidden');

          var string = pdf.output('datauristring');

          var obj = {};
          obj.obrObrazac03PovredniList = outter.get('modelObject');
          obj.fileContent = string;

          var obrObrazac03Model = Models['default'].ObrObrazac03.create({ errorHandlerController: outter });
          obrObrazac03Model.create(obj, outter.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t('commonPanel.dodavanjeHeader').string,
              content: i18n.t('commonPanel.dodavanjeUspesno').string,
              keepOpen: false,
              type: 'success'
            });
            outter.closePanel();
            outter.toggleProperty('refresh');
          });
        });

        window.scrollTo(0, 0);
      },
      update: function update() {

        var outter = this;
        var i18n = this.get('i18n');

        var objPovredjeni = this.get('objPovredjeni');
        var objRukovodioc = this.get('objRukovodioc');
        var objOcevidac = this.get('objOcevidac');

        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        var mestoPovrede = this.get('modelObject.sifMestoPovrede.idMestoPovrede');
        var uzrokPovrede = this.get('modelObject.sifUzrokPovrede.idUzrokPovrede');
        var izvorPovrede = this.get('modelObject.sifIzvorPovrede.idIzvorPovrede');
        var vrstaStepenSsPovredjeni = this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs');
        var vrstaStepenSsPropisano = this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs');
        var statusZaposlenog = this.get('modelObject.sifStatusZaposlenog.idStatusZaposlenog');

        var modelObject = this.get('modelObject');

        if (!objPovredjeni.naziv || !objRukovodioc.naziv || !idRadnoMestoStruktura || modelObject.vremePovrede === undefined || modelObject.vremePovrede === null || modelObject.vremePovrede === "" || modelObject.dobaDanaSat === undefined || modelObject.dobaDanaSat === null || modelObject.dobaDanaSat === "" || modelObject.radniSatOdPocetkaRada === undefined || modelObject.radniSatOdPocetkaRada === null || modelObject.radniSatOdPocetkaRada === "" || modelObject.ukupanBrojPovredjenih === undefined || modelObject.ukupanBrojPovredjenih === null || modelObject.ukupanBrojPovredjenih === "" || modelObject.ukupanBrojPoginulih === undefined || modelObject.ukupanBrojPoginulih === null || modelObject.ukupanBrojPoginulih === "" || modelObject.fgRanijaSlicnaPovreda === undefined || modelObject.fgRanijaSlicnaPovreda === null || modelObject.fgPovredaNaRmSaRizikom === undefined || modelObject.fgPovredaNaRmSaRizikom === null || modelObject.fgPropisaniUslovi === undefined || modelObject.fgPropisaniUslovi === null || modelObject.fgRanijiPregledi === undefined || modelObject.fgRanijiPregledi === null || modelObject.fgOsposobljenZaBezbRad === undefined || modelObject.fgOsposobljenZaBezbRad === null || modelObject.fgPropisanaSredstvaZaRad === undefined || modelObject.fgPropisanaSredstvaZaRad === null || modelObject.fgObezbedjanaSredstvaZaRad === undefined || modelObject.fgObezbedjanaSredstvaZaRad === null || modelObject.fgKoriscenaSredstvaZaRad === undefined || modelObject.fgKoriscenaSredstvaZaRad === null || !modelObject.sifIzvorPovrede.idIzvorPovrede || !modelObject.sifUzrokPovrede.idUzrokPovrede) {
          $.Notify({
            caption: i18n.t('obrObrazac03.povredniList.validationCaption').string,
            content: i18n.t('obrObrazac03.povredniList.validationContent').string,
            keepOpen: false,
            type: 'alert'
          });
          return;
        }

        // povredjeni
        this.set('modelObject.sifLicaByIdLicePovredjeni', {});
        this.set('modelObject.sifLicaByIdLicePovredjeni.idLice', objPovredjeni.id);

        // rukovodioc
        if (objRukovodioc.id === undefined || objRukovodioc.id === null) {
          this.set('modelObject.sifMestoPovrede', null);
        } else {
          this.set('modelObject.sifLicaByIdLiceRukovodioc', {});
          this.set('modelObject.sifLicaByIdLiceRukovodioc.idLice', objRukovodioc.id);
        }

        // ocevidac
        this.set('modelObject.ocevidacIme', objOcevidac.naziv);
        this.set('modelObject.ocevidacJmbg', objOcevidac.selectedRow.maticniBroj);
        this.set('modelObject.ocevidacAdresa', objOcevidac.selectedRow.adresa);
        this.set('modelObject.ocevidacMesto', objOcevidac.selectedRow.mesto);
        this.set('modelObject.ocevidacDrzava', objOcevidac.selectedRow.drzava);

        if (idRadnoMestoStruktura === undefined || idRadnoMestoStruktura === null) {
          this.set('modelObject.sifRadnoMestoStruktura', null);
        } else {
          this.set('modelObject.sifRadnoMestoStruktura', {});
          this.set('modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura', this.get('idRadnoMestoStruktura'));
        }

        this.set('modelObject.sifLicaByIdLiceBzr', {});
        this.set('modelObject.sifLicaByIdLiceBzr.idLice', this.get('idLiceZaBzr'));

        if (mestoPovrede === undefined || mestoPovrede === null) {
          this.set('modelObject.sifMestoPovrede', null);
        }

        if (uzrokPovrede === undefined || uzrokPovrede === null) {
          this.set('modelObject.sifUzrokPovrede', null);
        }

        if (izvorPovrede === undefined || izvorPovrede === null) {
          this.set('modelObject.sifIzvorPovrede', null);
        }

        if (vrstaStepenSsPovredjeni === undefined || vrstaStepenSsPovredjeni === null) {
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs', null);
        }

        if (vrstaStepenSsPropisano === undefined || vrstaStepenSsPropisano === null) {
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani', null);
        }

        if (statusZaposlenog === undefined || statusZaposlenog === null) {
          this.set('modelObject.sifStatusZaposlenog', null);
        }

        var pdf = new jsPDF('p', 'pt', 'a4');

        $('#obrObrazac03Report').removeClass('reportPanelHidden');

        pdf.addHTML($('#obrObrazac03Report #div-forma table').first(), function () {
          $('#obrObrazac03Report').addClass('reportPanelHidden');

          var string = pdf.output('datauristring');

          var obj = {};
          obj.obrObrazac03PovredniList = outter.get('modelObject');
          obj.fileContent = string;

          $('.preview-pane').attr('src', string);

          var obrObrazac03Model = Models['default'].ObrObrazac03.create({ errorHandlerController: outter });
          obrObrazac03Model.update(obj, outter.get('login').getAccessToken(), function () {
            $.Notify({
              caption: i18n.t("commonPanel.azuriranjeHeader").string,
              content: i18n.t("commonPanel.azuriranjeUspesno").string,
              keepOpen: false,
              type: 'success'
            });
            outter.closePanel();
            outter.toggleProperty('refresh');
          });
        });

        window.scrollTo(0, 0);
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.set('fgRizicnoPoruka', "");
      this.set('fgPovredjeniObukePoruka', "");
      //  this.set('fgPovredjeniPreglediPoruka', "");
      this.set('fgPropisanaSredstvaZaRadPoruka', "");

      $(".input-number").mask("9?99999", { placeholder: " " });
      $(".input-hour").mask("9?9", { placeholder: " " });

      // podesavanje objekta
      var isNew = this.get('isNew');
      var outter = this;

      var sysPravnaLicaModal = Models['default'].XsysPravnoLice.create({ errorHandlerController: this });
      var pravnoLice = sysPravnaLicaModal.findGetResult(localStorage.idPravnoLice, this.get('login').getAccessToken());
      outter.set('pravnoLice', pravnoLice);

      sysPravnaLicaModal.getResultById('getBrojZaposlenih', localStorage.idPravnoLice, this.get('login').getAccessToken(), 'GET', function (data) {
        outter.set('brojZaposlenih', data);
      });

      if (isNew) {
        // povredjeni
        this.set('objPovredjeni.id', null);
        this.set('objPovredjeni.naziv', "");
        this.set('objPovredjeni.selectedRow', {});

        // rukovodioc
        this.set('objRukovodioc.id', null);
        this.set('objRukovodioc.naziv', "");
        this.set('objRukovodioc.selectedRow', {});

        // ocevidac
        this.set('objOcevidac.id', null);
        this.set('objOcevidac.naziv', "");
        this.set('objOcevidac.selectedRow', {});

        // lice za bzr
        this.set('modelObject.sifLicaByIdLiceBzr', {});
        this.set('modelObject.sifLicaByIdLiceBzr', null);

        this.set('idRadnoMestoStruktura', null);

        // postavi na prvi
        this.set('idLiceZaBzr', this.get('firstIdLiceZaBzr'));

        this.set('modelObject.sifMestoPovrede', {});
        this.set('modelObject.sifMestoPovrede.idMestoPovrede', null);

        this.set('modelObject.sifUzrokPovrede', {});
        this.set('modelObject.sifUzrokPovrede.idUzrokPovrede', null);

        this.set('modelObject.sifIzvorPovrede', {});
        this.set('modelObject.sifIzvorPovrede.idIzvorPovrede', null);

        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs', {});
        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs', null);

        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani', {});
        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs', null);

        this.set('modelObject.sifStatusZaposlenog', {});
        this.set('modelObject.sifStatusZaposlenog.idStatusZaposlenog', null);
      } else {
        // treba da bude povezan sa modelObjectom

        this.set('objPovredjeni.id', this.get('modelObject.sifLicaByIdLicePovredjeni.idLice'));
        this.set('objPovredjeni.naziv', this.get('modelObject.sifLicaByIdLicePovredjeni.ime') + ' ' + this.get('modelObject.sifLicaByIdLicePovredjeni.prezime'));
        this.set('objPovredjeni.selectedRow', {});
        this.set('objPovredjeni.selectedRow.pol', this.get('modelObject.sifLicaByIdLicePovredjeni.pol'));
        this.set('objPovredjeni.selectedRow.datumRodjenja', this.get('modelObject.sifLicaByIdLicePovredjeni.datumRodjenja'));
        this.set('objPovredjeni.selectedRow.maticniBroj', this.get('modelObject.sifLicaByIdLicePovredjeni.maticniBroj'));
        this.set('objPovredjeni.selectedRow.adresa', this.get('modelObject.sifLicaByIdLicePovredjeni.adresa'));
        this.set('objPovredjeni.selectedRow.mesto', this.get('modelObject.sifLicaByIdLicePovredjeni.mesto'));
        this.set('objPovredjeni.selectedRow.drzava', this.get('modelObject.sifLicaByIdLicePovredjeni.drzava'));

        this.set('objRukovodioc.id', this.get('modelObject.sifLicaByIdLiceRukovodioc.idLice'));
        this.set('objRukovodioc.naziv', this.get('modelObject.sifLicaByIdLiceRukovodioc.ime') + ' ' + this.get('modelObject.sifLicaByIdLiceRukovodioc.prezime'));
        this.set('objRukovodioc.selectedRow', {});
        this.set('objRukovodioc.selectedRow.maticniBroj', this.get('modelObject.sifLicaByIdLiceRukovodioc.maticniBroj'));
        this.set('objRukovodioc.selectedRow.adresa', this.get('modelObject.sifLicaByIdLiceRukovodioc.adresa'));
        this.set('objRukovodioc.selectedRow.mesto', this.get('modelObject.sifLicaByIdLiceRukovodioc.mesto'));
        this.set('objRukovodioc.selectedRow.drzava', this.get('modelObject.sifLicaByIdLiceRukovodioc.drzava'));

        this.set('objOcevidac.id', null);
        this.set('objOcevidac.naziv', this.get('modelObject.ocevidacIme'));
        this.set('objOcevidac.selectedRow', {});
        this.set('objOcevidac.selectedRow.maticniBroj', this.get('modelObject.ocevidacJmbg'));
        this.set('objOcevidac.selectedRow.adresa', this.get('modelObject.sifLicaByIdLiceOcevidac.adresa'));
        this.set('objOcevidac.selectedRow.mesto', this.get('modelObject.sifLicaByIdLiceOcevidac.mesto'));
        this.set('objOcevidac.selectedRow.drzava', this.get('modelObject.sifLicaByIdLiceOcevidac.drzava'));

        this.set('idRadnoMestoStruktura', this.get('modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura'));

        // postavi na njegov ako ima
        if (this.get('modelObject.sifLicaByIdLiceBzr.idLice') !== undefined && this.get('modelObject.sifLicaByIdLiceBzr.idLice') !== null) {
          this.set('idLiceZaBzr', this.get('modelObject.sifLicaByIdLiceBzr.idLice'));
        } else {
          // ako nema - postavi na prvi
          this.set('idLiceZaBzr', this.get('firstIdLiceZaBzr'));
        }

        if (this.get('modelObject.sifMestoPovrede.idMestoPovrede') === undefined || this.get('modelObject.sifMestoPovrede.idMestoPovrede') === null) {
          this.set('modelObject.sifMestoPovrede', {});
          this.set('modelObject.sifMestoPovrede.idMestoPovrede', null);
        }

        if (this.get('modelObject.sifUzrokPovrede.idUzrokPovrede') === undefined || this.get('modelObject.sifUzrokPovrede.idUzrokPovrede') === null) {
          this.set('modelObject.sifUzrokPovrede', {});
          this.set('modelObject.sifUzrokPovrede.idUzrokPovrede', null);
        }

        if (this.get('modelObject.sifIzvorPovrede.idIzvorPovrede') === undefined || this.get('modelObject.sifIzvorPovrede.idIzvorPovrede') === null) {
          this.set('modelObject.sifIzvorPovrede', {});
          this.set('modelObject.sifIzvorPovrede.idIzvorPovrede', null);
        }

        if (this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs') === undefined || this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs') === null) {
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs', {});
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs', null);
        }

        if (this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs') === undefined || this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs') === null) {
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani', {});
          this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs', null);
        }

        if (this.get('modelObject.sifStatusZaposlenog.idStatusZaposlenog') === undefined || this.get('modelObject.sifStatusZaposlenog.idStatusZaposlenog') === null) {
          this.set('modelObject.sifStatusZaposlenog', {});
          this.set('modelObject.sifStatusZaposlenog.idStatusZaposlenog', null);
        }
      }

      Ember['default'].showModalPanel('.obrObrazac03Modal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      window.scrollTo(0, 0);
      $('.obrObrazac03Modal').data('dialog').close();
    }
  });

});