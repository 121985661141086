define('zaris-frontend/templates/components/select-radno-mesto-lov-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/select-radno-mesto-lov-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","select-datatable-lov-modal",[],["id",["subexpr","@mut",[["get","idModalPanel",["loc",[null,[3,9],[3,21]]]]],[],[]],"domainName","SifRadnoMesto","idModalPanel",["subexpr","@mut",[["get","idModalPanel",["loc",[null,[5,19],[5,31]]]]],[],[]],"target",["subexpr","@mut",[["get","this",["loc",[null,[6,11],[6,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[7,10],[7,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[8,16],[8,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[9,12],[9,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","modalPanelShow",["loc",[null,[10,19],[10,33]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[11,16],[11,27]]]]],[],[]],"obj",["subexpr","@mut",[["get","obj",["loc",[null,[12,8],[12,11]]]]],[],[]],"displayName","app.menu.radna_mesta","tableColumns",["subexpr","@mut",[["get","radnoMestoLovColumns",["loc",[null,[14,19],[14,39]]]]],[],[]],"objNameArray","naziv","objId","idRadnoMesto"],["loc",[null,[2,0],[17,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});