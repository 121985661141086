define('zaris-frontend/templates/components/obr-obrazac06-obuka-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 121,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/obr-obrazac06-obuka-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        dom.setAttribute(el1,"style","font-size: 0.8em;");
        dom.setAttribute(el1,"class","mediumForm");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panelLeft");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-panel-content resetMarginTop ui equal width grid");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-panel-content clearFloat resetMarginTop ui equal width grid");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-panel-content resetMarginTop ui equal width grid");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-panel-content resetMarginTop ui equal width grid");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-panel-content resetMarginTop ui equal width grid");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","column");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","input-control text full-size");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panelRight");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-panel-content resetMarginTop ui equal width grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","column");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button success");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-checkmark");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [3]);
        var element8 = dom.childAt(element5, [3]);
        var element9 = dom.childAt(element5, [5]);
        var element10 = dom.childAt(element5, [7]);
        var element11 = dom.childAt(element3, [5, 1]);
        var element12 = dom.childAt(element3, [7]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3]);
        var element15 = dom.childAt(element3, [9]);
        var element16 = dom.childAt(element15, [1]);
        var element17 = dom.childAt(element15, [3]);
        var element18 = dom.childAt(element2, [3, 1, 1]);
        var element19 = dom.childAt(element2, [8]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element19, [3]);
        var morphs = new Array(29);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        morphs[5] = dom.createMorphAt(element7,1,1);
        morphs[6] = dom.createMorphAt(element7,3,3);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element9, [3]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element10, [3]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element11, [3]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
        morphs[18] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
        morphs[20] = dom.createMorphAt(dom.childAt(element16, [3]),1,1);
        morphs[21] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
        morphs[22] = dom.createMorphAt(dom.childAt(element17, [3]),1,1);
        morphs[23] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
        morphs[24] = dom.createMorphAt(element18,3,3);
        morphs[25] = dom.createElementMorph(element20);
        morphs[26] = dom.createMorphAt(element20,2,2);
        morphs[27] = dom.createElementMorph(element21);
        morphs[28] = dom.createMorphAt(element21,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["obrObrazac06.obuka"],[],["loc",[null,[6,10],[6,36]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","t",["obrObrazac06.opisPoslovaNaRadnomMestu"],[],["loc",[null,[18,15],[18,60]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.opisPosla",["loc",[null,[20,29],[20,50]]]]],[],[]]],["loc",[null,[20,12],[20,53]]]],
        ["inline","t",["obrObrazac06.teoretskaObuka"],[],["loc",[null,[27,15],[27,50]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datumTeorijskogOsposobljavanja",["loc",[null,[29,46],[29,88]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[29,12],[29,110]]]],
        ["inline","input-validation",[],["type","hidden","validType","required","arg","8","value",["subexpr","@mut",[["get","modelObject.datumTeorijskogOsposobljavanja",["loc",[null,[30,80],[30,122]]]]],[],[]]],["loc",[null,[30,12],[30,125]]]],
        ["inline","t",["obrObrazac06.prakticnaObuka"],[],["loc",[null,[34,15],[34,50]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datumPrakticnogOsposobljavanja",["loc",[null,[36,46],[36,88]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[36,12],[36,110]]]],
        ["inline","t",["obrObrazac06.teoretskaProvera"],[],["loc",[null,[40,15],[40,52]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datumTeorijskeProvere",["loc",[null,[42,46],[42,79]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[42,12],[42,101]]]],
        ["inline","t",["obrObrazac06.prakticnaProvera"],[],["loc",[null,[46,15],[46,52]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datumPrakticneProvere",["loc",[null,[48,46],[48,79]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[48,12],[48,101]]]],
        ["inline","t",["obrObrazac06.rizici"],[],["loc",[null,[55,15],[55,42]]]],
        ["inline","textarea",[],["readonly",true,"value",["subexpr","@mut",[["get","modelObject.rizici",["loc",[null,[57,43],[57,61]]]]],[],[]]],["loc",[null,[57,12],[57,64]]]],
        ["inline","t",["obrObrazac06.mere"],[],["loc",[null,[64,15],[64,40]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.mere",["loc",[null,[66,27],[66,43]]]]],[],[]]],["loc",[null,[66,10],[66,46]]]],
        ["inline","t",["obrObrazac06.obavestenja"],[],["loc",[null,[70,15],[70,47]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.obavestenja",["loc",[null,[72,27],[72,50]]]]],[],[]]],["loc",[null,[72,10],[72,53]]]],
        ["inline","t",["obrObrazac06.zaposleniPotpisaoObrazac"],[],["loc",[null,[79,15],[79,60]]]],
        ["inline","pikaday-input",[],["useUTC",true,"value",["subexpr","@mut",[["get","modelObject.datumPotpisaObrasca",["loc",[null,[81,46],[81,77]]]]],[],[]],"format","DD.MM.YYYY"],["loc",[null,[81,12],[81,99]]]],
        ["inline","t",["obrObrazac06.napomena"],[],["loc",[null,[85,15],[85,44]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","modelObject.napomena",["loc",[null,[87,27],[87,47]]]]],[],[]]],["loc",[null,[87,10],[87,50]]]],
        ["inline","t",["obrObrazac06.razloziOsposobljavanjaHeader"],[],["loc",[null,[97,15],[97,64]]]],
        ["inline","component",[["get","componentName",["loc",[null,[98,20],[98,33]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[99,17],[99,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableRazloziOsposobljavanjaColumns",["loc",[null,[100,18],[100,52]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",20,"domainName","SifRazlogOsposobljavanja","domainId","idRazlogOsposobljavanja","selectedRow",["subexpr","@mut",[["get","selectedRazlogOsposobljavanja",["loc",[null,[106,22],[106,51]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshRazlogOsposobljavanja",["loc",[null,[107,18],[107,46]]]]],[],[]],"renderControlButtons",false,"content",["subexpr","@mut",[["get","razlogOsposobljavanjaContent",["loc",[null,[109,18],[109,46]]]]],[],[]],"customId",["subexpr","@mut",[["get","idSelectedZaposleni",["loc",[null,[110,21],[110,40]]]]],[],[]]],["loc",[null,[98,8],[111,10]]]],
        ["element","action",[["subexpr","if",[["get","isNew",["loc",[null,[117,25],[117,30]]]],"create","update"],[],["loc",[null,[117,21],[117,49]]]]],[],["loc",[null,[117,12],[117,51]]]],
        ["inline","t",["app.common.create"],[],["loc",[null,[117,111],[117,136]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[118,12],[118,38]]]],
        ["inline","t",["app.common.cancel"],[],["loc",[null,[118,86],[118,111]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});