define('zaris-frontend/controllers/obr-obrazac09-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    // idOpasnaMaterija
    idOpremaZaRad: null,

    // sta je odabrano u radio grupi za ispitivanje opreme
    ispitivanjaOpreme: "ispitivanjaKojaTreba",

    // default - ispitivanja narednog meseca
    periodZaIspitivanja: 30,

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;
    },

    actions: {

      generate: function generate() {

        // prepakovanje objekta
        var pObj = {};

        var idOpremaZaRad = this.get('idOpremaZaRad');
        pObj.p_id_oprema_za_rad = idOpremaZaRad;

        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        pObj.p_ispitivanja_datum_od = null;
        pObj.p_ispitivanja_datum_do = null;
        pObj.p_fg_neobavljena_ispitivanja = "0";

        var period = this.get('periodZaIspitivanja');

        // switch na malo drugaciji nacin
        var ispitivanje = {
          'ispitivanjaKojaTreba': function ispitivanjaKojaTreba() {
            pObj.p_ispitivanja_datum_od = moment().format("DD.MM.YYYY");
            pObj.p_ispitivanja_datum_do = moment().add(period, 'days').format("DD.MM.YYYY");
          },
          'svaIspitivanja': function svaIspitivanja() {
            // nista ovde
          },
          'neobavljenaIspitivanja': function neobavljenaIspitivanja() {
            pObj.p_fg_neobavljena_ispitivanja = "1";
          }
        };

        ispitivanje[this.get('ispitivanjaOpreme')]();

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_09';
        izvestajiDTO.reportParameters = pObj;

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});