define('zaris-frontend/templates/components/header-block', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "zaris-frontend/templates/components/header-block.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","component",[["get","itemViewClass",["loc",[null,[2,13],[2,26]]]]],["content",["subexpr","@mut",[["get","item",["loc",[null,[3,10],[3,14]]]]],[],[]],"rowWidth",["subexpr","@mut",[["get","rowWidth",["loc",[null,[4,11],[4,19]]]]],[],[]],"enableColumnReorder",["subexpr","@mut",[["get","enableColumnReorder",["loc",[null,[5,25],[5,44]]]]],[],[]],"isShowingSortableIndicator",["subexpr","@mut",[["get","isShowingSortableIndicator",["loc",[null,[6,31],[6,57]]]]],[],[]],"sortableIndicatorLeft",["subexpr","@mut",[["get","sortableIndicatorLeft",["loc",[null,[7,26],[7,47]]]]],[],[]],"headerHeight",["subexpr","@mut",[["get","headerHeight",["loc",[null,[8,17],[8,29]]]]],[],[]],"columnMode",["subexpr","@mut",[["get","columnMode",["loc",[null,[9,15],[9,25]]]]],[],[]],"columnsFillTable",["subexpr","@mut",[["get","columnsFillTable",["loc",[null,[10,21],[10,37]]]]],[],[]],"toggleTableCollapse","toggleTableCollapse","columnDidSort","columnDidSort"],["loc",[null,[2,1],[13,3]]]]
        ],
        locals: ["item"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 9
          }
        },
        "moduleName": "zaris-frontend/templates/components/header-block.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","each",[["get","content",["loc",[null,[1,8],[1,15]]]]],[],0,null,["loc",[null,[1,0],[14,9]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});