define('zaris-frontend/controllers/obr-obrazac09-unos', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/models/models'], function (exports, Ember, ColumnDefinition, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    isNewUnos: true,

    tableColumns: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.nazivIBrojOpreme'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var vrstaBroj = row.get('vrsta') + '[' + row.get('fabrickiBroj') + ']';
          return row.get('datumPregleda') == null ? Ember['default'].String.htmlSafe('<span style="color:red;">' + vrstaBroj + "</span>") : vrstaBroj;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'lokacijaBlize',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.lokacija'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPregleda',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.datumPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledecegPregleda',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac09.datumSledecegPregleda'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      return columns;
    })

  });

});