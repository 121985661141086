define('zaris-frontend/components/obr-obrazac03-report', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {

    tagName: 'div',
    classNames: ['reportPanelHidden'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    idRadnoMestoStruktura: null,

    idLiceZaBzr: null,

    firstIdLiceZaBzr: null,

    liceZaBzr: {},

    promenaLiceZaBzr: Ember['default'].observer('idLiceZaBzr', function () {
      // promenilo se lice za bzr - ako nije null dovuci ceo objekat
      var idLiceZaBzr = this.get('idLiceZaBzr');
      var self = this;
      if (idLiceZaBzr !== null) {
        var model = Models['default'].SifLica.create({ errorHandlerController: this });
        model.find(idLiceZaBzr, this.get('login').getAccessToken(), function (entity) {
          entity.ime = entity.ime + ' ' + entity.prezime;
          self.set('liceZaBzr', entity);
        });
      }
    }),

    povredjeniRadnaMesta: Ember['default'].computed('objPovredjeni', 'modelObject', function () {
      var idPovredjeni = this.get('objPovredjeni.id');
      var loginService = this.get('login');
      var radnaMesta = Em.A();

      var url;
      if (idPovredjeni != undefined && idPovredjeni != null) {
        url = conf['default'].serverBaseUrl + "/sifRadnoMestoStruktura/radnaMestaZaLice?idPravnoLice=" + localStorage.idPravnoLice + "&idLice=" + idPovredjeni + "&access_token=" + loginService.getAccessToken();

        $.ajax({
          url: url,
          type: 'GET',
          accepts: 'application/json',
          crossDomain: true,
          async: false,
          headers: conf['default'].corsHeaders,
          success: function success(data) {
            data.forEach(function (entity) {
              radnaMesta.pushObject(entity);
            });
          },
          error: function error(e) {
            console.log('DEBUG: GET Failed', e);
          }
        });

        // dovlaci se lista mogucih rms za to lice
        // ako nije novi (izmena)  i ima modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura, treba da se postavi na njegovo rms
        // ako je novi (kreiranje) - onda se samo postavi na prvi iz liste

        var idRms = this.get('modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura');
        this.set('idRadnoMestoStruktura', null);

        if (!this.get('isNew') && idRms != undefined && idRms != null) {
          this.set('idRadnoMestoStruktura', idRms);
        } else {
          var radnoMesto = radnaMesta.get('firstObject');
          if (radnoMesto != undefined && radnoMesto != null) this.set('idRadnoMestoStruktura', radnoMesto.idRadnoMestoStruktura);
        }
        return radnaMesta;
      }
    }),

    rukovodiocRadnaMesta: Ember['default'].computed('objRukovodioc', 'modelObject', function () {
      var idRukovodioc = this.get('objRukovodioc.id');
      var loginService = this.get('login');
      var radnaMesta = "";

      var url;
      if (idRukovodioc != undefined && idRukovodioc != null) {
        url = conf['default'].serverBaseUrl + "/sifRadnoMestoStruktura/radnaMestaZaLice?idPravnoLice=" + localStorage.idPravnoLice + "&idLice=" + idRukovodioc + "&access_token=" + loginService.getAccessToken();

        $.ajax({
          url: url,
          type: 'GET',
          accepts: 'application/json',
          crossDomain: true,
          async: false,
          headers: conf['default'].corsHeaders,
          success: function success(data) {
            data.forEach(function (entity, i) {
              radnaMesta += entity.radnoMestoNaziv + '[' + entity.organizacionaStruktura + ']';
              if (i < data.length - 1) radnaMesta += ', ';
            });
          },
          error: function error(e) {
            console.log('DEBUG: GET Failed', e);
          }
        });

        return radnaMesta;
      }
    }),

    formatedDatumPovrede: Ember['default'].computed('modelObject.vremePovrede', function () {
      var datumPovrede = this.get('modelObject.vremePovrede');
      if (datumPovrede) {
        return moment(datumPovrede).format('dddd');
      }
    }),

    openPanel: Ember['default'].observer('modelObject', function () {
      var outter = this;

      var sysPravnaLicaModal = Models['default'].XsysPravnoLice.create({ errorHandlerController: this });
      var pravnoLice = sysPravnaLicaModal.findGetResult(localStorage.idPravnoLice, this.get('login').getAccessToken());
      outter.set('pravnoLice', pravnoLice);

      sysPravnaLicaModal.getResultById('getBrojZaposlenih', localStorage.idPravnoLice, this.get('login').getAccessToken(), 'GET', function (data) {
        outter.set('brojZaposlenih', data);
      });

      if (!this.get('isNew')) {
        this.set('objPovredjeni.id', this.get('modelObject.sifLicaByIdLicePovredjeni.idLice'));
        this.set('objPovredjeni.naziv', this.get('modelObject.sifLicaByIdLicePovredjeni.ime') + ' ' + this.get('modelObject.sifLicaByIdLicePovredjeni.prezime'));
        this.set('objPovredjeni.selectedRow', {});
        this.set('objPovredjeni.selectedRow.pol', this.get('modelObject.sifLicaByIdLicePovredjeni.pol'));
        this.set('objPovredjeni.selectedRow.datumRodjenja', this.get('modelObject.sifLicaByIdLicePovredjeni.datumRodjenja'));
        this.set('objPovredjeni.selectedRow.maticniBroj', this.get('modelObject.sifLicaByIdLicePovredjeni.maticniBroj'));
        this.set('objPovredjeni.selectedRow.adresa', this.get('modelObject.sifLicaByIdLicePovredjeni.adresa'));
        this.set('objPovredjeni.selectedRow.mesto', this.get('modelObject.sifLicaByIdLicePovredjeni.mesto'));
        this.set('objPovredjeni.selectedRow.drzava', this.get('modelObject.sifLicaByIdLicePovredjeni.drzava'));

        this.set('objRukovodioc.id', this.get('modelObject.sifLicaByIdLiceRukovodioc.idLice'));
        this.set('objRukovodioc.naziv', this.get('modelObject.sifLicaByIdLiceRukovodioc.ime') + ' ' + this.get('modelObject.sifLicaByIdLiceRukovodioc.prezime'));
        this.set('objRukovodioc.selectedRow', {});
        this.set('objRukovodioc.selectedRow.maticniBroj', this.get('modelObject.sifLicaByIdLiceRukovodioc.maticniBroj'));
        this.set('objRukovodioc.selectedRow.adresa', this.get('modelObject.sifLicaByIdLiceRukovodioc.adresa'));
        this.set('objRukovodioc.selectedRow.mesto', this.get('modelObject.sifLicaByIdLiceRukovodioc.mesto'));
        this.set('objRukovodioc.selectedRow.drzava', this.get('modelObject.sifLicaByIdLiceRukovodioc.drzava'));

        this.set('objOcevidac.id', null);
        this.set('objOcevidac.naziv', this.get('modelObject.ocevidacIme'));
        this.set('objOcevidac.selectedRow', {});
        this.set('objOcevidac.selectedRow.maticniBroj', this.get('modelObject.ocevidacJmbg'));
        this.set('objOcevidac.selectedRow.adresa', this.get('modelObject.sifLicaByIdLiceOcevidac.adresa'));
        this.set('objOcevidac.selectedRow.mesto', this.get('modelObject.sifLicaByIdLiceOcevidac.mesto'));
        this.set('objOcevidac.selectedRow.drzava', this.get('modelObject.sifLicaByIdLiceOcevidac.drzava'));
      }

      this.set('idRadnoMestoStruktura', this.get('modelObject.sifRadnoMestoStruktura.idRadnoMestoStruktura'));

      // postavi na njegov ako ima
      if (this.get('modelObject.sifLicaByIdLiceBzr.idLice') !== undefined && this.get('modelObject.sifLicaByIdLiceBzr.idLice') !== null) {
        this.set('idLiceZaBzr', this.get('modelObject.sifLicaByIdLiceBzr.idLice'));
      } else {
        // ako nema - postavi na prvi
        this.set('idLiceZaBzr', this.get('firstIdLiceZaBzr'));
      }

      if (this.get('modelObject.sifMestoPovrede.idMestoPovrede') === undefined || this.get('modelObject.sifMestoPovrede.idMestoPovrede') === null) {
        this.set('modelObject.sifMestoPovrede', {});
        this.set('modelObject.sifMestoPovrede.idMestoPovrede', null);
      }

      if (this.get('modelObject.sifUzrokPovrede.idUzrokPovrede') === undefined || this.get('modelObject.sifUzrokPovrede.idUzrokPovrede') === null) {
        this.set('modelObject.sifUzrokPovrede', {});
        this.set('modelObject.sifUzrokPovrede.idUzrokPovrede', null);
      }

      if (this.get('modelObject.sifIzvorPovrede.idIzvorPovrede') === undefined || this.get('modelObject.sifIzvorPovrede.idIzvorPovrede') === null) {
        this.set('modelObject.sifIzvorPovrede', {});
        this.set('modelObject.sifIzvorPovrede.idIzvorPovrede', null);
      }

      if (this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs') === undefined || this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs') === null) {
        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs', {});
        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSs.idVrstaStepenSs', null);
      }

      if (this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs') === undefined || this.get('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs') === null) {
        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani', {});
        this.set('modelObject.sifVrstaStepenSsByIdVrstaStepenSsPropisani.idVrstaStepenSs', null);
      }

      if (this.get('modelObject.sifStatusZaposlenog.idStatusZaposlenog') === undefined || this.get('modelObject.sifStatusZaposlenog.idStatusZaposlenog') === null) {
        this.set('modelObject.sifStatusZaposlenog', {});
        this.set('modelObject.sifStatusZaposlenog.idStatusZaposlenog', null);
      }
    })
  });

});