define('zaris-frontend/controllers/obr-obrazac08-unos', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/models/models', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, ColumnDefinition, Models, LanguageTransformations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    isNewUnos: true,

    tableColumns: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac08.ispitivaniParametar'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          var kategorijaNaziv = row.get('kategorija') + ' -> ' + row.get('naziv');
          return row.get('datumIspitivanja') == null ? Ember['default'].String.htmlSafe('<span style="color:red;">' + kategorijaNaziv + "</span>") : kategorijaNaziv;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumIspitivanja',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac08.datumIspitivanja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumSledecegIspitivanja',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac08.datumSledecegIspitivanja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          if (row.get(this.key) == null) return "";
          return self.dateOutputFormat(row.get(this.key));
        }
      }));

      return columns;
    })

  });

});