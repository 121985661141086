define('zaris-frontend/templates/obr-obrazac05-izvestaji', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 12
            },
            "end": {
              "line": 13,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.common.unosPodataka"],[],["loc",[null,[11,14],[11,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 12
            },
            "end": {
              "line": 19,
              "column": 12
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","icon mif-chart-line");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","t",["app.menu.sacuvani_izvestaji"],[],["loc",[null,[17,14],[17,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 26
              },
              "end": {
                "line": 82,
                "column": 103
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac5.izvestaji.sviNivoiRadneSposobnosti"],[],["loc",[null,[82,51],[82,102]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 26
              },
              "end": {
                "line": 83,
                "column": 99
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac5.izvestaji.radnaSposobnostVecaOd"],[],["loc",[null,[83,50],[83,98]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 84,
                "column": 26
              },
              "end": {
                "line": 84,
                "column": 100
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac5.izvestaji.radnaSposobnostManjaOd"],[],["loc",[null,[84,50],[84,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 26
              },
              "end": {
                "line": 85,
                "column": 100
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac5.izvestaji.radnaSposobnostJednaka"],[],["loc",[null,[85,50],[85,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 24
            },
            "end": {
              "line": 86,
              "column": 24
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","<>"],0,null,["loc",[null,[82,26],[82,116]]]],
          ["block","x-option",[],["value",">"],1,null,["loc",[null,[83,26],[83,112]]]],
          ["block","x-option",[],["value","<"],2,null,["loc",[null,[84,26],[84,113]]]],
          ["block","x-option",[],["value","="],3,null,["loc",[null,[85,26],[85,113]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 22
              },
              "end": {
                "line": 106,
                "column": 88
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac5.izvestaji.radnimMestima"],[],["loc",[null,[106,47],[106,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 22
              },
              "end": {
                "line": 107,
                "column": 99
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac5.izvestaji.organizacionojStrukturi"],[],["loc",[null,[107,48],[107,98]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 108,
                "column": 22
              },
              "end": {
                "line": 108,
                "column": 87
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["obrazac5.izvestaji.zaposlenima"],[],["loc",[null,[108,48],[108,86]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 22
            },
            "end": {
              "line": 109,
              "column": 22
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value","rm"],0,null,["loc",[null,[106,22],[106,101]]]],
          ["block","x-option",[],["value","org"],1,null,["loc",[null,[107,22],[107,112]]]],
          ["block","x-option",[],["value","zap"],2,null,["loc",[null,[108,22],[108,100]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 138,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac05-izvestaji.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 90%; ");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row 2");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","cell zarisCell");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","flex-grid ");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2 flex-just-sa");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","input-control radio small-check");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","check");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","caption");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","input-control radio small-check");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","check");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","caption");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2 flex-just-sa");
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2 flex-just-sa last-row");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan6 obr-izv-cell");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ui equal width grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h4");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","column");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","input-control select full-size");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell obr-izv-cell-generate colspan6");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button success izv-generate-button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-checkmark");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element6, [3]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [1, 1]);
        var element12 = dom.childAt(element10, [3, 1]);
        var element13 = dom.childAt(element9, [3]);
        var element14 = dom.childAt(element3, [3]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element14, [3]);
        var element17 = dom.childAt(element16, [3]);
        var element18 = dom.childAt(element3, [5]);
        var element19 = dom.childAt(element18, [1, 1]);
        var element20 = dom.childAt(element18, [3, 1]);
        var morphs = new Array(24);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[4] = dom.createMorphAt(element5,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
        morphs[6] = dom.createMorphAt(element8,1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [5]),0,0);
        morphs[8] = dom.createMorphAt(element11,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element11, [5]),0,0);
        morphs[10] = dom.createMorphAt(element12,1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element12, [5]),0,0);
        morphs[12] = dom.createMorphAt(element13,1,1);
        morphs[13] = dom.createMorphAt(element13,3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        morphs[15] = dom.createMorphAt(element15,3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element17, [1, 1]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element17, [3, 1]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element19, [1, 1]),0,0);
        morphs[20] = dom.createMorphAt(dom.childAt(element19, [3, 1]),1,1);
        morphs[21] = dom.createElementMorph(element20);
        morphs[22] = dom.createMorphAt(element20,2,2);
        morphs[23] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_bolestima_u_vezi_sa_radom"],[],["loc",[null,[4,28],[4,83]]]],
        ["block","link-to",["obrObrazac05Unos"],["tagName","button","class","image-button info"],0,null,["loc",[null,[10,12],[13,24]]]],
        ["block","link-to",["obrObrazac05Sacuvani"],["tagName","button","class","image-button info"],1,null,["loc",[null,[16,12],[19,24]]]],
        ["inline","t",["obrazac5.izvestaji.headerStruktura"],[],["loc",[null,[25,22],[25,64]]]],
        ["inline","select-tree",[],["strukturaModalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[27,44],[27,67]]]]],[],[]],"label","obrazac5.izvestaji.sviNivoiStrukture","obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[29,24],[29,36]]]]],[],[]]],["loc",[null,[26,18],[30,22]]]],
        ["inline","t",["obrazac5.izvestaji.headerRadnaMesta"],[],["loc",[null,[35,24],[35,67]]]],
        ["inline","radio-button",[],["value","radnaMesta","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[37,64],[37,74]]]]],[],[]]],["loc",[null,[37,22],[37,76]]]],
        ["inline","t",["obrazac5.izvestaji.radnaMesta"],[],["loc",[null,[39,44],[39,81]]]],
        ["inline","radio-button",[],["value","radnoMesto","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[48,64],[48,74]]]]],[],[]]],["loc",[null,[48,22],[48,76]]]],
        ["inline","t",["obrazac5.izvestaji.radnoMesto"],[],["loc",[null,[50,44],[50,81]]]],
        ["inline","radio-button",[],["value","radnoMestoStruktura","checked",["subexpr","@mut",[["get","radnaMesta",["loc",[null,[55,73],[55,83]]]]],[],[]]],["loc",[null,[55,22],[55,85]]]],
        ["inline","t",["obrazac5.izvestaji.radnoMestoStruktura"],[],["loc",[null,[57,44],[57,90]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMesto","value",["subexpr","@mut",[["get","idRadnoMesto",["loc",[null,[62,85],[62,97]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[62,120],[62,130]]]],"radnoMesto"],[],["loc",[null,[62,112],[62,144]]]]],[],["loc",[null,[62,107],[62,145]]]]],["loc",[null,[62,20],[62,147]]]],
        ["inline","select-items",[],["nullChoice",false,"domainName","SifRadnoMestoStruktura","value",["subexpr","@mut",[["get","idRadnoMestoStruktura",["loc",[null,[63,94],[63,115]]]]],[],[]],"disabled",["subexpr","not",[["subexpr","equals",[["get","radnaMesta",["loc",[null,[63,138],[63,148]]]],"radnoMestoStruktura"],[],["loc",[null,[63,130],[63,171]]]]],[],["loc",[null,[63,125],[63,172]]]]],["loc",[null,[63,20],[63,174]]]],
        ["inline","t",["obrazac5.izvestaji.headerMedjunarodnaSifraOboljenja"],[],["loc",[null,[72,22],[72,81]]]],
        ["inline","select-items",[],["nullLabel","obrazac5.izvestaji.svaOboljenja","nullChoice",true,"domainName","SifBolest","value",["subexpr","@mut",[["get","idBolest",["loc",[null,[73,122],[73,130]]]]],[],[]]],["loc",[null,[73,18],[73,133]]]],
        ["inline","t",["obrazac5.izvestaji.headerPreostalaRadnaSposobnost"],[],["loc",[null,[77,22],[77,79]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","sposobnostOperacija",["loc",[null,[81,42],[81,61]]]]],[],[]]],2,null,["loc",[null,[81,24],[86,37]]]],
        ["inline","input",[],["type","number","disabled",["subexpr","equals",[["get","sposobnostOperacija",["loc",[null,[91,63],[91,82]]]],"<>"],[],["loc",[null,[91,55],[91,88]]]],"value",["subexpr","@mut",[["get","sposobnost",["loc",[null,[91,95],[91,105]]]]],[],[]]],["loc",[null,[91,24],[91,107]]]],
        ["inline","t",["obrazac5.izvestaji.grupisiIzvestajPo"],[],["loc",[null,[101,24],[101,68]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","groupBy",["loc",[null,[105,40],[105,47]]]]],[],[]]],3,null,["loc",[null,[105,22],[109,35]]]],
        ["element","action",["generate"],[],["loc",[null,[115,24],[115,45]]]],
        ["inline","t",["app.common.generisi"],[],["loc",[null,[115,125],[115,152]]]],
        ["inline","select-tree-lov-modal",[],["id","strukturaTreeLovModal","rootView",true,"domainName","SysOrganizacionaJedinicaTree","idModalPanel","sifOrganizacionaJedinicaModal","target",["subexpr","@mut",[["get","this",["loc",[null,[129,11],[129,15]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[130,10],[130,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[131,16],[131,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[132,12],[132,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","strukturaModalPanelShow",["loc",[null,[133,19],[133,42]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[134,16],[134,27]]]]],[],[]],"obj",["subexpr","@mut",[["get","strukturaObj",["loc",[null,[135,8],[135,20]]]]],[],[]],"displayName","app.menu.organizaciona_struktura"],["loc",[null,[124,0],[137,2]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});