define('zaris-frontend/templates/vertikalni-izvestaji', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 217,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/vertikalni-izvestaji.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel zarisIzvestaj07");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 90%; ");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","flex-grid");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells1");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan12 ");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells1");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell colspan12 ");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"style","list-style-type:none");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("ul");
        dom.setAttribute(el9,"style","list-style-type:none");
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("ul");
        dom.setAttribute(el9,"style","list-style-type:none");
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("ul");
        dom.setAttribute(el9,"style","list-style-type:none");
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","input-control-compact checkbox small-check");
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","check");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","caption");
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","input-control-compact checkbox small-check");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","check");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","caption");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row cells2");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","cell obr-izv-cell-generate colspan12");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","button success izv-generate-button");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","mif-checkmark");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1, 1]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element1, [3, 1, 1]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element7, [1, 1]);
        var element9 = dom.childAt(element7, [3, 1]);
        var element10 = dom.childAt(element7, [5, 1]);
        var element11 = dom.childAt(element7, [7, 1]);
        var element12 = dom.childAt(element3, [5]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3]);
        var element15 = dom.childAt(element14, [1, 1]);
        var element16 = dom.childAt(element14, [3, 1]);
        var element17 = dom.childAt(element3, [7, 1]);
        var element18 = dom.childAt(element3, [9, 1]);
        var element19 = dom.childAt(element3, [11, 1]);
        var element20 = dom.childAt(element3, [13]);
        var element21 = dom.childAt(element20, [1]);
        var element22 = dom.childAt(element20, [3]);
        var element23 = dom.childAt(element22, [1, 1]);
        var element24 = dom.childAt(element22, [3, 1]);
        var element25 = dom.childAt(element3, [15, 1]);
        var element26 = dom.childAt(element3, [17, 1]);
        var element27 = dom.childAt(element1, [5, 1, 1]);
        var morphs = new Array(40);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
        morphs[2] = dom.createMorphAt(element2,3,3);
        morphs[3] = dom.createMorphAt(element4,1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
        morphs[5] = dom.createMorphAt(element6,1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element6, [5]),0,0);
        morphs[7] = dom.createMorphAt(element8,1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [5]),0,0);
        morphs[9] = dom.createMorphAt(element9,1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element9, [5]),0,0);
        morphs[11] = dom.createMorphAt(element10,1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element10, [5]),0,0);
        morphs[13] = dom.createMorphAt(element11,1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element11, [5]),0,0);
        morphs[15] = dom.createMorphAt(element13,1,1);
        morphs[16] = dom.createMorphAt(dom.childAt(element13, [5]),0,0);
        morphs[17] = dom.createMorphAt(element15,1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element15, [5]),0,0);
        morphs[19] = dom.createMorphAt(element16,1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element16, [5]),0,0);
        morphs[21] = dom.createMorphAt(element17,1,1);
        morphs[22] = dom.createMorphAt(dom.childAt(element17, [5]),0,0);
        morphs[23] = dom.createMorphAt(element18,1,1);
        morphs[24] = dom.createMorphAt(dom.childAt(element18, [5]),0,0);
        morphs[25] = dom.createMorphAt(element19,1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element19, [5]),0,0);
        morphs[27] = dom.createMorphAt(element21,1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element21, [5]),0,0);
        morphs[29] = dom.createMorphAt(element23,1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element23, [5]),0,0);
        morphs[31] = dom.createMorphAt(element24,1,1);
        morphs[32] = dom.createMorphAt(dom.childAt(element24, [5]),0,0);
        morphs[33] = dom.createMorphAt(element25,1,1);
        morphs[34] = dom.createMorphAt(dom.childAt(element25, [5]),0,0);
        morphs[35] = dom.createMorphAt(element26,1,1);
        morphs[36] = dom.createMorphAt(dom.childAt(element26, [5]),0,0);
        morphs[37] = dom.createElementMorph(element27);
        morphs[38] = dom.createMorphAt(element27,2,2);
        morphs[39] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.vertikalni_izvestaj"],[],["loc",[null,[4,28],[4,64]]]],
        ["inline","t",["obrazac2.izvestaji.headerZaposleni"],[],["loc",[null,[11,19],[11,61]]]],
        ["inline","select-zaposleni",[],["refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[12,43],[12,65]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[13,33],[13,50]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[14,22],[14,34]]]]],[],[]],"placeholderLabel","obrazac2.izvestaji.odaberiteZaposlenog"],["loc",[null,[12,16],[16,18]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","osnovniPodaci",["loc",[null,[25,54],[25,67]]]]],[],[]]],["loc",[null,[25,22],[25,69]]]],
        ["inline","t",["vertikalniIzvestaj.osnovniPodaci"],[],["loc",[null,[27,44],[27,84]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","podaciORadnomMestuZaposlenog",["loc",[null,[34,54],[34,82]]]]],[],[]]],["loc",[null,[34,22],[34,84]]]],
        ["inline","t",["vertikalniIzvestaj.podaciORadnomMestuZaposlenog"],[],["loc",[null,[36,44],[36,99]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","opisRadnogMesta",["loc",[null,[44,56],[44,71]]]]],[],[]],"disabled",["subexpr","not",[["get","podaciORadnomMestuZaposlenog",["loc",[null,[44,86],[44,114]]]]],[],["loc",[null,[44,81],[44,115]]]]],["loc",[null,[44,24],[44,117]]]],
        ["inline","t",["vertikalniIzvestaj.opisRadnogMesta"],[],["loc",[null,[46,46],[46,88]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","opasnostiIStetnostiNaRadnomMestuZaposlenog",["loc",[null,[51,58],[51,100]]]]],[],[]],"disabled",["subexpr","not",[["get","podaciORadnomMestuZaposlenog",["loc",[null,[51,115],[51,143]]]]],[],["loc",[null,[51,110],[51,144]]]]],["loc",[null,[51,26],[51,146]]]],
        ["inline","t",["vertikalniIzvestaj.opasnostiIStetnostiNaRadnomMestuZaposlenog"],[],["loc",[null,[53,48],[53,117]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","mereZaBezbedanIZdravRad",["loc",[null,[59,58],[59,81]]]]],[],[]],"disabled",["subexpr","not",[["get","podaciORadnomMestuZaposlenog",["loc",[null,[59,96],[59,124]]]]],[],["loc",[null,[59,91],[59,125]]]]],["loc",[null,[59,26],[59,127]]]],
        ["inline","t",["vertikalniIzvestaj.mereZaBezbedanIZdravRad"],[],["loc",[null,[61,48],[61,98]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","obavestenja",["loc",[null,[67,58],[67,69]]]]],[],[]],"disabled",["subexpr","not",[["get","podaciORadnomMestuZaposlenog",["loc",[null,[67,84],[67,112]]]]],[],["loc",[null,[67,79],[67,113]]]]],["loc",[null,[67,26],[67,115]]]],
        ["inline","t",["vertikalniIzvestaj.obavestenja"],[],["loc",[null,[69,48],[69,86]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","lekarskiPregledi",["loc",[null,[80,54],[80,70]]]]],[],[]]],["loc",[null,[80,22],[80,72]]]],
        ["inline","t",["vertikalniIzvestaj.lekarskiPregledi"],[],["loc",[null,[82,44],[82,87]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","obavezniLekarskiPregledi",["loc",[null,[90,56],[90,80]]]]],[],[]],"disabled",["subexpr","not",[["get","lekarskiPregledi",["loc",[null,[90,95],[90,111]]]]],[],["loc",[null,[90,90],[90,112]]]]],["loc",[null,[90,24],[90,114]]]],
        ["inline","t",["vertikalniIzvestaj.obavezniLekarskiPregledi"],[],["loc",[null,[92,46],[92,97]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","korisnickiDefinisaniLekarskiPregledi",["loc",[null,[97,58],[97,94]]]]],[],[]],"disabled",["subexpr","not",[["get","lekarskiPregledi",["loc",[null,[97,109],[97,125]]]]],[],["loc",[null,[97,104],[97,126]]]]],["loc",[null,[97,26],[97,128]]]],
        ["inline","t",["vertikalniIzvestaj.korisnickiDefinisaniLekarskiPregledi"],[],["loc",[null,[99,48],[99,111]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","profesionalnaOboljenja",["loc",[null,[109,54],[109,76]]]]],[],[]]],["loc",[null,[109,22],[109,78]]]],
        ["inline","t",["vertikalniIzvestaj.profesionalnaOboljenja"],[],["loc",[null,[111,44],[111,93]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","bolestiUVeziSaRadom",["loc",[null,[119,54],[119,73]]]]],[],[]]],["loc",[null,[119,22],[119,75]]]],
        ["inline","t",["vertikalniIzvestaj.bolestiUVeziSaRadom"],[],["loc",[null,[121,44],[121,90]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","povredeNaRadu",["loc",[null,[129,54],[129,67]]]]],[],[]]],["loc",[null,[129,22],[129,69]]]],
        ["inline","t",["vertikalniIzvestaj.povredeNaRadu"],[],["loc",[null,[131,44],[131,84]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","osposobljenosti",["loc",[null,[139,54],[139,69]]]]],[],[]]],["loc",[null,[139,22],[139,71]]]],
        ["inline","t",["vertikalniIzvestaj.osposobljenosti"],[],["loc",[null,[141,44],[141,86]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","obavezneObuke",["loc",[null,[149,56],[149,69]]]]],[],[]],"disabled",["subexpr","not",[["get","osposobljenosti",["loc",[null,[149,84],[149,99]]]]],[],["loc",[null,[149,79],[149,100]]]]],["loc",[null,[149,24],[149,102]]]],
        ["inline","t",["vertikalniIzvestaj.obavezneObuke"],[],["loc",[null,[151,46],[151,86]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","korisnickiDefinisaneObuke",["loc",[null,[156,58],[156,83]]]]],[],[]],"disabled",["subexpr","not",[["get","osposobljenosti",["loc",[null,[156,98],[156,113]]]]],[],["loc",[null,[156,93],[156,114]]]]],["loc",[null,[156,26],[156,116]]]],
        ["inline","t",["vertikalniIzvestaj.korisnickiDefinisaneObuke"],[],["loc",[null,[158,48],[158,100]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","opasneMaterije",["loc",[null,[168,54],[168,68]]]]],[],[]]],["loc",[null,[168,22],[168,70]]]],
        ["inline","t",["vertikalniIzvestaj.opasneMaterije"],[],["loc",[null,[170,44],[170,85]]]],
        ["inline","input",[],["type","checkbox","checked",["subexpr","@mut",[["get","licnaZastitnaOprema",["loc",[null,[178,54],[178,73]]]]],[],[]]],["loc",[null,[178,22],[178,76]]]],
        ["inline","t",["vertikalniIzvestaj.licnaZastitnaOprema"],[],["loc",[null,[180,44],[180,90]]]],
        ["element","action",["generate"],[],["loc",[null,[191,22],[191,43]]]],
        ["inline","t",["app.common.generisi"],[],["loc",[null,[191,123],[191,150]]]],
        ["inline","select-zaposleni-lov-modal",[],["id","sifZaposleniLovModal","target",["subexpr","@mut",[["get","this",["loc",[null,[203,11],[203,15]]]]],[],[]],"isNew",false,"domainName","SifZaposleni","domainId","idZaposleni","modelObject",["subexpr","@mut",[["get","modelObjectSifZaposleniLov",["loc",[null,[207,16],[207,42]]]]],[],[]],"obj",["subexpr","@mut",[["get","zaposleniObj",["loc",[null,[208,8],[208,20]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshSifZaposleniLov",["loc",[null,[209,12],[209,34]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanel2Show",["loc",[null,[210,19],[210,36]]]]],[],[]],"customId",["subexpr","@mut",[["get","selectedRow.idZaposleni",["loc",[null,[211,13],[211,36]]]]],[],[]],"referenceDomainName","sifZaposleni","referenceDomainId","idZaposleni","refreshBaseTable",["subexpr","@mut",[["get","refreshBaseTable",["loc",[null,[214,21],[214,37]]]]],[],[]],"displayName","obrazac2.izvestaji.izborZaposlenog"],["loc",[null,[201,0],[216,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});