define('zaris-frontend/components/radno-mesto-kategorija-lzo-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel3', 'radnoMestoKategorijaLzoModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel3Overlay",

    isNew: true,
    modelObject: {},
    selectedRow: {},

    modelObj: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    myValidator: Ember['default'].computed('objRadnoMesto.id', 'objRadnoMestoStruktura.id', 'modelObj.idLzoKategorija', function () {
      var selectedDomainName = this.get('selectedDomainName');
      var objRadnoMesto = this.get('objRadnoMesto');
      var objRadnoMestoStruktura = this.get('objRadnoMestoStruktura');
      var modelObj = this.get('modelObj');

      if (selectedDomainName == "radnoMesto") {
        return objRadnoMesto.id != null && modelObj.idLzoKategorija != null ? true : "";
      } else {
        return objRadnoMestoStruktura.id != null && modelObj.idLzoKategorija != null ? true : "";
      }
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var obj;
          var model;
          var model;
          var model;

          (function () {
            outter = _this;

            var i18n = _this.get('i18n');

            var selectedDomainName = _this.get('selectedDomainName');

            obj = {};

            obj.sifLzoKategorija = {};
            obj.sifLzoKategorija.idLzoKategorija = _this.get('modelObj.idLzoKategorija');

            if (selectedDomainName == "radnoMesto") {
              obj.sifRadnoMesto = {};
              obj.sifRadnoMesto.idRadnoMesto = _this.get('objRadnoMesto.id');
              model = Models['default'].SifRadnoMestoLzo.create({ errorHandlerController: _this });
            } else {
              obj.sifRadnoMestoStruktura = {};
              obj.sifRadnoMestoStruktura.idRadnoMestoStruktura = _this.get('objRadnoMestoStruktura.id');
              model = Models['default'].SifRadnoMestoStrLzo.create({ errorHandlerController: _this });
            }
            model.create(obj, _this.get('login').getAccessToken(), function () {
              $.Notify({
                caption: i18n.t('sifLzo.tab2.dodavanjeHeader').string,
                content: i18n.t('sifLzo.tab2.dodavanjeUspesno').string,
                keepOpen: false,
                type: 'success'
              });
              outter.closePanel();
              outter.toggleProperty('refresh');
            });
          })();
        }
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.set('modelObj', {});
      this.set('modelObj.idLzoKategorija', null);
      this.get('validate').hideValidations(this);

      Ember['default'].showModalPanel('.radnoMestoKategorijaLzoModal');
    }),

    closePanel: function closePanel() {
      $('.radnoMestoKategorijaLzoModal').data('dialog').close();
    }
  });

});