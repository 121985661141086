define('zaris-frontend/components/zaris-tree-table', ['exports', 'ember', 'ember-table/components/ember-table', 'ember-table/models/column-definition', 'zaris-frontend/components/zaris-tree-table-tree-row', 'zaris-frontend/components/zaris-tree-table-tree-row-full', 'zaris-frontend/utils/number-format', 'zaris-frontend/models/models', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, TableComponent, ColumnDefinition, ZarisTreeTableTreeRow, ZarisTreeTableTreeRowFull, NumberFormatHelpers, Models, LanguageTransformations) {

  'use strict';

  exports['default'] = TableComponent['default'].extend(LanguageTransformations['default'], {
    // Overriding default properties
    rootView: false,
    layoutName: 'components/zaris-tree-table',
    tableRowView: 'zaris-tree-table-row',
    tableCellView: 'zaris-tree-table-tree-cell',
    zarisColumnsDefinition: Em.A(),
    numFixedColumns: 1,
    isCollapsed: false,
    isHeaderHeightResizable: true,
    rowHeight: 25,
    hasHeader: true,
    hasFooter: false,
    headerHeight: 70,
    indexVar: 0,
    collapseAfterLevel: 1,
    groupColumnWidth: 200,

    //name of domain for CRUD
    domainName: 'domainName',
    //name of domain id
    domainId: 'domainId',
    //toggle this variable to refresh data - value is not important
    refresh: false,
    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    //id of modal panel for add/edit
    modalPanelShow: 'modalPanelShow',
    //id of modal panel for add
    modalPanelShowNew: 'modalPanelShowNew',
    //id of modal panel for edit
    modalPanelShowEdit: 'modalPanelShowEdit',

    //id of currently selected row - ZERO if nothing selected
    idRowSelected: 0,
    //selected datatable element
    selectedRow: {},

    renderCreate: true,
    renderUpdate: true,
    renderDelete: true,

    //flag that defines if search box should be rendered
    renderSearch: false,

    //filter by custom column
    customColumnName: null,
    customColumnValue: null,

    //content path
    contentPath: 'group_value',

    //display name
    displayName: 'app.menu.organizaciona_struktura',

    //id for yes/no panel
    idYesNoDialog: Ember['default'].computed('domainName', function () {
      return 'idYesNoDialog' + this.get('domainName');
    }),
    //id for ok modal panel
    idDialogOk: Ember['default'].computed('domainName', function () {
      return 'idDialogOk' + this.get('domainName');
    }),
    //panel type for ok panel(ERROR => 'alert', SUCCESS => 'success', WARN: 'warning', 'INFO' => 'info')
    modalOkType: 'alert',
    //defines if modal panel is in mode for create or update
    isNew: true,

    usePage: false,

    colorDisabled: false,

    // max level for which is allowed creation of new sub-elements
    maxLevelCreate: 100,
    // min level for which is allowed creation of new sub-elements
    minLevelCreate: -1,
    // max level for which is allowed editing of new sub-elements
    maxLevelEdit: 100,
    // min level for which is allowed editing of new sub-elements
    minLevelEdit: -1,
    // max level for which is allowed deleting of new sub-elements
    maxLevelDelete: 100,
    // min level for which is allowed deleting of new sub-elements
    minLevelDelete: -1,

    // readOnly
    readOnly: false,

    //filter value - bound to input component
    filterValue: "",

    // update of this level is not allowed
    disabledUpdateLevel: 101,

    // disabled create option
    disabledCreate: false,

    // disabled delete option
    disabledDelete: false,

    valueFactors: [],

    // returns whether creation of new sub-elements is allowed
    createAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('isRowSelected');
      var selectedRow = this.get('selectedRow');
      var maxLevelCreate = this.get('maxLevelCreate');
      var minLevelCreate = this.get('minLevelCreate');
      var readOnly = this.get('readOnly');
      var disabledCreate = this.get('disabledCreate');

      return isRowSelected && selectedRow.level < maxLevelCreate && selectedRow.level > minLevelCreate && !readOnly && !disabledCreate;
    }),

    // returns whether update of element is allowed
    updateAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('isRowSelected');
      var selectedRow = this.get('selectedRow');
      var maxLevelEdit = this.get('maxLevelEdit');
      var minLevelEdit = this.get('minLevelEdit');
      var readOnly = this.get('readOnly');
      var disabledUpdateLevel = this.get('disabledUpdateLevel');

      return isRowSelected && selectedRow.level < maxLevelEdit && selectedRow.level > minLevelEdit && !readOnly && selectedRow.level != disabledUpdateLevel;
    }),

    // returns whether delete of element is allowed
    deleteAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('isRowSelected');
      var selectedRow = this.get('selectedRow');
      var maxLevelDelete = this.get('maxLevelDelete');
      var minLevelDelete = this.get('minLevelDelete');
      var readOnly = this.get('readOnly');
      var disabledDelete = this.get('disabledDelete');

      return isRowSelected && selectedRow.level < maxLevelDelete && selectedRow.level > minLevelDelete && !readOnly && !disabledDelete;
    }),

    //flag that defines if control buttons for add, create and delete are rendered
    renderControlButtons: true,
    //flag that defines if button for saving form should be rendered
    renderSacuvajObrazac: false,
    //flag that defines if button for routing to sacuvaniObrasci should be rendered
    renderSacuvaniObrasci: false,
    //flag that defines if button for routing to izvestaji should be rendered
    renderIzvestaji: false,
    //flag that defines if button for routing to sifarnici should be rendered
    renderSifarnici: false,

    cellNumCssClass: Ember['default'].computed('', function () {
      var n = (this.get('renderControlButtons') ? 3 : 0) + (this.get('renderSacuvajObrazac') ? 1 : 0) + (this.get('renderSacuvaniObrasci') ? 1 : 0) + (this.get('renderIzvestaji') ? 1 : 0) + (this.get('renderSifarnici') ? 1 : 0);

      return 'cells' + n;
    }),

    //messages for deleting entity
    titleDeleteMsgKey: 'titleDeleteMsgKey',
    descDeleteQuestionMsgKey: 'descDeleteQuestionMsgKey',
    descDeleteSuccessMsgKey: 'descDeleteSuccessMsgKey',

    //id of modal panel for add/edit
    idModalPanel: 'idModalPanel',

    // Custom properties
    sortAscending: false,
    sortColumn: null,

    classNames: ['ember-table-financial', 'sif-org-jedinica-tree-table'],

    /////////////////////////////////////////////////////////////////////////////
    // Data conversions
    /////////////////////////////////////////////////////////////////////////////

    data: null,

    //array for generating pages component
    totalPages: Em.A(),
    //current page
    page: 0,
    //results per page
    perPage: 30,

    totalCountNumber: 0,

    currentElements: Ember['default'].computed('page', 'perPage', 'totalCountNumber', function () {
      if (this.get('usePage')) {
        var start = this.get('page') * this.get('perPage') + 1;
        var end = Math.min((this.get('page') + 1) * this.get('perPage'), this.get('totalCountNumber'));
        return start + '..' + end;
      } else return '';
    }),

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0;
    }),

    //listens for param changes and triggers table data update upon filter, sort or paging change
    tableStateChanged: Ember['default'].observer('refresh', 'domainName', 'page', 'filterValue', function () {
      Ember['default'].run.once(this, 'fetchTableData');
    }),

    //gets data for one datatable page
    fetchTableData: (function () {
      var page,
          self = this;

      var loginService = this.get('loginService');
      var i18n = this.get('i18n');
      var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this.get('target') });
      var displayName = this.get('displayName');
      var valueFactors = this.get('valueFactors');
      if (!valueFactors) valueFactors = [];

      page = this.get('page');

      var ch = this.get('customColumnName') === null ? model.findGetResult(null, loginService.getAccessToken()) : this.get('usePage') ? model.findGetResultByCustomColumn(null, this.get('customColumnName'), this.get('customColumnValue'), this.get('filterValue'), this.get("page"), this.get("perPage"), loginService.getAccessToken()) : model.findGetResultByCustomColumn(null, this.get('customColumnName'), this.get('customColumnValue'), this.get('filterValue'), null, -1, loginService.getAccessToken());

      if (self.get("usePage")) {
        self.set('totalCountNumber', +ch.group_value);
        self.set('totalPageNumber', +ch.group_value / self.get('perPage'));
        self.set('totalPages', self.getPagingObject(self.get("totalPageNumber")));
      }

      this.set('data', {
        'root': {
          'group_name': '',
          'group_value': '',
          'level': 0,
          'id': 0,
          'children': [ch]
        },
        'grouping_factors': [{
          'display_name': i18n.t(displayName).string,
          'is_time_series': false,
          'template_id': 'down_factor'
        }],
        'value_factors': valueFactors
      });
    }).on('init'),

    columns: Ember['default'].computed(function () {
      var self = this;

      var data = this.get('data');

      if (!data) {
        return;
      }

      var names = this.get('data.value_factors').getEach('display_name');
      var columns = names.map(function (name, index) {

        //defining optional custom cell template/component
        var zarisColumnsDefinition = self.get('zarisColumnsDefinition');
        var zarisTableCellView = 'zaris-tree-table-cell';
        if (zarisColumnsDefinition[index] !== undefined && zarisColumnsDefinition[index].tableCellView != undefined) {
          zarisTableCellView = zarisColumnsDefinition[index].tableCellView;
        }

        var translateBoolean = true;
        if (zarisColumnsDefinition[index] !== undefined && zarisColumnsDefinition[index].translateBoolean != undefined) {
          translateBoolean = zarisColumnsDefinition[index].translateBoolean;
        }

        var zarisSavedWidth = 130;
        if (zarisColumnsDefinition[index] !== undefined && zarisColumnsDefinition[index].savedWidth != undefined) {
          zarisSavedWidth = zarisColumnsDefinition[index].savedWidth;
        }

        return ColumnDefinition['default'].create({
          index: index,
          headerCellName: name,
          headerCellView: 'zaris-tree-table-header-cell',
          tableCellView: zarisTableCellView,
          savedWidth: zarisSavedWidth,
          getCellContent: function getCellContent(row) {
            var object = row.get('values') !== undefined ? row.get('values')[this.get('index')] : null;
            if (object) {
              if (object.type === '_money') {
                return NumberFormatHelpers['default'].toCurrency(object.value);
              }
              if (object.type === '_percent') {
                return NumberFormatHelpers['default'].toPercent(object.value);
              }
              if (object.type === '_double') {
                return object.value;
              }
              if (object.type === '_string') {
                return object.value;
              }
              if (object.type === '_boolean') {
                if (translateBoolean) {
                  return self.booleanValueNames(object.value);
                } else {
                  return object.value;
                }
              }
              return '-';
            } else {
              return '';
            }
          }
        });
      });
      columns.unshiftObject(this.get('groupingColumn'));
      return columns;
    }).property('data.valueFactors.[]', 'groupingColumn'),

    groupingColumn: Ember['default'].computed(function () {
      var groupingFactors = this.get('data.grouping_factors');
      var name = groupingFactors.getEach('display_name').join(' ▸ ');
      var self = this;
      return ColumnDefinition['default'].create({
        headerCellName: name,
        savedWidth: self.get('groupColumnWidth'),
        isTreeColumn: true,
        isSortable: false,
        textAlign: 'text-align-left',
        headerCellView: 'zaris-tree-table-header-tree-cell',
        tableCellView: self.get('tableCellView'),
        contentPath: self.contentPath,
        getCellContent: function getCellContent(row) {
          var result = row.get(self.contentPath);
          if (self.get('colorDisabled')) {
            result = row.get('fg_arhivirano') ? Ember['default'].String.htmlSafe('<span style="color:red;">' + result + "</span>") : result;
          }
          return result;
        }
      });
    }).property('data.grouping_factors.[]'),

    root: Ember['default'].computed(function () {
      var data = this.get('data');
      if (!data) {
        return;
      }
      this.set('indexVar', 0);
      return this.createTree(null, data.root);
    }).property('data', 'sortAscending', 'sortColumn'),

    rows: Ember['default'].computed(function () {
      var self = this;
      var root = this.get('root');
      if (!root) {
        return Ember['default'].A();
      }
      var rows = this.flattenTree(null, root, Ember['default'].A());
      this.computeStyles(null, root);
      var maxGroupingLevel = Math.max.apply(rows.getEach('groupingLevel'));

      var i = -1;
      rows.forEach(function (row) {
        if (row.get('isShowing')) {
          row.set('itemIndex', i);
          i++;
        }

        return row.computeRowStyle(maxGroupingLevel);
      });

      return rows;
    }).property('root'),

    // OPTIMIZATION HACK
    bodyContent: Ember['default'].computed(function () {
      var rows = this.get('rows');
      if (!rows) {
        return Ember['default'].A();
      }
      rows = rows.slice(1, rows.get('length'));
      return rows.filterBy('isShowing');
    }).property('rows'),

    footerContent: Ember['default'].computed(function () {
      var rows = this.get('rows');
      if (!rows) {
        return Ember['default'].A();
      }
      return rows.slice(0, 1);
    }).property('rows'),

    orderBy: function orderBy(item1, item2) {
      var sortColumn = this.get('sortColumn');
      var sortAscending = this.get('sortAscending');
      if (!sortColumn) {
        return 1;
      }
      var value1 = sortColumn.getCellContent(item1.get('content'));
      var value2 = sortColumn.getCellContent(item2.get('content'));
      var result = Ember['default'].compare(value1, value2);
      if (sortAscending) {
        return result;
      } else {
        return -result;
      }
    },

    createTree: function createTree(parent, node) {
      var row = this.get('rootView') ? ZarisTreeTableTreeRowFull['default'].create({ parentController: this, tableComponent: this, itemIndex: this.get('indexVar') }) : ZarisTreeTableTreeRow['default'].create({ parentController: this, tableComponent: this, itemIndex: this.get('indexVar') });
      this.set('indexVar', this.get('indexVar') + 1);
      // TODO(azirbel): better map function and _this use
      var children = (node.children || []).map((function (_this) {
        return function (child) {
          return _this.createTree(row, child);
        };
      })(this));
      // TODO(Peter): Hack... only collapse table if it should collapseByDefault
      // and it is not the root. Currently the total row is the root, and if it
      // is collapse, it causes nothing to show in the table and there is no way
      // to get expand it.
      row.setProperties({
        isRoot: !parent,
        isLeaf: Ember['default'].isEmpty(children),
        content: node,
        parent: parent,
        children: children,
        groupName: node.group_name,
        isCollapsed: node.level <= this.get('collapseAfterLevel') ? false : true
      });
      return row;
    },

    // TODO(azirbel): Don't use the word 'parent'
    flattenTree: function flattenTree(parent, node, rows) {
      rows.pushObject(node);
      (node.children || []).forEach((function (_this) {
        return function (child) {
          return _this.flattenTree(node, child, rows);
        };
      })(this));
      return rows;
    },

    computeStyles: function computeStyles(parent, node) {
      node.computeStyles(parent);
      node.get('children').forEach((function (_this) {
        return function (child) {
          _this.computeStyles(node, child);
        };
      })(this));
    },

    actions: {
      nextPage: function nextPage() {
        this.resetSelection();
        this.incrementProperty('page');
      },
      previousPage: function previousPage() {
        this.resetSelection();
        this.decrementProperty('page');
      },
      changePage: function changePage(pageNum) {
        if (pageNum == this.get('page')) return;
        this.resetSelection();
        this.set('page', pageNum);
      },
      sacuvajObrazac: function sacuvajObrazac() {

        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default'][this.get('domainName')].create({ errorHandlerController: this.get('target') });

        model.sacuvajObrazac(this.get('loginService').getAccessToken(), function () {
          $('#sacuvaniObrasci').click();
          setTimeout(function () {
            $.Notify({
              content: i18n.t('porukaUspesnoSacuvanObrazac').string,
              keepOpen: false,
              type: 'success'
            });
          }, 100);
        });
      },

      filter: function filter() {
        this.resetSelection();
        //this.set('page', 0);
        this.set('filterValue', this.get('searchInputValue'));
      },

      toggleTableCollapse: function toggleTableCollapse() {
        var isCollapsed = this.toggleProperty('isCollapsed');
        var children = this.get('root.children');
        if (!(children && children.get('length') > 0)) {
          return;
        }
        children.forEach(function (child) {
          return child.recursiveCollapse(isCollapsed);
        });
        return this.notifyPropertyChange('rows');
      },

      toggleCollapse: function toggleCollapse(row) {
        row.toggleProperty('isCollapsed');
        Ember['default'].run.next(this, function () {
          this.notifyPropertyChange('rows');
        });
      },

      rowDidClick: function rowDidClick(row, event) {
        var domainId = this.get('domainId');
        this._super(row, event);
        this.set('idRowSelected', row.get('content')[domainId]);
        this.set('selectedRow', row.get('content'));
      },
      initCreate: function initCreate() {
        this.set('isNew', true);
        this.set('modelObject', { 'domain': {} });

        this.get('modalPanelShow') === 'modalPanelShow' ? this.toggleProperty('modalPanelShowNew') : this.toggleProperty('modalPanelShow');
      },
      createConfirm: function createConfirm() {},
      initUpdate: function initUpdate() {
        this.set('isNew', false);
        var selected = jQuery.extend(true, {}, this.get('selectedRow'));
        this.set('modelObject', selected);

        this.get('modalPanelShow') === 'modalPanelShow' ? this.toggleProperty('modalPanelShowEdit') : this.toggleProperty('modalPanelShow');
      },
      updateConfirm: function updateConfirm() {},
      initDelete: function initDelete() {
        this.get("target").send('openYesNoModal', 'idYesNoDialog', this.get('titleDeleteMsgKey'), this.get('descDeleteQuestionMsgKey'), this, 'deleteConfirm');
      }
    },

    deleteConfirm: function deleteConfirm(self) {
      var loginService = self.get('loginService');
      var outter = self;

      var model = new Models['default'][self.domainName]();
      model['delete'](self.get('idRowSelected'), loginService.getAccessToken(), function () {
        //call ws to get new results, without deleted one
        outter.toggleProperty('refresh');

        outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), outter.get('descDeleteSuccessMsgKey'), 'success');

        //hiding yes/no panel
        /*$('#' + outter.get('idYesNoDialog')).data('dialog').close();*/
        outter._controller.send('closeOkModal', 'idYesNoDialog');

        //reseting selection, as selected item does not exist anymore
        //outter.resetSelection();
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', outter.get('titleDeleteMsgKey'), e.responseJSON.messages[0], 'alert');

        //hide yes/no modal panel
        /*$('#' + outter.get('idYesNoDialog')).data('dialog').close();*/

        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    refreshDidChange: Ember['default'].observer('refresh', function () {
      Ember['default'].run.once(this, 'resetSelection');
    }),
    getPagingObject: function getPagingObject(totalPages) {
      var paging = Em.A();
      var page = this.get('page');
      //up to 10 pages we display all links
      if (totalPages <= 10) {
        for (var i = 0; i < totalPages; i++) paging.push({});
      }
      //else we display first, last and five middle pages
      else {
          paging[0] = {};paging[1] = {};

          if (page < 5) {
            paging[2] = {};paging[3] = {};paging[4] = {};paging[5] = {};paging[6] = {};

            paging[7] = { delimiter: true };
          } else if (totalPages - page < 5) {
            paging[totalPages - 8] = { delimiter: true };

            paging[totalPages - 7] = {};paging[totalPages - 6] = {};paging[totalPages - 5] = {};
            paging[totalPages - 4] = {};paging[totalPages - 3] = {};
          } else {
            paging[page - 3] = { delimiter: true };

            paging[page - 2] = {};paging[page - 1] = {};paging[page] = {};paging[page + 1] = {};paging[page + 2] = {};

            paging[page + 3] = { delimiter: true };
          }

          paging[totalPages - 2] = {};paging[totalPages - 1] = {};
        }

      return paging;
    },

    resetSelection: function resetSelection() {
      this.set('idRowSelected', 0);
      this.set('selectedRow', {});
    }

  });

});