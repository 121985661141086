define('zaris-frontend/components/sif-radna-mesta-struktura-edit-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'sifRadnaMestaStrukturaEditModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    modelObject: {},

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifRadnoMestoStrukturaModel = Models['default'].SifRadnoMestoStruktura.create({ errorHandlerController: this });
        sifRadnoMestoStrukturaModel.create(this.get('modelObject'), this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('sifRadnoMestoStruktura.dodavanjeHeader').string,
            content: i18n.t('sifRadnoMestoStruktura.dodavanjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
          outter.toggleProperty('refresh');
        }, function (e) {
          $.Notify({
            caption: i18n.t('sifRadnoMestoStruktura.dodavanjeHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: true,
            type: 'alert'
          });
        });
      },
      update: function update() {
        var outter = this;
        var i18n = this.get('i18n');

        var sifRadnoMestoStrukturaModel = Models['default'].SifRadnoMestoStruktura.create({ errorHandlerController: this });
        sifRadnoMestoStrukturaModel.update(this.get('modelObject'), this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t("sifRadnoMestoStruktura.azuriranjeHeader").string,
            content: i18n.t("sifRadnoMestoStruktura.azuriranjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
          outter.toggleProperty('refresh');
        }, function (e) {
          $.Notify({
            caption: i18n.t('sifRadnoMestoStruktura.azuriranjeHeader').string,
            content: i18n.t(e.responseJSON.messages[0]).string,
            keepOpen: true,
            type: 'alert'
          });
        });
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      Ember['default'].showModalPanel('#sifRadnaMestaStrukturaEditModal');
    }),

    closePanel: function closePanel() {
      $('.sifRadnaMestaStrukturaEditModal').data('dialog').close();
    }
  });

});