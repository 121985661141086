define('zaris-frontend/templates/sif-radno-mesto-struktura', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sif-radno-mesto-struktura.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.radna_mesta_u_strukturi"],[],["loc",[null,[5,28],[5,68]]]],
        ["inline","component",["zaris-datatable"],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","SifRadnoMestoStruktura","domainId","idRadnoMestoStruktura","titleMsgKey","sifRadnoMestoStruktura.brisanjeHeader","descYesNoMsgKey","sifRadnoMestoStruktura.brisanjePitanje","descDeleteSuccessMsgKey","sifRadnoMestoStruktura.brisanjeUspesno","titleDeleteMsgKey","sifRadnoMestoStruktura.brisanjeHeader","descDeleteQuestionMsgKey","sifRadnoMestoStruktura.brisanjePitanje","descDeleteSuccessMsgKey","sifRadnoMestoStruktura.brisanjeUspesno","idModalPanelEdit","sifRadnaMestaStrukturaEditModal","idModalPanelNew","sifRadnaMestaStrukturaNewModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[25,16],[25,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[26,22],[26,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[27,18],[27,25]]]]],[],[]],"content",["subexpr","@mut",[["get","radnoMestoStrukturaContent",["loc",[null,[28,18],[28,44]]]]],[],[]],"modalPanelShowNew",["subexpr","@mut",[["get","showRadnaMestaStrukturaNewModalPanel",["loc",[null,[29,28],[29,64]]]]],[],[]],"modalPanelShowEdit",["subexpr","@mut",[["get","showRadnaMestaStrukturaEditModalPanel",["loc",[null,[30,29],[30,66]]]]],[],[]],"toolbarComponent","sif-radno-mesto-struktura-toolbar","datatableWriteRole","ROLE_SIF_RADNA_MESTA_U_STRUKTURI_WRITE"],["loc",[null,[9,8],[33,10]]]],
        ["inline","sif-radna-mesta-struktura-edit-modal",[],["id","sifRadnaMestaStrukturaEditModal","target",["subexpr","@mut",[["get","this",["loc",[null,[40,11],[40,15]]]]],[],[]],"isNew",false,"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[42,16],[42,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[43,12],[43,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showRadnaMestaStrukturaEditModalPanel",["loc",[null,[44,19],[44,56]]]]],[],[]]],["loc",[null,[38,0],[45,2]]]],
        ["inline","sif-radna-mesta-struktura-new-modal",[],["id","sifRadnaMestaStrukturaNewModal","target",["subexpr","@mut",[["get","this",["loc",[null,[49,11],[49,15]]]]],[],[]],"isNew",true,"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[51,12],[51,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","showRadnaMestaStrukturaNewModalPanel",["loc",[null,[52,19],[52,55]]]]],[],[]],"selectedIdRadnoMestoStruktura",["subexpr","@mut",[["get","customId",["loc",[null,[53,36],[53,44]]]]],[],[]]],["loc",[null,[47,0],[54,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});