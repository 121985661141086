define('zaris-frontend/components/select-boolean', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    tagName: "",

    // da li je ukljucen null izbor
    nullChoice: true,

    // ful-size select
    fullSize: true,

    styleClass: "",

    divClass: Ember['default'].computed('fullSize', 'styleClass', function () {
      var fullSize = this.get('fullSize');
      var styleClass = this.get('styleClass');
      return "input-control select" + (fullSize ? " full-size " : " ") + styleClass;
    }),

    // label key za naslov
    label: ""

  });

});