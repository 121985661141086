define('zaris-frontend/components/obr-obrazac06-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'obrObrazac06Modal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentObuke: Em.A(),
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    didInsertElement: function didInsertElement() {
      this.$('.obrObrazac06ObukaModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      novaObuka: function novaObuka() {
        this.set('fgUnos', true);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelObuka');
        }, 100);
      },

      azurirajObuku: function azurirajObuku() {
        this.set('fgUnos', false);

        var outter = this;
        setTimeout(function () {
          outter.toggleProperty('showModalPanelObuka');
        }, 100);
      },

      downloadPdf: function downloadPdf() {
        var outter = this;
        var i18n = this.get('i18n');

        var model = Models['default'].ObrObrazac06Sacuvan.create({ errorHandlerController: this });

        model.getById('downloadSacuvanObrazacByIdObuka', this.get('selektovanaObuka.idObuka'), this.get('login').getAccessToken(), 'GET', function (hash) {
          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      },

      initDelete: function initDelete() {
        //prepare and show yes/no modal panel
        this.get("target").send('openYesNoModal', 'idYesNoDialog', 'obrObrazac06.brisanjeHeader', 'obrObrazac06.brisanjePitanje', this, 'deleteConfirm');
      }

    },

    deleteConfirm: function deleteConfirm(self) {
      var loginService = self.get('login');
      var outter = self;

      var obrObrazac06ObukaModel = Models['default'].ObrObrazac06Obuka.create({ errorHandlerController: this });
      obrObrazac06ObukaModel['delete'](self.get('idRowSelectedObr6'), loginService.getAccessToken(), function () {
        //call ws to get new results, without deleted one
        outter.toggleProperty('refreshObrazac06Modal');

        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac06.brisanjeHeader', 'obrObrazac06.dodavanjeUspesno', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'obrObrazac06.brisanjeHeader', e.responseJSON.messages[0], 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    openPanel: Ember['default'].observer('modalPanelShowEdit', function () {
      this.toggleProperty('refreshRazlogOsposobljavanja');
      this.toggleProperty('refreshObrazac06Modal');
      this.set('idRowSelectedObr6', 0);

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.obrObrazac06Modal');
    }),

    refreshBaseTableListener: Ember['default'].observer('refreshObrazac02Modal', function () {
      this.toggleProperty('refreshWoResetSelection');
    }),

    closePanel: function closePanel() {
      $('.obrObrazac06Modal').data('dialog').close();
    },

    isRowObukeSelected: Ember['default'].computed('idRowSelectedObr6', function () {
      return this.get('idRowSelectedObr6') !== 0;
    }),

    tableColumnsObuke: Ember['default'].computed(function () {
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'datumTeorijskogOsposobljavanja',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.teoretskaObuka'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPrakticnogOsposobljavanja',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.prakticnaObuka'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumTeorijskeProvere',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.teoretskaProvera'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPrakticneProvere',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.prakticnaProvera'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumPotpisaObrasca',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac06.potpisana'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      return columns;
    })
  });

});