define('zaris-frontend/templates/sif-vrste-velicina-za-lzo', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sif-vrste-velicina-za-lzo.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 80%; min-height: 800px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.vrste_velicina_za_lzo"],[],["loc",[null,[5,28],[5,66]]]],
        ["inline","zaris-tree-table",[],["groupColumnWidth",400,"domainName","SifLzoVrstaVelicineTree","target",["subexpr","@mut",[["get","this",["loc",[null,[12,17],[12,21]]]]],[],[]],"domainId","id","titleMsgKey","sifLzoVrsteVelicina.brisanjeHeader","descYesNoMsgKey","sifLzoVrsteVelicina.brisanjePitanje","descDeleteSuccessMsgKey","sifLzoVrsteVelicina.brisanjeUspesno","titleDeleteMsgKey","sifLzoVrsteVelicina.brisanjeHeader","descDeleteQuestionMsgKey","sifLzoVrsteVelicina.brisanjePitanje","descDeleteSuccessMsgKey","sifLzoVrsteVelicina.brisanjeUspesno","idModalPanel","sifLzoVrsteVelicinaModal","tableCellView","sif-vrste-velicina-za-lzo-tree-cell","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[22,16],[22,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[23,22],[23,33]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[24,22],[24,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[25,18],[25,25]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[26,25],[26,41]]]]],[],[]],"displayName","app.menu.vrste_velicina_za_lzo","toolbarComponent","sif-vrste-velicina-za-lzo-toolbar","maxLevelCreate",3,"datatableWriteRole","ROLE_SIF_VRSTE_VELICINA_ZA_LZO_WRITE"],["loc",[null,[9,8],[31,10]]]],
        ["inline","sif-vrste-velicina-za-lzo-modal",[],["id","sifVrsteVelicinaZaLzoModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[38,10],[38,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[39,16],[39,27]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[40,16],[40,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[41,12],[41,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[42,19],[42,35]]]]],[],[]]],["loc",[null,[36,0],[43,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});