define('zaris-frontend/components/zaris-editable-table-cell', ['exports', 'ember', 'ember-table/components/table-cell'], function (exports, Ember, TableCell) {

  'use strict';

  exports['default'] = TableCell['default'].extend({
    className: 'editable-table-cell',
    templateName: 'editable-table/editable-table-cell',
    isEditing: false,
    type: 'text',

    valueCopy: null,

    actions: {
      finishedEditing: function finishedEditing() {
        this.set('isEditing', false);
        this.set('cellContent', this.get('valueCopy'));
      },
      canceledEditing: function canceledEditing() {
        this.set('isEditing', false);
      }
    },

    onRowContentDidChange: Ember['default'].observer(function () {
      this.set('isEditing', false);
    }, 'row.content'),

    click: function click(event) {
      this.set('valueCopy', this.get('cellContent'));

      this.set('isEditing', true);

      event.stopPropagation();
    }
  });

});