define('zaris-frontend/controllers/sif-zaposleni-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    // zaposleni
    zaposleniObj: {},

    // izbor radnih mesta
    radnaMesta: "radnaMesta",

    // idRadnoMesto - ukoliko je izabrano radno mesto
    idRadnoMesto: null,

    // idRadnoMestoStruktura - ukoliko je izabrano radno mesto u strukturi
    idRadnoMestoStruktura: null,

    // id zdravstvenog pregleda
    idPregled: null,

    // odabrana vrednost za radioButton
    statusObuke: "nemaObuku",

    datumPocetak: null,

    datumKraj: null,

    labelPocetak: Ember['default'].computed(function () {
      var i18n = this.get('i18n');
      return i18n.t("obrazac2.izvestaji.labelPocetak");
    }),

    labelKraj: Ember['default'].computed(function () {
      var i18n = this.get('i18n');
      return i18n.t("obrazac2.izvestaji.labelKraj");
    }),

    // po cemu se grupise izvestaj
    groupBy: 'radno_mesto_naziv',

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;

      var zaposleniObj = this.get('zaposleniObj');
      zaposleniObj.id = null;
      zaposleniObj.naziv = "";
    },

    actions: {
      generate: function generate() {

        // prepakovanje objekta
        var pObj = {};

        /*
        ime
        salje se:
                p_id_zaposleni
        znacenje:
                p_id_zaposleni - number
                  null      - nije izabran zaposleni
                  not null  - id zaposlenog
        */
        var zaposleniObj = this.get('zaposleniObj');
        pObj.p_id_zaposleni = zaposleniObj.id;

        /*
        status
        salje se:
                p_nema_obuku
                p_obuka_zavrsena
                p_obuka_datum_od
                p_obuka_datum_do
        znacenje:
                p_nema_obuku - boolean(0|1)
                  0 - nije odabrana opcija
                  1 - odabrana opcija nema obuku
                p_obuka_zavrsena - boolean(0|1)
                  0 - nije odabrana opcija
                  1 - odabrana opcija obuka zavrsena
                p_obuka_datum_do - string
                  DD.MM.YYYY format - korisnik uneo datum
                  null              - datum nije unet
                p_obuka_datum_do - string
                  DD.MM.YYYY format - korisnik uneo datum
                  null              - datum nije unet
        */
        var statusObuke = this.get('statusObuke');
        var datumPocetak = this.get('datumPocetak');
        var datumKraj = this.get('datumKraj');

        pObj.p_nema_obuku = 0;
        pObj.p_obuka_zavrsena = 0;
        pObj.p_obuka_datum_od = null;
        pObj.p_obuka_datum_do = null;

        switch (statusObuke) {
          case "nemaObuku":
            pObj.p_nema_obuku = 1;
            break;
          case "obukaZavrsena":
            pObj.p_obuka_zavrsena = 1;
            break;
          case "obukaUIntervalu":
            if (datumPocetak != null) {
              pObj.p_obuka_datum_od = moment(datumPocetak).format("DD.MM.YYYY");
            }
            if (datumKraj != null) {
              pObj.p_obuka_datum_do = moment(datumKraj).format("DD.MM.YYYY");
            }
            break;
          default:
        }

        /*
         organizaciona struktura
         salje se:
                p_id_organizaciona_jedinica
         znacenje:
                p_id_organizaciona_jedinica - number
                  not null - id izabrane organizacione jedinice
                  null     - svi nivoi strukture
        */
        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        /*
         radna mesta
         salje se:
                p_sva_radna_mesta
                p_id_radno_mesto
                p_id_radno_mesto_struktura
         znacenje:
                p_sva_radna_mesta - boolean(0|1)
                  1 - izabrana opcija
                  0 - nije izabrana opcija
                p_id_radno_mesto - number
                  not null - id izabranog radnog mesta
                  null - nije izabrana opcija
                p_id_radno_mesto_struktura - number
                  not null - id izabranog radnog mesta u strukturi
                  null - nije izabrana opcija
        */
        var radnaMesta = this.get('radnaMesta');
        var idRadnoMesto = this.get('idRadnoMesto');
        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        pObj.p_sva_radna_mesta = 0;
        pObj.p_id_radno_mesto = null;
        pObj.p_id_radno_mesto_struktura = null;

        switch (radnaMesta) {
          case "radnaMesta":
            pObj.p_sva_radna_mesta = 1;
            break;
          case "radnoMesto":
            pObj.p_id_radno_mesto = idRadnoMesto;
            break;
          case "radnoMestoStruktura":
            pObj.p_id_radno_mesto_struktura = idRadnoMestoStruktura;
            break;
          default:
            console.log('error ', radnaMesta);
            break;
        }

        /*
        Grupisi po
        salje se:
            p_group_by
        znacenje:
            p_group_by - string
                'radnaMesta'             - grupisi po radnim mestima
                'organizacionaStruktura' - grupisi po organizacionoj strukturi
                'zaposleni'              - grupisi po zaposlenima
        */
        var groupBy = this.get('groupBy');
        pObj.p_order_by = groupBy;

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_zaposleni';
        izvestajiDTO.reportParameters = pObj;

        //this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});