define('zaris-frontend/routes/logout', ['exports', 'ember', 'zaris-frontend/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    login: Ember['default'].inject.service('login'),
    menu: Ember['default'].inject.service('menu'),
    i18n: Ember['default'].inject.service('i18n'),

    model: function model() {
      var loginService = this.get('login');
      var menuService = this.get('menu');

      loginService.set('token', null);
      menuService.set('menuHTML', '');
      this.controllerFor('login').set('imeIPrezime', '');
      localStorage.locale = ENV['default'].i18n.defaultLocale;
      localStorage.menuHTML = '';
      localStorage.idPravnoLice = '';
      this.set('i18n.locale', localStorage.locale);
      this.transitionTo('login');
    }
  });

});