define('zaris-frontend/models/base', ['exports', 'ember', 'zaris-frontend/models/models', 'zaris-frontend/conf'], function (exports, Ember, Models, conf) {

  'use strict';

  var BaseModel = Ember['default'].Object.extend({
    modelName: 'model',
    getBasePath: function getBasePath() {
      if (typeof this.basePath !== 'undefined') {
        return this.basePath;
      } else {
        return this.modelName.substr(0, 1).toLowerCase() + this.modelName.substr(1);
      }
    },
    getPathFindAll: function getPathFindAll() {
      if (typeof this.pathFindAll !== 'undefined') {
        return this.pathFindAll;
      } else {
        return this.modelName;
      }
    },
    getVerbFindAll: function getVerbFindAll() {
      if (typeof this.verbFindAll !== 'undefined') {
        return this.verbFindAll;
      } else {
        return 'GET';
      }
    },

    startSpinner: function startSpinner() {
      if (isNaN(localStorage.activeSpinnerRequests)) localStorage.activeSpinnerRequests = 0;

      var n = parseInt(localStorage.activeSpinnerRequests);
      n = n + 1;
      localStorage.activeSpinnerRequests = n;

      if (n === 1) {
        $('#appSpinner').data('dialog').open();
      }
    },
    stopSpinner: function stopSpinner() {
      var n = parseInt(localStorage.activeSpinnerRequests);
      n = n - 1;
      localStorage.activeSpinnerRequests = n;

      if (n === 0) {
        $('#appSpinner').data('dialog').close();
      }
    },

    findAll: function findAll(accessToken) {
      var results = [];
      var modelName = this.modelName;
      var outterObject = this;
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathFindAll() + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbFindAll(),
        accepts: 'application/json',
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success(data) {
          outterObject.stopSpinner();
          data.forEach(function (entity) {
            var model = new Models['default'][modelName]();
            model.setProperties(entity);

            results.addObject(model);
          });
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
        }
      });
    },

    findAllGetResult: function findAllGetResult(accessToken) {
      var results = [];
      var modelName = this.modelName;
      var outterObject = this;
      var i = 0;
      this.startSpinner();
      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathFindAll() + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbFindAll(),
        accepts: 'application/json',
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(data) {
          outterObject.stopSpinner();
          data.forEach(function (entity) {
            var model = new Models['default'][modelName]();
            model.setProperties(entity);

            results[i++] = model;
          });
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', // i18n.t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          outterObject.errorHandlerController.send('errorRestCall', { status: e.status }, null);
        }
      });

      return results;
    },
    getPathSelectItems: function getPathSelectItems() {
      if (typeof this.pathSelectItems !== 'undefined') {
        return this.pathSelectItems;
      } else {
        return this.modelName;
      }
    },
    getVerbSelectItems: function getVerbSelectItems() {
      if (typeof this.verbSelectItems !== 'undefined') {
        return this.verbSelectItems;
      } else {
        return 'GET';
      }
    },
    selectItemsGetResult: function selectItemsGetResult(accessToken, customId) {
      var results = [];
      var modelName = this.modelName;
      var outterObject = this;
      var i = 0;
      this.startSpinner();
      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathSelectItems() + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice + (customId ? '&id=' + customId : ''),
        type: outterObject.getVerbSelectItems(),
        accepts: 'application/json',
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(data) {
          outterObject.stopSpinner();
          data.forEach(function (entity) {
            var model = new Models['default'][modelName]();
            model.setProperties(entity);

            results[i++] = model;
          });
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', // i18n.t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
        }
      });

      return results;
    },

    getPathFind: function getPathFind() {
      if (typeof this.pathFind !== 'undefined') {
        return this.pathFind;
      } else {
        return this.modelName;
      }
    },
    getVerbFind: function getVerbFind() {
      if (typeof this.verbFind !== 'undefined') {
        return this.verbFind;
      } else {
        return 'GET';
      }
    },
    find: function find(id, accessToken, successCallback, errorCallback) {
      var model = new Models['default'][this.modelName]();
      var outterObject = this;
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathFind() + (id ? '/' + id : '') + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbFind(),
        accepts: 'application/json',
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          if (successCallback == undefined) {
            model.setProperties(entity);
          } else {
            successCallback(entity);
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback == undefined) {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          } else {
            errorCallback();
          }
        }
      });
    },
    findGetResult: function findGetResult(id, accessToken) {
      var model = new Models['default'][this.modelName]();
      var outterObject = this;
      this.startSpinner();
      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathFind() + (id ? '/' + id : '') + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbFind(),
        accepts: 'application/json',
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          model.setProperties(entity);
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
        }
      });

      return model;
    },

    findGetResultByCustomColumn: function findGetResultByCustomColumn(id, columnName, columnValue, filterValue, page, size, accessToken) {
      var model = new Models['default'][this.modelName]();
      var outterObject = this;
      this.startSpinner();
      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathFind() + (id ? '/' + id : '') + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice + "&" + columnName + "=" + columnValue + "&value=" + filterValue + "&page=" + page + "&size=" + size,
        type: outterObject.getVerbFind(),
        accepts: 'application/json',
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          model.setProperties(entity);
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });console.log(outterObject.errorHandlerController);
          outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
        }
      });

      return model;
    },
    get: function get(serviceName, accessToken, verb) {
      var model = new Models['default'][this.modelName]();
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;

      if (verb === undefined || verb === null || verb === 'null') {
        verb = 'POST';
      }
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + basePath + '/' + serviceName + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: verb,
        accepts: 'application/json',
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          model.setProperties(entity);
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
        }
      });
    },
    getById: function getById(serviceName, id, accessToken, verb, successCallback, errorCallback) {
      this.getByIdCore(serviceName, id, accessToken, verb, true, successCallback, errorCallback);
    },
    getByIdCore: function getByIdCore(serviceName, id, accessToken, verb, isAsync, successCallback, errorCallback) {
      var model = new Models['default'][this.modelName]();
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;

      if (verb === undefined || verb === null || verb === 'null') {
        verb = 'POST';
      }
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + basePath + '/' + serviceName + '/' + id + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: verb,
        accepts: 'application/json',
        crossDomain: true,
        async: isAsync,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          if (successCallback == undefined) {
            model.setProperties(entity);
          } else {
            successCallback(entity);
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback == undefined) {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          } else {
            errorCallback();
          }
        }
      });
    },
    getPathSacuvajObrazac: function getPathSacuvajObrazac() {
      if (typeof this.pathSacuvajObrazac !== 'undefined') {
        return this.pathSacuvajObrazac;
      } else {
        return this.modelName + '/sacuvajObrazac';
      }
    },
    getVerbSacuvajObrazac: function getVerbSacuvajObrazac() {
      if (typeof this.verbSacuvajObrazac !== 'undefined') {
        return this.verbSacuvajObrazac;
      } else {
        return 'GET';
      }
    },
    sacuvajObrazac: function sacuvajObrazac(accessToken, successCallback, errorCallback) {
      var model = new Models['default'][this.modelName]();
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;

      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathSacuvajObrazac() + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbSacuvajObrazac(),
        accepts: 'application/json',
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success() {
          outterObject.stopSpinner();
          if (successCallback) {
            successCallback();
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback) {
            errorCallback(e);
          } else {
            console.log(e);
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          }
        }
      });
    },

    uploadFile: function uploadFile(serviceName, obj, accessToken, verb, successCallback, errorCallback) {
      var model = new Models['default'][this.modelName]();
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;

      if (verb === undefined || verb === null || verb === 'null') {
        verb = 'POST';
      }
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + basePath + '/' + serviceName + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: verb,
        data: obj,
        crossDomain: true,
        cache: false,
        contentType: false,
        processData: false,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          if (successCallback == undefined) {
            model.setProperties(entity);
          } else {
            successCallback();
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback == undefined) {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          } else {
            errorCallback();
          }
        }
      });
    },
    generateDownloadLink: function generateDownloadLink(obj) {
      return conf['default'].serverBaseUrl + '/download/downloadFile/' + obj + '?idPravnoLice=' + localStorage.idPravnoLice;
    },
    getResult: function getResult(serviceName, accessToken, verb) {
      var model = new Models['default'][this.modelName]();
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;

      if (verb === undefined || verb === null || verb === 'null') {
        verb = 'POST';
      }
      this.startSpinner();
      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + basePath + '/' + serviceName + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: verb,
        accepts: 'application/json',
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          model.setProperties(entity);
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
        }
      });

      return model;
    },

    getAllResults: function getAllResults(serviceName, accessToken, verb) {
      var results = [];
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;
      var i = 0;
      this.startSpinner();
      if (verb === undefined || verb === null || verb === 'null') {
        verb = 'POST';
      }

      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + basePath + '/' + serviceName + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: verb,
        accepts: 'application/json',
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(data) {
          outterObject.stopSpinner();
          data.forEach(function (entity) {
            var model = new Models['default'][modelName]();
            model.setProperties(entity);

            results[i++] = model;
          });
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          //  outterObject.errorHandlerController.send('errorRestCall', {status: e.status}, null);
        }
      });

      return results;
    },

    getResultById: function getResultById(serviceName, id, accessToken, verb, successCallback) {
      var results = [];
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;
      var i = 0;

      if (verb === undefined || verb === null || verb === 'null') {
        verb = 'POST';
      }
      this.startSpinner();
      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + basePath + '/' + serviceName + '/' + id + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: verb,
        accepts: 'application/json',
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(data) {
          outterObject.stopSpinner();
          if (successCallback == undefined) {
            data.forEach(function (entity) {
              var model = new Models['default'][modelName]();
              model.setProperties(entity);

              results[i++] = model;
            });
          } else {
            successCallback(data);
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          $.Notify({
            caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
            content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
            keepOpen: true,
            type: 'alert'
          });
          outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
        }
      });

      return results;
    },

    postResult: function postResult(serviceName, jsonData, accessToken, successCallback, errorCallback) {
      var model = new Models['default'][this.modelName]();
      var basePath = this.getBasePath();
      var modelName = this.modelName;
      var outterObject = this;
      this.startSpinner();
      $.ajax({
        url: conf['default'].serverBaseUrl + '/' + basePath + '/' + serviceName + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: 'POST',
        accepts: 'application/json',
        contentType: "application/json",
        data: JSON.stringify(jsonData, function (key, value) {
          if (key == "errorHandlerController") {
            return undefined;
          }
          return value;
        }),
        dataType: "json",
        crossDomain: true,
        async: false,
        headers: conf['default'].corsHeaders,
        success: function success(entity) {
          outterObject.stopSpinner();
          if (successCallback == undefined) {
            model.setProperties(entity);
          } else {
            successCallback(entity);
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback == undefined) {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          } else {
            errorCallback(e);
          }
        }
      });

      return model;
    },
    getPathFindPaged: function getPathFindPaged() {
      if (typeof this.pathFindPaged !== 'undefined') {
        return this.pathFindPaged;
      } else {
        return this.modelName;
      }
    },
    getVerbFindPaged: function getVerbFindPaged() {
      if (typeof this.verbFindPaged !== 'undefined') {
        return this.verbFindPaged;
      } else {
        return 'GET';
      }
    },
    getPathFindPagedPost: function getPathFindPagedPost() {
      if (typeof this.pathFindPagedPost !== 'undefined') {
        return this.pathFindPagedPost;
      } else {
        return this.modelName;
      }
    },

    getPathDelete: function getPathDelete() {
      if (typeof this.pathDelete !== 'undefined') {
        return this.pathDelete;
      } else {
        return this.modelName;
      }
    },
    getVerbDelete: function getVerbDelete() {
      if (typeof this.verbDelete !== 'undefined') {
        return this.verbDelete;
      } else {
        return 'DELETE';
      }
    },
    'delete': function _delete(id, accessToken, successCallback, errorCallback) {
      var outterObject = this;
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathDelete() + '/' + id + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbDelete(),
        accepts: 'application/json',
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success() {
          outterObject.stopSpinner();
          if (successCallback) {
            successCallback();
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback) {
            errorCallback(e);
          } else {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          }
        }
      });
    },
    customDelete: function customDelete(servicePath, accessToken, successCallback, errorCallback) {
      var outterObject = this;
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getBasePath() + '/' + servicePath + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbDelete(),
        accepts: 'application/json',
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success() {
          outterObject.stopSpinner();
          if (successCallback) {
            successCallback();
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback) {
            errorCallback(e);
          } else {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          }
        }
      });
    },
    getPathCreate: function getPathCreate() {
      if (typeof this.pathCreate !== 'undefined') {
        return this.pathCreate;
      } else {
        return this.modelName;
      }
    },
    getVerbCreate: function getVerbCreate() {
      if (typeof this.verbCreate !== 'undefined') {
        return this.verbCreate;
      } else {
        return 'POST';
      }
    },
    create: function create(jsonData, accessToken, successCallback, errorCallback) {
      var outterObject = this;
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathCreate() + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbCreate(),
        accepts: 'application/json',
        contentType: "application/json",
        data: JSON.stringify(jsonData, function (key, value) {
          if (key == "errorHandlerController") {
            return undefined;
          }
          return value;
        }),
        dataType: "json",
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success(d) {
          outterObject.stopSpinner();
          if (successCallback) {
            successCallback(d);
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback) {
            console.log('CALLED ERROR HANDLER');
            errorCallback(e);
          } else {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.get("errorHandlerController.target").send('errorRestCall', { status: e.status }, null);
          }
        }
      });
    },

    getPathGenerateReport: function getPathGenerateReport() {
      if (typeof this.pathGenerateReport !== 'undefined') {
        return this.pathGenerateReport;
      } else {
        return this.modelName;
      }
    },

    getVerbGenerateReport: function getVerbGenerateReport() {
      if (typeof this.verbGetVerbGenerateReport !== 'undefined') {
        return this.verbGenerateReport;
      } else {
        return 'POST';
      }
    },

    generateReport: function generateReport(jsonData, accessToken, successCallback, errorCallback) {
      var outterObject = this;
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathGenerateReport() + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbGenerateReport(),
        accepts: 'application/json',
        contentType: "application/json",
        data: JSON.stringify(jsonData, function (key, value) {
          if (key == "errorHandlerController") {
            return undefined;
          }
          return value;
        }),
        dataType: "json",
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success(result) {
          outterObject.stopSpinner();
          console.log('successReport');
          if (successCallback) {
            successCallback(result);
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback) {
            errorCallback(e);
          } else {
            console.log(e);
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          }
        }
      });
    },

    getPathUpdate: function getPathUpdate() {
      if (typeof this.pathUpdate !== 'undefined') {
        return this.pathUpdate;
      } else {
        return this.modelName;
      }
    },
    getVerbUpdate: function getVerbUpdate() {
      if (typeof this.verbUpdate !== 'undefined') {
        return this.verbUpdate;
      } else {
        return 'PATCH';
      }
    },
    update: function update(jsonData, accessToken, successCallback, errorCallback) {
      var outterObject = this;
      this.startSpinner();
      return $.ajax({
        url: conf['default'].serverBaseUrl + '/' + outterObject.getPathUpdate() + '?access_token=' + accessToken + '&idPravnoLice=' + localStorage.idPravnoLice,
        type: outterObject.getVerbUpdate(),
        accepts: 'application/json',
        contentType: "application/json",
        data: JSON.stringify(jsonData, function (key, value) {
          if (key == "errorHandlerController") {
            return undefined;
          }
          return value;
        }),
        dataType: "json",
        crossDomain: true,
        headers: conf['default'].corsHeaders,
        success: function success() {
          outterObject.stopSpinner();
          if (successCallback) {
            successCallback();
          }
        },
        error: function error(e) {
          outterObject.stopSpinner();
          if (errorCallback) {
            errorCallback(e);
          } else {
            $.Notify({
              caption: 'error', //outterObject.get('i18n').t('coreErrorMessage'),
              content: e.responseJSON != null ? e.responseJSON.messages[0] : '',
              keepOpen: true,
              type: 'alert'
            });
            outterObject.errorHandlerController.get("target").send('errorRestCall', { status: e.status }, null);
          }
        }
      });
    }
  });

  exports['default'] = BaseModel;

});