define('zaris-frontend/components/zaris-tree-table-tree-cell', ['exports', 'ember', 'ember-table/components/table-cell'], function (exports, Ember, TableCell) {

  'use strict';

  exports['default'] = TableCell['default'].extend({
    templateName: 'zaris-tree-table-tree-cell',
    classNames: 'ember-table-table-tree-cell',

    paddingStyle: Ember['default'].computed(function () {
      return new Ember['default'].Handlebars.SafeString('padding-left:' + this.get('row.indentation') + 'px;');
    }).property('row.indentation'),

    actions: {
      toggleCollapse: function toggleCollapse(row) {
        this.set('action', 'toggleCollapse');
        this.sendAction('action', row);
      }
    }
  });

});