define('zaris-frontend/components/sys-korisnik-postojeci-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel1', 'sysPostojeciLovModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color', 'data-type'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "70%",
    "data-width": "70%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",
    "data-type": "modal",

    contentPostojeci: Em.A(),
    modelObjectPostojeci: {},
    myModalPanelShow: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    saveEnabled: false,

    saveButtonConditionChanged: Ember['default'].observer('selectedRowPostojeci', function () {
      this.set('saveEnabled', this.get('selectedRowPostojeci') !== null && Object.keys(this.get('selectedRowPostojeci')).length !== 0);
    }),

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      choose: function choose() {
        var i18n = this.get('i18n');
        var outter = this;

        var korisnikPostojeciModel = Models['default'].SysKorisnikPostojeci.create({ errorHandlerController: this });
        // url je r/save/id - gadja SysKorisnikController
        // id je id pravnog lica u koje se dodaje selectedRowPostojeciKorisnik
        korisnikPostojeciModel.postResult("r/postojeci/" + this.get('customId'), this.get('selectedRowPostojeci'), this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t("sysKorisnik.dodavanjeHeader").string,
            content: i18n.t("sysKorisnik.dodavanjeUspesno").string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
          outter.toggleProperty('refreshBaseTable');
        });
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.toggleProperty('refreshPostojeci');

      var outter = this;
      setTimeout(function () {
        outter.set('componentLov', 'zaris-datatable');
      }, 100);

      Ember['default'].showModalPanel('.sysPostojeciLovModal');
    }),

    closePanel: function closePanel() {
      $('.sysPostojeciLovModal').data('dialog').close();
    },

    tableColumns: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'ime',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sysKorisnik.ime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'prezime',
        savedWidth: 200,
        supportSort: true,
        headerCellName: this.get('i18n').t('sysKorisnik.prezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'username',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('sysKorisnik.username'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'trenutakSnimanja',
        savedWidth: 250,
        supportSort: true,
        headerCellName: this.get('i18n').t('sysKorisnik.trenutakSnimanja'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return self.dateTimeOutputFormat(row.get(this.key));
        }
      }));

      return columns;
    })
  });

});