define('zaris-frontend/components/sif-pregledi-lov-new-modal', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'modalPanelLevel2', 'sifPreglediLovNewModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-size', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-size": "{&quot;height&quot;: 50}",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark modalPanelLevel2Overlay",

    isNew: true,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),
    validate: Ember['default'].inject.service('validate'),

    title: Ember['default'].computed('isPeriodicno', function () {
      var i18n = this.get('i18n');
      return !this.get('isPeriodicno') ? i18n.t("sifKorisnickiDefinisaniPregledi.nijePeriodicno").string : "";
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      Ember['default'].run.scheduleOnce('afterRender', this, this.initSelectItems);
    },

    initSelectItems: function initSelectItems() {
      /* dovlacenje itema za select listu*/
      var loginService = this.get('login');
      var sifZdravstveniPregledModel = Models['default'].SifZdravstveniPregled.create({ errorHandlerController: this });
      this.set('selectItems', sifZdravstveniPregledModel.findAllGetResult(loginService.getAccessToken()));
    },

    initCreate: function initCreate() {
      var obj = {};
      this.set('isPeriodicno', this.get('selectItems') !== null && this.get('selectItems')[0] !== null && this.get('selectItems')[0] !== undefined ? this.get('selectItems')[0].fgPeriodicno : false);
      obj[this.get('referenceDomainName')] = {};
      obj[this.get('referenceDomainName')][this.get('referenceDomainId')] = this.get('customId');
      obj.sifZdravstveniPregled = {};
      obj.sifZdravstveniPregled.idZdravstveniPregled = this.get('selectItems') !== null && this.get('selectItems')[0] !== null && this.get('selectItems')[0] !== undefined ? this.get('selectItems')[0].idZdravstveniPregled : 0;
      obj.intervalMeseci = this.get('selectItems') !== null && this.get('selectItems')[0] !== null && this.get('isPeriodicno') && this.get('selectItems')[0] !== undefined ? this.get('selectItems')[0].intervalMeseci : null;

      this.set('modelObjectPregled', obj);
    },

    initUpdate: function initUpdate() {
      var selected = jQuery.extend(true, {}, this.get('selectedRow'));
      this.set('modelObjectPregled', selected);
      this.set('isPeriodicno', this.get('selectedRow').sifZdravstveniPregled.fgPeriodicno);
    },

    actions: {
      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },

      merge: function merge() {
        var _this = this;

        if (this.get('validate').validate(this)) {
          var outter;
          var isNew;
          var obj;
          var sifRadnoMestoStrPreglediModel;

          (function () {
            outter = _this;
            isNew = _this.get('isNew');

            var i18n = _this.get('i18n');

            obj = _this.get('modelObjectPregled');
            sifRadnoMestoStrPreglediModel = Models['default'][_this.get('domainName')].create({ errorHandlerController: _this });

            sifRadnoMestoStrPreglediModel.create(obj, _this.get('login').getAccessToken(), function (data) {
              if (isNew) {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisanePregledi.dodavanjeHeader').string,
                  content: i18n.t('sifKorisnickiDefinisanePregledi.dodavanjeUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
                outter.set('objId', data[outter.get('target').get('domainId')]);
                if (outter.get('domainName') == 'SifRadnoMestoPregledi') {
                  outter.get("target").send('openYesNoModal', 'idYesNoDialog', 'sifRadnaMesta.sinhronizacijaPregledaHeader', 'sifRadnaMesta.sinhronizacijaPregledaMsg', outter, 'createRadnoMestoPregledConfirm');
                }
                if (outter.get('domainName') == 'SifRadnoMestoStrPregledi') {
                  outter.get("target").send('openYesNoModal', 'idYesNoDialog', 'sifRadnoMestoStruktura.sinhronizacijaPregledaHeader', 'sifRadnoMestoStruktura.sinhronizacijaPregledaMsg', outter, 'createRadnoMestoStrPregledConfirm');
                }
              } else {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisanePregledi.azuriranjeHeader').string,
                  content: i18n.t('sifKorisnickiDefinisanePregledi.azuriranjeUspesno').string,
                  keepOpen: false,
                  type: 'success'
                });
                if (outter.get('domainName') == 'SifLicaPregledi') {
                  outter.get("target").send('openYesNoModal', 'idYesNoDialog', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaHeader', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaMsg', outter, 'updateConfirm');
                }
              }

              outter.closePanel();
              outter.toggleProperty('refreshPregledi');
              outter.toggleProperty('refreshWoResetSelection');
            }, function (e) {
              if (isNew) {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisanePregledi.dodavanjeHeader').string,
                  content: i18n.t(e.responseJSON.messages[0]).string,
                  keepOpen: true,
                  type: 'alert'
                });
              } else {
                $.Notify({
                  caption: i18n.t('sifKorisnickiDefinisanePregledi.azuriranjeHeader').string,
                  content: i18n.t(e.responseJSON.messages[0]).string,
                  keepOpen: true,
                  type: 'alert'
                });
              }
            });
          })();
        }
      },

      itemSelected: function itemSelected(selection, component) {
        var result = $.grep(this.get('selectItems'), function (e) {
          return e.idZdravstveniPregled == selection;
        });
        this.set('modelObjectPregled.intervalMeseci', result[0].intervalMeseci);
        this.set('isPeriodicno', result[0].fgPeriodicno);
      }
    },

    createRadnoMestoPregledConfirm: function createRadnoMestoPregledConfirm(self) {
      var loginService = self.get('login');
      var outter = self;
      var i18n = outter.get('i18n');

      var model = Models['default']["SifRadnoMestoPregledi"].create({ errorHandlerController: outter });

      model.getById('sinhronizacija', outter.get('objId'), loginService.getAccessToken(), 'POST', function () {
        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaHeader', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaSucc', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaHeader', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaErr', 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    createRadnoMestoStrPregledConfirm: function createRadnoMestoStrPregledConfirm(self) {
      var loginService = self.get('login');
      var outter = self;
      var i18n = outter.get('i18n');

      var model = Models['default']["SifRadnoMestoStrPregledi"].create({ errorHandlerController: outter });
      model.getById('sinhronizacija', outter.get('objId'), loginService.getAccessToken(), 'POST', function () {
        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaHeader', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaSucc', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaHeader', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaErr', 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      });
    },

    updateConfirm: function updateConfirm(self) {
      var loginService = self.get('login');
      var outter = self;
      var i18n = outter.get('i18n');

      var obj = outter.get('modelObjectPregled');
      var domainId = outter.get('target').get('domainId');

      var sifLicaModal = Models['default'][outter.get('domainName')].create({ errorHandlerController: outter });
      sifLicaModal.getById('sinhronizacija', obj[domainId], loginService.getAccessToken(), 'POST', function () {

        //prepare and show success ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaHeader', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaSucc', 'success');

        //hiding yes/no panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }, function (e) {
        //prepare and show error ok panel
        outter._controller.send('openOkModal', 'idDialogOk', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaHeader', 'sifKorisnickiDefinisaniPregledi.sinhronizacijaPregledaErr', 'alert');

        //hide yes/no modal panel
        outter._controller.send('closeOkModal', 'idYesNoDialog');
      }

      /*function(o){
        $.Notify({
            caption: i18n.t("sifKorisnickiDefinisanePregledi.sinhronizacijaPregledaSucc").string,
            content: i18n.t("sifKorisnickiDefinisanePregledi.sinhronizacijaPregledaErr").string,
            keepOpen: false,
            type: 'success'
        });
      }*/);
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      if (this.get('isNew')) {
        this.initCreate();
      } else {
        this.initUpdate();
      }

      Ember['default'].showModalPanel('.sifPreglediLovNewModal');
    }),

    closePanel: function closePanel() {
      this.get('validate').hideValidations(this);
      $('.sifPreglediLovNewModal').data('dialog').close();
    }
  });

});