define('zaris-frontend/helpers/has-role', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Helper.extend({
    roles: Ember['default'].inject.service('roles'),

    compute: function compute(params, hash) {
      var roleName = params[0];
      var roles = this.get('roles');

      if (roleName === undefined || roleName === null) return true;

      return roles.userHasRole(roleName);
    }
  });

});