define('zaris-frontend/components/zaris-tree-lazy-table-block', ['exports', 'ember-table/components/lazy-table-block'], function (exports, LazyTableBlock) {

  'use strict';

  exports['default'] = LazyTableBlock['default'].extend({
    templateName: 'zaris-tree-lazy-table-block',
    actions: {
      toggleCollapse: function toggleCollapse(row) {
        this.set('action', 'toggleCollapse');
        this.sendAction('action', row);
      }
    }
  });

});