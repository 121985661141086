define('zaris-frontend/templates/components/sif-lzo-velicine-lov-modal', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 59,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/components/sif-lzo-velicine-lov-modal.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-caption bg-cyan fg-white");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-icon");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","mif-stack3");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","window-caption-title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2,"class","btn-close");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","window-content");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fullWidth clearFloat centeredText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","button");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mif-cross");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [5, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createMorphAt(element2,3,3);
        morphs[4] = dom.createElementMorph(element3);
        morphs[5] = dom.createMorphAt(element3,2,2);
        return morphs;
      },
      statements: [
        ["inline","t",["toolbars.lzoVelicine"],[],["loc",[null,[6,10],[6,38]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[8,26],[8,52]]]],
        ["inline","component",[["get","componentLov",["loc",[null,[13,14],[13,26]]]]],["target",["subexpr","@mut",[["get","this",["loc",[null,[14,11],[14,15]]]]],[],[]],"content",["subexpr","@mut",[["get","contentLzoVelicine",["loc",[null,[15,12],[15,30]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumnsLzoVelicine",["loc",[null,[16,12],[16,35]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName",["subexpr","@mut",[["get","domainName",["loc",[null,[20,15],[20,25]]]]],[],[]],"domainId",["subexpr","@mut",[["get","domainId",["loc",[null,[21,13],[21,21]]]]],[],[]],"titleMsgKey","sifLzoVrsteVelicina.brisanjeHeader","descYesNoMsgKey","sifLzoVrsteVelicina.brisanjePitanje","descDeleteSuccessMsgKey","sifLzoVrsteVelicina.brisanjeUspesno","titleDeleteMsgKey","sifLzoVrsteVelicina.brisanjeHeader","descDeleteQuestionMsgKey","sifLzoVrsteVelicina.brisanjePitanje","descDeleteSuccessMsgKey","sifLzoVrsteVelicina.brisanjeUspesno","idModalPanelNew","sifLzoVelicineLovNewModal","isNew",["subexpr","@mut",[["get","isNewLzoVelicine",["loc",[null,[29,10],[29,26]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRowLzoVelicine",["loc",[null,[30,16],[30,38]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshLzoVelicine",["loc",[null,[31,12],[31,30]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelLzoVelicineShow",["loc",[null,[32,19],[32,46]]]]],[],[]],"renderFilterArhivirano",false,"renderSearch",false,"renderUpdate",false,"customId",["subexpr","@mut",[["get","customId",["loc",[null,[36,13],[36,21]]]]],[],[]],"datatableWriteRole",["subexpr","@mut",[["get","datatableWriteRole",["loc",[null,[37,23],[37,41]]]]],[],[]]],["loc",[null,[13,2],[38,4]]]],
        ["inline","sif-lzo-velicine-lov-new-modal",[],["id","sifLzoVelicineLovNewModal","target",["subexpr","@mut",[["get","this",["loc",[null,[42,13],[42,17]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNewLzoVelicine",["loc",[null,[43,12],[43,28]]]]],[],[]],"domainName",["subexpr","@mut",[["get","domainName",["loc",[null,[44,17],[44,27]]]]],[],[]],"refreshLzoVelicine",["subexpr","@mut",[["get","refreshLzoVelicine",["loc",[null,[45,25],[45,43]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelLzoVelicineShow",["loc",[null,[46,21],[46,48]]]]],[],[]],"customId",["subexpr","@mut",[["get","customId",["loc",[null,[47,15],[47,23]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRowLzoVelicine",["loc",[null,[48,18],[48,40]]]]],[],[]],"referenceDomainName",["subexpr","@mut",[["get","referenceDomainName",["loc",[null,[49,26],[49,45]]]]],[],[]],"referenceDomainId",["subexpr","@mut",[["get","referenceDomainId",["loc",[null,[50,24],[50,41]]]]],[],[]],"refreshBaseTable",["subexpr","@mut",[["get","refreshBaseTable",["loc",[null,[51,23],[51,39]]]]],[],[]]],["loc",[null,[40,2],[52,4]]]],
        ["element","action",["btnCloseClick"],[],["loc",[null,[55,12],[55,38]]]],
        ["inline","t",["app.common.close"],[],["loc",[null,[55,86],[55,110]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});