define('zaris-frontend/components/obr-obrazac06a-grupno-dodavanje-obuke-modal', ['exports', 'ember', 'zaris-frontend/models/models', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations'], function (exports, Ember, Models, ColumnDefinition, LanguageTransformations) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend(LanguageTransformations['default'], {
    tagName: 'div',
    classNames: ['window', 'dialog', 'zarisWindow', 'obrObrazac06aGrupnoDodavanjeObukeModal', 'panelHidden'],
    attributeBindings: ['data-role', 'data-drag-element', 'data-height', 'data-width', 'data-buttons', 'data-on-btn-close-click', 'data-overlay', 'data-overlay-color'],
    "data-role": "draggable, dialog",
    "data-drag-element": ".window-caption",
    "data-height": "90%",
    "data-width": "90%",
    "data-buttons": "{&quot;btnClose&quot;: &quot;true&quot;}",
    "data-on-btn-close-click": "btnCloseClick",
    "data-overlay": true,
    "data-overlay-color": "op-dark",

    isNew: true,
    refreshLicaSelected: false,

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    licaContent: Em.A(),

    componentName1: null,
    componentName2: null,

    selectedLice: null,
    selectedLiceIzabrano: null,

    datatableFilters: {},

    didInsertElement: function didInsertElement() {
      this.$('#strukturaTreeLovModal').appendTo(this.$(document.body));
      this.$('#sifRadnoMestoLovModal').appendTo(this.$(document.body));
      this.$('#sifRadnoMestoStrukturaLovModal').appendTo(this.$(document.body));
      this.$('#sifLiceLovModal').appendTo(this.$(document.body));
      $('.dialog').css({ top: '0px' });
    },

    strukturaFilterSelected: Ember['default'].observer('strukturaObj', function () {
      if (!jQuery.isEmptyObject(this.get('strukturaObj'))) {
        this.set('radnoMestoObj', {});
        this.set('radnoMestoStrukturaObj', {});
        this.set('zaposleniObj', {});
        this.set('selectedZaposleniBezObukeOption', {});

        var datatableFilters = {};
        datatableFilters.tipFiltera = 1;
        datatableFilters.id = this.get('strukturaObj.id');
        datatableFilters.idZdravstveniPregled = this.get('selectedZakazanaObuka.sifObuka.idObuka');
        this.set('datatableFilters', datatableFilters);
      }
    }),

    radnoMestoFilterSelected: Ember['default'].observer('radnoMestoObj', function () {
      if (!jQuery.isEmptyObject(this.get('radnoMestoObj'))) {
        this.set('strukturaObj', {});
        this.set('radnoMestoStrukturaObj', {});
        this.set('zaposleniObj', {});
        this.set('selectedZaposleniBezObukeOption', {});

        var datatableFilters = {};
        datatableFilters.tipFiltera = 2;
        datatableFilters.id = this.get('radnoMestoObj.id');
        datatableFilters.idZdravstveniPregled = this.get('selectedZakazanaObuka.sifObuka.idObuka');
        this.set('datatableFilters', datatableFilters);
      }
    }),

    radnoMestoStrukturaFilterSelected: Ember['default'].observer('radnoMestoStrukturaObj', function () {
      if (!jQuery.isEmptyObject(this.get('radnoMestoStrukturaObj'))) {
        this.set('strukturaObj', {});
        this.set('radnoMestoObj', {});
        this.set('zaposleniObj', {});
        this.set('selectedZaposleniBezObukeOption', {});

        var datatableFilters = {};
        datatableFilters.tipFiltera = 3;
        datatableFilters.id = this.get('radnoMestoStrukturaObj.id');
        datatableFilters.idZdravstveniPregled = this.get('selectedZakazanaObuka.sifObuka.idObuka');
        this.set('datatableFilters', datatableFilters);
      }
    }),

    zaposleniFilterSelected: Ember['default'].observer('zaposleniObj', function () {
      if (!jQuery.isEmptyObject(this.get('zaposleniObj'))) {
        this.set('strukturaObj', {});
        this.set('radnoMestoObj', {});
        this.set('radnoMestoStrukturaObj', {});
        this.set('selectedZaposleniBezObukeOption', {});

        var datatableFilters = {};
        datatableFilters.tipFiltera = 4;
        datatableFilters.id = this.get('zaposleniObj.id');
        datatableFilters.idZdravstveniPregled = this.get('selectedZakazanaObuka.sifObuka.idObuka');
        this.set('datatableFilters', datatableFilters);
      }
    }),

    actions: {
      zaposleniBezObukeFilterSelected: function zaposleniBezObukeFilterSelected(item) {
        if (item !== null) {
          this.set('strukturaObj', {});
          this.set('radnoMestoObj', {});
          this.set('radnoMestoStrukturaObj', {});
          this.set('zaposleniObj', {});

          var datatableFilters = {};
          datatableFilters.tipFiltera = 5;
          datatableFilters.id = item;
          datatableFilters.idZdravstveniPregled = this.get('selectedZakazanaObuka.sifObuka.idObuka');
          this.set('datatableFilters', datatableFilters);
        }
      },

      btnCloseClick: function btnCloseClick() {
        this.closePanel();
      },
      create: function create() {
        var outter = this;
        var i18n = this.get('i18n');

        var obj = {};
        obj.sifLica = [];

        obj.sifObukaZakazana = this.get('selectedZakazanaObuka');

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.get('selectedLicaContent')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var item = _step.value;

            obj.sifLica.push(item);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var obrObrazac06aSpecijalnaObukaModel = Models['default'].ObrObrazac06aSpecijalnaObuka.create({ errorHandlerController: this });
        obrObrazac06aSpecijalnaObukaModel.postResult('dodelaObukeZaposlenima', obj, this.get('login').getAccessToken(), function () {
          $.Notify({
            caption: i18n.t('obrObrazac06.grupnoDodavanjeObukeHeader').string,
            content: i18n.t('obrObrazac06.grupnoDodavanjeObukeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.closePanel();
        });

        console.log(outter.get('selectedLicaContent'));
      }
    },

    openPanel: Ember['default'].observer('modalPanelShow', function () {
      this.set('selectedLica', new Set());
      this.set('selectedLicaContent', Em.A());

      this.set('strukturaObj', {});
      this.set('radnoMestoObj', {});
      this.set('radnoMestoStrukturaObj', {});
      this.set('zaposleniObj', {});
      this.set('selectedZaposleniBezObukeOption', null);
      this.set('datatableFilters', {});

      this.toggleProperty('refreshLica');

      this.set('componentName1', 'zaris-datatable');
      var outter = this;
      setTimeout(function () {
        outter.set('componentName2', 'zaris-datatable-client');
      }, 100);

      setTimeout(function () {
        Ember['default'].showModalPanel('.obrObrazac06aGrupnoDodavanjeObukeModal');
      }, 120);
    }),

    closePanel: function closePanel() {
      $('.obrObrazac06aGrupnoDodavanjeObukeModal').data('dialog').close();
    },

    selectedLica: new Set(),
    selectedLicaContent: Em.A(),

    licaColumns: Ember['default'].computed(function () {
      var outter = this;
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'idLice',
        savedWidth: 100,
        supportSort: false,
        tableCellView: 'zaris-datatable-checkbox-cell',
        getCellContent: function getCellContent(row) {
          return outter.get('selectedLica').has(row.get(this.key));
        },
        setCellContent: function setCellContent(row, value) {
          if (outter.get('selectedLica').has(row.get(this.key))) {
            outter.get('selectedLica')['delete'](row.get(this.key));

            for (var i = 0; i < outter.get('selectedLicaContent').length; i++) {
              if (outter.get('selectedLicaContent')[i].idLice === row.get(this.key)) {
                outter.get('selectedLicaContent').removeAt(i);
              }
            }
          } else {
            outter.get('selectedLica').add(row.get(this.key));
            outter.get('selectedLicaContent').pushObject(row.content);
          }
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'ime',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.ime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'prezime',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.prezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.maticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    }),

    licaIzabranaColumns: Ember['default'].computed(function () {
      var outter = this;
      var columns = [];

      columns.push(ColumnDefinition['default'].create({
        key: 'idLice',
        savedWidth: 100,
        supportSort: false,
        tableCellView: 'zaris-datatable-remove-cell',
        getCellContent: function getCellContent(row) {
          return 'false';
        },
        setCellContent: function setCellContent(row, value) {
          outter.get('selectedLica')['delete'](row.get(this.key));

          for (var i = 0; i < outter.get('selectedLicaContent').length; i++) {
            if (outter.get('selectedLicaContent')[i].idLice === row.get(this.key)) {
              outter.get('selectedLicaContent').removeAt(i);
            }
          }

          outter.toggleProperty('refreshLica');
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'ime',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.ime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'prezime',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.prezime'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'maticniBroj',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac02.maticniBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});