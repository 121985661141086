define('zaris-frontend/templates/obr-obrazac1-sacuvani', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 10
              },
              "end": {
                "line": 10,
                "column": 85
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac1-sacuvani.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","t",["app.menu.radna_mesta"],[],["loc",[null,[10,57],[10,85]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 10
              },
              "end": {
                "line": 11,
                "column": 98
              }
            },
            "moduleName": "zaris-frontend/templates/obr-obrazac1-sacuvani.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","t",["app.menu.radna_mesta_u_strukturi"],[],["loc",[null,[11,58],[11,98]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.1",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 12,
              "column": 8
            }
          },
          "moduleName": "zaris-frontend/templates/obr-obrazac1-sacuvani.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["block","x-option",[],["value",true,"class","xSelectBoxItem"],0,null,["loc",[null,[10,10],[10,98]]]],
          ["block","x-option",[],["value",false,"class","xSelectBoxItem"],1,null,["loc",[null,[11,10],[11,111]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac1-sacuvani.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(element1,1,1);
        morphs[2] = dom.createMorphAt(element1,3,3);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_radnim_mestima_sa_povecanim_rizikom"],[],["loc",[null,[4,28],[4,93]]]],
        ["block","x-select",[],["value",["subexpr","@mut",[["get","radnoMesto",["loc",[null,[9,26],[9,36]]]]],[],[]],"action","refreshTable"],0,null,["loc",[null,[9,8],[12,21]]]],
        ["inline","sacuvani-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[15,17],[15,21]]]]],[],[]],"radnoMesto",["subexpr","@mut",[["get","radnoMesto",["loc",[null,[16,21],[16,31]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[17,18],[17,30]]]]],[],[]],"radnoMesto",["subexpr","@mut",[["get","radnoMesto",["loc",[null,[18,21],[18,31]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","ObrObrazac01Sacuvan","domainId","idObrazac01Sacuvan","titleMsgKey","obrazacSacuvani.brisanjeHeader","descYesNoMsgKey","obrazacSacuvani.brisanjePitanje","descDeleteSuccessMsgKey","obrazacSacuvani.brisanjeUspesno","titleDeleteMsgKey","obrazacSacuvani.brisanjeHeader","descDeleteQuestionMsgKey","obrazacSacuvani.brisanjePitanje","idModalPanel","obrazacSacuvaniModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[30,16],[30,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[31,22],[31,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshObr01",["loc",[null,[32,18],[32,30]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[33,25],[33,41]]]]],[],[]],"renderIzvestaji",true,"routeIzvestaji","obrObrazac1Izvestaji","routeUnos","obrObrazac1Unos","renderSearch",false,"renderFilterArhivirano",false,"renderCreate",false,"renderUpdate",false,"toolbarComponent","skeniran-toolbar","modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[42,31],[42,45]]]]],[],[]]],["loc",[null,[14,8],[43,10]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});