define('zaris-frontend/components/input-inplace', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].TextField.reopen({
    didInsertElement: function didInsertElement() {
      this.$().focus();
    },

    actions: {
      finishedEditing: function finishedEditing() {
        this.set('isEditing', false);
      }
    }
  });

});