define('zaris-frontend/templates/obr-obrazac06-sacuvani', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/obr-obrazac06-sacuvani.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n\n\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.evidencija_o_zaposlenima_osposobljenim_za_bezbedan_i_zdrav_rad"],[],["loc",[null,[4,28],[4,107]]]],
        ["inline","sacuvani-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[12,17],[12,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[13,18],[13,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","ObrObrazac06Sacuvan","domainId","idObrazac06Sacuvan","titleMsgKey","obrazacSacuvani.brisanjeHeader","descYesNoMsgKey","obrazacSacuvani.brisanjePitanje","descDeleteSuccessMsgKey","obrazacSacuvani.brisanjeUspesno","titleDeleteMsgKey","obrazacSacuvani.brisanjeHeader","descDeleteQuestionMsgKey","obrazacSacuvani.brisanjePitanje","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[24,16],[24,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[25,22],[25,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refreshObr06",["loc",[null,[26,18],[26,30]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[27,25],[27,41]]]]],[],[]],"renderIzvestaji",true,"routeIzvestaji","obrObrazac06Izvestaji","routeUnos","obrObrazac06Unos","renderSearch",false,"renderFilterArhivirano",false,"renderCreate",false,"renderUpdate",false,"toolbarComponent","skeniran-toolbar","modalPanelShowEdit",["subexpr","@mut",[["get","showModalPanel",["loc",[null,[36,31],[36,45]]]]],[],[]]],["loc",[null,[11,8],[37,10]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});