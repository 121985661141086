define('zaris-frontend/templates/sys-korisnici', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 49,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sys-korisnici.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" - ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [1, 3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element1,0,0);
        morphs[1] = dom.createMorphAt(element1,2,2);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.korisniciZaPravnoLice"],[],["loc",[null,[5,28],[5,66]]]],
        ["content","params.selectedRow.naziv",["loc",[null,[5,69],[5,97]]]],
        ["inline","zaris-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","SysKorisnik","domainId","idKorisnik","titleMsgKey","sysKorisnik.brisanjeHeader","descYesNoMsgKey","sysKorisnik.brisanjePitanje","descDeleteSuccessMsgKey","sysKorisnik.brisanjeUspesno","titleDeleteMsgKey","sysKorisnik.brisanjeHeader","descDeleteQuestionMsgKey","sysKorisnik.brisanjePitanje","descDeleteSuccessMsgKey","sysKorisnik.brisanjeUspesno","idModalPanel","sysKorisniciModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[24,16],[24,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[25,22],[25,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[26,18],[26,25]]]]],[],[]],"renderDelete",false,"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[28,25],[28,41]]]]],[],[]],"toolbarComponent","sys-korisnik-rola-toolbar","customId",["subexpr","@mut",[["get","params.id_pravno_lice",["loc",[null,[30,19],[30,40]]]]],[],[]]],["loc",[null,[9,8],[31,10]]]],
        ["inline","sys-korisnici-modal",[],["id","sysKorisniciModal","target",["subexpr","@mut",[["get","this",["loc",[null,[39,11],[39,15]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[40,12],[40,19]]]]],[],[]],"isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[41,10],[41,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[42,16],[42,27]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[43,21],[43,37]]]]],[],[]],"refreshBaseTable",["subexpr","@mut",[["get","refresh",["loc",[null,[44,21],[44,28]]]]],[],[]],"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[45,18],[45,29]]]]],[],[]],"refreshWoResetSelection",["subexpr","@mut",[["get","refreshWoResetSelection",["loc",[null,[46,28],[46,51]]]]],[],[]],"pravnoLice",["subexpr","@mut",[["get","params.id_pravno_lice",["loc",[null,[47,15],[47,36]]]]],[],[]]],["loc",[null,[37,0],[48,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});