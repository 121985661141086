define('zaris-frontend/components/sif-opasnosti-stetnosti-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    activeAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('idRowSelected') !== 0;
      var readOnly = this.get('readOnly');
      var selectedRow = this.get('selectedRow');

      return isRowSelected && !readOnly && selectedRow.fg_arhivirano;
    }),

    archiveAllowed: Ember['default'].computed('selectedRow', function () {
      var isRowSelected = this.get('idRowSelected') !== 0;
      var selectedRow = this.get('selectedRow');
      var readOnly = this.get('readOnly');

      return isRowSelected && !readOnly && !selectedRow.fg_arhivirano && !selectedRow.values[0].value;
    }),

    actions: {
      aktiviraj: function aktiviraj() {
        var outter = this;
        var i18n = this.get('i18n');
        var idRowSelected = this.get('idRowSelected');

        var sifOpasnostiStetnostiModel = Models['default'].SifOpasnostiStetnostiTree.create({ errorHandlerController: this });
        sifOpasnostiStetnostiModel.getById('aktiviranje', idRowSelected, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            content: i18n.t('sifOpasnostiStetnosti.aktiviranjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      },

      arhiviraj: function arhiviraj() {
        var outter = this;
        var i18n = this.get('i18n');
        var idRowSelected = this.get('idRowSelected');

        var sifOpasnostiStetnostiModel = Models['default'].SifOpasnostiStetnostiTree.create({ errorHandlerController: this });
        sifOpasnostiStetnostiModel.getById('arhiviranje', idRowSelected, this.get('login').getAccessToken(), 'PATCH', function (o) {
          $.Notify({
            content: i18n.t('sifOpasnostiStetnosti.deaktiviranjeUspesno').string,
            keepOpen: false,
            type: 'success'
          });
          outter.toggleProperty('refresh');
        });
      }
    }
  });

});