define('zaris-frontend/components/bz-zakoni-toolbar', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['datatableControlsPanel', 'panelCentered', 'zarisWindow'],

    login: Ember['default'].inject.service('login'),
    i18n: Ember['default'].inject.service('i18n'),

    //tells if any row is selected
    isRowSelected: Ember['default'].computed('idRowSelected', function () {
      return this.get('idRowSelected') !== 0 && this.get('selectedRow')[this.get('domainId')] !== null;
    }),

    actions: {

      preuzmiDokument: function preuzmiDokument() {
        var outter = this;
        var i18n = this.get('i18n');
        var model = Models['default'].BzZakon.create({ errorHandlerController: this });

        model.getById('downloadDokument', this.get('selectedRow.idZakon'), this.get('login').getAccessToken(), 'GET', function (hash) {
          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }
  });

});