define('zaris-frontend/templates/sif-revers', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 0
          }
        },
        "moduleName": "zaris-frontend/templates/sif-revers.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel zarisPanel");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","heading");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","icon mif-cabinet");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"style","width: 95%; min-height: 570px;");
        dom.setAttribute(el3,"class","content panelCentered");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","t",["app.menu.reversi"],[],["loc",[null,[5,28],[5,52]]]],
        ["inline","zaris-datatable",[],["target",["subexpr","@mut",[["get","this",["loc",[null,[10,17],[10,21]]]]],[],[]],"columns",["subexpr","@mut",[["get","tableColumns",["loc",[null,[11,18],[11,30]]]]],[],[]],"hasHeader",true,"hasFooter",false,"perPage",15,"domainName","VsifRevers","domainId","idLice","titleMsgKey","sifLica.brisanjeHeader","descYesNoMsgKey","sifLica.brisanjePitanje","descDeleteSuccessMsgKey","sifLica.brisanjeUspesno","titleDeleteMsgKey","sifLica.brisanjeHeader","descDeleteQuestionMsgKey","sifLica.brisanjePitanje","descDeleteSuccessMsgKey","sifLica.brisanjeUspesno","renderSacuvaniObrasci",true,"routeSacuvaniObrasci","obrObrazac10Sacuvani","idModalPanel","sifLicaModal","isNew",["subexpr","@mut",[["get","isNew",["loc",[null,[26,16],[26,21]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[27,22],[27,33]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[28,18],[28,25]]]]],[],[]],"onModalCreate","initModelObject","modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[30,25],[30,41]]]]],[],[]],"disabledCreate",true,"disabledDelete",true,"renderCreate",false,"renderDelete",false,"selectedRow",["subexpr","@mut",[["get","selectedRow",["loc",[null,[35,24],[35,35]]]]],[],[]],"renderSacuvajObrazac",true],["loc",[null,[9,8],[37,10]]]],
        ["inline","sif-revers-modal",[],["id","sifReversModal","target",["subexpr","@mut",[["get","this",["loc",[null,[44,11],[44,15]]]]],[],[]],"modelObject",["subexpr","@mut",[["get","modelObject",["loc",[null,[45,16],[45,27]]]]],[],[]],"refresh",["subexpr","@mut",[["get","refresh",["loc",[null,[46,12],[46,19]]]]],[],[]],"modalPanelShow",["subexpr","@mut",[["get","myModalPanelShow",["loc",[null,[47,19],[47,35]]]]],[],[]],"selektovanoLice",["subexpr","@mut",[["get","selectedRow",["loc",[null,[48,22],[48,33]]]]],[],[]]],["loc",[null,[42,0],[49,2]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});