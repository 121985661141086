define('zaris-frontend/controllers/sif-lzo-nabavka', ['exports', 'ember', 'ember-table/models/column-definition', 'zaris-frontend/mixins/language-transformations', 'zaris-frontend/conf'], function (exports, Ember, ColumnDefinition, LanguageTransformations, conf) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(LanguageTransformations['default'], {

    i18n: Ember['default'].inject.service('i18n'),

    selectedDomainName: "ObrObrazac10Lzo",

    selectedDomainNameDidChange: Ember['default'].observer('selectedDomainName', function () {
      this.toggleProperty('refresh');
    }),

    tableColumns: Ember['default'].computed(function () {
      var columns = [];
      var self = this;

      columns.push(ColumnDefinition['default'].create({
        key: 'sifLzo',
        savedWidth: 220,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.licnaZastitnaOprema'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key).naziv;
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'fabrickiBroj',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.fabrickiBroj'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'datumNabavke',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.datumNabavke'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'vaziDo',
        savedWidth: 150,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.vaziDo'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key) !== null ? moment(row.get(this.key)).format('DD.MM.YYYY.') : '';
        }
      }));

      columns.push(ColumnDefinition['default'].create({
        key: 'napomena',
        savedWidth: 300,
        supportSort: true,
        headerCellName: this.get('i18n').t('obrObrazac10.napomena'),
        headerCellView: 'zaris-header-cell',
        getCellContent: function getCellContent(row) {
          return row.get(this.key);
        }
      }));

      return columns;
    })

  });

});