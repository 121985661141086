define('zaris-frontend/controllers/obr-obrazac1-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    // opasnosti i stetnosti
    opasnostiStetnostiObj: {},

    // izbor radnih mesta
    radnaMesta: "radnaMesta",

    // idRadnoMesto - ukoliko je izabrano radno mesto
    idRadnoMesto: null,

    // idRadnoMestoStruktura - ukoliko je izabrano radno mesto u strukturi
    idRadnoMestoStruktura: null,

    // nivo rizika - null, '>', '<', '='
    nivoRizika: '<>',

    // idNivo rizika - ukoliko je odabran da je nivo rizika veci, manji ili jednak
    idNivoRizika: null,

    // po cemu se grupise izvestaj
    groupBy: 'rm',

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;

      var opasnostiStetnostiObj = this.get('opasnostiStetnostiObj');
      opasnostiStetnostiObj.id = null;
      opasnostiStetnostiObj.naziv = i18n.t('obrazac1.izvestaji.sveOpasnostiStetnosti').string;
    },

    actions: {
      generate: function generate() {

        var pObj = {};
        var outter = this;
        var loginService = this.get('loginService');

        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        var radnaMesta = this.get('radnaMesta');
        var idRadnoMesto = this.get('idRadnoMesto');
        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        //  pObj.p_sva_radna_mesta = "0";
        pObj.p_povecan_rizik = "0";
        pObj.p_id_radno_mesto = null;
        pObj.p_id_radno_mesto_struktura = null;

        switch (radnaMesta) {
          case "radnaMesta":
            // pObj.p_sva_radna_mesta = "1";
            break;
          case "radnaMestaSaRizikom":
            pObj.p_radna_mesta_povecani_rizik = "1";
            break;
          case "radnoMesto":
            pObj.p_id_radno_mesto = idRadnoMesto;
            break;
          case "radnoMestoStruktura":
            pObj.p_id_radno_mesto_struktura = idRadnoMestoStruktura;
            break;
          default:
            console.log('error ', radnaMesta);
        }

        var nivoRizika = this.get('nivoRizika');
        var idNivoRizika = this.get('idNivoRizika');
        pObj.p_nivo_rizika_operacija = nivoRizika;

        if (idNivoRizika != null && nivoRizika != '<>') {
          var myModel = Models['default'].SifNivoRizika.create({ errorHandlerController: this });
          pObj.p_nivo_rizika = myModel.findGetResult(idNivoRizika, loginService.getAccessToken()).sifra;
        } else {
          pObj.p_nivo_rizika = null;
        }

        var opasnostiStetnostiObj = this.get('opasnostiStetnostiObj');
        pObj.p_id_opasnosti_stetnosti = opasnostiStetnostiObj.id;

        /*
        Grupisi po
        salje se:
            p_group_by
        znacenje:
            p_group_by - string
                'radnaMesta'             - grupisi po radnim mestima
                'organizacionaStruktura' - grupisi po organizacionoj strukturi
                'opasnostiStetnosti'     - grupisi po opasnostima i stetnostima
                'nivoiRizika'            - grupisi po nivoima rizika
        */
        pObj.p_labela = this.get('groupBy');

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_01';
        izvestajiDTO.reportParameters = pObj;

        this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});