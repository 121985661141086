define('zaris-frontend/controllers/obr-obrazac07-izvestaji', ['exports', 'ember', 'zaris-frontend/models/models'], function (exports, Ember, Models) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //injected login service for getting oauth token
    loginService: Ember['default'].inject.service('login'),

    i18n: Ember['default'].inject.service('i18n'),

    // organizaciona struktura
    strukturaObj: {},

    // zaposleni
    zaposleniObj: {},

    // izbor radnih mesta
    radnaMesta: "radnaMesta",

    // idRadnoMesto - ukoliko je izabrano radno mesto
    idRadnoMesto: null,

    // idRadnoMestoStruktura - ukoliko je izabrano radno mesto u strukturi
    idRadnoMestoStruktura: null,

    // idOpasnaMaterija
    idOpasnaMaterija: null,

    // idKlasaOpasneMaterije
    idKlasaOpasneMaterije: null,

    // po cemu se grupise izvestaj
    groupBy: 'rm',

    init: function init() {
      this._super();

      var i18n = this.get('i18n');

      // napravi struktura.id i struktura.naziv
      var strukturaObj = this.get('strukturaObj');
      strukturaObj.id = null;
      strukturaObj.naziv = i18n.t('obrazac1.izvestaji.sviNivoiStrukture').string;

      var zaposleniObj = this.get('zaposleniObj');
      zaposleniObj.id = null;
      zaposleniObj.naziv = "";
    },

    actions: {

      generate: function generate() {

        // prepakovanje objekta
        var pObj = {};

        pObj.p_id_lice = null;
        var zaposleniObj = this.get('zaposleniObj');
        var zaposleniModel = Models['default'].SifZaposleni.create({ errorHandlerController: this });
        if (zaposleniObj.id) {
          var lice = zaposleniModel.getResult('liceByZaposleni/' + zaposleniObj.id, this.get('loginService').getAccessToken(), 'GET');
          pObj.p_id_lice = lice.idLice;
        }

        var strukturaObj = this.get('strukturaObj');
        pObj.p_id_organizaciona_jedinica = strukturaObj.id;

        var radnaMesta = this.get('radnaMesta');
        var idRadnoMesto = this.get('idRadnoMesto');
        var idRadnoMestoStruktura = this.get('idRadnoMestoStruktura');

        //  pObj.p_sva_radna_mesta = 0;
        pObj.p_id_radno_mesto = null;
        pObj.p_id_radno_mesto_struktura = null;

        switch (radnaMesta) {
          case "radnaMesta":
            //    pObj.p_sva_radna_mesta = 1;
            break;
          case "radnoMesto":
            pObj.p_id_radno_mesto = idRadnoMesto;
            break;
          case "radnoMestoStruktura":
            pObj.p_id_radno_mesto_struktura = idRadnoMestoStruktura;
            break;
          default:
            console.log('error ', radnaMesta);
            break;
        }

        var idOpasnaMaterija = this.get('idOpasnaMaterija');
        pObj.p_id_opasna_materija = idOpasnaMaterija;

        var idKlasaOpasneMaterije = this.get('idKlasaOpasneMaterije');
        pObj.id_klase_opasnih_materija = idKlasaOpasneMaterije;

        var groupBy = this.get('groupBy');
        pObj.p_labela = groupBy;

        var izvestajiDTO = {};
        izvestajiDTO.keyReport = 'izv_obrazac_07';
        izvestajiDTO.reportParameters = pObj;

        //this.set('izvestajiDTO', izvestajiDTO);

        var model = Models['default'].Util.create({ errorHandlerController: this });
        model.generateReport(izvestajiDTO, this.get('loginService').getAccessToken(), function (hash) {
          console.log(hash);

          var link = document.createElement('a');
          link.href = model.generateDownloadLink(hash);

          document.body.appendChild(link);
          link.click();
        }, function (e) {});
      }
    }

  });

});