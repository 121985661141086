define('zaris-frontend/router', ['exports', 'ember', 'zaris-frontend/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('home', { path: '/' });
    this.route('login', { path: '/login' });
    this.route('logout', { path: '/logout' });
    this.route('listPol', { path: '/pol' });
    this.route('viewPol', { path: '/pol/:id' });

    this.route('sifKorisnickiDefinisaniPregledi', { path: '/sif_korisnicki_definisani_pregledi' });
    this.route('sifKorisnickiDefinisaneObuke', { path: '/sif_korisnicki_definisane_obuke' });
    this.route('sifLicnaZastitnaOprema', { path: '/sif_licna_zastitna_oprema' });
    this.route('sifVrsteVelicinaZaLzo', { path: '/sif_vrste_velicina_za_lzo' });
    this.route('sifOpremaZaRad', { path: '/sif_oprema_za_rad' });
    this.route('sifParametriRadneOkoline', { path: '/sif_parametri_radne_okoline' });
    this.route('sifOpasneMaterije', { path: '/sif_opasne_materije' });
    this.route('sifNivoiRizika', { path: '/sif_nivoi_rizika' });
    this.route('sifOpasnostiStetnosti', { path: '/sif_opasnosti_stetnosti' });
    this.route('sifZaposleni', { path: '/sif_zaposleni' });
    this.route('sifLica', { path: '/sif_lica' });
    this.route('sifRadnoMestoStruktura', { path: '/sif_radno_mesto_struktura' });
    this.route('sifOrganizacionaStruktura', { path: '/sif_organizaciona_struktura' });
    this.route('sifRadnaMesta', { path: '/sif_radna_mesta' });
    this.route('sifElementiPregleda', { path: '/sif_elementi_pregleda' });
    this.route('sifLzoNabavka', { path: '/sif_lzo_nabavka' });

    this.route('obrObrazac1Unos', { path: '/obr_obrazac1_unos' });
    this.route('obrObrazac1Sacuvani', { path: '/obr_obrazac1_sacuvani' });
    this.route('obrObrazac1Izvestaji', { path: '/obr_obrazac1_izvestaji' });
    this.route('obrObrazac1aUnos', { path: '/obr_obrazac1a_unos' });
    this.route('obrObrazac1aSacuvani', { path: '/obr_obrazac1a_sacuvani' });
    this.route('obrObrazac1aIzvestaji', { path: '/obr_obrazac1a_izvestaji' });

    this.route('obrObrazac02Unos', { path: '/obr_obrazac02_unos' });
    this.route('obrObrazac02Sacuvani', { path: '/obr_obrazac02_sacuvani' });
    this.route('obrObrazac02Izvestaji', { path: '/obr_obrazac02_izvestaji' });
    this.route('obrObrazac13Unos', { path: '/obr_obrazac13_unos' });
    this.route('obrObrazac13Sacuvani', { path: '/obr_obrazac13_sacuvani' });
    this.route('obrObrazac13Izvestaji', { path: '/obr_obrazac13_izvestaji' });

    this.route('obrObrazac03Unos', { path: '/obr_obrazac03_unos' });
    this.route('obrObrazac03Sacuvani', { path: '/obr_obrazac03_sacuvani' });
    this.route('obrObrazac03Izvestaji', { path: '/obr_obrazac03_izvestaji' });
    this.route('obrObrazac06Izvestaji', { path: '/obr_obrazac06_izvestaji' });
    this.route('obrObrazac06aIzvestaji', { path: '/obr_obrazac06b_izvestaji' });

    this.route('obrObrazac04Unos', { path: '/obr_obrazac04_unos' });
    this.route('obrObrazac04Sacuvani', { path: '/obr_obrazac04_sacuvani' });
    this.route('obrObrazac04Izvestaji', { path: '/obr_obrazac04_izvestaji' });

    this.route('obrObrazac05Unos', { path: '/obr_obrazac05_unos' });
    this.route('obrObrazac05Sacuvani', { path: '/obr_obrazac05_sacuvani' });
    this.route('obrObrazac05Izvestaji', { path: '/obr_obrazac05_izvestaji' });

    this.route('obrObrazac06Unos', { path: '/obr_obrazac06_unos' });
    this.route('obrObrazac06Sacuvani', { path: '/obr_obrazac06_sacuvani' });
    this.route('obrObrazac06aUnos', { path: '/obr_obrazac06a_unos' });

    this.route('obrObrazac07Unos', { path: '/obr_obrazac07_unos' });
    this.route('obrObrazac07Sacuvani', { path: '/obr_obrazac07_sacuvani' });
    this.route('obrObrazac07Izvestaji', { path: '/obr_obrazac07_izvestaji' });

    this.route('obrObrazac08Unos', { path: '/obr_obrazac08_unos' });
    this.route('obrObrazac08Sacuvani', { path: '/obr_obrazac08_sacuvani' });
    this.route('obrObrazac08Izvestaji', { path: '/obr_obrazac08_izvestaji' });

    this.route('obrObrazac09Unos', { path: '/obr_obrazac09_unos' });
    this.route('obrObrazac09Sacuvani', { path: '/obr_obrazac09_sacuvani' });
    this.route('obrObrazac09Izvestaji', { path: '/obr_obrazac09_izvestaji' });

    this.route('obrObrazac10Unos', { path: '/obr_obrazac10_unos' });
    this.route('obrObrazac10Sacuvani', { path: '/obr_obrazac10_sacuvani' });
    this.route('obrObrazac10Izvestaji', { path: '/obr_obrazac10_izvestaji' });

    this.route('obrObrazac11Unos', { path: '/obr_obrazac11_unos' });
    this.route('obrObrazac11Sacuvani', { path: '/obr_obrazac11_sacuvani' });
    this.route('obrObrazac11Izvestaji', { path: '/obr_obrazac11_izvestaji' });

    this.route('obrObrazac12Unos', { path: '/obr_obrazac12_unos' });
    this.route('obrObrazac12Sacuvani', { path: '/obr_obrazac12_sacuvani' });
    this.route('obrObrazac12Izvestaji', { path: '/obr_obrazac12_izvestaji' });

    this.route('obrObrazac13Unos', { path: '/obr_obrazac13_unos' });
    this.route('obrObrazac13Sacuvani', { path: '/obr_obrazac13_sacuvani' });
    this.route('obrObrazac13Izvestaji', { path: '/obr_obrazac13_izvestaji' });

    this.route('obrObrazac14Unos', { path: '/obr_obrazac14_unos' });
    this.route('obrObrazac14Sacuvani', { path: '/obr_obrazac14_sacuvani' });
    this.route('obrObrazac14Izvestaji', { path: '/obr_obrazac14_izvestaji' });

    this.route('sifLzoIzvestaji', { path: '/sif_lzo_izvestaji' });

    this.route('sifRevers', { path: '/sif_revers' });

    this.route('sysPravnaLica', { path: '/sys_pravna_lica' });
    this.route('sysKorisnici', { path: '/sys_korisnici/:id_pravno_lice' });
    this.route('sysPromenaPassworda', { path: '/sys_promena_passworda' });

    this.route('sifOrganizacionaStrukturaIzvestaji', { path: '/sif_organizaciona_struktura_izvestaji' });
    this.route('sifZaposleniIzvestaji', { path: '/sif_zaposleni_izvestaji' });
    this.route('sifRadnaMestaIzvestaji', { path: '/sif_radna_mesta_izvestaji' });
    this.route('sifRadnaMestaStrukturaIzvestaji', { path: '/sif_radna_mesta_struktura_izvestaji' });
    this.route('sifLicaIzvestaji', { path: '/sif_lica_izvestaji' });

    this.route('bzOpasneMaterije', { path: '/bz_opasne_materije' });

    this.route('semPodesavanjeObavestenja', { path: '/sem_podesavanje_obavestenja' });
    this.route('sysPromenaJezika', { path: '/sys_promena_jezika' });
    this.route('sysOdabirPotpisnika', { path: '/sys_odabir_potpisnika' });

    this.route('vertikalniIzvestaji', { path: '/vertikalni_izvestaji' });

    this.route('bzZakoni', { path: '/bz_zakoni' });

    this.route('unauthorized', { path: '/unauthorized' });
  });

  Router.reopen({
    doSomething: (function () {
      $('.dialog-overlay').remove();
      return;
    }).on('didTransition')
  });

  Ember['default'].Route.reopen({
    roles: Ember['default'].inject.service('roles'),

    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'login' || transition.targetName === 'logout' || transition.targetName === 'home') {
        return true;
      }

      if (this.get('roles').userHasRoleForRoute(transition.targetName)) {
        return true;
      }

      // manage the unauthorized attempt
      this.transitionTo('unauthorized'); // or whatever
    }
  });

  exports['default'] = Router;

});