define('zaris-frontend/utils/template-to-string', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  // template-to-string.js
  var container = null;

  Ember['default'].Application.instanceInitializer({
    name: 'template-to-string',
    initialize: function initialize(instance) {
      // grab the container using the instance initializer
      container = instance.container;
    }
  });

  // when you call this function
  // app initialization must be finished
  exports['default'] = function (template) {
    return new Ember['default'].RSVP.Promise(function (resolve) {
      // create a new component
      var component = Ember['default'].Component.create({
        style: 'display:none;', // hide it
        layout: template, // specify your template as a layout
        container: container // provide container
      });
      // subscribing to didRender event
      component.on('didRender', function () {
        // no we can grab the result of rendering
        var el = component.element.innerHTML;
        // remove the component
        this.remove();
        resolve(el);
      });

      // append the component to the body to make it render
      component.append();
    });
  }

});